import React from 'react'
import { Modal } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import styled from '@emotion/styled'

const ModalUserDelete = ({ handleDelete, open, onClose }) => {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <ModalContainer>
        <ModalCloseIcon data-tip='Fechar' onClick={onClose} />
        <ButtonRed onClick={handleDelete}>Confirmar exclusão</ButtonRed>
        <ButtonBordered onClick={onClose}>Cancelar</ButtonBordered>
      </ModalContainer>
    </ModalWrapper>
  )
}

const Button = styled.button`
  max-height: 40px;
  padding: 0.7em 1em;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin: 0.8em 0.8em;
  transition: all 0.3s;
`

const ButtonRed = styled(Button)`
  color: #fff;
  background: #a80303;
  :hover {
    background-color: #880202 !important;
  }
  :focus {
    background-color: #880202 !important;
  }
`

const ButtonBordered = styled(Button)`
  border: 1px solid #555;
  color: #555;
  :hover {
    background: #555 !important;
    color: white;
    border: 1px solid white;
  }
  :focus {
    background: #555 !important;
    color: white;
    border: 1px solid white;
  }
`

const ModalWrapper = styled(Modal)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const ModalContainer = styled.div`
  min-height: 150px;
  min-width: 300px;
  padding: 1em 2em;
  background-color: #fff;
  position: relative;
  outline: none;
  display: flex;
  overflow: auto;
  align-content: center;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`

const ModalCloseIcon = styled(CloseIcon)`
  color: #7f7f7f;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

export default ModalUserDelete
