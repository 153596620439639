import styled from 'styled-components'

import { leadCardStatusBgColor, leadCardSatusFontColor, leadCardStatusBorderColors } from '../../../../styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 170px;
  height: 80px;

  border-radius: 12px;
  border: solid 2px;
  border-color: ${({ status }) => leadCardStatusBorderColors[status] || '#076F9F'};

  h4 {
    color: black;
    font-size: 2em;
  }

  p {
    width: 100%;
    height: 22px;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 7px;
    color: ${({ status }) => leadCardSatusFontColor[status] || '#076F9F'};

    background-color: ${({ status }) => leadCardStatusBgColor[status] || '#076F9F'};
    border-bottom: solid 2px;
    border-color: ${({ status }) => leadCardStatusBorderColors[status] || '#076F9F'};
    border-radius: 10px 10px 0px 0px;
  }

  @media (max-width: 540px) {
    height: 47px;
    width: 45%;

    h4 {
      font-size: 22px;
    }

    p {
      margin-bottom: 0px;
    }
  }
`
