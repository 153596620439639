import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { colors } from '../../Themes'

export default function MaterialButton(props) {
  const DefaultButton = withStyles(theme => ({
    root: {
      backgroundColor: props.backgroundColor || '#076f9f',
      margin: props.margin || theme.spacing(1),
      width: props.width || 'initial',
      height: props.height || '38px',
      padding: props.padding || '0.5em 3.2em',
      border: props.variant === 'outlined' ? `1px solid ${props.color}` : 'none',
      color: props.color || '#fff',
      textTransform: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: props.backgroundColor || '#076f9f'
      }
    }
  }))(Button)

  return (
    <DefaultButton
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      variant={props.variant || 'contained'}
    >
      {props.loading ? <CircularProgress size={20} style={{ color: colors.blueKavod }} /> : props.label}
    </DefaultButton>
  )
}
