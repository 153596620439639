import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Themes'

const SelectComponent = ({ children, field, label, ...props }) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Select {...field} {...props}>
        {children}
      </Select>
    </Container>
  )
}

const Label = styled.label`
  color: ${colors.mediumGray};
  transition: all 0.3s;
`

const Select = styled.select`
  border-radius: 4px;
  height: 40px;
  width: 100%;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  outline: none;
  border: 1px solid ${colors.mediumGray};
  padding: 0 10px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  background: #fff;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3em;
  &:focus-within {
    ${Label} {
      color: ${colors.blueKavod};
    }
    ${Select} {
      border: 1px solid ${colors.blueKavod};
    }
  }
`

export default SelectComponent
