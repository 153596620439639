import React from 'react'
import { CheckCircle } from '@material-ui/icons'
import styled from 'styled-components'

import { formatMoney, sanitizeDate } from '../../helpers'
import { installmentStatuses } from '../../constants'

const InstallmentRow = ({ portion, handleRowClick }) => {
  return (
    <Row onClick={handleRowClick}>
      <Cell mw='50px'>
        <i
          className='material-icons'
          style={{
            color: 'green',
            visibility: portion.abbreviation !== installmentStatuses.PAID ? 'hidden' : 'visible'
          }}
        >
          <CheckCircle />
        </i>
        <Text>
          {portion.installment_number}/{portion.total_installments}
        </Text>
      </Cell>
      <Cell mw='100px'>{`R$ ${formatMoney(portion.transfer_amount_delayed || portion.transfer_amount)}`}</Cell>
      <Cell mw='80px'>{sanitizeDate(portion.transfer_date_delayed || portion.transfer_date)}</Cell>
      <Cell mw='80px'>{sanitizeDate(portion.pay_date)}</Cell>
    </Row>
  )
}

const Row = styled.tr`
  cursor: pointer;
  display: flex;
  background-color: white;
  transition: all 0.3s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
    transition: all 0.3s;
  }
  @media (max-width: 769px) {
    font-size: 14px;
  }
`

const Cell = styled.th`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: normal;
  padding: 15px 5px;
  min-width: ${({ mw }) => mw || '0px'};
`

const Text = styled.p`
  padding: 0 0.5em;
  margin-right: 1.6em;
`

export default InstallmentRow
