import client from '../../providers/fetchClient'

export const getCompanies = params => client.get('/v3/companies', { params })

export const getCompanyById = id => client.get(`/v3/companies/${id}`)

export const getCompanyInfos = async cnpj => {
  try {
    const { data: companyInfo } = await client.get(`/v3/bmp/company-info/${cnpj}`)
    if (!companyInfo) throw new Error('Ocorreu um erro ao buscar o CNPJ')
    if (companyInfo.status === 'ERROR') throw new Error(companyInfo.message)
    return companyInfo
  } catch (error) {
    if (error.response) {
      if (error.response.status === 429) {
        throw new Error('Aguarde para realizar nova requisição de CNPJ.')
      } else {
        if (!!error.response.message) {
          throw new Error(`Erro ao buscar o CNPJ: ${error.response.message}.`)
        }
      }
      if (error.response.status === 500) {
        throw new Error('Erro ao buscar o CNPJ: CNPJ Inválido.')
      }
    }
    throw new Error(`Erro ao buscar o CNPJ: ${error.message}.`)
  }
}
