import React from 'react'

import PropTypes from 'prop-types'

import { Container, Content } from './styled'
import { Badge } from '../index'

const StatusModal = ({ title, close }) => {
  return (
    <Container show={!!title}>
      <section>
        <div>
          <h1>{title}</h1>

          <button onClick={close}>
            <img src='/assets/images/closemodal.svg' alt='Botão fechar' />
          </button>
        </div>

        <Content>
          <Badge status='Lista principal' color='green' />
          <p>
            Você está na lista principal. Ao término da captação, você receberá um e-mail com os dados para realizar a
            TED. Este status será atualizado após recebermos as TEDs de todos os investidores.
          </p>

          <Badge status='Em espera' color='yellow' />
          <p>
            Você ficou em lista de espera, mas não foi acionado. Caso queira, clique no X ao lado para apagar este Card.
          </p>

          <Badge status='Cancelado' color='red' />
          <p>Você está na lista de espera. Caso necessário, você será comunicado para prosseguir com o investimento.</p>
        </Content>
      </section>
    </Container>
  )
}

StatusModal.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
}

export default StatusModal
