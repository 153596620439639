import React from 'react'

import { formatValueToBrl } from '../../helpers'

export const simulationColumns = [
  {
    Header: 'Nº parcela',
    accessor: 'installment_number',
    minWidth: 80,
    Cell: ({ original: { installment_number } }) => <p>{installment_number}</p>
  },
  {
    Header: 'Valor',
    accessor: 'gross_amount',
    minWidth: 140,
    Cell: ({ original }) => <p>{formatValueToBrl(original.gross_amount)}</p>
  }
]
