import React, { useEffect } from 'react'
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'

import { TextInput } from '../../components/FormComponents'
import { CardEditIcon } from './'

import { leadModalCards } from '../../constants'

import { Card, Title, InfoContainer, Info, EditInputsContainer } from './styledComponents'

const HistoryCard = ({ lead, isEditing, handleEditCard, control, errors, setValue }) => {
  useEffect(() => {
    if (!isEditing) return
    setValue('company_history', lead.company_history)
  }, [isEditing])

  return (
    <Card>
      <CardEditIcon onClick={() => handleEditCard(leadModalCards.COMPANY_HISTORY)} />
      <Title>Histórico da empresa</Title>
      {isEditing ? (
        <EditInputsContainer style={{ justifyContent: 'center' }}>
          <ReactIsCapsLockActive>
            {active => (
              <TextInput
                name='company_history'
                control={control}
                error={active ? { message: 'Caps Lock ligado' } : errors['company_history']}
                label='Histórico da Empresa *'
                defaultValue=''
                width='100%'
                margin='0px'
                multiline
                dense
              />
            )}
          </ReactIsCapsLockActive>
        </EditInputsContainer>
      ) : (
        <InfoContainer>
          <Info>{lead.company_history}</Info>
        </InfoContainer>
      )}
    </Card>
  )
}

export default HistoryCard
