import React, { useCallback, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import { toast } from 'react-toastify'

import { DropzoneHome, Button, Row } from '../../../../components/v2Components'

import { Div, Controls, CropContainer } from './style'

import getCroppedImg from './cropImg'
import { updateImagesHome } from '../../../../services/v3'

const PhotoBody = ({ image, fetchInfos }) => {
  const { handleSubmit, control, setValue } = useForm({
    mode: 'onBlur'
  })

  const [photo, setPhoto] = useState({})
  const [dropModal, setDropModal] = useState(false)
  const [cropFile, setCropFile] = useState()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(cropFile, croppedAreaPixels, 0)
      setCroppedImage(croppedImage)
      setDropModal(false)
      onSubmit(croppedImage)
      window.scrollTo(0, 10000)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, cropFile])

  const handleDropDocuments = files => {
    const reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.addEventListener('load', () => {
      setCropFile(reader.result)
    })

    window.scrollTo(0, 0)
    setDropModal(true)
  }

  const handleDeleteDocuments = () => {
    setValue('file_document', '')
    setCropFile(undefined)
    setCroppedImage(null)
  }

  const onSubmit = useCallback(
    async croppedImage => {
      try {
        let file = await fetch(croppedImage)
          .then(r => r.blob())
          .then(blobFile => new File([blobFile], 'photo', { type: 'image/jpg' }))

        const formData = new FormData()
        formData.append('file', file)
        formData.append('order', 0)

        if (!!photo.id) {
          await updateImagesHome(photo.id, formData)
        }
        handleDeleteDocuments()
        fetchInfos()
        toast.success('Foto salva.')
      } catch (error) {
        toast.error('Erro ao salvar foto .')
      }
    },
    [photo, image]
  )

  useEffect(() => {
    if (!!image) {
      setPhoto(image)
    }
  }, [image])

  return (
    <Div>
      <form onSubmit={handleSubmit()} style={{ width: '100%' }}>
        <DropzoneHome
          name='photo'
          control={control}
          onDrop={handleDropDocuments}
          onDelete={handleDeleteDocuments}
          admin={true}
        />
        {dropModal && cropFile && (
          <CropContainer>
            <Cropper
              image={cropFile}
              crop={crop}
              zoom={zoom}
              aspect={4 / 4}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <Controls>
              <Slider
                value={zoom}
                min={1}
                max={4}
                step={0.1}
                aria-labelledby='Zoom'
                onChange={(e, zoom) => setZoom(zoom)}
              />
              <Row style={{ width: '100%', gap: '9px' }}>
                <Button width='50%' variant='outlined' onClick={() => setDropModal(false)} type='submit'>
                  Sair
                </Button>
                <Button width='50%' onClick={() => showCroppedImage()}>
                  Salvar
                </Button>
              </Row>
            </Controls>
          </CropContainer>
        )}
      </form>
      {!!croppedImage ? (
        <img src={croppedImage} alt='img' style={{ borderRadius: '50%' }} />
      ) : !!photo.photo_url ? (
        <img src={`${photo.photo_url}?${new Date().getTime()}`} alt='img' style={{ borderRadius: '50%' }} />
      ) : (
        <></>
      )}
    </Div>
  )
}

export default PhotoBody
