import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import { KeyboardArrowDown, CheckCircle } from '@material-ui/icons'
import { toast } from 'react-toastify'
import Spinner from 'react-spinkit'

import { LoadingScreen, MaterialButton } from '../../components'

import { getOrders, getCampaignResponse, createCcb } from '../../services/v3'

function StyledDropzone({ match }) {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: '.pdf'
  })

  const [orders, setOrders] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [userFiles, setUserFiles] = useState(null)

  const fetchOrders = async () => {
    try {
      const { data: orders } = await getOrders({ campaign_id: match.params.id })
      setOrders(orders)
    } catch {
      toast.error('Não foi possível carregar as informações.')
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    setUserFiles(acceptedFiles.map(file => Number(file.path.split('_')[0])))
  }, [acceptedFiles])

  const handleBankCreditCertificatesUpload = async () => {
    try {
      setSubmitting(true)
      const {
        data: { usersProposal = [] }
      } = await getCampaignResponse(match.params.id)
      const uploadData = orders.reduce((acc, order) => {
        const validProposal = usersProposal.find(bmp => bmp.user.user_profile.id === order.pledges[0].investor_id)
        const file = acceptedFiles.find(file => Number(file.path.split('_')[0]) === order.pledges[0].investor_id)
        if (userFiles.includes(order.pledges[0].investor_id)) {
          return [...acc, { ...order, file: file, valorParcela: validProposal.user.proposal.VlrParcela }]
        }
        return acc
      }, [])

      if (uploadData.length === 0) {
        setSubmitting(false)
        return toast.error('Nenhum dos arquivo(s) selecionados é válido.')
      }

      for (const order of uploadData) {
        const formData = new FormData()
        formData.append('order_id', order.id)
        formData.append('file', order.file)
        await createCcb(formData)
      }

      toast.success('O upload foi concluído com sucesso.')
      fetchOrders()
    } catch (error) {
      console.error(error)
      toast.error('Tivemos problemas em fazer o upload dos seus arquivos.')
    } finally {
      setSubmitting(false)
    }
  }

  if (isLoading) return <LoadingScreen />

  return (
    <Container>
      <TitleContainer>
        <Title>
          Utilize essa página para fazer o upload de todas cédulas de crédito bancário ao mesmo tempo. Apenas arraste ou
          selecione todas ao clique para a caixa abaixo.
        </Title>
        <ArrowDown />
      </TitleContainer>
      <DragZone {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Selecione ou arraste todas as CCB's para cá. </p>
        <SelectedFiles>
          {acceptedFiles.length === 0
            ? 'Nenhum arquivo selecionado.'
            : `${acceptedFiles.length} arquivo(s) selecionado(s).`}
        </SelectedFiles>
      </DragZone>
      <TableContainer>
        <ButtonContainer>
          <MaterialButton
            width='300px'
            onClick={handleBankCreditCertificatesUpload}
            disabled={acceptedFiles.length === 0 || submitting}
            label={submitting ? <Spinner name='circle' color='steelblue' /> : 'Confirmar upload dos arquivos'}
            backgroundColor='#076f9f'
          />
        </ButtonContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadItem>Nome</TableHeadItem>
              <TableHeadItem>investor_id</TableHeadItem>
              <TableHeadItem>Já possui CCB</TableHeadItem>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map(order => (
              <TableRow key={order.id}>
                <TableData>
                  {order.pledges[0].investor.first_name} {order.pledges[0].investor.last_name}
                </TableData>
                <TableData>{order.pledges[0].investor.id}</TableData>
                <TableData>
                  {order.ccb && order.ccb.ccb_url ? (
                    <Checked />
                  ) : userFiles.includes(order.pledges[0].investor.id) ? (
                    'Pronto pra enviar.'
                  ) : (
                    'Não possui arquivo'
                  )}
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ButtonContainerFooter>
          <MaterialButton
            width='300px'
            onClick={handleBankCreditCertificatesUpload}
            disabled={acceptedFiles.length === 0 || submitting}
            label={submitting ? <Spinner name='circle' color='steelblue' /> : 'Confirmar upload dos arquivos'}
            backgroundColor='#076f9f'
          />
        </ButtonContainerFooter>
      </TableContainer>
    </Container>
  )
}

const ButtonContainer = styled.div`
  margin-top: 2em;
`

const ButtonContainerFooter = styled.div`
  margin-bottom: 2em;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Checked = styled(CheckCircle)`
  color: black;
`

const TableHead = styled.thead`
  background: #076f9f;
  color: white;
`

const TableBody = styled.tbody`
  tr:nth-of-type(even) {
    background: #076f9f;
    color: white;
  }
  tr:nth-of-type(odd) {
    background: #fafafa;
  }
`

const Table = styled.table`
  background: #fafafa;
  margin: 2em 0;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
`

const TableRow = styled.tr``

const TableHeadItem = styled.th`
  padding: 1em 2em;
`

const TableData = styled.td`
  padding: 0.7em 2em;
  text-align: center;
  min-width: 200px;
  height: 60px;
  border-top: 1px solid lightgray;
`

const ArrowDown = styled(KeyboardArrowDown)`
  font-size: 3em !important;
  color: #076f9f;
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1em;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-size: 1em;
  color: #076f9f;
  font-weight: 450;
`

const SelectedFiles = styled.p`
  padding-top: 1em;
  font-weight: bold;
`

const DragZone = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 60%;
  align-items: center;
  padding: 20px;
  background-color: #076f9f;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  color: white;
  outline: none;
  transition: border 0.24s ease-in-out;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 110px;
  align-items: center;
`
export default StyledDropzone
