import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'

import { CampaignContainer, LoadingScreen, CampaignUpdatesForm, Button } from '../../components'
import { Updates, Table, Update, TableHeader } from './style'

import { fetchCampaignUpdates } from '../../services'

const CampaignUpdates = ({ match }) => {
  const [loading, setLoading] = useState(true)
  const [updates, setUpdates] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [currentUpdate, setCurrentUpdate] = useState(null)

  const getUpdates = async () => {
    try {
      const response = await fetchCampaignUpdates(match.params.id)
      setUpdates(response.data)
      setLoading(false)
    } catch (error) {
      toast.error('Não foi possível buscar informações no momento')
      setLoading(false)
    }
  }

  useEffect(() => {
    getUpdates()
  }, [])

  const handleClick = update => {
    setCurrentUpdate(update)
    setShowForm(true)
  }

  const goToForm = () => {
    setShowForm(true)
  }

  const reload = () => {
    setCurrentUpdate(null)
    setShowForm(false)
    getUpdates()
  }

  if (loading) return <LoadingScreen />

  if (showForm) {
    return (
      <CampaignContainer step={7}>
        <CampaignUpdatesForm
          update={currentUpdate}
          campaignId={match.params.id}
          setLoading={setLoading}
          reload={reload}
        />
      </CampaignContainer>
    )
  }

  return (
    <CampaignContainer step={7}>
      <Updates>
        <Table>
          <TableHeader>
            <p>#</p>
            <p>Nome</p>
          </TableHeader>
          {updates.map(update => (
            <Update key={update.id} onClick={() => handleClick(update)}>
              <p>{moment(update.date).format('DD/MM/YYYY')}</p>
              <p>{update.title}</p>
            </Update>
          ))}
        </Table>
        <Button secondary label='Novo' onClick={goToForm} />
      </Updates>
    </CampaignContainer>
  )
}

export default CampaignUpdates
