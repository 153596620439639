import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Dropzone from 'react-dropzone'
import { UploadIcon } from '../../components'
import { getLinkToDownload } from '../../services/personalDocuments'
import { toast } from 'react-toastify'

const UploadPersonalDocument = ({ input, field, classes, setFieldValue }) => {
  const [withDocument, setWithDocument] = useState(false)
  const acceptedFiles = 'image/jpg,image/jpeg,image/png,application/pdf'
  const kvdId = input.document.kvd_id
  const documents = input.document

  const onDrop = async acceptedFiles => {
    if (acceptedFiles) {
      if (field.name === 'upload_personal_document_type_cr') {
        setFieldValue('upload_personal_document_type_cr', acceptedFiles[0])
        setFieldValue('personal_documents_type_cr', input.document.personal_document_type_cr)
      } else {
        setFieldValue('upload_personal_document_type_id', acceptedFiles[0])
        setFieldValue('personal_document_type_id', input.document.personal_document_type_id)
      }
    }
  }

  const showDocument = async doc => {
    try {
      const doc = { ...documents }
      const response = await getLinkToDownload(kvdId, doc.personal_document_type_id)
      return window.open(response.data, '_blank')
    } catch (e) {
      toast.error('Não foi possível abrir seu documento')
    }
  }
  const sendNewDocument = () => {
    setWithDocument(true)
  }

  return (
    <Grid item sm={input.col} xs={12}>
      <Grid>
        {!withDocument ? (
          <Grid className={classes.dropzone}>
            <p className={classes.textDropzone}>Você já enviou esse documento.</p>
            <Grid className={classes.buttonsDropzone}>
              <Grid container className={classes.containerButton}>
                <Fab variant='extended' aria-label='Add' className={classes.button} onClick={showDocument}>
                  VISUALIZAR DOCUMENTO
                </Fab>
              </Grid>

              <Grid container className={classes.containerButton}>
                <Fab variant='extended' aria-label='Add' className={classes.button} onClick={sendNewDocument}>
                  ENVIAR NOVO DOCUMENTO
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.containerDropzone}>
            <Dropzone onDrop={onDrop} activeStyle={classes.dropzone} multiple={false} accept={acceptedFiles}>
              {({ getRootProps, getInputProps, setFieldValue }) => (
                <section {...getRootProps({ className: classes.dropzone })}>
                  <div className={classes.iconUpload}>
                    <input name={input.name} {...getInputProps()} />
                    <UploadIcon />
                    <span className={classes.spanDropzone}>{input.text}</span>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default UploadPersonalDocument
