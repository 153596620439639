import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import * as yup from 'yup'

import { MaterialButton, Modal, Select } from '../../../components'

import { removeDelayFromInstallments } from '../../../services/v3'

const ModalRemoveInstallmentDelay = ({ isOpen, setIsOpen, campaign, installments, reload }) => {
  yup.setLocale({
    mixed: {
      required: 'Campo obrigatório'
    }
  })

  const validationSchema = yup.object().shape({
    installment_number: yup.string().required()
  })

  const handleRemoveDelayInstallment = async values => {
    try {
      const parcel = installments.find(
        installment => installment.installment_number === Number(values.installment_number)
      )
      await removeDelayFromInstallments(parcel.id)
      reload()
      toast.success('Atraso da parcela removido com sucesso.')
      setIsOpen(false)
    } catch (error) {
      toast.error('Ocorreu um erro ao remover atraso da parcela.')
    }
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalContent>
        <Title>Remover atraso da parcela</Title>
        <Formik
          initialValues={{
            installment_number: ''
          }}
          onSubmit={handleRemoveDelayInstallment}
          validationSchema={validationSchema}
        >
          {({ errors }) => (
            <Form>
              <InputContainer>
                <InputRowContainer>
                  <InputContent>
                    <Field
                      name='installment_number'
                      render={({ field }) => (
                        <Select label='Nº Parcela' field={field}>
                          <option value='' disabled />
                          {installments
                            .filter(({ installment_status }) => installment_status.abbreviation === 'O')
                            .filter(({ transfer_date_delayed }) => !!transfer_date_delayed)
                            .map(({ installment_number, expire_date }) => (
                              <option key={installment_number} value={installment_number}>
                                {installment_number} ({moment.utc(expire_date).format('DD/MM/YYYY')})
                              </option>
                            ))}
                        </Select>
                      )}
                    />
                    <Error>{errors.installment_number}</Error>
                  </InputContent>
                </InputRowContainer>
              </InputContainer>
              <MaterialButton type='submit' label='Remover' />
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
`
const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 10px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContent = styled.div`
  width: 200px;
  padding: 0 10px 0 0;
`

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`
export default ModalRemoveInstallmentDelay
