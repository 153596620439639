import styled from 'styled-components'

export const Main = styled.div`
  display: none;

  @media (max-width: 730px) {
    display: block;
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
`

export const ContainerCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 120px;
  grid-template-areas:
    'date value'
    'amorti tax'
    'ir ir'
    'description description'
    'parcel parcel';

  border-radius: 4px;
  padding: 16px;
  background: ${({ color }) => (color % 2 === 0 ? '#FAFAFA' : '#FFFFFF')};

  @media (max-width: 960px) {
    gap: 24px 60px;
  }

  @media (max-width: 840px) {
    gap: 24px 40px;
  }

  @media (max-width: 370px) {
    gap: 24px 0px;
  }

  div {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #424242;
    }
  }
`
export const Detail = styled.div`
  h4 {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }
`

export const FilterContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 1100px) {
    display: block;
  }
`
