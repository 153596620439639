import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import CloseIcon from '@material-ui/icons/Close'

import { MaterialButton, Modal } from '../'

import { updateCampaign, getCampaignStatuses } from '../../services/v3'
import { campaignStatuses as campaignStatusesAbbreviation } from '../../constants'

const ModalReopenCampaign = ({ isOpen, setIsOpen, campaignId, reload }) => {
  const [loading, setLoading] = useState(false)

  const handleReopen = async () => {
    try {
      setLoading(true)
      const { data: campaignStatuses } = await getCampaignStatuses()
      const newStatus = campaignStatuses.find(
        ({ abbreviation }) => abbreviation === campaignStatusesAbbreviation.ACTIVE
      )
      await updateCampaign(campaignId, { campaign_status_id: newStatus.id })
      toast.success('Campanha reaberta com sucesso')
      reload()
      setIsOpen(false)
    } catch (error) {
      console.log('Ocorreu um erro ao reabrir a campanha')
      toast.error('Ocorreu um erro ao reabrir a campanha')
      setLoading(false)
    }
  }

  const closeModal = () => setIsOpen(false)

  return (
    <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalContainer>
        <CloseIconContainer>
          <CloseIconStyled onClick={closeModal} />
        </CloseIconContainer>
        <Title>Mudar status da campanha?</Title>
        <Text>Esta ação fará com que o valor captado seja inferior a meta de captação. Você deseja?</Text>
        <ButtonContainer>
          <MaterialButton label='Reabrir a campanha' onClick={handleReopen} loading={loading} />
          <MaterialButton label='Manter em lista de espera' onClick={closeModal} />
        </ButtonContainer>
      </ModalContainer>
    </StyledModal>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
`

const CloseIconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: -20px;
  margin-right: -40px;
`

const StyledModal = styled(Modal)`
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 580px;
  padding: 10px;
`

const Title = styled.p`
  font-size: 28px;
  color: #076f9f;
  font-weight: bold;
  margin-bottom: 35px;
  width: 100%;
  text-align: center;
`

const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`

export default ModalReopenCampaign
