import React from 'react'
import CurrencyInputComponent from 'react-currency-input'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

const PercentageInput = ({ percentage, ...props }) => {
  return <Input decimalSeparator=',' thousandSeparator='.' suffix=' %' onChangeEvent={props.onChange} {...props} />
}

const Input = styled(CurrencyInputComponent)`
  ${({ disabled }) => `
    border-radius: 4px;
    height: 40px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    outline: none;
    border: 1px solid gray;
    padding: 0 10px;
    background: ${disabled ? 'rgb(235, 235, 228)' : ''};
  `}
  &:focus {
    border: 1px solid #076f9f;
  }
`

export default withTheme(PercentageInput)
