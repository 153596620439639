import styled from 'styled-components'
import { colors } from '../../../../../Themes/colors'

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    color: ${colors.blue};
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
