import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import useDevice from '../../../../../hooks/useDevice'

import { ModalBase, Text, Column, Row, Button } from '../../../index'

import { deleteAllLeadDocumentsRefuseds, updateLeads } from '../../../../../services/v3'

const ModalInitCredit = ({ open, handleClose, fetchInfos }) => {
  const { isMobile } = useDevice()
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm()

  const onSubmit = async () => {
    try {
      await deleteAllLeadDocumentsRefuseds(open.id)
      await updateLeads(open.id, { lead_status_id: 4, is_visible_to_partner: true })
      fetchInfos()
      toast.success('Sucesso.')
      document.location.reload(true)
    } catch {
      toast.error('Erro ao ir para analise  documento.')
    } finally {
      handleClose()
    }
  }

  return (
    <ModalBase open={!!open ? true : false} onClose={handleClose} width={isMobile ? 300 : 526} title='Documento'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column justifyContent='space-around' style={{ gap: '20px' }}>
          <Text variant='h4'>Você tem certeza que deseja ir para análise de crédito?</Text>
          <Row>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting}>
              Enviar
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalInitCredit
