import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'

import {
  originLeads,
  fetchAllEmployees,
  getPartners,
  requestLeads,
  getAddressByCep,
  getCompanyInfos,
  GetOneLead,
  getPartner
} from '../../services/v3'
import { states } from '../../constants'
import { formatCurrencyToBack } from '../../helpers'
import { newCreditRequestSchema } from '../../schemas'
import { useDevice } from '../../hooks'

import LoadingComponent from '../../components/LoadingComponent'
import { Row, Column, Text, Breadcrumb, Radio, Checkbox, Button } from '../../components/v2Components'

import InternalData from '../../components/v2Components/FormComponents/InternalData'
import ContactData from '../../components/v2Components/FormComponents/ContactData'
import FranchiseData from '../../components/v2Components/FormComponents/FranchiseData'
import CompanyDataCompleted from '../../components/v2Components/FormComponents/CompanyDataCompleted'
import CompanyDataIncompleted from '../../components/v2Components/FormComponents/CompanyDataIncompleted'

import { getLeadsIndicators } from '../../services/v3'

import { ButtonContainer } from './styles'

const CreditRequestForm = () => {
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [partners, setPartner] = useState([])
  const [leadOriginOptions, setLeadOriginOptions] = useState([])
  const { isMobile, isTablet } = useDevice()

  const [indicatorsOptions, setIndicatorsOptions] = useState([])

  const { control, watch, handleSubmit, errors, setValue } = useForm({
    resolver: newCreditRequestSchema,
    defaultValues: {
      street: '',
      city: '',
      district: '',
      company_name: '',
      indicated_by: '',
      first_name: '',
      last_name: '',
      celphone: '',
      email: '',
      telephone: ''
    },
    shouldUnregister: false
  })

  const bussinessType = watch('company_type')
  const franchisorType = watch('franchise_type')
  const cnpj = watch('have_cnpj')
  const valueOrigin = watch('lead_origin_id')
  const zipCode = watch('zip_code')
  const cnpjValue = watch('cnpj')
  const indicateBy = watch('indicated_by')

  const data = [
    { label: 'Solicitação de crédito', path: '/solicitacoes-de-credito' },
    { label: 'Adicionar solicitação', path: '/formulario-solicitacao-credito' }
  ]

  const handleContactData = useCallback(async () => {
    if (!!indicateBy.value) {
      try {
        const { data: user } = await getPartner(Number(indicateBy.value))

        if (!user) {
          throw Error()
        }

        if (!!user.first_name) {
          setValue('first_name', user.first_name)
        }

        if (!!user.last_name) {
          setValue('last_name', user.last_name)
        }

        if (!!user.celphone && !!user.celphone_prefix) {
          const celphone = user.celphone_prefix + user.celphone
          setValue('celphone', celphone)
        }

        if (!!user.user.email) {
          setValue('email', user.user.email)
        }

        if (!!user.telephone && user.telephone_prefix) {
          const telephone = user.telephone_prefix + user.telephone
          setValue('telephone', telephone)
        }
      } catch (error) {
        console.log(error, error.message)
        toast.error('Não foi possível obter as informações do usuário.')
      }
    }
  }, [setValue, indicateBy])

  const changeCheckbox = useCallback(
    event => {
      if (event.target.checked) {
        handleContactData()
        return
      }
      setValue('first_name', '')
      setValue('last_name', '')
      setValue('celphone', '')
      setValue('email', '')
      setValue('telephone', '')
    },
    [handleContactData, setValue]
  )

  const handleCompanyAddress = useCallback(
    async cep => {
      try {
        const companyAddress = await getAddressByCep(cep)
        setValue('city', companyAddress.localidade)
        setValue('uf', companyAddress.uf)
        setValue('street', companyAddress.logradouro)
        setValue('district', companyAddress.bairro)
      } catch (error) {
        if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('Erro a buscar CEP')
        }
      }
    },
    [setValue]
  )

  const handleCompanyName = useCallback(
    async cnpj => {
      try {
        const companyInfo = await getCompanyInfos(cnpj)
        setValue('company_name', companyInfo.nome)
      } catch (error) {
        if (error.message === 'Erro ao buscar o CNPJ: undefined.') {
          toast.error('Erro ao buscar CNPJ')
        } else {
          toast.error(error.message)
        }
      }
    },
    [setValue]
  )

  const onSubmit = useCallback(async data => {
    try {
      const celphone = data.celphone.replace(/\D/g, '')
      const telephone = data.telephone ? data.telephone.replace(/\D/g, '') : ''

      if (!data.indicated_by) {
        data.indicated_by = { value: '', label: '' }
      }

      if (data.company_type === 'Geral') {
        data.franchise_type = undefined
      }
      const body = {
        lead_origin_id: Number(data.lead_origin_id),
        indicated_by: data.indicated_by.label,
        responsible: data.responsible,
        first_name: data.first_name,
        last_name: data.last_name,
        celphone: celphone.slice(2),
        celphone_prefix: celphone.slice(0, 2),
        telephone: telephone.slice(2),
        telephone_prefix: telephone.slice(0, 2),
        email: data.email,
        company_type: data.company_type,
        franchisor: data.franchisor,
        franchise_type: data.franchise_type,
        franchisor_name: data.franchisor_name,
        franchise_description: data.franchise_description,
        street: data.street,
        number: data.number,
        zip_code: data.zip_code,
        city: data.city,
        complement: data.complement,
        district: data.district,
        uf: data.uf,
        cnpj: data.cnpj ? data.cnpj.replace(/[^\d]+/g, '') : '',
        company_name: data.company_name,
        company_site: data.company_site,
        company_history: data.company_history,
        average_monthly_revenue: formatCurrencyToBack(data.average_monthly_revenue),
        desired_amount: formatCurrencyToBack(data.desired_amount),
        lending_reason: data.lending_reason,
        partner_id: Number(data.lead_origin_id) === 3 ? data.indicated_by.value : null
      }

      if (data.franchise_type === 'Nova' && !data.have_cnpj) {
        const bodyNewFranchise = {
          lead_origin_id: Number(data.lead_origin_id),
          indicated_by: data.indicated_by.label,
          responsible: data.responsible,
          average_monthly_revenue: formatCurrencyToBack(data.average_monthly_revenue),
          celphone: celphone.slice(2),
          celphone_prefix: celphone.slice(0, 2),
          city: undefined,
          cnpj: data.have_cnpj ? (data.cnpj ? data.cnpj.replace(/[^\d]+/g, '') : '') : '',
          company_history: data.company_history,
          company_name: data.company_name,
          company_site: undefined,
          company_type: data.company_type,
          complement: undefined,
          desired_amount: formatCurrencyToBack(data.desired_amount),
          district: undefined,
          email: data.email,
          first_name: data.first_name,
          franchise_description: data.franchise_description,
          franchise_type: data.franchise_type,
          franchisor: data.franchisor,
          franchisor_name: data.franchisor_name,
          last_name: data.last_name,
          lending_reason: data.lending_reason,
          number: undefined,
          street: undefined,
          telephone: telephone.slice(2),
          telephone_prefix: telephone.slice(0, 2),
          uf: undefined,
          zip_code: undefined,
          partner_id: Number(data.lead_origin_id) === 3 ? data.indicated_by.value : null
        }
        await requestLeads(bodyNewFranchise)
        toast.success('Solicitação de crédito cadastrada com sucesso.')
        history.push('/solicitacoes-de-credito')
      } else {
        await requestLeads(body)
        toast.success('Solicitação de crédito cadastrada com sucesso.')
        history.push('/solicitacoes-de-credito')
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar a solicitação de crédito.')
    }
  }, [])

  useEffect(() => {
    if (!zipCode) return

    const sanitizedZipCode = zipCode.replace(/\D/g, '')
    if (sanitizedZipCode.length === 8) {
      handleCompanyAddress(sanitizedZipCode)
    }
  }, [zipCode, handleCompanyAddress])

  useEffect(() => {
    if (!cnpjValue) return

    const sanitizedCnpj = cnpjValue.replace(/\D/g, '')

    if (sanitizedCnpj.length === 14) {
      handleCompanyName(sanitizedCnpj)
    }
  }, [cnpjValue, handleCompanyName])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        if (!!query.get('id')) {
          const { data } = await GetOneLead(Number(query.get('id')))
          for (var item in data) {
            if (data[item] === null) {
              data[item] = undefined
            }
          }

          setValue('lead_origin_id', data.lead_origin.id)
          setValue('indicated_by', data.indicated_by)
          setValue('responsible', data.responsible)
          setValue('first_name', data.first_name)
          setValue('last_name', data.last_name)
          setValue('email', data.email)
          setValue('celphone', `${data.celphone_prefix}${data.celphone}`)
          setValue('telephone', `${data.telephone_prefix}${data.telephone}`)
          setValue('company_type', data.company_type)
          setValue('franchisor', !!data.franchisor)
          setValue('have_cnpj', data.cnpj.length > 4 && data.company_type === 'Franquia' ? true : false)
          setValue('company_type', data.company_type)
          setValue('franchise_type', data.franchise_type)
          setValue('franchisor_name', data.franchisor_name)
          setValue('franchise_description', data.franchise_description)
          setValue('cnpj', data.cnpj)
          setValue('company_name', data.company_name)
          setValue('company_site', data.company_site ? data.company_site : '')
          setValue('zip_code', data.zip_code)
          setValue('street', data.street)
          setValue('number', data.number)
          setValue('complement', !!data.complement ? data.complement : '')
          setValue('city', data.city)
          setValue('district', data.district)
          setValue('uf', data.uf)
          setValue('company_history', data.company_history)
          setValue('cnpj', data.cnpj)
        }

        const { data: employees } = await fetchAllEmployees({ pageSize: 1000 })
        setOptions(
          employees.employees.map(({ first_name, last_name }) => ({
            value: `${first_name} ${last_name}`,
            label: `${first_name} ${last_name}`
          }))
        )
        const { data: partners } = await getPartners()
        setPartner(
          partners
            .map(({ first_name, last_name, id }) => ({
              value: id,
              label: `${first_name} ${last_name}`
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
        const { data: origins } = await originLeads()
        setLeadOriginOptions(origins)

        const { data: indicatorsInfo } = await getLeadsIndicators()

        setIndicatorsOptions(
          indicatorsInfo.leads
            .map(({ indicated_by }) => ({ value: indicated_by, label: indicated_by }))
            .sort((a, b) => a.value.localeCompare(b.value))
        )
      } catch {
        toast.error('Erro ao carregar a página')
      } finally {
        setLoading(false)
      }
    }
    fetchInfos()
  }, [loading])

  if (loading) return <LoadingComponent />

  if (isMobile || isTablet)
    return (
      <Column mt={13} mb={13} mx={2}>
        <Breadcrumb data={data} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row justifyContent='flex-start' mt={3} mb={4}>
            <Text variant='h3'>Adicionar solicitação</Text>
          </Row>
          <InternalData
            control={control}
            isMobile={isMobile}
            isTablet={isTablet}
            leadOriginOptions={leadOriginOptions}
            options={options}
            partners={partners}
            errors={errors}
            valueOrigin={valueOrigin}
            indi={indicatorsOptions}
          />
          <ContactData
            control={control}
            isMobile={isMobile}
            isTablet={isTablet}
            errors={errors}
            valueOrigin={valueOrigin}
            changeCheckbox={changeCheckbox}
            indicateBy={indicateBy}
          />
          <Text variant='h4' mt={5}>
            Qual empresa você está indicando
          </Text>
          <Radio
            name='company_type'
            control={control}
            error={errors.company_type}
            mt={5}
            options={[
              {
                value: 'Franquia',
                label: 'Franquias'
              },
              {
                value: 'Geral',
                label: 'Empresas em geral'
              }
            ]}
          />
          {bussinessType === 'Franquia' && (
            <Column>
              <Text variant='h4' mt={5}>
                Franqueador
              </Text>
              <Checkbox name='franchisor' control={control} label='Sou o franqueador' mt={5} />
              <Text variant='h4' mt={5}>
                {' '}
                Tipo de franquia{' '}
              </Text>
              <Radio
                name='franchise_type'
                mt={5}
                control={control}
                options={[
                  {
                    value: 'Existente',
                    label: 'Unidade em operação'
                  },
                  {
                    value: 'Nova',
                    label: 'Nova loja'
                  }
                ]}
              />
            </Column>
          )}
          {franchisorType === 'Existente' && bussinessType === 'Franquia' && (
            <Column>
              <FranchiseData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
              <CompanyDataCompleted
                control={control}
                isMobile={isMobile}
                isTablet={isTablet}
                errors={errors}
                states={states}
              />
              <Button mt={5} width='100%' type='submit'>
                Adicionar Solicitação
              </Button>
            </Column>
          )}
          {franchisorType === 'Nova' && bussinessType === 'Franquia' && (
            <Column>
              <FranchiseData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
              <Checkbox mt={5} name='have_cnpj' control={control} label='Já possuo CNPJ' defaultValues={true} />
            </Column>
          )}
          {franchisorType === 'Nova' && bussinessType === 'Franquia' && !cnpj && (
            <Column>
              <CompanyDataIncompleted control={control} errors={errors} isMobile={isMobile} isTablet={isTablet} />
              <Button mt={5} width='100%' type='submit'>
                {' '}
                Adicionar Solicitação
              </Button>
            </Column>
          )}
          {franchisorType === 'Nova' && bussinessType === 'Franquia' && cnpj && (
            <Column>
              <Checkbox name='have_pj_count' control={control} label='Já possuo conta PJ aberta' mt={5} />
              <CompanyDataCompleted
                control={control}
                isMobile={isMobile}
                isTablet={isTablet}
                errors={errors}
                states={states}
              />
              <Button mt={5} width='100%' type='submit'>
                {' '}
                Adicionar Solicitação
              </Button>
            </Column>
          )}
          {bussinessType === 'Geral' && (
            <Column>
              <CompanyDataCompleted
                control={control}
                isMobile={isMobile}
                isTablet={isTablet}
                errors={errors}
                states={states}
              />
              <Button mt={5} width='100%' type='submit'>
                Adicionar Solicitação
              </Button>
            </Column>
          )}
        </form>
      </Column>
    )

  return (
    <Column mx={30.5} mt={13} mb={13}>
      <Breadcrumb data={data} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row justifyContent='flex-start' mt={3} mb={4}>
          <Text variant='h3'>Adicionar solicitação</Text>
        </Row>
        <InternalData
          control={control}
          isMobile={isMobile}
          isTablet={isTablet}
          leadOriginOptions={leadOriginOptions}
          options={options}
          partners={partners}
          errors={errors}
          valueOrigin={valueOrigin}
          indi={indicatorsOptions}
        />
        <ContactData
          control={control}
          isMobile={isMobile}
          isTablet={isTablet}
          errors={errors}
          valueOrigin={valueOrigin}
          changeCheckbox={changeCheckbox}
          indicateBy={indicateBy}
        />
        <Row mt={5}>
          <Text variant='h4'>Qual empresa você está indicando</Text>
        </Row>
        <Row mt={5}>
          <Radio
            name='company_type'
            control={control}
            error={errors.company_type}
            options={[
              {
                value: 'Franquia',
                label: 'Franquias'
              },
              {
                value: 'Geral',
                label: 'Empresas em geral'
              }
            ]}
          />
        </Row>
        {bussinessType === 'Franquia' && (
          <Column>
            <Row>
              <Text variant='h4' mt={5}>
                Franqueador
              </Text>
            </Row>
            <Row mt={3}>
              <Checkbox name='franchisor' control={control} label='Sou o franqueador' />
            </Row>
            <Row mt={5}>
              <Text variant='h4'> Tipo de franquia </Text>
            </Row>
            <Row mt={5}>
              <Radio
                name='franchise_type'
                control={control}
                options={[
                  {
                    value: 'Existente',
                    label: 'Unidade em operação'
                  },
                  {
                    value: 'Nova',
                    label: 'Nova loja'
                  }
                ]}
              />
            </Row>
          </Column>
        )}
        {franchisorType === 'Existente' && bussinessType === 'Franquia' && (
          <Column>
            <FranchiseData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
            <CompanyDataCompleted
              control={control}
              isMobile={isMobile}
              isTablet={isTablet}
              errors={errors}
              states={states}
            />
            <Row mt={5}>
              <ButtonContainer>
                <Button width='45%' type='submit'>
                  {' '}
                  Adicionar Solicitação
                </Button>
              </ButtonContainer>
            </Row>
          </Column>
        )}
        {franchisorType === 'Nova' && bussinessType === 'Franquia' && (
          <Column>
            <FranchiseData control={control} isMobile={isMobile} isTablet={isTablet} errors={errors} />
            <Row mt={5}>
              <Checkbox name='have_cnpj' control={control} label='Já possuo CNPJ' defaultValues={true} />
            </Row>
          </Column>
        )}
        {franchisorType === 'Nova' && bussinessType === 'Franquia' && !cnpj && (
          <Column>
            <CompanyDataIncompleted control={control} errors={errors} isMobile={isMobile} isTablet={isTablet} />
            <Row mt={5}>
              <ButtonContainer>
                <Button width='45%' type='submit'>
                  {' '}
                  Adicionar Solicitação
                </Button>
              </ButtonContainer>
            </Row>
          </Column>
        )}
        {franchisorType === 'Nova' && bussinessType === 'Franquia' && cnpj && (
          <Column>
            <Row mt={3}>
              <Checkbox name='have_pj_count' control={control} label='Já possuo conta PJ aberta' />
            </Row>
            <CompanyDataCompleted
              control={control}
              isMobile={isMobile}
              isTablet={isTablet}
              errors={errors}
              states={states}
            />
            <Row mt={5}>
              <Button width='45%' type='submit'>
                {' '}
                Adicionar Solicitação
              </Button>
            </Row>
          </Column>
        )}
        {bussinessType === 'Geral' && (
          <Column>
            <CompanyDataCompleted
              control={control}
              isMobile={isMobile}
              isTablet={isTablet}
              errors={errors}
              states={states}
            />
            <Row mt={5}>
              <ButtonContainer>
                <Button width='45%' type='submit'>
                  {' '}
                  Adicionar Solicitação
                </Button>
              </ButtonContainer>
            </Row>
          </Column>
        )}
      </form>
    </Column>
  )
}

export default CreditRequestForm
