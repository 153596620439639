import client from '../providers/fetchClient'

export const getCampaign = campaignID => client.get(`/v2/campaigns/${campaignID}`)

export const getCampaigns = _ => client.get('/v2/campaigns/')

export const filteredCampaigns = (filter, value) => client.get(`/v2/campaigns?${filter}=${value}`)

export const updateCampaign = campaign => client.put(`/v2/campaigns/${campaign.id}`, campaign)

export const deleteCampaign = campaignID => client.delete(`/v2/campaigns/${campaignID}`)

export const getCampaignOrders = campaignId => client.get(`/v2/campaigns/${campaignId}/ccb-orders`)
// export const getCampaignOrders = campaignId => client.get(`/bank-credit-certificate/campaign_id/${campaignId}`)

export const getCampaignsFlow = _ => client.get('/v2/campaigns-flow')

export const getCampaignFlow = campaignID => client.get(`/v2/campaigns-flow/${campaignID}`)

export const updateCampaignsFlow = (id, campaign) => client.put(`/v2/campaigns-flow/${id}`, campaign)

export const getCampaignPhoto = id => client.get(`/campaign-photos/campaign_id/${id}?stt_id=A&rol_id=B&exactmatch=true`)

export const updateCampaignPhoto = data =>
  client.put('/campaign-photos/', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

export const getCampaignGallery = id =>
  client.get(`/campaign-photos/campaign_id/${id}?stt_id=A&rol_id=GL&exactmatch=true`)

export const deleteCampaignGallery = data => client.put('/campaign-photos/update', data)

export const createCampaignGallery = data =>
  client.put('/campaign-photos/', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

export const updateCampaignGallery = data => client.put('/campaign-photos/update', data)

export const fetchCampaignUpdate = id => client.get(`/campaign-update/id/${id}?stt_id=A&exactmatch=true`)

export const updateCampaignUpdate = data => client.put('/campaign-update/', { data })

export const createCampaignUpdate = data => client.post('/campaign-update/', { data })

export const fetchCampaignUpdates = campaignId =>
  client.get(`/campaign-update/campaign_id/${campaignId}?stt_id=A&exactmatch=true`)

export const runCampaign = (campaignId, data) => client.post(`/bmp/run-campaign/${campaignId}`, data)

export const processPledges = campaign => client.post('/process-orders', campaign)

export const processCampaignExtraFields = campaignID => client.get(`/bmp/extra-fields/${campaignID}`)

export const getCampaignUuid = campaignID => client.get(`v2/bmp-campaign/${campaignID}`)

export const getCampaignUsersOrders = campaignID => client.get(`/v2/campaigns/users/${campaignID}`)

export const generateSignatures = (campaignUuid, data) => client.post(`/bmp/ccb-sign-to-bmp/${campaignUuid}`, data)

export const getCampaignOperationsCode = campaignUuid => client.get(`/bmp/operation-code/${campaignUuid}`)

export const getCampaignResponse = campaignID => client.get(`/bmp/campaign-response/${campaignID}`)

export const getBccSigning = campaignID => client.get(`/v2/bmp-sign/${campaignID}`)

export const getInstallmentsValues = campaignID => client.get(`/bmp/installment-value/${campaignID}`)

export const testInstallment = data => client.post('/bmp/test-installment', data)

export const updateInstallmentsValue = installments => client.post('/bmp/installment-value', installments)

export const changeCampaignProposalsStatus = campaignId => client.get(`/bmp/change-status/${campaignId}`)

export const getRunCampaignStatus = campaignId => client.get('/v2/run-campaign-status')
