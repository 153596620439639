import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const Loading = () => <Loader src='/assets/icons/spinner.svg' />

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.img`
  width: 40px;
  animation: ${rotate} 1s linear infinite;
`

export default Loading
