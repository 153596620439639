import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalBase, Text, Column, Row, Button } from '../../../../components/v2Components'

import { deleteFQS } from '../../../../services/v3'

const ModalDeleteFQS = ({ open, handleClose }) => {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm()

  const onSubmit = async () => {
    try {
      await deleteFQS(open.id).then(() => {
        toast.success('Pergunta deletado com sucesso.')
      })
    } catch {
      toast.error('Erro ao deletar a pergunta.')
    } finally {
      handleClose()
      window.location.reload()
    }
  }

  return (
    <ModalBase open={!!open ? true : false} onClose={handleClose} height='100%' width='100%' title='Documento'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column justifyContent='space-around' style={{ gap: '20px' }}>
          <Text variant='h4'>Você tem certeza que deseja deletar esta pergunta?</Text>

          <Row>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting}>
              Sim
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalDeleteFQS
