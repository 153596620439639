import React from 'react'
import { toast } from 'react-toastify'

import { ModalBase, Column, Row, Button, Text } from '../index'

import { deleteEmployee } from '../../../services/v3'
import useDevice from '../../../hooks/useDevice'

const ModalDeleteEmployee = ({open, onClose, id, loading}) => {
  const { isMobile } = useDevice()

  const deleteContributor = async id => {
    try{
      await deleteEmployee(id)
      toast.success('Funcionário deletado com sucesso.')
      loading()
      onClose()
    }catch{
      toast.error('Erro ao deletar funcionário.')
    }
  }
  return(
    <ModalBase title='Excluir funcionário' iconClose open={open} onClose={onClose} width={isMobile ? 300 : 526} height={isMobile ? 200 : 174 }>
      <Column>
        <Text variant='subtitle1'>Tem certeza que deseja excluir este funcionário?</Text>
        <Row mt={4}>
          <Button variant='outlined' width={227} onClick={onClose} mr={3}> Cancelar</Button>
          <Button width={227} onClick={() => deleteContributor(id)}>Excluir</Button>
        </Row>
      </Column>
    </ModalBase>
  )
}

export default ModalDeleteEmployee
