import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'
import styled from '@emotion/styled'

import { getUsers } from '../../services/v3'

import './style.css'

const AutosuggestInput = props => {
  const [value, setValue] = useState(props.value ? props.value : '')
  const [suggestions, setSuggestions] = useState([])

  const onChange = (_, { newValue }) => {
    setValue(newValue)
  }

  const renderInputComponent = ({ ref, ...inputProps }) => (
    <WrapperInputComponent>
      <Label>{props.label}</Label>
      <StyledInput ref={ref} {...inputProps} name={props.name} />
    </WrapperInputComponent>
  )

  const getSuggestionValue = suggestion => {
    props.setInvestorId(suggestion.id)

    switch (props.suggestion) {
      case 'email':
        return suggestion.email
      case 'document_number':
        return suggestion.document_number
      case 'name':
      default:
        return `${suggestion.first_name} ${suggestion.last_name}`
    }
  }

  const renderSuggestion = suggestion => {
    switch (props.suggestion) {
      case 'email':
        return <Dropdown>{suggestion.email}</Dropdown>
      case 'document_number':
        return (
          <Dropdown>{`${suggestion.document_number} - ${suggestion.first_name} ${suggestion.last_name}`} </Dropdown>
        )
      case 'name':
      default:
        return <Dropdown>{`${suggestion.first_name} ${suggestion.last_name}`}</Dropdown>
    }
  }

  const onSuggestionsFetchRequested = async ({ value }) => {
    setSuggestions(await getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestions = async value => {
    const inputValue = value.trim().toLowerCase()

    if (inputValue.length < 3) return []

    const { data: users } = await getUsers({ [props.suggestion]: inputValue })

    if (!users || users.length === 0) return []
    return users.map(user => ({
      ...user,
      ...(user.signer ? user.signer : null),
      ...(user.investor ? user.investor : null)
    }))
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        placeholder: props.placeholder,
        value,
        onChange: onChange,
        type: 'text',
        autocomplete: 'new-password'
      }}
      renderInputComponent={renderInputComponent}
      focusInputOnSuggestionClick={false}
    />
  )
}

const Dropdown = styled.div`
  padding: 0.7em 2em;
  border: 1px solid #ddd;
  white-space: nowrap;
  background: white;
  &:hover {
    background: #eee;
  }
  &:focus {
    background: #eee;
  }
`

const WrapperInputComponent = styled.div`
  padding: 4.8px;
`

const StyledInput = styled.input`
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  outline: none;
  padding: 0px 10px;
  background: '#ddd';
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.blueKavod};
    color: #000;
    text-shadow: none;
  }
  @media only screen and (max-width: 500px) {
    height: 32px;
  }
`

const Label = styled.label`
  padding: 0px 8px;
  transition: all 0.3s;
  white-space: nowrap;
  color: #7f7f7f;
`

export default AutosuggestInput
