import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment-timezone'

import { LoadingComponent, MaterialButton } from '../../components'
import { Select, NumberInput, DatePicker } from '../../components/FormComponents'
import Resume from './Resume'

import {
  getCampaignById,
  getCampaignInstallments,
  anticipationAllInstallments,
  anticipationOneInstallment
} from '../../services/v3'
import {
  anticipationTypes,
  anticipationOptions,
  anticipationFormDefaultValues,
  installmentStatuses
} from '../../constants'
import { IDLE, FETCHING, SUBMITTING } from '../../constants/status'
import { anticipationSchema } from '../../schemas'
import { formatPercentageToBack } from '../../helpers'

import { Title, Row, Container } from './style'

const Anticipation = ({ match }) => {
  const [status, setStatus] = useState(FETCHING)
  const [openInstallments, setOpenInstallments] = useState([])
  const [resume, setResume] = useState({})

  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(anticipationSchema),
    mode: 'onChange',
    defaultValues: anticipationFormDefaultValues
  })

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setStatus(FETCHING)

        const [{ data: campaign }, { data: installments }] = await Promise.all([
          getCampaignById(match.params.id),
          getCampaignInstallments({ campaign_id: match.params.id })
        ])

        setOpenInstallments(
          installments
            .filter(({ installment_status }) => installment_status.abbreviation === installmentStatuses.OPEN)
            .sort((a, b) => a.installment_number - b.installment_number)
        )

        reset({
          operation_tax: campaign.rate_month,
          anticipation_date: null
        })
      } catch (error) {
        console.error(error)
        toast.error('Erro ao buscar os dados da campanha')
      } finally {
        setStatus(IDLE)
      }
    }
    fetchCampaign()
  }, [match.params.id])

  const onSubmit = async values => {
    try {
      setStatus(SUBMITTING)
      switch (values.type) {
        case anticipationTypes.ONE_INSTALLMENT:
          const paramsOne = {
            liquidation_date: moment(new Date(values.anticipation_date)).format('YYYY-MM-DD'),
            tax_fine: formatPercentageToBack(values.tax_fine),
            tax_default_interest: formatPercentageToBack(values.tax_default_interest),
            is_simulation: true
          }
          const { data: simulationOne } = await anticipationOneInstallment(Number(values.installment_id), paramsOne)
          setResume({
            type: anticipationTypes.ONE_INSTALLMENT,
            params: paramsOne,
            simulation: simulationOne,
            installment_number: Number(values.installment_id)
          })
          break
        case anticipationTypes.ALL_INSTALLMENTS:
          const installment = openInstallments.find(({ id }) => id === Number(values.installment_id))
          if (!installment) throw new Error('Erro ao buscar a parcela inicial na antecipação')
          const paramsAll = {
            liquidation_date: moment(new Date(values.anticipation_date)).format('YYYY-MM-DD'),
            tax_fine: formatPercentageToBack(values.tax_fine),
            tax_default_interest: formatPercentageToBack(values.tax_default_interest),
            campaign_id: Number(match.params.id),
            installment_number: installment.installment_number,
            is_simulation: true
          }
          const { data: simulationAll } = await anticipationAllInstallments(paramsAll)
          setResume({
            type: anticipationTypes.ALL_INSTALLMENTS,
            params: paramsAll,
            simulation: simulationAll
          })
          break
        default:
          throw new Error('Erro ao identificar o tipo da antecipação')
      }
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao realizar a renegociação da campanha: ' + error.message)
    } finally {
      setStatus(IDLE)
    }
  }

  if (status === FETCHING) {
    return (
      <Container>
        <LoadingComponent />
      </Container>
    )
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Cálculo antecipação</Title>
        <Row>
          <NumberInput
            name='operation_tax'
            control={control}
            label='Taxa operação *'
            error={errors['operation_tax']}
            disabled
            suffix=' %'
          />
          <NumberInput
            name='tax_default_interest'
            control={control}
            label='Mora *'
            error={errors['tax_default_interest']}
            suffix=' %'
          />
          <NumberInput name='tax_fine' control={control} label='Multa *' error={errors['tax_fine']} suffix=' %' />
          <DatePicker
            name='anticipation_date'
            control={control}
            label='Data liquidação *'
            error={errors['anticipation_date']}
          />
          <Select name='type' control={control} label='Tipo *' error={errors['type']} options={anticipationOptions} />
          <Select
            name='installment_id'
            control={control}
            label='Parcela *'
            error={errors['installment_id']}
            options={openInstallments.map(({ id, installment_number, transfer_date }) => ({
              value: id,
              label: `${installment_number} (${moment.utc(transfer_date).format('DD/MM/YYYY')})`
            }))}
          />
        </Row>
        <Row>
          <MaterialButton loading={status === SUBMITTING} type='submit' label='Calcular' width='220px' />
        </Row>
      </form>
      {Object.keys(resume).length > 0 && <Resume infos={resume} />}
    </Container>
  )
}

export default Anticipation
