import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '../index'
import useDevice from '../../../hooks/useDevice'
import { StyledBreadcrumb, StyledLink as Link, Container } from './styles'

const Breadcrumb = ({ data, ...props }) => {
  const { isDesktop } = useDevice()

  if (!isDesktop) {
    return <Link to={`${data[data.length - 2].path}`} {...props}>{` ‹ ${data[data.length - 2].label}`}</Link>
  }

  return (
    <StyledBreadcrumb separator='›' maxItems={data.length} {...props}>
      {data.map(item => {
        if (item.isActive) {
          return <Text key='item.label' variant='subtitle1' color='gray.800'>{item.label}</Text>
        }

        return (
          <Link to={`${item.path}`} key={item.label} color='primary.main' >
            <Container>{item.label}</Container>
          </Link>
        )
      })}
    </StyledBreadcrumb>
  )
}

Breadcrumb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      path: PropTypes.string
    })
  )
}

export default Breadcrumb
