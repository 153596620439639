/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import {
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { CheckCircle, PersonPin, LocationOn, AccountBalance, Folder, Email } from '@material-ui/icons'
import ModalDocumentDelete from '../../routes/Investors/ModalDocumentDelete'

import {
  sanitizeDate,
  sanitizeDateWithHours,
  getAccountType,
  formatDocumentType,
  formatMoney,
  checkIsCompletedPersonalData,
  checkIsCompletedAddress,
  checkIsCompletedBank
} from '../../helpers'
import {
  getInvestorComplianceAgreements,
  getInvestorDocumentUrl,
  updateInvestor,
  deleteDocument,
  getInvestor,
  getBankList
} from '../../services/v3'

const Field = ({ error, title, value }) => {
  return (
    <Info error={error}>
      <span>{title}</span>
      {value}
    </Info>
  )
}

const InvestorPanel = ({ user }) => {
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [identificationDoc, setIdentificationDoc] = useState(null)
  const [identificationDocBack, setIdentificationDocBack] = useState(null)
  const [residenceDoc, setResidenceDoc] = useState(null)
  const [isCompleted, setIsCompleted] = useState({ bank: false, address: false, documents: false, personalData: false })
  const [address, setAddress] = useState(null)
  const [bank, setBank] = useState(null)
  const [complianceForInvest, setComplianceForInvest] = useState(null)
  const [complianceForPlataform, setComplianceForPlataform] = useState(null)

  const fetchUserInfo = useCallback(async () => {
    try {
      setLoading(true)
      const { data: respDoc } = await getInvestor(user.id)

      let isCompleted = {}

      if (user.bank) {
        setBank(user.bank)
        isCompleted = {
          ...isCompleted,
          bank: checkIsCompletedBank(user)
        }

        const { data: respBank } = await getBankList()
        const findBank = respBank.find(({ code }) => user.bank.code === code)
        setBank({ ...user.bank, bankName: !!findBank ? `${findBank.code} - ${findBank.name}` : false })
      }

      if (user.address) {
        setAddress(user.address)
        isCompleted = {
          ...isCompleted,
          address: checkIsCompletedAddress(user)
        }
      }
      if (user.investor) {
        const { data: investorComplianceAgreements } = await getInvestorComplianceAgreements(user.investor.id)

        const complianceForInvest = investorComplianceAgreements.find(
          ({ compliance_agreement_type }) => compliance_agreement_type.abbreviation === 'I'
        )

        const complianceForPlataform = investorComplianceAgreements.find(
          ({ compliance_agreement_type }) => compliance_agreement_type.abbreviation === 'P'
        )

        setComplianceForInvest(complianceForInvest)
        setComplianceForPlataform(complianceForPlataform)

        if (respDoc.documents && respDoc.documents.length !== 0) {
          const docs = respDoc.documents.filter(item => (item.deleted_at !== null ? false : true))

          if (docs && docs.length !== 0) {
            const identificationDoc = docs.find(
              ({ document_type, s3_name }) => ['C', 'R', 'O', 'RN'].includes(document_type.abbreviation) && s3_name
            )

            const identificationDocBack = docs.find(
              ({ document_type, s3_name }) => ['RV'].includes(document_type.abbreviation) && s3_name
            )

            const residenceDoc = docs.find(
              ({ document_type, s3_name }) => document_type.abbreviation === 'CR' && s3_name
            )

            isCompleted = {
              ...isCompleted,
              documents: Boolean(identificationDoc && residenceDoc)
            }

            setIdentificationDoc(identificationDoc)

            if (!!identificationDocBack) {
              setIdentificationDocBack(identificationDocBack)
            }
            setResidenceDoc(residenceDoc)
          }

          isCompleted = {
            ...isCompleted,
            documents: Boolean(isCompleted.documents && complianceForInvest && complianceForPlataform)
          }
        } else {
          setIdentificationDoc(false)
          setIdentificationDocBack(false)
          setResidenceDoc(false)
        }
      }

      setIsCompleted({
        ...isCompleted,
        personalData: checkIsCompletedPersonalData(user)
      })
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao carregar os dados do investidor.')
    } finally {
      setLoading(false)
    }
  }, [user])

  useEffect(() => {
    fetchUserInfo()
  }, [])

  const handleChange = panel => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleDocumentDownload = async document => {
    try {
      const {
        data: { url }
      } = await getInvestorDocumentUrl(user.investor.id, document.document_type_id)
      window.open(url)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao baixar o documento')
    }
  }

  const handleComplianceDownload = () => {
    if (!complianceForInvest || !complianceForInvest.s3_name) {
      toast.error('Ocorreu um erro ao acessar o termo assinado do usuário')
      return
    }
    window.open(complianceForInvest.s3_name)
  }

  const handleSendEmailStatus = useCallback(
    async e => {
      try {
        await updateInvestor(user.investor.id, { send_paid_installments: e.target.checked })
      } catch {}
    },
    [user]
  )

  const handleSendEmailCampaing = useCallback(
    async e => {
      try {
        await updateInvestor(user.investor.id, { send_campaign_information: e.target.checked })
      } catch {}
    },
    [user]
  )

  const handleDeleteDocument = useCallback(
    async id => {
      try {
        await deleteDocument(id).then(async () => {
          await fetchUserInfo()
        })

        setOpenModalDelete(false)
      } catch {}
    },
    [fetchUserInfo]
  )

  if (loading) {
    return (
      <LoaderContainer>
        <CircularProgress style={{ color: '#0275d8', width: 25, height: 25 }} />
      </LoaderContainer>
    )
  }

  return (
    <div>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary aria-controls='panel1bh-content' id='panel1bh-header'>
          <ExpansionPanelTitleContainer>
            <Typography>
              <PersonPin style={{ color: '#076f9f', marginRight: 10 }} />
              Dados pessoais
            </Typography>
            <CheckCircle
              style={{
                color: isCompleted.personalData ? 'green' : 'gray'
              }}
            />
          </ExpansionPanelTitleContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ExpansionPanelContent>
            <Field error={!!user.first_name} title='Nome: ' value={user.first_name || 'não preenchido.'} />
            <Field error={!!user.last_name} title='Sobrenome: ' value={user.last_name || 'não preenchido.'} />
            <Field error={!!user.document_number} title='CPF: ' value={user.document_number || 'não preenchido.'} />
            <Field error={!!user.email} title='E-mail: ' value={user.email || 'não preenchido.'} />
            <Field
              error={!!user.phone}
              title='Telefone: '
              value={user.phone ? `(${user.prefix ? user.prefix : '##'}) ${user.phone}` : 'não preenchido.'}
            />
            <Field
              error={!!user.birth_date}
              title='Data de nascimento: '
              value={sanitizeDate(user.birth_date) || 'não preenchido.'}
            />
            <Field error={user.rg} title='RG: ' value={user.rg ? user.rg : 'não preenchido.'} />
            <Field
              error={user.rg_agent}
              title='Orgão expedidor: '
              value={user.rg_agent ? `${user.rg_agent} ${user.rg_uf ? user.rg_uf : ''}` : 'não preenchido.'}
            />
            <Field error={!!user.nationality} title='Nacionalidade: ' value={user.nationality || 'não preenchido.'} />
            <Field error={!!user.naturality} title='Naturalidade: ' value={user.naturality || 'não preenchido.'} />
            <Field
              error={user.gender && user.gender.name}
              title='Gênero: '
              value={user.gender && user.gender.name ? user.gender.name : 'não preenchido.'}
            />
            <Field
              error={user.marital_type && user.marital_type.name}
              title='Estado civil: '
              value={user.marital_type && user.marital_type.name ? user.marital_type.name : 'não preenchido.'}
            />
            <Field error={!!user.mother_name} title='Nome da mãe: ' value={user.mother_name || 'não preenchido.'} />
            <Field error={!!user.father_name} title='Nome do pai: ' value={user.father_name || 'não preenchido.'} />
            <Field
              error={!!user.created_at_user}
              title='Data de cadastro: '
              value={sanitizeDateWithHours(user.created_at_user) || 'não preenchido.'}
            />
          </ExpansionPanelContent>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary aria-controls='panel2bh-content' id='panel2bh-header'>
          <ExpansionPanelTitleContainer>
            <Typography>
              <LocationOn style={{ color: '#076f9f', marginRight: 10 }} /> Endereço principal
            </Typography>
            <CheckCircle style={{ color: isCompleted.address ? 'green' : 'gray' }} />
          </ExpansionPanelTitleContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ExpansionPanelContent>
            <Field
              error={Boolean(address && address.zip_code)}
              title='CEP: '
              value={address && address.zip_code ? address.zip_code : 'não preenchido.'}
            />
            <Field
              error={Boolean(address && address.type)}
              title='Tipo: '
              value={address && address.type ? address.type : 'não preenchido.'}
            />
            <Field
              error={Boolean(address && address.street)}
              title='Rua: '
              value={address && address.street ? address.street : 'não preenchido.'}
            />
            <Field
              error={Boolean(address && address.number)}
              title='Número: '
              value={address && address.number ? address.number : 'não preenchido.'}
            />
            <Field
              error={true}
              title='Complemento: '
              value={address && address.complement ? address.complement : 'não preenchido.'}
            />
            <Field
              error={Boolean(address && address.district)}
              title='Bairro: '
              value={address && address.district ? address.district : 'não preenchido.'}
            />
            <Field
              error={Boolean(address && address.city)}
              title='Cidade: '
              value={address && address.city ? `${address.city} - ${address.uf || ''}` : 'não preenchido.'}
            />
          </ExpansionPanelContent>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary aria-controls='panel3bh-content' id='panel3bh-header'>
          <ExpansionPanelTitleContainer>
            <Typography>
              <AccountBalance style={{ color: '#076f9f', marginRight: 10 }} /> Dados financeiros
            </Typography>
            <CheckCircle style={{ color: isCompleted.bank ? 'green' : 'gray' }} />
          </ExpansionPanelTitleContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ExpansionPanelContent>
            <Field
              error={Boolean(bank && bank.type)}
              title='Tipo de conta: '
              value={bank && bank.type ? getAccountType(bank.type) : 'não preenchido.'}
            />
            <Field
              error={Boolean(bank && bank.code)}
              title='Banco: '
              value={bank && !!bank.bankName ? bank.bankName : 'não preenchido.'}
            />
            <Info />
            <Field
              error={Boolean(bank && bank.agency)}
              title='Agência: '
              value={bank && bank.agency ? bank.agency : 'não preenchido.'}
            />
            <Field
              error={Boolean(bank && bank.account)}
              title='Conta: '
              value={bank && bank.account ? bank.account : 'não preenchido.'}
            />
            <Field
              error={Boolean(bank && bank.account_digit)}
              title='Digito conta: '
              value={bank && bank.account_digit ? bank.account_digit : 'não preenchido.'}
            />
            <Field
              error={Boolean(user.monthly_earn)}
              title='Renda mensal: '
              value={user.monthly_earn ? `R$ ${formatMoney(user.monthly_earn / 100)}` : 'não preenchido.'}
            />
            <Field
              error={Boolean(user.property_value)}
              title='Patrimônio: '
              value={user.property_value ? `R$ ${formatMoney(user.property_value / 100)}` : 'não preenchido.'}
            />
          </ExpansionPanelContent>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary aria-controls='panel4bh-content' id='panel4bh-header'>
          <ExpansionPanelTitleContainer>
            <Typography>
              <Folder style={{ color: '#076f9f', marginRight: 10 }} /> Documentos
            </Typography>
            <CheckCircle
              style={{
                color: isCompleted.documents ? 'green' : 'gray'
              }}
            />
          </ExpansionPanelTitleContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ComplianceContainer>
            <Document>
              <p>Comprovante de residência</p>
              {residenceDoc ? (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <DownloadButton onClick={() => handleDocumentDownload(residenceDoc)}>Ver</DownloadButton>
                  <DeleteButton onClick={() => setOpenModalDelete(residenceDoc)}>Deletar</DeleteButton>
                </div>
              ) : (
                <p>Sem upload de arquivo</p>
              )}
            </Document>
            <Document>
              <p>
                {!!identificationDoc && !!identificationDoc.document_type
                  ? formatDocumentType(identificationDoc.document_type.abbreviation)
                  : 'Tipo de documento não selecionado'}
              </p>
              {!!identificationDoc ? (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <DownloadButton onClick={() => handleDocumentDownload(identificationDoc)}>Ver</DownloadButton>
                  <DeleteButton onClick={() => setOpenModalDelete(identificationDoc)}>Deletar</DeleteButton>
                </div>
              ) : (
                <p>Sem upload de arquivo</p>
              )}
            </Document>
            {!!identificationDoc &&
              !!identificationDoc.document_type &&
              identificationDoc.document_type.abbreviation === 'R' &&
              !!identificationDocBack &&
              identificationDocBack.document_type.abbreviation === 'RV' && (
                <Document>
                  <p>
                    {identificationDocBack
                      ? formatDocumentType(identificationDocBack.document_type.abbreviation)
                      : 'Tipo de documento não selecionado'}
                  </p>
                  {identificationDocBack ? (
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <DownloadButton onClick={() => handleDocumentDownload(identificationDocBack)}>Ver</DownloadButton>
                      <DeleteButton onClick={() => setOpenModalDelete(identificationDocBack)}>Deletar</DeleteButton>
                    </div>
                  ) : (
                    <p>Sem upload de arquivo</p>
                  )}
                </Document>
              )}
            <Document>
              <p>Termos e condições para investidor assinado</p>
              {complianceForInvest ? (
                <DownloadButton onClick={handleComplianceDownload}>Ver</DownloadButton>
              ) : (
                <p>Sem assinatura</p>
              )}
            </Document>
            {!complianceForInvest && (
              <Typography style={{ color: 'red', padding: '0.4em 0' }}>
                Não assinou os termos e condições para INVESTIR na Kavod Lending.
              </Typography>
            )}
            {!complianceForPlataform && (
              <Typography style={{ color: 'red' }}>
                Não assinou os termos e condições de USO DA PLATAFORMA DIGITAL da Kavod Lending.
              </Typography>
            )}
          </ComplianceContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary aria-controls='panel4bh-content' id='panel4bh-header'>
          <ExpansionPanelTitleContainer>
            <Typography>
              <Email style={{ color: '#076f9f', marginRight: 10 }} /> E-mail
            </Typography>
          </ExpansionPanelTitleContainer>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={!!user.send_paid_installments}
                  onChange={handleSendEmailStatus}
                  color='primary'
                />
              }
              label='Atualizações das parcelas pagas.'
            />
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={!!user.send_campaign_information}
                  onChange={handleSendEmailCampaing}
                  color='primary'
                />
              }
              label='Atualização sobre campanhas investidas.'
            />
          </FormGroup>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ModalDocumentDelete
        handleDelete={handleDeleteDocument}
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
      />
    </div>
  )
}

const DownloadButton = styled.button`
  padding: 0.6em 2em;
  background: #5cb85c;
  outline: 0;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #499449;
  }
`

const DeleteButton = styled.button`
  padding: 0.6em 2em;
  background: red;
  outline: 0;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: red;
  }
`

const Document = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1em 0;
  p {
    font-weight: bold;
  }
`

const ComplianceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Info = styled.p`
  width: 33%;
  padding: 0.3em 0;
  color: ${props => (props.error ? 'black' : 'red')};
  span {
    font-weight: bold;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`

const ExpansionPanelContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const ExpansionPanelTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1em;
`

export default InvestorPanel
