import client from '../providers/fetchClient'
import axios from 'axios'

export const fetchPersonalDocuments = async kvdId => {
  const commonQuery = `/person-document/kvd_id/${kvdId}?limit=1&exactmatch=true&stt_id=A&personal_document_type_id`
  try {
    const promises = [
      client.get(`${commonQuery}=R`),
      client.get(`${commonQuery}=C`),
      client.get(`${commonQuery}=O`),
      client.get(`${commonQuery}=RN`),
      client.get(`${commonQuery}=CR`)
    ]

    const data = await Promise.all(promises)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const getLinkToUpload = (id, docInitials, docName) => client.get(`/esh-upload/${id}/${docInitials}/${docName}`)

export const getLinkToDownload = (id, docInitials) => client.get(`/esh-download/${id}/${docInitials}`)

export const uploadDoc = async (url, file) =>
  new Promise(async (resolve, reject) => {
    const config = {
      headers: {
        'content-type': file.type
      }
    }
    try {
      const res = await axios.put(url, file, config)
      resolve(res.data.url)
    } catch (error) {
      reject(error)
    }
  })

export const uploadPersonalDocument = data => client.post('/person-document/', { data })
export const updatePersonalDocument = data => client.put('/person-document/', { data })
