export const colors = {
  background: '#fff',
  blue: '#076F9F',
  green: '#4AB34E',
  red: '#FD4646',
  yellow: '#F6B344',
  purple: '#663CCC',
  gray: '#424242',
  'gray-light': '#e0e0e0',
  black: '#212121'
}
