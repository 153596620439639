import styled from '@emotion/styled'

export const InputFile = styled.input`
  display: none;
`
export const Img = styled.img`
  height: auto;
  margin-top: 30px;
`
export const Label = styled.label`
  border-radius: 4px;
  height: 38px;
  padding: 10px 47px 0 47px;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  outline: none;
  background: white;
  border: 1px solid #076f9f;
  color: #076f9f;
  :hover {
    background: #076f9f;
    color: white;
  }
  text-align: center;
  margin: 15px 0;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
