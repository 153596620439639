import React, { useEffect, useState } from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import { fetchPersons } from '../../services'

import { LoadingScreen, Filter, DatePickerMaterial } from '../../components'
import {
  PersonsContainer,
  StyledReactTable,
  ReactTableContainer,
  SearchContainer,
  DatesContainer,
  ResetFilter,
  Button
} from './style'

import './react-table.css'
import { formatCPF, formatPhone, formatDateInterval, filterIntervalDates } from '../../helpers'

const columns = [
  {
    Header: 'id',
    accessor: 'kvd_id',
    width: 60
  },
  {
    Header: 'Nome da pessoa',
    accessor: 'name',
    Cell: props => <p>{props.original.first_name + ' ' + props.original.last_name}</p>,
    width: 300
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    Cell: props => <p>{props.original.cpf ? formatCPF(props.original.cpf) : ''}</p>
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 300
  },
  {
    Header: 'Telefone',
    accessor: 'phone',
    Cell: props => <p>{props.original.phone ? formatPhone(props.original.phone) : ''}</p>
  }
]

const Persons = ({ history }) => {
  const [persons, setPersons] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilter] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [unfilteredPersons, setUnfilteredPersons] = useState(null)

  const handleStartDate = date => {
    setStartDate(date)
    setEndDate(endDate ? endDate : new Date())
  }

  const handleEndDate = date => {
    setEndDate(date)
  }

  useEffect(() => {
    const getPersons = async () => {
      const response = await fetchPersons()
      const personsWithActions = response.data.map(person => {
        return {
          ...person,
          name: `${person.first_name} ${person.last_name}`
        }
      })

      setUnfilteredPersons(personsWithActions)
      setPersons(personsWithActions)
      setIsLoading(false)
    }

    getPersons()
  }, [])

  const filterPersons = e => {
    e.preventDefault()

    setPersons(
      unfilteredPersons.filter(person =>
        `${person.first_name} ${person.last_name}`.toUpperCase().includes(filter.toUpperCase())
      )
    )

    if (!!startDate && !!endDate) {
      setPersons(
        unfilteredPersons.filter(
          person =>
            !!filterIntervalDates(
              formatDateInterval(person.date_created),
              formatDateInterval(startDate),
              formatDateInterval(endDate)
            )
        )
      )
    }
    return persons
  }

  const resetFilters = () => {
    setPersons(unfilteredPersons)
    setFilter('')
    setStartDate(null)
    setEndDate(null)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <>
      <PersonsContainer>
        <SearchContainer>
          <Filter value={filter} onChange={e => setFilter(e.target.value)} />
          <DatesContainer>
            <DatePickerMaterial
              value={startDate}
              onChange={handleStartDate}
              placeholder='Data de Inicio'
              inputVariant='outlined'
            />
            <DatePickerMaterial
              value={endDate}
              onChange={handleEndDate}
              placeholder='Data Final'
              inputVariant='outlined'
            />
          </DatesContainer>

          <Button type='submit' onClick={e => filterPersons(e)}>
            Buscar
          </Button>
          <ResetFilter onClick={resetFilters}>Resetar filtros</ResetFilter>
        </SearchContainer>
        <ReactTableContainer>
          <StyledReactTable
            className='ReactTableDefault'
            data={persons}
            columns={columns}
            getTrProps={(state, rowInfo) => {
              return {
                onClick: e => {
                  history.push(`/pessoas/${rowInfo.original.kvd_id}/informacoes`)
                }
              }
            }}
          />
        </ReactTableContainer>
      </PersonsContainer>
    </>
  )
}

export default withRouter(withTheme(Persons))
