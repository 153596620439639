export const leadModalCards = {
  COMPANY: 'company',
  LEAD_INFOS: 'lead_infos',
  CONTACT_INFOS: 'contact_infos',
  COMPANY_INFOS: 'company_infos',
  LENDING_REASON: 'lending_reason',
  COMPANY_HISTORY: 'company_history'
}

export const origins = {
  PARTNER: 'Parceiro',
  FRANCHISOR: 'Franqueador',
  SITE: 'Site',
  KAVOD: 'Kavod'
}
