import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import moment from 'moment-timezone'
import { TextField, IconButton, Switch } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { ModalInstallmentDelay } from './component'
import { LoadingComponent } from '../../components'
import { Checkbox, Radio, AutocompleteMulti, Row, Column } from '../../components/v2Components'

import { IconCalendar } from '../../components/v2Components/icons'
import { SearchOutlined } from '@material-ui/icons'
import { Icon } from '@iconify/react'

import { Body, Title, Subtitle, ContainerFilter, Infotitle, ButtonFilter, Table } from './style'

import { sanitizeDate, formatValueToBrl, expectedDate } from '../../helpers'
import { getCampaignInstallments, installmentReceived } from '../../services/v3'

const options = [
  {
    value: '3',
    label: '3 dias'
  },
  {
    value: '7',
    label: '7 dias'
  },
  {
    value: '10',
    label: '10 dias'
  },
  {
    value: '30',
    label: '30 dias'
  },
  {
    value: 'all',
    label: 'Tudo'
  }
]

const InstallmentsMaturities = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [installments, setInstallments] = useState()
  const [data, setData] = useState()
  const [judicial, setJudicial] = useState()
  const [text, setText] = useState('')
  const [openModalDelay, setOpenModalDelay] = useState(false)
  const [selectedDateStart, setSelectedDateStart] = useState(new Date())
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date())
  const [infoHeader, setInfoHeader] = useState({
    parcel: 0,
    amount: 0,
    amortization: 0
  })

  const { control, errors, watch } = useForm({
    defaultValues: { maturities: true, filterData: 'all', judicial: [] }
  })

  const checkbox = watch('maturities')
  const filterData = watch('filterData')
  const JudicialSelected = watch('judicial')

  const simulationColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'campaign_id',
        minWidth: 30,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Campanha',
        accessor: 'campaign',
        minWidth: 190,
        Cell: props => <p>{props.value.name}</p>
      },
      {
        Header: () => <p style={{ textAlign: 'center', width: '100%' }}>Parcela</p>,
        accessor: 'installment_number',
        minWidth: 65,
        aling: 'center',
        Cell: props => <p style={{ textAlign: 'center' }}>{props.value}</p>
      },
      {
        Header: () => <p style={{ textAlign: 'right', width: '100%' }}>Valor</p>,
        accessor: 'gross_amount',
        Cell: props => <p style={{ textAlign: 'right' }}>{formatValueToBrl(props.value)}</p>
      },
      {
        Header: () => <p style={{ textAlign: 'right', width: '100%' }}>Amortização</p>,
        accessor: 'amortization',
        Cell: props => <p style={{ textAlign: 'right' }}>{formatValueToBrl(props.value)}</p>
      },
      {
        Header: 'Vencimento',
        accessor: 'expire_date',
        minWidth: 80,
        Cell: props => (
          <p
            style={{
              color:
                new Date(expectedDate(props.row.original)).getTime() - new Date().setHours(-24, 0, 0, 0) <= 0
                  ? 'red'
                  : 'black'
            }}
          >
            {sanitizeDate(expectedDate(props.row.original))}
          </p>
        )
      },
      {
        Header: 'Investidor',
        accessor: 'transfer_date',
        minWidth: 70,
        Cell: props => <p>{sanitizeDate(props.value)}</p>
      },
      {
        Header: 'Recebido',
        accessor: 'installment_received',
        Cell: props => {
          const [check, setCheck] = useState(!!props.value)
          useEffect(() => {
            setCheck(!!props.value)
          }, [props])
          return (
            <p>
              <Switch
                checked={check}
                color='primary'
                onChange={e => {
                  setCheck(e.target.checked)
                  handleChangeReceived(e, props.row.original.id)
                }}
                name='checkedA'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              {!!props.value ? 'Sim' : 'Não'}
            </p>
          )
        }
      },
      {
        Header: 'Data Prevista',
        accessor: 'expected_date',
        Cell: props => {
          const [dateExpected, setDateExpected] = useState(
            !!props.row.original.expected_date ? moment(props.row.original.expected_date) : null
          )

          useEffect(() => {
            setDateExpected(!!props.row.original.expected_date ? moment(props.row.original.expected_date) : null)
          }, [props])

          return (
            <>
              <KeyboardDatePicker
                clearable
                value={dateExpected ? dateExpected : null}
                emptyLabel='dd/mm/aa'
                placeholder='10/10/2018'
                invalidDateMessage='Data inválida'
                onChange={e => {
                  setDateExpected(e)
                  if (!!e && !!e._isValid && e._isValid) {
                    setOpenModalDelay({ ...props.row.original, newDate: e })
                    /* handleUpdateExpectedDate(e, props.row.original.id) */
                  }
                }}
                keyboardIcon={<Icon icon='mdi:calendar-edit' color='#076f9f' />}
                format='DD/MM/yyyy'
                style={{ width: '180px' }}
              />
            </>
          )
        }
      }
    ],
    []
  )

  const handleChangeReceived = useCallback(async (event, id) => {
    try {
      await installmentReceived(id, { installment_received: event.target.checked })
      await fetchInfos()
      toast.success('Parcela atualizada.')
    } catch {
      toast.error('Erro ao receber parcela.')
    }
  }, [])

  const handleDataFilter = useCallback(async () => {
    try {
      if (!!installments) {
        const data = installments.filter(
          item =>
            !item.pay_date &&
            item.campaign_id !== 3 &&
            (JudicialSelected.some(value => value.value.campaign_id === item.campaign_id) ||
              item.campaign.campaign_state_id !== 2) &&
            (checkbox ? true : new Date(expectedDate(item)).getTime() - new Date().setHours(-24, 0, 0, 0) >= 0) &&
            `${String(item.campaign.id)}${item.campaign.name.toUpperCase()}`.includes(text)
        )

        let filtered = []

        if (moment(selectedDateStart).isBefore(selectedDateEnd)) {
          filtered = data.filter(
            item =>
              moment(expectedDate(item)).valueOf() -
                moment(selectedDateStart)
                  .subtract(1, 'days')
                  .valueOf() >=
                0 && moment(selectedDateEnd).valueOf() - moment(expectedDate(item)).valueOf() >= 0
          )
        }

        setData(filtered)

        if (filtered.length > 0) {
          setInfoHeader({
            parcel: filtered.length,
            amount: filtered.reduce(function(acumulador = 0, valorAtual) {
              return (!!Number(acumulador) ? acumulador : 0) + Number(valorAtual.gross_amount)
            }),
            amortization: filtered.reduce(function(acumulador = 0, valorAtual) {
              return (!!Number(acumulador) ? acumulador : 0) + Number(valorAtual.amortization)
            })
          })
        } else {
          setInfoHeader({
            parcel: 0,
            amount: 0,
            amortization: 0
          })
        }
      }
    } catch (error) {}
  }, [selectedDateStart, selectedDateEnd, installments, checkbox])

  const fetchInfos = useCallback(async () => {
    try {
      const { data: installments } = await getCampaignInstallments()

      const install = installments.sort(
        (a, b) => new Date(expectedDate(a)).getTime() - new Date(expectedDate(b)).getTime()
      )
      setInstallments(install)

      const setInstall = new Set()
      setJudicial(
        install
          .filter(item => item.campaign.campaign_state_id === 2)
          .map(item => {
            return { value: item, label: `${item.campaign.id} - ${item.campaign.name}` }
          })
          .filter(item => {
            const duplicatedInstallment = setInstall.has(item.value.campaign.id)
            setInstall.add(item.value.campaign.id)
            return !duplicatedInstallment
          })
      )
    } catch {
      toast.error('Não foi possível encontrar as parcelas da campanha.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchInfos()
  }, [])

  useEffect(() => {
    switch (filterData) {
      case '3':
        let date = new Date()
        date.setDate(date.getDate() + 2)
        setSelectedDateStart(new Date())
        setSelectedDateEnd(moment(date))
        break

      case '7':
        const date7 = new Date()
        date7.setDate(date7.getDate() + 6)
        setSelectedDateStart(new Date())
        setSelectedDateEnd(moment(date7))
        break

      case '10':
        const date10 = new Date()
        date10.setDate(date10.getDate() + 9)
        setSelectedDateStart(new Date())
        setSelectedDateEnd(moment(date10))
        break

      case '30':
        const date30 = new Date()
        date30.setDate(date30.getDate() + 29)
        setSelectedDateStart(new Date())
        setSelectedDateEnd(moment(date30))
        break

      case 'all':
      default:
        setSelectedDateStart(null)
        setSelectedDateEnd(null)
        break
    }
  }, [filterData])

  useEffect(() => {
    if (!!installments && typeof JudicialSelected !== 'string') {
      const data = installments.filter(
        item =>
          !item.pay_date &&
          item.campaign_id !== 3 &&
          (JudicialSelected.some(value => value.value.campaign_id === item.campaign_id) ||
            item.campaign.campaign_state_id !== 2) &&
          (checkbox ? true : new Date(expectedDate(item)).getTime() - new Date().setHours(-24, 0, 0, 0) >= 0) &&
          `${String(item.campaign.id)}${item.campaign.name
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')}`.includes(text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      )

      let filtered = [installments]

      switch (filterData) {
        case '3':
          let date = new Date()
          date.setDate(date.getDate() + 2)
          filtered = data.filter(item => date.getTime() - new Date(expectedDate(item)).getTime() >= 0)
          break

        case '7':
          const date7 = new Date()
          date7.setDate(date7.getDate() + 6)
          filtered = data.filter(item => date7.getTime() - new Date(expectedDate(item)).getTime() >= 0)
          break

        case '10':
          const date10 = new Date()
          date10.setDate(date10.getDate() + 9)
          filtered = data.filter(item => date10.getTime() - new Date(expectedDate(item)).getTime() >= 0)
          break

        case '30':
          const date30 = new Date()
          date30.setDate(date30.getDate() + 29)
          filtered = data.filter(item => date30.getTime() - new Date(expectedDate(item)).getTime() >= 0)
          break

        case 'all':
        default:
          filtered = data
          break
      }

      setData(filtered)

      if (filtered.length > 0) {
        setInfoHeader({
          parcel: filtered.length,
          amount: filtered.reduce(function(acumulador = 0, valorAtual) {
            return (!!Number(acumulador) ? acumulador : 0) + Number(valorAtual.gross_amount)
          }),
          amortization: filtered.reduce(function(acumulador = 0, valorAtual) {
            return (!!Number(acumulador) ? acumulador : 0) + Number(valorAtual.amortization)
          })
        })
      } else {
        setInfoHeader({
          parcel: 0,
          amount: 0,
          amortization: 0
        })
      }
    }
  }, [installments, checkbox, filterData, JudicialSelected, text])

  if (isLoading) return <LoadingComponent />

  return (
    <Body>
      <div className='body'>
        <Title>Vencimentos</Title>

        <ContainerFilter>
          <div className='filter'>
            <Row justifyContent='space-between' alignItems='center'>
              <Subtitle>Período:</Subtitle>
              <Checkbox
                label='Vencidos'
                control={control}
                defaultValue={true}
                name='maturities'
                error={errors.is_visible_to_partner}
              />
            </Row>

            <Column>
              <Row justifyContent='space-between' style={{ gap: '32px' }}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  openTo='year'
                  format='DD/MM/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  views={['year', 'month', 'date']}
                  label='DE'
                  value={selectedDateStart}
                  invalidDateMessage={'Data inválida'}
                  inputVariant='outlined'
                  onChange={data => setSelectedDateStart(data)}
                  InputProps={{
                    style: {
                      fontSize: 16,
                      height: 40
                    }
                  }}
                  style={{ height: '40px' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  keyboardIcon={<IconCalendar />}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  openTo='year'
                  format='DD/MM/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  views={['year', 'month', 'date']}
                  label='ATÉ'
                  invalidDateMessage={'Data inválida'}
                  value={selectedDateEnd}
                  inputVariant='outlined'
                  onChange={data => setSelectedDateEnd(data)}
                  InputProps={{
                    style: {
                      fontSize: 16,
                      height: 40
                    }
                  }}
                  style={{ height: '40px' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  keyboardIcon={<IconCalendar />}
                />
              </Row>

              <Radio options={options} control={control} name='filterData' error={errors.filterData} />
              <Row justifyContent='flex-end'>
                <ButtonFilter width={127} height={40} mt={'51px'} onClick={handleDataFilter}>
                  Cosultar
                </ButtonFilter>
              </Row>
            </Column>
          </div>

          <div className='judicial'>
            <Subtitle style={{ marginTop: '10px' }}>Execução judicial</Subtitle>
            <AutocompleteMulti
              error={errors.status}
              name='judicial'
              disableCloseOnSelect={true}
              options={judicial}
              control={control}
              label='Campanhas em Execução judicial '
              multiple
              creatable={false}
              width='100%'
              mt='25px'
              mb='10px'
            />
          </div>

          <div className='search'>
            <Subtitle style={{ marginTop: '10px' }}>Pesquisa de parcelas</Subtitle>
            <TextField
              fullWidth
              size='small'
              id='standard-bare'
              variant='outlined'
              label='Pesquisar'
              onChange={e => setText(e.target.value.toUpperCase())}
              style={{ marginTop: '24px' }}
              InputProps={{
                style: {
                  fontSize: 16,
                  height: 40
                },
                endAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                )
              }}
            />
          </div>
        </ContainerFilter>

        <Row style={{ gap: '40px', flexWrap: 'wrap', marginTop: '16px', marginBottom: '16px' }}>
          <Infotitle>Parcelas ({infoHeader.parcel})</Infotitle>
          <Infotitle>Saldo ({formatValueToBrl(infoHeader.amount)})</Infotitle>
          <Infotitle>Principal ({formatValueToBrl(infoHeader.amortization)})</Infotitle>
        </Row>

        <ModalInstallmentDelay isOpen={openModalDelay} setIsOpen={setOpenModalDelay} reload={fetchInfos} />

        <Table
          hasPaginationTop
          hasPaginationBottom
          columns={simulationColumns}
          data={data}
          length={50}
          scrollWidth='1280'
        />
      </div>
    </Body>
  )
}

export default InstallmentsMaturities
