import React from 'react'
import { Formik, Form, Field } from 'formik'
import { personalAddressInputs } from '../../constants/forms'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import { updateAddress } from '../../services'
import { toast } from 'react-toastify'
import { formatAddressToUpdate } from '../../utils/formatters'
import { renderField } from '../../constants/inputs'

const AddressForm = ({ classes, address }) => {
  const handleSubmit = async values => {
    const addressId = address.id
    try {
      const addressData = formatAddressToUpdate(values)
      await updateAddress({ id: addressId, address: { ...addressData } })
      toast.success('Endereço atualizado com sucesso.')
    } catch (e) {
      toast.error('Não foi possível atualizar o endereço')
    }
  }
  return (
    <Grid>
      <Formik
        initialValues={{
          zip_code: address.zip_code,
          type: address.type,
          street: address.street,
          number: address.number,
          complement: address.complement,
          district: address.district,
          city: address.city,
          latitude: address.latitude,
          longitude: address.longitude
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className={classes.container}>
            {personalAddressInputs.map(input => (
              <Field name={input.name} render={({ field }) => renderField(input, field, classes)} />
            ))}
            <Grid container spacing={1} className={classes.containerButton}>
              <Fab type='submit' variant='extended' aria-label='Add' className={classes.button}>
                SALVAR E CONTINUAR
              </Fab>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default AddressForm
