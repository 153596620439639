import React from 'react'
import { toast } from 'react-toastify'

import { Breadcrumb, Button, SelectStatusLead, Column, InactivityAlert } from '../index'

import { Container, StyledRow, StyledButton, StyledText, Link, LinkPartner } from './styles'
import { getSiteLink } from '../../../helpers'

const HeaderCreditRequest = ({
  state,
  leadStatuses,
  handleOpenModal,
  handleOpenProposal,
  addInTimeline,
  id,
  oldStatus
}) => {
  const hasCampaign = !!state.campaign

  const data = [
    { label: 'Solicitação de crédito', path: '/solicitacoes-de-credito' },
    { label: state.company_name, path: '/detalhes-solicitação-de-credito' }
  ]

  const createCampaign = () => {
    const tab = window.open(
      `${getSiteLink()}/admin/campanhas/criar/?company_name=${state.company_name}&cnpj=${state.cnpj}&desired_amount=${
        state.lending_amount
      }&id_lead=${state.id}${
        !!state.proposal.sort((a, b) => b.id - a.id).find(item => item.is_accepted)
          ? `&proposal${new URLSearchParams(
              state.proposal.sort((a, b) => b.id - a.id).find(item => item.is_accepted)
            ).toString()}`
          : ''
      }`
    )
    tab.focus()
  }

  const handleGeneratorLink = () => {
    try {
      if (!!id) {
        navigator.clipboard.writeText(`${getSiteLink()}/minhas-indicacoes/${id}`)
        toast.success('Link para o parceiro copiado.')
      } else {
        throw Error('1')
      }
    } catch (error) {
      toast.error('Erro ao copiar link para o parceiro.')
    }
  }

  const viewCampaign = () => {
    const tab = window.open(`${getSiteLink()}/campanhas/${state.campaign.id}`)
    tab.focus()
  }

  return (
    <Container>
      <Column>
        <Breadcrumb data={data} />
        <StyledRow mt={3} flexWrap='wrap'>
          <StyledText variant='h3' color='primary'>
            {state.company_name}
          </StyledText>
          <SelectStatusLead
            lead={state}
            leadStatuses={leadStatuses}
            handleOpenModal={handleOpenModal}
            addInTimeline={addInTimeline}
            id={id}
            oldStatus={oldStatus}
          />
          <InactivityAlert lead={state} />
          <Link to={`/formulario-solicitacao-credito?id=${state.id}`}>Duplicar lead</Link>
          <LinkPartner onClick={handleGeneratorLink}>| Copiar link do parceiro</LinkPartner>
        </StyledRow>
      </Column>
      <StyledRow alignItems='flex-end'>
        <Button variant='outlined' width={160} height={40} onClick={handleOpenProposal}>
          Enviar proposta
        </Button>

        {hasCampaign ? (
          <StyledButton width={160} height={40} onClick={viewCampaign}>
            Ver campanha
          </StyledButton>
        ) : (
          !!state.lead_updates.find(item => item.lead_update_status_id === 6) && (
            <StyledButton width={160} height={40} onClick={createCampaign}>
              Criar briefing
            </StyledButton>
          )
        )}
      </StyledRow>
    </Container>
  )
}

export default HeaderCreditRequest
