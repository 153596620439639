import React from 'react'

import { Grid } from './style'

import { formatCurrency } from '../../../helpers'

const Proposal = ({ data }) => {
  return (
    <Grid>
      <h4 style={{ gridArea: 'title', justifyContent: 'center', backgroundColor: '#076F9F', color: 'white' }}>
        PROPOSTA DE CRÉDITO
      </h4>
      <h4 style={{ gridArea: 'time', justifyContent: 'center', backgroundColor: '#3dc6ef', color: 'white' }}>
        {data.time}
      </h4>

      <label style={{ gridArea: 'reason_social' }}>Razão social</label>
      <label style={{ gridArea: 'cnpj_name', justifyContent: 'center' }}>{data.cnpj_name}</label>

      <label style={{ gridArea: 'financing' }}>Valor financiamento</label>
      <label style={{ gridArea: 'financing_value', justifyContent: 'center' }}>
        {formatCurrency(data.financing_value)}
      </label>
      <label style={{ gridArea: 'due', justifyContent: 'center' }}>1º vencimento</label>
      <label style={{ gridArea: 'first_due', justifyContent: 'center' }}>{data.first_due}</label>

      <label style={{ gridArea: 'parcel' }}>Parcelas mensais</label>
      <h4 style={{ gridArea: 'parcel_number', justifyContent: 'center' }}>{data.installments_number}</h4>
      <h4 style={{ gridArea: 'parcel_value', justifyContent: 'center' }}>{formatCurrency(data.installment_value)}</h4>

      <label style={{ gridArea: 'tax_month' }}>Taxa (a.m.)</label>
      <label style={{ gridArea: 'tax_month_value', justifyContent: 'center' }}>{`${data.rate_month}%`}</label>
      <label style={{ gridArea: 'tax_year', justifyContent: 'center' }}>Taxa (a.a)</label>
      <label style={{ gridArea: 'tax_year_value', justifyContent: 'center' }}>{`${data.rate_annual}%`}</label>

      <label style={{ gridArea: 'warranty' }}>Garantia</label>
      <label style={{ gridArea: 'warranty_text', justifyContent: 'center' }}> {data.warranty}</label>
      <label style={{ gridArea: 'warranty_type', justifyContent: 'center' }}>Tipo Garantia</label>
      <label style={{ gridArea: 'warranty_type_value', justifyContent: 'center' }}>{data.warranty_type}</label>

      <label style={{ gridArea: 'percent' }}>Percentual</label>
      <label style={{ gridArea: 'percent_number', justifyContent: 'center' }}>{`${data.warranty_percentage}%`}</label>
      <label style={{ gridArea: 'percent_text', justifyContent: 'center' }}>Valor</label>
      <label style={{ gridArea: 'percent_value', justifyContent: 'center' }}>
        {formatCurrency(data.warranty_value)}
      </label>

      <label style={{ gridArea: 'tac' }}>Comissão Kavod</label>
      <label style={{ gridArea: 'tac_percent', justifyContent: 'center' }}>{`${data.kavod_percentage}%`}</label>
      <label style={{ gridArea: 'tac_value', justifyContent: 'center' }}>
        {formatCurrency(data.commission_amount)}
      </label>

      <label style={{ gridArea: 'iof' }}>IOF</label>
      <label style={{ gridArea: 'iof_value', justifyContent: 'center' }}>{formatCurrency(data.iof_data)}</label>

      <label style={{ gridArea: 'net' }}>Valor líquido</label>
      <h4 style={{ gridArea: 'net_value', justifyContent: 'center' }}>{formatCurrency(data.liquid_amount)}</h4>

      <label style={{ gridArea: 'cet_month' }}>CET (a.m.)</label>
      <label style={{ gridArea: 'cet_month_value', justifyContent: 'center' }}>{`${data.cet_month}%`}</label>
      <label style={{ gridArea: 'cet_year', justifyContent: 'center' }}>CET (a.a)</label>
      <label style={{ gridArea: 'cet_year_value', justifyContent: 'center' }}>{`${data.cet_annual}%`}</label>
    </Grid>
  )
}

export default Proposal
