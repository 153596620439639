import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Typography } from '@material-ui/core'
import { CloudUpload, CloudDone } from '@material-ui/icons'
import { MaterialButton } from '../../../components'
import styled from 'styled-components'
import Modal from 'react-modal'

const ModalUploadCnab = ({ isOpen, setIsOpen }) => {
  const [fileToUpload, setFileToUpload] = useState(null)

  const handleUploadFileModal = file => {
    setFileToUpload(file)
  }
  return (
    <ModalComponent
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      closeModal={() => setIsOpen(false)}
    >
      <ModalContainer>
        <ModalContent>
          <Typography variant='h6'>Upload de Cnab</Typography>
          <TypographyContainer>
            <Typography variant='subtitle1'>Upload do arquivo</Typography>
          </TypographyContainer>

          <Formik
            onSubmit={values => {
              console.log('values: ', values)
            }}
          >
            {({ setFieldValue }) => (
              <StyledForm>
                <InputContainer>
                  <InputWrapper>
                    <FileInput
                      type='file'
                      name={'file'}
                      id={'file'}
                      onChange={e => {
                        setFieldValue('file', e.currentTarget.files[0])
                        handleUploadFileModal(e.currentTarget.files[0])
                      }}
                    />
                    <label htmlFor={'file'}>
                      {fileToUpload ? (
                        <CloudDone style={{ fontSize: 80, color: '#076F9F' }} />
                      ) : (
                        <CloudUpload style={{ fontSize: 80, color: '#076F9F' }} />
                      )}
                    </label>
                  </InputWrapper>
                </InputContainer>
                <ButtonsContainer>
                  <StyledMaterialButton label='ADICIONAR O CNAB' type='submit' disabled={!fileToUpload} />
                  <MaterialButton label='FECHAR' onClick={() => setIsOpen(false)} />
                </ButtonsContainer>
              </StyledForm>
            )}
          </Formik>
        </ModalContent>
      </ModalContainer>
    </ModalComponent>
  )
}

const TypographyContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`
const InputContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
`
const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`
const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const ModalComponent = styled(Modal)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalContent = styled.div`
  display: flex;
  width: 700px;
  border-radius: 4px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`
const StyledMaterialButton = styled(MaterialButton)`
  background: ${({ fileToUpload }) => (fileToUpload ? '#3dc6ef' : 'gray')};
  color: ${({ fileToUpload }) => (fileToUpload ? '#fff' : 'darkgray')};
`
const StyledForm = styled(Form)`
  width: 100%;
  padding: 30px;
`

export default ModalUploadCnab
