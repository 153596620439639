import styled from '@emotion/styled'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ClientPartner = styled.div`
  display: flex;
  width: 40%;
`

export const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Partners = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  height: 24px;
`

export const Guarantors = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

export const RemoveGuarantor = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const Title = styled.p`
  text-align: center;
  font-size: 1.2em;
  margin: 1em 0;
`

export const AccordionContainer = styled.div`
  width: 90%;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

export const SendContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
