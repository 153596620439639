import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Pagination } from '../../../../components/v2Components'
import { ProgressBar, GlobalFilter } from '../index'

import { Container, Content, Main, FilterContainer } from './styles'
import { CCBDownload } from '../../styles'

import PropTypes from 'prop-types'

const CardBase = ({ investments, hasPaginationTop, hasPaginationBottom, offset, rowCount, navigationTo, children }) => {
  const [filter, setFilter] = useState('')

  const data = useMemo(
    () =>
      investments.filter(
        investment => investment.Campanha.toUpperCase().indexOf(filter.toUpperCase()) !== -1 && investment
      ),
    [investments, filter]
  )

  const paginationInvestments = useMemo(() => {
    let list = [[]]
    let group = 0

    for (let i = 0; i < data.length; i++) {
      list[group].push(data[i])

      if (list[group].length === rowCount) {
        group++

        if (i + 1 < data.length) {
          list[group] = []
        }
      }
    }

    return list
  }, [data, rowCount])

  return (
    <Main>
      <FilterContainer>
        <GlobalFilter
          filter={filter}
          setFilter={value => {
            navigationTo(0)
            setFilter(value)
          }}
        />
      </FilterContainer>

      {children}

      {hasPaginationTop && rowCount > 1 && (
        <Pagination
          pagination={{
            offset,
            rowCount,
            limit: 1,
            pageCount: paginationInvestments.length
          }}
          onChangePage={({ page }) => navigationTo(page - 1)}
        />
      )}

      <Content>
        {paginationInvestments[offset] &&
          paginationInvestments[offset].map(investment => {
            return (
              <Container key={investment.Campanha}>
                <header>
                  <p>{investment.Campanha}</p>
                  <span>{investment.Status}</span>
                </header>
                <section>
                  <div>
                    <p>Total recebido</p>
                    <p>Total a receber</p>
                  </div>
                </section>
                <div>
                  <ProgressBar
                    initialValue={investment['Recebido / A receber'].props.initialValue}
                    finalValue={investment['Recebido / A receber'].props.finalValue}
                    thinbar={true}
                    value={investment['Recebido / A receber'].props.value}
                  />
                </div>
                <main>
                  <div>
                    <p>Taxa mensal / anual</p>
                    <label>{investment['Taxa mensal / anual']}</label>
                  </div>
                  <div>
                    <p>TIR</p>
                    <label>{investment.TIR}</label>
                  </div>
                  <div>
                    <p>Parcela</p>
                    <label>{investment.Parcela}</label>
                  </div>
                  <div>
                    <p>Próxima parcela</p>
                    <label>{investment['Próxima parcela']}</label>
                  </div>
                </main>
                <footer>
                  <Link to={investment.detalhes.props.to}>Ver detalhes</Link>
                  <CCBDownload show={!!investment.ccb.props.href}>
                    <a
                      style={{ padding: '0px' }}
                      download
                      href={!!investment.ccb.props.href ? investment.ccb.props.href : ''}
                      target='_blank'
                      rel='noopener noreferrer'
                      title='Download CCB'
                    >
                      CCB
                      <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
                    </a>
                  </CCBDownload>
                </footer>
              </Container>
            )
          })}
      </Content>

      {hasPaginationBottom && rowCount > 1 && (
        <Pagination
          pagination={{
            offset,
            rowCount,
            limit: 1,
            pageCount: paginationInvestments.length
          }}
          onChangePage={({ page }) => navigationTo(page - 1)}
        />
      )}
    </Main>
  )
}

CardBase.propTypes = {
  investment: PropTypes.shape({
    Campanha: PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    received: PropTypes.string.isRequired,
    receive: PropTypes.string.isRequired,
    'Taxa mensal / anual': PropTypes.string.isRequired,
    TIR: PropTypes.string.isRequired,
    Parcela: PropTypes.string.isRequired,
    'Próxima parcela': PropTypes.string.isRequired
  }),
  hasPaginationTop: PropTypes.bool,
  hasPaginationBottom: PropTypes.bool,
  offset: PropTypes.number,
  rowCount: PropTypes.number,
  pageCount: PropTypes.number,
  navigationTo: PropTypes.func
}

export default CardBase
