import PropTypes from 'prop-types'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'

import Row from '../Row'

const Column = styled(Row)`
  flex-direction: column;
  ${spacing};
`

Column.propTypes = {
  flex: PropTypes.number,
  flexWrap: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string
}

export const ColumnDesktop = styled(Column)`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
  }
`

export const ColumnMobile = styled(Column)`
  display: flex;
  @media (min-width: 1200px) {
    display: none;
  }
`

export default Column
