import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'

import AnticipationRow from '../AnticipationRow'

const AnticipationTableModal = ({ installments, isOpen, onClose, handleInstallmentClick }) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      className='Modal modal-container-anticipation'
      overlayClassName='Overlay'
    >
      <IconContainer>
        <Title>Detalhes da Liquidação Antecipada</Title>
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </IconContainer>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader mw='50px'>#</TableHeader>
              <TableHeader mw='100px'>Valor líquido original</TableHeader>
              <TableHeader mw='100px'>Valor liquidado da TED</TableHeader>
              <TableHeader mw='80px'>Previsão de pagamento original</TableHeader>
              <TableHeader mw='80px'>Pago em</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {installments.map(portion => (
              <AnticipationRow key={portion.id} portion={portion} handleInstallmentClick={handleInstallmentClick} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <FooterContainer>
        <FooterText onClick={onClose}>Fechar</FooterText>
      </FooterContainer>
    </Modal>
  )
}

const Table = styled.table`
  width: 100%;
`

const TableHeader = styled.th`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: normal;
  font-weight: 600;
  padding: 1em 0;
  ${({ mw }) => `min-width: ${mw}`};
`

const TableRow = styled.tr`
  cursor: pointer;
  background-color: white;
  transition: all 0.3s;
  display: flex;
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
    transition: all 0.3s;
  }

  @media (max-width: 769px) {
    font-size: 14px;
  }
`

const TableContainer = styled.div`
  flex: 1;
  margin-bottom: 30px;
`

const IconContainer = styled.div`
  width: 100%;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FooterContainer = styled.div`
  width: 100%;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterText = styled.p`
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2em;
`

const Title = styled.p`
  margin-top: 0px;
  font-size: 16px;
`

export default AnticipationTableModal
