import styled from 'styled-components'
import { styles } from '../../../../Themes/styles'
import { colors } from '../../../../Themes/colors'

export const Main = styled.div`
  span {
    display: none;
    @media (max-width: ${({ behavior }) => (behavior ? '730px' : '1200px')}) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        padding: 15px 0 0 0;
      }
    }
    button {
      background-color: transparent;
      border: none;
    }
  }

  @media (max-width: ${({ behavior }) => (behavior ? '730px' : 'unset')}) {
    align-self: baseline;
    position: relative;
    bottom: ${({ campaing }) => (campaing ? '25px' : '40px')};
  }
`

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  border-bottom: 1px solid ${styles.palette.gray[300]};
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  div {
    display: flex;
  }

  button {
    background-color: transparent;
    border: none;
  }

  button + button {
    margin-left: 40px;
  }

  span {
    display: flex;
    button {
      @media (max-width: ${({ behavior }) => (behavior ? '730px' : '1200px')}) {
        display: none;
      }
      margin-left: 20px;
    }
  }
`

export const Option = styled.label`
  font-size: 1rem;
  color: ${({ selected }) => (selected ? `${colors.blue}` : `${colors.gray}`)};
  border-bottom: ${({ selected }) => (selected ? `4px solid ${colors.blue}` : '')};
  border-radius: ${({ selected }) => (selected ? '2px' : '')};
  font-weight: ${({ selected }) => (selected ? '600' : 'normal')};
  padding-bottom: 12px;
  display: block;
  white-space: nowrap;

  cursor: pointer;
`
