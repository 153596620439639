import React from 'react'
import { Container, Content, InfoContainer } from './styled'
import PropTypes from 'prop-types'

const CardProcessing = ({ investmentIntentions, showProcessing = false, info }) => {
  return (
    <Content>
      <InfoContainer>{info}</InfoContainer>
      {investmentIntentions &&
        investmentIntentions.map((investmentIntention, index) => {
          return (
            <Container key={index} showPocessing={showProcessing}>
              <h1>{investmentIntention.Campanha}</h1>
              <section>
                <div>
                  <label>Investimento</label>
                  <p>{investmentIntention.Investimento}</p>
                </div>
                <div>
                  <span>{investmentIntention.Status}</span>
                  <span>{investmentIntention.delete}</span>
                </div>
              </section>
              {!!investmentIntention.ted && (
                <div style={{ justifyContent: 'center' }}>
                  <span>{investmentIntention.ted}</span>
                </div>
              )}
            </Container>
          )
        })}
    </Content>
  )
}

CardProcessing.propTypes = {
  investment: PropTypes.shape({
    Campanha: PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    Investimento: PropTypes.string.isRequired,
    delete: PropTypes.string.isRequired
  })
}

export default CardProcessing
