import React from 'react'
import { Loader } from '../'
import styled from 'styled-components'

const LoadingComponent = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1em;
  margin: 0 0.3em;
  align-items: center;
  height: 400px;
`

export default LoadingComponent
