import React from 'react'

import { Row } from '../../../../components/v2Components'

import { H3 } from './styles'

const Title = ({ title, children, ...props }) => {
  return (
    <Row {...props}>
      <H3>{title}</H3>
      {children}
    </Row>
  )
}

export default Title
