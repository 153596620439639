import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

export const Container = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleContainer = styled.div`
  width: 90vw;

  margin-bottom: 60px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const Title = styled.h1`
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  font-size: 36px;
  line-height: 49px;
  color: #076f9f;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`

export const ReactTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 0px;

  .ReactTable .-loading > div {
    top: 100px;
  }
`

export const SearchContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const StyledSearchIcon = styled(SearchIcon)`
  color: #757575;
  margin: 0px 10px;
  cursor: pointer;
`

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`

export const StyledInputBase = styled(InputBase)`
  width: 480px;
  padding: 5px 16px;
  background: #ffffff;
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 50px;
  @media (max-width: 768px) {
    width: 300px;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

export const StatusFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    justify-content: center;
  }
`

export const StatusFilter = styled.div`
  margin-left: 18px;
  margin-bottom: 5px;
  width: 120px;
  height: 27px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 769px) {
    margin: 10px;
  }
`
