import client from '../../providers/fetchClient'

export const createInvestor = data => client.post('/v3/investors/signup', data)

export const updateInvestor = (id, data) => client.put(`/v3/investors/${id}`, data)

export const getInvestor = id => client.get(`/v3/investors/${id}`)

export const getInvestors = params => client.get('/v3/investors', { params })

export const getIncomesReport = investor_id => client.get(`/v3/incomes-report/${investor_id}`)

export const getInvestorsQualifications = id =>
  client.get(`/v3/investors-qualification?campaign_id=${id}`, { responseType: 'arraybuffer' })
