export const nil = new Error('#NULL!')
export const div0 = new Error('#DIV/0!')
export const value = new Error('#VALUE!')
export const ref = new Error('#REF!')
export const name = new Error('#NAME?')
export const num = new Error('#NUM!')
export const na = new Error('#N/A')
export const error = new Error('#ERROR!')
export const data = new Error('#GETTING_DATA')

function parseNumber(string) {
  if (string instanceof Error) {
    return string
  }

  if (string === undefined || string === null || string === '') {
    return 0
  }

  if (typeof string === 'boolean') {
    string = +string
  }

  if (!isNaN(string)) {
    return parseFloat(string)
  }

  return error.value
}

function anyIsError() {
  let n = arguments.length

  while (n--) {
    if (arguments[n] instanceof Error) {
      return true
    }
  }

  return false
}

export function RATE(periods, payment, present, future, type, guess) {
  guess = guess === undefined ? 0.01 : guess
  future = future === undefined ? 0 : future
  type = type === undefined ? 0 : type

  periods = parseNumber(periods)
  payment = parseNumber(payment)
  present = parseNumber(present)
  future = parseNumber(future)
  type = parseNumber(type)
  guess = parseNumber(guess)

  if (anyIsError(periods, payment, present, future, type, guess)) {
    return error.value
  }

  const epsMax = 1e-10
  const iterMax = 20
  let rate = guess

  type = type ? 1 : 0

  for (let i = 0; i < iterMax; i++) {
    if (rate <= -1) {
      return error.num
    }

    let y, f

    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future
    } else {
      f = Math.pow(1 + rate, periods)
      y = present * f + payment * (1 / rate + type) * (f - 1) + future
    }

    if (Math.abs(y) < epsMax) {
      return rate
    }

    let dy

    if (Math.abs(rate) < epsMax) {
      dy = present * periods + payment * type * periods
    } else {
      f = Math.pow(1 + rate, periods)
      const df = periods * Math.pow(1 + rate, periods - 1)
      dy = present * df + payment * (1 / rate + type) * df + payment * (-1 / (rate * rate)) * (f - 1)
    }

    rate -= y / dy
  }

  return rate
}
