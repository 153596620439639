import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'

import { CampaignContainer, LoadingScreen } from '../../components'
import { InputFile, Label, Wrapper, Images, Image, ImageContainer, Icon, WrapperImages, OrderMessage } from './style'

import {
  getCampaignGallery,
  deleteCampaignGallery,
  createCampaignGallery,
  updateCampaignGallery
} from '../../services/campaigns'

const CampaignPhoto = ({ match }) => {
  const [loading, setLoading] = useState(true)
  const [images, setImages] = useState([])

  const getGallery = async () => {
    try {
      const response = await getCampaignGallery(match.params.id)
      const orderedImages = response.data.sort((a, b) => {
        if (a.photo_order < b.photo_order) return -1
        if (a.photo_order > b.photo_order) return 1
        return 0
      })
      setImages(orderedImages)
      setLoading(false)
    } catch (e) {
      toast.error('Não foi possível buscar informações no momento.')
      setLoading(false)
    }
  }

  useEffect(() => {
    getGallery()
  }, [])

  const onSelectFile = async e => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true)
      try {
        const promises = Array.from(e.target.files).map(file => {
          const formData = new FormData() //eslint-disable-line
          formData.append('file', file)
          formData.append('campaign_id', match.params.id)
          formData.append('rol_id', 'GL')
          return createCampaignGallery(formData)
        })
        await Promise.all(promises)
        toast.success('Imagens adicionadas a galeria com sucesso.')
        getGallery()
      } catch (error) {
        setLoading(false)
        toast.error('Houve algum erro ao adicionar imagens.')
      }
    }
  }

  const deletePhoto = async photo => {
    try {
      setLoading(true)
      await deleteCampaignGallery({ id: photo.id, stt_id: 'I' })
      toast.success('Imagem deletada com sucesso.')
      getGallery()
    } catch (error) {
      toast.error('Houve algum erro ao remover imagens.')
      setLoading(false)
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = async result => {
    if (!result.destination) {
      return
    }

    setLoading(true)

    const items = reorder(images, result.source.index, result.destination.index)

    try {
      const promises = items.map((image, index) => updateCampaignGallery({ id: image.id, photo_order: index + 1 }))
      await Promise.all(promises)
      toast.success('Ordem das imagens alterada com sucesso.')
      setImages(items)
      setLoading(false)
    } catch (error) {
      toast.error('Houve algum erro ao ordenar imagens.')
      setLoading(false)
    }
  }

  if (loading) return <LoadingScreen />

  return (
    <CampaignContainer step={6}>
      <Wrapper>
        <Label htmlFor='ProfileFileInput'>
          Selecionar imagens
          <InputFile multiple type='file' id='ProfileFileInput' onChange={onSelectFile} accept='image/*' />
        </Label>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {provided => (
              <WrapperImages ref={provided.innerRef} {...provided.droppableProps}>
                {images.length > 0 && <OrderMessage>Arraste as imagens para alterar a ordem.</OrderMessage>}
                <Images>
                  {images.map((image, index) => (
                    <Draggable key={image.id} draggableId={image.id} index={index}>
                      {(provided, snapshot) => (
                        <ImageContainer
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Image src={image.photoURL} />
                          <Icon src='/assets/icons/trash.svg' onClick={() => deletePhoto(image)} />
                        </ImageContainer>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Images>
              </WrapperImages>
            )}
          </Droppable>
        </DragDropContext>
      </Wrapper>
    </CampaignContainer>
  )
}

export default withRouter(CampaignPhoto)
