import styled from '@emotion/styled'

export const InputFile = styled.input`
  display: none;
`
export const Label = styled.label`
  border-radius: 4px;
  height: 38px;
  padding: 10px 47px 10px 47px;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  outline: none;
  background: white;
  border: 1px solid #076f9f;
  color: #076f9f;
  :hover {
    background: #076f9f;
    color: white;
  }
  text-align: center;
  margin: 15px 0;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`
export const Image = styled.img`
  width: 200px;
  height: auto;
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px 0;
`
export const Icon = styled.img`
  width: 15px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.2);
  }
`
export const WrapperImages = styled.div`
  width: 100%;
  min-height: 100%;
`
export const OrderMessage = styled.p`
  color: grey;
  font-size: 14px;
  margin-bottom: 30px;
`
