import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(4, 1fr);
  grid-template-areas:
    'title title title title time'
    'reason_social cnpj_name cnpj_name cnpj_name cnpj_name'
    'financing financing_value financing_value due first_due'
    'parcel parcel_number parcel_number parcel_value .'
    'tax_month tax_month_value tax_month_value tax_year tax_year_value '
    '. . . . .'
    'warranty warranty_text warranty_text warranty_type warranty_type_value'
    'percent percent_number percent_number percent_text percent_value'
    'tac tac_percent tac_percent tac_value .'
    'iof iof_value iof_value . .'
    'net net_value net_value . .'
    'cet_month cet_month_value cet_month_value cet_year cet_year_value';

  border-style: solid;
  border: 2px solid;

  h4 {
    display: flex;
    align-items: center;
    min-height: 28px;
    height: inherit;
    border: 1px dotted gray;
    padding-left: 5px;
  }

  label {
    display: flex;
    align-items: center;
    min-height: 28px;
    height: inherit;
    border: 1px dotted gray;
    padding-left: 5px;

    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 850px) {
    grid-template-columns: 200px 1fr;
    grid-template-areas:
      'title time'
      'reason_social cnpj_name'
      'financing financing_value'
      'due first_due'
      'parcel parcel_number'
      '. parcel_value'
      'tax_month tax_month_value'
      'tax_year tax_year_value '
      '. .'
      'warranty warranty_text'
      'warranty_type warranty_type_value'
      'percent percent_number'
      'percent_text percent_value'
      'tac tac_percent'
      'tac_value .'
      'iof iof_value'
      'net net_value'
      'cet_month cet_month_value'
      'cet_year cet_year_value';

    label {
      min-height: 44px;
      height: inherit;
    }

    h4 {
      min-height: 44px;
      height: inherit;
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: minmax(158px, 1fr);
    grid-template-areas:
      'title time'
      'reason_social cnpj_name'
      'financing financing_value'
      'due first_due'
      'parcel parcel_number'
      '. parcel_value'
      'tax_month tax_month_value'
      'tax_year tax_year_value '
      '. .'
      'warranty warranty_text'
      'warranty_type warranty_type_value'
      'percent percent_number'
      'percent_text percent_value'
      'tac tac_percent'
      'tac_value .'
      'iof iof_value'
      'net net_value'
      'cet_month cet_month_value'
      'cet_year cet_year_value';

    label {
      min-height: 44px;
      height: inherit;
    }

    h4 {
      min-height: 44px;
      height: inherit;
    }
  }

  @media (max-width: 440px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'time'
      'reason_social'
      'cnpj_name'
      'financing'
      'financing_value'
      'due'
      'first_due'
      'parcel'
      'parcel_number'
      'parcel_value'
      'tax_month'
      'tax_month_value'
      'tax_year'
      'tax_year_value'
      '.'
      'warranty'
      'warranty_text'
      'warranty_type'
      'warranty_type_value'
      'percent'
      'percent_number'
      'percent_text'
      'percent_value'
      'tac'
      'tac_percent'
      'tac_value'
      'iof'
      'iof_value'
      'net'
      'net_value'
      'cet_month'
      'cet_month_value'
      'cet_year'
      'cet_year_value';

    label {
      min-height: 44px;
      height: inherit;
      justify-content: center;
      text-align: center;
    }

    h4 {
      min-height: 44px;
      height: inherit;
      text-align: center;
    }
  }
`
