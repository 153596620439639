import styled from 'styled-components'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

import { Upload } from '../icons/custom'
import { IconAttachFileIcon } from '../icons'
import { Row, Button } from '../index'

export const DragAndDrop = styled.div`
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  height: 144px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
`

export const StyledUpload = styled(Upload)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const Underlined = styled.span`
  text-decoration: underline;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const Description = styled.span`
  text-decoration: underlined;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const AcceptFile = styled.span`
  color: ${({ theme }) => theme.palette.secondary[700]};
  max-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AttachFileIcon = styled(IconAttachFileIcon)`
  transform: rotate(45deg);
  margin-right: 5px;
`

export const StyledRow = styled(Row)`
  color: ${({ theme }) => theme.palette.secondary[700]};
  align-items: center;
  justify-content: space-between;
  ${spacing};
  ${sizing};
  height: 40px;
  max-height: 40px;
`

export const StyledButton = styled(Button)`
  &&.MuiButton-root {
    text-transform: capitalize;
  }
  &&.MuiButton-textPrimary {
    color: ${props => props.buttoncolor};
  }
  &&.MuiButton-text {
    padding: 0px;
  }
  width: 1px;
`

export const ErrorFile = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  display: flex;
  flex-direction: Row;
  justify-content: space-between;
  ${spacing};
  ${sizing};
  height: 40px;
`

export const ErrorMessage = styled.p`
  font-size: 10px;
  line-height: 14px;
`

export const FileWithError = styled.span`
  max-width: 25ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Container = styled.section``

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin: 10px 0 20px;

  img {
    max-width: 100%;
    max-height: 200px;
  }
`
