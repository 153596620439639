import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Drawer, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { editLeadUpdate } from '../../../services/v3'
import { modalEditLeadUpdate } from '../../../schemas'

import { Checkbox, Row, Button } from '../index'
import { Form } from './styles'

const drawerWidth = 240

const ModalEditRegister = ({ item, buttonClick, handleClose, editInTimeline }) => {
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    formState: { isSubmitting }
  } = useForm({
    resolver: modalEditLeadUpdate,
    shouldUnregister: false
  })

  const [val, setVal] = useState('')

  const isCheck = watch('is_visible_to_partner')

  const onSubmit = async values => {
    try {
      const body = {
        observation: val.toString('html'),
        is_visible_to_partner: values.is_visible_to_partner ? true : false
      }

      await editLeadUpdate(item.id, body)
      toast.success('Registro editado.')
      editInTimeline({ ...body, id: item.id })
    } catch {
      toast.error('Ocorreu um erro ao editar o resgistro.')
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    if (item !== undefined) {
      setVal(item.observation)
    }
  }, [item])

  useEffect(() => {
    if (item !== undefined) {
      setValue('observation', item.observation)
      setValue(
        'is_visible_to_partner',
        item.is_visible_to_partner === 1 || item.is_visible_to_partner === true ? true : false
      )
    } else {
      setValue('observation', '')
    }
  }, [item])

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      anchor='right'
      open={!!item}
    >
      <div style={{ background: '#dfdfdf' }}>
        <IconButton onClick={handleClose}>{!!item === false ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <h4>Editar registro</h4>

        <ReactQuill
          theme='snow'
          value={val}
          onChange={setVal}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['link', 'image'],
              ['clean']
            ]
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image'
          ]}
        />

        <Checkbox
          label='Mostrar mensagem para parceiro'
          control={control}
          name='is_visible_to_partner'
          error={errors.is_visible_to_partner}
          checked={isCheck}
          style={{ marginTop: '15px' }}
        />
        <Row style={{ marginTop: '15px' }}>
          <Button height={40} width={227} onClick={buttonClick} mr={3} variant='outlined'>
            Cancelar
          </Button>
          <Button height={40} width={227} type='submit' loader={isSubmitting}>
            Enviar
          </Button>
        </Row>
      </Form>
    </Drawer>
  )
}

export default ModalEditRegister
