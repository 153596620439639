import styled from 'styled-components'
import { colors } from '../../../../Themes/colors'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  section {
    background-color: #fff;
    width: 526px;
    max-width: 526px;
    margin: auto;
    padding: 24px;
    border-radius: 8px;
    color: ${colors.gray};
    @media (max-width: 475px) {
      width: 328px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 18px;
    line-height: 26px;
  }

  button {
    border: none;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
`

export const ButtonContainer = styled.div`
  &:last-child {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    margin: 32px 5px 0;
    width: 100%;
    border: 1px solid ${colors.blue};
  }
`
