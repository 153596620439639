import React from 'react'
import styled, { keyframes } from 'styled-components'

const Button = ({ type, disabled, label, onClick, align, loading, ...props }) => {
  return (
    <ButtonStyled type={type} disabled={disabled || loading} onClick={onClick} {...props}>
      <Label>{loading ? <Loader src='/assets/icons/spinner.svg' /> : label}</Label>
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button(
  ({ margin = 0, padding = 0, width = '180px' }) => `
  margin: ${margin};
  padding: ${padding};
  background-color: #076f9f;
  border-radius: 24px;
  width: ${width};
  height: 40px;
  border: none;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  outline: none;
  &:hover {
    background-color: #07567B;
  }
  &:disabled {
    background-color: #9E9E9E;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
)

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.img`
  animation: ${rotate} 2s linear infinite;
`

const Label = styled.span`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
`

export { Button }
