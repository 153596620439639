import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'

import { ModalBase, Row, NumberInput } from '../../../../components/v2Components'
import { Button } from './style'

import { updateImagesHome } from '../../../../services/v3'

const defaultValue = { order: '' }

const ModalEditOrder = ({ open, onClose, fetchInfos, img }) => {
  const { handleSubmit, control, errors, reset } = useForm({
    mode: 'all',
    defaultValues: defaultValue
  })

  const onSubmit = useCallback(
    async (data, img) => {
      try {
        const orderArray = img

        for (var i in orderArray) {
          if (Number(orderArray[i].order) === Number(data.order)) {
            orderArray[i].order = Number(orderArray[i].order) + 1
          }

          if (orderArray[i].id === open.id) {
            orderArray[i].order = Number(data.order)
          }
        }

        orderArray.sort((a, b) => Number(a.order) - Number(b.order))

        for (var x in orderArray) {
          if (Number(x) < orderArray.length - 1) {
            if (Number(orderArray[x].order) === Number(orderArray[Number(x) + 1].order)) {
              orderArray[Number(x) + 1].order = Number(orderArray[Number(x) + 1].order) + 1
            }
          }
        }

        await Promise.all(
          orderArray.map(async file => {
            await updateImagesHome(file.id, { order: String(file.order) })
          })
        )

        toast.success('Sucesso ao ordenar fotos.')
        onClose(false)
      } catch (error) {
        console.log(error.message)
        toast.error('Erro ao ordenar fotos.')
      }
    },
    [open]
  )

  useEffect(() => {
    if (open) {
      reset({
        order: open.order
      })
    }
  }, [open])

  return (
    <ModalBase
      open={open ? true : false}
      title='Ordem da foto'
      onClose={onClose}
      iconClose={true}
      width='100%'
      height='100%'
    >
      <form onSubmit={handleSubmit(data => onSubmit(data, img))}>
        <NumberInput
          style={{ minWidth: '100px' }}
          width='100%'
          mb='40px !important'
          id='order'
          label='Ordem'
          name='order'
          control={control}
          error={errors.order}
        />
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button type='submit'>Salvar</Button>
        </Row>
      </form>
    </ModalBase>
  )
}

export default ModalEditOrder
