import client from '../../providers/fetchClient'

export const getUsers = params => client.get('/v3/users', { params })

export const getUsersWithParams = params => client.get(`/v3/users?${params}`)

export const getUserById = id => client.get(`/v3/users/${id}`)

export const getUserByCpf = cpf => client.get(`/v3/users?cpf=${cpf}`)

export const getRoles = () => client.get('/v3/roles')

export const deleteUser = id => client.delete(`/v3/users/${id}`)
