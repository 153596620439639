import React, { useState, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Input, Select } from '../../components'

import { banksArray } from '../../constants/banksArray'
import { getCompanyInfos } from '../../services/v3'

const ProcessCampaignCompanyForm = ({ campaign, setFieldValue, setValues, values }) => {
  const [banksSuggestion, setBanksSuggestion] = useState(banksArray)

  useEffect(_ => {
    if (values.company_cnpj) {
      const parsedCnpj = values.company_cnpj.replace(/\D+/g, '')
      if (parsedCnpj.length === 14) {
        searchCompanyByCNPJ(parsedCnpj)
      }
    } else {
      if (!!campaign.company && !!campaign.company.cnpj) {
        setFieldValue('company_cnpj', campaign.company.cnpj)
        if (campaign.company.cnpj.length === 14) {
          searchCompanyByCNPJ(campaign.company.cnpj)
        }
      }
    }
  }, [])

  const searchCompanyByCNPJ = async parsedCnpj => {
    try {
      const companyInfo = await getCompanyInfos(parsedCnpj)
      const [phonePrefix, phone] = companyInfo.telefone.split(' ')
      setValues({
        ...values,
        company_cnpj: parsedCnpj,
        company_name: companyInfo.nome,
        company_fantasy_name: companyInfo.fantasia,
        company_zip_code: companyInfo.cep,
        company_street: companyInfo.logradouro,
        company_number: companyInfo.numero,
        company_city: companyInfo.municipio,
        company_complement: companyInfo.complemento,
        company_neighborhood: companyInfo.bairro,
        company_uf: companyInfo.uf,
        company_country: 'BRASIL',
        company_email: '',
        company_phone_prefix: phonePrefix,
        company_phone: phone
      })
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleDocumentChange = e => {
    setFieldValue('company_cnpj', e.target.value)
    const parsedCnpj = e.target.value.replace(/\D+/g, '')
    if (parsedCnpj.length === 14) {
      searchCompanyByCNPJ(parsedCnpj)
    }
  }

  const handleBankCodeChange = e => {
    setFieldValue('company_bank_code', e.target.value)
    setBanksSuggestion(banksArray.filter(bank => bank.includes(e.target.value)))
  }

  const handleSuggestionClick = e => setFieldValue('company_bank_code', e.target.childNodes[0].data)

  return (
    <Container>
      <DivisionContainer>
        <InputContainer>
          <Field
            name='company_cnpj'
            render={({ field }) => (
              <Input
                mask='99.999.999/9999-99'
                placeholder='Ex: 24.541.933/0001-54'
                label='CNPJ'
                {...field}
                onChange={handleDocumentChange}
              />
            )}
          />
          <ErrorMessage name='company_cnpj' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_name'
            render={({ field }) => (
              <Input placeholder='Ex: Sopetra Rolamentos e Peças LTDA' label='Nome da empresa' {...field} />
            )}
          />
          <ErrorMessage name='company_fantasy_name' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_fantasy_name'
            render={({ field }) => (
              <Input placeholder='Ex: Sopetra Rolamento e Peças' label='Nome fantasia da empresa' {...field} />
            )}
          />
          <ErrorMessage name='company_fantasy_name' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_zip_code' render={({ field }) => <Input mask='99999-999' label='CEP' {...field} />} />
          <ErrorMessage name='company_zip_code' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_street' render={({ field }) => <Input label='Rua' {...field} />} />
          <ErrorMessage name='company_street' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_number' render={({ field }) => <Input label='Número' {...field} />} />
          <ErrorMessage name='company_number' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_city' render={({ field }) => <Input label='Cidade' {...field} />} />
          <ErrorMessage name='company_city' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_complement' render={({ field }) => <Input label='Complemento' {...field} />} />
          <ErrorMessage name='company_complement' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_neighborhood' render={({ field }) => <Input label='Bairro' {...field} />} />
          <ErrorMessage name='company_neighborhood' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_uf' render={({ field }) => <Input label='UF' {...field} />} />
          <ErrorMessage name='company_uf' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_country' render={({ field }) => <Input label='País' {...field} />} />
          <ErrorMessage name='company_country' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_email'
            render={({ field }) => <Input style={{ background: '#4ab34e24' }} label='E-mail' {...field} />}
          />
          <ErrorMessage name='company_email' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_phone_prefix' render={({ field }) => <Input label='Prefixo' mask='99' {...field} />} />
          <ErrorMessage name='company_phone_prefix' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field name='company_phone' render={({ field }) => <Input label='Telefone' {...field} />} />
          <ErrorMessage name='company_phone' component={Error} />
        </InputContainer>
      </DivisionContainer>
      <DivisionContainer>
        <InputContainer>
          <Field
            name='company_bank_code'
            render={({ field }) => (
              <Input
                style={{ background: '#4ab34e24' }}
                label='Código do banco'
                {...field}
                autocomplete='new-password'
                type='text'
                onChange={handleBankCodeChange}
              />
            )}
          />
          <Suggestions>
            {banksSuggestion.map(bank => (
              <Suggestion key={bank} onClick={handleSuggestionClick} value={bank}>
                {bank}
              </Suggestion>
            ))}
          </Suggestions>
          <ErrorMessage name='company_bank_code' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_bank_agency'
            render={({ field }) => <Input green={true} label='Código da agência' mask='99999' {...field} />}
          />
          <ErrorMessage name='company_bank_agency' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_bank_account'
            render={({ field }) => <Input green={true} label='Conta bancária' mask='9999999999999' {...field} />}
          />
          <ErrorMessage name='company_bank_account' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_bank_account_digit'
            render={({ field }) => <Input green={true} label='Digito da conta' mask='9' {...field} />}
          />
          <ErrorMessage name='company_bank_account_digit' component={Error} />
        </InputContainer>
        <InputContainer>
          <Field
            name='company_bank_type'
            render={({ field }) => (
              <Select label='Tipo de conta' field={field} style={{ background: '#4ab34e24' }}>
                <option value={''} disabled />
                <option value={2}>Conta corrente</option>
                <option value={1}>Poupança</option>
              </Select>
            )}
          />
          <ErrorMessage name='company_bank_type' component={Error} />
        </InputContainer>
      </DivisionContainer>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const DivisionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`

const Suggestions = styled.ul`
  visibility: hidden;
  height: 200px;
  width: 300px;
  flex-direction: column;
  overflow: hidden;
  transform: translateY(120px);
  position: absolute;
  z-index: 5;
  background: #fff;
  &:focus {
    visibility: visible;
  }
  &:hover {
    visibility: visible;
  }
`

const Suggestion = styled.li`
  font-weight: bold;
  padding: 1em;
  color: #333;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 0.8em;
  transition: all 0.3s;
  &:focus {
    ${Suggestions} {
      visibility: visible;
    }
  }
  &:hover {
    background: #ddd;
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

const InputContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  &:focus-within {
    ${Suggestions} {
      visibility: visible;
    }
  }
  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`

export default ProcessCampaignCompanyForm
