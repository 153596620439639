import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

yup.addMethod(yup.string, 'cpf', function() {
  return yup.mixed().test('validation cpf', 'Insira um CPF valido', cpf => {
    if (!cpf) return false
    cpf = cpf.replace(/[\s.-]*/gim, '')
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if (resto === 10 || resto === 11) resto = 0
    if (resto !== parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var j = 1; j <= 10; j++) soma = soma + parseInt(cpf.substring(j - 1, j)) * (12 - j)
    resto = (soma * 10) % 11
    if (resto === 10 || resto === 11) resto = 0
    if (resto !== parseInt(cpf.substring(10, 11))) return false
    return true
  })
})

export const EmployeesSchema = yupResolver(
  yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    celphone: yup.string().required(),
    date: yup.date('Data inválida').required(),
    document_number: yup
      .string()
      .required()
      .cpf(),
    file: yup
      .array()
      .required('Campo obrigatório')
      .nullable()
  })
)

export const editEmployeesSchema = yupResolver(
  yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    celphone: yup.string().required(),
    date: yup.date('Data inválida').required(),
    document_number: yup
      .string()
      .required()
      .cpf()
  })
)

export const HomeSchema = yupResolver(
  yup.object().shape({
    question: yup.string().required()
  })
)
