import styled from '@emotion/styled'
import { Form } from 'formik'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 10%;
  flex: 1;
`

export const FormTitle = styled.p`
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2em;
`

export const Container = styled.div`
  margin-top: 120px;
  display: flex;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledForm = styled(Form)`
  display: flex;
  padding: 1.5em;
  border: 1px solid lightgray;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
`
