import React, { useEffect, useState, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'
import { Formik, Field } from 'formik'

import { MaterialButton, Input, ParcelSimulatorCard, LoadingComponent } from '../../components'

import {
  RowContainer,
  Container,
  ButtonsContainer,
  ContainerButton,
  EditIcon,
  InputsContainer,
  InputContainer,
  CardTitle,
  BankInfoContainer,
  Form,
  Amount,
  AmountContainer
} from './style'

import { formatMoney, formattedParcels } from '../../helpers'
import { IDLE, FETCHING, SUBMITTING } from '../../constants/status'
import {
  getCampaignById,
  getOrders,
  simulateInstallmentBmp,
  getCampaignInstallmentValues,
  updateCampaignInstallmentValues
} from '../../services/v3'
import { campaignStatuses } from '../../constants'

const Simulator = ({ match, updateCampaignStatus }) => {
  const [selectedAmount, setSelectedAmount] = useState(null)
  const [distinctAmounts, setDistinctAmounts] = useState([])
  const [confirmedAmounts, setConfirmedAmounts] = useState([])
  const [campaignInfo, setCampaignInfo] = useState(null)
  const [parcels, setParcels] = useState([])
  const [status, setStatus] = useState(IDLE)

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setStatus(FETCHING)
        const [{ data: orders }, { data: simulatedOrders }, { data: campaign }] = await Promise.all([
          getOrders({ campaign_id: match.params.id }),
          getCampaignInstallmentValues(match.params.id),
          getCampaignById(match.params.id)
        ])

        setDistinctAmounts(
          orders
            .reduce((acc, { amount }) => (!acc.includes(amount) ? [...acc, amount] : acc), [])
            .sort((a, b) => a - b)
            .map(amount => String(amount))
        )

        if (simulatedOrders.installments) {
          setConfirmedAmounts(simulatedOrders.installments.map(({ OriginalOrder }) => OriginalOrder))
        }

        setCampaignInfo(campaign)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao iniciar o simulador BMP')
      } finally {
        setStatus(IDLE)
      }
    }
    fetchInfos()
  }, [])

  const handleClick = (value, idx) => {
    setSelectedAmount(selectedAmount && idx === selectedAmount.idx ? null : { idx: idx, value: value })
  }

  const confirmAmount = async amount => {
    try {
      const { data: campaignInstallmentValues } = await getCampaignInstallmentValues(match.params.id)
      await updateCampaignInstallmentValues({
        campaign_id: match.params.id,
        installments: formattedParcels(campaignInstallmentValues, amount)
      })

      const newConfirmedAmount = [...confirmedAmounts, String(amount.value)]
      setConfirmedAmounts(newConfirmedAmount)

      if (checkAllSimulated(newConfirmedAmount)) {
        updateCampaignStatus(campaignStatuses.SEND_PROPOSAL)
      }
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao salvar o valor simulado')
    }
  }

  const deleteAmount = amount => {
    setParcels(parcels.filter(parcel => parcel.idx !== amount.idx))
  }

  const clearSimulation = () => {
    setParcels([])
    setConfirmedAmounts([])
  }

  const handleSubmit = async values => {
    try {
      setStatus(SUBMITTING)
      const body = {
        campaign_id: match.params.id,
        tac_percentage: values.tacPercentage.replace(',', '.').replace(/[\s%]/g, ''),
        dto: {
          OriginalOrder: selectedAmount.value,
          Prazo: values.Prazo,
          NroDiasAcrescimo: values.NroDiasAcrescimo,
          PercJurosNegociado: values.PercJurosNegociado,
          TipoPessoa: values.TipoPessoa
        },
        parametros: [
          {
            Nome: 'string',
            Valor: selectedAmount.value
          }
        ]
      }

      const { data: simulatedOrder } = await simulateInstallmentBmp(body)

      if (simulatedOrder.Msg !== 'OK') {
        throw new Error('Ocorreu um erro ao simular o valor selecionado, por favor tente novamente')
      }

      setParcels([
        ...parcels.filter(({ OriginalOrder }) => OriginalOrder !== simulatedOrder.dto.OriginalOrder),
        { ...simulatedOrder, ...simulatedOrder.dto, idx: selectedAmount.idx, value: selectedAmount.value }
      ])
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao simular a parcela.')
    } finally {
      setStatus(IDLE)
    }
  }

  function enableAmount(pledge, index) {
    setConfirmedAmounts(confirmedAmounts.filter(amount => String(amount) !== pledge))
    setSelectedAmount({ value: pledge, idx: index })
  }

  const initialRateMonth = () => {
    if (campaignInfo) {
      const rateMonth = campaignInfo.rate_month.toString()
      if (rateMonth.includes('.')) return rateMonth.replace('.', ',')
      else if (!rateMonth.includes(',')) return rateMonth + ',00'
      return rateMonth
    }
    return ''
  }

  const initalTAC = () => {
    if (campaignInfo) {
      const tacIsValid = campaignInfo.TAC
      if (tacIsValid !== null) {
        const TAC = tacIsValid.toString()
        if (TAC.includes('.')) return TAC.replace('.', ',')
        else if (!TAC.includes(',')) return TAC + ',00'
        return TAC
      }
    }
    return '6,00 %'
  }

  const checkAllSimulated = confirmedAmounts => {
    return distinctAmounts.every(pledge => confirmedAmounts.includes(String(pledge)))
  }

  if (status === FETCHING) {
    return (
      <Container>
        <LoadingComponent />
      </Container>
    )
  }

  return (
    <Container>
      <CardTitle>
        Selecione um valor e preencha o formulário abaixo para realizar o cálculo das parcelas. Os valores desabilitados
        são os que já possuem valores registrados no banco de dados.
      </CardTitle>
      <Formik
        initialValues={{
          Prazo: campaignInfo ? campaignInfo.installments : '',
          NroDiasAcrescimo: campaignInfo ? campaignInfo.grace_period : '',
          PercJurosNegociado: initialRateMonth(),
          TipoPessoa: '2',
          tacPercentage: initalTAC()
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Fragment>
            <ButtonsContainer>
              <AmountContainer>
                {distinctAmounts.map((pledge, idx) => (
                  <Amount key={idx}>
                    <MaterialButton
                      width='190px'
                      disabled={confirmedAmounts.includes(String(pledge))}
                      onClick={_ => handleClick(pledge, idx)}
                      backgroundColor={selectedAmount && selectedAmount.idx === idx ? '#076f9f' : 'white'}
                      color={selectedAmount && selectedAmount.idx === idx ? 'white' : '#076f9f'}
                      variant={selectedAmount && selectedAmount.idx === idx ? 'contained' : 'outlined'}
                      label={`R$ ${formatMoney(pledge)}`}
                    />
                    {confirmedAmounts.includes(String(pledge)) && (
                      <EditIcon onClick={_ => enableAmount(String(pledge), idx)} data-tip='Editar valor' />
                    )}
                  </Amount>
                ))}
              </AmountContainer>
              <ContainerButton>
                <MaterialButton
                  disabled={!checkAllSimulated(confirmedAmounts)}
                  width={230}
                  onClick={clearSimulation}
                  label='Refazer simulação'
                />
              </ContainerButton>
            </ButtonsContainer>
            <RowContainer>
              <Form>
                <InputsContainer>
                  <InputContainer>
                    <Field
                      name='Prazo'
                      render={({ field }) => <Input disabled mask='999' {...field} label='Prazo' />}
                    />
                  </InputContainer>

                  <InputContainer>
                    <Field
                      name='NroDiasAcrescimo'
                      render={({ field }) => <Input disabled mask='999' {...field} label='Carência' />}
                    />
                  </InputContainer>

                  <InputContainer>
                    <Field
                      name='PercJurosNegociado'
                      render={({ field }) => <Input disabled percentage {...field} label='Taxa juros a.m.' />}
                    />
                  </InputContainer>

                  <InputContainer>
                    <Field
                      name='TipoPessoa'
                      render={({ field }) => <Input disabled mask='99' {...field} label='Tipo de pessoa' />}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Field
                      name='tacPercentage'
                      render={({ field }) => <Input disabled percentage {...field} label='Taxa TAC' />}
                    />
                  </InputContainer>
                </InputsContainer>
                <MaterialButton
                  type='submit'
                  label='Enviar'
                  disabled={!selectedAmount}
                  loading={status === SUBMITTING}
                  width={'150px'}
                />
              </Form>
              <BankInfoContainer>
                {parcels.map(parcel => (
                  <ParcelSimulatorCard
                    key={parcel.OriginalOrder}
                    deleteAmount={deleteAmount}
                    confirmAmount={confirmAmount}
                    parcel={parcel}
                  />
                ))}
              </BankInfoContainer>
            </RowContainer>
          </Fragment>
        )}
      </Formik>
      <ReactTooltip />
    </Container>
  )
}

export default Simulator
