import React, { useEffect } from 'react'
import styled from 'styled-components'

import { NumberInput } from '../../components/FormComponents'
import { SelectStatusLead } from '../../components'
import { CardEditIcon } from './'

import { formatValueToBrl, sanitizeDate } from '../../helpers'
import { leadModalCards } from '../../constants'

import { Card, EditInputsContainer } from './styledComponents'

const LeadInfosCard = ({ lead, isEditing, handleEditCard, control, errors, setValue }) => {
  useEffect(() => {
    if (!isEditing) return
    setValue('lending_amount', formatValueToBrl(lead.lending_amount))
  }, [isEditing])

  return (
    <Card style={{ ...(!isEditing && { justifyContent: 'center', alignItems: 'center' }) }}>
      <CardEditIcon onClick={() => handleEditCard(leadModalCards.LEAD_INFOS)} />
      {isEditing ? (
        <EditInputsContainer>
          <NumberInput
            name='lending_amount'
            control={control}
            error={errors['lending_amount']}
            label='Valor do empréstimo *'
            type='currency'
            defaultValue=''
            dense
          />
        </EditInputsContainer>
      ) : (
        <>
          <DesiredAmountTitle>Valor do empréstimo</DesiredAmountTitle>
          <Date>{sanitizeDate(lead.created_at)}</Date>
          <DesiredAmount>{formatValueToBrl(lead.lending_amount)}</DesiredAmount>
          <SelectStatusLead lead={lead} />
        </>
      )}
    </Card>
  )
}

const DesiredAmountTitle = styled.p`
  font-weight: bold;
  font-size: 33px;
  color: #076f9f;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const Date = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #076f9f;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 23px;
  }
`

const DesiredAmount = styled.p`
  margin: 10px 0px;
  font-weight: bold;
  font-size: 33px;
  line-height: 45px;
  color: #076f9f;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 30px;
  }
`

export default LeadInfosCard
