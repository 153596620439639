import React, { useEffect, useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'

import { TextInput, NumberInput, Select } from '../../components/FormComponents'
import { CardEditIcon } from './'

import { formatCNPJ, getSiteLink } from '../../helpers'
import { getCompanyInfos } from '../../services/v3'
import { CNPJ_LENGTH, leadModalCards } from '../../constants'
import { LeadContext } from '../../contexts'

import { Card, EditInputsContainer } from './styledComponents'

const CompanyCard = ({ lead, isEditing, handleEditCard, control, errors, setValue, watch }) => {
  const [lastValue, setLastValue] = useState(null)
  const cnpjValue = watch('cnpj')

  const { responsibles } = useContext(LeadContext)

  useEffect(() => {
    if (!isEditing) return
    setValue('company_name', lead.company_name)
    setValue('cnpj', lead.cnpj)
    setValue('responsible', lead.responsible)
    setLastValue(lead.cnpj)
  }, [isEditing])

  const setCompanyName = useCallback(
    async cnpj => {
      try {
        const companyInfo = await getCompanyInfos(cnpj)
        setValue('company_name', companyInfo.nome)
      } catch (error) {
        if (error.message === 'Erro ao buscar o CNPJ: undefined.') {
          toast.error('Erro ao buscar CNPJ')
        } else {
          toast.error(error.message)
        }
      }
    },
    [setValue]
  )

  useEffect(() => {
    if (!(cnpjValue && isEditing)) return

    const sanitizedCnpj = cnpjValue.replace(/\D/g, '')

    if (sanitizedCnpj.length === CNPJ_LENGTH && lastValue !== sanitizedCnpj) {
      setLastValue(sanitizedCnpj)
      setCompanyName(sanitizedCnpj)
    }
  }, [cnpjValue, setCompanyName])

  return (
    <Card style={{ justifyContent: 'center', alignItems: 'center' }}>
      <CardEditIcon onClick={() => handleEditCard(leadModalCards.COMPANY)} />
      {isEditing ? (
        <EditInputsContainer>
          <NumberInput
            name='cnpj'
            control={control}
            error={errors['cnpj']}
            label='CNPJ'
            defaultValue=''
            format='##.###.###/####-##'
            width='200px'
            dense
          />
          <TextInput
            name='company_name'
            control={control}
            error={errors['company_name']}
            label='Empresa *'
            defaultValue=''
            width='400px'
            dense
          />
          <Select
            name='responsible'
            control={control}
            error={errors['responsible']}
            label='Responsável'
            width='280px'
            dense
            options={responsibles.map(item => ({ value: item, label: item }))}
          />
        </EditInputsContainer>
      ) : (
        <>
          <CompanyName>{lead.company_name}</CompanyName>
          <Cnpj>{formatCNPJ(lead.cnpj)}</Cnpj>
          <ContainerLink
            href={`${getSiteLink()}/admin/campanhas/criar?company_name=${lead.company_name}&cnpj=${
              lead.cnpj
            }&desired_amount=${lead.lending_amount}`}
            target='popup'
          >
            <Link>Campanha</Link>
            <StyledAddCircleOutlineOutlinedIcon />
          </ContainerLink>
          <Responsible>{lead.responsible}</Responsible>
        </>
      )}
    </Card>
  )
}

const Responsible = styled.span`
  margin: 10px;
  color: #076f9f;
  font-weight: 500;
`

const CompanyName = styled.p`
  font-weight: bold;
  font-size: 33px;
  line-height: 45px;
  color: #076f9f;
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  }
`

const Cnpj = styled.p`
  margin: 10px 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #076f9f;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 23px;
    margin: 8px 0px;
  }
`

const StyledAddCircleOutlineOutlinedIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: #076f9f;
  margin-left: 5px;
`

const Link = styled.span`
  font-size: 18px;
  color: #076f9f;
  text-decoration-line: none;
`

const ContainerLink = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export default CompanyCard
