import client from '../providers/fetchClient'

export const updatePledge = pledge => client.put(`/v2/pledges/${pledge.id}`, pledge)

export const createBCC = values => client.post('/v2/ccbs/', values)

export const createPledge = pledge => client.post('/v2/pledges/', pledge)

export const deletePledge = pledge => client.delete(`/v2/pledges/${pledge.id}`)

export const getCampaignsPledges = campaignId => client.get(`/v2/campaigns/${campaignId}/pledges`)

export const getUserPledges = kvdId => client.get(`/v2/pledges/kvd_id/${kvdId}`)
