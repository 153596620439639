import styled from 'styled-components'
import { Dialog as MaterialModal } from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

import Row from '../Row'
import Column from '../Column'
import { IconClear } from '../icons'

export const Modal = styled(MaterialModal)`
  div {
    &.MuiPaper-elevation24 {
      box-shadow: none;
      border-radius: 8px;
    }

    &.MuiDialog-paperWidthXl {
      width: 100%;
      max-width: 1000px;
    }
  }
`

export const ModalContainer = styled(Column)`
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
  padding: 24px;
`
export const Close = styled(IconClear)`
  cursor: pointer;
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: ${props => props.flexDirection};
  width: 100%;
`
