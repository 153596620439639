import styled from 'styled-components'

export const CampaignsContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 30px;
  margin-left: 32px;
  margin-right: 32px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  display: grid;
  /* grid-gap: 32px 10px; */
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr auto; */
  grid-template-areas:
    'Header Header'
    'Details Timeline'
    'Accordion Timeline'
    '. Timeline';
`
