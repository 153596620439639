import React from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { wizardElements } from '../../constants'
const Wizard = props => {
  return (
    <Container>
      {wizardElements.map(element => (
        <Element key={element.index} last={element.index === wizardElements.length}>
          <Title isSelected={element.index === props.step}>
            <Number
              onClick={() => props.history.push(`/campanhas/${props.campaignId}/${element.to}`)}
              isSelected={element.index === props.step}
            >
              {element.index}
            </Number>
            {element.title}
          </Title>
        </Element>
      ))}
    </Container>
  )
}

const Title = styled.p`
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
`

const Number = styled.span`
  display: inline-grid;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  align-content: center;
  align-self: center;
  padding: 15px;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.blueKavod : theme.colors.gray)};
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.white : theme.colors.darkGray)};
  border-radius: 50%;
  margin-right: 0.5em;
`

const Container = styled.div`
  padding: 0 1em;
  width: 290px;
`

const Element = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8em;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-bottom: 45px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;

  &::after {
    content: '';
    width: 2px;
    height: ${({ last }) => (last ? '0' : '30px')};
    background: ${({ theme }) => theme.colors.mediumGray};
    position: absolute;
    margin-left: 18px;
    margin-top: 45px;
  }

  &:last-child {
    &::after {
      width: 0;
      height: 0;
    }
  }

  p {
    margin: 0;
  }
`

export default withTheme(Wizard)
