import client from '../../providers/fetchClient'

export const createDarfs = (campaign_id, installment_number) =>
  client.post('/v3/darfs', { campaign_id, installment_number })

export const getDarfs = (campaign_id, installment_number) =>
  client.get(`/v3/darfs?campaign_id=${campaign_id}&installment_number=${installment_number}`)

export const getCnab = (darf_ids, file_date, payment_date, due_date, counting_period) =>
  client.get(
    `/v3/darfs/cnabs?darf_ids=${darf_ids}&file_date=${file_date}&payment_date=${payment_date}&due_date=${due_date}&counting_period=${counting_period}`
  )

export const simulateDarfs = body => client.post('/v3/darfs/simulate', body)

export const deleteDarfs = darfs => client.delete(`/v3/darfs?${darfs}`)
