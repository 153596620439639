import React, { useContext, useEffect, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { Select, Autocomplete, Button } from '../../components/FormComponents'

import { editLeadIndicatorSchema } from '../../schemas'
import { LeadContext } from '../../contexts'
import { updateLeads } from '../../services/v3'
import { origins as originsConsts } from '../../constants'

const PopoverEditIndicatedBy = ({ lead, anchor, setAnchor }) => {
  const [isEditingPartner, setIsEditingPartner] = useState(false)

  const { handleSubmit, control, errors, watch, setValue, reset } = useForm({
    resolver: editLeadIndicatorSchema
  })

  const { indicatorsOptions, origins, partnersOptions, refresh, refetchIndicators } = useContext(LeadContext)

  const originValue = watch('origin')

  useEffect(() => {
    const selectedOrigin = origins.find(({ id }) => id === lead.lead_origin_id)
    const isEditingPartner = selectedOrigin && selectedOrigin.name === originsConsts.PARTNER
    setIsEditingPartner(isEditingPartner)
    reset({
      origin: selectedOrigin ? selectedOrigin.name : '',
      indicated_by: lead.indicated_by
        ? { value: isEditingPartner ? lead.partner_id : lead.indicated_by, label: lead.indicated_by }
        : ''
    })
  }, [lead])

  const onSubmit = async values => {
    try {
      const selectedOrigin = origins.find(({ name }) => name === originValue)
      const body = {
        lead_origin_id: Number(selectedOrigin.id),
        indicated_by: (values.indicated_by && values.indicated_by.label) || '',
        partner_id: isEditingPartner ? values.indicated_by.value : null
      }
      await updateLeads(lead.id, body)
      setAnchor(null)
      refresh()
      refetchIndicators()
      toast.success('Indicação atualizada com sucesso')
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao atualizar a indicação')
    }
  }

  useEffect(() => {
    if (!originValue) return
    const newIsEditingPartner = originValue === originsConsts.PARTNER
    if (newIsEditingPartner !== isEditingPartner) {
      setValue('indicated_by', '')
    }
    setIsEditingPartner(newIsEditingPartner)
  }, [originValue])

  return (
    <Popover
      id='indicated_by-popover'
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Select
          name='origin'
          control={control}
          error={errors['origin']}
          label='Origem *'
          defaultValue=''
          dense
          margin='10px 0px 0px 0px'
          options={origins.map(({ name }) => ({ value: name, label: name }))}
        />
        <Autocomplete
          name='indicated_by'
          control={control}
          error={errors['indicated_by']}
          label='Indicado Por *'
          dense
          creatable={!isEditingPartner}
          margin='10px 0px'
          options={isEditingPartner ? partnersOptions.filter(item => item.label !== '') : indicatorsOptions}
        />
        <Button label='Salvar' type='submit' width='220px' loading={false} />
      </Form>
    </Popover>
  )
}
const Form = styled.form`
  width: 240px;
  padding: 10px;
  padding-top: 0px;
`

export default PopoverEditIndicatedBy
