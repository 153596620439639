import { sanitizeMoney } from '../helpers'

export const formattedParcels = (response, parcel) => {
  const newInstallment = {
    OriginalOrder: sanitizeMoney(parcel.value),
    VlrSolicitado: sanitizeMoney(parcel.VlrSolicitado),
    Prazo: parcel.Prazo,
    NroDiasAcrescimo: parcel.NroDiasAcrescimo,
    TipoPessoa: parcel.TipoPessoa,
    VlrIOF: sanitizeMoney(parcel.VlrIOF),
    VlrTAC: parcel.VlrTAC,
    VlrParcela: sanitizeMoney(parcel.VlrParcela),
    PercJurosNegociado: parcel.PercJurosNegociado
  }

  if (!response) {
    return [newInstallment]
  }

  return [
    ...response.installments.filter(({ OriginalOrder }) => OriginalOrder !== sanitizeMoney(parcel.value)),
    newInstallment
  ]
}

export const formatAnnualRate = value => {
  if (isNaN(parseFloat(value))) {
    return 0
  } else {
    return parseFloat(((parseFloat(value) / 100 + 1) ** 12 - 1) * 100).toFixed(2)
  }
}
