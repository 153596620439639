import React from 'react'

import PropTypes from 'prop-types'

import { Button } from '../../../../components/v2Components'

import { Container, ButtonContainer } from './styled'

const DeleteModal = ({ deleteInvestment, item, close }) => {
  return (
    <Container show={!!item}>
      <section>
        <div>
          <h1>Excluir investimento</h1>

          <button style={{ background: 'transparent' }} onClick={close}>
            <img src='/assets/images/closemodal.svg' alt='Botão fechar' />
          </button>
        </div>

        <p>Tem certeza que deseja excluir este investimento?</p>

        <ButtonContainer>
          <Button onClick={close} variant='outlined'>
            Cancelar
          </Button>
          <Button onClick={() => deleteInvestment(item)}>Excluir</Button>
        </ButtonContainer>
      </section>
    </Container>
  )
}

DeleteModal.propTypes = {
  deleteInvestment: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

export default DeleteModal
