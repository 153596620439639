export const leadStatusBgColors = {
  Novo: '#fbbc04',
  'Em análise': '#4285f4',
  'Aguardando documentação': '#46bdc6',
  'Aguardando comitê': '#ffffff',
  'Proposta enviada': '#ffffff',
  'Análise de crédito': '#ffffff',
  'Proposta recusada': '#fbebec',
  'Proposta aceita': '#edf7ed',
  'Campanha criada': '#3dc6ef',
  'Em captação': '#076f9f',
  Concluído: '#34a853',
  'Não atingiu a meta': '#999999',
  'Campanha deletada': '#999999',
  Negado: '#ea4335'
}

export const leadSatusFontColor = {
  Novo: '#ffffff',
  'Em análise': '#ffffff',
  'Aguardando documentação': '#ffffff',
  'Aguardando comitê': '#46bdc6',
  'Proposta enviada': '#34a853',
  'Análise de crédito': '#4285f4',
  'Proposta recusada': '#ea4335',
  'Proposta aceita': '#34a853',
  'Campanha criada': '#000000',
  'Em captação': '#ffffff',
  Concluído: '#ffffff',
  'Não atingiu a meta': '#ffffff',
  'Campanha deletada': '#ffffff',
  Negado: '#ffffff'
}

export const leadStatusBorderColors = {
  Novo: '#fbbc04',
  'Em análise': '#4285f4',
  'Aguardando documentação': '#46bdc6',
  'Aguardando comitê': '#46bdc6',
  'Proposta enviada': '#34a853',
  'Análise de crédito': '#4285f4',
  'Proposta recusada': '#fbebec',
  'Proposta aceita': '#edf7ed',
  'Campanha criada': '#3dc6ef',
  'Em captação': '#076f9f',
  Concluído: '#34a853',
  'Não atingiu a meta': '#999999',
  'Campanha deletada': '#999999',
  Negado: '#ea4335'
}
