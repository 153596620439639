import React, { useState, useContext } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button } from '../../components/FormComponents'
import {
  ContactCard,
  CompanyCard,
  LeadInfosCard,
  CompanyInfosCard,
  LendingReasonCard,
  HistoryCard
} from '../../components/LeadCards'

import { updateLeads } from '../../services/v3'
import { LeadContext } from '../../contexts'
import { formatCurrencyToBack, formatName } from '../../helpers'
import { leadModalCards } from '../../constants'
import { editLeadSchema } from '../../schemas'

import './modal-details.css'

const ModalDetails = ({ isOpen, onClose, lead }) => {
  const [editingCards, setEditingCards] = useState({})

  const { refresh } = useContext(LeadContext)

  const { control, errors, handleSubmit, setValue, watch, getValues } = useForm({
    resolver: editLeadSchema
  })

  const onSubmit = async values => {
    try {
      const body = {
        ...(values.cnpj !== undefined && { cnpj: values.cnpj.replace(/\D/g, '') }),
        ...(values.company_name && { company_name: values.company_name }),
        ...(values.lending_amount && { lending_amount: formatCurrencyToBack(values.lending_amount) }),
        ...(values.first_name && { first_name: formatName(values.first_name) }),
        ...(values.last_name !== undefined && { last_name: formatName(values.last_name) }),
        ...(values.email && { email: values.email.toLowerCase() }),
        ...(values.celphone && {
          celphone: values.celphone.replace(/\D/g, '').slice(2),
          celphone_prefix: values.celphone.replace(/\D/g, '').slice(0, 2)
        }),
        ...(values.telephone !== undefined && {
          telephone: values.telephone.replace(/\D/g, '').slice(2),
          telephone_prefix: values.telephone.replace(/\D/g, '').slice(0, 2)
        }),
        ...(values.company_site !== undefined && { company_site: values.company_site }),
        ...(values.zip_code && { zip_code: values.zip_code.replace(/\D/g, '') }),
        ...(values.street && { street: values.street }),
        ...(values.number && { number: values.number }),
        ...(values.district && { district: values.district }),
        ...(values.complement !== undefined && { complement: values.complement }),
        ...(values.city && { city: values.city }),
        ...(values.uf && { uf: values.uf }),
        ...(values.average_monthly_revenue && {
          average_monthly_revenue: formatCurrencyToBack(values.average_monthly_revenue)
        }),
        ...(values.lending_reason && { lending_reason: values.lending_reason }),
        ...(values.company_history && { company_history: values.company_history }),
        ...(values.responsible && { responsible: values.responsible })
      }

      if (Object.keys(body).length === 0) return

      await updateLeads(lead.id, body)
      setEditingCards([])
      toast.success('Solicitação de crédito atualizada com o sucesso')
      refresh()
    } catch (error) {
      toast.error('Ocorreu um erro ao atualizar os dados da solicitação de crédito')
      console.log(error)
    }
  }

  const handleEditCard = card => {
    setEditingCards({
      ...editingCards,
      [card]: !editingCards[card]
    })
  }

  const handleCloseEditing = () => {
    setEditingCards({})
  }

  const cardProperties = {
    lead,
    handleEditCard,
    control,
    errors,
    setValue,
    watch,
    getValues
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      className='ModalDetails'
      overlayClassName='OverlayModalDetails'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <IconContainer>
          <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
        </IconContainer>
        <CardsContainer>
          <CompanyCard isEditing={editingCards[leadModalCards.COMPANY]} {...cardProperties} />
          <LeadInfosCard isEditing={editingCards[leadModalCards.LEAD_INFOS]} {...cardProperties} />
          <ContactCard isEditing={editingCards[leadModalCards.CONTACT_INFOS]} {...cardProperties} />
          <CompanyInfosCard isEditing={editingCards[leadModalCards.COMPANY_INFOS]} {...cardProperties} />
          <LendingReasonCard isEditing={editingCards[leadModalCards.LENDING_REASON]} {...cardProperties} />
          <HistoryCard isEditing={editingCards[leadModalCards.COMPANY_HISTORY]} {...cardProperties} />
        </CardsContainer>
        {Object.keys(editingCards).length > 0 && (
          <ButtonContainer>
            <Button type='submit' label='Salvar' margin='0px 10px' />
            <Button type='button' label='Cancelar' margin='0px 10px' onClick={handleCloseEditing} />
          </ButtonContainer>
        )}
      </form>
    </Modal>
  )
}

const ButtonContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const IconContainer = styled.div`
  width: 100%;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

export default ModalDetails
