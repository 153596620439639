import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'

import { ModalLoading, MaterialButton, Modal } from '../../components'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import { formatMoney } from '../../helpers'
import { IDLE, SUBMITTING, FETCHING } from '../../constants/status'
import {
  getCampaignResponse,
  processCampaignExtraFields,
  getCampaignById,
  updateCampaign,
  createInstallments
} from '../../services/v3'
import { campaignStatuses } from '../../constants'

const ModalSummary = ({ match, isOpen, setIsOpen, updateCampaignStatus }) => {
  const [proposal, setProposal] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const [status, setStatus] = useState(FETCHING)

  const fetchCampaignInfo = async () => {
    setStatus(FETCHING)
    try {
      const [{ data: campaignResponse }, { data: campaign }] = await Promise.all([
        getCampaignResponse(match.params.id),
        getCampaignById(match.params.id)
      ])
      setCampaign(campaign)
      setProposal(campaignResponse)
    } catch (error) {
      console.error(error)
    } finally {
      setStatus(IDLE)
    }
  }

  useEffect(() => {
    fetchCampaignInfo()
  }, [])

  const handleClick = () => setIsOpenDetails(!isOpenDetails)

  const handleSubmit = async () => {
    setStatus(SUBMITTING)
    try {
      const { data } = await processCampaignExtraFields(match.params.id)

      if (data[0].Msg === 'OK') {
        const operation_date = moment()
          .utc()
          .format('YYYY-MM-DD')

        await updateCampaign(campaign.id, {
          operation_date
        })
        await createInstallments({
          campaign_id: campaign.id,
          number_installments: campaign.installments,
          grace_period: campaign.grace_period,
          operation_date
        })

        toast.success(
          'A campanha foi rodada na BMP e os campos adicionais foram cadastrados. Restando apenas agora a validação das assinaturas.'
        )
      } else {
        toast.error('Os dados não foram preenchidos corretamente para a BMP, por favor, preencha-os novamente.')
        throw new Error()
      }
      updateCampaignStatus(campaignStatuses.PROPOSAL_STATUS)
    } catch {
      toast.error('Não foi possível processar os dados da campanha.')
    } finally {
      setStatus(IDLE)
    }
  }

  if (status === FETCHING || !campaign || !proposal) return <ModalLoading isOpen={true} setIsOpen={() => {}} />

  if (!proposal.usersProposal) {
    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Container>
          <Error>Ocorreu um erro ao enviar a campanha para a BMP, por favor verifique e envie novamente.</Error>
        </Container>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} closeOnEsc={false}>
      <Container>
        <Campaign>
          <h1>Resumo da operação</h1>
          <Row>
            <CampaignInfo>
              Dados relacionados à campanha: <span> {campaign.name}</span>
            </CampaignInfo>
            <CampaignInfo>
              Taxa mensal: <span>{campaign.rate_month} %</span>
            </CampaignInfo>
          </Row>
          <Row>
            <CampaignInfo>
              Empresa requerente: <span> {campaign.company.name}</span>
            </CampaignInfo>
            <CampaignInfo>
              Taxa anual: <span>{campaign.rate_anual} %</span>
            </CampaignInfo>
          </Row>
          <Row>
            <CampaignInfo>
              <span>Garantia da operação: </span>
              {proposal.warranty}
            </CampaignInfo>
          </Row>
          <Title onClick={handleClick}>
            Total de pedidos : {proposal.usersProposal.length}
            {isOpenDetails ? <ExpandLess style={{ fontSize: 30 }} /> : <ExpandMore style={{ fontSize: 30 }} />}
          </Title>
          {isOpenDetails && (
            <ProposalsContainer>
              {proposal.usersProposal.map(proposal => (
                <Proposal key={proposal.uuid}>
                  <span>Resumo do pedido</span>
                  <p>
                    <span>Código da operação: </span> {proposal.uuid}
                  </p>
                  <p>
                    <span>Usuário: </span> {proposal.user.user_profile.first_name}{' '}
                    {proposal.user.user_profile.last_name}
                  </p>
                  <p>
                    <span>E-mail: </span> {proposal.user.user_profile.email}{' '}
                  </p>
                  <p>
                    <span>Valor do pedido: </span> R$ {formatMoney(proposal.user.order)}
                  </p>
                </Proposal>
              ))}
            </ProposalsContainer>
          )}
          <MaterialButton
            onClick={handleSubmit}
            label='Confirmar operação'
            loading={status === SUBMITTING}
            width='230px'
          />
        </Campaign>
      </Container>
    </Modal>
  )
}

const Title = styled.p`
  display: flex;
  padding: 1em;
  cursor: pointer;
  justify-content: flex-start;
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  width: 100%;
`

const Proposal = styled.div`
  width: 300px;
  height: 250px;
  border: 1px solid lightgray;
  padding: 0.6em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 1em;

  span {
    font-weight: bold;
  }
`

const ProposalsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  padding: 1em 0;
  justify-content: center;
`

const CampaignInfo = styled.p`
  font-size: 1.2em;
  padding: 0 1em;
  span {
    font-weight: bold;
  }
`

const Campaign = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`

const Error = styled.span`
  text-align: center;
  font-size: 20px;
`

const Container = styled.div`
  padding: 0 1em;
  display: flex;
  flex: 1;
  max-height: 900px;
  max-width: 1374px;
`

export default ModalSummary
