import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

const Card = ({ card, wid = false }) => {
  return (
    <Container main={card.main} signature={card.signature} wid={wid} disabled={card.alreadySigned}>
      <p>
        {`${card.name} `}
        {card.information && (
          <button onClick={card.onClick}>
            <img src='/assets/images/info.svg' alt='Detalhes' />
          </button>
        )}
      </p>
      <label>{card.value}</label>
    </Container>
  )
}

Card.propTypes = {
  card: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    information: PropTypes.bool,
    main: PropTypes.bool,
    signature: PropTypes.bool,
    onClick: PropTypes.func
  }).isRequired
}

export default Card
