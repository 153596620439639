import React, { useEffect, useState, useRef, Fragment } from 'react'
import moment from 'moment-timezone'
import ReactTable from 'react-table-6'
import { FormControlLabel, Switch } from '@material-ui/core'
import { Edit, CheckCircle, HighlightOff } from '@material-ui/icons'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'

import { ModalTicketData } from './Components'
import { ExecutionFlag } from '../../routes/NewUserInvestments/Show/components'
import { LoadingComponent, MaterialButton, Modal as NewModalComponent } from '../../components'
import {
  ModalInstallmentDelay,
  ModalRemoveInstallmentDelay,
  ModalUploadCnab,
  ModalDarfCnab,
  ModalDarfDelete,
  ModalExecution
} from './Components'

import {
  Cell,
  ModalDescripion,
  ModalContent,
  ModalComponent,
  PayDateInput,
  PayDateContainer,
  CampaignName,
  ParcelsContainer,
  ParcelsContent,
  I,
  DateCell,
  DateText,
  ActionContainer,
  DownloadCSVContainer,
  DownloadCSVButton
} from './style'

import { sanitizeDate, formatValueToBrl } from '../../helpers'
import {
  getCampaignInstallments,
  getCampaignById,
  updateInstallment as updateInstallmentService,
  getInstallmentStatus,
  getInstallments,
  getDownloadInstallments
} from '../../services/v3'
import { installmentStatuses } from '../../constants'

const Parcels = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [installments, setInstallments] = useState([])
  const [campaign, setCampaign] = useState(null)
  const [editing, setEditing] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [payDate, setPayDate] = useState(null)
  const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] = useState(false)
  const [selectedCampaignInfos, setSelectedCampaignInfos] = useState(null)
  const [isOpenModalDarf, setIsOpenModalDarf] = useState(false)
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false)
  const [isDelayModalOpen, setIsDelayModalOpen] = useState(false)
  const [isRemoveDelayModalOpen, setIsRemoveDelayModalOpen] = useState(false)
  const [installmentStatus, setInstallmentStatus] = useState([])
  const [deleteDarfInfos, setDeleteDarfInfos] = useState(null)
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [campaignPaid, setCampaignPaid] = useState(false)
  const [executionModal, setExecutionModal] = useState(false)
  const [ticketDataModal, setTicketDataModal] = useState(false)

  const payDateRef = useRef('')

  const columns = [
    {
      Header: 'Nº parcela',
      accessor: 'installment_number',
      minWidth: 100,
      Cell: props => (
        <Cell>
          <p>{props.original.installment_number + '/' + installments.length}</p>
        </Cell>
      )
    },
    {
      Header: 'Valor',
      accessor: 'gross_amount',
      minWidth: 150,
      Cell: ({ original: { gross_amount } }) => (
        <Cell>
          <p>{formatValueToBrl(gross_amount)}</p>
        </Cell>
      )
    },
    {
      Header: 'Principal',
      accessor: 'amortization',
      minWidth: 150,
      Cell: ({ original: { amortization } }) => (
        <Cell>
          <p>{formatValueToBrl(amortization)}</p>
        </Cell>
      )
    },
    {
      Header: 'Juros',
      accessor: 'interest_per_installment',
      minWidth: 150,
      Cell: ({ original: { interest_per_installment } }) => {
        return (
          <Cell>
            <p>{!!interest_per_installment ? formatValueToBrl(interest_per_installment) : '-'}</p>
          </Cell>
        )
      }
    },
    {
      Header: 'Venc. Tomador',
      accessor: 'expire_date',
      minWidth: 140,
      Cell: props => (
        <DateCell>
          <DateText>{sanitizeDate(props.original.expire_date)}</DateText>
          {props.original.expire_date_delayed && (
            <DateText>({sanitizeDate(props.original.expire_date_delayed)})</DateText>
          )}
        </DateCell>
      )
    },
    {
      Header: 'Venc. Investidor',
      accessor: 'transfer_date',
      minWidth: 150,
      Cell: props => (
        <DateCell>
          <DateText>{sanitizeDate(props.original.transfer_date)}</DateText>
          {props.original.transfer_date_delayed && (
            <DateText>({sanitizeDate(props.original.transfer_date_delayed)})</DateText>
          )}
        </DateCell>
      )
    },
    {
      Header: 'Pagamento',
      accessor: 'pay_date',
      minWidth: 120,
      Cell: props => (
        <Cell>
          <p>{sanitizeDate(props.original.pay_date)}</p>
        </Cell>
      )
    },
    {
      Header: 'Status da parcela',
      acessor: 'status',
      minWidth: 150,
      Cell: props => {
        const isPaid = props.original.installment_status.abbreviation === installmentStatuses.PAID
        return (
          <Fragment>
            <PayDateContainer>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPaid}
                    onChange={e => handleCheckbox(e, props.original)}
                    color='primary'
                    style={{ color: '#076f9f' }}
                  />
                }
                label={isPaid ? 'Pago' : 'Em aberto'}
              />
              {editing !== props.original.id && isPaid && (
                <Fragment>
                  <Edit onClick={() => editPayDate(props.original)} />
                </Fragment>
              )}
            </PayDateContainer>
          </Fragment>
        )
      }
    },
    {
      Header: 'DARFs',
      accessor: '',
      minWidth: 210,
      Cell: ({ original: { campaign_id, installment_number, hasDarf }, index }) => (
        <Cell>
          <FormControlLabel
            style={{ marginRight: '30px' }}
            onClick={() => {
              setSelectedCampaignInfos({
                campaign_id,
                installment_number,
                warning: Boolean(installments[index - 1] && !installments[index - 1].hasDarf)
              })
              setIsOpenModalDarf(true)
            }}
            control={
              <I className='material-icons' color={hasDarf ? 'green' : 'grey'}>
                <CheckCircle />
              </I>
            }
            label='Gerar'
          />
          <FormControlLabel
            onClick={() => {
              setDeleteDarfInfos({ campaign_id, installment_number })
              setIsOpenModalDelete(true)
            }}
            control={
              <I className='material-icons' color='red'>
                <HighlightOff />
              </I>
            }
            label='Excluir'
          />
        </Cell>
      )
    }
    // {
    //   Header: 'Upload Cnab',
    //   Cell: _ => (
    //     <IconsContainer>
    //       <Fab
    //         data-tip='Adicione o Cnab'
    //         size='small'
    //         onClick={() => setIsOpenModalUpload(true)}
    //         color='primary'
    //         style={{ background: '#076F9F' }}
    //         aria-label='Add'
    //       >
    //         <Add />
    //       </Fab>
    //     </IconsContainer>
    //   )
    // }
  ]

  const fetchInfos = async () => {
    try {
      const [
        { data: installments },
        { data: campaign },
        { data: installmentStatus },
        { data: investorsInstallments }
      ] = await Promise.all([
        getCampaignInstallments({ campaign_id: match.params.id }),
        getCampaignById(match.params.id),
        getInstallmentStatus(),
        getInstallments({ campaign_id: match.params.id })
      ])

      const sorttedInstallments = installments
        .map(installment => {
          const installmentsByNumber = investorsInstallments.filter(
            i => installment.installment_number === i.installment_number
          )

          return {
            ...installment,
            hasDarf: installmentsByNumber.some(({ darf_id }) => darf_id)
          }
        })
        .sort((a, b) => a.installment_number - b.installment_number)

      const campaignPaid = sorttedInstallments.every(
        ({ installment_status: { abbreviation } }) => abbreviation === installmentStatuses.PAID
      )

      setCampaign(campaign)
      setInstallments(sorttedInstallments)
      setCampaignPaid(campaignPaid)
      setInstallmentStatus(installmentStatus)
    } catch {
      toast.error('Não foi possível encontrar as parcelas da campanha.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchInfos()
  }, [])

  if (isLoading) return <LoadingComponent />

  const updateInstallment = async (installmentId, newStatusAbbreviation, payDate, hasToSendEmail) => {
    try {
      const newStatus = installmentStatus.find(({ abbreviation }) => abbreviation === newStatusAbbreviation)
      if (!newStatus) {
        toast.error('Ocorreu um erro ao buscar o status da parcela')
        return
      }
      const body = {
        installment_status_id: newStatus.id,
        pay_date: payDate,
        send_mail: hasToSendEmail
      }
      await updateInstallmentService(installmentId, body)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar a parcela')
    }
  }

  const confirmPayment = async hasToSendEmail => {
    const newDate = moment()
      .tz('America/Sao_Paulo')
      .format('YYYY-MM-DD')

    await updateInstallment(editing.id, installmentStatuses.PAID, newDate, hasToSendEmail)
    fetchInfos()
    setIsConfirmPaymentModalOpen(false)
  }

  const handleCheckbox = async (event, installment) => {
    if (event.target.checked) {
      setEditing(installment)
      setIsConfirmPaymentModalOpen(true)
    } else {
      await updateInstallment(installment.id, installmentStatuses.OPEN, null, false)
      fetchInfos()
    }
  }

  const savePayDate = async installment => {
    const date = moment.tz(payDateRef.current.value, 'DD/MM/YYYY', 'America/Sao_Paulo')
    if (date.isValid() && date.isBefore(moment())) {
      await updateInstallment(installment.id, installmentStatuses.PAID, date, false)
      toast.success(`Data atualizada para ${date.format('DD/MM/YYYY')}`)
      fetchInfos()
      setIsOpen(false)
    } else {
      toast.error('Data inválida')
    }
  }

  const editPayDate = installment => {
    setEditing(installment)
    setIsOpen(true)
    setPayDate(
      moment(installment.pay_date)
        .utc()
        .format('DD/MM/YYYY')
    )
  }

  const handleDownloadParcel = async () => {
    try {
      const { data: response } = await getDownloadInstallments(campaign.id)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = `Parcelas-${campaign.name}.xlsx`
      element.click()
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao exportar as parcelas.')
    }
  }

  return (
    <ParcelsContainer>
      <CampaignName>
        {campaign.name}
        {campaign.campaign_state_id === 2 || campaign.campaign_state_id === 3 ? (
          <ExecutionFlag title={campaign.campaign_states.name} />
        ) : (
          ''
        )}
      </CampaignName>
      <ActionContainer>
        <MaterialButton
          disabled={campaignPaid}
          width={230}
          onClick={() => setTicketDataModal(true)}
          label='Dados do boleto'
        />
        <MaterialButton disabled={campaignPaid} width={230} onClick={() => setIsDelayModalOpen(true)} label='Atraso' />
        <MaterialButton
          disabled={campaignPaid}
          width={230}
          onClick={() => setIsRemoveDelayModalOpen(true)}
          label='Remover atraso'
        />
        <MaterialButton
          disabled={campaignPaid}
          width={230}
          onClick={() => history.push(`/campanha/${match.params.id}/antecipacao`)}
          label='Antecipação'
        />
        <MaterialButton
          disabled={campaignPaid}
          width={230}
          onClick={() => history.push(`/campanha/${match.params.id}/judicial`)}
          label='Execução Judicial'
        />
      </ActionContainer>

      <DownloadCSVContainer>
        <DownloadCSVButton disabled={!installments} onClick={handleDownloadParcel}>
          Exportar Parcelas
        </DownloadCSVButton>
      </DownloadCSVContainer>

      <ParcelsContent>
        {installments && (
          <ReactTable
            className='ReactTableDefault'
            data={installments}
            columns={columns}
            showPagination={false}
            defaultPageSize={installments.length}
            minRows={0}
          />
        )}
      </ParcelsContent>
      <ModalComponent
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={_ => setIsOpen(false)}
        closeModal={_ => setIsOpen(false)}
      >
        <ModalContent>
          <Fragment>
            <ModalDescripion>
              Alteração referente à parcela
              {` ${editing.installment_number}/${installments.length} `}
              prevista para:{' '}
              {moment(editing.transfer_date_delayed || editing.transfer_date)
                .utc()
                .format('DD/MM/YYYY')}
            </ModalDescripion>
            <PayDateContainer>
              <InputMask
                value={payDate}
                onChange={e => setPayDate(e.target.value)}
                onKeyDown={({ key }) => key === 'Enter' && savePayDate(editing)}
                mask='99/99/9999'
              >
                {inputProps => <PayDateInput ref={payDateRef} {...inputProps} />}
              </InputMask>
              <MaterialButton label='Confimar' onClick={_ => savePayDate(editing)} />
              <MaterialButton label='Cancelar' backgroundColor='red' onClick={_ => setIsOpen(false)} />
            </PayDateContainer>
          </Fragment>
        </ModalContent>
      </ModalComponent>

      {isOpenModalDarf && (
        <ModalDarfCnab
          reload={fetchInfos}
          isOpen={isOpenModalDarf}
          setIsOpen={setIsOpenModalDarf}
          selectedCampaingInfos={selectedCampaignInfos}
        />
      )}

      <ModalUploadCnab isOpen={isOpenModalUpload} setIsOpen={setIsOpenModalUpload} />

      <NewModalComponent isOpen={isConfirmPaymentModalOpen} setIsOpen={setIsConfirmPaymentModalOpen}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ padding: '1em 0' }}>Deseja enviar e-mail para os investidores?</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MaterialButton label='Sim' onClick={() => confirmPayment(true)} />
            <MaterialButton label='Não' backgroundColor='#3dc6ef' onClick={() => confirmPayment(false)} />
          </div>
        </div>
      </NewModalComponent>

      <ModalTicketData
        open={ticketDataModal}
        onClose={() => setTicketDataModal(false)}
        campaign={campaign}
        reload={fetchInfos}
      />

      {executionModal && (
        <ModalExecution
          isOpen={executionModal}
          setIsOpen={() => setExecutionModal(false)}
          campaign={campaign}
          reload={fetchInfos}
        />
      )}

      {isDelayModalOpen && (
        <ModalInstallmentDelay
          isOpen={isDelayModalOpen}
          setIsOpen={setIsDelayModalOpen}
          campaign={campaign}
          installments={installments}
          reload={fetchInfos}
        />
      )}

      {isRemoveDelayModalOpen && (
        <ModalRemoveInstallmentDelay
          isOpen={isRemoveDelayModalOpen}
          setIsOpen={setIsRemoveDelayModalOpen}
          campaign={campaign}
          installments={installments}
          reload={fetchInfos}
        />
      )}

      {isOpenModalDelete && (
        <ModalDarfDelete
          isOpen={isOpenModalDelete}
          setIsOpen={setIsOpenModalDelete}
          campaignId={deleteDarfInfos && deleteDarfInfos.campaign_id}
          installmentNumber={deleteDarfInfos && deleteDarfInfos.installment_number}
          reload={fetchInfos}
        />
      )}
    </ParcelsContainer>
  )
}

export default Parcels
