import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import styled from '@emotion/styled'
import numbro from 'numbro'
import './style.css'

const CampaignReturnSimulator = props => {
  const SAVINGS_RATE = 0.39 / 100
  const KAVOD_RATE = props.campaign.rate_month / 100
  const KAVOD_INSTALLMENT = (
    (props.investmentValue * (KAVOD_RATE * Math.pow(1 + KAVOD_RATE, props.campaign.installments))) /
    (Math.pow(1 + KAVOD_RATE, props.campaign.installments) - 1)
  ).toFixed(2)
  const KAVOD_TOTAL = KAVOD_INSTALLMENT * props.campaign.installments

  const getSavingsTotal = () => {
    return props.investmentValue * Math.pow(1 + SAVINGS_RATE, props.campaign.installments)
  }

  const data = {
    labels: [
      `Poupança R$ ${numbro(getSavingsTotal()).format({ thousandSeparated: true, mantissa: 2 })} `,
      `Kavod R$ ${numbro(KAVOD_TOTAL).format({ thousandSeparated: true, mantissa: 2 })}`
    ],
    datasets: [
      {
        data: [getSavingsTotal(), KAVOD_TOTAL],
        backgroundColor: ['#aaa', '#076f9f']
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        }
      ],
      ticks: {
        display: false
      }
    },
    legend: {
      display: false
    }
  }

  if (!props.isSimulated) {
    return <p>Clique no botão pra simular um investimento na Kavod.</p>
  }

  return (
    <>
      <Container>
        <Title>Retorno Estimado Total*</Title>
        <ExpectedAmount>{`R$ ${numbro(KAVOD_TOTAL).format({ thousandSeparated: true, mantissa: 2 })}`}</ExpectedAmount>
        <Installments>
          {props.campaign.installments} Parcelas de R$ {numbro(KAVOD_INSTALLMENT).format({ thousandSeparated: true })}
        </Installments>
        <Warning>
          * os valores podem variar de acordo com a data de fechamento da campanha e a formalização da CCB
        </Warning>
        <ChartContainer>
          <HorizontalBar data={data} width={600} height={150} options={options} />
        </ChartContainer>
      </Container>
    </>
  )
}

const Warning = styled.p`
  margin-bottom: 1em;
  color: #686c8b;
`

const Installments = styled.p`
  font-size: 1em !important;
  color: #686c8b;
  margin-top: 1em;
`

const ExpectedAmount = styled.p`
  font-size: 2.5rem !important;
  color: #076f9f;
  font-weight: 750;
  margin: 0;
`

const Title = styled.p`
  font-size: 1.75em !important;
  font-weight: 500;
  color: #686c8b;
  margin: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ChartContainer = styled.div`
  max-width: 100%;
`

export default CampaignReturnSimulator
