import client from '../../providers/fetchClient'

export const getDocumentTypes = () => client.get('/v3/document-types')

export const createDocument = body => client.post('/v3/documents', body)

export const updateDocument = (id, body) => client.put(`/v3/documents/${id}`, body)

export const getDocument = id => client.get(`/v3/documents/${id}`)

export const getInvestorDocumentUrl = (investor_id, document_type_id) =>
  client.get(`/v3/investors/${investor_id}/documents/${document_type_id}`)

export const deleteDocument = id => client.delete(`/v3/documents/${id}`)
