import React from 'react'

import useDevice from '../../../../../hooks/useDevice'

import { ModalBase, Text, Column, Row, Button } from '../../../index'

const ModalPendingDocument = ({ open, handleClose }) => {
  const { isMobile } = useDevice()

  return (
    <ModalBase
      open={!!open ? true : false}
      onClose={handleClose}
      width={isMobile ? 300 : 526}
      height={isMobile ? 235 : 200}
      title='Documentos pendentes'
    >
      <Column justifyContent='space-around' style={{ gap: '20px' }}>
        <Text variant='body1'>
          Existem documentos pendentes de aprovação/recusa. Por favor elimine as pendências antes de enviar para análise
          de crédito.
        </Text>
        <Row justifyContent='flex-end'>
          <Button height={40} width={150} onClick={handleClose}>
            Fechar
          </Button>
        </Row>
      </Column>
    </ModalBase>
  )
}

export default ModalPendingDocument
