import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { ModalBase, Text, Column, Row, Button, Input, Checkbox } from '../../../index'

import { modalRefuseDocumentResolver } from '../../../../../helpers'

const defaultValue = { reason: '' }

const ModalRecuseDoc = ({ open, handleClose, handleRecuseDoc }) => {
  const {
    handleSubmit,
    reset,
    control,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(modalRefuseDocumentResolver),
    defaultValues: defaultValue
  })

  const onSubmit = async data => {
    try {
      handleRecuseDoc({ ...open, reason: data.reason, is_visible_to_partner: data.is_visible_to_partner })
    } catch {
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    reset({ name: open.s3_name })
  }, [open])

  return (
    <ModalBase open={!!open ? true : false} onClose={handleClose} height='100%' width='100%' title='Documento'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column justifyContent='space-around' style={{ gap: '20px' }}>
          <Text variant='h4'>Você tem certeza que deseja recusar este documento?</Text>
          <Input
            name='reason'
            label='Motivo'
            control={control}
            multiline
            rows={6}
            error={errors.reason}
            placeholder='Mensagem opcional.'
          />
          <Checkbox
            label='Mostrar mensagem para parceiro'
            control={control}
            name='is_visible_to_partner'
            error={errors.is_visible_to_partner}
            defaultValue={false}
          />
          <Row>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting}>
              Sim
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalRecuseDoc
