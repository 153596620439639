import React, { useMemo } from 'react'
import { Content, Main, Detail, ContainerCard } from './styles'

import { Pagination, Row } from '../../../../../../components/v2Components'

import PropTypes from 'prop-types'

const Card = ({ releases, hasPaginationTop, hasPaginationBottom, offset, rowCount, navigationTo, children }) => {
  const data = useMemo(() => releases, [releases])

  const paginationReleases = useMemo(() => {
    let list = [[]]
    let group = 0

    for (let i = 0; i < data.length; i++) {
      list[group].push(data[i])

      if (list[group].length === rowCount) {
        group++

        if (i + 1 < data.length) {
          list[group] = []
        }
      }
    }

    return list
  }, [data, rowCount])

  return (
    <Main>
      {children}

      {hasPaginationTop && rowCount > 1 && (
        <Pagination
          pagination={{
            offset,
            rowCount,
            limit: 1,
            pageCount: paginationReleases.length
          }}
          onChangePage={({ page }) => navigationTo(page - 1)}
        />
      )}

      <Content>
        {paginationReleases[offset] &&
          paginationReleases[offset].map((releases, index) => {
            return (
              <ContainerCard key={index} color={index}>
                <div style={{ gridArea: 'date' }}>
                  <label title={releases.date}>{releases.date}</label>
                </div>
                <div style={{ gridArea: 'value', textAlign: 'end' }}>{releases.value}</div>
                <div style={{ gridArea: 'amorti' }}>Amortização: {releases.amortization}</div>
                <div style={{ gridArea: 'tax', textAlign: 'end' }}>Juros: {releases.tax}</div>
                <div
                  style={{
                    gridArea: 'ir',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '4px'
                  }}
                >
                  Desconto IR: {releases.ir}
                </div>
                <div style={{ gridArea: 'description' }} title={releases.description}>
                  <label>{releases.description}</label>
                </div>
                <div style={{ gridArea: 'parcel' }}>
                  <Row style={{ justifyContent: 'space-between' }}>
                    {releases.parcel.length > 2 ? (
                      <label title={'Parcela ' + releases.parcel}>Parcela {releases.parcel}</label>
                    ) : null}
                    <Detail style={{ gridArea: 'detail' }}>
                      <h4>{releases.detail}</h4>
                    </Detail>
                  </Row>
                </div>
              </ContainerCard>
            )
          })}
      </Content>

      {hasPaginationBottom && rowCount > 1 && (
        <Pagination
          pagination={{
            offset,
            rowCount,
            limit: 1,
            pageCount: paginationReleases.length
          }}
          onChangePage={({ page }) => navigationTo(page - 1)}
        />
      )}
    </Main>
  )
}

Card.propTypes = {
  releases: PropTypes.array,
  hasPaginationTop: PropTypes.bool,
  hasPaginationBottom: PropTypes.bool,
  offset: PropTypes.number,
  rowCount: PropTypes.number,
  pageCount: PropTypes.number,
  navigationTo: PropTypes.func
}

export default Card
