import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.2);
`

export const ContainerOption = styled.div`
  display: none !important;
  @media (max-width: 1200px) {
    display: flex !important;
  }
  @media (max-width: 768px) {
    display: none !important;
  }
`
export const ResponsiveTabMenu = styled.div`
  @media (max-width: 425px) {
    div {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`

export const TableContainer = styled.div`
  td {
    span {
      display: flex;
      align-items: center;
      span:nth-child(4) {
        display: block;
      }
    }
  }
  td:last-child {
    span {
      display: block;
    }
  }
`

export const PredictedValue = styled.div`
  display: flex;
  align-items: center;
`
export const Parcel = styled.div`
  display: flex;
  align-items: center;
`
