import React, {
  useEffect,
  useRef,
  forwardRef,
  useContext,
  createContext,
  cloneElement,
  Children,
  isValidElement
} from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useMediaQuery, ListSubheader } from '@material-ui/core'
import { VariableSizeList } from 'react-window'
import { useTheme } from '@material-ui/core/styles'

import Input from '../../Input'
import { IconCheck, IconExpandMore } from '../../icons'

import { Container, SelectInput, Tag } from './styles'

const LISTBOX_PADDING = 8

const renderRow = props => {
  const { data, index, style } = props
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  })
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const useResetCache = data => {
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData = Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node
}

const AutocompleteMulti = forwardRef(
  (
    {
      name,
      label,
      options,
      error,
      helperText,
      defaultValue,
      disabled,
      creatable,
      control,
      disableCloseOnSelect = false,
      ...props
    },
    ref
  ) => {
    const filter = createFilterOptions()

    return (
      <Container {...props}>
        <Controller
          render={props => (
            <SelectInput
              {...props}
              disableCloseOnSelect={disableCloseOnSelect}
              defaultValue={defaultValue || undefined}
              multiple
              ref={ref}
              options={options}
              getOptionLabel={option => option.label}
              popupIcon={<IconExpandMore />}
              renderInput={params => (
                <Input {...params} label={label} helperText={helperText} error={error} control={control} name={name} />
              )}
              onChange={(_, newValue) => {
                if (creatable) {
                  if (typeof newValue === 'string') {
                    props.onChange({
                      label: newValue,
                      value: newValue
                    })
                  } else if (newValue && newValue.inputValue) {
                    props.onChange({ value: newValue.inputValue, label: newValue.inputValue })
                  } else {
                    props.onChange(newValue)
                  }
                } else {
                  props.onChange(newValue)
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                if (creatable && params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    isNew: true,
                    label: params.inputValue
                  })
                }
                return filtered
              }}
              getOptionSelected={(option, value) => option.value === value.value}
              selectOnFocus
              handleHomeEndKeys
              freeSolo={creatable}
              ListboxComponent={ListboxComponent}
              noOptionsText='Não existe esta opção'
              renderOption={option => (
                <div style={{ display: 'flex' }}>
                  <div>
                    {option.isNew && <Tag size='small' label='Criar' />}
                    {option.label}
                  </div>
                  <IconCheck />
                </div>
              )}
              disabled={disabled}
            />
          )}
          name={name}
          defaultValue={defaultValue}
          control={control}
        />
      </Container>
    )
  }
)

AutocompleteMulti.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  creatable: PropTypes.bool,
  size: PropTypes.string,
  defaultValue: PropTypes.array
}

AutocompleteMulti.defaultProps = {
  size: 'small'
}

export default AutocompleteMulti
