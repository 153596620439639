import styled from '@emotion/styled'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1em;
`

export const QuillContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3em 2em;
  flex: 1;
`
