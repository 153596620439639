import React from 'react'
import RadialSeparators from './RadialSeparators'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import styled from 'styled-components'

const CircleProgressBar = ({ step }) => {
  return (
    <Container>
      <CircularProgressbarWithChildren
        value={step * 20}
        text={`${step}`}
        strokeWidth={24}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: '#076F9F',
          textColor: '#076F9F',
          textSize: '24px',
          trailColor: 'A9A9A9'
        })}
      >
        <RadialSeparators
          count={5}
          style={{
            background: '#fff',
            width: '2px',
            height: `${30}%`
          }}
        />
      </CircularProgressbarWithChildren>
    </Container>
  )
}

export default CircleProgressBar

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  margin-bottom: 20px;
`
