import { cities, banks } from '../constants'

export const campaignInfoInputs = [
  { name: 'name', label: 'Título', type: 'text', mask: null },
  { name: 'url', label: 'URL', type: 'text' },
  { name: 'CDI', label: 'CDI(%)', type: 'text', mask: '999' },
  { name: 'amount', label: 'Meta de captação', type: 'text' },
  { name: 'min_amount', label: 'Valor mínimo', type: 'text' },
  { name: 'max_amount', label: 'Valor máximo', type: 'text' },
  { name: 'rate_month', label: 'Taxa mensal', type: 'text' },
  { name: 'installments', label: 'Parcelas', type: 'number', mask: '99' },
  { name: 'grace_period', label: 'Período de carência', type: 'number', mask: '999' },
  { name: 'company_name', label: 'Empresa', type: 'text' },
  { name: 'start_date', label: 'Data de início', type: 'text', mask: '99/99/9999' },
  { name: 'start_time', label: 'Horário de início', type: 'text', mask: '99:99' },
  { name: 'end_date', label: 'Data de Término', type: 'text', mask: '99/99/9999' },
  { name: 'end_time', label: 'Horário de término', type: 'text', mask: '99:99' }
]

export const companyForm = [
  { label: 'Nome da empresa', name: 'company_name', type: 'text', placeholder: 'Ex: Sopetra Rolamentos e Peças LTDA' },
  {
    label: 'Nome fantasia da empresa',
    name: 'company_fantasy_name',
    type: 'text',
    placeholder: 'Ex: Sopetra Rolamentos e Peças '
  },
  {
    label: 'CNPJ',
    name: 'company_cnpj',
    type: 'text',
    mask: '99.999.999/9999-99',
    placeholder: 'Ex: 24.541.933/0001-54'
  },
  { label: 'CEP', name: 'company_zip_code', type: 'zip_code', mask: '99999-999', placeholder: 'Ex: 68458-094' },
  { label: 'Rua', name: 'company_street', type: 'text' },
  { label: 'Número', name: 'company_number', type: 'text', placeholder: 'Ex: 80' },
  { label: 'Cidade', name: 'company_city', type: 'text' },
  {
    label: 'Complemento',
    name: 'company_complement',
    type: 'text',
    placeholder: 'Bloco, Apartamento...'
  },
  { label: 'Bairro', name: 'company_neighborhood', type: 'text' },
  { label: 'UF', name: 'company_uf', type: 'text', placeholder: 'Ex: RS', mask: 'aa' },
  { label: 'Código do banco', name: 'company_bank_code', type: 'text', mask: '999' },
  { label: 'Código da agência', name: 'company_bank_agency', type: 'text', mask: '99999' },
  { label: 'Conta bancária', name: 'company_bank_account', type: 'text', mask: '9999999999999' },
  { label: 'Digito da conta', name: 'company_bank_account_digit', type: 'text', mask: '9' },
  { label: 'Tipo de conta', name: 'company_bank_type', type: 'text' },
  { label: 'País', name: 'company_country', type: 'text' },
  { label: 'E-mail', name: 'company_email', type: 'text', placeholder: 'Ex: business@sopetra.com' },
  { label: 'Prefixo', name: 'company_phone_prefix', type: 'text', mask: '99' },
  { label: 'Telefone', name: 'company_phone', type: 'text' }
]

export const guarantorsForm = [
  { label: 'Nome', name: 'guarantor_name', type: 'text', placeholder: 'Ex: João das neves...' },
  {
    label: 'Documento Federal',
    name: 'guarantor_document',
    type: 'text',
    placeholder: 'CPF para pessoa física e CNPJ para pessoa jurídica'
  },
  { label: 'RG', name: 'guarantor_id_card', type: 'text' },
  { label: 'Estado civil', name: 'guarantor_marital_status', type: 'text' },
  { label: 'Tipo de união', name: 'guarantor_marital_type', type: 'text' },
  { label: 'CEP', name: 'guarantor_zip_code', type: 'zip_code', mask: '99999-999', placeholder: 'Ex: 68458-094' },
  { label: 'Logradouro', name: 'guarantor_street', type: 'text' },
  { label: 'Número', name: 'guarantor_number', type: 'text' },
  { label: 'Bairro', name: 'guarantor_neighborhood', type: 'text' },
  { label: 'Complemento', name: 'guarantor_complement', type: 'text' },
  { label: 'Cidade', name: 'guarantor_city', type: 'text' },
  { label: 'UF', name: 'guarantor_uf', type: 'text', placeholder: 'Ex: RS', mask: 'aa' }
]

export const guarantorsFormLegalPerson = [
  { label: 'Nome', name: 'guarantor_name', type: 'text', placeholder: 'Ex: João das neves...' },
  { label: 'Nome fantasia', name: 'guarantor_fantasy_name', type: 'text', placeholder: 'Pessoa jurídica' },
  {
    label: 'Documento Federal',
    name: 'guarantor_document',
    type: 'text',
    placeholder: 'CPF para pessoa física e CNPJ para pessoa jurídica'
  },
  { label: 'CEP', name: 'guarantor_zip_code', type: 'zip_code', mask: '99999-999', placeholder: 'Ex: 68458-094' },
  { label: 'Logradouro', name: 'guarantor_street', type: 'text' },
  { label: 'Número', name: 'guarantor_number', type: 'text' },
  { label: 'Bairro', name: 'guarantor_neighborhood', type: 'text' },
  { label: 'Complemento', name: 'guarantor_complement', type: 'text' },
  { label: 'Cidade', name: 'guarantor_city', type: 'text' },
  { label: 'UF', name: 'guarantor_uf', type: 'text', placeholder: 'Ex: RS', mask: 'aa' }
]

export const campaignForm = [
  { label: 'Data do primeiro vencimento', name: 'campaign_first_due_date', type: 'date' },
  { label: 'Garantia', name: 'campaign_guarantee', type: 'text' },
  { label: 'Percentual de IOF', name: 'campaign_iof_percentual', type: 'text' },
  { label: 'Adicional de IOF', name: 'campaign_iof_aditional', type: 'text' }
]

export const subscribersForm = [
  { label: 'E-mail', name: 'subscriber_email', type: 'email' },
  { label: 'Nome', name: 'subscriber_name', type: 'text', placeholder: 'Ex: João das neves...', disabled: true },
  {
    label: 'CPF',
    name: 'subscriber_document',
    type: 'text',
    mask: '999.999.999-99',
    placeholder: '123.456.789-10',
    disabled: true
  },
  { label: 'Prefixo', name: 'subscriber_phone_prefix', type: 'text', mask: '99', disabled: true },
  { label: 'Telefone celular', name: 'subscriber_phone', type: 'text', mask: '99999-9999', disabled: true },
  { label: 'Descrição', name: 'subscriber_description', type: 'text' }
]

export const aboutPersonInputs = [
  {
    label: 'Nome*',
    margin: 'normal',
    name: 'first_name',
    id: 'first_name',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: ''
  },
  {
    label: 'Sobrenome*',
    margin: 'normal',
    name: 'last_name',
    id: 'last_name',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: ''
  },
  {
    label: 'CPF*',
    margin: 'normal',
    name: 'cpf',
    id: 'cpf',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: '999.999.999-99'
  },
  {
    label: 'Email*',
    margin: 'normal',
    name: 'email',
    id: 'email',
    type: 'email',
    placeholder: '',
    disabled: 'disabled',
    col: '6',
    mask: ''
  },
  {
    label: 'Celular*',
    margin: 'normal',
    name: 'phone',
    id: 'phone',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: '(99) 99999-9999'
  },
  {
    label: 'Data de nascimento*',
    margin: 'normal',
    name: 'birth_date',
    id: 'birth_date',
    type: 'text',
    placeholder: 'dd/mm/aaaa',
    col: '6',
    mask: '99/99/9999'
  },
  {
    label: 'Nacionalidade*',
    margin: 'normal',
    name: 'nationality',
    id: 'nationality',
    type: 'select',
    col: '6',
    mask: '',
    options: [
      {
        id: 'Brasileiro',
        label: 'Brasileiro',
        value: 'Brasileiro'
      },
      {
        id: 'Brasileiro Naturalizado',
        label: 'Brasileiro Naturalizado',
        value: 'Brasileiro Naturalizado'
      },
      {
        id: 'Estrangeiro',
        label: 'Estrangeiro',
        value: 'Estrangeiro'
      }
    ]
  },
  {
    label: 'Naturalidade*',
    margin: 'normal',
    name: 'naturality',
    id: 'naturality',
    type: 'autocomplete',
    options: cities,
    col: '6',
    mask: ''
  },
  {
    label: 'Gênero*',
    margin: 'normal',
    name: 'gender',
    id: 'gender',
    type: 'select',
    col: '6',
    mask: '',
    options: [
      {
        id: 'M',
        label: 'Masculino',
        value: 'M'
      },
      {
        id: 'F',
        label: 'Feminino',
        value: 'F'
      }
    ]
  },
  {
    label: 'Estado Civil*',
    margin: 'normal',
    name: 'marital_status_id',
    id: 'marital_status_id',
    type: 'select',
    col: '6',
    mask: '',
    options: [
      { id: 'C', label: 'Casado', value: 'C' },
      { id: 'D', label: 'Divorciado', value: 'D' },
      { id: 'S', label: 'Solteiro', value: 'S' },
      { id: 'V', label: 'Viúvo', value: 'V' }
    ]
  },
  {
    label: 'RG*',
    margin: 'normal',
    name: 'number',
    id: 'number',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: '9999999999999'
  },
  {
    label: 'Orgão Expedidor*',
    margin: 'normal',
    name: 'agent',
    id: 'agent',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: 'aa'
  },
  {
    label: 'Nome da mãe*',
    margin: 'normal',
    name: 'mother_name',
    id: 'mother_name',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: ''
  },
  {
    label: 'Nome do pai*',
    margin: 'normal',
    name: 'father_name',
    id: 'father_name',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: ''
  }
]
export const personalAddressInputs = [
  {
    label: 'CEP*',
    margin: 'normal',
    name: 'zip_code',
    id: 'zip_code',
    type: 'text',
    placeholder: '',
    col: '6',
    mask: '99999-999'
  },
  {
    label: 'Tipo*',
    margin: 'normal',
    name: 'type',
    id: 'type',
    type: 'select',
    placeholder: '',
    col: '6',
    options: [
      {
        id: 'Comercial',
        label: 'Comercial',
        value: 'Comercial'
      },
      {
        id: 'Residencial',
        label: 'Residencial',
        value: 'Residencial'
      }
    ]
  },
  {
    label: 'Endereço*',
    margin: 'normal',
    name: 'street',
    id: 'street',
    type: 'text',
    placeholder: '',
    col: '12'
  },
  {
    label: 'Número*',
    margin: 'normal',
    name: 'number',
    id: 'number',
    type: 'text',
    placeholder: '',
    col: '6'
  },
  {
    label: 'Complemento*',
    margin: 'normal',
    name: 'complement',
    id: 'complement',
    type: 'text',
    placeholder: '',
    col: '6'
  },
  {
    label: 'Bairro*',
    margin: 'normal',
    name: 'district',
    id: 'district',
    type: 'text',
    placeholder: '',
    col: '6'
  },
  {
    label: 'Cidade*',
    margin: 'normal',
    name: 'city',
    id: 'city',
    type: 'text',
    placeholder: '',
    col: '6'
  }
]

export const personalBankInputs = [
  {
    label: 'Tipo da conta*',
    margin: 'normal',
    name: 'type',
    id: 'type',
    type: 'select',
    placeholder: '',
    col: '12',
    options: [
      {
        id: 'CC',
        label: 'Conta Corrente',
        value: 'CC'
      },
      {
        id: 'CP',
        label: 'Conta Poupança',
        value: 'CP'
      }
    ]
  },
  {
    label: 'Banco*',
    margin: 'normal',
    name: 'code',
    id: 'code',
    type: 'text',
    placeholder: '',
    col: '12',
    options: banks
  },
  {
    label: 'Agência (sem o dígito)*',
    margin: 'normal',
    name: 'agency',
    id: 'agency',
    type: 'text',
    placeholder: '',
    col: '4'
  },
  {
    label: 'Conta*',
    margin: 'normal',
    name: 'account',
    id: 'account',
    type: 'text',
    placeholder: '',
    col: '4'
  },
  {
    label: 'Dígito conta *',
    margin: 'normal',
    type: 'text',
    name: 'account_digit',
    id: 'account_digit',
    placeholder: '',
    col: '4'
  },
  {
    label: 'Renda mensal *',
    type: 'text',
    name: 'monthly_earn',
    id: 'monthly_earn',
    placeholder: '',
    col: '6'
  },
  {
    label: 'Patrimônio *',
    type: 'text',
    name: 'property_value',
    id: 'property_value',
    placeholder: '',
    col: '6'
  }
]
export const personalDocumentsInputs = [
  {
    label: 'Documento de identificação *',
    margin: 'normal',
    name: 'personal_document_type_id',
    id: 'personal_document_type_id',
    type: 'radio',
    placeholder: '',
    col: '12',
    options: [
      {
        id: 'C',
        label: 'CNH',
        value: 'C'
      },
      {
        id: 'R',
        label: 'RG',
        value: 'R'
      },
      {
        id: 'O',
        label: 'OAB',
        value: 'O'
      },
      {
        id: 'RN',
        label: 'RNE',
        value: 'RN'
      }
    ]
  },
  {
    label: '',
    text: 'Arraste ou clique para fazer upload do seu documento de identificação',
    placeholder: '',
    col: '12',
    type: 'personal-document',
    className: 'classes.dropzone',
    name: 'upload_personal_document_type_id',
    id: 'upload_personal_document_type_id'
  },
  {
    label: 'Comprovante de Residência *',
    margin: 'normal',
    name: 'personal_document_type_cr',
    id: 'personal_document_type_cr',
    placeholder: '',
    col: '12'
  },
  {
    label: '',
    text: 'Arraste ou clique para fazer upload do seu documento pessoal',
    placeholder: '',
    col: '12',
    type: 'personal-document',
    className: 'classes.dropzone',
    id: 'upload_personal_document_type_cr',
    name: 'upload_personal_document_type_cr'
  }
]
