import client from '../../providers/fetchClient'

export const requestLeads = data => client.post('/v3/leads/intern', data)

export const GetOneLead = id => client.get(`v3/leads/${id}`)

export const originLeads = () => client.get('/v3/lead-origins')

export const getLeads = (params, configs) => client.get(`/v3/leads?${params}`, configs)

export const updateLeads = (id, body) => client.put(`/v3/leads/${id}`, body)

export const getLeadStatuses = () => client.get('/v3/lead-statuses')

export const getLeadsIndicators = () => client.get('/v3/leads?unique_indicated_by=true')

export const getLeadDocuments = lead_id => client.get(`/v3/lead-documents?lead_id=${lead_id}`)

export const getLeadDocumentTypes = () => client.get('/v3/lead-document-types')

export const createLeadDocument = data => client.post('/v3/lead-documents', data)

export const getLeadDocumentUrl = (lead_id, document_id) =>
  client.get(`/v3/leads/${lead_id}/lead-documents/${document_id}`)

export const updateDocumentStatus = data => client.put('/v3/lead-documents-approve', data)

export const updateLeadDocument = (id, data) => client.put(`/v3/lead-documents/${id}`, data)

export const deleteLeadDocument = id => client.delete(`/v3/lead-documents/${id}`)

export const deleteAllLeadDocumentsRefuseds = id => client.delete(`v3/lead-documents-delete-all/${id}`)
