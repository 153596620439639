import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'

import { NumberInput, Select, BlankField, TextInput, Button } from '../../components/FormComponents'
import { LoadingScreen } from '../../components'

import { statesOptions, roles } from '../../constants'
import { IDLE, FETCHING, SUBMITTING } from '../../constants/status'
import { formatCurrencyToBack, formatName } from '../../helpers'
import {
  getCompanyInfos,
  getAddressByCep,
  requestLeads,
  originLeads,
  getRoles,
  getUsers,
  fetchCurrentUser
} from '../../services/v3'
import { creditRequestSchema } from '../../schemas'

const signupCompanyDefaultValues = {
  indicated_by: '',
  responsible: '',
  lead_origin_id: '',
  first_name: '',
  last_name: '',
  email: '',
  street: '',
  number: '',
  zip_code: '',
  complement: '',
  district: '',
  city: '',
  uf: '',
  celphone: '',
  telephone: '',
  cnpj: '',
  company_name: '',
  company_site: '',
  average_monthly_revenue: '',
  desired_amount: '',
  company_history: '',
  lending_reason: ''
}

const CreditRequest = ({ history }) => {
  const [origins, setOrigins] = useState([])
  const [responsibles, setResponsibles] = useState([])
  const [status, setStatus] = useState(FETCHING)

  const { handleSubmit, errors, control, watch, setValue } = useForm({
    resolver: creditRequestSchema,
    defaultValues: signupCompanyDefaultValues,
    mode: 'onBlur'
  })

  const cnpjValue = watch('cnpj')
  const zipCode = watch('zip_code')

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setStatus(FETCHING)
        const { data: allRoles } = await getRoles()
        const kavodStaffRole = allRoles.find(({ name }) => name === roles.kavodStaff)
        if (!kavodStaffRole) return toast.error('Não foi possível encontrar os responsáveis')
        const { data: kavodStaff } = await getUsers({ role_id: kavodStaffRole.id })
        setResponsibles(
          kavodStaff
            .filter(({ investor }) => investor)
            .map(({ investor }) => `${investor.first_name} ${investor.last_name}`)
            .sort()
        )
        const { data: origins } = await originLeads()
        setOrigins(origins)
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um erro ao buscar os dados')
      } finally {
        setStatus(IDLE)
      }
    }
    fetchInfos()
  }, [])

  const setCompanyName = useCallback(
    async cnpj => {
      try {
        const companyInfo = await getCompanyInfos(cnpj)
        setValue('company_name', companyInfo.nome)
      } catch (error) {
        if (error.message === 'Erro ao buscar o CNPJ: undefined.') {
          toast.error('Erro ao buscar CNPJ')
        } else {
          toast.error(error.message)
        }
      }
    },
    [setValue]
  )

  const setCompanyAddress = useCallback(
    async cep => {
      try {
        const companyAddress = await getAddressByCep(cep)
        setValue('city', companyAddress.localidade)
        setValue('uf', companyAddress.uf)
        setValue('street', companyAddress.logradouro)
        setValue('district', companyAddress.bairro)
      } catch (error) {
        if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('Erro a buscar CEP')
        }
      }
    },
    [setValue]
  )

  useEffect(() => {
    if (responsibles.length === 0) return

    const getResponsible = async () => {
      try {
        const {
          data: { investor }
        } = await fetchCurrentUser()
        setValue('responsible', `${investor.first_name} ${investor.last_name}`)
      } catch {
        toast.error('Ocorreu um erro ao buscar o dado de responsável')
      }
    }

    getResponsible()
  }, [responsibles])

  useEffect(() => {
    if (!cnpjValue) return

    const sanitizedCnpj = cnpjValue.replace(/\D/g, '')

    if (sanitizedCnpj.length === 14) {
      setCompanyName(sanitizedCnpj)
    }
  }, [cnpjValue, setCompanyName])

  useEffect(() => {
    if (!zipCode) return

    const sanitizedZipCode = zipCode.replace(/\D/g, '')
    if (sanitizedZipCode.length === 8) {
      setCompanyAddress(sanitizedZipCode)
    }
  }, [zipCode, setCompanyAddress])

  const formatPhone = value =>
    value.length <= 10
      ? `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`
      : `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`

  const onSubmit = async values => {
    try {
      setStatus(SUBMITTING)
      const celphone = values.celphone.replace(/\D/g, '')
      const telephone = values.telephone.replace(/\D/g, '')
      const body = {
        first_name: formatName(values.first_name),
        last_name: formatName(values.last_name),
        email: values.email.toLowerCase(),
        street: values.street,
        number: values.number,
        zip_code: values.zip_code,
        complement: values.complement,
        district: values.district,
        city: values.city,
        uf: values.uf,
        celphone: celphone.slice(2),
        celphone_prefix: celphone.slice(0, 2),
        telephone: telephone.slice(2),
        telephone_prefix: telephone.slice(0, 2),
        cnpj: values.cnpj.replace(/[^\d]+/g, ''),
        company_name: values.company_name,
        company_site: values.company_site,
        company_history: values.company_history,
        average_monthly_revenue: formatCurrencyToBack(values.average_monthly_revenue),
        desired_amount: formatCurrencyToBack(values.desired_amount),
        lending_reason: values.lending_reason,
        responsible: values.responsible,
        indicated_by: formatName(values.indicated_by),
        lead_origin_id: Number(values.lead_origin_id),
        company_type: 'Geral'
      }
      await requestLeads(body)
      toast.success('Solicitação de crédito cadastrada com sucesso.')
      history.push('/solicitacoes-de-credito')
    } catch (err) {
      console.log(err)
      toast.error('Ocorreu um erro ao enviar a solicitação de crédito.')
    } finally {
      setStatus(IDLE)
    }
  }
  if (status === FETCHING) return <LoadingScreen />

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TitleContainer>
          <Title>Solicitação de crédito</Title>
        </TitleContainer>
        <TitleContainer>
          <SubTitle>Dados internos</SubTitle>
        </TitleContainer>
        <Row width='75%'>
          <Select
            name='lead_origin_id'
            control={control}
            error={errors['lead_origin_id']}
            options={origins.map(({ name, id }) => ({ value: id, label: name }))}
            label='Origem *'
            width='33.3333%'
            dense
          />
          <TextInput
            name='indicated_by'
            control={control}
            error={errors['indicated_by']}
            label='Indicado por'
            width='33.3333%'
            dense
          />
          <Select
            name='responsible'
            control={control}
            error={errors['responsible']}
            label='Responsável *'
            width='33.3333%'
            dense
            options={responsibles.map(item => ({ value: item, label: item }))}
          />
        </Row>
        <TitleContainer>
          <SubTitle>Dados do contato</SubTitle>
        </TitleContainer>
        <Row width='50%'>
          <TextInput
            name='first_name'
            control={control}
            error={errors['first_name']}
            label='Nome *'
            width='50%'
            dense
          />
          <TextInput
            name='last_name'
            control={control}
            error={errors['last_name']}
            label='Sobrenome'
            width='50%'
            dense
          />
        </Row>
        <Row width='75%'>
          <TextInput name='email' control={control} error={errors['email']} label='E-mail *' width='33.3333%' dense />
          <NumberInput
            name='celphone'
            format={formatPhone}
            control={control}
            error={errors['celphone']}
            label='Celular *'
            width='33.3333%'
            dense
          />
          <NumberInput
            name='telephone'
            format={formatPhone}
            control={control}
            error={errors['telephone']}
            label='Telefone fixo'
            width='33.3333%'
            dense
          />
        </Row>
        <TitleContainer>
          <SubTitle>Dados da empresa</SubTitle>
        </TitleContainer>
        <Row>
          <NumberInput
            name='cnpj'
            control={control}
            error={errors['cnpj']}
            label='CNPJ '
            format='##.###.###/####-##'
            width='25%'
            dense
          />
          <TextInput
            name='company_name'
            control={control}
            error={errors['company_name']}
            label='Empresa *'
            width='50%'
            dense
          />
          <TextInput
            name='company_site'
            control={control}
            error={errors['company_site']}
            label='Site da empresa'
            width='25%'
            dense
          />
        </Row>
        <Row>
          <NumberInput
            name='zip_code'
            control={control}
            error={errors['zip_code']}
            label='CEP *'
            format='#####-###'
            width='25.5%'
            dense
          />
          <TextInput name='street' control={control} error={errors['street']} label='Rua *' width='39%' dense />
          <TextInput name='number' control={control} error={errors['number']} label='Nº *' width='10%' dense />
          <TextInput
            name='district'
            control={control}
            error={errors['district']}
            label='Bairro *'
            width='25.5%'
            dense
          />
        </Row>
        <Row>
          <TextInput
            name='complement'
            control={control}
            error={errors['complement']}
            label='Complemento'
            width='25.5%'
            dense
          />
          <TextInput name='city' control={control} error={errors['city']} label='Cidade *' width='25%' dense />
          <Select
            name='uf'
            error={errors['uf']}
            options={statesOptions}
            control={control}
            label='Estado *'
            width='24%'
            dense
          />
          <BlankField width='25.5%' />
        </Row>
        <Row>
          <NumberInput
            name='average_monthly_revenue'
            control={control}
            error={errors['average_monthly_revenue']}
            label='Faturamento médio mensal estimado *'
            type='currency'
            width='50%'
            dense
          />
          <NumberInput
            name='desired_amount'
            control={control}
            error={errors['desired_amount']}
            label='Valor do empréstimo desejado *'
            type='currency'
            width='50%'
            dense
          />
        </Row>
        <Row>
          <ReactIsCapsLockActive>
            {active => (
              <>
                <TextInput
                  name='company_history'
                  control={control}
                  error={active ? { message: 'Caps Lock ligado' } : errors['company_history']}
                  label='Histórico da Empresa *'
                  width='50%'
                  multiline
                  dense
                />
                <TextInput
                  name='lending_reason'
                  control={control}
                  error={active ? { message: 'Caps Lock ligado' } : errors['lending_reason']}
                  label='Motivo do empréstimo *'
                  width='50%'
                  multiline
                  dense
                />
              </>
            )}
          </ReactIsCapsLockActive>
        </Row>
        <ButtonContainer>
          <Button label='Enviar' type='submit' width='270px' loading={status === SUBMITTING} />
        </ButtonContainer>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  font-family: Open Sans;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 20px;
  width: 90%;
  max-width: 1280px;
  @media (min-width: 768px) {
    width: 85%;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  margin: 8px 0px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
const SubTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #424242;
  margin: 14px 0px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const TitleContainer = styled.div`
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const ButtonContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
    margin: 10px 0px;
  }
`
export default CreditRequest
