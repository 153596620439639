import React from 'react'
import PropTypes from 'prop-types'

import { Container, Content, OpenDetails } from './styles'

const ResponsiveCard = ({ infoParcels }) => {
  const infos = infoParcels

  return (
    <>
      {infos.map((info, index) => {
        return (
          <Container key={index}>
            <Content>
              <label>{info.Parcela}</label>
              <label>{info['Valor previsto da TED']}</label>
            </Content>

            <Content>
              <section>
                <div>
                  <p>Previsão</p>
                  <label>{info['Previsão de pagamento']}</label>
                </div>
                <div>
                  <p>Pago em</p>
                  <label>{info['Pago em']}</label>
                </div>
              </section>
              <OpenDetails onClick={info.detalhes.props.onClick}>Ver detalhes</OpenDetails>
            </Content>
          </Container>
        )
      })}
    </>
  )
}

ResponsiveCard.propTypes = {
  openModal: PropTypes.func
}

export default ResponsiveCard
