import React from 'react'
import { Formik, Form, Field } from 'formik'
import { personalBankInputs } from '../../constants/forms'
import { formatMoney } from '../../helpers/index'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import { getBankName } from '../../helpers/formatters'
import { formatBankToUpdate, formatInvestorToUpdate } from '../../utils/formatters'
import { updateBank, updateInvestor } from '../../services'
import { toast } from 'react-toastify'
import { renderField } from '../../constants/inputs'

const BankForm = ({ classes, bank, investor }) => {
  const handleSubmit = async values => {
    try {
      const bankData = formatBankToUpdate(values)
      const investorData = formatInvestorToUpdate(values)
      const responseBank = await updateBank({ data: { bank: { ...bankData }, id: bank.id } })
      const responseInvestor = await updateInvestor(investor.kvd_id, investorData)
      if (responseBank && responseInvestor) {
        toast.success('Dados bancários atualizado com sucesso.')
      } else {
        toast.error('Não foi possível atualizar o dados bancários')
      }
    } catch (e) {
      toast.error('Não foi possível atualizar o dados bancários')
    }
  }
  return (
    <Grid>
      <Formik
        initialValues={{
          kvd_id: bank.kvd_id,
          agency_digit: bank.agency_digit,
          type: bank.type,
          code: getBankName(bank.code),
          agency: bank.agency,
          account: bank.account,
          account_digit: bank.account_digit,
          monthly_earn: investor.monthly_earn ? formatMoney(investor.monthly_earn) : '',
          property_value: investor.property_value ? formatMoney(investor.property_value) : ''
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className={classes.container}>
            {personalBankInputs.map(input => (
              <Field name={input.name} render={({ field }) => renderField(input, field, classes)} />
            ))}
            <Grid container spacing={1} className={classes.containerButton}>
              <Fab type='submit' variant='extended' aria-label='Add' className={classes.button}>
                SALVAR E CONTINUAR
              </Fab>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default BankForm
