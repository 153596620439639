import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { toast } from 'react-toastify'
import { RATE } from './rateInteration'

import { Column, Row, Button, NumberInput, Checkbox, Select, Radio, Proposal } from '../index'
import { Modal, StyledRow, GridProposal } from './style'

import { createLeadUpdate, createProposal, updateLeads } from '../../../services/v3'
import { sanitizeMoneyNumber, formatAnnualRate, formatCurrency, modalProposal } from '../../../helpers'

const campaignTypes = [{ value: '1', label: 'Possui aval' }, { value: '2', label: 'Possui aval e garantia' }]
const modalityTypes = [
  {
    value: '1',
    label: 'Operação'
  },
  {
    value: '2',
    label: 'Próprio'
  }
]

const gracePeriods = [
  {
    value: '0',
    label: '0'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '60',
    label: '60'
  }
]

const parcelNumbers = [
  {
    value: '18',
    label: '18'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '36',
    label: '36'
  }
]

const ModalAddRegister = ({ open, buttonClick, handleClose, addInTimeline }) => {
  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: { isSubmitting }
  } = useForm({ resolver: yupResolver(modalProposal) })

  const [checkIOF, setCheckIOF] = useState(true)

  const type = watch('type')
  const goal = watch('goal')
  const gracePeriod = watch('grace_period')
  const subtractDeadline = watch('subtract_deadline')
  const parcelNumber = watch('parcel_number')
  const feeMonthly = watch('fee_monthly')
  const collateral = watch('collateral')
  const modalityType = watch('modality_type')
  const tac = watch('tac')
  const iof = watch('iof')

  const proposalData = useMemo(() => {
    let sanitizeTaxMonth = Number(sanitizeMoneyNumber(feeMonthly))

    let financing_value = sanitizeMoneyNumber(goal)
    let grace_period = gracePeriod
    let first_due = !!gracePeriod ? 30 + Number(gracePeriod) : 30
    let installments_number =
      subtractDeadline === true ? Number(parcelNumber) - Number(gracePeriod) / 30 : Number(parcelNumber)

    let installment_value = (
      (((1 + sanitizeTaxMonth / 100) ** installments_number * (sanitizeTaxMonth / 100)) /
        ((1 + sanitizeTaxMonth / 100) ** installments_number - 1)) *
      ((1 + sanitizeTaxMonth / 100) ** (Number(gracePeriod) / 30) * sanitizeMoneyNumber(goal))
    ).toFixed(2)

    let rate_month = !!feeMonthly ? Number(feeMonthly.replace(',', '.')) : 0
    let rate_annual = !!feeMonthly ? formatAnnualRate(sanitizeMoneyNumber(feeMonthly)) : 0
    let warranty = modalityType === '2' ? 'Com recursos próprios' : 'Retirada do valor da operação'
    let warranty_type = type === '1' ? 'AVAL' : 'CDB'
    let warranty_percentage = type === '1' ? 100 : !!collateral ? Number(collateral.replace('%', '')) : ''
    let warranty_value =
      type === '2' && !!collateral ? (sanitizeMoneyNumber(goal) * Number(collateral.replace('%', ''))) / 100 : 0
    let kavod_percentage = !!tac ? Number(tac.replace('%', '')) : 0
    let commission_amount = !!tac ? (Number(tac.replace('%', '')) / 100) * sanitizeMoneyNumber(goal) : 0
    let iof_data = iof ? Math.round((1.88 / 100) * sanitizeMoneyNumber(goal)).toFixed(2) : 0
    let liquid_amount =
      modalityType === '1'
        ? sanitizeMoneyNumber(goal) - warranty_value - commission_amount - iof_data
        : sanitizeMoneyNumber(goal) - commission_amount - iof_data

    let cet_month = (
      RATE(installments_number, installment_value, -1 * (sanitizeMoneyNumber(goal) - commission_amount - iof_data)) *
      100
    ).toFixed(2)
    let cet_annual = (
      ((1 +
        RATE(
          installments_number,
          installment_value,
          -1 * (sanitizeMoneyNumber(goal) - commission_amount - iof_data)
        )) **
        12 -
        1) *
      100
    ).toFixed(2)

    return {
      time: format(new Date(), 'dd/MM/yyyy', { locale: ptBR }),
      cnpj_name: open.company_name,
      financing_value: financing_value,
      grace_period: grace_period,
      first_due: first_due,
      installment_value: Number(installment_value),
      installments_number: installments_number,
      rate_month: rate_month,
      rate_annual: Number(rate_annual),
      warranty: warranty,
      warranty_type: warranty_type,
      warranty_percentage: warranty_percentage,
      warranty_value: warranty_value,
      kavod_percentage: kavod_percentage,
      commission_amount: commission_amount,
      iof_data: iof_data,
      liquid_amount: liquid_amount,
      cet_month: Number(cet_month),
      cet_annual: Number(cet_annual)
    }
  }, [type, goal, gracePeriod, subtractDeadline, parcelNumber, feeMonthly, collateral, modalityType, tac, iof, open])

  useEffect(() => {
    reset({
      goal: formatCurrency(open.lending_amount),
      grace_period: '0',
      parcel_number: '24',
      iof: true,
      modality_type: '1'
    })
    setCheckIOF(true)
  }, [open])

  const handleSimulateProposal = useCallback(async () => {
    try {
      await createProposal({
        financing_value: proposalData.financing_value,
        first_due: proposalData.first_due,
        installment_value: proposalData.installment_value,
        installments_number: proposalData.installments_number,
        rate_month: proposalData.rate_month,
        rate_annual: proposalData.rate_annual,
        warranty: proposalData.warranty,
        warranty_type_id: Number(type),
        warranty_value: proposalData.warranty_value,
        warranty_percentage: proposalData.warranty_percentage,
        kavod_percentage: proposalData.kavod_percentage,
        commission_amount: proposalData.commission_amount,
        iof: proposalData.iof_data,
        liquid_amount: proposalData.liquid_amount,
        cet_month: proposalData.cet_month,
        cet_annual: proposalData.cet_annual,
        is_accepted: false,
        is_simulation: true,
        lead_id: open.id
      }).then(async ({ data }) => {
        await createLeadUpdate({
          lead_id: open.id,
          lead_update_status_id: 5,
          is_visible_to_partner: true,
          proposal_id: data.id
        })
      })

      document.location.reload(true)
      toast.success('Proposta enviada com sucesso.')
      handleClose()
    } catch {
      toast.error('Ocorreu um erro ao enviar proposta.')
    }
  }, [proposalData, open, type])

  const handleNewProposal = useCallback(async () => {
    try {
      await createProposal({
        financing_value: proposalData.financing_value,
        first_due: proposalData.first_due,
        installment_value: proposalData.installment_value,
        installments_number: proposalData.installments_number,
        rate_month: proposalData.rate_month,
        rate_annual: proposalData.rate_annual,
        warranty: proposalData.warranty,
        warranty_type_id: Number(type),
        warranty_value: proposalData.warranty_value,
        warranty_percentage: proposalData.warranty_percentage,
        kavod_percentage: proposalData.kavod_percentage,
        commission_amount: proposalData.commission_amount,
        iof: proposalData.iof_data,
        liquid_amount: proposalData.liquid_amount,
        cet_month: proposalData.cet_month,
        cet_annual: proposalData.cet_annual,
        is_accepted: false,
        is_simulation: false,
        lead_id: open.id
      }).then(async ({ data }) => {
        await createLeadUpdate({
          lead_id: open.id,
          lead_update_status_id: 5,
          is_visible_to_partner: true,
          proposal_id: data.id
        })
        await updateLeads(open.id, { is_visible_to_partner: true, lead_status_id: 6 })
      })

      document.location.reload(true)
      toast.success('Proposta enviada com sucesso.')
      handleClose()
    } catch {
      toast.error('Ocorreu um erro ao enviar proposta.')
    }
  }, [proposalData, open, type])

  return (
    <Modal open={open} onClose={handleClose}>
      <GridProposal>
        <form style={{ gridArea: 'input' }}>
          <StyledRow flexDirection='row'>{<h4>Proposta</h4>}</StyledRow>
          <Column>
            <NumberInput
              mb='20px !important'
              id='goal'
              name='goal'
              inputType='goal'
              control={control}
              error={errors.goal}
            />
            <Select
              id='grace_period'
              name='grace_period'
              label='Período de carência'
              control={control}
              options={gracePeriods}
              error={errors.grace_period}
              mb='0px !important'
            />

            <Checkbox
              label='Subtrair do prazo'
              control={control}
              name='subtract_deadline'
              error={errors.subtract_deadline}
            />
            <Select
              id='parcel_number'
              name='parcel_number'
              label='Número de parcelas'
              control={control}
              options={parcelNumbers}
              error={errors.parcel_number}
              mb='20px !important'
            />

            <NumberInput
              style={{ gridArea: 'month' }}
              mb='20px !important'
              id='fee_monthly'
              name='fee_monthly'
              inputType='fee'
              label='Taxa mensal'
              step={0.01}
              control={control}
              error={errors.fee_monthly}
            />

            <Select
              style={{ gridArea: 'select' }}
              id='type'
              name='type'
              label='Tipo de campanha'
              control={control}
              options={campaignTypes}
              error={errors.type}
              mb='0px !important'
            />

            {type === '2' && (
              <NumberInput
                mt='20px !important'
                mb='20px !important'
                id='collateral'
                name='collateral'
                suffix='%'
                label='Porcentagem da garantia'
                control={control}
                error={errors.collateral}
              />
            )}

            <Row>
              <Radio
                mb='0px !important'
                name='modality_type'
                control={control}
                error={errors.modality_type}
                options={modalityTypes}
              />
            </Row>

            <NumberInput
              style={{ gridArea: 'tac' }}
              mb='10px !important'
              id='tac'
              name='tac'
              suffix='%'
              label='Comissão Kavod'
              control={control}
              error={errors.tac}
            />

            <Checkbox
              label='IOF'
              control={control}
              name='iof'
              error={errors.iof}
              checked={checkIOF}
              onChange={() => setCheckIOF(!checkIOF)}
            />
          </Column>
        </form>
        <Column justifyContent='space-between' style={{ gridArea: 'proposal' }}>
          <div>
            <StyledRow flexDirection='row'>{<h4>Preview</h4>}</StyledRow>
            <Proposal data={proposalData} />
          </div>

          <Row mt='10px' justifyContent='flex-end' flexWrap='wrap' style={{ gap: '20px' }}>
            <Button
              variant='outlined'
              style={{ color: '#FD4646', borderColor: '#FD4646', borderWidth: '3px' }}
              width={130}
              onClick={() => {
                buttonClick()
              }}
            >
              Cancelar
            </Button>

            <Button
              height={40}
              width={130}
              style={{ borderColor: '#076F9F', borderWidth: '3px' }}
              onClick={handleSubmit(handleSimulateProposal)}
              variant='outlined'
            >
              Simulação
            </Button>
            <Button height={40} width={130} onClick={handleSubmit(handleNewProposal)} loader={isSubmitting}>
              Proposta
            </Button>
          </Row>
        </Column>
      </GridProposal>
    </Modal>
  )
}

export default ModalAddRegister
