import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Modal } from '../../../components'
import { Button, Select } from '../../../components/v2Components'

import { updateCampaign } from '../../../services/v3'

const options = [
  { label: 'Não está em execução.', value: 1 },
  { label: 'Em processo de execução judicial', value: 2 },
  { label: 'Execução judicial para retomada dos pagamentos', value: 3 }
]

const ModalExeciton = ({ isOpen, setIsOpen, campaign, reload }) => {
  const { handleSubmit, errors, control, reset } = useForm({})

  const onSubmit = useCallback(
    async values => {
      try {
        await updateCampaign(campaign.id, { campaign_state_id: values.execution_type })

        toast.success('Campanha atualizada.')
        reload()
        setIsOpen()
      } catch (error) {}
    },
    [campaign]
  )

  useEffect(() => {
    reset({ execution_type: campaign.campaign_states.id })
  }, [campaign])

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>Execução Judicial</Title>

          <Select
            name='execution_type'
            label='Status da execução'
            control={control}
            options={options}
            error={errors['execution_type']}
            mb='10px'
            mt='20px'
            style={{ width: '100%' }}
          />
          <DivButton>
            <Button variant='outlined' width='50%' onClick={() => setIsOpen()}>
              Não
            </Button>
            <Button
              color='white'
              style={{
                backgroundColor: 'red'
              }}
              width='50%'
              type='submit'
            >
              Sim
            </Button>
          </DivButton>
        </form>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
`
const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const DivButton = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  margin-top: 10px;
`
export default ModalExeciton
