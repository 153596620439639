import * as yup from 'yup'

export const modalRefuseDocumentResolver = yup.object().shape({
  reason: yup
    .string()
    .max(255, 'Deve ter no máximo 255 caracteres')
    .nullable()
})

export const modalProposal = yup.object().shape({
  tac: yup.string().required('Campo obrigatório'),
  fee_monthly: yup.string().required('Campo obrigatório')
})
