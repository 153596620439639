import styled from 'styled-components'

export const P = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #424242;
  margin-top: 16px;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0px;
  }
`
