import React from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import RadioUI from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'

const KavodRadio = withStyles({
  root: {
    '&$checked': {
      color: '#076F9F'
    }
  },
  checked: {}
})(props => <RadioUI color='default' {...props} />)

const Radio = ({
  control,
  defaultValue = '',
  name,
  error,
  label,
  options,
  width,
  margin,
  orientation = 'row',
  ...props
}) => {
  return (
    <Container width={width} margin={margin}>
      <FormControl variant='outlined' fullWidth error={Boolean(error)}>
        {label && <FormLabel component='legend'>{label}</FormLabel>}
        <Controller
          as={
            <RadioGroup aria-label={name} name={name} style={{ display: 'flex', flexDirection: orientation }}>
              {options.map(({ value, label }) => (
                <FormControlLabel key={value} value={value} control={<KavodRadio />} label={label} />
              ))}
            </RadioGroup>
          }
          defaultValue={defaultValue}
          error={error}
          name={name}
          control={control}
          {...props}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '220px')};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`

export { Radio }
