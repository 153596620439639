import React from 'react'

import { Column, Main } from './style'

const Body = ({ children }) => {
  return (
    <Column alignItems='center' width='100%'>
      <Main>{children}</Main>
    </Column>
  )
}

export default Body
