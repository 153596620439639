import React, { useEffect } from 'react'
import styled from 'styled-components'
import EmailIcon from '@material-ui/icons/Email'

import { TextInput, NumberInput } from '../../components/FormComponents'
import { CardEditIcon } from './'

import { formatPhoneWithoutPrefix, formatInputPhone } from '../../helpers'
import { leadModalCards } from '../../constants'

import { Card, Title, InfoContainer, Info, Bold, EditInputsContainer } from './styledComponents'

const ContactCard = ({ lead, isEditing, handleEditCard, control, errors, setValue }) => {
  const sendEmail = () => {
    window.open(`mailto:${lead.email}`)
  }

  useEffect(() => {
    if (!isEditing) return
    setValue('first_name', lead.first_name)
    setValue('last_name', lead.last_name)
    setValue('celphone', lead.celphone_prefix + lead.celphone)
    setValue('telephone', lead.telephone_prefix + lead.telephone)
    setValue('email', lead.email)
  }, [isEditing])

  return (
    <Card>
      <CardEditIcon onClick={() => handleEditCard(leadModalCards.CONTACT_INFOS)} />
      <Title>Dados do contato</Title>
      {isEditing ? (
        <EditInputsContainer>
          <TextInput
            name='first_name'
            control={control}
            label='Nome *'
            error={errors['first_name']}
            defaultValue=''
            width='160px'
            dense
          />
          <TextInput
            name='last_name'
            control={control}
            label='Sobrenome'
            error={errors['last_name']}
            defaultValue=''
            width='160px'
            dense
          />
          <NumberInput
            name='celphone'
            format={formatInputPhone}
            control={control}
            error={errors['celphone']}
            label='Celular *'
            defaultValue=''
            width='160px'
            dense
          />
          <NumberInput
            name='telephone'
            format={formatInputPhone}
            control={control}
            error={errors['telephone']}
            label='Telefone fixo'
            defaultValue=''
            width='160px'
            dense
          />
          <TextInput
            name='email'
            control={control}
            error={errors['email']}
            label='E-mail *'
            defaultValue=''
            width='280px'
            dense
          />
        </EditInputsContainer>
      ) : (
        <InfoContainer>
          <Info>
            <Bold>Nome:</Bold> {lead.first_name}
          </Info>
          <Info>
            <Bold>Sobrenome:</Bold> {lead.last_name || 'não informado'}
          </Info>
          <Info>
            <Bold>Celular:</Bold> {`(${lead.celphone_prefix || '##'}) ${formatPhoneWithoutPrefix(lead.celphone)}`}
          </Info>
          <Info>
            <Bold>Telefone Fixo:</Bold>{' '}
            {`(${lead.telephone_prefix || '##'}) ${formatPhoneWithoutPrefix(lead.telephone)}`}
          </Info>
          <Info>
            <Bold>E-mail:</Bold> {lead.email} <StyledEmailIcon style={{ fontSize: '14px' }} onClick={sendEmail} />
          </Info>
        </InfoContainer>
      )}
    </Card>
  )
}

const StyledEmailIcon = styled(EmailIcon)`
  margin-bottom: 1px;
  color: #757575;
  cursor: pointer;
`

export default ContactCard
