import * as yup from 'yup'

yup.setLocale({
  mixed: {
    default: 'Inválido',
    required: 'Campo obrigatório',
    email: 'E-mail inválido.'
  }
})

export const CampaignInfoForm = yup.object().shape({
  name: yup.string().required('Campo obrigatório.'),
  url: yup.string().required('Campo obrigatório.'),
  company_name: yup.string().required('Campo obrigatório.'),
  rate_month: yup
    .number()
    .max(10, 'a taxa máxima é de 10')
    .required('Campo obrigatório.'),
  installments: yup.number().required('Campo obrigatório.'),
  amount: yup.string().required('Campo obrigatório.'),
  min_amount: yup.string().required('Campo obrigatório.'),
  max_amount: yup.string().required('Campo obrigatório.'),
  grace_period: yup.string().required('Campo obrigatório.'),
  start_date: yup
    .string()
    .required('Campo obrigatório.')
    .min(10, 'Preencha totalmente o campo.'),
  start_time: yup
    .string()
    .required('Campo obrigatório')
    .min(5, 'Preencha totalmente o campo.'),
  end_date: yup
    .string()
    .required('Campo obrigatório.')
    .min(10, 'Preencha totalmente o campo.'),
  end_time: yup
    .string()
    .required('Campo obrigatório.')
    .min(5, 'Preencha totalmente o campo.')
})

export const processCampaignForm = yup.object().shape({
  company_name: yup.string().required(),
  company_cnpj: yup.string().required(),
  company_zip_code: yup.string().required(),
  company_street: yup.string().required(),
  company_number: yup.string().required(),
  company_city: yup.string().required(),
  company_neighborhood: yup.string().required(),
  company_uf: yup.string().required(),
  company_country: yup.string().required(),
  company_email: yup
    .string()
    .email()
    .required(),
  company_bank_code: yup.string().required(),
  company_bank_agency: yup.number().required(),
  company_bank_account: yup.number().required(),
  company_bank_account_digit: yup.number().required(),
  company_bank_type: yup.string().required(),
  subscribers: yup
    .array()
    .of(
      yup.object().shape({
        subscriber_email: yup
          .string()
          .email()
          .required(),
        subscriber_name: yup.string().required(),
        subscriber_phone_prefix: yup.string().required(),
        subscriber_phone: yup.string().required()
      })
    )
    .min(1, 'Preencha pelo menos um assinante'),
  guarantors: yup
    .array()
    .of(
      yup.object().shape({
        guarantor_name: yup.string().required(),
        guarantor_document: yup.string().required(),
        guarantor_id_card: yup.string(),
        guarantor_marital_status: yup.string(),
        guarantor_marital_type: yup.string(),
        guarantor_zip_code: yup.string().required(),
        guarantor_street: yup.string().required(),
        guarantor_number: yup.string().required(),
        guarantor_neighborhood: yup.string().required(),
        guarantor_city: yup.string().required(),
        guarantor_uf: yup.string().required()
      })
    )
    .min(1, 'Preencha pelo menos um avalista'),
  partners: yup
    .array()
    .of(
      yup.object().shape({
        partner_name: yup.string().required(),
        partner_document: yup.string().required()
      })
    )
    .min(1, 'Preencha pelo menos um sócio'),
  campaign_first_due_date: yup.string().required(),
  campaign_guarantee: yup.string().required()
})
