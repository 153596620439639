import React, { useState } from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import InputMask from 'react-input-mask'
import { CurrencyInput, PercentageInput } from '../../components'

export default withTheme(({ onFocus, onBlur, ...props }) => {
  const [focused, isFocused] = useState(false)
  const {
    theme: { colors }
  } = props
  const handleFocus = () => {
    isFocused(!focused)
  }

  return (
    <InputWrapper>
      <InputLabelContainer>
        <Label focused={focused} colors={colors}>
          {props.label}
        </Label>
        {props.password && <InputIcon src={props.error ? '/assets/icons/error.svg' : '/assets/icons/eye.svg'} />}
      </InputLabelContainer>
      {props.mask ? (
        <InputMask
          mask={props.mask}
          maskChar={props.maskChar ? props.maskChar : ''}
          formatChars={props.formatChars}
          focused={focused}
          placeholder={props.placeholder}
          name={props.name}
          captions={props.captions}
          colors={colors}
          style={!!props.green ? { background: '#4ab34e24' } : {}}
          disabled={props.disabled}
          onFocus={handleFocus}
          onBlur={handleFocus}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          autoComplete={props.autocomplete}
          loading={props.loading}
        >
          {inputProps => (
            <Input
              {...inputProps}
              onFocus={handleFocus}
              onBlur={handleFocus}
              disabled={props.disabled}
              type='tel'
              disableUnderline
            />
          )}
        </InputMask>
      ) : props.currency ? (
        <CurrencyInput onFocus={handleFocus} onBlur={handleFocus} {...props} />
      ) : props.percentage ? (
        <PercentageInput onFocus={handleFocus} onBlur={handleFocus} {...props} />
      ) : (
        <Input
          focused={focused}
          loading={props.loading}
          placeholder={props.placeholder}
          name={props.name}
          captions={props.captions}
          colors={colors}
          disabled={props.disabled}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
            handleFocus()
          }}
          onBlur={handleFocus}
          type={props.type}
          autoComplete={props.autocomplete}
          {...props}
        />
      )}
      <Caption colors={colors}>{props.caption}</Caption>
    </InputWrapper>
  )
})

const InputIcon = styled.img`
  transform: translateY(2em);
  width: 1.5em;
  transition: all 0.3s;
  height: 1.5em;
`

const InputLabelContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 0.5em;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3em;
`
const Label = styled.label`
  color: ${({ colors, focused }) => (focused ? colors.blueKavod : colors.mediumGray)};
  transition: all 0.3s;
  white-space: nowrap;
`
const Input = styled.input`
  ${({ colors, secondary, ghost, disabled, focused, error, loading }) => `
    border-radius: 4px;
    height: 40px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
    outline: none;
    border: 1px solid ${error ? 'red' : focused ? colors.blueKavod : colors.mediumGray};
    padding: 0 10px;
    background: ${loading ? '#ddd' : ''};
  `}
`
const Caption = styled.small`
  ${({ colors }) => `
    color: ${colors.mediumGray};
    font-size: 12px;
    margin-top: 5px;
  `}
`
