import React, { useCallback, useState } from 'react'
import { AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  StyledAccordion,
  StyledAccordionDetails,
  AccordionDetailsDocuments,
  CompanyData,
  StyledColumn,
  TextScroll,
  Label,
  StyledLaunchIcon,
  StyledEmailIcon
} from './styles'
import { StyledAccordionDetailsFranchise } from './styles'

import { Text, Row, Button, Column, IconButton } from '../index'
import {
  ModalInitCredit,
  ModalAproveDoc,
  ModalRecuseDoc,
  ModalEditDoc,
  ModalDeleteDoc,
  ModalSendDocuments,
  ModalPendingDocument
} from './components'

import { IconEdit, IconDelete } from '../icons'

import { formatCNPJ, formatValueToBrl, formatPhoneWithoutPrefix, formatZipCode } from '../../../helpers'
import { createLeadUpdate, getLeadDocumentUrl, updateDocumentStatus } from '../../../services/v3'

const Accordion = ({ state, fetchInfos, documentType }) => {
  const history = useHistory()
  const [openModalAprove, setOpenModalAprove] = useState(false)
  const [openModalRecuse, setOpenModalRecuse] = useState(false)
  const [openModalCredit, setOpenModalCredit] = useState(false)
  const [openModalPendingDocument, setModalPendingDocument] = useState(false)
  const [openModalSendDoc, setOpenModalSendDoc] = useState(false)
  const [openModalEditDoc, setOpenModalEditDoc] = useState(false)
  const [openModalDeleteDoc, setOpenModalDeleteDoc] = useState(false)

  const openMaps = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${state.street}+${state.number}+${state.city}+${state.uf}`
    )
  }

  const openSite = () => {
    if (state.company_site)
      window.open(state.company_site.match(/^http[s]?:\/\//) ? state.company_site : 'http://' + state.company_site)
  }

  const handleShowDocument = useCallback(
    async doc => {
      try {
        const { data: document } = await getLeadDocumentUrl(state.id, doc.id)
        window.open(document.url, '_blank')
      } catch (error) {}
    },
    [state]
  )

  const handleAproveDoc = useCallback(async (doc, data) => {
    try {
      await updateDocumentStatus({
        document_id: doc.id,
        is_approved: true,
        filename: data.name,
        lead_document_type_id: data.document_type
      })
      fetchInfos()
      toast.success('Documento aprovado.')
    } catch (error) {
      toast.error('Erro ao aprovar documento.')
    }
  }, [])

  const handleRecuseDoc = useCallback(async doc => {
    console.log(doc)
    try {
      const { data } = await createLeadUpdate({
        reason: doc.reason,
        lead_id: doc.lead_id,
        lead_update_status_id: 10,
        is_visible_to_partner: doc.is_visible_to_partner
      })
      await updateDocumentStatus({ document_id: doc.id, is_approved: false, lead_update_id: data.id })
      fetchInfos()
      toast.success('Documento recusado.')
    } catch (error) {
      toast.error('Erro ao recusar documento.')
    }
  }, [])

  const handleInitCredit = useCallback(() => {
    if (state.lead_Documents.filter(doc => doc.approved === null).length > 0) {
      setModalPendingDocument(true)
    } else {
      setOpenModalCredit(state)
    }
  }, [state])

  return (
    <Column mt={4}>
      <Row mb={3} justifyContent='space-between'>
        <Text variant='h4'>Informações da solicitação</Text>
        <Button variant='text' onClick={() => history.push(`/formulario-solicitacao-credito/${state.id}`)}>
          {' '}
          Editar{' '}
        </Button>
      </Row>

      <StyledColumn>
        <StyledAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='Dados de contato' id='Dados de contato'>
            <Text variant='subtitle2'>Dados do contato</Text>
          </AccordionSummary>
          <StyledAccordionDetails>
            <div>
              <Label>Nome</Label>
              <Text variant='body1' color='gray.600'>
                {state.first_name}
              </Text>
            </div>
            <div>
              <Label>Sobrenome</Label>
              <Text variant='body1' color='gray.600'>
                {state.last_name || '-'}
              </Text>
            </div>
            <div>
              <Label>Celular</Label>
              <Text variant='body1' color='gray.600'>
                ({state.celphone_prefix}) {formatPhoneWithoutPrefix(state.celphone)}
              </Text>
            </div>
            <div>
              <Label>Telefone fixo</Label>
              <Text variant='body1' color='gray.600'>
                ({state.telephone_prefix || '##'}) {formatPhoneWithoutPrefix(state.telephone) || ' - '}
              </Text>
            </div>
            <div>
              <Label>E-mail</Label>
              <Row justifyContent='start' alignItems='center'>
                <Text mr={1} variant='body1' color='gray.600'>
                  {state.email}
                </Text>
                <StyledEmailIcon fontSize='small' onClick={() => window.open(`mailto:${state.email}`)} />
              </Row>
            </div>
          </StyledAccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          defaultExpanded={state.lead_status_id === 1 || state.lead_status_id === 2 || state.lead_status_id >= 5}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='Dados da empresa' id='Dados da empresa'>
            <Text variant='subtitle2'>Dados da empresa</Text>
          </AccordionSummary>
          <CompanyData>
            <div style={{ gridArea: 'type' }}>
              <Label>Tipo de empresa</Label>
              <Text variant='body1' color='gray.600'>
                {state.company_type === 'Franquia' ? 'Franquia' : 'Empresas em geral'}
              </Text>
            </div>
            <div style={{ gridArea: 'CNPJ' }}>
              <Label>CNPJ</Label>
              <Text variant='body1' color='gray.600'>
                {formatCNPJ(state.cnpj)}
              </Text>
            </div>
            <div style={{ gridArea: 'fantasyName' }}>
              <Label>Razão Social</Label>
              <Text variant='body1' color='gray.600'>
                {state.company_name}
              </Text>
            </div>
            <div style={{ gridArea: 'site' }}>
              <Label>Site da empresa</Label>
              <Row justifyContent='start' alignItems='center'>
                <Text mr={1} variant='body1' color='gray.600'>
                  {state.company_site || 'Não informado'}
                </Text>
                {state.company_site && <StyledLaunchIcon onClick={openSite} />}
              </Row>
            </div>
            <div style={{ gridArea: 'address' }}>
              <Label>Endereço</Label>
              <Row justifyContent='start' alignItems='center'>
                <Text mt={1} mr={1} variant='body1' color='gray.600'>
                  {state.zip_code
                    ? `${state.street}, ${state.number}${state.complement} ${formatZipCode(state.zip_code)} - ${
                        state.city
                      }/${state.uf}`
                    : 'Não informado'}
                </Text>
                <StyledLaunchIcon onClick={openMaps} />
              </Row>
            </div>
            <div style={{ gridArea: 'FMM' }}>
              <Label>Faturamento médio mensal estimado</Label>
              <Text variant='body1' color='gray.600'>
                {formatValueToBrl(state.average_monthly_revenue)}
              </Text>
            </div>
            <div style={{ gridArea: 'Value' }}>
              <Label>Valor solicitado</Label>
              <Text variant='body1' color='gray.600'>
                {formatValueToBrl(state.desired_amount)}
              </Text>
            </div>
            <div style={{ gridArea: 'historic', textAlign: 'justify' }}>
              <Label>Histórico da empresa </Label>
              <TextScroll variant='body1' color='gray.600' style={{ overflowY: 'auto', maxHeight: 138 }}>
                {' '}
                {state.company_history}
              </TextScroll>
            </div>
            <div style={{ gridArea: 'reason', textAlign: 'justify' }}>
              <Label>Motivo do empréstimo</Label>
              <TextScroll variant='body1' color='gray.600' style={{ overflowY: 'auto', maxHeight: 138 }}>
                {state.lending_reason}
              </TextScroll>
            </div>
          </CompanyData>
        </StyledAccordion>

        {state.company_type === 'Franquia' && (
          <StyledAccordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='Tipo de franquia' id='Tipo de franquia'>
              <Text variant='subtitle2'>Tipo de franquia</Text>
            </AccordionSummary>
            <StyledAccordionDetailsFranchise>
              <div style={{ gridArea: 'A' }}>
                <Text variant='caption'>Tipo de franquia</Text>
                <Text variant='body1' color='gray.600'>
                  {state.franchise_type === 'Nova' ? 'Nova loja' : 'Unidade em operação'}
                </Text>
              </div>
              <div style={{ gridArea: 'B' }}>
                <Text variant='caption'>Franqueador</Text>
                <Text variant='body1' color='gray.600'>
                  {state.franchisor_name}
                </Text>
              </div>
              <div style={{ gridArea: 'C', textAlign: 'justify' }}>
                <Text variant='caption'>Descrição da franquia</Text>
                <Text variant='body1' color='gray.600' style={{ overflowY: 'auto', maxHeight: 138 }}>
                  {state.franchise_description}
                </Text>
              </div>
            </StyledAccordionDetailsFranchise>
          </StyledAccordion>
        )}

        <StyledAccordion defaultExpanded={state.lead_status_id === 3 || state.lead_status_id === 4}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='Dados de contato' id='Dados de contato'>
            <Text variant='subtitle2'>Documentação</Text>
          </AccordionSummary>
          <AccordionDetailsDocuments>
            <Column style={{ gap: '3px' }}>
              <Row mt='15px' justifyContent='center'>
                <Button
                  variant='contained'
                  width={200}
                  height={40}
                  color='#ffffff'
                  style={{ background: '#076F9F' }}
                  mr='5px'
                  onClick={() => setOpenModalSendDoc({ id: state.id })}
                >
                  Enviar Documentos
                </Button>
              </Row>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.approved === null)
                  .map((doc, index) => (
                    <Row justifyContent='space-between' alignItems='center' key={index}>
                      <Row>
                        <Text
                          variant='body1'
                          mr='10px'
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleShowDocument(doc)}
                          color='primary'
                        >
                          {doc.filename || 'Documento sem nome'}
                        </Text>
                      </Row>

                      {doc.approved === null && (
                        <div>
                          <Button
                            variant='outlined'
                            bgColor='#4AB34E'
                            color='#4AB34E'
                            borderColor='#4AB34E'
                            mr='5px'
                            onClick={() => {
                              setOpenModalAprove(doc)
                            }}
                          >
                            Aceitar
                          </Button>
                          <Button
                            variant='outlined'
                            color='#FD4646'
                            borderColor='#FD4646'
                            onClick={() => {
                              setOpenModalRecuse(doc)
                            }}
                          >
                            Recusar
                          </Button>
                        </div>
                      )}
                    </Row>
                  ))}
            </Column>

            <Column mt='10px'>
              <Text variant='body1'>Contrato social</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 1 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Balanço e DRE</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 2 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Faturamento mensal</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 3 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Extrato bancário</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 4 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Ficha Cadastral KAVOD PJ</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 5 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Imposto de Renda mais recente</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 6 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Endividamento bancário</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 7 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>
            <Column mt='10px'>
              <Text variant='body1'>Referências comerciais e bancárias</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 8 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>

            <Column mt='10px'>
              <Text variant='body1'>Outros</Text>
              {state.lead_Documents.length > 0 &&
                state.lead_Documents
                  .filter(doc => doc.lead_document_type_id === 9 && doc.approved !== null)
                  .map((doc, index) => (
                    <Row alignItems='center' key={index}>
                      <Text
                        variant='body1'
                        mr='10px'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowDocument(doc)}
                        color='primary'
                      >
                        {doc.filename || 'Documento sem nome'}
                      </Text>

                      <IconButton
                        icon={<IconEdit fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalEditDoc(doc)
                        }}
                        mr={1}
                      />
                      <IconButton
                        icon={<IconDelete fontSize='small' />}
                        variant='text'
                        onClick={() => {
                          setOpenModalDeleteDoc(doc)
                        }}
                      />
                    </Row>
                  ))}
            </Column>

            {state.observations.length > 0 && (
              <Column>
                <Text variant='body1'>Observações</Text>
                {state.observations.map(obs => (
                  <Text variant='body1' color='gray.600'>
                    {obs}
                  </Text>
                ))}
              </Column>
            )}

            {state.lead_status_id !== 4 && state.lead_status_id !== 5 && (
              <Row mt='10px' justifyContent='flex-end'>
                <Button
                  variant='outlined'
                  width={200}
                  height={40}
                  bgColor='#076F9F'
                  color='#076F9F'
                  borderColor='#076F9F'
                  mr='5px'
                  onClick={handleInitCredit}
                >
                  Iniciar análise de crédito
                </Button>
              </Row>
            )}
          </AccordionDetailsDocuments>
        </StyledAccordion>
      </StyledColumn>
      <ModalAproveDoc
        open={openModalAprove}
        handleClose={() => setOpenModalAprove(false)}
        handleAproveDoc={handleAproveDoc}
        documentType={documentType}
      />
      <ModalRecuseDoc
        open={openModalRecuse}
        handleClose={() => setOpenModalRecuse(false)}
        handleRecuseDoc={handleRecuseDoc}
      />
      <ModalInitCredit open={openModalCredit} handleClose={() => setOpenModalCredit(false)} fetchInfos={fetchInfos} />
      <ModalPendingDocument open={openModalPendingDocument} handleClose={() => setModalPendingDocument(false)} />
      <ModalSendDocuments
        open={openModalSendDoc}
        handleClose={() => setOpenModalSendDoc(false)}
        lead={state}
        fetchInfos={fetchInfos}
        documentType={documentType}
      />
      <ModalEditDoc
        open={openModalEditDoc}
        handleClose={() => setOpenModalEditDoc(false)}
        documentType={documentType}
        fetchInfos={fetchInfos}
      />
      <ModalDeleteDoc
        open={openModalDeleteDoc}
        handleClose={() => setOpenModalDeleteDoc(false)}
        fetchInfos={fetchInfos}
      />
    </Column>
  )
}

export default Accordion
