import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import ReactTable from 'react-table-6'

export const Button = styled.button`
  height: 30px;
  cursor: pointer;
  padding: 0 1.3em;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blueKavod};
  margin: 0 1em;
`

export const ResetFilter = styled.p`
  font-size: 1em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blueKavod};
  &:hover {
    text-decoration: underline;
  }
`

export const SearchContainer = styled.form`
  display: flex;
  padding-bottom: 2em;
  align-items: flex-end;
  justify-content: center;
`

export const SelectContainer = styled.div`
  padding: 0 1em;
`

export const Select = styled.select`
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  outline: none;
  border: 1px solid #7f7f7f;
  padding: 0 10px;
  color: #7f7f7f;
  background: #fff;
  &:focus {
    border: 1px solid #076f9f;
  }
`

export const TypographyContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
`

export const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalContent = styled.div`
  padding: 2em;
  background: white;
  display: flex;
  flex-direction: column;
  min-width: 45%;
`

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`

export const CheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
`

export const StyledReactTable = styled(ReactTable)`
  width: 80vw;
`

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 0.3em;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
`
export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
export const Loader = styled.img`
  animation: ${rotate} 2s linear infinite;
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100vh;
`
