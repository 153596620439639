import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { formatCPF, fetchRG, formatDate, formatPhoneWithPrefix } from '../../helpers/general'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import { aboutPersonInputs } from '../../constants/forms'
import { updatePerson, updatePersonalDocument, uploadPersonalDocument } from '../../services'
import { toast } from 'react-toastify'
import { formatPersonToUpdate, formatDocumentRGToUpdate } from '../../utils/formatters'
import { renderField } from '../../constants/inputs'

const PersonForm = ({ classes, person, documents }) => {
  const [editRG, setEditRG] = useState(false)
  const documentRG = fetchRG(documents)

  useEffect(() => {
    setEditRG(!!documentRG)
  }, [])

  const handleSubmit = async values => {
    try {
      const personData = formatPersonToUpdate(values)
      const documentData = formatDocumentRGToUpdate(values)

      const promises = [
        updatePerson(person.kvd_id, personData),
        editRG ? updatePersonalDocument(documentData) : uploadPersonalDocument(documentData)
      ]
      const response = await Promise.all(promises)
      const responsePersons = response[0].data
      const responseDocuments = response[1].data
      if (!!responsePersons && !!responseDocuments) toast.success('Usuário atualizado com sucesso.')
    } catch (e) {
      toast.error('Não foi possível atualizar os dados pessoais do usuário')
    }
  }
  return (
    <Grid>
      <Formik
        initialValues={{
          kvd_id: person.kvd_id || '',
          id: documentRG.id || '',
          first_name: person.first_name || '',
          last_name: person.last_name || '',
          cpf: formatCPF(person.cpf) || '',
          email: person.email || '',
          phone: formatPhoneWithPrefix(person.prefix, person.phone) || '',
          birth_date: formatDate(person.birth_date) || '',
          nationality: person.nationality || '',
          naturality: person.naturality || '',
          gender: person.gender || '',
          marital_status_id: person.marital_status_id || '',
          number: documentRG.number || '',
          agent:
            documentRG.agent && documentRG.uf
              ? documentRG.agent + '-' + documentRG.uf
              : documentRG.agent || documentRG.uf,
          mother_name: person.mother_name || '',
          father_name: person.father_name || '',
          personal_document_type_id: documentRG.personal_document_type_id || ''
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, onChange, onBlur, onFocus }) => (
          <Form className={classes.container}>
            {aboutPersonInputs.map(input => (
              <Field
                name={input.name}
                render={({ field }) => renderField(input, field, classes, setFieldValue, onChange, onFocus, onBlur)}
              />
            ))}
            <Grid container spacing={1} className={classes.containerButton}>
              <Fab type='submit' variant='extended' aria-label='Add' className={classes.button}>
                SALVAR E CONTINUAR
              </Fab>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
export default PersonForm
