import React from 'react'
import styled from '@emotion/styled'

const ManageCampaignStatus = ({ campaign, handleUpdate }) => {
  const formatDataToUpdate = e => {
    const formattedCampaign = { ...campaign }
    delete formattedCampaign.campaign_name
    switch (e.target.name) {
      case 'payments_received':
        handleUpdate({
          ...formattedCampaign,
          payments_received: campaign.payments_received === 1 ? 0 : 1
        })
        break

      case 'disbursement_done':
        handleUpdate({
          ...formattedCampaign,
          disbursement_done: campaign.disbursement_done === 1 ? 0 : 1
        })
        break

      case 'bank_credit_certificate_emitted':
        handleUpdate({
          ...formattedCampaign,
          bank_credit_certificate_emitted: campaign.bank_credit_certificate_emitted === 1 ? 0 : 1
        })
        break
      default:
        break
    }
  }
  return (
    <Container>
      <Header>
        <CampaignTitle>{campaign.campaign_name}</CampaignTitle>
      </Header>
      <Content>
        <StatusOption>
          <input
            name='payments_received'
            id={`${campaign.id}-payments_received`}
            type='checkbox'
            defaultChecked={campaign.payments_received === 1}
            onChange={formatDataToUpdate}
          />
          <label htmlFor={`${campaign.id}-payments_received`}>TEDS Recebidas</label>
        </StatusOption>
        <StatusOption>
          <input
            name='disbursement_done'
            id={`${campaign.id}-disbursement_done`}
            type='checkbox'
            defaultChecked={campaign.disbursement_done === 1}
            onChange={formatDataToUpdate}
          />
          <label htmlFor={`${campaign.id}-disbursement_done`}>Desembolso realizado</label>
        </StatusOption>
        <StatusOption>
          <input
            name='bank_credit_certificate_emitted'
            id={`${campaign.id}-bank_credit_certificate_emitted`}
            type='checkbox'
            defaultChecked={campaign.bank_credit_certificate_emitted === 1}
            onChange={formatDataToUpdate}
          />
          <label htmlFor={`${campaign.id}-bank_credit_certificate_emitted`}>CCBS Emitidas</label>
        </StatusOption>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
`

const StatusOption = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: 10px;
  }
  label {
    cursor: pointer;
  }
`

const CampaignTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`

const Content = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

const Header = styled.div`
  background-color: #f7f7f9;
  padding: 0.75rem 1.25rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`

export default ManageCampaignStatus
