import React from 'react'
import styled from '@emotion/styled'
import { Field, ErrorMessage } from 'formik'
import { Input } from '../'

const FieldForm = ({ name, label, placeholder, type = 'text', ...props }) => {
  return (
    <InputWrapper>
      <Field
        name={name}
        render={({ field }) => <Input {...field} type={type} label={label} placeholder={placeholder} {...props} />}
      />
      <ErrorMessage name={name} component={Error} />
    </InputWrapper>
  )
}

const Error = styled.span`
  color: red;
  font-size: 0.8em;
  padding: 0 0.3em;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 100%;
`

export default FieldForm
