import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { withTheme } from 'emotion-theming'

export default withTheme(props => {
  const {
    theme: { colors }
  } = props
  return (
    <Button colors={colors} onClick={props.onClick} {...props}>
      {props.isLoading ? <Loader src='/assets/icons/spinner.svg' /> : props.label}
    </Button>
  )
})

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const Loader = styled.img`
  animation: ${rotate} 2s linear infinite;
`

const Button = styled.button`
  ${({ colors, secondary, ghost, disabled }) => `
    border-radius: 4px;
    height: 38px;
    padding: 0 35px;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    background: white;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-size: 14px;
    outline: none;
    ${!disabled &&
      `
      :hover {
        background: ${secondary ? colors.blueKavod : ghost ? colors.gray : colors.darkBlue};
        color: ${secondary && colors.white};
      }
    `}
    ${disabled &&
      `
      color: ${colors.mediumGray};
      background: ${secondary || ghost ? colors.white : colors.gray};
      border: ${ghost ? colors.white : colors.mediumGray};`}
    ${!disabled &&
      `
      background: ${secondary || ghost ? colors.white : colors.blueKavod};
      border: 1px solid ${ghost ? colors.white : colors.blueKavod};
      color: ${secondary || ghost ? colors.blueKavod : colors.white};`}
 `}
`
