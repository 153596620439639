import { campaignStatuses } from '../constants'

export const getOldStatus = status => {
  switch (status) {
    case campaignStatuses.CLOSED:
    case campaignStatuses.PAYMENT_RECEIVED:
    case campaignStatuses.DISBURSED:
    case campaignStatuses.OLD_CCBS:
    case campaignStatuses.PROCESS_PLEDGES:
    case campaignStatuses.BMP_SIMULATOR:
    case campaignStatuses.SEND_PROPOSAL:
    case campaignStatuses.PROPOSAL_STATUS:
    case campaignStatuses.CCBS:
      return campaignStatuses.CLOSED
    case campaignStatuses.CLOSED_WITHOUT_CAPTATION:
      return campaignStatuses.CLOSED_WITHOUT_CAPTATION
    case campaignStatuses.WAITING_LIST:
      return campaignStatuses.WAITING_LIST
    case campaignStatuses.DELETED:
      return campaignStatuses.DELETED
    case campaignStatuses.ACTIVE:
    default:
      return campaignStatuses.ACTIVE
  }
}

export const getRunCampaignAbbreviation = campaignStatus => {
  switch (campaignStatus) {
    case campaignStatuses.BMP_SIMULATOR:
      return campaignStatuses.BMP_SIMULATOR

    case campaignStatuses.SEND_PROPOSAL:
      return campaignStatuses.SEND_PROPOSAL

    case campaignStatuses.PROPOSAL_STATUS:
      return campaignStatuses.PROPOSAL_STATUS

    case campaignStatuses.DISBURSED:
    case campaignStatuses.CCBS:
    case campaignStatuses.OLD_CCBS:
      return campaignStatuses.CCBS

    case campaignStatuses.PAYMENT_RECEIVED:
    case campaignStatuses.CLOSED:
    case campaignStatuses.PROCESS_PLEDGES:
    case campaignStatuses.CLOSED_WITHOUT_CAPTATION:
    case campaignStatuses.WAITING_LIST:
    case campaignStatuses.DELETED:
    case campaignStatuses.ACTIVE:
    default:
      return campaignStatuses.PROCESS_PLEDGES
  }
}
