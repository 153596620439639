import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

export const ticketDataSchema = yupResolver(
  yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().optional(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    second_email: yup
      .string()
      .email('Email inválido')
      .optional(),
    celphone: yup.string().optional(),
    link: yup.string().required()
  })
)
