import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Selected = props => {
  return (
    <SvgIcon width='16' height='12' viewBox='0 0 12 16' fill='none' {...props}>
      <path d='M5.99965 10.5043L2.52965 7.0343C2.13965 6.6443 1.50965 6.6443 1.11965 7.0343C0.729648 7.4243 0.729648 8.0543 1.11965 8.4443L5.29965 12.6243C5.68965 13.0143 6.31965 13.0143 6.70965 12.6243L17.2896 2.0443C17.6796 1.6543 17.6796 1.0243 17.2896 0.634297C16.8997 0.244297 16.2696 0.244297 15.8796 0.634297L5.99965 10.5043Z' fill='#378BEA'/>
    </SvgIcon>
  )
}

export { Selected }
