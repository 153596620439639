import React, { useEffect, useState } from 'react'

import { LoadingScreen, MaterialButton, CampaignStatus } from '../../components'

import {
  CampaignStatusContainer,
  Info,
  CampaignInfo,
  Subtitle,
  Title,
  CampaignActions,
  Header,
  CampaignName,
  Container
} from './style'

import { getCampaign } from '../../services'
import { formatMoney, sanitizeDate } from '../../helpers'
import { campaignStatuses } from '../../constants'

const CampaignPage = ({ match, history }) => {
  const [campaign, setCampaign] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  async function fetchCampaign() {
    const response = await getCampaign(match.params.id)
    setCampaign(response.data[0])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchCampaign()
  }, [])

  const getCampaignStatus = status => {
    switch (status) {
      case campaignStatuses.ACTIVE:
        return <Title color='#146801'>Em captação.</Title>
      case campaignStatuses.CLOSED:
        return <Title color='#076f9f'>Finalizada.</Title>
      case campaignStatuses.CLOSED_WITHOUT_CAPTATION:
        return <Title color='#680000'>Finalizada sem concluir captação.</Title>
      case campaignStatuses.WAITING_LIST:
        return <Title color='#f29d00'>Em lista de espera.</Title>
      default:
        return <Title>Indefinido.</Title>
    }
  }

  if (isLoading) return <LoadingScreen />

  return (
    <Container>
      <Header>
        <CampaignName>{campaign.name}</CampaignName>
        <MaterialButton label='Editar' disabled />
      </Header>
      <CampaignActions>
        <CampaignInfo>
          <Info>
            <Title>Meta de captação</Title>
            <Subtitle>R$ {formatMoney(campaign.amount)}</Subtitle>
          </Info>
          <Info>
            <Title>Data de início</Title>
            <Subtitle>{sanitizeDate(campaign.start_date)}</Subtitle>
          </Info>
          <Info>
            <Title>Status da campanha</Title>
            {getCampaignStatus(campaign.stt_id)}
          </Info>
        </CampaignInfo>
      </CampaignActions>
      <CampaignStatusContainer>
        <Title>Status</Title>
        <CampaignStatus campaign={campaign} />
      </CampaignStatusContainer>
    </Container>
  )
}

export default CampaignPage
