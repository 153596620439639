import React, { useState } from 'react'
import { MenuItem, Select, makeStyles } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'

import { formatCNPJ, formatValueToBrl, sanitizeDate, formatCurrencyToBack, copyCnpj } from '../../../helpers'
import { Text, Column, Row, NumberInput, IconButton, Select as SelectStyled, Autocomplete } from '../index'
import { Container, StyledAvatar, StyledText } from './styles'
import { updateLeads } from '../../../services/v3'
import { IconEdit, IconCheck, IconClear, IconCopy } from '../icons'

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      padding: '0px',
      backgroundColor: 'transparent',
      border: 'none',
      marginTop: 4
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
})

const Info = ({ state, responsibles, reload, partners }) => {
  const [responsible, setResponsible] = useState(state.responsible)
  const [isEditingAmount, setIsEditingAmount] = useState(false)
  const [isEditingOrigin, setIsEditingOrigin] = useState(false)
  const [isEditingIndicated, setIsEditingIndicated] = useState(false)

  const { control, handleSubmit } = useForm()

  const classes = useStyles()

  const handleChangeResponsible = async e => {
    try {
      const body = {
        responsible: e.target.value
      }
      await updateLeads(state.id, body)
      setResponsible(e.target.value)
    } catch (error) {
      toast.error('Ocorreu um erro ao mudar o responsável')
    }
  }

  const onSubmitAmount = async ({ lending_amount }) => {
    try {
      if (lending_amount) {
        const body = {
          lending_amount: formatCurrencyToBack(lending_amount)
        }
        state.lending_amount = formatCurrencyToBack(lending_amount)
        await updateLeads(state.id, body)
        toast.success('Valor atualizado com sucesso.')
      }
    } catch {
      toast.error('Erro ao atualizar o valor do empréstimo.')
    } finally {
      setIsEditingAmount(false)
    }
  }

  const onSubmitOrigin = async data => {
    try {
      const body = {
        ...data,
        ...(data.lead_origin_id === 3 && { indicated_by: '' })
      }
      await updateLeads(state.id, body)

      toast.success('Atualizado com sucesso.')
    } catch {
      toast.error('Erro ao atualizar.')
    } finally {
      setIsEditingOrigin(false)
      reload()
    }
  }

  const onSubmitPartner = async data => {
    try {
      const body = {
        partner_id: data.indicated_by.value,
        indicated_by: data.indicated_by.label
      }
      await updateLeads(state.id, body)
      toast.success('Atualizado com sucesso.')
    } catch {
      toast.error('Erro ao atualizar.')
    } finally {
      setIsEditingOrigin(false)
      reload()
    }
  }

  const selectData = [
    {
      label: 'Franqueador',
      value: 1
    },
    {
      label: 'Site',
      value: 2
    },
    {
      label: 'Parceiro',
      value: 3
    },
    {
      label: 'Kavod',
      value: 4
    }
  ]

  return (
    <Container>
      <Column mb={1}>
        <Text variant='caption' mb={0.5}>
          CNPJ
        </Text>
        <Row justifyContent='space-between'>
          <Text variant='h4'> {formatCNPJ(state.cnpj)}</Text>
          <IconButton
            icon={<IconCopy fontSize='small' />}
            variant='text'
            mr='15px'
            onClick={() => copyCnpj(state.cnpj)}
            style={{ position: 'relative', top: '-8px' }}
          />
        </Row>
      </Column>
      <Column mb={1}>
        <Text variant='caption' mb={0.5}>
          Data da solicitação
        </Text>
        <Text variant='h4'> {sanitizeDate(state.created_at)}</Text>
      </Column>
      <Column mb={1}>
        {isEditingAmount ? (
          <form onSubmit={handleSubmit(onSubmitAmount)}>
            <Row>
              <NumberInput
                label='Valor do empréstimo'
                name='lending_amount'
                control={control}
                maxWidth={200}
                autoFocus
                inputType='currency'
              />
              <Row>
                <IconButton icon={<IconCheck />} variant='text' type='submit' />
                <IconButton icon={<IconClear />} variant='text' onClick={() => setIsEditingAmount(false)} />
              </Row>
            </Row>
          </form>
        ) : (
          <Row justifyContent='space-between'>
            <Column>
              <Text variant='caption' mb={0.5}>
                Valor do empréstimo
              </Text>
              <Text variant='h4'> {formatValueToBrl(state.lending_amount)}</Text>
            </Column>
            <IconButton
              icon={<IconEdit fontSize='small' />}
              variant='text'
              onClick={() => setIsEditingAmount(true)}
              mr={2}
            />
          </Row>
        )}
      </Column>
      <Column>
        {isEditingOrigin ? (
          <form onSubmit={handleSubmit(onSubmitOrigin)}>
            <Row>
              <SelectStyled
                control={control}
                name='lead_origin_id'
                options={selectData}
                defaultValue={state.lead_origin.id}
                width={146}
              />
              <Row>
                <IconButton icon={<IconCheck />} variant='text' type='submit' />
                <IconButton icon={<IconClear />} variant='text' onClick={() => setIsEditingOrigin(false)} />
              </Row>
            </Row>
          </form>
        ) : (
          <Row justifyContent='space-between'>
            <Column>
              <Text variant='caption' mb={0.5}>
                {' '}
                Origem{' '}
              </Text>
              <Text variant='h4'> {state.lead_origin.name}</Text>
            </Column>
            <IconButton
              icon={<IconEdit fontSize='small' />}
              variant='text'
              onClick={() => setIsEditingOrigin(true)}
              mr={2}
            />
          </Row>
        )}
      </Column>
      {state.lead_origin.name === 'Parceiro' && (
        <Column>
          {isEditingIndicated ? (
            <form onSubmit={handleSubmit(onSubmitPartner)}>
              <Row>
                <Autocomplete
                  name='indicated_by'
                  control={control}
                  label='Indicado por'
                  options={partners}
                  width='75%'
                />
                <Row>
                  <IconButton icon={<IconCheck />} variant='text' type='submit' />
                  <IconButton icon={<IconClear />} variant='text' onClick={() => setIsEditingIndicated(false)} />
                </Row>
              </Row>
            </form>
          ) : (
            <Row justifyContent='space-between'>
              <Column>
                <Text variant='caption' mb={0.5}>
                  Indicado por
                </Text>
                <Text variant='h4'> {state.indicated_by}</Text>
              </Column>
              <IconButton
                icon={<IconEdit fontSize='small' />}
                variant='text'
                onClick={() => setIsEditingIndicated(true)}
                mr={2}
              />
            </Row>
          )}
        </Column>
      )}
      <Column mb={1}>
        <Text variant='caption'> Responsável </Text>
        <Select variant='outlined' value={responsible} onChange={handleChangeResponsible} className={classes.root}>
          {responsibles.map(({ name, avatar }) => (
            <MenuItem key={name} value={name}>
              <Row>
                <StyledAvatar sizes={'32px'} src={avatar} />
                <StyledText variant='body1'> {name}</StyledText>
              </Row>
            </MenuItem>
          ))}
        </Select>
      </Column>
    </Container>
  )
}

export default Info
