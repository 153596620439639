import client from '../providers/fetchClient'

export const fetchPersons = () => client.get('/v2/persons')
export const fetchPerson = kvdId => client.get(`/v2/persons/${kvdId}`)
export const fetchAddress = kvdId => client.get(`/address/kvd_id/${kvdId}?limit=1&exactmatch=true&stt_id=A`)
export const fetchBank = kvdId => client.get(`/esh-bank/kvd_id/${kvdId}?limit=1&exactmatch=true&std_id=A`)
export const fetchInvestor = kvdId => client.get(`/v2/investors/${kvdId}`)

export const updatePerson = (personId, data) => client.put(`/v2/persons/${personId}`, data)
export const updateAddress = data => client.put('/address/', data)
export const updateBank = data => client.put('/esh-bank/', data)
export const updateInvestor = (kvdId, data) => client.put(`/v2/investors/${kvdId}`, data)

export const checkCompleteProfile = kvdId => client.get(`/person-complete/kvd_id/${kvdId}?exactmatch=true`)
