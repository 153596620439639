export const colors = {
  blueKavod: '#076F9F',
  darkBlue: '#076cd5',
  blue: '#4bc5e6',
  red: '#e53b3b',
  darkRed: '#d33131',
  green: '#17ae29',
  darkGreen: '#0c951c',
  yellow: '#ffc300',
  darkYellow: '#ffb020',
  black: '#252525',
  darkGray: '#5d5d5d',
  mediumGray: '#7f7f7f',
  lightGray: '#c6c9cb',
  gray: '#f5f5f5',
  white: '#ffffff'
}
