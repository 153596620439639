import React, { useEffect, useState } from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'

import { LoadingComponent, Filter } from '../../components'
import { DatePickerMaterial } from '../../components/Pickers'

import {
  Button,
  ResetFilter,
  SearchContainer,
  SearchFieldContainer,
  KavodInfo,
  Row,
  Title,
  Text,
  CampaignsContainer,
  StyledReactTable,
  ReactTableContainer,
  TextTable,
  StyledLink,
  HeaderColumn,
  SearchCenter,
  SearchBody,
  DivExport
} from './style'

import { sanitizeDate, formatMoney, filterIntervalDates, formatDateInterval, formatCNPJ, copyCnpj } from '../../helpers'
import { getAllInfoCompaigns, exportCampaings } from '../../services/v3'

import { IconCopy } from '../../components/v2Components/icons'
import { IconButton } from '../../components/v2Components'
import { Option } from '../../routes/NewUserInvestments/components'

import './sticky.css'

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    className: 'sticky',
    headerClassName: 'sticky',
    minWidth: 50,
    Cell: props => <StyledLink to={`/campanha/${props.original.id}/pedidos`}>{props.original.id}</StyledLink>
  },
  {
    Header: 'Nome da campanha',
    accessor: 'name',
    minWidth: 320,
    Cell: props => <StyledLink to={`/campanha/${props.original.id}/pedidos`}>{props.original.name}</StyledLink>
  },
  {
    Header: 'CNPJ',
    accessor: 'company_cnpj',
    minWidth: 180,
    Cell: props => (
      <>
        <span>{formatCNPJ(props.original.company_cnpj) || 'Não cadastrado'}</span>
        {props.original.company_cnpj && (
          <IconButton
            icon={<IconCopy style={{ fontSize: 16 }} />}
            variant='text'
            onClick={() => {
              copyCnpj(props.original.company_cnpj)
            }}
          />
        )}
      </>
    )
  },
  {
    Header: () => (
      <HeaderColumn>
        <p>Venc.</p>
        <p>Tomador</p>
      </HeaderColumn>
    ),
    accessor: 'taker_expire_day',
    style: { textAlign: 'center' },
    minWidth: 100,
    Cell: props => (
      <StyledLink to={`/campanha/${props.original.id}/pedidos`}>{props.original.taker_expire_day}</StyledLink>
    )
  },
  {
    Header: () => (
      <HeaderColumn>
        <p>Parcelas</p>
        <p>em aberto</p>
      </HeaderColumn>
    ),
    accessor: 'open_installments',
    style: { textAlign: 'center' },
    minWidth: 100,
    Cell: props => (
      <StyledLink to={`/campanha/${props.original.id}/pedidos`}>
        {`${!!props.original.open_installments ? props.original.open_installments : '-'}`}
      </StyledLink>
    )
  },
  {
    Header: 'Taxa Mensal',
    accessor: 'rate_month',
    minWidth: 140,
    Cell: props => (
      <StyledLink to={`/campanha/${props.original.id}/pedidos`}>
        <TextTable>{formatMoney(props.original.rate_month)}%</TextTable>
      </StyledLink>
    )
  },
  {
    Header: 'Total captado',
    accessor: 'total_captured',
    minWidth: 160,
    Cell: props => (
      <StyledLink to={`/campanha/${props.original.id}/pedidos`}>
        <p>R$ {formatMoney(props.original.total_captured)}</p>
      </StyledLink>
    )
  },
  {
    Header: () => (
      <HeaderColumn>
        <p>N° de</p>
        <p>Investidores</p>
      </HeaderColumn>
    ),
    accessor: 'qtd_investors',
    style: { textAlign: 'center' },
    minWidth: 95,
    Cell: props => <StyledLink to={`/campanha/${props.original.id}/pedidos`}>{props.original.qtd_investors}</StyledLink>
  },
  {
    Header: 'Ticket Médio',
    accessor: 'ticket_medio',
    minWidth: 140,
    Cell: props => (
      <StyledLink to={`/campanha/${props.original.id}/pedidos`}>
        <p>R$ {formatMoney(props.original.ticket_medio)}</p>
      </StyledLink>
    )
  },
  {
    Header: 'Data de início',
    accessor: 'start_date',
    minWidth: 140,
    Cell: props => (
      <StyledLink to={`/campanha/${props.original.id}/pedidos`}>
        <TextTable>{sanitizeDate(props.original.start_date)}</TextTable>{' '}
      </StyledLink>
    )
  }
]

const Campaigns = ({ history }) => {
  const [campaigns, setCampaigns] = useState([])
  const [unfilteredCampaigns, setUnfilteredCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilter] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [totalOfCampaigns, setTotalOfCampaigns] = useState({})

  const handleStartDate = date => {
    setStartDate(date)
    setEndDate(endDate ? endDate : new Date())
  }

  const handleEndDate = date => {
    setEndDate(date)
  }

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const {
          data: { campaigns, total_campaigns_captured, total_campaigns_request }
        } = await getAllInfoCompaigns({
          order_by: 'id',
          sort: 'desc'
        })

        setCampaigns(campaigns)
        setUnfilteredCampaigns(campaigns)
        setTotalOfCampaigns({
          campaignsLength: campaigns.length,
          totalCampaignsCaptured: total_campaigns_captured,
          totalCampaignsRequest: total_campaigns_request
        })
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar as campanhas')
      } finally {
        setIsLoading(false)
      }
    }

    fetchCampaigns()
  }, [])

  const normalizeText = text =>
    text
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  const filterCampaigns = e => {
    e.preventDefault()

    const filteredByName = unfilteredCampaigns.filter(campaign =>
      normalizeText(campaign.name).includes(normalizeText(filter))
    )

    if (!!startDate && !!endDate) {
      return setCampaigns(
        filteredByName.filter(
          campaign =>
            !!filterIntervalDates(
              formatDateInterval(campaign.start_date),
              formatDateInterval(startDate),
              formatDateInterval(endDate)
            )
        )
      )
    }
    return setCampaigns(filteredByName)
  }

  const resetFilters = () => {
    setCampaigns(unfilteredCampaigns)
    setFilter('')
    setStartDate(null)
    setEndDate(null)
  }

  const exportAllCampaings = async () => {
    try {
      const { data: response } = await exportCampaings()
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = 'Campanhas.xlsx'
      element.click()
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao exportar a lista de campanhas.')
    }
  }

  if (isLoading) return <LoadingComponent />

  return (
    <CampaignsContainer>
      <SearchCenter>
        <SearchBody>
          <SearchContainer>
            <SearchFieldContainer>
              <Filter value={filter} onChange={e => setFilter(e.target.value)} />
            </SearchFieldContainer>
            <SearchFieldContainer>
              <DatePickerMaterial
                value={startDate}
                onChange={handleStartDate}
                placeholder='Data de Inicio'
                inputVariant='outlined'
              />
            </SearchFieldContainer>
            <SearchFieldContainer>
              <DatePickerMaterial
                value={endDate}
                onChange={handleEndDate}
                placeholder='Data Final'
                inputVariant='outlined'
              />
            </SearchFieldContainer>
            <Button type='submit' onClick={e => filterCampaigns(e)}>
              Buscar
            </Button>
            <ResetFilter onClick={resetFilters}>Resetar filtros</ResetFilter>
          </SearchContainer>
          <DivExport>
            <Option
              type='button'
              name='Exportar'
              imgUrl='/assets/images/export.svg'
              title='Exportar'
              onPress={() => exportAllCampaings()}
            />
          </DivExport>
        </SearchBody>
      </SearchCenter>
      <KavodInfo>
        <Row>
          <Title>Total de campanhas: </Title>
          <Text> {totalOfCampaigns.campaignsLength}</Text>
        </Row>
        <Row>
          <Title>Valor total solicitado: </Title>
          <Text> R$ {formatMoney(totalOfCampaigns.totalCampaignsRequest)}</Text>
        </Row>
        <Row>
          <Title>Valor total captado </Title>
          <Text> R$ {formatMoney(totalOfCampaigns.totalCampaignsCaptured)}</Text>
        </Row>
      </KavodInfo>
      <ReactTableContainer>
        <StyledReactTable
          className='ReactTableDefault'
          data={campaigns}
          columns={columns}
          showPaginationTop={true}
          defaultPageSize={50}
          getTdProps={() => ({
            style: {
              display: 'flex',
              alignItems: 'center'
            }
          })}
        />
      </ReactTableContainer>
      <ReactTooltip />
    </CampaignsContainer>
  )
}

export default withRouter(withTheme(Campaigns))
