import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

import { updatePartner } from '../../services/v3'
import { PartnerContext } from '../../contexts'

const useStyles = makeStyles({
  root: {
    width: 80,
    '& .MuiSelect-root': {
      padding: '0px',
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiSelect-icon': {
      color: '#424242'
    }
  }
})

const SelectStatusPartner = ({ partner }) => {
  const [status, setStatus] = useState('')

  const { partnerStatuses, refresh } = useContext(PartnerContext)

  const classes = useStyles()

  useEffect(() => {
    if (partner.partner_status) {
      setStatus(partner.partner_status.id)
    }
  }, [partner])

  const handleChangeStatus = async e => {
    try {
      const body = {
        partner_status_id: e.target.value
      }
      await updatePartner(partner.id, body)
      setStatus(e.target.value)
      refresh()
    } catch (error) {
      toast.error('Ocorreu um erro ao mudar o status')
    }
  }

  return (
    <StatusContainer>
      <Select
        className={classes.root}
        value={status}
        onChange={handleChangeStatus}
        variant='outlined'
        IconComponent={ExpandMoreIcon}
      >
        {partnerStatuses.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Text>{name}</Text>
          </MenuItem>
        ))}
      </Select>
    </StatusContainer>
  )
}

const Text = styled.span`
  color: #076f9f;
  font-size: 14px;
  font-weight: 500;
`

const StatusContainer = styled.div`
  display: flex;
  position: relative;
`

export default SelectStatusPartner
