import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

const defaultStyle = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
    zIndex: 12,
    width: '100%',
    height: '100%'
  },
  content: {
    display: 'flex',
    position: 'initial',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid transparent',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none'
  }
}

const ModalComponent = ({
  children,
  isOpen,
  setIsOpen,
  closeOnEsc = true,
  ariaHideApp = false,
  onRequestClose = () => {},
  style
}) => (
  <ReactModal
    isOpen={isOpen}
    style={style ? style : defaultStyle}
    onRequestClose={() => {
      onRequestClose()
      setIsOpen(false)
    }}
    shouldCloseOnOverlayClick={closeOnEsc}
    shouldCloseOnEsc={closeOnEsc}
    ariaHideApp={ariaHideApp}
  >
    {children}
  </ReactModal>
)

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  closeOnEsc: PropTypes.bool,
  ariaHideApp: PropTypes.bool
}

export default ModalComponent
