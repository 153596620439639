import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import { MenuItem as Menu } from '@material-ui/core'

export const StyledSelect = styled(Select)`
  &&.MuiInputBase-root {
    width: 250px;
  }
`

export const Status = styled.div`
  width: 220px;
  height: 25px;
  border-radius: 15px;
  color: ${({ fontColor }) => fontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  white-space: normal;
  text-align: center;
  line-height: initial;
  background-color: ${({ bgColor }) => bgColor};
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor};
`

export const StatusContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`
export const MenuItem = styled(Menu)`
  &&.MuiListItem-root.Mui-disabled {
    display: none;
  }
`
