import ReactTable from 'react-table-6'
import styled from '@emotion/styled'

export const PersonsContainer = styled.div`
  margin: 140px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const StyledReactTable = styled(ReactTable)`
  width: 80vw;
`

export const ReactTableContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const SearchContainer = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 16px;
`
export const DatesContainer = styled.div`
  display: flex;
  padding: 0 1em;
  justify-content: space-between;
`
export const ResetFilter = styled.p`
  font-size: 1em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blueKavod};
  &:hover {
    text-decoration: underline;
  }
`
export const Button = styled.button`
  height: 30px;
  cursor: pointer;
  padding: 0 1.3em;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blueKavod};
  margin: 0 1em;
`
