import styled from 'styled-components'
import { Accordion, AccordionDetails, Tooltip as Tool } from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'
import { withStyles } from '@material-ui/core/styles'
import { IconLaunch, IconMail } from '../icons'
import { Column, Text } from '../index'

export const StyledAccordion = styled(Accordion)`
  &&.MuiAccordion-root {
    ${palette};
    ${spacing};
    ${typography};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};
    border-bottom: 1px solid #e0e0e0;
    &&.Mui-expanded {
      margin: 0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  &&.MuiAccordionDetails-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px 0px;
  }
`

export const AccordionDetailsDocuments = styled(AccordionDetails)`
  &&.MuiAccordionDetails-root {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px 0px;
  }
`

export const StyledAccordionDetailsFranchise = styled(AccordionDetails)`
  &&.MuiAccordionDetails-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 12px 0px;
    grid-template-areas:
      'A B'
      'C C'
      'C C'
      'C C';
  }
`

export const CompanyData = styled(AccordionDetails)`
  &&.MuiAccordionDetails-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 0px;
    grid-template-areas:
      'type type'
      'CNPJ CNPJ'
      'fantasyName fantasyName'
      'site site'
      'address address'
      'state state'
      'FMM Value'
      'historic historic'
      'historic historic'
      'historic historic'
      'reason reason';
    &&.Mui-expanded {
      margin: 0px;
    }
  }
`

export const StyledColumn = styled(Column)`
  box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);

  @media (min-width: 768px) {
    width: 50vw;
  }
`

export const TextScroll = styled(Text)`
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    width: 4px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 20px;
  }
`

export const Label = styled(Text)`
  &&.MuiTypography-root {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.gray[800]};
  }
`

export const StyledLaunchIcon = styled(IconLaunch)`
  &&.MuiSvgIcon-root {
    margin-bottom: 3px;
    color: #757575;
    cursor: pointer;
    font-size: 16px;
  }
`

export const StyledEmailIcon = styled(IconMail)`
  color: #757575;
  cursor: pointer;
  font-size: 16px;
`
export const Tooltip = withStyles({
  tooltip: {
    color: '#076F9F',
    backgroundColor: 'white',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #076F9F'
  }
})(Tool)
