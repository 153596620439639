import React from 'react'
import { Content } from './styled'
import PropTypes from 'prop-types'

const DeleteCampaignIcon = ({ isDelete, onClick }) => {
  return (
    <Content>
      {isDelete && (
        <button onClick={onClick}>
          <img src='/assets/images/deletecampaing.svg' alt='Deletar' />
        </button>
      )}
    </Content>
  )
}

DeleteCampaignIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDelete: PropTypes.bool.isRequired
}

export default DeleteCampaignIcon
