import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Input, Column, Row, NumberInput, Button } from '../../../../components/v2Components'

import { Modal } from './style'

import useDevice from '../../../../hooks/useDevice'
import { ticketDataSchema } from '../../../../schemas'
import { createTicket, updateCampaign, updateTicket } from '../../../../services/v3'

const ModalTicketData = ({ open, onClose, reload, campaign }) => {
  const [newTicket, setNewTicket] = useState(true)
  const [ticket, setTicket] = useState({})

  const { control, handleSubmit, reset, errors } = useForm({
    mode: 'onBlur',
    resolver: ticketDataSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      second_email: '',
      celphone: '',
      link: ''
    }
  })
  const { isMobile } = useDevice()

  const onSubmit = useCallback(
    async data => {
      try {
        if (newTicket) {
          const body = {
            first_name: data.first_name,
            last_name: !!data.last_name ? data.last_name : null,
            email: data.email,
            second_email: !!data.second_email ? data.second_email : null,
            cellphone: !!data.celphone ? data.celphone.replace(/([^\d])+/gim, '').slice(2) : '',
            cellphone_prefix: !!data.celphone ? data.celphone.replace(/([^\d])+/gim, '').slice(0, 2) : '',
            campaign_id: campaign.id
          }

          await createTicket(body)
        } else {
          const body = {
            first_name: data.first_name,
            last_name: !!data.last_name ? data.last_name : null,
            email: data.email,
            second_email: !!data.second_email ? data.second_email : null,
            cellphone: !!data.celphone ? data.celphone.replace(/([^\d])+/gim, '').slice(2) : '',
            cellphone_prefix: !!data.celphone ? data.celphone.replace(/([^\d])+/gim, '').slice(0, 2) : ''
          }

          await updateTicket(ticket.id, body)
        }

        await updateCampaign(campaign.id, { link: data.link })

        reload()
        onClose()
        toast.success('Dados do boleto salvos.')
      } catch {
        toast.error('Erro ao salvar dados do boleto.')
      }
    },
    [ticket, newTicket, campaign]
  )

  useEffect(() => {
    const getData = async () => {
      if (!!campaign.campaign_contacts) {
        setNewTicket(false)
        setTicket(campaign.campaign_contacts)

        reset({
          first_name: campaign.campaign_contacts.first_name,
          last_name: !!campaign.campaign_contacts.last_name ? campaign.campaign_contacts.last_name : '',
          email: campaign.campaign_contacts.email,
          second_email: !!campaign.campaign_contacts.second_email ? campaign.campaign_contacts.second_email : '',
          celphone: campaign.campaign_contacts.cellphone_prefix + campaign.campaign_contacts.cellphone,
          link: !!campaign.link ? campaign.link : ''
        })
      } else {
        setNewTicket(true)
      }
    }
    getData()
  }, [campaign, open])

  if (isMobile) {
    return (
      <Modal title='Dados do Boleto' iconClose width={300} height={600} open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
          <Column>
            <Input
              control={control}
              name='first_name'
              mt={3}
              mb={3}
              label='Nome'
              placeholder='Nome'
              width='95%'
              error={errors.first_name}
            />
            <Input
              control={control}
              name='last_name'
              label='Sobrenome'
              placeholder='Sobrenome'
              width='95%'
              mb={3}
              error={errors.last_name}
            />

            <Input
              control={control}
              name='email'
              label='E-mail'
              placeholder='E-mail'
              width='95%'
              mb={3}
              error={errors.email}
            />
            <Input
              control={control}
              name='second_email'
              label='2º E-mail (opcional)'
              placeholder='2º E-mail (opcional)'
              width='95%'
              mb={3}
              error={errors.second_email}
            />

            <NumberInput
              control={control}
              name='celphone'
              label='Telefone'
              inputType='celphone'
              width='95%'
              mb={3}
              error={errors.celphone}
            />
            <Input
              control={control}
              name='link'
              label='Cole aqui o link do boleto'
              placeholder='Cole aqui o link do boleto'
              width='95%'
              multiline
              rows={5}
              mb={3}
              error={errors.link}
            />
            <Row mb={3}>
              <Button width='45%' variant='outlined' ml={1} mr={4} onClick={onClose}>
                Cancelar
              </Button>
              <Button width='45%' type='submit'>
                Enviar
              </Button>
            </Row>
          </Column>
        </form>
      </Modal>
    )
  }

  return (
    <Modal title='Dados do Boleto' iconClose width={526} height={560} open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
        <Column>
          <Row justifyContent='space-around' mb={5} style={{ gap: '24px' }}>
            <Input
              variant='outlined'
              control={control}
              name='first_name'
              label='Nome'
              placeholder='Nome'
              width='50%'
              error={errors.first_name}
            />
            <Input
              control={control}
              name='last_name'
              label='Sobrenome'
              placeholder='Sobrenome'
              width='50%'
              error={errors.last_name}
            />
          </Row>
          <Row justifyContent='space-around' mb={5}>
            <Input
              control={control}
              name='email'
              label='E-mail'
              placeholder='E-mail'
              width='100%'
              error={errors.email}
            />
          </Row>
          <Row justifyContent='space-around' mb={5}>
            <Input
              control={control}
              name='second_email'
              label='2º E-mail (opcional)'
              placeholder='2º E-mail (opcional)'
              width='100%'
              error={errors.second_email}
            />
          </Row>

          <Row mb={5}>
            <NumberInput
              control={control}
              name='celphone'
              label='Telefone'
              inputType='celphone'
              width='34%'
              error={errors.celphone}
            />
          </Row>

          <Row justifyContent='space-around' mb={5}>
            <Input
              control={control}
              name='link'
              label='Cole aqui o link do boleto'
              placeholder='Cole aqui o link do boleto'
              width='100%'
              multiline
              rows={3}
              error={errors.link}
            />
          </Row>

          <Row style={{ gap: '24px' }}>
            <Button width='50%' variant='outlined' ml={1} mr={4} onClick={onClose}>
              Cancelar
            </Button>
            <Button width='50%' type='submit'>
              Enviar
            </Button>
          </Row>
        </Column>
      </form>
    </Modal>
  )
}

export default ModalTicketData
