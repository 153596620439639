import client from '../../providers/fetchClient'

export const getSignerByUserId = user_id => client.get(`/v3/signers?user_id=${user_id}`)

export const getCampaignSigners = campaign_id => client.get(`/v3/signers?campaign_id=${campaign_id}`)

export const getSigners = () => client.get('/v3/signers')

export const getSignerById = id => client.get(`/v3/signers/${id}`)

export const createSigner = data => client.post('/v3/signers', data)

export const updateSigner = (id, data) => client.put(`/v3/signers/${id}`, data)

export const getSignerStatuses = () => client.get('/v3/signer-statuses')

export const getEletronicSignatures = params => client.get('/v3/electronic-signatures', { params })
