import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import { MaterialButton } from '../../../components'

import { getDarfs, deleteDarfs } from '../../../services/v3'

const ModalDarfDelete = ({ isOpen, setIsOpen, campaignId, installmentNumber, reload }) => {
  const [submitting, setSubmitting] = useState(false)

  const handleDeleteDarfs = async () => {
    try {
      setSubmitting(true)
      const { data: requestDarfs } = await getDarfs(campaignId, installmentNumber)
      await deleteDarfs(requestDarfs.map(darf => `darf_ids=${darf.id}`).join('&'))
      toast.success('DARFs excluidos com sucesso.')
      reload()
      setIsOpen(false)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao deletar os DARFs.')
      setSubmitting(false)
    }
  }

  return (
    <ModalComponent
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      closeModal={() => setIsOpen(false)}
    >
      <ModalDarfContent>
        <ModalDescripion>Deseja realmente excluir os DARFs relacionados a parcela {installmentNumber}?</ModalDescripion>
        <ButtonsContent>
          <MaterialButton width='230px' label='Cancelar' backgroundColor='red' onClick={() => setIsOpen(false)} />
          <MaterialButton width='230px' label='Confimar' onClick={handleDeleteDarfs} loading={submitting} />
        </ButtonsContent>
      </ModalDarfContent>
    </ModalComponent>
  )
}

const ModalDescripion = styled.p`
  padding: 1em 0;
  font-weight: 500;
`

const ModalContent = styled.div`
  display: flex;
  width: 700px;
  border-radius: 4px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`

const ModalDarfContent = styled(ModalContent)`
  height: auto;
  width: auto;
  padding: 30px;
`

const ModalComponent = styled(Modal)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonsContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export default ModalDarfDelete
