import React, { useMemo, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'

import { getLowerValue } from '../../helpers'

const parsePage = (command, page = 1) => {
  switch (command) {
    case 'next':
      return parseInt(page) + 1
    case 'prev':
      return parseInt(page) - 1
    default:
      return command
  }
}

const Pagination = ({ pagination, search, isLoading, maxPageNumber = 10 }) => {
  const currentPage = useMemo(() => pagination.offset / pagination.limit + 1, [pagination.limit, pagination.offset])

  const numberOfPages = useMemo(() => getLowerValue(maxPageNumber, pagination.pageCount), [
    maxPageNumber,
    pagination.pageCount
  ])

  const changePage = command => {
    search({
      page: parsePage(command, currentPage)
    })
  }

  const getPages = useCallback(
    state => {
      const middle = Math.trunc(numberOfPages / 2)
      const prevLength = getLowerValue(middle, currentPage - 1)
      const nextLength = getLowerValue(middle, pagination.pageCount - currentPage)
      if (state === 'prev') {
        return getLowerValue(middle + middle - nextLength, currentPage - 1)
      }
      return getLowerValue(middle + middle - prevLength, pagination.pageCount - currentPage)
    },
    [currentPage, pagination.pageCount, numberOfPages]
  )

  const renderPage = useCallback(
    page => (
      <PaginationButton
        variant='regular'
        as='button'
        isSelected={currentPage === page}
        key={page}
        disabled={currentPage === page}
        isLoading={isLoading}
        onClick={() => changePage(page)}
      >
        {page}
      </PaginationButton>
    ),
    [currentPage, changePage, isLoading]
  )

  const renderPages = useMemo(() => {
    const prevLength = getPages('prev')
    const nextLength = getPages('next')

    return (
      currentPage && (
        <Fragment>
          {Array.from({ length: prevLength }).map((_, index) => renderPage(currentPage - (prevLength - index)))}
          {renderPage(currentPage)}
          {Array.from({ length: nextLength }).map((_, index) => renderPage(currentPage + index + 1))}
        </Fragment>
      )
    )
  }, [getPages, renderPage, currentPage])

  return (
    <WrapperPagination isLoading={isLoading} justifyContent='center'>
      <Control disabled={currentPage === 1 || isLoading} onClick={() => changePage('prev')}>
        <ArrowBackIosRoundedIcon style={{ width: '12px', height: '12px', color: '#616161' }} />
      </Control>
      {renderPages}
      <Control disabled={currentPage === pagination.pageCount || isLoading} onClick={() => changePage('next')}>
        <ArrowForwardIosRoundedIcon style={{ width: '12px', height: '12px', color: '#616161' }} />
      </Control>
    </WrapperPagination>
  )
}

const WrapperPagination = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
  margin: 24px 0px;
`

const PaginationButton = styled.span`
  outline: none;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  margin: 0 5px;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  ${({ isSelected, disabled, isLoading }) => `
    background: ${isSelected ? '#076F9F' : 'none'};
    color: ${isSelected ? '#ffffff' : '#424242'};
    opacity: ${disabled && !isSelected ? '0.5' : '1'};
    &:disabled {
      cursor: ${isSelected || isLoading ? 'pointer' : 'not-allowed'};
    }
    &:hover {
      background: ${!isSelected ? '#076F9F15' : '#076F9F'};
    }
  `}

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

const Control = styled.button`
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    margin-right: 8px;
  }
  &:last-of-type {
    margin-left: 8px;
  }
  ${({ disabled, isLoading }) => `
      cursor: ${disabled || isLoading ? 'not-allowed' : 'pointer'};
  `}
`

export default Pagination
