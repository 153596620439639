import styled from '@emotion/styled'

export const CampaignsContainer = styled.div`
  margin: 140px 0;
  display: flex;
  justify-content: center;
`

export const CampaignsContent = styled.div`
  width: 80%;
`
