import React from 'react'
import { Modal } from '@material-ui/core'
import styled from '@emotion/styled'

import { Button as But } from '../../components/v2Components'

const ModalRunInfo = ({ open, onClose }) => {
  return (
    <ModalWrapper open={!!open}>
      <ModalContainer>
        <h3>Atenção!</h3>
        <h6>Existem pedidos confirmados sem confirmação de TED/Pix recebido.</h6>
        <p>Favor rivisar antes de rodar a campanha novamente.</p>
        <DivPledges>
          <h6>{`Pedidos confirmados: ${open.numberAcceptedPledges} | R$ ${open.valueAcceptedPledges}`}</h6>
          <h6> {`TED/Pix: ${open.numberTedAcceptedPledges} | R$ ${open.tedValueAcceptedPledges}`}</h6>
        </DivPledges>
        <Button variant='outlined' color='#ea4742' onClick={onClose}>
          Revisar pedidos
        </Button>
      </ModalContainer>
    </ModalWrapper>
  )
}

const DivPledges = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
`

const ModalWrapper = styled(Modal)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
`
const ModalContainer = styled.div`
  width: 700px;
  max-height: 80%;
  padding: 1em 2em;
  background-color: #fff;
  position: relative;
  outline: none;
  display: block;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  h3 {
    color: #d9534f;
    font-size: 30px;
  }

  h6 {
    font-size: 18px;
    font-weight: normal;
  }

  p {
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 500px) {
    height: 100%;
    width: 100%;
  }
`
const Button = styled(But)`
  &.MuiButton-outlined {
    border: 3px solid #ea4742;
    padding: 5px 15px;
  }
`

export default ModalRunInfo
