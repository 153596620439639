import React, { useState, Fragment } from 'react'
import ReactTable from 'react-table-6'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { MaterialButton } from '../../components'
import { renegotiationTypes } from '../../constants'
import { simulationColumns } from './simulationColumns'
import { renegotiatePrice, renegotiateIrregular } from '../../services/v3'
import { formatValueToBrl } from '../../helpers'

import { Title, ButtonContainer } from './style'

const renegotiationFetchFunctions = {
  [renegotiationTypes.IRREGULAR]: renegotiateIrregular,
  [renegotiationTypes.PRICE]: renegotiatePrice
}

const SimulationTable = ({ simulation: { type, body, new_installments, new_balance }, cleanForm, campaignId }) => {
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setSubmitting(true)
      await renegotiationFetchFunctions[type]({ ...body, campaign_id: campaignId, is_simulation: false })
      toast.success('Renegociação salva com sucesso')
      cleanForm()
    } catch (error) {
      toast.error('Ocorreu um erro ao salvar a renegociação, por favor tente novamente')
      setSubmitting(false)
    }
  }

  const handleExport = async e => {
    try {
      e.preventDefault()
      setSubmitting(true)
      const { data: simulationSheet } = await renegotiationFetchFunctions[type](
        { ...body, campaign_id: campaignId },
        { params: { export_as_csv: true }, responseType: 'arraybuffer' }
      )
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([simulationSheet], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = `simulação_renegociação_${campaignId}.xlsx`
      element.click()
    } catch (error) {
      toast.error('Ocorreu um erro ao gerar o excel da simulação, por favor tente novamente')
    } finally {
      setSubmitting(false)
    }
  }

  const generateSumRow = () => {
    if (new_installments.length === 0) return []
    const sum = new_installments.reduce((acc, { amount }) => acc + amount, 0)
    return new_installments.concat({ installment_number: 'Total', amount: sum })
  }

  const tableData = generateSumRow()

  return (
    <Fragment>
      <Title>Novas parcelas do Tomador</Title>
      <NewBalanceContainer>
        <p>Novo saldo para o cálculo: {formatValueToBrl(new_balance)}</p>
      </NewBalanceContainer>
      <StyledReactTable
        className='ReactTableDefault'
        data={tableData}
        columns={simulationColumns}
        showPagination={false}
        pageSize={tableData.length}
        minRows={0}
      />
      <ButtonContainer>
        <MaterialButton loading={submitting} onClick={handleSubmit} label='Salvar' margin='10px' width='220px' />
        <MaterialButton loading={submitting} onClick={handleExport} label='Exportar' margin='10px' width='220px' />
      </ButtonContainer>
    </Fragment>
  )
}

const StyledReactTable = styled(ReactTable)`
  margin: 20px 10px;
  max-width: 820px;
`

const NewBalanceContainer = styled.div`
  margin: 10px;
`

export default SimulationTable
