import React from 'react'
import { Modal, ModalContent } from '../BankCreditCertificatesModal'
import styled from '@emotion/styled'

const ConfirmUploadModal = ({ handleConfirmUploadModal, fileName, isOpen }) => {
  return (
    isOpen && (
      <Modal>
        <ModalContent>
          <Title>
            Deseja confirmar o upload do arquivo <FileName>{fileName} </FileName>?{' '}
          </Title>
          <ButtonContainer>
            <button onClick={handleConfirmUploadModal} type='submit' form='form1'>
              Nice
            </button>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    )
  )
}

const FileName = styled.span`
  font-weight: bold;
`

const Title = styled.p`
  font-size: 1.5em;
  padding: 1em;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.darkBlue};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 769px) {
    flex-direction: column;
  }
  button {
    margin: 1em;
  }
`

export default ConfirmUploadModal
