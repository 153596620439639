import React, { useEffect, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import moment from 'moment-timezone'

import { LoadingComponent, MaterialButton } from '../../components'
import { Radio } from '../../components/v2Components'
import { ExecutionFlag } from '../NewUserInvestments/Show/components'

import { Body, CampaignName, List } from './style'

import {
  getCampaignById,
  getLinkToDownloadCompliance,
  getInvestorsQualifications,
  updateCampaign,
  getDownloadInstallmentsExecution
} from '../../services/v3'

const JudicialExecution = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isDownloadingTerms, setIsDownloadingTerms] = useState(false)
  const [isInvestorQualification, setInvestorQualification] = useState(false)
  const [isInstallmentsExecution, setIsInstallmentsExecution] = useState(false)
  const [campaign, setCampaign] = useState(null)
  const [error, setError] = useState(null)

  const { control, reset } = useForm()

  const handleRadio = useCallback(
    async e => {
      await updateCampaign(match.params.id, { campaign_state_id: e.target.value })

      toast.success('Campanha atualizada.')
      fetchInfos()
    },
    [match]
  )

  const handleDownloadCompliance = useCallback(async () => {
    try {
      setIsDownloadingTerms(true)
      const { data: response } = await getLinkToDownloadCompliance(match.params.id)
      if (!!response.zipped_investors_compliance_agreement) {
        const zipFile = new Blob([new Uint8Array(response.zipped_investors_compliance_agreement.data).buffer])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(new Blob([zipFile], { type: 'text/plain' }))
        element.download = `Termos_campaign_${match.params.id}.zip`
        document.body.appendChild(element)
        element.click()
      }

      setError(response.investors_without_compliance_agreement)
    } catch (error) {
      console.error(error.message)
      toast.error('Erro ao encontrar os termos.')
    } finally {
      setIsDownloadingTerms(false)
    }
  }, [match])

  const handleDownloadInvestorQualification = useCallback(async () => {
    try {
      setInvestorQualification(true)
      const { data: response } = await getInvestorsQualifications(match.params.id)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = 'Qualificação_investidores.xlsx'
      element.click()
    } catch (error) {
      toast.error('Erro ao encontrar a qualificação dos investidores.')
    } finally {
      setInvestorQualification(false)
    }
  }, [match])

  const handleDownloadInstallmentsExecution = useCallback(async () => {
    try {
      setIsInstallmentsExecution(true)

      const { data: response } = await getDownloadInstallmentsExecution(
        match.params.id,
        moment(new Date()).format('YYYY-MM-DD')
      )
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = `Parcelas-execução-${campaign.name}.xlsx`
      element.click()
    } catch (error) {
      toast.error('Erro ao encontrar parcelas para execução.')
    } finally {
      setIsInstallmentsExecution(false)
    }
  }, [match, campaign])

  const fetchInfos = useCallback(async () => {
    try {
      const { data: campaign } = await getCampaignById(match.params.id)

      setCampaign(campaign)
    } catch {
      toast.error('Erro ao buscar campanha.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchInfos()
  }, [])

  useEffect(() => {
    if (!!campaign && !!campaign.campaign_state_id) {
      reset({ execution_type: campaign.campaign_state_id })
    }
  }, [campaign])

  if (isLoading) return <LoadingComponent />

  return (
    <Body>
      <CampaignName>
        {campaign.name}
        {campaign.campaign_state_id === 2 || campaign.campaign_state_id === 3 ? (
          <ExecutionFlag title={campaign.campaign_states.name} />
        ) : (
          ''
        )}
      </CampaignName>
      <h3>Status da campanha</h3>
      <Radio
        name='execution_type'
        mt={5}
        control={control}
        onChange={handleRadio}
        options={[
          {
            value: 1,
            label: 'Padrão'
          },
          {
            value: 2,
            label: 'Em execução'
          },
          {
            value: 3,
            label: 'Acordo judicial'
          }
        ]}
      />
      <h3>Downloads</h3>
      <MaterialButton
        onClick={handleDownloadCompliance}
        backgroundColor='#076F9F'
        padding='0.5em 2.2em'
        color='#ffffff'
        width='250px'
        variant='contained'
        label='Termos do investidor em lote'
        loading={isDownloadingTerms}
      />
      <MaterialButton
        onClick={handleDownloadInvestorQualification}
        backgroundColor='#076F9F'
        color='#ffffff'
        width='250px'
        variant='contained'
        label='Qualificação investidores'
        loading={isInvestorQualification}
      />
      <MaterialButton
        onClick={handleDownloadInstallmentsExecution}
        backgroundColor='#076F9F'
        color='#ffffff'
        width='250px'
        variant='contained'
        label='Parcelas para execução'
        loading={isInstallmentsExecution}
      />
      {!!error && (
        <>
          <h3>Investidores que não assinaram</h3>
          <List>
            {error.map(item => (
              <li>{item}</li>
            ))}
          </List>
        </>
      )}
    </Body>
  )
}

export default JudicialExecution
