import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox as MaterialCheckbox } from '@material-ui/core'

const StyledCheckbox = withStyles({
  root: {
    color: '#076F9F',
    '&$checked': {
      color: '#076F9F'
    }
  },
  checked: {}
})(props => <MaterialCheckbox color='default' {...props} />)

const Checkbox = props => <StyledCheckbox {...props} />

export default Checkbox
