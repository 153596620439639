import styled from 'styled-components'
import { Column as Col } from '../../../../components/v2Components'

export const Column = styled(Col)`
  padding-top: 120px;

  @media (max-width: 500px) {
    padding-top: 160px;
  }
`

export const Main = styled.main`
  padding: 20px;
  width: 100%;
  max-width: 1800px;

  @media only screen and (max-width: 730px) {
    padding: 16px;
  }
`
