import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Field } from 'formik'
import { DatePicker } from 'material-ui-pickers'
import { Input } from '../'
import moment from 'moment-timezone'

const DatePickerFormik = ({ name, label, values, setFieldValue, customChange }) => {
  const pickerRef = useRef(null)
  const [pickerOpen, setPickerOpen] = useState(false)
  const handleFocus = () => {
    if (pickerOpen) {
      pickerRef.current.close()
    } else {
      pickerRef.current.open()
    }
    setPickerOpen(!pickerOpen)
  }

  return (
    <InputWrapper>
      <Field
        name={name}
        render={() => (
          <label htmlFor={name}>
            <Input
              label={label}
              placeholder='dd/mm/aaaa'
              value={values[name] ? moment(values[name]).format('DD/MM/YYYY') : ''}
              style={{ zIndex: 0 }}
              onChange={() => {}}
              onFocus={handleFocus}
            />
            <DatePicker
              id={name}
              ref={pickerRef}
              value={values[name]}
              onChange={val => {
                setFieldValue(name, val)
                if (customChange) {
                  customChange()
                }
              }}
              style={{ display: 'none' }}
            />
          </label>
        )}
      />
    </InputWrapper>
  )
}

export default DatePickerFormik

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
