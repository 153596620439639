import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { Text } from '../../../../components/v2Components'
import { CardProcessing } from '../index'

import { Modal, Row } from './style'

const ModalShowProcessing = ({ open, onClose }) => {
  return (
    <Modal
      open={!!open}
      onClose={onClose}
      title='Detalhes'
      width='100%'
      height='min-content'
      maxWidth='lg'
      fullWidth={true}
    >
      <Row>
        <Text variant='h4'>Detalhes</Text>
        {onClose ? (
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Row>
      <CardProcessing investmentIntentions={open} showProcessing='true' />
    </Modal>
  )
}

export default ModalShowProcessing
