import React from 'react'
import moment from 'moment-timezone'
import { pdf } from '@react-pdf/renderer'

import { PDFIncomeReport } from '../components'

import { getIncomesReport } from '../services/v3'

export const generateIncomeReportData = async user => {
  try {
    if (!user.investor) {
      return null
    }

    const { data: income } = await getIncomesReport(user.investor.id)

    if (!income || income.campaignsInfos.length === 0) {
      return null
    }

    const momentYear = moment().subtract(1, 'year')

    return {
      userInfos: {
        ...user.investor,
        cpf: user.document_number
      },
      year: momentYear.format('YYYY'),
      campaignsInfos: income.campaignsInfos,
      campaignsSum: income.campaignsSum
    }
  } catch (error) {
    console.error(error.message)
    return null
  }
}

export const downloadIncomeReportPDF = async userAnnualIncomeInfos => {
  try {
    const blob = await pdf(<PDFIncomeReport data={userAnnualIncomeInfos} />).toBlob()
    window.open(URL.createObjectURL(blob), '_blank')
  } catch (error) {
    console.error(error.message)
    throw new Error('Erro ao realizar o download do informe.')
  }
}
