import styled from 'styled-components'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import RefreshIcon from '@material-ui/icons/Refresh'

export const Info = styled.div`
  display: grid;
  width: 90vw;
  gap: 25px;
  grid-template-columns: 300px repeat(7, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'title cardAll cardAll cardAll search search search search'
    'cardValue cardStatus cardStatus cardStatus cardStatus cardStatus cardStatus cardStatus'
    'cardValue cardStatus cardStatus cardStatus cardStatus cardStatus cardStatus cardStatus';

  @media (max-width: 1830px) {
    grid-template-columns: 300px repeat(6, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      'title cardAll cardAll cardAll search search search'
      'cardValue cardStatus cardStatus cardStatus cardStatus cardStatus cardStatus';
  }

  @media (max-width: 1600px) {
    grid-template-columns: 300px repeat(6, 1fr);
    grid-template-areas:
      'title search search search search search search'
      'cardValue cardAll cardAll cardAll cardAll cardAll cardAll'
      'cardValue cardStatus cardStatus cardStatus cardStatus cardStatus cardStatus';
  }

  /*  @media (max-width: 1500px) {
    grid-template-columns: 300px 1fr 1fr;
    grid-template-areas:
      'title search search'
      'cardValue cardAll cardFilter'
      'cardValue cardDate .'
      'cardStatus cardStatus cardStatus';
  } */

  @media (max-width: 1060px) {
    grid-template-columns: 300px 1fr 1fr;
    grid-template-areas:
      'title . .'
      'search search search'
      'cardValue cardAll cardAll'
      'cardValue cardAll cardAll'
      'cardStatus cardStatus cardStatus';
  }

  @media (max-width: 805px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'title title '
      'search search'
      'cardAll cardAll'
      'cardValue cardValue'
      'cardStatus cardStatus';
    gap: 10px 0px;
  }
`

export const Title = styled.h1`
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #076f9f;
`

export const PlusIcon = styled.p`
  font-size: 30px;
`

export const CreatePledgeContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5em;
  border-radius: 50%;
  background-color: #5cb85c;
  box-shadow: 0 0 1px 0 #5cb85c inset, 0 0 1px 0 #5cb85c;
  position: fixed;
  bottom: 20px;
  right: 45px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #3d7c3d;
    box-shadow: 0 0 1px 0 #3d7c3d inset, 0 0 1px 0 #3d7c3d;
    transition: all 0.3s;
  }
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const StyledRefreshIcon = styled(RefreshIcon)`
  margin-bottom: 0px;
  margin-top: 0px;
  color: #076f9f;
  cursor: pointer;
  margin-left: 10px;
`

export const StyledSearchIcon = styled(SearchIcon)`
  color: #757575;
  margin: 0px 10px;
  cursor: pointer;
`

export const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  margin: 0px 10px;
`

export const ExcelIcon = styled.img`
  cursor: pointer;
`

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledInputBase = styled(InputBase)`
  width: 480px;
  padding: 5px 16px;
  background: #ffffff;
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 50px;
  @media (max-width: 768px) {
    width: 300px;
  }
`

export const Container = styled.div`
  padding: 120px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StatusFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;

  vl {
    border-left: 1px solid #076f9f;
    height: 10px;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const ReactTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 0px;

  .ReactTable .-loading > div {
    top: 100px;
  }
`

export const TableFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  width: 76px;
  cursor: pointer;
`

export const TableFilterText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #076f9f;
  margin-left: 12px;
`

export const TextLeadsAll = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #076f9f;
  cursor: pointer;
`

export const TableFilterIcon = styled.img`
  height: 18px;
  width: 18px;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const CardRequestsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  grid-area: cardAll;

  @media (max-width: 1060px) {
    display: grid !important;
    grid-template-columns: min-content;
    grid-template-areas:
      'cardAll cardFilter'
      'cardDate .';
  }

  @media (max-width: 805px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'cardAll cardFilter cardDate';
  }

  @media (max-width: 624px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'cardAll cardFilter'
      'cardDate .';
  }
`

export const ContainerCardStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  grid-area: cardStatus;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 1905px) {
    display: none;
  }
`

export const ContainerCardStatusMobile = styled.div`
  display: none;

  @media (max-width: 1905px) {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    grid-area: cardStatus;
  }
`
