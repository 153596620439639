import styled from 'styled-components'

import { colors } from '../../Themes'

export const Title = styled.h1`
  color: ${colors.blueKavod};
  font-size: 24px;
  margin: 20px 10px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 620px) {
    justify-content: center;
  }
`
export const Container = styled.div`
  margin-bottom: 2em;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
