import React, { useState, useEffect, useRef, useContext } from 'react'
import { toast } from 'react-toastify'
import ReactTable from 'react-table-6'
import ReactTooltip from 'react-tooltip'

import { LoadingComponent, ModalDetails, DrawerFiltersLeads, Pagination } from '../../components'
import { CardValue, CardStatus, CardRequests } from './Components'

import { creditRequestColumns } from './creditRequestColumns'

import {
  Title,
  PlusIcon,
  CreatePledgeContainer,
  SearchContainer,
  StyledRefreshIcon,
  StyledSearchIcon,
  StyledClearIcon,
  ExcelIcon,
  SearchIconContainer,
  StyledInputBase,
  Container,
  FilterContainer,
  StatusFiltersContainer,
  ReactTableContainer,
  TableFilterContainer,
  TableFilterText,
  TextLeadsAll,
  TableFilterIcon,
  Row,
  Info,
  CardRequestsContainer,
  ContainerCardStatus,
  ContainerCardStatusMobile
} from './style'

import {
  getLeads,
  getLeadStatuses,
  getLeadsIndicators,
  originLeads,
  getPartners,
  fetchAllEmployees
} from '../../services/v3'
import { LeadContext, GlobalContext } from '../../contexts'
import { useIsMount, useDebounce } from '../../hooks'
import { sanitizeSearchTermFromInput } from '../../helpers'

import './react-table-leads.css'

const CreditRequestList = ({ history }) => {
  const [leads, setLeads] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false)
  const [selectedLead, setSelectedLead] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [responsibles, setResponsibles] = useState([])
  const [indicatorsOptions, setIndicatorsOptions] = useState([])
  const [partnersOptions, setPartnersOptions] = useState([])
  const [origins, setOrigins] = useState([])
  const [leadStatuses, setLeadStatuses] = useState([])
  const [totalizationInfos, setTotalizationInfos] = useState({
    total_qtd_leads: 0,
    total_lending_amount: 0,
    qtd_leads: 0,
    sum_lending_amount: 0,
    qtd_leads_last_days: 0
  })
  const [qtdLeadStatus, setQtdLeadStatus] = useState({})
  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [pagination, setPagination] = useState(false)
  const [params, setParams] = useState({
    statuses: null,
    general_filter: '',
    start_date: null,
    end_date: null,
    lending_amount_min: 0.01,
    lending_amount_max: null,
    responsible: '',
    indicated_by: '',
    contact: '',
    origin_name: '',
    page: 1,
    pageSize: 50,
    order_by: '',
    sort: ''
  })
  const usedHistory = useRef(false)

  const isMount = useIsMount()

  const globalState = useContext(GlobalContext)

  const debounceSearchText = useDebounce(searchText, 500)

  const fetchLeads = async () => {
    try {
      const { data: leadsInfo } = await getLeads(generateQueryParams())
      setLeads(leadsInfo.leads)

      setQtdLeadStatus(leadsInfo.qtd_lead_status_by_id)
      setTotalizationInfos({
        total_qtd_leads: leadsInfo.total_qtd_leads,
        total_lending_amount: leadsInfo.total_lending_amount,
        qtd_leads: leadsInfo.qtd_leads,
        sum_lending_amount: leadsInfo.sum_lending_amount,
        qtd_leads_last_days: leadsInfo.qtd_leads_last_days
      })
      setPagination(leadsInfo.pagination)
      if (selectedLead) {
        const newSelectedLead = leadsInfo.leads.find(({ id }) => id === selectedLead.id)
        if (newSelectedLead) setSelectedLead(newSelectedLead)
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as solicitações de crédito')
    }
    setLoadingTable(false)
  }

  useEffect(() => {
    if (!isMount) {
      fetchLeads()
    }
  }, [params])

  const fetchIndicators = async () => {
    try {
      const { data: indicatorsInfo } = await getLeadsIndicators()
      setIndicatorsOptions(
        indicatorsInfo.leads.map(({ indicated_by }) => ({ value: indicated_by, label: indicated_by }))
      )
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os indicadores')
    }
  }

  useEffect(() => {
    const fetchResponsibles = async () => {
      try {
        const { data: employees } = await fetchAllEmployees({ pageSize: 1000 })
        setResponsibles(
          employees.employees.reduce((acc, cur) => {
            return (acc = [...acc, `${cur.first_name} ${cur.last_name}`])
          }, [])
        )
      } catch (error) {
        toast.error(error.message || 'Ocorreu um erro ao buscar os responsáveis')
      }
    }

    const fetchInfos = async () => {
      try {
        setLoading(true)
        await fetchResponsibles()
        await fetchIndicators()
        const [{ data: leadStatuses }, { data: origins }, { data: partners }] = await Promise.all([
          getLeadStatuses(),
          originLeads(),
          getPartners()
        ])
        setLeadStatuses(leadStatuses)
        setOrigins(origins)
        setPartnersOptions(
          partners.map(({ id, first_name, last_name }) => ({ value: id, label: `${first_name} ${last_name}`.trim() }))
        )

        if (
          history.location.state &&
          history.location.state.leads &&
          history.location.state.leads.length > 0 &&
          !usedHistory.current
        ) {
          usedHistory.current = true
          setParams({
            ...params,
            statuses: leadStatuses.map(({ name }) => name),
            indicated_by: [history.location.state.leads[0].indicated_by]
          })
        } else {
          setParams({
            ...params,
            statuses: ['Novo', 'Em análise', 'Aguardando documentação'],
            indicated_by: ''
          })
        }
      } catch (error) {
        toast.error('Ocorreu um erro ao buscar as informações de leads')
      } finally {
        setLoading(false)
      }
    }
    fetchInfos()
  }, [])

  const generateQueryParams = () => {
    const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) return acc
      if (key === 'statuses') {
        return (
          acc +
          value.reduce(
            (acc, status) => acc + `lead_status_id=${leadStatuses.find(({ name }) => name === status).id}&`,
            ''
          )
        )
      }
      if (key === 'origin_name') {
        return (
          acc +
          value.reduce((acc, status) => acc + `lead_origin_id=${origins.find(({ name }) => name === status).id}&`, '')
        )
      }

      if (key === 'indicated_by') {
        return acc + value.reduce((acc, status) => acc + `indicated_by=${status}&`, '')
      }

      if (key === 'responsible') {
        return acc + value.reduce((acc, status) => acc + `responsible=${status}&`, '')
      }

      return acc + `${key}=${value}&`
    }, '')

    return queryParams
  }

  const handleSearch = filters => {
    setParams({ ...params, ...filters, page: filters.page || 1 })
  }

  const exportLeadsToExcel = async () => {
    try {
      const { data: leads } = await getLeads(generateQueryParams() + 'export_as_csv=true', {
        responseType: 'arraybuffer'
      })
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([leads], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = 'solicitacoes_de_credito.xlsx'
      element.click()
    } catch (error) {
      toast.error('Ocorreu um erro ao exportar a listagem em excel')
      console.log(error)
    }
  }

  const handleTextSearch = () => handleSearch({ general_filter: sanitizeSearchTermFromInput(searchText) })

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') handleTextSearch()
  }

  useEffect(() => {
    handleTextSearch()
  }, [debounceSearchText])

  const handleSortColumns = sortedColumns => {
    setLoadingTable(true)

    const { id, desc } = sortedColumns[0]

    setParams({
      ...params,
      order_by: id,
      sort: !desc ? 'asc' : 'desc'
    })
  }

  const clearFilters = () => {
    setSearchText('')
    setParams({
      ...params,
      statuses: leadStatuses.map(({ name }) => name),
      general_filter: '',
      lending_amount_min: 0.01,
      lending_amount_max: null,
      start_date: null,
      end_date: null,
      responsible: '',
      indicated_by: '',
      contact: '',
      origin_name: '',
      page: 1,
      pageSize: 50,
      order_by: '',
      sort: ''
    })
  }

  globalState.refreshLeadsClean = clearFilters

  const refresh = () => {
    setParams({ ...params })
  }

  const someFilterActive = () => {
    return Object.entries(params).some(([param, value]) => {
      if (param === 'statuses') return value.length !== leadStatuses.length
      else if (param === 'page' || param === 'pageSize') return false
      return Boolean(value)
    })
  }

  const handleResetStatusFilter = () => {
    setParams({
      ...params,
      origin_name: '',
      indicated_by: '',
      responsible: '',
      statuses: leadStatuses.map(({ name }) => name),
      inactive: false,
      is_read: false
    })
  }

  const handleBusinessStatusFilter = () => {
    setParams({
      ...params,
      statuses: ['Novo', 'Em análise', 'Aguardando documentação'],
      indicated_by: '',
      inactive: false,
      is_read: false
    })
  }

  const handleCreditStatusFilter = () => {
    setParams({
      ...params,
      statuses: ['Análise de crédito', 'Aguardando comitê'],
      indicated_by: '',
      inactive: false,
      is_read: false
    })
  }

  const handleInactiveFilter = () => {
    setParams({
      ...params,
      statuses: '',
      page: 1,
      inactive: true,
      is_read: false
    })
  }

  const handleNotificationFilter = () => {
    setParams({
      ...params,
      statuses: '',
      page: 1,
      inactive: false,
      is_read: true
    })
  }

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <Container>
      <LeadContext.Provider
        value={{
          refresh: fetchLeads,
          refetchIndicators: fetchIndicators,
          responsibles,
          indicatorsOptions,
          origins,
          partnersOptions,
          leadStatuses,
          search: handleSearch,
          setSearchText
        }}
      >
        <Info>
          <Row style={{ gridArea: 'title' }}>
            <Title>Solicitações de crédito</Title>
            <StyledRefreshIcon onClick={refresh} styled={{ fontSize: 24 }} />
          </Row>
          <div style={{ gridArea: 'd' }} />
          <div style={{ gridArea: 'dd' }} />
          <SearchContainer style={{ gridArea: 'search' }}>
            <TextLeadsAll onClick={clearFilters} style={{ marginRight: '5px' }}>
              Limpar Filtro
            </TextLeadsAll>
            <StyledInputBase
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={handleKeyDown}
              endAdornment={
                <StyledClearIcon onClick={clearFilters} style={{ color: someFilterActive() ? '#076f9f' : '#757575' }} />
              }
            />
            <SearchIconContainer>
              <StyledSearchIcon onClick={handleTextSearch} />
            </SearchIconContainer>
          </SearchContainer>

          <CardRequestsContainer>
            <CardRequests value={totalizationInfos.total_qtd_leads} type='all' style={{ gridArea: 'cardAll' }} />
            <CardRequests value={totalizationInfos.qtd_leads} type='filter' style={{ gridArea: 'cardFilter' }} />
            <CardRequests value={totalizationInfos.qtd_leads_last_days} type='days' style={{ gridArea: 'cardDate' }} />
          </CardRequestsContainer>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '25px', gridArea: 'cardValue' }}>
            <CardValue value={totalizationInfos.total_lending_amount} />
            {totalizationInfos.total_lending_amount !== totalizationInfos.sum_lending_amount && (
              <CardValue value={totalizationInfos.sum_lending_amount} type='filter' />
            )}
          </div>

          <ContainerCardStatus>
            {leadStatuses.map(status =>
              status.id === 14 ? (
                <CardStatus value={qtdLeadStatus[8] || 0} status={{ name: 'Proposta aceita' }} />
              ) : status.id === 8 ? (
                <CardStatus value={qtdLeadStatus[14] || 0} status={{ name: 'Campanha deletada' }} />
              ) : (
                <CardStatus value={qtdLeadStatus[status.id] || 0} status={status} />
              )
            )}
          </ContainerCardStatus>

          <ContainerCardStatusMobile>
            {leadStatuses.map(status => (
              <CardStatus value={qtdLeadStatus[status.id] || 0} status={status} />
            ))}
          </ContainerCardStatusMobile>
        </Info>

        <DrawerFiltersLeads
          isOpen={isOpenDrawerFilter}
          setIsOpen={setIsOpenDrawerFilter}
          pageSize={params.pageSize}
          maxPageSize={pagination && pagination.rowCount}
          leadStatuses={leadStatuses}
          clearFilters={() => clearFilters()}
          origins={origins}
          params={params}
        />
        <FilterContainer />
        <ReactTableContainer>
          <StatusFiltersContainer>
            <TextLeadsAll onClick={handleResetStatusFilter}>Todos</TextLeadsAll>
            <vl />
            <TextLeadsAll onClick={handleBusinessStatusFilter}>Comercial</TextLeadsAll>
            <vl />
            <TextLeadsAll onClick={handleCreditStatusFilter}>Crédito</TextLeadsAll>
            <vl />
            <TextLeadsAll onClick={handleInactiveFilter}>Inatividade</TextLeadsAll>
            <vl />
            <TextLeadsAll onClick={handleNotificationFilter}>Notificações</TextLeadsAll>
            <ExcelIcon src='/assets/icons/excel.png' alt='Exportar para Excel' onClick={exportLeadsToExcel} />
            <TableFilterContainer onClick={() => setIsOpenDrawerFilter(true)}>
              <TableFilterIcon src='/assets/icons/filter-icon.svg' alt='Filtros da Tabela' />
              <TableFilterText>Filtrar</TableFilterText>
            </TableFilterContainer>
          </StatusFiltersContainer>

          <ReactTable
            className='react-table-leads'
            data={leads}
            columns={creditRequestColumns}
            manual
            loading={loadingTable}
            loadingText='Carregando dados...'
            showPagination={false}
            defaultPageSize={100}
            minRows={10}
            style={{ borderRadius: '10px 10px 0px 0px', width: '90vw', cursor: 'default' }}
            getTableProps={() => ({
              style: {
                borderRadius: '10px 10px 0px 0px'
              }
            })}
            getTrProps={() => ({
              style: {
                color: '#076F9F',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '19px'
              }
            })}
            onSortedChange={shiftKey => {
              handleSortColumns(shiftKey)
            }}
          />
          <Pagination pagination={pagination} search={handleSearch} maxPageNumber={params.pageSize} />
        </ReactTableContainer>
        {isOpenModalDetails && (
          <ModalDetails lead={selectedLead} isOpen={isOpenModalDetails} onClose={() => setIsOpenModalDetails(false)} />
        )}

        <CreatePledgeContainer
          data-tip='Adicionar Solicitação de Crédito'
          onClick={() => history.push('/formulario-solicitacao-credito')}
        >
          <PlusIcon>+</PlusIcon>
        </CreatePledgeContainer>
        <ReactTooltip />
      </LeadContext.Provider>
    </Container>
  )
}

export default CreditRequestList
