import React from 'react'

import PropTypes from 'prop-types'

import { Drawer } from '../../../components'
import { PaymentConfirmed } from '../index'

import { Content, DrawerTitle, CarouselController, ResponsiveController } from './styles'

const DetailsModal = ({ isOpen, isClose, dataInfo, indexValue, handleChangePortion }) => {
  const length = dataInfo.length

  const nextParcel = () => {
    if (indexValue < length - 1) handleChangePortion(indexValue + 1)
  }
  const prevParcel = () => {
    if (indexValue > 0) handleChangePortion(indexValue - 1)
  }

  return (
    <div>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={isClose}
        iconClose
        titleContainer={
          <CarouselController>
            <div>
              <button onClick={prevParcel}>
                <img src='/assets/images/backarrow.svg' alt='backarrow' />
              </button>
              <span>
                {indexValue + 1} / {length}
              </span>
              <button onClick={nextParcel}>
                <img src='/assets/images/nextarrow.svg' alt='nextarrow' />
              </button>
            </div>
            <DrawerTitle> Detalhes das parcelas </DrawerTitle>
          </CarouselController>
        }
      >
        <ResponsiveController>
          <div>
            <button onClick={prevParcel}>
              <img src='/assets/images/backarrow.svg' alt='backarrow' />
            </button>
            <span>
              {indexValue + 1} / {length}
            </span>
            <button onClick={nextParcel}>
              <img src='/assets/images/nextarrow.svg' alt='nextarrow' />
            </button>
          </div>
        </ResponsiveController>
        <Content>
          <div>
            <label>Status</label>
            <p>
              {dataInfo[indexValue].status}
              {dataInfo[indexValue].status === 'Pago' ? <PaymentConfirmed /> : null}
            </p>
          </div>
          <div>
            <label>Valor bruto</label>
            <p>{dataInfo[indexValue]['Valor bruto da parcela']}</p>
          </div>
          <div>
            <label>Amortização</label>
            <p>{dataInfo[indexValue].Amortização}</p>
          </div>
          <div>
            <label>Juros</label>
            <p>{dataInfo[indexValue]['Juros da parcela']}</p>
          </div>
          {!!dataInfo[indexValue]['Juros do atraso'] && (
            <div>
              <label>Juros do atraso</label>
              <p>{dataInfo[indexValue]['Juros do atraso']}</p>
            </div>
          )}

          <div>
            <label>Prazo total operação: </label>
            <p>{dataInfo[indexValue]['Dias corridos desde o início da campanha']}</p>
          </div>
          <div>
            <label>Faixa de imposto de renda</label>
            <p>{dataInfo[indexValue]['Faixa de imposto de renda']}</p>
          </div>
          <div>
            <label>Valor do imposto de renda</label>
            <p>{dataInfo[indexValue]['Imposto de renda da parcela']}</p>
          </div>
          {!!dataInfo[indexValue]['Imposto de renda do atraso da parcela'] && (
            <div>
              <label>Valor do imposto de renda do atraso</label>
              <p>{dataInfo[indexValue]['Imposto de renda do atraso da parcela']}</p>
            </div>
          )}
          <div>
            <label>Imposto de renda acumulado</label>
            <p>{dataInfo[indexValue]['Imposto de renda acumulado']}</p>
          </div>
          <div>
            <label>Valor líquido original</label>
            <p>{dataInfo[indexValue]['Valor líquido original']}</p>
          </div>
          {!!dataInfo[indexValue].transfer_amount_delayed && (
            <div>
              <label>Valor líquido previsto</label>
              <p>{dataInfo[indexValue]['Valor líquido previsto']}</p>
            </div>
          )}
          <div>
            <label>{dataInfo[indexValue].status === 'A receber' ? 'Data prevista do pagamento' : 'Pago em'}</label>
            <p>{dataInfo[indexValue]['Previsão de pagamento']}</p>
          </div>
        </Content>
      </Drawer>
    </div>
  )
}

DetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  isClose: PropTypes.func,
  dataInfo: PropTypes.array
}

export default DetailsModal
