import React from 'react'

import { Container } from './style'

const CardSatus = ({ value, status }) => {
  return (
    <Container status={status.name}>
      <p>{status.name}</p>
      <h4>{value}</h4>
    </Container>
  )
}

export default CardSatus
