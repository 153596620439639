import React from 'react'

import PropTypes from 'prop-types'

import { Container } from './style'

const Modal = ({ title, description, close }) => {
  return (
    <Container show={!!title}>
      <section>
        <div>
          <h1>{title}</h1>

          <button onClick={close}>
            <img src='/assets/images/closemodal.svg' alt='Botão fechar' />
          </button>
        </div>

        {!!description && <p>{description}</p>}
      </section>
    </Container>
  )
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
}

export default Modal
