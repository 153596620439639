import React from 'react'

import { Row, Column, Text, Select } from '../index'

import { Autocomplete } from '../../../components/FormComponents'

const InternalData = ({
  errors,
  valueOrigin,
  isMobile,
  indi,
  isTablet,
  control,
  leadOriginOptions,
  options,
  partners
}) => {
  if (isMobile || isTablet) {
    return (
      <Column>
        <Row justifyContent='flex-start' mb={5}>
          <Text variant='h4'>Dados internos</Text>
        </Row>
        <Select
          mt={5}
          options={leadOriginOptions.map(({ name, id }) => ({ value: id, label: name }))}
          label='Origem *'
          control={control}
          name='lead_origin_id'
          error={errors.lead_origin_id}
        />
        {valueOrigin === 3 ? (
          <Autocomplete
            margin=' 40px 0 0 0 !important'
            error={errors.indicated_by}
            name='indicated_by'
            options={partners}
            control={control}
            label='Indicado por '
            dense
            creatable={false}
            width='100%'
          />
        ) : (
          <Autocomplete
            margin=' 40px 0 0 0 !important'
            error={errors.indicated_by}
            name='indicated_by'
            options={indi}
            control={control}
            label='Indicado por '
            dense
            creatable={true}
            width='100%'
          />
        )}
        <Select
          mt={5}
          error={errors.responsible}
          label='Responsável *'
          name='responsible'
          control={control}
          options={options}
        />
      </Column>
    )
  }
  return (
    <Column>
      <Row justifyContent='flex-start' mb={5}>
        <Text variant='h4'>Dados internos</Text>
      </Row>
      <Row>
        <Select
          options={leadOriginOptions.map(({ name, id }) => ({ value: id, label: name }))}
          label='Origem *'
          control={control}
          name='lead_origin_id'
          width='45%'
          error={errors.lead_origin_id}
        />
        {valueOrigin === 3 ? (
          <Autocomplete
            error={errors.indicated_by}
            name='indicated_by'
            options={partners}
            control={control}
            label='Indicado por '
            dense
            creatable={false}
            margin=' -8px 24px'
            width='45%'
          />
        ) : (
          <Autocomplete
            error={errors.indicated_by}
            name='indicated_by'
            options={indi}
            control={control}
            label='Indicado por '
            dense
            creatable={true}
            margin=' -8px 24px'
            width='45%'
          />
        )}
      </Row>
      <Row mt={5}>
        <Select
          error={errors.responsible}
          label='Responsável *'
          name='responsible'
          control={control}
          options={options}
          width='45%'
        />
      </Row>
    </Column>
  )
}

export default InternalData
