import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { KeyboardDatePicker } from '@material-ui/pickers'

const DatePicker = ({ name, error, label, placeholder, disabled, control, width, margin }) => {
  return (
    <Container width={width} margin={margin}>
      <Controller
        as={
          <KeyboardDatePicker
            inputProps={{
              className: 'browser-default'
            }}
            disabled={disabled}
            fullWidth
            autoOk
            invalidDateMessage='Data inválida'
            error={!!error}
            inputVariant='outlined'
            variant='inline'
            format='DD/MM/YYYY'
            label={label}
            helperText={error && error.message}
          />
        }
        control={control}
        name={name}
        placeholder={placeholder}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '220px')};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`

export { DatePicker }
