import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'

import { Wizard, Button, LoadingScreen, FieldForm, DatePickerFormik, TimePickerFormik } from '../../components'
import { FormContainer, FormTitle, Container, ButtonContainer, StyledForm } from './style'

import { CampaignInfoForm } from '../../constants/yupSchemas'
import { getAnnualRate, checkIsEdit, formatCampaign, formatCampaignToServer } from '../../helpers'
import client from '../../providers/fetchClient'
import MomentUtils from '@date-io/moment'
import { getCampaign, updateCampaign } from '../../services'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import 'moment/locale/pt'

moment.locale('pt')

const CampaignForm = ({ history, match }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [campaign, setCampaign] = useState(formatCampaign())

  useEffect(() => {
    const edit = checkIsEdit(history.location.pathname)
    if (edit) {
      setIsEdit(true)
      const fetchCampaign = async () => {
        try {
          const response = await getCampaign(match.params.id)
          setCampaign(formatCampaign(response.data[0], true))
          setIsLoading(false)
        } catch (e) {
          toast.error('Houve um erro ao buscar as informações.')
          setIsLoading(false)
        }
      }
      fetchCampaign()
    } else {
      setIsLoading(false)
    }
  }, [])

  const handleSubmit = async values => {
    setIsLoading(true)
    try {
      if (isEdit) {
        const response = await updateCampaign(formatCampaignToServer(values))
        toast.success('Campanha atualizada com sucesso.')
        setCampaign(formatCampaign(response.data[0], true))
        setIsLoading(false)
      } else {
        const response = await client.post('/v2/campaigns/', formatCampaignToServer(values))
        toast.success('Campanha criada com sucesso.')
        history.push(`/campanhas/${response.data[0].id}/informacoes`)
      }
    } catch (e) {
      toast.error(`Não foi possível ${isEdit ? 'editar' : 'criar'} a campanha no momento.`)
    }
  }

  const handleAnnualRateValue = (e, setFieldValue) => {
    setFieldValue('anual_rate', getAnnualRate(e.target.value))
    setFieldValue('rate_month', e.target.value)
  }

  if (isLoading) return <LoadingScreen />

  return (
    <Container>
      {isEdit && <Wizard step={1} campaignId={match.params.id} history={history} />}
      <FormContainer>
        <FormTitle>
          {isEdit
            ? `Informações da campanha ${campaign.name}`
            : 'Preencha o formulário para a criação de uma campanha.'}
        </FormTitle>
        <Formik initialValues={campaign} validationSchema={CampaignInfoForm} onSubmit={handleSubmit}>
          {({ setFieldValue, values, errors }) => (
            <StyledForm>
              <FieldForm name='name' placeholder='Nome da campanha' label='Título' />
              <FieldForm
                name='url'
                placeholder='Ex: exemplo-de-campanha'
                label='URL'
                mask='nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn'
                formatChars={{ n: '[a-z/-]' }}
              />
              <FieldForm
                name='rate_month'
                placeholder='Ex: 1.25'
                label='Taxa mensal'
                mask='nnnn'
                formatChars={{ n: '[0-9/.]' }}
                onChange={e => handleAnnualRateValue(e, setFieldValue)}
              />
              <FieldForm name='anual_rate' label='Taxa anual.' disabled />
              <FieldForm name='amount' placeholder='R$ 100,000' label='Meta de captação' currency='true' />
              <FieldForm name='min_amount' placeholder='Ex: R$ 1,000' label='Valor mínimo' currency='true' />
              <FieldForm name='max_amount' placeholder='Ex: R$ 100,000' label='Valor máximo' currency='true' />
              <FieldForm name='installments' placeholder='12' label='Parcelas' />
              <FieldForm name='grace_period' placeholder='Ex: 0' label='Período de carência' mask='9999' />
              <FieldForm name='company_name' placeholder='Ex: Empresa' label='Empresa' />
              <MuiPickersUtilsProvider utils={MomentUtils} locale='pt'>
                <DatePickerFormik
                  name='start_date'
                  label='Data de início'
                  values={values}
                  setFieldValue={setFieldValue}
                  customChange={val => {
                    if (values.end_date && moment(values.end_date).isBefore(val)) {
                      setFieldValue('end_date', val)
                    }
                  }}
                />
                <TimePickerFormik
                  name='start_time'
                  label='Horário de início'
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <DatePickerFormik
                  name='end_date'
                  label='Data de término'
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <TimePickerFormik
                  name='end_time'
                  label='Horário de término'
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </MuiPickersUtilsProvider>
              <ButtonContainer>
                <Button type='submit' label='Enviar' secondary />
              </ButtonContainer>
            </StyledForm>
          )}
        </Formik>
      </FormContainer>
    </Container>
  )
}

export default withRouter(CampaignForm)
