import styled from 'styled-components'
import { Accordion as Acordion } from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

export const FilterContainer = styled.div`
  display: block;
  width: 100%;
`

export const Accordion = styled(Acordion)`
  &&.MuiAccordion-root {
    ${palette};
    ${spacing};
    ${typography};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};

    width: 100%;
    padding-left: 43px;
    margin-bottom: 15px;

    border: 1px solid rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    box-shadow: 0px -1px 36px -4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    text-align: start;

    ::before {
      height: 0px;
    }

    h4 {
      width: 100%;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      white-space: break-spaces;
    }

    &&.Mui-expanded {
      padding: 0px 43px 32px 43px;
    }
  }

  @media (max-width: 600px) {
    &&.MuiAccordion-root {
      margin-bottom: 15px;
      padding-left: 16px;
      text-align: start;
    }
  }
`

export const AnswerFQS = styled.div`
  ul,
  ol {
    padding-left: 25px;
  }

  p,
  ul,
  ol {
    font-weight: 600;
    font-size: 18px;
    color: #757575;

    span {
      &.ql-size-small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 47px;
        color: #757575;
      }

      &.ql-size-large {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 47px;
        color: #757575;
      }

      &.ql-size-huge {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 47px;
        color: #757575;
      }
    }

    strong {
      color: black;
      &.ql-size-small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 47px;
        color: black;
      }

      &.ql-size-large {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 47px;
        color: black;
      }

      &.ql-size-huge {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 47px;
        color: black;
      }
    }

    img {
      width: 100%;
      max-width: 800px;
    }
  }
`
