import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { toast } from 'react-toastify'

import { ModalBase, Column, Button, Row, Input, DropzoneDoc } from '../../../../v2Components'

import { createLeadDocument, updateDocumentStatus } from '../../../../../services/v3'

import { modalSendDocumentsResolver } from '../../../../../schemas'

const defaultValue = { observation: '' }

const ModalSendDocuments = ({ open, lead, handleClose, fetchInfos, documentType }) => {
  const {
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(modalSendDocumentsResolver),
    defaultValues: defaultValue
  })

  const [dataFile, setDataFile] = useState({
    filesData: []
  })

  const updateUploadedFiles = files => setDataFile({ ...dataFile, filesData: files })

  const onSubmit = async data => {
    try {
      dataFile.filesData.map(file => {
        if (data[file.name.split('.')[0]] === '') {
          throw Error('doc_error')
        }

        return null
      })
      if (dataFile.filesData.length > 0) {
        const formData = new FormData()
        formData.append('file', dataFile.filesData[0])
        formData.append('lead_id', lead.id)
        formData.append('lead_document_type_id', Number(data[dataFile.filesData[0].name.split('.')[0]]))
        formData.append('is_first', true)
        if (!!data.observation) {
          formData.append('lead_document_observation', data.observation)
        }

        await createLeadDocument(formData).then(async resp => {
          await updateDocumentStatus({ document_id: resp.data.id, is_approved: true }).then(async () => {
            if (dataFile.filesData.length >= 2) {
              var newArrayFiles = dataFile.filesData.slice(1)

              newArrayFiles.map(async item => {
                const formData = new FormData()
                formData.append('file', item)
                formData.append('lead_id', lead.id)
                formData.append('lead_document_type_id', Number(data[item.name.split('.')[0]]))
                if (!!data.observation) {
                  formData.append('lead_document_observation', data.observation)
                }

                const { data: resp } = await createLeadDocument(formData)
                await updateDocumentStatus({ document_id: resp.id, is_approved: true })
              })
            }
          })
        })

        toast.success('Documento enviado.')
        fetchInfos()
        handleClose()
      } else {
        throw Error('empty')
      }
    } catch (error) {
      if (error.message === 'empty') {
        return toast.error('Precisa anexar documentos.')
      }
      if (error.message === 'doc_error') {
        return toast.error('Precisa verificar os tipos do documento.')
      }

      toast.error('Ocorreu um erro ao enviar o documento.')
    }
  }

  useEffect(() => {
    setDataFile({
      filesData: []
    })
  }, [open])

  return (
    <ModalBase
      open={open ? true : false}
      onClose={handleClose}
      height='100%'
      width='100%'
      maxWidth='xl'
      title={'Enviar Documentos'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column height='100%'>
          <DropzoneDoc
            accept='.jpg,.png,.jpeg,.pdf,.png,.csv,.html,.xls, .xlsx,.sheet'
            multiple
            updateFilesCb={updateUploadedFiles}
            control={control}
            errors={errors}
            options={documentType.map(item => {
              return { value: item.id, label: item.name }
            })}
          />

          <Input
            name='observation'
            placeholder='Observação'
            label='Observação'
            control={control}
            multiline
            mt='15px'
            rows={6}
            error={errors.observation}
            style={{ minWidth: '100px' }}
          />

          <Row mt={3} justifyContent='flex-end'>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting}>
              Enviar
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalSendDocuments
