import React from 'react'
import ReactDOM from 'react-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment-timezone'
import Theme from './Themes/Theme'

import App from './App'

ReactDOM.render(
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='pt-br'>
    <Theme>
      <App />
    </Theme>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)
