import React from 'react'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'

const CardEditIcon = ({ isOpen, ...props }) => <StyledEditIcon style={{ fontSize: 16 }} {...props} />

const StyledEditIcon = styled(EditIcon)`
  position: absolute;
  right: 15px;
  top: 15px;
  color: #076f9f;
  cursor: pointer;
`

export default CardEditIcon
