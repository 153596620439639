import React, { Fragment, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { Input } from '../../components'

import { CPF_LENGTH, CNPJ_LENGTH } from '../../constants'
import { getCompanyInfos, getUsers } from '../../services/v3'

const ProcessCampaignPartnerForm = ({ setFieldValue, partner, index }) => {
  useEffect(() => {
    searchPartner(partner.partner_document)
  }, [])

  const searchPartner = async docNumber => {
    const sanitizedDocNumber = docNumber.replace(/\D/g, '')
    if (sanitizedDocNumber.length === CPF_LENGTH) {
      try {
        const {
          data: [user]
        } = await getUsers({ document_number: sanitizedDocNumber })

        if (!user) return

        const userInfos = {
          ...user,
          ...(user.signer ? user.signer : null),
          ...(user.investor ? user.investor : null)
        }

        setFieldValue(`partners[${index}].partner_name`, `${userInfos.first_name} ${userInfos.last_name}`)
      } catch (error) {
        toast.error('Ocorreu um erro ao buscar o sócio: ' + error.message)
      }
    } else if (sanitizedDocNumber.length === CNPJ_LENGTH) {
      const companyInfo = await getCompanyInfos(sanitizedDocNumber).catch(() =>
        toast.error('Aguarde para fazer novas buscas de CNPJ.')
      )
      if (companyInfo && companyInfo.status !== 'ERROR') {
        setFieldValue(`partners[${index}].partner_name`, companyInfo.nome)
      }
    }
  }

  const handleDocumentChange = async e => {
    setFieldValue(`partners[${index}].partner_document`, e.target.value)
    searchPartner(e.target.value)
  }

  return (
    <Fragment>
      <InputContainer>
        <Field
          name={`partners[${index}].partner_document`}
          render={({ field }) => (
            <Input label='Documento Federal' placeholder='CPF/CNPJ' {...field} onChange={handleDocumentChange} />
          )}
        />
        <ErrorMessage name={`partners[${index}].partner_document`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`partners[${index}].partner_name`}
          render={({ field }) => <Input label='Nome' placeholder='Ex: João das neves...' {...field} />}
        />
        <ErrorMessage name={`partners[${index}].partner_name`} component={Error} />
      </InputContainer>
    </Fragment>
  )
}

const InputContainer = styled.div`
  display: flex;
  width: 25%;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

export default ProcessCampaignPartnerForm
