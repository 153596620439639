import React from 'react'
import styled from 'styled-components'

import { MaterialButton, Modal } from '../../components'

const ModalChangeStatus = ({ isOpen, setIsOpen, onConfirm, onCancel }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalContent>
        <Text>Deseja confirmar a alteração dos status das propostas da campanha?</Text>
        <div>
          <MaterialButton label='Confirmar' onClick={onConfirm} />
          <MaterialButton label='Cancelar' backgroundColor={'#3dc6ef'} onClick={onCancel} />
        </div>
      </ModalContent>
    </Modal>
  )
}

const Text = styled.p`
  font-weight: bold;
  margin-bottom: 20px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`

export default ModalChangeStatus
