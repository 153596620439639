import styled from 'styled-components'
import { colors } from '../../../../Themes/colors'

export const Container = styled.button`
  margin-left: 20px;
  border: none;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  img {
    margin: 0;
    padding: 0;
  }

  span {
    color: ${colors.blue};
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
  }
`
