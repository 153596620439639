import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { ProposalStatus, CircleProgressBar, ModalDownloadCcb, LoadingComponent, ModalLoading } from '../../components'
import { ProcessOrders, Simulator, ProcessCampaign, BankCreditCertificates } from '../'

import { IDLE, FETCHING } from '../../constants/status'
import { getCampaignById, getCampaignStatuses, updateCampaign } from '../../services/v3'
import { runCampaignSteps } from '../../constants'
import { getRunCampaignAbbreviation } from '../../helpers'

const RunCampaign = ({ match, history }) => {
  const [activeStep, setActiveStep] = useState(null)
  const [campaignStep, setCampaignStep] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModalDownloadCcb, setIsOpenModalDownloadCcb] = useState(false)
  const [status, setStatus] = useState(FETCHING)
  const [campaign, setCampaign] = useState({})

  useEffect(() => {
    const getCampaignStatus = async () => {
      try {
        setStatus(FETCHING)

        const { data: campaign } = await getCampaignById(match.params.id)
        const campaignStatusAbbreviation = getRunCampaignAbbreviation(campaign.campaign_status.abbreviation)
        const currentStep = runCampaignSteps.find(({ abbreviation }) => abbreviation === campaignStatusAbbreviation)

        setCampaign(campaign)
        setActiveStep(currentStep)
        setCampaignStep(currentStep)
      } catch (error) {
        console.error(error)
        toast.error('Erro ao carregar as informações da campanha')
      } finally {
        setStatus(IDLE)
      }
    }

    getCampaignStatus()
  }, [])

  const updateCampaignStatus = async newStepAbbreviation => {
    try {
      setStatus(FETCHING)
      const { data: campaignStatuses } = await getCampaignStatuses()
      const processedStatus = campaignStatuses.find(({ abbreviation }) => abbreviation === newStepAbbreviation)
      if (!processedStatus) throw new Error('Não foi possível processar os pedidos')
      await updateCampaign(campaign.id, { campaign_status_id: processedStatus.id })
      handleNext()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao processar os pedidos')
    } finally {
      setStatus(IDLE)
    }
  }

  const handleNext = () => {
    const newActiveStep = runCampaignSteps.find(({ order }) => activeStep.order + 1 === order)

    if (campaignStep.order < activeStep.order + 1) {
      const newCampaignStep = runCampaignSteps.find(({ order }) => campaignStep.order + 1 === order)
      setCampaignStep(newCampaignStep)
    }
    setActiveStep(newActiveStep)
  }

  if (status === FETCHING) {
    return (
      <Container>
        <LoadingComponent />
      </Container>
    )
  }

  return (
    <Container>
      {isLoading && <ModalLoading isOpen={isLoading} setIsOpen={setIsLoading} />}
      <StepContainer>
        {runCampaignSteps.map(runCampaignStep => (
          <ButtonStep
            key={runCampaignStep.title}
            onClick={() => {
              if (runCampaignStep.order <= campaignStep.order) {
                setActiveStep(runCampaignStep)
              }
            }}
          >
            <TextStep index={runCampaignStep.order} activeStep={activeStep.order}>
              {runCampaignStep.title}
            </TextStep>
          </ButtonStep>
        ))}
      </StepContainer>
      <CircleProgressBar step={activeStep.order} />
      <StepsContent>
        {(() => {
          switch (activeStep.order) {
            case 1:
              return <ProcessOrders match={match} history={history} updateCampaignStatus={updateCampaignStatus} />
            case 2:
              return <Simulator updateCampaignStatus={updateCampaignStatus} match={match} />
            case 3:
              return (
                <ProcessCampaign match={match} handleNext={handleNext} updateCampaignStatus={updateCampaignStatus} />
              )
            case 4:
              return (
                <ProposalStatus
                  match={match}
                  setIsLoading={setIsLoading}
                  handleNext={handleNext}
                  updateCampaignStatus={updateCampaignStatus}
                  openModalCcb={() => setIsOpenModalDownloadCcb(true)}
                />
              )
            case 5:
            default:
              return (
                <BankCreditCertificates
                  match={match}
                  history={history}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )
          }
        })()}
      </StepsContent>
      {isOpenModalDownloadCcb && (
        <ModalDownloadCcb
          isOpen={isOpenModalDownloadCcb}
          setIsOpen={setIsOpenModalDownloadCcb}
          campaignId={match.params.id}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  p {
    span {
      font-weight: 500;
    }
  }
`
const StepContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  @media (max-width: 972px) {
    flex-direction: column;
  }
`
const TextStep = styled.p`
  margin: 0 10px;
  font-size: 20px;
  color: ${({ theme, index, activeStep }) => (index <= activeStep ? theme.colors.blueKavod : theme.colors.mediumGray)};
`
const ButtonStep = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'auto')};

  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  @media (max-width: 972px) {
    margin-bottom: 20px;
    width: 300px;
    text-align: left;
  }
`
const StepsContent = styled.div`
  align-items: center;
  width: 100%;
`
export default RunCampaign
