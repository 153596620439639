import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'

import { Input } from '../../components'

import { CPF_LENGTH } from '../../constants'
import { getUsers } from '../../services/v3'

const ProcessCampaignSubscribersForm = ({ setFieldValue, subscriber, index }) => {
  const [loading, setLoading] = useState(false)

  const searchSubscriber = async docNumber => {
    const sanitizedDocNumber = docNumber.replace(/\D/g, '')
    if (sanitizedDocNumber.length === CPF_LENGTH) {
      try {
        setLoading(true)

        const {
          data: [user]
        } = await getUsers({ document_number: sanitizedDocNumber })

        if (!user) return

        const userInfos = {
          ...user,
          ...(user.signer ? user.signer : null),
          ...(user.investor ? user.investor : null)
        }

        setFieldValue(`subscribers[${index}].subscriber_email`, userInfos.email || '')
        setFieldValue(`subscribers[${index}].subscriber_name`, `${userInfos.first_name} ${userInfos.last_name}`)
        setFieldValue(`subscribers[${index}].subscriber_phone_prefix`, userInfos.prefix || '')
        setFieldValue(`subscribers[${index}].subscriber_phone`, userInfos.phone || '')
      } catch (error) {
        console.error(error.message)
        toast.error('Ocorreu um erro ao buscar o assinante')
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (subscriber.subscriber_document) {
      searchSubscriber(subscriber.subscriber_document)
    }
  }, [])

  const handleDocumentChange = async e => {
    setFieldValue(`subscribers[${index}].subscriber_document`, e.target.value)
    searchSubscriber(e.target.value)
  }

  return (
    <Fragment>
      <InputContainer>
        <Field
          name={`subscribers[${index}].subscriber_document`}
          render={({ field }) => (
            <Input
              label='CPF'
              type='text'
              placeholder='Procure o usuário pelo CPF'
              {...field}
              onChange={handleDocumentChange}
            />
          )}
        />
        <ErrorMessage component={Error} name={`subscribers[${index}].subscriber_document`} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`subscribers[${index}].subscriber_email`}
          render={({ field }) => <Input disabled loading={loading} label='E-mail' {...field} />}
        />
        <ErrorMessage component={Error} name={`subscribers[${index}].subscriber_email`} />
      </InputContainer>

      <InputContainer>
        <Field
          name={`subscribers[${index}].subscriber_name`}
          render={({ field }) => <Input disabled loading={loading} label='Nome' {...field} />}
        />
        <ErrorMessage component={Error} name={`subscribers[${index}].subscriber_name`} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`subscribers[${index}].subscriber_phone_prefix`}
          render={({ field }) => <Input loading={loading} label='Prefixo' mask='99' {...field} />}
        />
        <ErrorMessage component={Error} name={`subscribers[${index}].subscriber_phone_prefix`} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`subscribers[${index}].subscriber_phone`}
          render={({ field }) => <Input loading={loading} label='Telefone celular' mask='99999-9999' {...field} />}
        />
        <ErrorMessage component={Error} name={`subscribers[${index}].subscriber_phone`} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`subscribers[${index}].subscriber_description`}
          render={({ field }) => <Input label='Descrição' {...field} />}
        />
        <ErrorMessage component={Error} name={`subscribers[${index}].subscriber_description`} />
      </InputContainer>
    </Fragment>
  )
}

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

const InputContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`

export default ProcessCampaignSubscribersForm
