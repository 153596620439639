import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'

import { Text } from '../index'

export const Container = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledAvatar = styled(Avatar)`
  &&.MuiAvatar-root {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`

export const StyledText = styled(Text)`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`
