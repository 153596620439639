import React from 'react'
import { Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styled from '@emotion/styled'
import Pledge from './Pledge'

const ModalPledgeTable = ({
  open,
  onClose,
  pledges,
  campaign,
  reload,
  handleUpdate,
  pledgeStatuses,
  reopenCampaingModal
}) => {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <ModalContainer>
        <ModalCloseIcon data-tip='Fechar' onClick={onClose} />
        {pledges.map(pledge => (
          <Pledge
            key={pledge.id}
            pledge={pledge}
            handleUpdate={(pledge, newStatus) => {
              handleUpdate(pledge, newStatus)
              onClose()
            }}
            reload={reload}
            campaign={campaign}
            pledgeStatuses={pledgeStatuses}
            reopenCampaingModal={reopenCampaingModal}
            index={false}
          />
        ))}
      </ModalContainer>
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
`
const ModalContainer = styled.div`
  width: 80vw;
  max-height: 80%;
  padding: 1em 2em;
  background-color: #fff;
  position: relative;
  outline: none;
  display: block;
  overflow: auto;
  @media (max-width: 500px) {
    height: 100%;
    width: 100%;
  }
`

const ModalCloseIcon = styled(Close)`
  color: #7f7f7f;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

export default ModalPledgeTable
