import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactTable from 'react-table-6'

import { LoadingComponent, Pagination } from '../../components'
import { partnersColumns } from './partnersColumns'
import {
  Container,
  TitleContainer,
  Title,
  ReactTableContainer,
  SearchContainer,
  StyledSearchIcon,
  SearchIconContainer,
  StyledInputBase,
  FilterContainer,
  StatusFiltersContainer,
  StatusFilter
} from './style'

import { IDLE, FETCHING } from '../../constants/status'
import { getPartners, getPartnerStatuses } from '../../services/v3'
import { PartnerContext } from '../../contexts'

import { sanitizeSearchTermFromInput } from '../../helpers'

import './react-table-partners.css'

const numberRowns = 20 /*  maximum number of rows in the table */

const PartnersComponent = () => {
  const [status, setStatus] = useState(false)
  const [partners, setPartners] = useState([])
  const [partnerStatuses, setPartnerStatuses] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filterStatuses, setFilterStatuses] = useState(['Ativo'])
  const [params, setParams] = useState({
    general_filter: '',
    page: 1,
    pageSize: numberRowns,
    order_by: '',
    sort: ''
  })
  const [pagination, setPagination] = useState(false)

  const fetchPartners = async () => {
    try {
      const { data: partnerss } = await getPartners(generateQueryParams())
      setPartners(partnerss.slice(params.page * params.pageSize - numberRowns, params.page * params.pageSize))
      setPagination({
        offset: params.page - 1,
        limit: 1,
        rowCount: partners.length,
        pageCount: Math.ceil(partnerss.length / numberRowns)
      })
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as parcerias')
    }
  }

  useEffect(() => {
    fetchPartners()
  }, [params])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setStatus(FETCHING)
        const { data: partnerStatuses } = await getPartnerStatuses()
        setPartnerStatuses(partnerStatuses)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar os parceiros no banco.')
      } finally {
        setStatus(IDLE)
      }
    }
    fetchInfos()
  }, [])

  /* const handleSearch = filters => {
    setParams({ ...params, ...filters })
  } */

  const handleSearch = filters => {
    setParams({ ...params, ...filters, page: filters.page || 1 })
  }

  const generateQueryParams = () => {
    const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
      return acc + (value ? `${key}=${value}&` : '')
    }, '')
    return queryParams
  }

  const handleTextSearch = () => handleSearch({ general_filter: sanitizeSearchTermFromInput(searchText) })

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') handleTextSearch()
  }

  if (status === FETCHING) {
    return <LoadingComponent />
  }

  return (
    <PartnerContext.Provider value={{ partnerStatuses, refresh: fetchPartners }}>
      <Container>
        <TitleContainer>
          <Title>Parcerias</Title>
        </TitleContainer>
        <FilterContainer>
          <SearchContainer>
            <StyledInputBase
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <SearchIconContainer>
              <StyledSearchIcon onClick={handleTextSearch} />
            </SearchIconContainer>
          </SearchContainer>
          <StatusFiltersContainer>
            {partnerStatuses.map(({ id, name }) => {
              const hasStatus = filterStatuses.includes(name)
              return (
                <StatusFilter
                  key={id}
                  bgColor={hasStatus ? '#2F80ED' : '#BDBDBD'}
                  onClick={() =>
                    setFilterStatuses(
                      hasStatus ? filterStatuses.filter(status => status !== name) : [...filterStatuses, name]
                    )
                  }
                >
                  {name}
                </StatusFilter>
              )
            })}
          </StatusFiltersContainer>
        </FilterContainer>
        <ReactTableContainer>
          <ReactTable
            className='react-table-partners'
            data={partners.filter(
              ({ partner_status }) =>
                filterStatuses.length === 0 || (partner_status && filterStatuses.includes(partner_status.name))
            )}
            columns={partnersColumns}
            showPagination={false}
            minRows={1}
            manual
            style={{ borderRadius: '10px 10px 0px 0px', width: '90vw', cursor: 'default' }}
            getTableProps={() => ({
              style: {
                borderRadius: '10px 10px 0px 0px'
              }
            })}
            getTrProps={() => ({
              style: {
                color: '#076F9F',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '19px'
              }
            })}
            getTdProps={() => ({
              style: {
                cursor: 'default',
                display: 'flex',
                alignItems: 'center'
              }
            })}
          />
          <Pagination pagination={pagination} search={handleSearch} maxPageNumber={params.pageSize} />
        </ReactTableContainer>
      </Container>
    </PartnerContext.Provider>
  )
}

export default PartnersComponent
