import React from 'react'
import styled from 'styled-components'

import { InfoField } from '../../components'

const InfoFieldsLine = ({ infos }) => {
  return (
    <Wrapper>
      {infos.map(({ label, value }) => (
        <InfoField key={label} labelText={label} valueText={value} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export default InfoFieldsLine
