import React, { useContext } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'

import { LeadContext } from '../../contexts'

const PopoverResponsible = ({ anchor, setAnchor, handleSelectOption }) => {
  const { responsibles } = useContext(LeadContext)

  return (
    <Popover
      id='responsible-popover'
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {responsibles.map(responsible => (
        <MenuItem key={responsible} value={responsible} onClick={() => handleSelectOption(responsible)}>
          {responsible}
        </MenuItem>
      ))}
    </Popover>
  )
}

export default PopoverResponsible
