import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'

import { Text, Column } from '../index'

export const EmailText = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`

export const Card = styled(Column)`
  box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
  border-radius: 4px;
  width: 272px;
  padding: 16px;
  margin-bottom: 16px;
  margin-right: 12px;
  margin-left: 12px;
  height: 448px;
`
export const Admin = styled.span`
  position: relative;
  color: blue;
  border-bottom: 33px solid #076f9f;
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  height: 0;
  width: 117px;
  top: -419px;
  left: -45px;
  transform: rotate(-44deg);

  p {
    position: relative;
    top: 5px;
    left: 2px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }
`

export const StyledAvatar = styled(Avatar)`
  &&.MuiAvatar-root {
    width: 56px;
    height: 56px;
  }
`
