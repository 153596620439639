import React from 'react'

import { Container } from './style'

const CardRequests = ({ value, type = 'all' }) => {
  if (type === 'all') {
    return (
      <Container status='Total de Solicitações' type={type}>
        <p>Total de solicitações</p>
        <h4>{value}</h4>
      </Container>
    )
  } else if (type === 'filter') {
    return (
      <Container status='Solicitações Filtradas' type={type}>
        <p>Solicitações Filtradas</p>
        <h4>{value}</h4>
      </Container>
    )
  } else {
    return (
      <Container status='Últimos 7 dias' type={type}>
        <p>Últimos 7 dias</p>
        <h4>{value}</h4>
      </Container>
    )
  }
}

export default CardRequests
