import React, { useEffect, useState } from 'react'
import { FormControlLabel } from '@material-ui/core'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'
import { Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { Checkbox, Modal } from '../../components'

import { getCampaignsPledges } from '../../services'
import { getCampaignStatuses, updateCampaign } from '../../services/v3'
import { campaignStatuses as campaignStatusesAbbreviation } from '../../constants'

const ModalCloseCampaign = ({ isOpen, setIsOpen, changeCampaignStatus, handleChecked, reloadCampaign }) => {
  const handleCloseCampaign = async status => {
    try {
      await changeCampaignStatus(status)
      handleChecked()
      setIsOpen(false)
      reloadCampaign()
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} setIsOpen={setIsOpen} shouldCloseOnEsc={true} overlayClassName='Overlay'>
      <ModalContent>
        <CloseIconContainer onClick={_ => setIsOpen(false)}>
          <CloseIcon />
        </CloseIconContainer>
        <TitleModal>A campanha atingiu a meta?</TitleModal>
        <Buttons>
          <Button
            color='primary'
            variant='contained'
            onClick={() => handleCloseCampaign(campaignStatusesAbbreviation.CLOSED)}
            style={{ margin: '0 1em', backgroundColor: '#076f9f' }}
          >
            Sim
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => handleCloseCampaign(campaignStatusesAbbreviation.CLOSED_WITHOUT_CAPTATION)}
          >
            Não
          </Button>
        </Buttons>
      </ModalContent>
    </Modal>
  )
}

const ModalOpenCampaign = ({
  isOpen,
  setIsOpen,
  changeCampaignStatus,
  campaignId,
  fundraisingGoal,
  handleChecked,
  reloadCampaign
}) => {
  const handleOpenCampaign = async _ => {
    try {
      const pledges = await getCampaignsPledges(campaignId)

      let totalAmount = 0

      if (pledges && pledges.data) {
        totalAmount = pledges.data
          .filter(pledge => ['AC', 'A'].includes(pledge.stt_id))
          .reduce((totalAmount, pledge) => totalAmount + pledge.amount, 0)
      }

      if (totalAmount >= fundraisingGoal) {
        await changeCampaignStatus(campaignStatusesAbbreviation.WAITING_LIST)
      } else {
        await changeCampaignStatus(campaignStatusesAbbreviation.ACTIVE)
      }
      handleChecked()
      setIsOpen(false)
      reloadCampaign()
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} setIsOpen={setIsOpen} shouldCloseOnEsc={true} overlayClassName='Overlay'>
      <ModalContent>
        <CloseIconContainer onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </CloseIconContainer>
        <TitleModal>Reabrir a campanha?</TitleModal>
        <Buttons>
          <Button
            color='primary'
            variant='contained'
            onClick={handleOpenCampaign}
            style={{ margin: '0 1em', backgroundColor: '#076f9f' }}
          >
            Sim
          </Button>
          <Button color='secondary' variant='contained' onClick={() => setIsOpen(false)}>
            Não
          </Button>
        </Buttons>
      </ModalContent>
    </Modal>
  )
}

const CloseIconContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  position: absolute;
  right: 10;
  top: 10;
`

const TitleModal = styled.p`
  font-size: 1.1em;
`

const Buttons = styled.div`
  padding: 1em 0;
  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
  }
`

const ModalContent = styled.div`
  display: flex;
  width: 400px;
  border-radius: 4px;
  height: 200px;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CampaignStatus = ({ campaign, match, reloadCampaign = null }) => {
  const [checked, setChecked] = useState(false)
  const [isOpenModalCloseCampaign, setIsOpenModalCloseCampaign] = useState(false)
  const [isOpenModalOpenCampaign, setIsOpenModalOpenCampaign] = useState(false)
  const [campaignStatuses, setCampaignStatuses] = useState([])

  useEffect(() => {
    const fetchCampaignStatuses = async () => {
      try {
        const { data: campaignStatuses } = await getCampaignStatuses()
        setCampaignStatuses(campaignStatuses)
      } catch (error) {
        console.error(error)
      }
    }

    fetchCampaignStatuses()

    const finishedStatuses = [
      campaignStatusesAbbreviation.CLOSED_WITHOUT_CAPTATION,
      campaignStatusesAbbreviation.CLOSED,
      campaignStatusesAbbreviation.PAYMENT_RECEIVED,
      campaignStatusesAbbreviation.DISBURSED,
      campaignStatusesAbbreviation.OLD_CCBS,
      campaignStatusesAbbreviation.PROCESS_PLEDGES,
      campaignStatusesAbbreviation.BMP_SIMULATOR,
      campaignStatusesAbbreviation.SEND_PROPOSAL,
      campaignStatusesAbbreviation.PROPOSAL_STATUS,
      campaignStatusesAbbreviation.CCBS
    ]
    setChecked(Boolean(campaign.campaign_status && finishedStatuses.includes(campaign.campaign_status.abbreviation)))
  }, [])

  const getCampaignStatus = status => {
    switch (status) {
      case campaignStatusesAbbreviation.ACTIVE:
        return <Title color='#146801'>Em captação.</Title>
      case campaignStatusesAbbreviation.CLOSED:
        return <Title color='#076f9f'>Encerrada.</Title>
      case campaignStatusesAbbreviation.CLOSED_WITHOUT_CAPTATION:
        return <Title color='#680000'>Encerrada sem concluir captação.</Title>
      case campaignStatusesAbbreviation.WAITING_LIST:
        return <Title color='#f29d00'>Em lista de espera.</Title>
      case campaignStatusesAbbreviation.DELETED:
        return <Title color='#680000'>Deletada.</Title>
      case campaignStatusesAbbreviation.PAYMENT_RECEIVED:
        return <Title color='#076f9f'>TED's recebidas.</Title>
      case campaignStatusesAbbreviation.DISBURSED:
        return <Title color='#076f9f'>Desembolso realizado.</Title>
      case campaignStatusesAbbreviation.PROCESS_PLEDGES:
      case campaignStatusesAbbreviation.BMP_SIMULATOR:
      case campaignStatusesAbbreviation.SEND_PROPOSAL:
      case campaignStatusesAbbreviation.PROPOSAL_STATUS:
      case campaignStatusesAbbreviation.CCBS:
        return <Title color='#076f9f'>{campaign.campaign_status.name}.</Title>
      default:
        return <Title>{campaign.campaign_status.name || 'Indefinido.'}</Title>
    }
  }

  const changeCampaignStatus = async status => {
    const newStatus = campaignStatuses.find(({ abbreviation }) => abbreviation === status)
    try {
      if (!newStatus) throw new Error('Ocorreu um erro ao atualizar o status')
      await updateCampaign(campaign.id, { campaign_status_id: newStatus.id })
    } catch (error) {
      toast.error(error.message)
    }
  }

  const invertChecked = _ => setChecked(!checked)

  const handleUpdate = async e => {
    switch (e.target.name) {
      case 'payments_received':
        try {
          let newStatus = null
          if (e.target.checked) {
            newStatus = campaignStatuses.find(
              ({ abbreviation }) => abbreviation === campaignStatusesAbbreviation.PAYMENT_RECEIVED
            )
          } else {
            newStatus = campaignStatuses.find(
              ({ abbreviation }) => abbreviation === campaignStatusesAbbreviation.CLOSED
            )
          }
          if (!newStatus) {
            throw new Error('Ocorreu um erro ao tentar atualizar o status da campanha')
          }
          await updateCampaign(campaign.id, { campaign_status_id: newStatus.id })
          reloadCampaign()
        } catch (error) {
          toast.error(error.message)
        }
        break

      case 'disbursement_done':
        try {
          let newStatus = null
          if (e.target.checked) {
            newStatus = campaignStatuses.find(
              ({ abbreviation }) => abbreviation === campaignStatusesAbbreviation.DISBURSED
            )
          } else {
            newStatus = campaignStatuses.find(
              ({ abbreviation }) => abbreviation === campaignStatusesAbbreviation.PAYMENT_RECEIVED
            )
          }
          if (!newStatus) {
            throw new Error('Ocorreu um erro ao tentar atualizar o status da campanha')
          }
          await updateCampaign(campaign.id, { campaign_status_id: newStatus.id })
          reloadCampaign()
        } catch (error) {
          toast.error(error.message)
        }
        break
      case 'finish_campaign':
        checked ? setIsOpenModalOpenCampaign(true) : setIsOpenModalCloseCampaign(true)
        break

      default:
        break
    }
  }

  const checkIsPaymentReceived = () => {
    const receivedStatuses = [
      campaignStatusesAbbreviation.PAYMENT_RECEIVED,
      campaignStatusesAbbreviation.DISBURSED,
      campaignStatusesAbbreviation.OLD_CCBS,
      campaignStatusesAbbreviation.PROCESS_PLEDGES,
      campaignStatusesAbbreviation.BMP_SIMULATOR,
      campaignStatusesAbbreviation.SEND_PROPOSAL,
      campaignStatusesAbbreviation.PROPOSAL_STATUS,
      campaignStatusesAbbreviation.CCBS
    ]

    return Boolean(campaign.campaign_status && receivedStatuses.includes(campaign.campaign_status.abbreviation))
  }

  const checkIsDisbursementReceived = () => {
    const disbursementStatuses = [
      campaignStatusesAbbreviation.DISBURSED,
      campaignStatusesAbbreviation.OLD_CCBS,
      campaignStatusesAbbreviation.PROCESS_PLEDGES,
      campaignStatusesAbbreviation.BMP_SIMULATOR,
      campaignStatusesAbbreviation.SEND_PROPOSAL,
      campaignStatusesAbbreviation.PROPOSAL_STATUS,
      campaignStatusesAbbreviation.CCBS
    ]
    return Boolean(campaign.campaign_status && disbursementStatuses.includes(campaign.campaign_status.abbreviation))
  }

  return (
    <Container>
      <CheckboxContainer>
        <FormControlLabel
          control={<Checkbox name='finish_campaign' onChange={checked ? null : handleUpdate} checked={checked} />}
          label='Encerrar Campanha'
        />
      </CheckboxContainer>
      <CheckboxContainer>
        <FormControlLabel
          control={<Checkbox name='payments_received' onChange={handleUpdate} checked={checkIsPaymentReceived()} />}
          label="TED's recebidas."
        />
      </CheckboxContainer>
      <CheckboxContainer>
        <FormControlLabel
          control={
            <Checkbox name='disbursement_done' onChange={handleUpdate} checked={checkIsDisbursementReceived()} />
          }
          label='Desembolso realizado.'
        />
      </CheckboxContainer>
      <p>
        Status da campanha:{' '}
        {campaign.campaign_status ? (
          getCampaignStatus(campaign.campaign_status.abbreviation)
        ) : (
          <Title>Indefinido.</Title>
        )}
      </p>
      <ModalCloseCampaign
        isOpen={isOpenModalCloseCampaign}
        setIsOpen={setIsOpenModalCloseCampaign}
        changeCampaignStatus={changeCampaignStatus}
        handleChecked={invertChecked}
        reloadCampaign={reloadCampaign}
      />
      <ModalOpenCampaign
        isOpen={isOpenModalOpenCampaign}
        setIsOpen={setIsOpenModalOpenCampaign}
        changeCampaignStatus={changeCampaignStatus}
        campaignId={campaign.stt_id}
        fundraisingGoal={campaign.amount}
        handleChecked={invertChecked}
        reloadCampaign={reloadCampaign}
      />
    </Container>
  )
}

const Title = styled.p`
  font-weight: bold;
  color: ${({ color }) => color || '#5d5d5d'};
  margin-bottom: 1em;
`

const CheckboxContainer = styled.div`
  padding: 0 1em;
  @media (max-width: 769px) {
    padding: 0px;
  }
`

const Container = styled.div`
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export default CampaignStatus
