import { formatNameFirstLetterUpperCase } from './formatters'

export const formatGuarantors = guarantors =>
  guarantors.map(
    ({
      guarantor_name,
      guarantor_document,
      guarantor_id_card,
      guarantor_marital_status,
      guarantor_marital_type,
      guarantor_zip_code,
      guarantor_street,
      guarantor_number,
      guarantor_neighborhood,
      guarantor_complement,
      guarantor_city,
      guarantor_uf,
      guarantor_type,
      guarantor_fantasy_name
    }) => {
      if (guarantor_type === 'PF') {
        const guarantorBody = {
          DocumentoFederal: guarantor_document.replace(/\D/g, ''),
          Nome: formatNameFirstLetterUpperCase(guarantor_name),
          CEP: guarantor_zip_code,
          Logradouro: guarantor_street,
          NroLogradouro: guarantor_number,
          Bairro: guarantor_neighborhood,
          Complemento: guarantor_complement,
          Cidade: guarantor_city,
          UF: guarantor_uf,
          AvalistaPF: {
            RG: guarantor_id_card,
            EstadoCivil: String(guarantor_marital_status),
            ...(Number(guarantor_marital_type) !== 4 && { TipoUniao: String(guarantor_marital_type) })
          }
        }
        if (!guarantorBody.Complemento) delete guarantorBody.Complemento
        return guarantorBody
      }

      const guarantorBody = {
        DocumentoFederal: guarantor_document.replace(/\D/g, ''),
        Nome: guarantor_name,
        CEP: guarantor_zip_code,
        Logradouro: guarantor_street,
        NroLogradouro: guarantor_number,
        Bairro: guarantor_neighborhood,
        Complemento: guarantor_complement,
        Cidade: guarantor_city,
        UF: guarantor_uf,
        AvalistaPJ: {
          NomeFantasia: guarantor_fantasy_name
        }
      }
      if (!guarantorBody.Complemento) delete guarantorBody.Complemento
      return guarantorBody
    }
  )

export const formatSubscribers = subscribers =>
  subscribers.map(
    ({
      subscriber_name,
      subscriber_email,
      subscriber_document,
      subscriber_phone_prefix,
      subscriber_phone,
      subscriber_description
    }) => ({
      Nome: subscriber_name,
      Email: subscriber_email,
      Documento: subscriber_document.replace(/\D/g, ''),
      Descricao: subscriber_description,
      TelefoneCelular: subscriber_phone_prefix + subscriber_phone,
      NotificarPorEmail: true
    })
  )

export const formatPartners = (partners, clientDocument) => {
  const documents = partners.map(({ partner_name, partner_document }) => ({
    Nome: formatNameFirstLetterUpperCase(partner_name),
    DocumentoFederal: partner_document.replace(/\D/g, '')
  }))

  return { DocumentoCliente: clientDocument.replace(/\D/g, ''), dtos: documents }
}

export const formatProposals = installments => {
  return installments.map(installment => {
    return {
      ...installment,
      Prazo: String(installment.Prazo),
      NroDiasAcrescimo: String(installment.NroDiasAcrescimo),
      VlrTAC: String(installment.VlrTAC),
      VlrIOF: String(installment.VlrIOF)
    }
  })
}
