import React, { Fragment, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { Input, Select } from '../../components'

import { CPF_LENGTH, CNPJ_LENGTH } from '../../constants'
import { getAddressByCep, getCompanyInfos, getUsers } from '../../services/v3'

const ProcessCampaignGuarantorsForm = ({
  setFieldValue,
  guarantor,
  index,
  values,
  maritalTypes,
  unionTypes,
  maritalTypesNotMarried,
  unionTypeSingle
}) => {
  useEffect(() => {
    searchGuarantor(!!guarantor.guarantor_document ? guarantor.guarantor_document : '')
  }, [])

  const handleZipCodeChange = async e => {
    setFieldValue(`guarantors[${index}].guarantor_zip_code`, e.target.value)
    const sanitizedZipCode = e.target.value.replace(/\D/g, '')
    if (sanitizedZipCode.length === 8) {
      try {
        const addressInfos = await getAddressByCep(sanitizedZipCode)
        setFieldValue(`guarantors[${index}].guarantor_city`, addressInfos.localidade)
        setFieldValue(`guarantors[${index}].guarantor_neighborhood`, addressInfos.bairro)
        setFieldValue(`guarantors[${index}].guarantor_street`, addressInfos.logradouro)
        setFieldValue(`guarantors[${index}].guarantor_uf`, addressInfos.uf)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar o endereço pelo CEP')
      }
    }
  }

  const searchGuarantor = async docNumber => {
    const sanitizedDocNumber = docNumber.replace(/\D/g, '')

    if (sanitizedDocNumber.length === CPF_LENGTH) {
      try {
        const {
          data: [user]
        } = await getUsers({ document_number: sanitizedDocNumber })

        if (!user) return

        const userInfos = {
          ...user,
          ...(user.signer ? user.signer : null),
          ...(user.investor ? user.investor : null)
        }

        setFieldValue(`guarantors[${index}].guarantor_type`, 'PF')
        setFieldValue(`guarantors[${index}].guarantor_name`, `${userInfos.first_name} ${userInfos.last_name}`)

        if (userInfos.marital_type) {
          const findMaritalType = maritalTypes.find(({ name }) => name === userInfos.marital_type.name)
          if (findMaritalType) {
            setFieldValue(`guarantors[${index}].guarantor_marital_status`, findMaritalType.id)
            setFieldValue(
              `guarantors[${index}].guarantor_marital_type`,
              maritalTypesNotMarried.includes(findMaritalType.id) && unionTypeSingle ? unionTypeSingle.id : ''
            )
          }
        } else {
          setFieldValue(`guarantors[${index}].guarantor_marital_status`, '')
          setFieldValue(`guarantors[${index}].guarantor_marital_type`, '')
        }

        if (userInfos.address_id) {
          if (!!userInfos.address.zip_code) {
            handleZipCodeChange({ target: { value: userInfos.address.zip_code } })
          }
          if (!!userInfos.address.number) {
            setFieldValue(`guarantors[${index}].guarantor_number`, userInfos.address.number)
          }
        } else {
          setFieldValue(`guarantors[${index}].guarantor_zip_code`, '')
          setFieldValue(`guarantors[${index}].guarantor_city`, '')
          setFieldValue(`guarantors[${index}].guarantor_neighborhood`, '')
          setFieldValue(`guarantors[${index}].guarantor_street`, '')
          setFieldValue(`guarantors[${index}].guarantor_uf`, '')
          setFieldValue(`guarantors[${index}].guarantor_number`, '')
        }

        setFieldValue(`guarantors[${index}].guarantor_id_card`, userInfos.rg || '')
      } catch (error) {
        console.error(error.message)
        toast.error('Ocorreu um erro ao buscar o avalista')
      }
    } else if (sanitizedDocNumber.length === CNPJ_LENGTH) {
      const companyInfo = await getCompanyInfos(sanitizedDocNumber).catch(() =>
        toast.error('Aguarde para fazer novas requisições!')
      )
      if (companyInfo && companyInfo.status !== 'ERROR') {
        setFieldValue(`guarantors[${index}].guarantor_type`, 'PJ')
        setFieldValue(`guarantors[${index}].guarantor_name`, companyInfo.nome)
        setFieldValue(`guarantors[${index}].guarantor_fantasy_name`, companyInfo.fantasia)
        setFieldValue(`guarantors[${index}].guarantor_zip_code`, companyInfo.cep)
        setFieldValue(`guarantors[${index}].guarantor_street`, companyInfo.logradouro)
        setFieldValue(`guarantors[${index}].guarantor_number`, companyInfo.numero)
        setFieldValue(`guarantors[${index}].guarantor_neighborhood`, companyInfo.bairro)
        setFieldValue(`guarantors[${index}].guarantor_complement`, companyInfo.complemento)
        setFieldValue(`guarantors[${index}].guarantor_city`, companyInfo.municipio)
        setFieldValue(`guarantors[${index}].guarantor_uf`, companyInfo.uf)
      }
    }
  }

  const handleDocumentChange = async e => {
    setFieldValue(`guarantors[${index}].guarantor_document`, e.target.value)
    searchGuarantor(e.target.value)
  }

  const renderFieldsPF = () => (
    <>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_marital_status`}
          render={({ field }) => {
            return (
              <Select
                label='Estado civil'
                field={{
                  ...field,
                  onChange: e => {
                    field.onChange(e)
                    if (maritalTypesNotMarried.includes(Number(e.target.value))) {
                      setFieldValue(`guarantors[${index}].guarantor_marital_type`, unionTypeSingle.id)
                    } else {
                      setFieldValue(`guarantors[${index}].guarantor_marital_type`, '')
                    }
                  }
                }}
              >
                <option value='' disabled />
                {maritalTypes.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            )
          }}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_marital_status`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_marital_type`}
          render={({ field }) => (
            <Select
              label='Tipo de união'
              type='text'
              field={field}
              disabled={maritalTypesNotMarried.includes(Number(values.guarantors[index].guarantor_marital_status))}
            >
              <option value='' disabled />
              {unionTypes.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          )}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_marital_type`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_id_card`}
          render={({ field }) => <Input label='RG' type='text' placeholder='RG' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_id_card`} component={Error} />
      </InputContainer>
    </>
  )

  const renderFieldsPJ = () => (
    <InputContainer>
      <Field
        name={`guarantors[${index}].guarantor_fantasy_name`}
        render={({ field }) => <Input label='Nome fantasia' type='text' {...field} />}
      />
      <ErrorMessage name={`guarantors[${index}].guarantor_fantasy_name`} component={Error} />
    </InputContainer>
  )

  return (
    <Fragment>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_type`}
          render={({ field }) => (
            <Select label='Tipo de avalista' field={field}>
              <option value='PF'>Pessoa física</option>
              <option value='PJ'>Pessoa jurídica</option>
            </Select>
          )}
        />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_document`}
          render={({ field }) => (
            <Input
              label='Documento Federal'
              type='text'
              placeholder='CPF/CNPJ'
              {...field}
              onChange={handleDocumentChange}
            />
          )}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_document`} component={Error} />
      </InputContainer>
      {guarantor.guarantor_type === 'PF' && renderFieldsPF()}
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_name`}
          render={({ field }) => <Input label='Nome' type='text' placeholder='João das neves...' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_name`} component={Error} />
      </InputContainer>
      {guarantor.guarantor_type === 'PJ' && renderFieldsPJ()}
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_zip_code`}
          render={({ field }) => (
            <Input
              label='CEP'
              type='text'
              {...field}
              mask='99999-999'
              onChange={e => handleZipCodeChange(e, `guarantors[${index}].guarantor_zip_code`)}
            />
          )}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_zip_code`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_number`}
          render={({ field }) => <Input label='Número' type='text' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_number`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_street`}
          render={({ field }) => <Input label='Logradouro' type='text' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_street`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_neighborhood`}
          render={({ field }) => <Input label='Bairro' type='text' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_neighborhood`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_complement`}
          render={({ field }) => <Input label='Complemento' type='text' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_complement`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_city`}
          render={({ field }) => <Input label='Cidade' type='text' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_city`} component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name={`guarantors[${index}].guarantor_uf`}
          render={({ field }) => <Input label='UF' type='text' {...field} />}
        />
        <ErrorMessage name={`guarantors[${index}].guarantor_uf`} component={Error} />
      </InputContainer>
    </Fragment>
  )
}

const InputContainer = styled.div`
  display: flex;
  width: 25%;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

export default ProcessCampaignGuarantorsForm
