export const cities = [
  { label: `${''}Alta Floresta D'oeste - RO` },
  { label: 'Ariquemes - RO' },
  { label: 'Cabixi - RO' },
  { label: 'Cacoal - RO' },
  { label: 'Cerejeiras - RO' },
  { label: 'Colorado Do Oeste - RO' },
  { label: 'Corumbiara - RO' },
  { label: 'Costa Marques - RO' },
  { label: `${''}Espigao D'oeste - RO` },
  { label: 'Guajara-mirim - RO' },
  { label: 'Jaru - RO' },
  { label: 'Ji-parana - RO' },
  { label: `${''}Machadinho D'oeste - RO` },
  { label: `${''}Nova Brasilandia D'oeste - RO` },
  { label: 'Ouro Preto Do Oeste - RO' },
  { label: 'Pimenta Bueno - RO' },
  { label: 'Porto Velho - RO' },
  { label: 'Presidente Medici - RO' },
  { label: 'Rio Crespo - RO' },
  { label: 'Rolim De Moura - RO' },
  { label: `${''}Santa Luzia D'oeste - RO` },
  { label: 'Vilhena - RO' },
  { label: 'Sao Miguel Do Guapore - RO' },
  { label: 'Nova Mamore - RO' },
  { label: `${''}Alvorada D'oeste - RO` },
  { label: 'Alto Alegre Dos Parecis - RO' },
  { label: 'Alto Paraiso - RO' },
  { label: 'Buritis - RO' },
  { label: 'Novo Horizonte Do Oeste - RO' },
  { label: 'Cacaulandia - RO' },
  { label: 'Campo Novo De Rondonia - RO' },
  { label: 'Candeias Do Jamari - RO' },
  { label: 'Castanheiras - RO' },
  { label: 'Chupinguaia - RO' },
  { label: 'Cujubim - RO' },
  { label: 'Governador Jorge Teixeira - RO' },
  { label: 'Itapua Do Oeste - RO' },
  { label: 'Ministro Andreazza - RO' },
  { label: 'Mirante Da Serra - RO' },
  { label: 'Monte Negro - RO' },
  { label: 'Nova Uniao - RO' },
  { label: 'Parecis - RO' },
  { label: 'Pimenteiras Do Oeste - RO' },
  { label: 'Primavera De Rondonia - RO' },
  { label: `${''}Sao Felipe D'oeste - RO` },
  { label: 'Sao Francisco Do Guapore - RO' },
  { label: 'Seringueiras - RO' },
  { label: 'Teixeiropolis - RO' },
  { label: 'Theobroma - RO' },
  { label: 'Urupa - RO' },
  { label: 'Vale Do Anari - RO' },
  { label: 'Vale Do Paraiso - RO' },
  { label: 'Acrelandia - AC' },
  { label: 'Assis Brasil - AC' },
  { label: 'Brasileia - AC' },
  { label: 'Bujari - AC' },
  { label: 'Capixaba - AC' },
  { label: 'Cruzeiro Do Sul - AC' },
  { label: 'Epitaciolandia - AC' },
  { label: 'Feijo - AC' },
  { label: 'Jordao - AC' },
  { label: 'Mancio Lima - AC' },
  { label: 'Manoel Urbano - AC' },
  { label: 'Marechal Thaumaturgo - AC' },
  { label: 'Placido De Castro - AC' },
  { label: 'Porto Walter - AC' },
  { label: 'Rio Branco - AC' },
  { label: 'Rodrigues Alves - AC' },
  { label: 'Santa Rosa Do Purus - AC' },
  { label: 'Senador Guiomard - AC' },
  { label: 'Sena Madureira - AC' },
  { label: 'Tarauaca - AC' },
  { label: 'Xapuri - AC' },
  { label: 'Porto Acre - AC' },
  { label: 'Alvaraes - AM' },
  { label: 'Amatura - AM' },
  { label: 'Anama - AM' },
  { label: 'Anori - AM' },
  { label: 'Apui - AM' },
  { label: 'Atalaia Do Norte - AM' },
  { label: 'Autazes - AM' },
  { label: 'Barcelos - AM' },
  { label: 'Barreirinha - AM' },
  { label: 'Benjamin Constant - AM' },
  { label: 'Beruri - AM' },
  { label: 'Boa Vista Do Ramos - AM' },
  { label: 'Boca Do Acre - AM' },
  { label: 'Borba - AM' },
  { label: 'Caapiranga - AM' },
  { label: 'Canutama - AM' },
  { label: 'Carauari - AM' },
  { label: 'Careiro - AM' },
  { label: 'Careiro Da Varzea - AM' },
  { label: 'Coari - AM' },
  { label: 'Codajas - AM' },
  { label: 'Eirunepe - AM' },
  { label: 'Envira - AM' },
  { label: 'Fonte Boa - AM' },
  { label: 'Guajara - AM' },
  { label: 'Humaita - AM' },
  { label: 'Ipixuna - AM' },
  { label: 'Iranduba - AM' },
  { label: 'Itacoatiara - AM' },
  { label: 'Itamarati - AM' },
  { label: 'Itapiranga - AM' },
  { label: 'Japura - AM' },
  { label: 'Jurua - AM' },
  { label: 'Jutai - AM' },
  { label: 'Labrea - AM' },
  { label: 'Manacapuru - AM' },
  { label: 'Manaquiri - AM' },
  { label: 'Manaus - AM' },
  { label: 'Manicore - AM' },
  { label: 'Maraa - AM' },
  { label: 'Maues - AM' },
  { label: 'Nhamunda - AM' },
  { label: 'Nova Olinda Do Norte - AM' },
  { label: 'Novo Airao - AM' },
  { label: 'Novo Aripuana - AM' },
  { label: 'Parintins - AM' },
  { label: 'Pauini - AM' },
  { label: 'Presidente Figueiredo - AM' },
  { label: 'Rio Preto Da Eva - AM' },
  { label: 'Santa Isabel Do Rio Negro - AM' },
  { label: 'Santo Antonio Do Ica - AM' },
  { label: 'Sao Gabriel Da Cachoeira - AM' },
  { label: 'Sao Paulo De Olivenca - AM' },
  { label: 'Sao Sebastiao Do Uatuma - AM' },
  { label: 'Silves - AM' },
  { label: 'Tabatinga - AM' },
  { label: 'Tapaua - AM' },
  { label: 'Tefe - AM' },
  { label: 'Tonantins - AM' },
  { label: 'Uarini - AM' },
  { label: 'Urucara - AM' },
  { label: 'Urucurituba - AM' },
  { label: 'Amajari - RR' },
  { label: 'Alto Alegre - RR' },
  { label: 'Boa Vista - RR' },
  { label: 'Bonfim - RR' },
  { label: 'Canta - RR' },
  { label: 'Caracarai - RR' },
  { label: 'Caroebe - RR' },
  { label: 'Iracema - RR' },
  { label: 'Mucajai - RR' },
  { label: 'Normandia - RR' },
  { label: 'Pacaraima - RR' },
  { label: 'Rorainopolis - RR' },
  { label: 'Sao Joao Da Baliza - RR' },
  { label: 'Sao Luiz - RR' },
  { label: 'Uiramuta - RR' },
  { label: 'Abaetetuba - PA' },
  { label: 'Abel Figueiredo - PA' },
  { label: 'Acara - PA' },
  { label: 'Afua - PA' },
  { label: 'Agua Azul Do Norte - PA' },
  { label: 'Alenquer - PA' },
  { label: 'Almeirim - PA' },
  { label: 'Altamira - PA' },
  { label: 'Anajas - PA' },
  { label: 'Ananindeua - PA' },
  { label: 'Anapu - PA' },
  { label: 'Augusto Correa - PA' },
  { label: 'Aurora Do Para - PA' },
  { label: 'Aveiro - PA' },
  { label: 'Bagre - PA' },
  { label: 'Baiao - PA' },
  { label: 'Bannach - PA' },
  { label: 'Barcarena - PA' },
  { label: 'Belem - PA' },
  { label: 'Belterra - PA' },
  { label: 'Benevides - PA' },
  { label: 'Bom Jesus Do Tocantins - PA' },
  { label: 'Bonito - PA' },
  { label: 'Braganca - PA' },
  { label: 'Brasil Novo - PA' },
  { label: 'Brejo Grande Do Araguaia - PA' },
  { label: 'Breu Branco - PA' },
  { label: 'Breves - PA' },
  { label: 'Bujaru - PA' },
  { label: 'Cachoeira Do Piria - PA' },
  { label: 'Cachoeira Do Arari - PA' },
  { label: 'Cameta - PA' },
  { label: 'Canaa Dos Carajas - PA' },
  { label: 'Capanema - PA' },
  { label: 'Capitao Poco - PA' },
  { label: 'Castanhal - PA' },
  { label: 'Chaves - PA' },
  { label: 'Colares - PA' },
  { label: 'Conceicao Do Araguaia - PA' },
  { label: 'Concordia Do Para - PA' },
  { label: 'Cumaru Do Norte - PA' },
  { label: 'Curionopolis - PA' },
  { label: 'Curralinho - PA' },
  { label: 'Curua - PA' },
  { label: 'Curuca - PA' },
  { label: 'Dom Eliseu - PA' },
  { label: 'Eldorado Dos Carajas - PA' },
  { label: 'Faro - PA' },
  { label: 'Floresta Do Araguaia - PA' },
  { label: 'Garrafao Do Norte - PA' },
  { label: 'Goianesia Do Para - PA' },
  { label: 'Gurupa - PA' },
  { label: 'Igarape-acu - PA' },
  { label: 'Igarape-miri - PA' },
  { label: 'Inhangapi - PA' },
  { label: 'Ipixuna Do Para - PA' },
  { label: 'Irituia - PA' },
  { label: 'Itaituba - PA' },
  { label: 'Itupiranga - PA' },
  { label: 'Jacareacanga - PA' },
  { label: 'Jacunda - PA' },
  { label: 'Juruti - PA' },
  { label: 'Limoeiro Do Ajuru - PA' },
  { label: 'Mae Do Rio - PA' },
  { label: 'Magalhaes Barata - PA' },
  { label: 'Maraba - PA' },
  { label: 'Maracana - PA' },
  { label: 'Marapanim - PA' },
  { label: 'Marituba - PA' },
  { label: 'Medicilandia - PA' },
  { label: 'Melgaco - PA' },
  { label: 'Mocajuba - PA' },
  { label: 'Moju - PA' },
  { label: 'Monte Alegre - PA' },
  { label: 'Muana - PA' },
  { label: 'Nova Esperanca Do Piria - PA' },
  { label: 'Nova Ipixuna - PA' },
  { label: 'Nova Timboteua - PA' },
  { label: 'Novo Progresso - PA' },
  { label: 'Novo Repartimento - PA' },
  { label: 'Obidos - PA' },
  { label: 'Oeiras Do Para - PA' },
  { label: 'Oriximina - PA' },
  { label: 'Ourem - PA' },
  { label: 'Ourilandia Do Norte - PA' },
  { label: 'Pacaja - PA' },
  { label: 'Palestina Do Para - PA' },
  { label: 'Paragominas - PA' },
  { label: 'Parauapebas - PA' },
  { label: `${''}Pau D'arco - PA` },
  { label: 'Peixe-boi - PA' },
  { label: 'Picarra - PA' },
  { label: 'Placas - PA' },
  { label: 'Ponta De Pedras - PA' },
  { label: 'Portel - PA' },
  { label: 'Porto De Moz - PA' },
  { label: 'Prainha - PA' },
  { label: 'Primavera - PA' },
  { label: 'Quatipuru - PA' },
  { label: 'Redencao - PA' },
  { label: 'Rio Maria - PA' },
  { label: 'Rondon Do Para - PA' },
  { label: 'Ruropolis - PA' },
  { label: 'Salinopolis - PA' },
  { label: 'Salvaterra - PA' },
  { label: 'Santa Barbara Do Para - PA' },
  { label: 'Santa Cruz Do Arari - PA' },
  { label: 'Santa Isabel Do Para - PA' },
  { label: 'Santa Luzia Do Para - PA' },
  { label: 'Santa Maria Das Barreiras - PA' },
  { label: 'Santa Maria Do Para - PA' },
  { label: 'Santana Do Araguaia - PA' },
  { label: 'Santarem - PA' },
  { label: 'Santarem Novo - PA' },
  { label: 'Santo Antonio Do Taua - PA' },
  { label: 'Sao Caetano De Odivelas - PA' },
  { label: 'Sao Domingos Do Araguaia - PA' },
  { label: 'Sao Domingos Do Capim - PA' },
  { label: 'Sao Felix Do Xingu - PA' },
  { label: 'Sao Francisco Do Para - PA' },
  { label: 'Sao Geraldo Do Araguaia - PA' },
  { label: 'Sao Joao Da Ponta - PA' },
  { label: 'Sao Joao De Pirabas - PA' },
  { label: 'Sao Joao Do Araguaia - PA' },
  { label: 'Sao Miguel Do Guama - PA' },
  { label: 'Sao Sebastiao Da Boa Vista - PA' },
  { label: 'Sapucaia - PA' },
  { label: 'Senador Jose Porfirio - PA' },
  { label: 'Soure - PA' },
  { label: 'Tailandia - PA' },
  { label: 'Terra Alta - PA' },
  { label: 'Terra Santa - PA' },
  { label: 'Tome-acu - PA' },
  { label: 'Tracuateua - PA' },
  { label: 'Trairao - PA' },
  { label: 'Tucuma - PA' },
  { label: 'Tucurui - PA' },
  { label: 'Ulianopolis - PA' },
  { label: 'Uruara - PA' },
  { label: 'Vigia - PA' },
  { label: 'Viseu - PA' },
  { label: 'Vitoria Do Xingu - PA' },
  { label: 'Xinguara - PA' },
  { label: 'Serra Do Navio - AP' },
  { label: 'Amapa - AP' },
  { label: 'Pedra Branca Do Amapari - AP' },
  { label: 'Calcoene - AP' },
  { label: 'Cutias - AP' },
  { label: 'Ferreira Gomes - AP' },
  { label: 'Itaubal - AP' },
  { label: 'Laranjal Do Jari - AP' },
  { label: 'Macapa - AP' },
  { label: 'Mazagao - AP' },
  { label: 'Oiapoque - AP' },
  { label: 'Porto Grande - AP' },
  { label: 'Pracuuba - AP' },
  { label: 'Santana - AP' },
  { label: 'Tartarugalzinho - AP' },
  { label: 'Vitoria Do Jari - AP' },
  { label: 'Abreulandia - TO' },
  { label: 'Aguiarnopolis - TO' },
  { label: 'Alianca Do Tocantins - TO' },
  { label: 'Almas - TO' },
  { label: 'Alvorada - TO' },
  { label: 'Ananas - TO' },
  { label: 'Angico - TO' },
  { label: 'Aparecida Do Rio Negro - TO' },
  { label: 'Aragominas - TO' },
  { label: 'Araguacema - TO' },
  { label: 'Araguacu - TO' },
  { label: 'Araguaina - TO' },
  { label: 'Araguana - TO' },
  { label: 'Araguatins - TO' },
  { label: 'Arapoema - TO' },
  { label: 'Arraias - TO' },
  { label: 'Augustinopolis - TO' },
  { label: 'Aurora Do Tocantins - TO' },
  { label: 'Axixa Do Tocantins - TO' },
  { label: 'Babaculandia - TO' },
  { label: 'Bandeirantes Do Tocantins - TO' },
  { label: 'Barra Do Ouro - TO' },
  { label: 'Barrolandia - TO' },
  { label: 'Bernardo Sayao - TO' },
  { label: 'Bom Jesus Do Tocantins - TO' },
  { label: 'Brasilandia Do Tocantins - TO' },
  { label: 'Brejinho De Nazare - TO' },
  { label: 'Buriti Do Tocantins - TO' },
  { label: 'Cachoeirinha - TO' },
  { label: 'Campos Lindos - TO' },
  { label: 'Cariri Do Tocantins - TO' },
  { label: 'Carmolandia - TO' },
  { label: 'Carrasco Bonito - TO' },
  { label: 'Caseara - TO' },
  { label: 'Centenario - TO' },
  { label: 'Chapada De Areia - TO' },
  { label: 'Chapada Da Natividade - TO' },
  { label: 'Colinas Do Tocantins - TO' },
  { label: 'Combinado - TO' },
  { label: 'Conceicao Do Tocantins - TO' },
  { label: 'Couto Magalhaes - TO' },
  { label: 'Cristalandia - TO' },
  { label: 'Crixas Do Tocantins - TO' },
  { label: 'Darcinopolis - TO' },
  { label: 'Dianopolis - TO' },
  { label: 'Divinopolis Do Tocantins - TO' },
  { label: 'Dois Irmaos Do Tocantins - TO' },
  { label: 'Duere - TO' },
  { label: 'Esperantina - TO' },
  { label: 'Fatima - TO' },
  { label: 'Figueiropolis - TO' },
  { label: 'Filadelfia - TO' },
  { label: 'Formoso Do Araguaia - TO' },
  { label: 'Fortaleza Do Tabocao - TO' },
  { label: 'Goianorte - TO' },
  { label: 'Goiatins - TO' },
  { label: 'Guarai - TO' },
  { label: 'Gurupi - TO' },
  { label: 'Ipueiras - TO' },
  { label: 'Itacaja - TO' },
  { label: 'Itaguatins - TO' },
  { label: 'Itapiratins - TO' },
  { label: 'Itapora Do Tocantins - TO' },
  { label: 'Jau Do Tocantins - TO' },
  { label: 'Juarina - TO' },
  { label: 'Lagoa Da Confusao - TO' },
  { label: 'Lagoa Do Tocantins - TO' },
  { label: 'Lajeado - TO' },
  { label: 'Lavandeira - TO' },
  { label: 'Lizarda - TO' },
  { label: 'Luzinopolis - TO' },
  { label: 'Marianopolis Do Tocantins - TO' },
  { label: 'Mateiros - TO' },
  { label: 'Maurilandia Do Tocantins - TO' },
  { label: 'Miracema Do Tocantins - TO' },
  { label: 'Miranorte - TO' },
  { label: 'Monte Do Carmo - TO' },
  { label: 'Monte Santo Do Tocantins - TO' },
  { label: 'Palmeiras Do Tocantins - TO' },
  { label: 'Muricilandia - TO' },
  { label: 'Natividade - TO' },
  { label: 'Nazare - TO' },
  { label: 'Nova Olinda - TO' },
  { label: 'Nova Rosalandia - TO' },
  { label: 'Novo Acordo - TO' },
  { label: 'Novo Alegre - TO' },
  { label: 'Novo Jardim - TO' },
  { label: 'Oliveira De Fatima - TO' },
  { label: 'Palmeirante - TO' },
  { label: 'Palmeiropolis - TO' },
  { label: 'Paraiso Do Tocantins - TO' },
  { label: 'Parana - TO' },
  { label: `${''}Pau D'arco - TO` },
  { label: 'Pedro Afonso - TO' },
  { label: 'Peixe - TO' },
  { label: 'Pequizeiro - TO' },
  { label: 'Colmeia - TO' },
  { label: 'Pindorama Do Tocantins - TO' },
  { label: 'Piraque - TO' },
  { label: 'Pium - TO' },
  { label: 'Ponte Alta Do Bom Jesus - TO' },
  { label: 'Ponte Alta Do Tocantins - TO' },
  { label: 'Porto Alegre Do Tocantins - TO' },
  { label: 'Porto Nacional - TO' },
  { label: 'Praia Norte - TO' },
  { label: 'Presidente Kennedy - TO' },
  { label: 'Pugmil - TO' },
  { label: 'Recursolandia - TO' },
  { label: 'Riachinho - TO' },
  { label: 'Rio Da Conceicao - TO' },
  { label: 'Rio Dos Bois - TO' },
  { label: 'Rio Sono - TO' },
  { label: 'Sampaio - TO' },
  { label: 'Sandolandia - TO' },
  { label: 'Santa Fe Do Araguaia - TO' },
  { label: 'Santa Maria Do Tocantins - TO' },
  { label: 'Santa Rita Do Tocantins - TO' },
  { label: 'Santa Rosa Do Tocantins - TO' },
  { label: 'Santa Tereza Do Tocantins - TO' },
  { label: 'Santa Terezinha Do Tocantins - TO' },
  { label: 'Sao Bento Do Tocantins - TO' },
  { label: 'Sao Felix Do Tocantins - TO' },
  { label: 'Sao Miguel Do Tocantins - TO' },
  { label: 'Sao Salvador Do Tocantins - TO' },
  { label: 'Sao Sebastiao Do Tocantins - TO' },
  { label: 'Sao Valerio Da Natividade - TO' },
  { label: 'Silvanopolis - TO' },
  { label: 'Sitio Novo Do Tocantins - TO' },
  { label: 'Sucupira - TO' },
  { label: 'Taguatinga - TO' },
  { label: 'Taipas Do Tocantins - TO' },
  { label: 'Talisma - TO' },
  { label: 'Palmas - TO' },
  { label: 'Tocantinia - TO' },
  { label: 'Tocantinopolis - TO' },
  { label: 'Tupirama - TO' },
  { label: 'Tupiratins - TO' },
  { label: 'Wanderlandia - TO' },
  { label: 'Xambioa - TO' },
  { label: 'Acailandia - MA' },
  { label: 'Afonso Cunha - MA' },
  { label: 'Agua Doce Do Maranhao - MA' },
  { label: 'Alcantara - MA' },
  { label: 'Aldeias Altas - MA' },
  { label: 'Altamira Do Maranhao - MA' },
  { label: 'Alto Alegre Do Maranhao - MA' },
  { label: 'Alto Alegre Do Pindare - MA' },
  { label: 'Alto Parnaiba - MA' },
  { label: 'Amapa Do Maranhao - MA' },
  { label: 'Amarante Do Maranhao - MA' },
  { label: 'Anajatuba - MA' },
  { label: 'Anapurus - MA' },
  { label: 'Apicum-acu - MA' },
  { label: 'Araguana - MA' },
  { label: 'Araioses - MA' },
  { label: 'Arame - MA' },
  { label: 'Arari - MA' },
  { label: 'Axixa - MA' },
  { label: 'Bacabal - MA' },
  { label: 'Bacabeira - MA' },
  { label: 'Bacuri - MA' },
  { label: 'Bacurituba - MA' },
  { label: 'Balsas - MA' },
  { label: 'Barao De Grajau - MA' },
  { label: 'Barra Do Corda - MA' },
  { label: 'Barreirinhas - MA' },
  { label: 'Belagua - MA' },
  { label: 'Bela Vista Do Maranhao - MA' },
  { label: 'Benedito Leite - MA' },
  { label: 'Bequimao - MA' },
  { label: 'Bernardo Do Mearim - MA' },
  { label: 'Boa Vista Do Gurupi - MA' },
  { label: 'Bom Jardim - MA' },
  { label: 'Bom Jesus Das Selvas - MA' },
  { label: 'Bom Lugar - MA' },
  { label: 'Brejo - MA' },
  { label: 'Brejo De Areia - MA' },
  { label: 'Buriti - MA' },
  { label: 'Buriti Bravo - MA' },
  { label: 'Buriticupu - MA' },
  { label: 'Buritirana - MA' },
  { label: 'Cachoeira Grande - MA' },
  { label: 'Cajapio - MA' },
  { label: 'Cajari - MA' },
  { label: 'Campestre Do Maranhao - MA' },
  { label: 'Candido Mendes - MA' },
  { label: 'Cantanhede - MA' },
  { label: 'Capinzal Do Norte - MA' },
  { label: 'Carolina - MA' },
  { label: 'Carutapera - MA' },
  { label: 'Caxias - MA' },
  { label: 'Cedral - MA' },
  { label: 'Central Do Maranhao - MA' },
  { label: 'Centro Do Guilherme - MA' },
  { label: 'Centro Novo Do Maranhao - MA' },
  { label: 'Chapadinha - MA' },
  { label: 'Cidelandia - MA' },
  { label: 'Codo - MA' },
  { label: 'Coelho Neto - MA' },
  { label: 'Colinas - MA' },
  { label: 'Conceicao Do Lago-acu - MA' },
  { label: 'Coroata - MA' },
  { label: 'Cururupu - MA' },
  { label: 'Davinopolis - MA' },
  { label: 'Dom Pedro - MA' },
  { label: 'Duque Bacelar - MA' },
  { label: 'Esperantinopolis - MA' },
  { label: 'Estreito - MA' },
  { label: 'Feira Nova Do Maranhao - MA' },
  { label: 'Fernando Falcao - MA' },
  { label: 'Formosa Da Serra Negra - MA' },
  { label: 'Fortaleza Dos Nogueiras - MA' },
  { label: 'Fortuna - MA' },
  { label: 'Godofredo Viana - MA' },
  { label: 'Goncalves Dias - MA' },
  { label: 'Governador Archer - MA' },
  { label: 'Governador Edison Lobao - MA' },
  { label: 'Governador Eugenio Barros - MA' },
  { label: 'Governador Luiz Rocha - MA' },
  { label: 'Governador Newton Bello - MA' },
  { label: 'Governador Nunes Freire - MA' },
  { label: 'Graca Aranha - MA' },
  { label: 'Grajau - MA' },
  { label: 'Guimaraes - MA' },
  { label: 'Humberto De Campos - MA' },
  { label: 'Icatu - MA' },
  { label: 'Igarape Do Meio - MA' },
  { label: 'Igarape Grande - MA' },
  { label: 'Imperatriz - MA' },
  { label: 'Itaipava Do Grajau - MA' },
  { label: 'Itapecuru Mirim - MA' },
  { label: 'Itinga Do Maranhao - MA' },
  { label: 'Jatoba - MA' },
  { label: 'Jenipapo Dos Vieiras - MA' },
  { label: 'Joao Lisboa - MA' },
  { label: 'Joselandia - MA' },
  { label: 'Junco Do Maranhao - MA' },
  { label: 'Lago Da Pedra - MA' },
  { label: 'Lago Do Junco - MA' },
  { label: 'Lago Verde - MA' },
  { label: 'Lagoa Do Mato - MA' },
  { label: 'Lago Dos Rodrigues - MA' },
  { label: 'Lagoa Grande Do Maranhao - MA' },
  { label: 'Lajeado Novo - MA' },
  { label: 'Lima Campos - MA' },
  { label: 'Loreto - MA' },
  { label: 'Luis Domingues - MA' },
  { label: 'Magalhaes De Almeida - MA' },
  { label: 'Maracacume - MA' },
  { label: 'Maraja Do Sena - MA' },
  { label: 'Maranhaozinho - MA' },
  { label: 'Mata Roma - MA' },
  { label: 'Matinha - MA' },
  { label: 'Matoes - MA' },
  { label: 'Matoes Do Norte - MA' },
  { label: 'Milagres Do Maranhao - MA' },
  { label: 'Mirador - MA' },
  { label: 'Miranda Do Norte - MA' },
  { label: 'Mirinzal - MA' },
  { label: 'Moncao - MA' },
  { label: 'Montes Altos - MA' },
  { label: 'Morros - MA' },
  { label: 'Nina Rodrigues - MA' },
  { label: 'Nova Colinas - MA' },
  { label: 'Nova Iorque - MA' },
  { label: 'Nova Olinda Do Maranhao - MA' },
  { label: `${''}Olho D'agua Das Cunhas - MA` },
  { label: 'Olinda Nova Do Maranhao - MA' },
  { label: 'Paco Do Lumiar - MA' },
  { label: 'Palmeirandia - MA' },
  { label: 'Paraibano - MA' },
  { label: 'Parnarama - MA' },
  { label: 'Passagem Franca - MA' },
  { label: 'Pastos Bons - MA' },
  { label: 'Paulino Neves - MA' },
  { label: 'Paulo Ramos - MA' },
  { label: 'Pedreiras - MA' },
  { label: 'Pedro Do Rosario - MA' },
  { label: 'Penalva - MA' },
  { label: 'Peri Mirim - MA' },
  { label: 'Peritoro - MA' },
  { label: 'Pindare-mirim - MA' },
  { label: 'Pinheiro - MA' },
  { label: 'Pio Xii - MA' },
  { label: 'Pirapemas - MA' },
  { label: 'Pocao De Pedras - MA' },
  { label: 'Porto Franco - MA' },
  { label: 'Porto Rico Do Maranhao - MA' },
  { label: 'Presidente Dutra - MA' },
  { label: 'Presidente Juscelino - MA' },
  { label: 'Presidente Medici - MA' },
  { label: 'Presidente Sarney - MA' },
  { label: 'Presidente Vargas - MA' },
  { label: 'Primeira Cruz - MA' },
  { label: 'Raposa - MA' },
  { label: 'Riachao - MA' },
  { label: 'Ribamar Fiquene - MA' },
  { label: 'Rosario - MA' },
  { label: 'Sambaiba - MA' },
  { label: 'Santa Filomena Do Maranhao - MA' },
  { label: 'Santa Helena - MA' },
  { label: 'Santa Ines - MA' },
  { label: 'Santa Luzia - MA' },
  { label: 'Santa Luzia Do Parua - MA' },
  { label: 'Santa Quiteria Do Maranhao - MA' },
  { label: 'Santa Rita - MA' },
  { label: 'Santana Do Maranhao - MA' },
  { label: 'Santo Amaro Do Maranhao - MA' },
  { label: 'Santo Antonio Dos Lopes - MA' },
  { label: 'Sao Benedito Do Rio Preto - MA' },
  { label: 'Sao Bento - MA' },
  { label: 'Sao Bernardo - MA' },
  { label: 'Sao Domingos Do Azeitao - MA' },
  { label: 'Sao Domingos Do Maranhao - MA' },
  { label: 'Sao Felix De Balsas - MA' },
  { label: 'Sao Francisco Do Brejao - MA' },
  { label: 'Sao Francisco Do Maranhao - MA' },
  { label: 'Sao Joao Batista - MA' },
  { label: 'Sao Joao Do Caru - MA' },
  { label: 'Sao Joao Do Paraiso - MA' },
  { label: 'Sao Joao Do Soter - MA' },
  { label: 'Sao Joao Dos Patos - MA' },
  { label: 'Sao Jose De Ribamar - MA' },
  { label: 'Sao Jose Dos Basilios - MA' },
  { label: 'Sao Luis - MA' },
  { label: 'Sao Luis Gonzaga Do Maranhao - MA' },
  { label: 'Sao Mateus Do Maranhao - MA' },
  { label: 'Sao Pedro Da Agua Branca - MA' },
  { label: 'Sao Pedro Dos Crentes - MA' },
  { label: 'Sao Raimundo Das Mangabeiras - MA' },
  { label: 'Sao Raimundo Do Doca Bezerra - MA' },
  { label: 'Sao Roberto - MA' },
  { label: 'Sao Vicente Ferrer - MA' },
  { label: 'Satubinha - MA' },
  { label: 'Senador Alexandre Costa - MA' },
  { label: 'Senador La Rocque - MA' },
  { label: 'Serrano Do Maranhao - MA' },
  { label: 'Sitio Novo - MA' },
  { label: 'Sucupira Do Norte - MA' },
  { label: 'Sucupira Do Riachao - MA' },
  { label: 'Tasso Fragoso - MA' },
  { label: 'Timbiras - MA' },
  { label: 'Timon - MA' },
  { label: 'Trizidela Do Vale - MA' },
  { label: 'Tufilandia - MA' },
  { label: 'Tuntum - MA' },
  { label: 'Turiacu - MA' },
  { label: 'Turilandia - MA' },
  { label: 'Tutoia - MA' },
  { label: 'Urbano Santos - MA' },
  { label: 'Vargem Grande - MA' },
  { label: 'Viana - MA' },
  { label: 'Vila Nova Dos Martirios - MA' },
  { label: 'Vitoria Do Mearim - MA' },
  { label: 'Vitorino Freire - MA' },
  { label: 'Ze Doca - MA' },
  { label: 'Acaua - PI' },
  { label: 'Agricolandia - PI' },
  { label: 'Agua Branca - PI' },
  { label: 'Alagoinha Do Piaui - PI' },
  { label: 'Alegrete Do Piaui - PI' },
  { label: 'Alto Longa - PI' },
  { label: 'Altos - PI' },
  { label: 'Alvorada Do Gurgueia - PI' },
  { label: 'Amarante - PI' },
  { label: 'Angical Do Piaui - PI' },
  { label: 'Anisio De Abreu - PI' },
  { label: 'Antonio Almeida - PI' },
  { label: 'Aroazes - PI' },
  { label: 'Aroeiras Do Itaim - PI' },
  { label: 'Arraial - PI' },
  { label: 'Assuncao Do Piaui - PI' },
  { label: 'Avelino Lopes - PI' },
  { label: 'Baixa Grande Do Ribeiro - PI' },
  { label: `${''}Barra D'alcantara - PI` },
  { label: 'Barras - PI' },
  { label: 'Barreiras Do Piaui - PI' },
  { label: 'Barro Duro - PI' },
  { label: 'Batalha - PI' },
  { label: 'Bela Vista Do Piaui - PI' },
  { label: 'Belem Do Piaui - PI' },
  { label: 'Beneditinos - PI' },
  { label: 'Bertolinia - PI' },
  { label: 'Betania Do Piaui - PI' },
  { label: 'Boa Hora - PI' },
  { label: 'Bocaina - PI' },
  { label: 'Bom Jesus - PI' },
  { label: 'Bom Principio Do Piaui - PI' },
  { label: 'Bonfim Do Piaui - PI' },
  { label: 'Boqueirao Do Piaui - PI' },
  { label: 'Brasileira - PI' },
  { label: 'Brejo Do Piaui - PI' },
  { label: 'Buriti Dos Lopes - PI' },
  { label: 'Buriti Dos Montes - PI' },
  { label: 'Cabeceiras Do Piaui - PI' },
  { label: 'Cajazeiras Do Piaui - PI' },
  { label: 'Cajueiro Da Praia - PI' },
  { label: 'Caldeirao Grande Do Piaui - PI' },
  { label: 'Campinas Do Piaui - PI' },
  { label: 'Campo Alegre Do Fidalgo - PI' },
  { label: 'Campo Grande Do Piaui - PI' },
  { label: 'Campo Largo Do Piaui - PI' },
  { label: 'Campo Maior - PI' },
  { label: 'Canavieira - PI' },
  { label: 'Canto Do Buriti - PI' },
  { label: 'Capitao De Campos - PI' },
  { label: 'Capitao Gervasio Oliveira - PI' },
  { label: 'Caracol - PI' },
  { label: 'Caraubas Do Piaui - PI' },
  { label: 'Caridade Do Piaui - PI' },
  { label: 'Castelo Do Piaui - PI' },
  { label: 'Caxingo - PI' },
  { label: 'Cocal - PI' },
  { label: 'Cocal De Telha - PI' },
  { label: 'Cocal Dos Alves - PI' },
  { label: 'Coivaras - PI' },
  { label: 'Colonia Do Gurgueia - PI' },
  { label: 'Colonia Do Piaui - PI' },
  { label: 'Conceicao Do Caninde - PI' },
  { label: 'Coronel Jose Dias - PI' },
  { label: 'Corrente - PI' },
  { label: 'Cristalandia Do Piaui - PI' },
  { label: 'Cristino Castro - PI' },
  { label: 'Curimata - PI' },
  { label: 'Currais - PI' },
  { label: 'Curralinhos - PI' },
  { label: 'Curral Novo Do Piaui - PI' },
  { label: 'Demerval Lobao - PI' },
  { label: 'Dirceu Arcoverde - PI' },
  { label: 'Dom Expedito Lopes - PI' },
  { label: 'Domingos Mourao - PI' },
  { label: 'Dom Inocencio - PI' },
  { label: 'Elesbao Veloso - PI' },
  { label: 'Eliseu Martins - PI' },
  { label: 'Esperantina - PI' },
  { label: 'Fartura Do Piaui - PI' },
  { label: 'Flores Do Piaui - PI' },
  { label: 'Floresta Do Piaui - PI' },
  { label: 'Floriano - PI' },
  { label: 'Francinopolis - PI' },
  { label: 'Francisco Ayres - PI' },
  { label: 'Francisco Macedo - PI' },
  { label: 'Francisco Santos - PI' },
  { label: 'Fronteiras - PI' },
  { label: 'Geminiano - PI' },
  { label: 'Gilbues - PI' },
  { label: 'Guadalupe - PI' },
  { label: 'Guaribas - PI' },
  { label: 'Hugo Napoleao - PI' },
  { label: 'Ilha Grande - PI' },
  { label: 'Inhuma - PI' },
  { label: 'Ipiranga Do Piaui - PI' },
  { label: 'Isaias Coelho - PI' },
  { label: 'Itainopolis - PI' },
  { label: 'Itaueira - PI' },
  { label: 'Jacobina Do Piaui - PI' },
  { label: 'Jaicos - PI' },
  { label: 'Jardim Do Mulato - PI' },
  { label: 'Jatoba Do Piaui - PI' },
  { label: 'Jerumenha - PI' },
  { label: 'Joao Costa - PI' },
  { label: 'Joaquim Pires - PI' },
  { label: 'Joca Marques - PI' },
  { label: 'Jose De Freitas - PI' },
  { label: 'Juazeiro Do Piaui - PI' },
  { label: 'Julio Borges - PI' },
  { label: 'Jurema - PI' },
  { label: 'Lagoinha Do Piaui - PI' },
  { label: 'Lagoa Alegre - PI' },
  { label: 'Lagoa Do Barro Do Piaui - PI' },
  { label: 'Lagoa De Sao Francisco - PI' },
  { label: 'Lagoa Do Piaui - PI' },
  { label: 'Lagoa Do Sitio - PI' },
  { label: 'Landri Sales - PI' },
  { label: 'Luis Correia - PI' },
  { label: 'Luzilandia - PI' },
  { label: 'Madeiro - PI' },
  { label: 'Manoel Emidio - PI' },
  { label: 'Marcolandia - PI' },
  { label: 'Marcos Parente - PI' },
  { label: 'Massape Do Piaui - PI' },
  { label: 'Matias Olimpio - PI' },
  { label: 'Miguel Alves - PI' },
  { label: 'Miguel Leao - PI' },
  { label: 'Milton Brandao - PI' },
  { label: 'Monsenhor Gil - PI' },
  { label: 'Monsenhor Hipolito - PI' },
  { label: 'Monte Alegre Do Piaui - PI' },
  { label: 'Morro Cabeca No Tempo - PI' },
  { label: 'Morro Do Chapeu Do Piaui - PI' },
  { label: 'Murici Dos Portelas - PI' },
  { label: 'Nazare Do Piaui - PI' },
  { label: 'Nazaria - PI' },
  { label: 'Nossa Senhora De Nazare - PI' },
  { label: 'Nossa Senhora Dos Remedios - PI' },
  { label: 'Novo Oriente Do Piaui - PI' },
  { label: 'Novo Santo Antonio - PI' },
  { label: 'Oeiras - PI' },
  { label: `${''}Olho D'agua Do Piaui - PI` },
  { label: 'Padre Marcos - PI' },
  { label: 'Paes Landim - PI' },
  { label: 'Pajeu Do Piaui - PI' },
  { label: 'Palmeira Do Piaui - PI' },
  { label: 'Palmeirais - PI' },
  { label: 'Paqueta - PI' },
  { label: 'Parnagua - PI' },
  { label: 'Parnaiba - PI' },
  { label: 'Passagem Franca Do Piaui - PI' },
  { label: 'Patos Do Piaui - PI' },
  { label: `${''}Pau D'arco Do Piaui - PI` },
  { label: 'Paulistana - PI' },
  { label: 'Pavussu - PI' },
  { label: 'Pedro Ii - PI' },
  { label: 'Pedro Laurentino - PI' },
  { label: 'Nova Santa Rita - PI' },
  { label: 'Picos - PI' },
  { label: 'Pimenteiras - PI' },
  { label: 'Pio Ix - PI' },
  { label: 'Piracuruca - PI' },
  { label: 'Piripiri - PI' },
  { label: 'Porto - PI' },
  { label: 'Porto Alegre Do Piaui - PI' },
  { label: 'Prata Do Piaui - PI' },
  { label: 'Queimada Nova - PI' },
  { label: 'Redencao Do Gurgueia - PI' },
  { label: 'Regeneracao - PI' },
  { label: 'Riacho Frio - PI' },
  { label: 'Ribeira Do Piaui - PI' },
  { label: 'Ribeiro Goncalves - PI' },
  { label: 'Rio Grande Do Piaui - PI' },
  { label: 'Santa Cruz Do Piaui - PI' },
  { label: 'Santa Cruz Dos Milagres - PI' },
  { label: 'Santa Filomena - PI' },
  { label: 'Santa Luz - PI' },
  { label: 'Santana Do Piaui - PI' },
  { label: 'Santa Rosa Do Piaui - PI' },
  { label: 'Santo Antonio De Lisboa - PI' },
  { label: 'Santo Antonio Dos Milagres - PI' },
  { label: 'Santo Inacio Do Piaui - PI' },
  { label: 'Sao Braz Do Piaui - PI' },
  { label: 'Sao Felix Do Piaui - PI' },
  { label: 'Sao Francisco De Assis Do Piaui - PI' },
  { label: 'Sao Francisco Do Piaui - PI' },
  { label: 'Sao Goncalo Do Gurgueia - PI' },
  { label: 'Sao Goncalo Do Piaui - PI' },
  { label: 'Sao Joao Da Canabrava - PI' },
  { label: 'Sao Joao Da Fronteira - PI' },
  { label: 'Sao Joao Da Serra - PI' },
  { label: 'Sao Joao Da Varjota - PI' },
  { label: 'Sao Joao Do Arraial - PI' },
  { label: 'Sao Joao Do Piaui - PI' },
  { label: 'Sao Jose Do Divino - PI' },
  { label: 'Sao Jose Do Peixe - PI' },
  { label: 'Sao Jose Do Piaui - PI' },
  { label: 'Sao Juliao - PI' },
  { label: 'Sao Lourenco Do Piaui - PI' },
  { label: 'Sao Luis Do Piaui - PI' },
  { label: 'Sao Miguel Da Baixa Grande - PI' },
  { label: 'Sao Miguel Do Fidalgo - PI' },
  { label: 'Sao Miguel Do Tapuio - PI' },
  { label: 'Sao Pedro Do Piaui - PI' },
  { label: 'Sao Raimundo Nonato - PI' },
  { label: 'Sebastiao Barros - PI' },
  { label: 'Sebastiao Leal - PI' },
  { label: 'Sigefredo Pacheco - PI' },
  { label: 'Simoes - PI' },
  { label: 'Simplicio Mendes - PI' },
  { label: 'Socorro Do Piaui - PI' },
  { label: 'Sussuapara - PI' },
  { label: 'Tamboril Do Piaui - PI' },
  { label: 'Tanque Do Piaui - PI' },
  { label: 'Teresina - PI' },
  { label: 'Uniao - PI' },
  { label: 'Urucui - PI' },
  { label: 'Valenca Do Piaui - PI' },
  { label: 'Varzea Branca - PI' },
  { label: 'Varzea Grande - PI' },
  { label: 'Vera Mendes - PI' },
  { label: 'Vila Nova Do Piaui - PI' },
  { label: 'Wall Ferraz - PI' },
  { label: 'Abaiara - CE' },
  { label: 'Acarape - CE' },
  { label: 'Acarau - CE' },
  { label: 'Acopiara - CE' },
  { label: 'Aiuaba - CE' },
  { label: 'Alcantaras - CE' },
  { label: 'Altaneira - CE' },
  { label: 'Alto Santo - CE' },
  { label: 'Amontada - CE' },
  { label: 'Antonina Do Norte - CE' },
  { label: 'Apuiares - CE' },
  { label: 'Aquiraz - CE' },
  { label: 'Aracati - CE' },
  { label: 'Aracoiaba - CE' },
  { label: 'Ararenda - CE' },
  { label: 'Araripe - CE' },
  { label: 'Aratuba - CE' },
  { label: 'Arneiroz - CE' },
  { label: 'Assare - CE' },
  { label: 'Aurora - CE' },
  { label: 'Baixio - CE' },
  { label: 'Banabuiu - CE' },
  { label: 'Barbalha - CE' },
  { label: 'Barreira - CE' },
  { label: 'Barro - CE' },
  { label: 'Barroquinha - CE' },
  { label: 'Baturite - CE' },
  { label: 'Beberibe - CE' },
  { label: 'Bela Cruz - CE' },
  { label: 'Boa Viagem - CE' },
  { label: 'Brejo Santo - CE' },
  { label: 'Camocim - CE' },
  { label: 'Campos Sales - CE' },
  { label: 'Caninde - CE' },
  { label: 'Capistrano - CE' },
  { label: 'Caridade - CE' },
  { label: 'Carire - CE' },
  { label: 'Caririacu - CE' },
  { label: 'Carius - CE' },
  { label: 'Carnaubal - CE' },
  { label: 'Cascavel - CE' },
  { label: 'Catarina - CE' },
  { label: 'Catunda - CE' },
  { label: 'Caucaia - CE' },
  { label: 'Cedro - CE' },
  { label: 'Chaval - CE' },
  { label: 'Choro - CE' },
  { label: 'Chorozinho - CE' },
  { label: 'Coreau - CE' },
  { label: 'Crateus - CE' },
  { label: 'Crato - CE' },
  { label: 'Croata - CE' },
  { label: 'Cruz - CE' },
  { label: 'Deputado Irapuan Pinheiro - CE' },
  { label: 'Erere - CE' },
  { label: 'Eusebio - CE' },
  { label: 'Farias Brito - CE' },
  { label: 'Forquilha - CE' },
  { label: 'Fortaleza - CE' },
  { label: 'Fortim - CE' },
  { label: 'Frecheirinha - CE' },
  { label: 'General Sampaio - CE' },
  { label: 'Graca - CE' },
  { label: 'Granja - CE' },
  { label: 'Granjeiro - CE' },
  { label: 'Groairas - CE' },
  { label: 'Guaiuba - CE' },
  { label: 'Guaraciaba Do Norte - CE' },
  { label: 'Guaramiranga - CE' },
  { label: 'Hidrolandia - CE' },
  { label: 'Horizonte - CE' },
  { label: 'Ibaretama - CE' },
  { label: 'Ibiapina - CE' },
  { label: 'Ibicuitinga - CE' },
  { label: 'Icapui - CE' },
  { label: 'Ico - CE' },
  { label: 'Iguatu - CE' },
  { label: 'Independencia - CE' },
  { label: 'Ipaporanga - CE' },
  { label: 'Ipaumirim - CE' },
  { label: 'Ipu - CE' },
  { label: 'Ipueiras - CE' },
  { label: 'Iracema - CE' },
  { label: 'Iraucuba - CE' },
  { label: 'Itaicaba - CE' },
  { label: 'Itaitinga - CE' },
  { label: 'Itapage - CE' },
  { label: 'Itapipoca - CE' },
  { label: 'Itapiuna - CE' },
  { label: 'Itarema - CE' },
  { label: 'Itatira - CE' },
  { label: 'Jaguaretama - CE' },
  { label: 'Jaguaribara - CE' },
  { label: 'Jaguaribe - CE' },
  { label: 'Jaguaruana - CE' },
  { label: 'Jardim - CE' },
  { label: 'Jati - CE' },
  { label: 'Jijoca De Jericoacoara - CE' },
  { label: 'Juazeiro Do Norte - CE' },
  { label: 'Jucas - CE' },
  { label: 'Lavras Da Mangabeira - CE' },
  { label: 'Limoeiro Do Norte - CE' },
  { label: 'Madalena - CE' },
  { label: 'Maracanau - CE' },
  { label: 'Maranguape - CE' },
  { label: 'Marco - CE' },
  { label: 'Martinopole - CE' },
  { label: 'Massape - CE' },
  { label: 'Mauriti - CE' },
  { label: 'Meruoca - CE' },
  { label: 'Milagres - CE' },
  { label: 'Milha - CE' },
  { label: 'Miraima - CE' },
  { label: 'Missao Velha - CE' },
  { label: 'Mombaca - CE' },
  { label: 'Monsenhor Tabosa - CE' },
  { label: 'Morada Nova - CE' },
  { label: 'Moraujo - CE' },
  { label: 'Morrinhos - CE' },
  { label: 'Mucambo - CE' },
  { label: 'Mulungu - CE' },
  { label: 'Nova Olinda - CE' },
  { label: 'Nova Russas - CE' },
  { label: 'Novo Oriente - CE' },
  { label: 'Ocara - CE' },
  { label: 'Oros - CE' },
  { label: 'Pacajus - CE' },
  { label: 'Pacatuba - CE' },
  { label: 'Pacoti - CE' },
  { label: 'Pacuja - CE' },
  { label: 'Palhano - CE' },
  { label: 'Palmacia - CE' },
  { label: 'Paracuru - CE' },
  { label: 'Paraipaba - CE' },
  { label: 'Parambu - CE' },
  { label: 'Paramoti - CE' },
  { label: 'Pedra Branca - CE' },
  { label: 'Penaforte - CE' },
  { label: 'Pentecoste - CE' },
  { label: 'Pereiro - CE' },
  { label: 'Pindoretama - CE' },
  { label: 'Piquet Carneiro - CE' },
  { label: 'Pires Ferreira - CE' },
  { label: 'Poranga - CE' },
  { label: 'Porteiras - CE' },
  { label: 'Potengi - CE' },
  { label: 'Potiretama - CE' },
  { label: 'Quiterianopolis - CE' },
  { label: 'Quixada - CE' },
  { label: 'Quixelo - CE' },
  { label: 'Quixeramobim - CE' },
  { label: 'Quixere - CE' },
  { label: 'Redencao - CE' },
  { label: 'Reriutaba - CE' },
  { label: 'Russas - CE' },
  { label: 'Saboeiro - CE' },
  { label: 'Salitre - CE' },
  { label: 'Santana Do Acarau - CE' },
  { label: 'Santana Do Cariri - CE' },
  { label: 'Santa Quiteria - CE' },
  { label: 'Sao Benedito - CE' },
  { label: 'Sao Goncalo Do Amarante - CE' },
  { label: 'Sao Joao Do Jaguaribe - CE' },
  { label: 'Sao Luis Do Curu - CE' },
  { label: 'Senador Pompeu - CE' },
  { label: 'Senador Sa - CE' },
  { label: 'Sobral - CE' },
  { label: 'Solonopole - CE' },
  { label: 'Tabuleiro Do Norte - CE' },
  { label: 'Tamboril - CE' },
  { label: 'Tarrafas - CE' },
  { label: 'Taua - CE' },
  { label: 'Tejucuoca - CE' },
  { label: 'Tiangua - CE' },
  { label: 'Trairi - CE' },
  { label: 'Tururu - CE' },
  { label: 'Ubajara - CE' },
  { label: 'Umari - CE' },
  { label: 'Umirim - CE' },
  { label: 'Uruburetama - CE' },
  { label: 'Uruoca - CE' },
  { label: 'Varjota - CE' },
  { label: 'Varzea Alegre - CE' },
  { label: 'Vicosa Do Ceara - CE' },
  { label: 'Acari - RN' },
  { label: 'Acu - RN' },
  { label: 'Afonso Bezerra - RN' },
  { label: 'Agua Nova - RN' },
  { label: 'Alexandria - RN' },
  { label: 'Almino Afonso - RN' },
  { label: 'Alto Do Rodrigues - RN' },
  { label: 'Angicos - RN' },
  { label: 'Antonio Martins - RN' },
  { label: 'Apodi - RN' },
  { label: 'Areia Branca - RN' },
  { label: 'Ares - RN' },
  { label: 'Augusto Severo - RN' },
  { label: 'Baia Formosa - RN' },
  { label: 'Barauna - RN' },
  { label: 'Barcelona - RN' },
  { label: 'Bento Fernandes - RN' },
  { label: 'Bodo - RN' },
  { label: 'Bom Jesus - RN' },
  { label: 'Brejinho - RN' },
  { label: 'Caicara Do Norte - RN' },
  { label: 'Caicara Do Rio Do Vento - RN' },
  { label: 'Caico - RN' },
  { label: 'Campo Redondo - RN' },
  { label: 'Canguaretama - RN' },
  { label: 'Caraubas - RN' },
  { label: 'Carnauba Dos Dantas - RN' },
  { label: 'Carnaubais - RN' },
  { label: 'Ceara-mirim - RN' },
  { label: 'Cerro Cora - RN' },
  { label: 'Coronel Ezequiel - RN' },
  { label: 'Coronel Joao Pessoa - RN' },
  { label: 'Cruzeta - RN' },
  { label: 'Currais Novos - RN' },
  { label: 'Doutor Severiano - RN' },
  { label: 'Parnamirim - RN' },
  { label: 'Encanto - RN' },
  { label: 'Equador - RN' },
  { label: 'Espirito Santo - RN' },
  { label: 'Extremoz - RN' },
  { label: 'Felipe Guerra - RN' },
  { label: 'Fernando Pedroza - RN' },
  { label: 'Florania - RN' },
  { label: 'Francisco Dantas - RN' },
  { label: 'Frutuoso Gomes - RN' },
  { label: 'Galinhos - RN' },
  { label: 'Goianinha - RN' },
  { label: 'Governador Dix-sept Rosado - RN' },
  { label: 'Grossos - RN' },
  { label: 'Guamare - RN' },
  { label: 'Ielmo Marinho - RN' },
  { label: 'Ipanguacu - RN' },
  { label: 'Ipueira - RN' },
  { label: 'Itaja - RN' },
  { label: 'Itau - RN' },
  { label: 'Jacana - RN' },
  { label: 'Jandaira - RN' },
  { label: 'Janduis - RN' },
  { label: 'Januario Cicco - RN' },
  { label: 'Japi - RN' },
  { label: 'Jardim De Angicos - RN' },
  { label: 'Jardim De Piranhas - RN' },
  { label: 'Jardim Do Serido - RN' },
  { label: 'Joao Camara - RN' },
  { label: 'Joao Dias - RN' },
  { label: 'Jose Da Penha - RN' },
  { label: 'Jucurutu - RN' },
  { label: 'Jundia - RN' },
  { label: `${''}Lagoa D'anta - RN` },
  { label: 'Lagoa De Pedras - RN' },
  { label: 'Lagoa De Velhos - RN' },
  { label: 'Lagoa Nova - RN' },
  { label: 'Lagoa Salgada - RN' },
  { label: 'Lajes - RN' },
  { label: 'Lajes Pintadas - RN' },
  { label: 'Lucrecia - RN' },
  { label: 'Luis Gomes - RN' },
  { label: 'Macaiba - RN' },
  { label: 'Macau - RN' },
  { label: 'Major Sales - RN' },
  { label: 'Marcelino Vieira - RN' },
  { label: 'Martins - RN' },
  { label: 'Maxaranguape - RN' },
  { label: 'Messias Targino - RN' },
  { label: 'Montanhas - RN' },
  { label: 'Monte Alegre - RN' },
  { label: 'Monte Das Gameleiras - RN' },
  { label: 'Mossoro - RN' },
  { label: 'Natal - RN' },
  { label: 'Nisia Floresta - RN' },
  { label: 'Nova Cruz - RN' },
  { label: `${''}Olho D'agua Do Borges - RN` },
  { label: 'Ouro Branco - RN' },
  { label: 'Parana - RN' },
  { label: 'Parau - RN' },
  { label: 'Parazinho - RN' },
  { label: 'Parelhas - RN' },
  { label: 'Rio Do Fogo - RN' },
  { label: 'Passa E Fica - RN' },
  { label: 'Passagem - RN' },
  { label: 'Patu - RN' },
  { label: 'Santa Maria - RN' },
  { label: 'Pau Dos Ferros - RN' },
  { label: 'Pedra Grande - RN' },
  { label: 'Pedra Preta - RN' },
  { label: 'Pedro Avelino - RN' },
  { label: 'Pedro Velho - RN' },
  { label: 'Pendencias - RN' },
  { label: 'Piloes - RN' },
  { label: 'Poco Branco - RN' },
  { label: 'Portalegre - RN' },
  { label: 'Porto Do Mangue - RN' },
  { label: 'Presidente Juscelino - RN' },
  { label: 'Pureza - RN' },
  { label: 'Rafael Fernandes - RN' },
  { label: 'Rafael Godeiro - RN' },
  { label: 'Riacho Da Cruz - RN' },
  { label: 'Riacho De Santana - RN' },
  { label: 'Riachuelo - RN' },
  { label: 'Rodolfo Fernandes - RN' },
  { label: 'Tibau - RN' },
  { label: 'Ruy Barbosa - RN' },
  { label: 'Santa Cruz - RN' },
  { label: 'Santana Do Matos - RN' },
  { label: 'Santana Do Serido - RN' },
  { label: 'Santo Antonio - RN' },
  { label: 'Sao Bento Do Norte - RN' },
  { label: 'Sao Bento Do Trairi - RN' },
  { label: 'Sao Fernando - RN' },
  { label: 'Sao Francisco Do Oeste - RN' },
  { label: 'Sao Goncalo Do Amarante - RN' },
  { label: 'Sao Joao Do Sabugi - RN' },
  { label: 'Sao Jose De Mipibu - RN' },
  { label: 'Sao Jose Do Campestre - RN' },
  { label: 'Sao Jose Do Serido - RN' },
  { label: 'Sao Miguel - RN' },
  { label: 'Sao Miguel Do Gostoso - RN' },
  { label: 'Sao Paulo Do Potengi - RN' },
  { label: 'Sao Pedro - RN' },
  { label: 'Sao Rafael - RN' },
  { label: 'Sao Tome - RN' },
  { label: 'Sao Vicente - RN' },
  { label: 'Senador Eloi De Souza - RN' },
  { label: 'Senador Georgino Avelino - RN' },
  { label: 'Serra De Sao Bento - RN' },
  { label: 'Serra Do Mel - RN' },
  { label: 'Serra Negra Do Norte - RN' },
  { label: 'Serrinha - RN' },
  { label: 'Serrinha Dos Pintos - RN' },
  { label: 'Severiano Melo - RN' },
  { label: 'Sitio Novo - RN' },
  { label: 'Taboleiro Grande - RN' },
  { label: 'Taipu - RN' },
  { label: 'Tangara - RN' },
  { label: 'Tenente Ananias - RN' },
  { label: 'Tenente Laurentino Cruz - RN' },
  { label: 'Tibau Do Sul - RN' },
  { label: 'Timbauba Dos Batistas - RN' },
  { label: 'Touros - RN' },
  { label: 'Triunfo Potiguar - RN' },
  { label: 'Umarizal - RN' },
  { label: 'Upanema - RN' },
  { label: 'Varzea - RN' },
  { label: 'Venha-ver - RN' },
  { label: 'Vera Cruz - RN' },
  { label: 'Vicosa - RN' },
  { label: 'Vila Flor - RN' },
  { label: 'Agua Branca - PB' },
  { label: 'Aguiar - PB' },
  { label: 'Alagoa Grande - PB' },
  { label: 'Alagoa Nova - PB' },
  { label: 'Alagoinha - PB' },
  { label: 'Alcantil - PB' },
  { label: 'Algodao De Jandaira - PB' },
  { label: 'Alhandra - PB' },
  { label: 'Sao Joao Do Rio Do Peixe - PB' },
  { label: 'Amparo - PB' },
  { label: 'Aparecida - PB' },
  { label: 'Aracagi - PB' },
  { label: 'Arara - PB' },
  { label: 'Araruna - PB' },
  { label: 'Areia - PB' },
  { label: 'Areia De Baraunas - PB' },
  { label: 'Areial - PB' },
  { label: 'Aroeiras - PB' },
  { label: 'Assuncao - PB' },
  { label: 'Baia Da Traicao - PB' },
  { label: 'Bananeiras - PB' },
  { label: 'Barauna - PB' },
  { label: 'Barra De Santana - PB' },
  { label: 'Barra De Santa Rosa - PB' },
  { label: 'Barra De Sao Miguel - PB' },
  { label: 'Bayeux - PB' },
  { label: 'Belem - PB' },
  { label: 'Belem Do Brejo Do Cruz - PB' },
  { label: 'Bernardino Batista - PB' },
  { label: 'Boa Ventura - PB' },
  { label: 'Boa Vista - PB' },
  { label: 'Bom Jesus - PB' },
  { label: 'Bom Sucesso - PB' },
  { label: 'Bonito De Santa Fe - PB' },
  { label: 'Boqueirao - PB' },
  { label: 'Igaracy - PB' },
  { label: 'Borborema - PB' },
  { label: 'Brejo Do Cruz - PB' },
  { label: 'Brejo Dos Santos - PB' },
  { label: 'Caapora - PB' },
  { label: 'Cabaceiras - PB' },
  { label: 'Cabedelo - PB' },
  { label: 'Cachoeira Dos Indios - PB' },
  { label: 'Cacimba De Areia - PB' },
  { label: 'Cacimba De Dentro - PB' },
  { label: 'Cacimbas - PB' },
  { label: 'Caicara - PB' },
  { label: 'Cajazeiras - PB' },
  { label: 'Cajazeirinhas - PB' },
  { label: 'Caldas Brandao - PB' },
  { label: 'Camalau - PB' },
  { label: 'Campina Grande - PB' },
  { label: 'Capim - PB' },
  { label: 'Caraubas - PB' },
  { label: 'Carrapateira - PB' },
  { label: 'Casserengue - PB' },
  { label: 'Catingueira - PB' },
  { label: 'Catole Do Rocha - PB' },
  { label: 'Caturite - PB' },
  { label: 'Conceicao - PB' },
  { label: 'Condado - PB' },
  { label: 'Conde - PB' },
  { label: 'Congo - PB' },
  { label: 'Coremas - PB' },
  { label: 'Coxixola - PB' },
  { label: 'Cruz Do Espirito Santo - PB' },
  { label: 'Cubati - PB' },
  { label: 'Cuite - PB' },
  { label: 'Cuitegi - PB' },
  { label: 'Cuite De Mamanguape - PB' },
  { label: 'Curral De Cima - PB' },
  { label: 'Curral Velho - PB' },
  { label: 'Damiao - PB' },
  { label: 'Desterro - PB' },
  { label: 'Vista Serrana - PB' },
  { label: 'Diamante - PB' },
  { label: 'Dona Ines - PB' },
  { label: 'Duas Estradas - PB' },
  { label: 'Emas - PB' },
  { label: 'Esperanca - PB' },
  { label: 'Fagundes - PB' },
  { label: 'Frei Martinho - PB' },
  { label: 'Gado Bravo - PB' },
  { label: 'Guarabira - PB' },
  { label: 'Gurinhem - PB' },
  { label: 'Gurjao - PB' },
  { label: 'Ibiara - PB' },
  { label: 'Imaculada - PB' },
  { label: 'Inga - PB' },
  { label: 'Itabaiana - PB' },
  { label: 'Itaporanga - PB' },
  { label: 'Itapororoca - PB' },
  { label: 'Itatuba - PB' },
  { label: 'Jacarau - PB' },
  { label: 'Jerico - PB' },
  { label: 'Joao Pessoa - PB' },
  { label: 'Juarez Tavora - PB' },
  { label: 'Juazeirinho - PB' },
  { label: 'Junco Do Serido - PB' },
  { label: 'Juripiranga - PB' },
  { label: 'Juru - PB' },
  { label: 'Lagoa - PB' },
  { label: 'Lagoa De Dentro - PB' },
  { label: 'Lagoa Seca - PB' },
  { label: 'Lastro - PB' },
  { label: 'Livramento - PB' },
  { label: 'Logradouro - PB' },
  { label: 'Lucena - PB' },
  { label: `${''}Mae D'agua - PB` },
  { label: 'Malta - PB' },
  { label: 'Mamanguape - PB' },
  { label: 'Manaira - PB' },
  { label: 'Marcacao - PB' },
  { label: 'Mari - PB' },
  { label: 'Marizopolis - PB' },
  { label: 'Massaranduba - PB' },
  { label: 'Mataraca - PB' },
  { label: 'Matinhas - PB' },
  { label: 'Mato Grosso - PB' },
  { label: 'Matureia - PB' },
  { label: 'Mogeiro - PB' },
  { label: 'Montadas - PB' },
  { label: 'Monte Horebe - PB' },
  { label: 'Monteiro - PB' },
  { label: 'Mulungu - PB' },
  { label: 'Natuba - PB' },
  { label: 'Nazarezinho - PB' },
  { label: 'Nova Floresta - PB' },
  { label: 'Nova Olinda - PB' },
  { label: 'Nova Palmeira - PB' },
  { label: `${''}Olho D'agua - PB` },
  { label: 'Olivedos - PB' },
  { label: 'Ouro Velho - PB' },
  { label: 'Parari - PB' },
  { label: 'Passagem - PB' },
  { label: 'Patos - PB' },
  { label: 'Paulista - PB' },
  { label: 'Pedra Branca - PB' },
  { label: 'Pedra Lavrada - PB' },
  { label: 'Pedras De Fogo - PB' },
  { label: 'Pianco - PB' },
  { label: 'Picui - PB' },
  { label: 'Pilar - PB' },
  { label: 'Piloes - PB' },
  { label: 'Piloezinhos - PB' },
  { label: 'Pirpirituba - PB' },
  { label: 'Pitimbu - PB' },
  { label: 'Pocinhos - PB' },
  { label: 'Poco Dantas - PB' },
  { label: 'Poco De Jose De Moura - PB' },
  { label: 'Pombal - PB' },
  { label: 'Prata - PB' },
  { label: 'Princesa Isabel - PB' },
  { label: 'Puxinana - PB' },
  { label: 'Queimadas - PB' },
  { label: 'Quixaba - PB' },
  { label: 'Remigio - PB' },
  { label: 'Pedro Regis - PB' },
  { label: 'Riachao - PB' },
  { label: 'Riachao Do Bacamarte - PB' },
  { label: 'Riachao Do Poco - PB' },
  { label: 'Riacho De Santo Antonio - PB' },
  { label: 'Riacho Dos Cavalos - PB' },
  { label: 'Rio Tinto - PB' },
  { label: 'Salgadinho - PB' },
  { label: 'Salgado De Sao Felix - PB' },
  { label: 'Santa Cecilia - PB' },
  { label: 'Santa Cruz - PB' },
  { label: 'Santa Helena - PB' },
  { label: 'Santa Ines - PB' },
  { label: 'Santa Luzia - PB' },
  { label: 'Santana De Mangueira - PB' },
  { label: 'Santana Dos Garrotes - PB' },
  { label: 'Santarem - PB' },
  { label: 'Santa Rita - PB' },
  { label: 'Santa Teresinha - PB' },
  { label: 'Santo Andre - PB' },
  { label: 'Sao Bento - PB' },
  { label: 'Sao Bentinho - PB' },
  { label: 'Sao Domingos Do Cariri - PB' },
  { label: 'Sao Domingos - PB' },
  { label: 'Sao Francisco - PB' },
  { label: 'Sao Joao Do Cariri - PB' },
  { label: 'Sao Joao Do Tigre - PB' },
  { label: 'Sao Jose Da Lagoa Tapada - PB' },
  { label: 'Sao Jose De Caiana - PB' },
  { label: 'Sao Jose De Espinharas - PB' },
  { label: 'Sao Jose Dos Ramos - PB' },
  { label: 'Sao Jose De Piranhas - PB' },
  { label: 'Sao Jose De Princesa - PB' },
  { label: 'Sao Jose Do Bonfim - PB' },
  { label: 'Sao Jose Do Brejo Do Cruz - PB' },
  { label: 'Sao Jose Do Sabugi - PB' },
  { label: 'Sao Jose Dos Cordeiros - PB' },
  { label: 'Sao Mamede - PB' },
  { label: 'Sao Miguel De Taipu - PB' },
  { label: 'Sao Sebastiao De Lagoa De Roca - PB' },
  { label: 'Sao Sebastiao Do Umbuzeiro - PB' },
  { label: 'Sape - PB' },
  { label: 'Serido - PB' },
  { label: 'Serra Branca - PB' },
  { label: 'Serra Da Raiz - PB' },
  { label: 'Serra Grande - PB' },
  { label: 'Serra Redonda - PB' },
  { label: 'Serraria - PB' },
  { label: 'Sertaozinho - PB' },
  { label: 'Sobrado - PB' },
  { label: 'Solanea - PB' },
  { label: 'Soledade - PB' },
  { label: 'Sossego - PB' },
  { label: 'Sousa - PB' },
  { label: 'Sume - PB' },
  { label: 'Campo De Santana - PB' },
  { label: 'Taperoa - PB' },
  { label: 'Tavares - PB' },
  { label: 'Teixeira - PB' },
  { label: 'Tenorio - PB' },
  { label: 'Triunfo - PB' },
  { label: 'Uirauna - PB' },
  { label: 'Umbuzeiro - PB' },
  { label: 'Varzea - PB' },
  { label: 'Vieiropolis - PB' },
  { label: 'Zabele - PB' },
  { label: 'Abreu E Lima - PE' },
  { label: 'Afogados Da Ingazeira - PE' },
  { label: 'Afranio - PE' },
  { label: 'Agrestina - PE' },
  { label: 'Agua Preta - PE' },
  { label: 'Aguas Belas - PE' },
  { label: 'Alagoinha - PE' },
  { label: 'Alianca - PE' },
  { label: 'Altinho - PE' },
  { label: 'Amaraji - PE' },
  { label: 'Angelim - PE' },
  { label: 'Aracoiaba - PE' },
  { label: 'Araripina - PE' },
  { label: 'Arcoverde - PE' },
  { label: 'Barra De Guabiraba - PE' },
  { label: 'Barreiros - PE' },
  { label: 'Belem De Maria - PE' },
  { label: 'Belem De Sao Francisco - PE' },
  { label: 'Belo Jardim - PE' },
  { label: 'Betania - PE' },
  { label: 'Bezerros - PE' },
  { label: 'Bodoco - PE' },
  { label: 'Bom Conselho - PE' },
  { label: 'Bom Jardim - PE' },
  { label: 'Bonito - PE' },
  { label: 'Brejao - PE' },
  { label: 'Brejinho - PE' },
  { label: 'Brejo Da Madre De Deus - PE' },
  { label: 'Buenos Aires - PE' },
  { label: 'Buique - PE' },
  { label: 'Cabo De Santo Agostinho - PE' },
  { label: 'Cabrobo - PE' },
  { label: 'Cachoeirinha - PE' },
  { label: 'Caetes - PE' },
  { label: 'Calcado - PE' },
  { label: 'Calumbi - PE' },
  { label: 'Camaragibe - PE' },
  { label: 'Camocim De Sao Felix - PE' },
  { label: 'Camutanga - PE' },
  { label: 'Canhotinho - PE' },
  { label: 'Capoeiras - PE' },
  { label: 'Carnaiba - PE' },
  { label: 'Carnaubeira Da Penha - PE' },
  { label: 'Carpina - PE' },
  { label: 'Caruaru - PE' },
  { label: 'Casinhas - PE' },
  { label: 'Catende - PE' },
  { label: 'Cedro - PE' },
  { label: 'Cha De Alegria - PE' },
  { label: 'Cha Grande - PE' },
  { label: 'Condado - PE' },
  { label: 'Correntes - PE' },
  { label: 'Cortes - PE' },
  { label: 'Cumaru - PE' },
  { label: 'Cupira - PE' },
  { label: 'Custodia - PE' },
  { label: 'Dormentes - PE' },
  { label: 'Escada - PE' },
  { label: 'Exu - PE' },
  { label: 'Feira Nova - PE' },
  { label: 'Fernando De Noronha - PE' },
  { label: 'Ferreiros - PE' },
  { label: 'Flores - PE' },
  { label: 'Floresta - PE' },
  { label: 'Frei Miguelinho - PE' },
  { label: 'Gameleira - PE' },
  { label: 'Garanhuns - PE' },
  { label: 'Gloria Do Goita - PE' },
  { label: 'Goiana - PE' },
  { label: 'Granito - PE' },
  { label: 'Gravata - PE' },
  { label: 'Iati - PE' },
  { label: 'Ibimirim - PE' },
  { label: 'Ibirajuba - PE' },
  { label: 'Igarassu - PE' },
  { label: 'Iguaraci - PE' },
  { label: 'Inaja - PE' },
  { label: 'Ingazeira - PE' },
  { label: 'Ipojuca - PE' },
  { label: 'Ipubi - PE' },
  { label: 'Itacuruba - PE' },
  { label: 'Itaiba - PE' },
  { label: 'Ilha De Itamaraca - PE' },
  { label: 'Itambe - PE' },
  { label: 'Itapetim - PE' },
  { label: 'Itapissuma - PE' },
  { label: 'Itaquitinga - PE' },
  { label: 'Jaboatao Dos Guararapes - PE' },
  { label: 'Jaqueira - PE' },
  { label: 'Jatauba - PE' },
  { label: 'Jatoba - PE' },
  { label: 'Joao Alfredo - PE' },
  { label: 'Joaquim Nabuco - PE' },
  { label: 'Jucati - PE' },
  { label: 'Jupi - PE' },
  { label: 'Jurema - PE' },
  { label: 'Lagoa Do Carro - PE' },
  { label: 'Lagoa Do Itaenga - PE' },
  { label: 'Lagoa Do Ouro - PE' },
  { label: 'Lagoa Dos Gatos - PE' },
  { label: 'Lagoa Grande - PE' },
  { label: 'Lajedo - PE' },
  { label: 'Limoeiro - PE' },
  { label: 'Macaparana - PE' },
  { label: 'Machados - PE' },
  { label: 'Manari - PE' },
  { label: 'Maraial - PE' },
  { label: 'Mirandiba - PE' },
  { label: 'Moreno - PE' },
  { label: 'Nazare Da Mata - PE' },
  { label: 'Olinda - PE' },
  { label: 'Orobo - PE' },
  { label: 'Oroco - PE' },
  { label: 'Ouricuri - PE' },
  { label: 'Palmares - PE' },
  { label: 'Palmeirina - PE' },
  { label: 'Panelas - PE' },
  { label: 'Paranatama - PE' },
  { label: 'Parnamirim - PE' },
  { label: 'Passira - PE' },
  { label: 'Paudalho - PE' },
  { label: 'Paulista - PE' },
  { label: 'Pedra - PE' },
  { label: 'Pesqueira - PE' },
  { label: 'Petrolandia - PE' },
  { label: 'Petrolina - PE' },
  { label: 'Pocao - PE' },
  { label: 'Pombos - PE' },
  { label: 'Primavera - PE' },
  { label: 'Quipapa - PE' },
  { label: 'Quixaba - PE' },
  { label: 'Recife - PE' },
  { label: 'Riacho Das Almas - PE' },
  { label: 'Ribeirao - PE' },
  { label: 'Rio Formoso - PE' },
  { label: 'Saire - PE' },
  { label: 'Salgadinho - PE' },
  { label: 'Salgueiro - PE' },
  { label: 'Saloa - PE' },
  { label: 'Sanharo - PE' },
  { label: 'Santa Cruz - PE' },
  { label: 'Santa Cruz Da Baixa Verde - PE' },
  { label: 'Santa Cruz Do Capibaribe - PE' },
  { label: 'Santa Filomena - PE' },
  { label: 'Santa Maria Da Boa Vista - PE' },
  { label: 'Santa Maria Do Cambuca - PE' },
  { label: 'Santa Terezinha - PE' },
  { label: 'Sao Benedito Do Sul - PE' },
  { label: 'Sao Bento Do Una - PE' },
  { label: 'Sao Caitano - PE' },
  { label: 'Sao Joao - PE' },
  { label: 'Sao Joaquim Do Monte - PE' },
  { label: 'Sao Jose Da Coroa Grande - PE' },
  { label: 'Sao Jose Do Belmonte - PE' },
  { label: 'Sao Jose Do Egito - PE' },
  { label: 'Sao Lourenco Da Mata - PE' },
  { label: 'Sao Vicente Ferrer - PE' },
  { label: 'Serra Talhada - PE' },
  { label: 'Serrita - PE' },
  { label: 'Sertania - PE' },
  { label: 'Sirinhaem - PE' },
  { label: 'Moreilandia - PE' },
  { label: 'Solidao - PE' },
  { label: 'Surubim - PE' },
  { label: 'Tabira - PE' },
  { label: 'Tacaimbo - PE' },
  { label: 'Tacaratu - PE' },
  { label: 'Tamandare - PE' },
  { label: 'Taquaritinga Do Norte - PE' },
  { label: 'Terezinha - PE' },
  { label: 'Terra Nova - PE' },
  { label: 'Timbauba - PE' },
  { label: 'Toritama - PE' },
  { label: 'Tracunhaem - PE' },
  { label: 'Trindade - PE' },
  { label: 'Triunfo - PE' },
  { label: 'Tupanatinga - PE' },
  { label: 'Tuparetama - PE' },
  { label: 'Venturosa - PE' },
  { label: 'Verdejante - PE' },
  { label: 'Vertente Do Lerio - PE' },
  { label: 'Vertentes - PE' },
  { label: 'Vicencia - PE' },
  { label: 'Vitoria De Santo Antao - PE' },
  { label: 'Xexeu - PE' },
  { label: 'Agua Branca - AL' },
  { label: 'Anadia - AL' },
  { label: 'Arapiraca - AL' },
  { label: 'Atalaia - AL' },
  { label: 'Barra De Santo Antonio - AL' },
  { label: 'Barra De Sao Miguel - AL' },
  { label: 'Batalha - AL' },
  { label: 'Belem - AL' },
  { label: 'Belo Monte - AL' },
  { label: 'Boca Da Mata - AL' },
  { label: 'Branquinha - AL' },
  { label: 'Cacimbinhas - AL' },
  { label: 'Cajueiro - AL' },
  { label: 'Campestre - AL' },
  { label: 'Campo Alegre - AL' },
  { label: 'Campo Grande - AL' },
  { label: 'Canapi - AL' },
  { label: 'Capela - AL' },
  { label: 'Carneiros - AL' },
  { label: 'Cha Preta - AL' },
  { label: 'Coite Do Noia - AL' },
  { label: 'Colonia Leopoldina - AL' },
  { label: 'Coqueiro Seco - AL' },
  { label: 'Coruripe - AL' },
  { label: 'Craibas - AL' },
  { label: 'Delmiro Gouveia - AL' },
  { label: 'Dois Riachos - AL' },
  { label: 'Estrela De Alagoas - AL' },
  { label: 'Feira Grande - AL' },
  { label: 'Feliz Deserto - AL' },
  { label: 'Flexeiras - AL' },
  { label: 'Girau Do Ponciano - AL' },
  { label: 'Ibateguara - AL' },
  { label: 'Igaci - AL' },
  { label: 'Igreja Nova - AL' },
  { label: 'Inhapi - AL' },
  { label: 'Jacare Dos Homens - AL' },
  { label: 'Jacuipe - AL' },
  { label: 'Japaratinga - AL' },
  { label: 'Jaramataia - AL' },
  { label: 'Jequia Da Praia - AL' },
  { label: 'Joaquim Gomes - AL' },
  { label: 'Jundia - AL' },
  { label: 'Junqueiro - AL' },
  { label: 'Lagoa Da Canoa - AL' },
  { label: 'Limoeiro De Anadia - AL' },
  { label: 'Maceio - AL' },
  { label: 'Major Isidoro - AL' },
  { label: 'Maragogi - AL' },
  { label: 'Maravilha - AL' },
  { label: 'Marechal Deodoro - AL' },
  { label: 'Maribondo - AL' },
  { label: 'Mar Vermelho - AL' },
  { label: 'Mata Grande - AL' },
  { label: 'Matriz De Camaragibe - AL' },
  { label: 'Messias - AL' },
  { label: 'Minador Do Negrao - AL' },
  { label: 'Monteiropolis - AL' },
  { label: 'Murici - AL' },
  { label: 'Novo Lino - AL' },
  { label: `${''}Olho D'agua Das Flores - AL` },
  { label: `${''}Olho D'agua Do Casado - AL` },
  { label: `${''}Olho D'agua Grande - AL` },
  { label: 'Olivenca - AL' },
  { label: 'Ouro Branco - AL' },
  { label: 'Palestina - AL' },
  { label: 'Palmeira Dos Indios - AL' },
  { label: 'Pao De Acucar - AL' },
  { label: 'Pariconha - AL' },
  { label: 'Paripueira - AL' },
  { label: 'Passo De Camaragibe - AL' },
  { label: 'Paulo Jacinto - AL' },
  { label: 'Penedo - AL' },
  { label: 'Piacabucu - AL' },
  { label: 'Pilar - AL' },
  { label: 'Pindoba - AL' },
  { label: 'Piranhas - AL' },
  { label: 'Poco Das Trincheiras - AL' },
  { label: 'Porto Calvo - AL' },
  { label: 'Porto De Pedras - AL' },
  { label: 'Porto Real Do Colegio - AL' },
  { label: 'Quebrangulo - AL' },
  { label: 'Rio Largo - AL' },
  { label: 'Roteiro - AL' },
  { label: 'Santa Luzia Do Norte - AL' },
  { label: 'Santana Do Ipanema - AL' },
  { label: 'Santana Do Mundau - AL' },
  { label: 'Sao Bras - AL' },
  { label: 'Sao Jose Da Laje - AL' },
  { label: 'Sao Jose Da Tapera - AL' },
  { label: 'Sao Luis Do Quitunde - AL' },
  { label: 'Sao Miguel Dos Campos - AL' },
  { label: 'Sao Miguel Dos Milagres - AL' },
  { label: 'Sao Sebastiao - AL' },
  { label: 'Satuba - AL' },
  { label: 'Senador Rui Palmeira - AL' },
  { label: `${''}Tanque D'arca - AL` },
  { label: 'Taquarana - AL' },
  { label: 'Teotonio Vilela - AL' },
  { label: 'Traipu - AL' },
  { label: 'Uniao Dos Palmares - AL' },
  { label: 'Vicosa - AL' },
  { label: 'Amparo De Sao Francisco - SE' },
  { label: 'Aquidaba - SE' },
  { label: 'Aracaju - SE' },
  { label: 'Araua - SE' },
  { label: 'Areia Branca - SE' },
  { label: 'Barra Dos Coqueiros - SE' },
  { label: 'Boquim - SE' },
  { label: 'Brejo Grande - SE' },
  { label: 'Campo Do Brito - SE' },
  { label: 'Canhoba - SE' },
  { label: 'Caninde De Sao Francisco - SE' },
  { label: 'Capela - SE' },
  { label: 'Carira - SE' },
  { label: 'Carmopolis - SE' },
  { label: 'Cedro De Sao Joao - SE' },
  { label: 'Cristinapolis - SE' },
  { label: 'Cumbe - SE' },
  { label: 'Divina Pastora - SE' },
  { label: 'Estancia - SE' },
  { label: 'Feira Nova - SE' },
  { label: 'Frei Paulo - SE' },
  { label: 'Gararu - SE' },
  { label: 'General Maynard - SE' },
  { label: 'Gracho Cardoso - SE' },
  { label: 'Ilha Das Flores - SE' },
  { label: 'Indiaroba - SE' },
  { label: 'Itabaiana - SE' },
  { label: 'Itabaianinha - SE' },
  { label: 'Itabi - SE' },
  { label: `${''}Itaporanga D'ajuda - SE` },
  { label: 'Japaratuba - SE' },
  { label: 'Japoata - SE' },
  { label: 'Lagarto - SE' },
  { label: 'Laranjeiras - SE' },
  { label: 'Macambira - SE' },
  { label: 'Malhada Dos Bois - SE' },
  { label: 'Malhador - SE' },
  { label: 'Maruim - SE' },
  { label: 'Moita Bonita - SE' },
  { label: 'Monte Alegre De Sergipe - SE' },
  { label: 'Muribeca - SE' },
  { label: 'Neopolis - SE' },
  { label: 'Nossa Senhora Aparecida - SE' },
  { label: 'Nossa Senhora Da Gloria - SE' },
  { label: 'Nossa Senhora Das Dores - SE' },
  { label: 'Nossa Senhora De Lourdes - SE' },
  { label: 'Nossa Senhora Do Socorro - SE' },
  { label: 'Pacatuba - SE' },
  { label: 'Pedra Mole - SE' },
  { label: 'Pedrinhas - SE' },
  { label: 'Pinhao - SE' },
  { label: 'Pirambu - SE' },
  { label: 'Poco Redondo - SE' },
  { label: 'Poco Verde - SE' },
  { label: 'Porto Da Folha - SE' },
  { label: 'Propria - SE' },
  { label: 'Riachao Do Dantas - SE' },
  { label: 'Riachuelo - SE' },
  { label: 'Ribeiropolis - SE' },
  { label: 'Rosario Do Catete - SE' },
  { label: 'Salgado - SE' },
  { label: 'Santa Luzia Do Itanhy - SE' },
  { label: 'Santana Do Sao Francisco - SE' },
  { label: 'Santa Rosa De Lima - SE' },
  { label: 'Santo Amaro Das Brotas - SE' },
  { label: 'Sao Cristovao - SE' },
  { label: 'Sao Domingos - SE' },
  { label: 'Sao Francisco - SE' },
  { label: 'Sao Miguel Do Aleixo - SE' },
  { label: 'Simao Dias - SE' },
  { label: 'Siriri - SE' },
  { label: 'Telha - SE' },
  { label: 'Tobias Barreto - SE' },
  { label: 'Tomar Do Geru - SE' },
  { label: 'Umbauba - SE' },
  { label: 'Abaira - BA' },
  { label: 'Abare - BA' },
  { label: 'Acajutiba - BA' },
  { label: 'Adustina - BA' },
  { label: 'Agua Fria - BA' },
  { label: 'Erico Cardoso - BA' },
  { label: 'Aiquara - BA' },
  { label: 'Alagoinhas - BA' },
  { label: 'Alcobaca - BA' },
  { label: 'Almadina - BA' },
  { label: 'Amargosa - BA' },
  { label: 'Amelia Rodrigues - BA' },
  { label: 'America Dourada - BA' },
  { label: 'Anage - BA' },
  { label: 'Andarai - BA' },
  { label: 'Andorinha - BA' },
  { label: 'Angical - BA' },
  { label: 'Anguera - BA' },
  { label: 'Antas - BA' },
  { label: 'Antonio Cardoso - BA' },
  { label: 'Antonio Goncalves - BA' },
  { label: 'Apora - BA' },
  { label: 'Apuarema - BA' },
  { label: 'Aracatu - BA' },
  { label: 'Aracas - BA' },
  { label: 'Araci - BA' },
  { label: 'Aramari - BA' },
  { label: 'Arataca - BA' },
  { label: 'Aratuipe - BA' },
  { label: 'Aurelino Leal - BA' },
  { label: 'Baianopolis - BA' },
  { label: 'Baixa Grande - BA' },
  { label: 'Banzae - BA' },
  { label: 'Barra - BA' },
  { label: 'Barra Da Estiva - BA' },
  { label: 'Barra Do Choca - BA' },
  { label: 'Barra Do Mendes - BA' },
  { label: 'Barra Do Rocha - BA' },
  { label: 'Barreiras - BA' },
  { label: 'Barro Alto - BA' },
  { label: 'Barrocas - BA' },
  { label: 'Barro Preto - BA' },
  { label: 'Belmonte - BA' },
  { label: 'Belo Campo - BA' },
  { label: 'Biritinga - BA' },
  { label: 'Boa Nova - BA' },
  { label: 'Boa Vista Do Tupim - BA' },
  { label: 'Bom Jesus Da Lapa - BA' },
  { label: 'Bom Jesus Da Serra - BA' },
  { label: 'Boninal - BA' },
  { label: 'Bonito - BA' },
  { label: 'Boquira - BA' },
  { label: 'Botupora - BA' },
  { label: 'Brejoes - BA' },
  { label: 'Brejolandia - BA' },
  { label: 'Brotas De Macaubas - BA' },
  { label: 'Brumado - BA' },
  { label: 'Buerarema - BA' },
  { label: 'Buritirama - BA' },
  { label: 'Caatiba - BA' },
  { label: 'Cabaceiras Do Paraguacu - BA' },
  { label: 'Cachoeira - BA' },
  { label: 'Cacule - BA' },
  { label: 'Caem - BA' },
  { label: 'Caetanos - BA' },
  { label: 'Caetite - BA' },
  { label: 'Cafarnaum - BA' },
  { label: 'Cairu - BA' },
  { label: 'Caldeirao Grande - BA' },
  { label: 'Camacan - BA' },
  { label: 'Camacari - BA' },
  { label: 'Camamu - BA' },
  { label: 'Campo Alegre De Lourdes - BA' },
  { label: 'Campo Formoso - BA' },
  { label: 'Canapolis - BA' },
  { label: 'Canarana - BA' },
  { label: 'Canavieiras - BA' },
  { label: 'Candeal - BA' },
  { label: 'Candeias - BA' },
  { label: 'Candiba - BA' },
  { label: 'Candido Sales - BA' },
  { label: 'Cansancao - BA' },
  { label: 'Canudos - BA' },
  { label: 'Capela Do Alto Alegre - BA' },
  { label: 'Capim Grosso - BA' },
  { label: 'Caraibas - BA' },
  { label: 'Caravelas - BA' },
  { label: 'Cardeal Da Silva - BA' },
  { label: 'Carinhanha - BA' },
  { label: 'Casa Nova - BA' },
  { label: 'Castro Alves - BA' },
  { label: 'Catolandia - BA' },
  { label: 'Catu - BA' },
  { label: 'Caturama - BA' },
  { label: 'Central - BA' },
  { label: 'Chorrocho - BA' },
  { label: 'Cicero Dantas - BA' },
  { label: 'Cipo - BA' },
  { label: 'Coaraci - BA' },
  { label: 'Cocos - BA' },
  { label: 'Conceicao Da Feira - BA' },
  { label: 'Conceicao Do Almeida - BA' },
  { label: 'Conceicao Do Coite - BA' },
  { label: 'Conceicao Do Jacuipe - BA' },
  { label: 'Conde - BA' },
  { label: 'Condeuba - BA' },
  { label: 'Contendas Do Sincora - BA' },
  { label: 'Coracao De Maria - BA' },
  { label: 'Cordeiros - BA' },
  { label: 'Coribe - BA' },
  { label: 'Coronel Joao Sa - BA' },
  { label: 'Correntina - BA' },
  { label: 'Cotegipe - BA' },
  { label: 'Cravolandia - BA' },
  { label: 'Crisopolis - BA' },
  { label: 'Cristopolis - BA' },
  { label: 'Cruz Das Almas - BA' },
  { label: 'Curaca - BA' },
  { label: 'Dario Meira - BA' },
  { label: `${''}Dias D'avila - BA` },
  { label: 'Dom Basilio - BA' },
  { label: 'Dom Macedo Costa - BA' },
  { label: 'Elisio Medrado - BA' },
  { label: 'Encruzilhada - BA' },
  { label: 'Entre Rios - BA' },
  { label: 'Esplanada - BA' },
  { label: 'Euclides Da Cunha - BA' },
  { label: 'Eunapolis - BA' },
  { label: 'Fatima - BA' },
  { label: 'Feira Da Mata - BA' },
  { label: 'Feira De Santana - BA' },
  { label: 'Filadelfia - BA' },
  { label: 'Firmino Alves - BA' },
  { label: 'Floresta Azul - BA' },
  { label: 'Formosa Do Rio Preto - BA' },
  { label: 'Gandu - BA' },
  { label: 'Gaviao - BA' },
  { label: 'Gentio Do Ouro - BA' },
  { label: 'Gloria - BA' },
  { label: 'Gongogi - BA' },
  { label: 'Governador Mangabeira - BA' },
  { label: 'Guajeru - BA' },
  { label: 'Guanambi - BA' },
  { label: 'Guaratinga - BA' },
  { label: 'Heliopolis - BA' },
  { label: 'Iacu - BA' },
  { label: 'Ibiassuce - BA' },
  { label: 'Ibicarai - BA' },
  { label: 'Ibicoara - BA' },
  { label: 'Ibicui - BA' },
  { label: 'Ibipeba - BA' },
  { label: 'Ibipitanga - BA' },
  { label: 'Ibiquera - BA' },
  { label: 'Ibirapitanga - BA' },
  { label: 'Ibirapua - BA' },
  { label: 'Ibirataia - BA' },
  { label: 'Ibitiara - BA' },
  { label: 'Ibitita - BA' },
  { label: 'Ibotirama - BA' },
  { label: 'Ichu - BA' },
  { label: 'Igapora - BA' },
  { label: 'Igrapiuna - BA' },
  { label: 'Iguai - BA' },
  { label: 'Ilheus - BA' },
  { label: 'Inhambupe - BA' },
  { label: 'Ipecaeta - BA' },
  { label: 'Ipiau - BA' },
  { label: 'Ipira - BA' },
  { label: 'Ipupiara - BA' },
  { label: 'Irajuba - BA' },
  { label: 'Iramaia - BA' },
  { label: 'Iraquara - BA' },
  { label: 'Irara - BA' },
  { label: 'Irece - BA' },
  { label: 'Itabela - BA' },
  { label: 'Itaberaba - BA' },
  { label: 'Itabuna - BA' },
  { label: 'Itacare - BA' },
  { label: 'Itaete - BA' },
  { label: 'Itagi - BA' },
  { label: 'Itagiba - BA' },
  { label: 'Itagimirim - BA' },
  { label: 'Itaguacu Da Bahia - BA' },
  { label: 'Itaju Do Colonia - BA' },
  { label: 'Itajuipe - BA' },
  { label: 'Itamaraju - BA' },
  { label: 'Itamari - BA' },
  { label: 'Itambe - BA' },
  { label: 'Itanagra - BA' },
  { label: 'Itanhem - BA' },
  { label: 'Itaparica - BA' },
  { label: 'Itape - BA' },
  { label: 'Itapebi - BA' },
  { label: 'Itapetinga - BA' },
  { label: 'Itapicuru - BA' },
  { label: 'Itapitanga - BA' },
  { label: 'Itaquara - BA' },
  { label: 'Itarantim - BA' },
  { label: 'Itatim - BA' },
  { label: 'Itirucu - BA' },
  { label: 'Itiuba - BA' },
  { label: 'Itororo - BA' },
  { label: 'Ituacu - BA' },
  { label: 'Itubera - BA' },
  { label: 'Iuiu - BA' },
  { label: 'Jaborandi - BA' },
  { label: 'Jacaraci - BA' },
  { label: 'Jacobina - BA' },
  { label: 'Jaguaquara - BA' },
  { label: 'Jaguarari - BA' },
  { label: 'Jaguaripe - BA' },
  { label: 'Jandaira - BA' },
  { label: 'Jequie - BA' },
  { label: 'Jeremoabo - BA' },
  { label: 'Jiquirica - BA' },
  { label: 'Jitauna - BA' },
  { label: 'Joao Dourado - BA' },
  { label: 'Juazeiro - BA' },
  { label: 'Jucurucu - BA' },
  { label: 'Jussara - BA' },
  { label: 'Jussari - BA' },
  { label: 'Jussiape - BA' },
  { label: 'Lafaiete Coutinho - BA' },
  { label: 'Lagoa Real - BA' },
  { label: 'Laje - BA' },
  { label: 'Lajedao - BA' },
  { label: 'Lajedinho - BA' },
  { label: 'Lajedo Do Tabocal - BA' },
  { label: 'Lamarao - BA' },
  { label: 'Lapao - BA' },
  { label: 'Lauro De Freitas - BA' },
  { label: 'Lencois - BA' },
  { label: 'Licinio De Almeida - BA' },
  { label: 'Livramento De Nossa Senhora - BA' },
  { label: 'Luis Eduardo Magalhaes - BA' },
  { label: 'Macajuba - BA' },
  { label: 'Macarani - BA' },
  { label: 'Macaubas - BA' },
  { label: 'Macurure - BA' },
  { label: 'Madre De Deus - BA' },
  { label: 'Maetinga - BA' },
  { label: 'Maiquinique - BA' },
  { label: 'Mairi - BA' },
  { label: 'Malhada - BA' },
  { label: 'Malhada De Pedras - BA' },
  { label: 'Manoel Vitorino - BA' },
  { label: 'Mansidao - BA' },
  { label: 'Maracas - BA' },
  { label: 'Maragogipe - BA' },
  { label: 'Marau - BA' },
  { label: 'Marcionilio Souza - BA' },
  { label: 'Mascote - BA' },
  { label: 'Mata De Sao Joao - BA' },
  { label: 'Matina - BA' },
  { label: 'Medeiros Neto - BA' },
  { label: 'Miguel Calmon - BA' },
  { label: 'Milagres - BA' },
  { label: 'Mirangaba - BA' },
  { label: 'Mirante - BA' },
  { label: 'Monte Santo - BA' },
  { label: 'Morpara - BA' },
  { label: 'Morro Do Chapeu - BA' },
  { label: 'Mortugaba - BA' },
  { label: 'Mucuge - BA' },
  { label: 'Mucuri - BA' },
  { label: 'Mulungu Do Morro - BA' },
  { label: 'Mundo Novo - BA' },
  { label: 'Muniz Ferreira - BA' },
  { label: 'Muquem De Sao Francisco - BA' },
  { label: 'Muritiba - BA' },
  { label: 'Mutuipe - BA' },
  { label: 'Nazare - BA' },
  { label: 'Nilo Pecanha - BA' },
  { label: 'Nordestina - BA' },
  { label: 'Nova Canaa - BA' },
  { label: 'Nova Fatima - BA' },
  { label: 'Nova Ibia - BA' },
  { label: 'Nova Itarana - BA' },
  { label: 'Nova Redencao - BA' },
  { label: 'Nova Soure - BA' },
  { label: 'Nova Vicosa - BA' },
  { label: 'Novo Horizonte - BA' },
  { label: 'Novo Triunfo - BA' },
  { label: 'Olindina - BA' },
  { label: 'Oliveira Dos Brejinhos - BA' },
  { label: 'Ouricangas - BA' },
  { label: 'Ourolandia - BA' },
  { label: 'Palmas De Monte Alto - BA' },
  { label: 'Palmeiras - BA' },
  { label: 'Paramirim - BA' },
  { label: 'Paratinga - BA' },
  { label: 'Paripiranga - BA' },
  { label: 'Pau Brasil - BA' },
  { label: 'Paulo Afonso - BA' },
  { label: 'Pe De Serra - BA' },
  { label: 'Pedrao - BA' },
  { label: 'Pedro Alexandre - BA' },
  { label: 'Piata - BA' },
  { label: 'Pilao Arcado - BA' },
  { label: 'Pindai - BA' },
  { label: 'Pindobacu - BA' },
  { label: 'Pintadas - BA' },
  { label: 'Pirai Do Norte - BA' },
  { label: 'Piripa - BA' },
  { label: 'Piritiba - BA' },
  { label: 'Planaltino - BA' },
  { label: 'Planalto - BA' },
  { label: 'Pocoes - BA' },
  { label: 'Pojuca - BA' },
  { label: 'Ponto Novo - BA' },
  { label: 'Porto Seguro - BA' },
  { label: 'Potiragua - BA' },
  { label: 'Prado - BA' },
  { label: 'Presidente Dutra - BA' },
  { label: 'Presidente Janio Quadros - BA' },
  { label: 'Presidente Tancredo Neves - BA' },
  { label: 'Queimadas - BA' },
  { label: 'Quijingue - BA' },
  { label: 'Quixabeira - BA' },
  { label: 'Rafael Jambeiro - BA' },
  { label: 'Remanso - BA' },
  { label: 'Retirolandia - BA' },
  { label: 'Riachao Das Neves - BA' },
  { label: 'Riachao Do Jacuipe - BA' },
  { label: 'Riacho De Santana - BA' },
  { label: 'Ribeira Do Amparo - BA' },
  { label: 'Ribeira Do Pombal - BA' },
  { label: 'Ribeirao Do Largo - BA' },
  { label: 'Rio De Contas - BA' },
  { label: 'Rio Do Antonio - BA' },
  { label: 'Rio Do Pires - BA' },
  { label: 'Rio Real - BA' },
  { label: 'Rodelas - BA' },
  { label: 'Ruy Barbosa - BA' },
  { label: 'Salinas Da Margarida - BA' },
  { label: 'Salvador - BA' },
  { label: 'Santa Barbara - BA' },
  { label: 'Santa Brigida - BA' },
  { label: 'Santa Cruz Cabralia - BA' },
  { label: 'Santa Cruz Da Vitoria - BA' },
  { label: 'Santa Ines - BA' },
  { label: 'Santaluz - BA' },
  { label: 'Santa Luzia - BA' },
  { label: 'Santa Maria Da Vitoria - BA' },
  { label: 'Santana - BA' },
  { label: 'Santanopolis - BA' },
  { label: 'Santa Rita De Cassia - BA' },
  { label: 'Santa Teresinha - BA' },
  { label: 'Santo Amaro - BA' },
  { label: 'Santo Antonio De Jesus - BA' },
  { label: 'Santo Estevao - BA' },
  { label: 'Sao Desiderio - BA' },
  { label: 'Sao Domingos - BA' },
  { label: 'Sao Felix - BA' },
  { label: 'Sao Felix Do Coribe - BA' },
  { label: 'Sao Felipe - BA' },
  { label: 'Sao Francisco Do Conde - BA' },
  { label: 'Sao Gabriel - BA' },
  { label: 'Sao Goncalo Dos Campos - BA' },
  { label: 'Sao Jose Da Vitoria - BA' },
  { label: 'Sao Jose Do Jacuipe - BA' },
  { label: 'Sao Miguel Das Matas - BA' },
  { label: 'Sao Sebastiao Do Passe - BA' },
  { label: 'Sapeacu - BA' },
  { label: 'Satiro Dias - BA' },
  { label: 'Saubara - BA' },
  { label: 'Saude - BA' },
  { label: 'Seabra - BA' },
  { label: 'Sebastiao Laranjeiras - BA' },
  { label: 'Senhor Do Bonfim - BA' },
  { label: 'Serra Do Ramalho - BA' },
  { label: 'Sento Se - BA' },
  { label: 'Serra Dourada - BA' },
  { label: 'Serra Preta - BA' },
  { label: 'Serrinha - BA' },
  { label: 'Serrolandia - BA' },
  { label: 'Simoes Filho - BA' },
  { label: 'Sitio Do Mato - BA' },
  { label: 'Sitio Do Quinto - BA' },
  { label: 'Sobradinho - BA' },
  { label: 'Souto Soares - BA' },
  { label: 'Tabocas Do Brejo Velho - BA' },
  { label: 'Tanhacu - BA' },
  { label: 'Tanque Novo - BA' },
  { label: 'Tanquinho - BA' },
  { label: 'Taperoa - BA' },
  { label: 'Tapiramuta - BA' },
  { label: 'Teixeira De Freitas - BA' },
  { label: 'Teodoro Sampaio - BA' },
  { label: 'Teofilandia - BA' },
  { label: 'Teolandia - BA' },
  { label: 'Terra Nova - BA' },
  { label: 'Tremedal - BA' },
  { label: 'Tucano - BA' },
  { label: 'Uaua - BA' },
  { label: 'Ubaira - BA' },
  { label: 'Ubaitaba - BA' },
  { label: 'Ubata - BA' },
  { label: 'Uibai - BA' },
  { label: 'Umburanas - BA' },
  { label: 'Una - BA' },
  { label: 'Urandi - BA' },
  { label: 'Urucuca - BA' },
  { label: 'Utinga - BA' },
  { label: 'Valenca - BA' },
  { label: 'Valente - BA' },
  { label: 'Varzea Da Roca - BA' },
  { label: 'Varzea Do Poco - BA' },
  { label: 'Varzea Nova - BA' },
  { label: 'Varzedo - BA' },
  { label: 'Vera Cruz - BA' },
  { label: 'Vereda - BA' },
  { label: 'Vitoria Da Conquista - BA' },
  { label: 'Wagner - BA' },
  { label: 'Wanderley - BA' },
  { label: 'Wenceslau Guimaraes - BA' },
  { label: 'Xique-xique - BA' },
  { label: 'Abadia Dos Dourados - MG' },
  { label: 'Abaete - MG' },
  { label: 'Abre Campo - MG' },
  { label: 'Acaiaca - MG' },
  { label: 'Acucena - MG' },
  { label: 'Agua Boa - MG' },
  { label: 'Agua Comprida - MG' },
  { label: 'Aguanil - MG' },
  { label: 'Aguas Formosas - MG' },
  { label: 'Aguas Vermelhas - MG' },
  { label: 'Aimores - MG' },
  { label: 'Aiuruoca - MG' },
  { label: 'Alagoa - MG' },
  { label: 'Albertina - MG' },
  { label: 'Alem Paraiba - MG' },
  { label: 'Alfenas - MG' },
  { label: 'Alfredo Vasconcelos - MG' },
  { label: 'Almenara - MG' },
  { label: 'Alpercata - MG' },
  { label: 'Alpinopolis - MG' },
  { label: 'Alterosa - MG' },
  { label: 'Alto Caparao - MG' },
  { label: 'Alto Rio Doce - MG' },
  { label: 'Alvarenga - MG' },
  { label: 'Alvinopolis - MG' },
  { label: 'Alvorada De Minas - MG' },
  { label: 'Amparo Do Serra - MG' },
  { label: 'Andradas - MG' },
  { label: 'Cachoeira De Pajeu - MG' },
  { label: 'Andrelandia - MG' },
  { label: 'Angelandia - MG' },
  { label: 'Antonio Carlos - MG' },
  { label: 'Antonio Dias - MG' },
  { label: 'Antonio Prado De Minas - MG' },
  { label: 'Aracai - MG' },
  { label: 'Aracitaba - MG' },
  { label: 'Aracuai - MG' },
  { label: 'Araguari - MG' },
  { label: 'Arantina - MG' },
  { label: 'Araponga - MG' },
  { label: 'Arapora - MG' },
  { label: 'Arapua - MG' },
  { label: 'Araujos - MG' },
  { label: 'Araxa - MG' },
  { label: 'Arceburgo - MG' },
  { label: 'Arcos - MG' },
  { label: 'Areado - MG' },
  { label: 'Argirita - MG' },
  { label: 'Aricanduva - MG' },
  { label: 'Arinos - MG' },
  { label: 'Astolfo Dutra - MG' },
  { label: 'Ataleia - MG' },
  { label: 'Augusto De Lima - MG' },
  { label: 'Baependi - MG' },
  { label: 'Baldim - MG' },
  { label: 'Bambui - MG' },
  { label: 'Bandeira - MG' },
  { label: 'Bandeira Do Sul - MG' },
  { label: 'Barao De Cocais - MG' },
  { label: 'Barao De Monte Alto - MG' },
  { label: 'Barbacena - MG' },
  { label: 'Barra Longa - MG' },
  { label: 'Barroso - MG' },
  { label: 'Bela Vista De Minas - MG' },
  { label: 'Belmiro Braga - MG' },
  { label: 'Belo Horizonte - MG' },
  { label: 'Belo Oriente - MG' },
  { label: 'Belo Vale - MG' },
  { label: 'Berilo - MG' },
  { label: 'Bertopolis - MG' },
  { label: 'Berizal - MG' },
  { label: 'Betim - MG' },
  { label: 'Bias Fortes - MG' },
  { label: 'Bicas - MG' },
  { label: 'Biquinhas - MG' },
  { label: 'Boa Esperanca - MG' },
  { label: 'Bocaina De Minas - MG' },
  { label: 'Bocaiuva - MG' },
  { label: 'Bom Despacho - MG' },
  { label: 'Bom Jardim De Minas - MG' },
  { label: 'Bom Jesus Da Penha - MG' },
  { label: 'Bom Jesus Do Amparo - MG' },
  { label: 'Bom Jesus Do Galho - MG' },
  { label: 'Bom Repouso - MG' },
  { label: 'Bom Sucesso - MG' },
  { label: 'Bonfim - MG' },
  { label: 'Bonfinopolis De Minas - MG' },
  { label: 'Bonito De Minas - MG' },
  { label: 'Borda Da Mata - MG' },
  { label: 'Botelhos - MG' },
  { label: 'Botumirim - MG' },
  { label: 'Brasilandia De Minas - MG' },
  { label: 'Brasilia De Minas - MG' },
  { label: 'Bras Pires - MG' },
  { label: 'Braunas - MG' },
  { label: 'Brasopolis - MG' },
  { label: 'Brumadinho - MG' },
  { label: 'Bueno Brandao - MG' },
  { label: 'Buenopolis - MG' },
  { label: 'Bugre - MG' },
  { label: 'Buritis - MG' },
  { label: 'Buritizeiro - MG' },
  { label: 'Cabeceira Grande - MG' },
  { label: 'Cabo Verde - MG' },
  { label: 'Cachoeira Da Prata - MG' },
  { label: 'Cachoeira De Minas - MG' },
  { label: 'Cachoeira Dourada - MG' },
  { label: 'Caetanopolis - MG' },
  { label: 'Caete - MG' },
  { label: 'Caiana - MG' },
  { label: 'Cajuri - MG' },
  { label: 'Caldas - MG' },
  { label: 'Camacho - MG' },
  { label: 'Camanducaia - MG' },
  { label: 'Cambui - MG' },
  { label: 'Cambuquira - MG' },
  { label: 'Campanario - MG' },
  { label: 'Campanha - MG' },
  { label: 'Campestre - MG' },
  { label: 'Campina Verde - MG' },
  { label: 'Campo Azul - MG' },
  { label: 'Campo Belo - MG' },
  { label: 'Campo Do Meio - MG' },
  { label: 'Campo Florido - MG' },
  { label: 'Campos Altos - MG' },
  { label: 'Campos Gerais - MG' },
  { label: 'Canaa - MG' },
  { label: 'Canapolis - MG' },
  { label: 'Cana Verde - MG' },
  { label: 'Candeias - MG' },
  { label: 'Cantagalo - MG' },
  { label: 'Caparao - MG' },
  { label: 'Capela Nova - MG' },
  { label: 'Capelinha - MG' },
  { label: 'Capetinga - MG' },
  { label: 'Capim Branco - MG' },
  { label: 'Capinopolis - MG' },
  { label: 'Capitao Andrade - MG' },
  { label: 'Capitao Eneas - MG' },
  { label: 'Capitolio - MG' },
  { label: 'Caputira - MG' },
  { label: 'Carai - MG' },
  { label: 'Caranaiba - MG' },
  { label: 'Carandai - MG' },
  { label: 'Carangola - MG' },
  { label: 'Caratinga - MG' },
  { label: 'Carbonita - MG' },
  { label: 'Careacu - MG' },
  { label: 'Carlos Chagas - MG' },
  { label: 'Carmesia - MG' },
  { label: 'Carmo Da Cachoeira - MG' },
  { label: 'Carmo Da Mata - MG' },
  { label: 'Carmo De Minas - MG' },
  { label: 'Carmo Do Cajuru - MG' },
  { label: 'Carmo Do Paranaiba - MG' },
  { label: 'Carmo Do Rio Claro - MG' },
  { label: 'Carmopolis De Minas - MG' },
  { label: 'Carneirinho - MG' },
  { label: 'Carrancas - MG' },
  { label: 'Carvalhopolis - MG' },
  { label: 'Carvalhos - MG' },
  { label: 'Casa Grande - MG' },
  { label: 'Cascalho Rico - MG' },
  { label: 'Cassia - MG' },
  { label: 'Conceicao Da Barra De Minas - MG' },
  { label: 'Cataguases - MG' },
  { label: 'Catas Altas - MG' },
  { label: 'Catas Altas Da Noruega - MG' },
  { label: 'Catuji - MG' },
  { label: 'Catuti - MG' },
  { label: 'Caxambu - MG' },
  { label: 'Cedro Do Abaete - MG' },
  { label: 'Central De Minas - MG' },
  { label: 'Centralina - MG' },
  { label: 'Chacara - MG' },
  { label: 'Chale - MG' },
  { label: 'Chapada Do Norte - MG' },
  { label: 'Chapada Gaucha - MG' },
  { label: 'Chiador - MG' },
  { label: 'Cipotanea - MG' },
  { label: 'Claraval - MG' },
  { label: 'Claro Dos Pocoes - MG' },
  { label: 'Claudio - MG' },
  { label: 'Coimbra - MG' },
  { label: 'Coluna - MG' },
  { label: 'Comendador Gomes - MG' },
  { label: 'Comercinho - MG' },
  { label: 'Conceicao Da Aparecida - MG' },
  { label: 'Conceicao Das Pedras - MG' },
  { label: 'Conceicao Das Alagoas - MG' },
  { label: 'Conceicao De Ipanema - MG' },
  { label: 'Conceicao Do Mato Dentro - MG' },
  { label: 'Conceicao Do Para - MG' },
  { label: 'Conceicao Do Rio Verde - MG' },
  { label: 'Conceicao Dos Ouros - MG' },
  { label: 'Conego Marinho - MG' },
  { label: 'Confins - MG' },
  { label: 'Congonhal - MG' },
  { label: 'Congonhas - MG' },
  { label: 'Congonhas Do Norte - MG' },
  { label: 'Conquista - MG' },
  { label: 'Conselheiro Lafaiete - MG' },
  { label: 'Conselheiro Pena - MG' },
  { label: 'Consolacao - MG' },
  { label: 'Contagem - MG' },
  { label: 'Coqueiral - MG' },
  { label: 'Coracao De Jesus - MG' },
  { label: 'Cordisburgo - MG' },
  { label: 'Cordislandia - MG' },
  { label: 'Corinto - MG' },
  { label: 'Coroaci - MG' },
  { label: 'Coromandel - MG' },
  { label: 'Coronel Fabriciano - MG' },
  { label: 'Coronel Murta - MG' },
  { label: 'Coronel Pacheco - MG' },
  { label: 'Coronel Xavier Chaves - MG' },
  { label: 'Corrego Danta - MG' },
  { label: 'Corrego Do Bom Jesus - MG' },
  { label: 'Corrego Fundo - MG' },
  { label: 'Corrego Novo - MG' },
  { label: 'Couto De Magalhaes De Minas - MG' },
  { label: 'Crisolita - MG' },
  { label: 'Cristais - MG' },
  { label: 'Cristalia - MG' },
  { label: 'Cristiano Otoni - MG' },
  { label: 'Cristina - MG' },
  { label: 'Crucilandia - MG' },
  { label: 'Cruzeiro Da Fortaleza - MG' },
  { label: 'Cruzilia - MG' },
  { label: 'Cuparaque - MG' },
  { label: 'Curral De Dentro - MG' },
  { label: 'Curvelo - MG' },
  { label: 'Datas - MG' },
  { label: 'Delfim Moreira - MG' },
  { label: 'Delfinopolis - MG' },
  { label: 'Delta - MG' },
  { label: 'Descoberto - MG' },
  { label: 'Desterro De Entre Rios - MG' },
  { label: 'Desterro Do Melo - MG' },
  { label: 'Diamantina - MG' },
  { label: 'Diogo De Vasconcelos - MG' },
  { label: 'Dionisio - MG' },
  { label: 'Divinesia - MG' },
  { label: 'Divino - MG' },
  { label: 'Divino Das Laranjeiras - MG' },
  { label: 'Divinolandia De Minas - MG' },
  { label: 'Divinopolis - MG' },
  { label: 'Divisa Alegre - MG' },
  { label: 'Divisa Nova - MG' },
  { label: 'Divisopolis - MG' },
  { label: 'Dom Bosco - MG' },
  { label: 'Dom Cavati - MG' },
  { label: 'Dom Joaquim - MG' },
  { label: 'Dom Silverio - MG' },
  { label: 'Dom Vicoso - MG' },
  { label: 'Dona Eusebia - MG' },
  { label: 'Dores De Campos - MG' },
  { label: 'Dores De Guanhaes - MG' },
  { label: 'Dores Do Indaia - MG' },
  { label: 'Dores Do Turvo - MG' },
  { label: 'Doresopolis - MG' },
  { label: 'Douradoquara - MG' },
  { label: 'Durande - MG' },
  { label: 'Eloi Mendes - MG' },
  { label: 'Engenheiro Caldas - MG' },
  { label: 'Engenheiro Navarro - MG' },
  { label: 'Entre Folhas - MG' },
  { label: 'Entre Rios De Minas - MG' },
  { label: 'Ervalia - MG' },
  { label: 'Esmeraldas - MG' },
  { label: 'Espera Feliz - MG' },
  { label: 'Espinosa - MG' },
  { label: 'Espirito Santo Do Dourado - MG' },
  { label: 'Estiva - MG' },
  { label: 'Estrela Dalva - MG' },
  { label: 'Estrela Do Indaia - MG' },
  { label: 'Estrela Do Sul - MG' },
  { label: 'Eugenopolis - MG' },
  { label: 'Ewbank Da Camara - MG' },
  { label: 'Extrema - MG' },
  { label: 'Fama - MG' },
  { label: 'Faria Lemos - MG' },
  { label: 'Felicio Dos Santos - MG' },
  { label: 'Sao Goncalo Do Rio Preto - MG' },
  { label: 'Felisburgo - MG' },
  { label: 'Felixlandia - MG' },
  { label: 'Fernandes Tourinho - MG' },
  { label: 'Ferros - MG' },
  { label: 'Fervedouro - MG' },
  { label: 'Florestal - MG' },
  { label: 'Formiga - MG' },
  { label: 'Formoso - MG' },
  { label: 'Fortaleza De Minas - MG' },
  { label: 'Fortuna De Minas - MG' },
  { label: 'Francisco Badaro - MG' },
  { label: 'Francisco Dumont - MG' },
  { label: 'Francisco Sa - MG' },
  { label: 'Franciscopolis - MG' },
  { label: 'Frei Gaspar - MG' },
  { label: 'Frei Inocencio - MG' },
  { label: 'Frei Lagonegro - MG' },
  { label: 'Fronteira - MG' },
  { label: 'Fronteira Dos Vales - MG' },
  { label: 'Fruta De Leite - MG' },
  { label: 'Frutal - MG' },
  { label: 'Funilandia - MG' },
  { label: 'Galileia - MG' },
  { label: 'Gameleiras - MG' },
  { label: 'Glaucilandia - MG' },
  { label: 'Goiabeira - MG' },
  { label: 'Goiana - MG' },
  { label: 'Goncalves - MG' },
  { label: 'Gonzaga - MG' },
  { label: 'Gouveia - MG' },
  { label: 'Governador Valadares - MG' },
  { label: 'Grao Mogol - MG' },
  { label: 'Grupiara - MG' },
  { label: 'Guanhaes - MG' },
  { label: 'Guape - MG' },
  { label: 'Guaraciaba - MG' },
  { label: 'Guaraciama - MG' },
  { label: 'Guaranesia - MG' },
  { label: 'Guarani - MG' },
  { label: 'Guarara - MG' },
  { label: 'Guarda-mor - MG' },
  { label: 'Guaxupe - MG' },
  { label: 'Guidoval - MG' },
  { label: 'Guimarania - MG' },
  { label: 'Guiricema - MG' },
  { label: 'Gurinhata - MG' },
  { label: 'Heliodora - MG' },
  { label: 'Iapu - MG' },
  { label: 'Ibertioga - MG' },
  { label: 'Ibia - MG' },
  { label: 'Ibiai - MG' },
  { label: 'Ibiracatu - MG' },
  { label: 'Ibiraci - MG' },
  { label: 'Ibirite - MG' },
  { label: 'Ibitiura De Minas - MG' },
  { label: 'Ibituruna - MG' },
  { label: 'Icarai De Minas - MG' },
  { label: 'Igarape - MG' },
  { label: 'Igaratinga - MG' },
  { label: 'Iguatama - MG' },
  { label: 'Ijaci - MG' },
  { label: 'Ilicinea - MG' },
  { label: 'Imbe De Minas - MG' },
  { label: 'Inconfidentes - MG' },
  { label: 'Indaiabira - MG' },
  { label: 'Indianopolis - MG' },
  { label: 'Ingai - MG' },
  { label: 'Inhapim - MG' },
  { label: 'Inhauma - MG' },
  { label: 'Inimutaba - MG' },
  { label: 'Ipaba - MG' },
  { label: 'Ipanema - MG' },
  { label: 'Ipatinga - MG' },
  { label: 'Ipiacu - MG' },
  { label: 'Ipuiuna - MG' },
  { label: 'Irai De Minas - MG' },
  { label: 'Itabira - MG' },
  { label: 'Itabirinha - MG' },
  { label: 'Itabirito - MG' },
  { label: 'Itacambira - MG' },
  { label: 'Itacarambi - MG' },
  { label: 'Itaguara - MG' },
  { label: 'Itaipe - MG' },
  { label: 'Itajuba - MG' },
  { label: 'Itamarandiba - MG' },
  { label: 'Itamarati De Minas - MG' },
  { label: 'Itambacuri - MG' },
  { label: 'Itambe Do Mato Dentro - MG' },
  { label: 'Itamogi - MG' },
  { label: 'Itamonte - MG' },
  { label: 'Itanhandu - MG' },
  { label: 'Itanhomi - MG' },
  { label: 'Itaobim - MG' },
  { label: 'Itapagipe - MG' },
  { label: 'Itapecerica - MG' },
  { label: 'Itapeva - MG' },
  { label: 'Itatiaiucu - MG' },
  { label: 'Itau De Minas - MG' },
  { label: 'Itauna - MG' },
  { label: 'Itaverava - MG' },
  { label: 'Itinga - MG' },
  { label: 'Itueta - MG' },
  { label: 'Ituiutaba - MG' },
  { label: 'Itumirim - MG' },
  { label: 'Iturama - MG' },
  { label: 'Itutinga - MG' },
  { label: 'Jaboticatubas - MG' },
  { label: 'Jacinto - MG' },
  { label: 'Jacui - MG' },
  { label: 'Jacutinga - MG' },
  { label: 'Jaguaracu - MG' },
  { label: 'Jaiba - MG' },
  { label: 'Jampruca - MG' },
  { label: 'Janauba - MG' },
  { label: 'Januaria - MG' },
  { label: 'Japaraiba - MG' },
  { label: 'Japonvar - MG' },
  { label: 'Jeceaba - MG' },
  { label: 'Jenipapo De Minas - MG' },
  { label: 'Jequeri - MG' },
  { label: 'Jequitai - MG' },
  { label: 'Jequitiba - MG' },
  { label: 'Jequitinhonha - MG' },
  { label: 'Jesuania - MG' },
  { label: 'Joaima - MG' },
  { label: 'Joanesia - MG' },
  { label: 'Joao Monlevade - MG' },
  { label: 'Joao Pinheiro - MG' },
  { label: 'Joaquim Felicio - MG' },
  { label: 'Jordania - MG' },
  { label: 'Jose Goncalves De Minas - MG' },
  { label: 'Jose Raydan - MG' },
  { label: 'Josenopolis - MG' },
  { label: 'Nova Uniao - MG' },
  { label: 'Juatuba - MG' },
  { label: 'Juiz De Fora - MG' },
  { label: 'Juramento - MG' },
  { label: 'Juruaia - MG' },
  { label: 'Juvenilia - MG' },
  { label: 'Ladainha - MG' },
  { label: 'Lagamar - MG' },
  { label: 'Lagoa Da Prata - MG' },
  { label: 'Lagoa Dos Patos - MG' },
  { label: 'Lagoa Dourada - MG' },
  { label: 'Lagoa Formosa - MG' },
  { label: 'Lagoa Grande - MG' },
  { label: 'Lagoa Santa - MG' },
  { label: 'Lajinha - MG' },
  { label: 'Lambari - MG' },
  { label: 'Lamim - MG' },
  { label: 'Laranjal - MG' },
  { label: 'Lassance - MG' },
  { label: 'Lavras - MG' },
  { label: 'Leandro Ferreira - MG' },
  { label: 'Leme Do Prado - MG' },
  { label: 'Leopoldina - MG' },
  { label: 'Liberdade - MG' },
  { label: 'Lima Duarte - MG' },
  { label: 'Limeira Do Oeste - MG' },
  { label: 'Lontra - MG' },
  { label: 'Luisburgo - MG' },
  { label: 'Luislandia - MG' },
  { label: 'Luminarias - MG' },
  { label: 'Luz - MG' },
  { label: 'Machacalis - MG' },
  { label: 'Machado - MG' },
  { label: 'Madre De Deus De Minas - MG' },
  { label: 'Malacacheta - MG' },
  { label: 'Mamonas - MG' },
  { label: 'Manga - MG' },
  { label: 'Manhuacu - MG' },
  { label: 'Manhumirim - MG' },
  { label: 'Mantena - MG' },
  { label: 'Maravilhas - MG' },
  { label: 'Mar De Espanha - MG' },
  { label: 'Maria Da Fe - MG' },
  { label: 'Mariana - MG' },
  { label: 'Marilac - MG' },
  { label: 'Mario Campos - MG' },
  { label: 'Maripa De Minas - MG' },
  { label: 'Marlieria - MG' },
  { label: 'Marmelopolis - MG' },
  { label: 'Martinho Campos - MG' },
  { label: 'Martins Soares - MG' },
  { label: 'Mata Verde - MG' },
  { label: 'Materlandia - MG' },
  { label: 'Mateus Leme - MG' },
  { label: 'Matias Barbosa - MG' },
  { label: 'Matias Cardoso - MG' },
  { label: 'Matipo - MG' },
  { label: 'Mato Verde - MG' },
  { label: 'Matozinhos - MG' },
  { label: 'Matutina - MG' },
  { label: 'Medeiros - MG' },
  { label: 'Medina - MG' },
  { label: 'Mendes Pimentel - MG' },
  { label: 'Merces - MG' },
  { label: 'Mesquita - MG' },
  { label: 'Minas Novas - MG' },
  { label: 'Minduri - MG' },
  { label: 'Mirabela - MG' },
  { label: 'Miradouro - MG' },
  { label: 'Mirai - MG' },
  { label: 'Miravania - MG' },
  { label: 'Moeda - MG' },
  { label: 'Moema - MG' },
  { label: 'Monjolos - MG' },
  { label: 'Monsenhor Paulo - MG' },
  { label: 'Montalvania - MG' },
  { label: 'Monte Alegre De Minas - MG' },
  { label: 'Monte Azul - MG' },
  { label: 'Monte Belo - MG' },
  { label: 'Monte Carmelo - MG' },
  { label: 'Monte Formoso - MG' },
  { label: 'Monte Santo De Minas - MG' },
  { label: 'Montes Claros - MG' },
  { label: 'Monte Siao - MG' },
  { label: 'Montezuma - MG' },
  { label: 'Morada Nova De Minas - MG' },
  { label: 'Morro Da Garca - MG' },
  { label: 'Morro Do Pilar - MG' },
  { label: 'Munhoz - MG' },
  { label: 'Muriae - MG' },
  { label: 'Mutum - MG' },
  { label: 'Muzambinho - MG' },
  { label: 'Nacip Raydan - MG' },
  { label: 'Nanuque - MG' },
  { label: 'Naque - MG' },
  { label: 'Natalandia - MG' },
  { label: 'Natercia - MG' },
  { label: 'Nazareno - MG' },
  { label: 'Nepomuceno - MG' },
  { label: 'Ninheira - MG' },
  { label: 'Nova Belem - MG' },
  { label: 'Nova Era - MG' },
  { label: 'Nova Lima - MG' },
  { label: 'Nova Modica - MG' },
  { label: 'Nova Ponte - MG' },
  { label: 'Nova Porteirinha - MG' },
  { label: 'Nova Resende - MG' },
  { label: 'Nova Serrana - MG' },
  { label: 'Novo Cruzeiro - MG' },
  { label: 'Novo Oriente De Minas - MG' },
  { label: 'Novorizonte - MG' },
  { label: 'Olaria - MG' },
  { label: `${''}Olhos D'agua - MG` },
  { label: 'Olimpio Noronha - MG' },
  { label: 'Oliveira - MG' },
  { label: 'Oliveira Fortes - MG' },
  { label: 'Onca De Pitangui - MG' },
  { label: 'Oratorios - MG' },
  { label: 'Orizania - MG' },
  { label: 'Ouro Branco - MG' },
  { label: 'Ouro Fino - MG' },
  { label: 'Ouro Preto - MG' },
  { label: 'Ouro Verde De Minas - MG' },
  { label: 'Padre Carvalho - MG' },
  { label: 'Padre Paraiso - MG' },
  { label: 'Paineiras - MG' },
  { label: 'Pains - MG' },
  { label: 'Pai Pedro - MG' },
  { label: 'Paiva - MG' },
  { label: 'Palma - MG' },
  { label: 'Palmopolis - MG' },
  { label: 'Papagaios - MG' },
  { label: 'Paracatu - MG' },
  { label: 'Para De Minas - MG' },
  { label: 'Paraguacu - MG' },
  { label: 'Paraisopolis - MG' },
  { label: 'Paraopeba - MG' },
  { label: 'Passabem - MG' },
  { label: 'Passa Quatro - MG' },
  { label: 'Passa Tempo - MG' },
  { label: 'Passa-vinte - MG' },
  { label: 'Passos - MG' },
  { label: 'Patis - MG' },
  { label: 'Patos De Minas - MG' },
  { label: 'Patrocinio - MG' },
  { label: 'Patrocinio Do Muriae - MG' },
  { label: 'Paula Candido - MG' },
  { label: 'Paulistas - MG' },
  { label: 'Pavao - MG' },
  { label: 'Pecanha - MG' },
  { label: 'Pedra Azul - MG' },
  { label: 'Pedra Bonita - MG' },
  { label: 'Pedra Do Anta - MG' },
  { label: 'Pedra Do Indaia - MG' },
  { label: 'Pedra Dourada - MG' },
  { label: 'Pedralva - MG' },
  { label: 'Pedras De Maria Da Cruz - MG' },
  { label: 'Pedrinopolis - MG' },
  { label: 'Pedro Leopoldo - MG' },
  { label: 'Pedro Teixeira - MG' },
  { label: 'Pequeri - MG' },
  { label: 'Pequi - MG' },
  { label: 'Perdigao - MG' },
  { label: 'Perdizes - MG' },
  { label: 'Perdoes - MG' },
  { label: 'Periquito - MG' },
  { label: 'Pescador - MG' },
  { label: 'Piau - MG' },
  { label: 'Piedade De Caratinga - MG' },
  { label: 'Piedade De Ponte Nova - MG' },
  { label: 'Piedade Do Rio Grande - MG' },
  { label: 'Piedade Dos Gerais - MG' },
  { label: 'Pimenta - MG' },
  { label: `${''}Pingo-d'agua - MG` },
  { label: 'Pintopolis - MG' },
  { label: 'Piracema - MG' },
  { label: 'Pirajuba - MG' },
  { label: 'Piranga - MG' },
  { label: 'Pirangucu - MG' },
  { label: 'Piranguinho - MG' },
  { label: 'Pirapetinga - MG' },
  { label: 'Pirapora - MG' },
  { label: 'Pirauba - MG' },
  { label: 'Pitangui - MG' },
  { label: 'Piumhi - MG' },
  { label: 'Planura - MG' },
  { label: 'Poco Fundo - MG' },
  { label: 'Pocos De Caldas - MG' },
  { label: 'Pocrane - MG' },
  { label: 'Pompeu - MG' },
  { label: 'Ponte Nova - MG' },
  { label: 'Ponto Chique - MG' },
  { label: 'Ponto Dos Volantes - MG' },
  { label: 'Porteirinha - MG' },
  { label: 'Porto Firme - MG' },
  { label: 'Pote - MG' },
  { label: 'Pouso Alegre - MG' },
  { label: 'Pouso Alto - MG' },
  { label: 'Prados - MG' },
  { label: 'Prata - MG' },
  { label: 'Pratapolis - MG' },
  { label: 'Pratinha - MG' },
  { label: 'Presidente Bernardes - MG' },
  { label: 'Presidente Juscelino - MG' },
  { label: 'Presidente Kubitschek - MG' },
  { label: 'Presidente Olegario - MG' },
  { label: 'Alto Jequitiba - MG' },
  { label: 'Prudente De Morais - MG' },
  { label: 'Quartel Geral - MG' },
  { label: 'Queluzito - MG' },
  { label: 'Raposos - MG' },
  { label: 'Raul Soares - MG' },
  { label: 'Recreio - MG' },
  { label: 'Reduto - MG' },
  { label: 'Resende Costa - MG' },
  { label: 'Resplendor - MG' },
  { label: 'Ressaquinha - MG' },
  { label: 'Riachinho - MG' },
  { label: 'Riacho Dos Machados - MG' },
  { label: 'Ribeirao Das Neves - MG' },
  { label: 'Ribeirao Vermelho - MG' },
  { label: 'Rio Acima - MG' },
  { label: 'Rio Casca - MG' },
  { label: 'Rio Doce - MG' },
  { label: 'Rio Do Prado - MG' },
  { label: 'Rio Espera - MG' },
  { label: 'Rio Manso - MG' },
  { label: 'Rio Novo - MG' },
  { label: 'Rio Paranaiba - MG' },
  { label: 'Rio Pardo De Minas - MG' },
  { label: 'Rio Piracicaba - MG' },
  { label: 'Rio Pomba - MG' },
  { label: 'Rio Preto - MG' },
  { label: 'Rio Vermelho - MG' },
  { label: 'Ritapolis - MG' },
  { label: 'Rochedo De Minas - MG' },
  { label: 'Rodeiro - MG' },
  { label: 'Romaria - MG' },
  { label: 'Rosario Da Limeira - MG' },
  { label: 'Rubelita - MG' },
  { label: 'Rubim - MG' },
  { label: 'Sabara - MG' },
  { label: 'Sabinopolis - MG' },
  { label: 'Sacramento - MG' },
  { label: 'Salinas - MG' },
  { label: 'Salto Da Divisa - MG' },
  { label: 'Santa Barbara - MG' },
  { label: 'Santa Barbara Do Leste - MG' },
  { label: 'Santa Barbara Do Monte Verde - MG' },
  { label: 'Santa Barbara Do Tugurio - MG' },
  { label: 'Santa Cruz De Minas - MG' },
  { label: 'Santa Cruz De Salinas - MG' },
  { label: 'Santa Cruz Do Escalvado - MG' },
  { label: 'Santa Efigenia De Minas - MG' },
  { label: 'Santa Fe De Minas - MG' },
  { label: 'Santa Helena De Minas - MG' },
  { label: 'Santa Juliana - MG' },
  { label: 'Santa Luzia - MG' },
  { label: 'Santa Margarida - MG' },
  { label: 'Santa Maria De Itabira - MG' },
  { label: 'Santa Maria Do Salto - MG' },
  { label: 'Santa Maria Do Suacui - MG' },
  { label: 'Santana Da Vargem - MG' },
  { label: 'Santana De Cataguases - MG' },
  { label: 'Santana De Pirapama - MG' },
  { label: 'Santana Do Deserto - MG' },
  { label: 'Santana Do Garambeu - MG' },
  { label: 'Santana Do Jacare - MG' },
  { label: 'Santana Do Manhuacu - MG' },
  { label: 'Santana Do Paraiso - MG' },
  { label: 'Santana Do Riacho - MG' },
  { label: 'Santana Dos Montes - MG' },
  { label: 'Santa Rita De Caldas - MG' },
  { label: 'Santa Rita De Jacutinga - MG' },
  { label: 'Santa Rita De Minas - MG' },
  { label: 'Santa Rita De Ibitipoca - MG' },
  { label: 'Santa Rita Do Itueto - MG' },
  { label: 'Santa Rita Do Sapucai - MG' },
  { label: 'Santa Rosa Da Serra - MG' },
  { label: 'Santa Vitoria - MG' },
  { label: 'Santo Antonio Do Amparo - MG' },
  { label: 'Santo Antonio Do Aventureiro - MG' },
  { label: 'Santo Antonio Do Grama - MG' },
  { label: 'Santo Antonio Do Itambe - MG' },
  { label: 'Santo Antonio Do Jacinto - MG' },
  { label: 'Santo Antonio Do Monte - MG' },
  { label: 'Santo Antonio Do Retiro - MG' },
  { label: 'Santo Antonio Do Rio Abaixo - MG' },
  { label: 'Santo Hipolito - MG' },
  { label: 'Santos Dumont - MG' },
  { label: 'Sao Bento Abade - MG' },
  { label: 'Sao Bras Do Suacui - MG' },
  { label: 'Sao Domingos Das Dores - MG' },
  { label: 'Sao Domingos Do Prata - MG' },
  { label: 'Sao Felix De Minas - MG' },
  { label: 'Sao Francisco - MG' },
  { label: 'Sao Francisco De Paula - MG' },
  { label: 'Sao Francisco De Sales - MG' },
  { label: 'Sao Francisco Do Gloria - MG' },
  { label: 'Sao Geraldo - MG' },
  { label: 'Sao Geraldo Da Piedade - MG' },
  { label: 'Sao Geraldo Do Baixio - MG' },
  { label: 'Sao Goncalo Do Abaete - MG' },
  { label: 'Sao Goncalo Do Para - MG' },
  { label: 'Sao Goncalo Do Rio Abaixo - MG' },
  { label: 'Sao Goncalo Do Sapucai - MG' },
  { label: 'Sao Gotardo - MG' },
  { label: 'Sao Joao Batista Do Gloria - MG' },
  { label: 'Sao Joao Da Lagoa - MG' },
  { label: 'Sao Joao Da Mata - MG' },
  { label: 'Sao Joao Da Ponte - MG' },
  { label: 'Sao Joao Das Missoes - MG' },
  { label: 'Sao Joao Del Rei - MG' },
  { label: 'Sao Joao Do Manhuacu - MG' },
  { label: 'Sao Joao Do Manteninha - MG' },
  { label: 'Sao Joao Do Oriente - MG' },
  { label: 'Sao Joao Do Pacui - MG' },
  { label: 'Sao Joao Do Paraiso - MG' },
  { label: 'Sao Joao Evangelista - MG' },
  { label: 'Sao Joao Nepomuceno - MG' },
  { label: 'Sao Joaquim De Bicas - MG' },
  { label: 'Sao Jose Da Barra - MG' },
  { label: 'Sao Jose Da Lapa - MG' },
  { label: 'Sao Jose Da Safira - MG' },
  { label: 'Sao Jose Da Varginha - MG' },
  { label: 'Sao Jose Do Alegre - MG' },
  { label: 'Sao Jose Do Divino - MG' },
  { label: 'Sao Jose Do Goiabal - MG' },
  { label: 'Sao Jose Do Jacuri - MG' },
  { label: 'Sao Jose Do Mantimento - MG' },
  { label: 'Sao Lourenco - MG' },
  { label: 'Sao Miguel Do Anta - MG' },
  { label: 'Sao Pedro Da Uniao - MG' },
  { label: 'Sao Pedro Dos Ferros - MG' },
  { label: 'Sao Pedro Do Suacui - MG' },
  { label: 'Sao Romao - MG' },
  { label: 'Sao Roque De Minas - MG' },
  { label: 'Sao Sebastiao Da Bela Vista - MG' },
  { label: 'Sao Sebastiao Da Vargem Alegre - MG' },
  { label: 'Sao Sebastiao Do Anta - MG' },
  { label: 'Sao Sebastiao Do Maranhao - MG' },
  { label: 'Sao Sebastiao Do Oeste - MG' },
  { label: 'Sao Sebastiao Do Paraiso - MG' },
  { label: 'Sao Sebastiao Do Rio Preto - MG' },
  { label: 'Sao Sebastiao Do Rio Verde - MG' },
  { label: 'Sao Tiago - MG' },
  { label: 'Sao Tomas De Aquino - MG' },
  { label: 'Sao Thome Das Letras - MG' },
  { label: 'Sao Vicente De Minas - MG' },
  { label: 'Sapucai-mirim - MG' },
  { label: 'Sardoa - MG' },
  { label: 'Sarzedo - MG' },
  { label: 'Setubinha - MG' },
  { label: 'Sem-peixe - MG' },
  { label: 'Senador Amaral - MG' },
  { label: 'Senador Cortes - MG' },
  { label: 'Senador Firmino - MG' },
  { label: 'Senador Jose Bento - MG' },
  { label: 'Senador Modestino Goncalves - MG' },
  { label: 'Senhora De Oliveira - MG' },
  { label: 'Senhora Do Porto - MG' },
  { label: 'Senhora Dos Remedios - MG' },
  { label: 'Sericita - MG' },
  { label: 'Seritinga - MG' },
  { label: 'Serra Azul De Minas - MG' },
  { label: 'Serra Da Saudade - MG' },
  { label: 'Serra Dos Aimores - MG' },
  { label: 'Serra Do Salitre - MG' },
  { label: 'Serrania - MG' },
  { label: 'Serranopolis De Minas - MG' },
  { label: 'Serranos - MG' },
  { label: 'Serro - MG' },
  { label: 'Sete Lagoas - MG' },
  { label: 'Silveirania - MG' },
  { label: 'Silvianopolis - MG' },
  { label: 'Simao Pereira - MG' },
  { label: 'Simonesia - MG' },
  { label: 'Sobralia - MG' },
  { label: 'Soledade De Minas - MG' },
  { label: 'Tabuleiro - MG' },
  { label: 'Taiobeiras - MG' },
  { label: 'Taparuba - MG' },
  { label: 'Tapira - MG' },
  { label: 'Tapirai - MG' },
  { label: 'Taquaracu De Minas - MG' },
  { label: 'Tarumirim - MG' },
  { label: 'Teixeiras - MG' },
  { label: 'Teofilo Otoni - MG' },
  { label: 'Timoteo - MG' },
  { label: 'Tiradentes - MG' },
  { label: 'Tiros - MG' },
  { label: 'Tocantins - MG' },
  { label: 'Tocos Do Moji - MG' },
  { label: 'Toledo - MG' },
  { label: 'Tombos - MG' },
  { label: 'Tres Coracoes - MG' },
  { label: 'Tres Marias - MG' },
  { label: 'Tres Pontas - MG' },
  { label: 'Tumiritinga - MG' },
  { label: 'Tupaciguara - MG' },
  { label: 'Turmalina - MG' },
  { label: 'Turvolandia - MG' },
  { label: 'Uba - MG' },
  { label: 'Ubai - MG' },
  { label: 'Ubaporanga - MG' },
  { label: 'Uberaba - MG' },
  { label: 'Uberlandia - MG' },
  { label: 'Umburatiba - MG' },
  { label: 'Unai - MG' },
  { label: 'Uniao De Minas - MG' },
  { label: 'Uruana De Minas - MG' },
  { label: 'Urucania - MG' },
  { label: 'Urucuia - MG' },
  { label: 'Vargem Alegre - MG' },
  { label: 'Vargem Bonita - MG' },
  { label: 'Vargem Grande Do Rio Pardo - MG' },
  { label: 'Varginha - MG' },
  { label: 'Varjao De Minas - MG' },
  { label: 'Varzea Da Palma - MG' },
  { label: 'Varzelandia - MG' },
  { label: 'Vazante - MG' },
  { label: 'Verdelandia - MG' },
  { label: 'Veredinha - MG' },
  { label: 'Verissimo - MG' },
  { label: 'Vermelho Novo - MG' },
  { label: 'Vespasiano - MG' },
  { label: 'Vicosa - MG' },
  { label: 'Vieiras - MG' },
  { label: 'Mathias Lobato - MG' },
  { label: 'Virgem Da Lapa - MG' },
  { label: 'Virginia - MG' },
  { label: 'Virginopolis - MG' },
  { label: 'Virgolandia - MG' },
  { label: 'Visconde Do Rio Branco - MG' },
  { label: 'Volta Grande - MG' },
  { label: 'Wenceslau Braz - MG' },
  { label: 'Afonso Claudio - ES' },
  { label: 'Aguia Branca - ES' },
  { label: 'Agua Doce Do Norte - ES' },
  { label: 'Alegre - ES' },
  { label: 'Alfredo Chaves - ES' },
  { label: 'Alto Rio Novo - ES' },
  { label: 'Anchieta - ES' },
  { label: 'Apiaca - ES' },
  { label: 'Aracruz - ES' },
  { label: 'Atilio Vivacqua - ES' },
  { label: 'Baixo Guandu - ES' },
  { label: 'Barra De Sao Francisco - ES' },
  { label: 'Boa Esperanca - ES' },
  { label: 'Bom Jesus Do Norte - ES' },
  { label: 'Brejetuba - ES' },
  { label: 'Cachoeiro De Itapemirim - ES' },
  { label: 'Cariacica - ES' },
  { label: 'Castelo - ES' },
  { label: 'Colatina - ES' },
  { label: 'Conceicao Da Barra - ES' },
  { label: 'Conceicao Do Castelo - ES' },
  { label: 'Divino De Sao Lourenco - ES' },
  { label: 'Domingos Martins - ES' },
  { label: 'Dores Do Rio Preto - ES' },
  { label: 'Ecoporanga - ES' },
  { label: 'Fundao - ES' },
  { label: 'Governador Lindenberg - ES' },
  { label: 'Guacui - ES' },
  { label: 'Guarapari - ES' },
  { label: 'Ibatiba - ES' },
  { label: 'Ibiracu - ES' },
  { label: 'Ibitirama - ES' },
  { label: 'Iconha - ES' },
  { label: 'Irupi - ES' },
  { label: 'Itaguacu - ES' },
  { label: 'Itapemirim - ES' },
  { label: 'Itarana - ES' },
  { label: 'Iuna - ES' },
  { label: 'Jaguare - ES' },
  { label: 'Jeronimo Monteiro - ES' },
  { label: 'Joao Neiva - ES' },
  { label: 'Laranja Da Terra - ES' },
  { label: 'Linhares - ES' },
  { label: 'Mantenopolis - ES' },
  { label: 'Marataizes - ES' },
  { label: 'Marechal Floriano - ES' },
  { label: 'Marilandia - ES' },
  { label: 'Mimoso Do Sul - ES' },
  { label: 'Montanha - ES' },
  { label: 'Mucurici - ES' },
  { label: 'Muniz Freire - ES' },
  { label: 'Muqui - ES' },
  { label: 'Nova Venecia - ES' },
  { label: 'Pancas - ES' },
  { label: 'Pedro Canario - ES' },
  { label: 'Pinheiros - ES' },
  { label: 'Piuma - ES' },
  { label: 'Ponto Belo - ES' },
  { label: 'Presidente Kennedy - ES' },
  { label: 'Rio Bananal - ES' },
  { label: 'Rio Novo Do Sul - ES' },
  { label: 'Santa Leopoldina - ES' },
  { label: 'Santa Maria De Jetiba - ES' },
  { label: 'Santa Teresa - ES' },
  { label: 'Sao Domingos Do Norte - ES' },
  { label: 'Sao Gabriel Da Palha - ES' },
  { label: 'Sao Jose Do Calcado - ES' },
  { label: 'Sao Mateus - ES' },
  { label: 'Sao Roque Do Canaa - ES' },
  { label: 'Serra - ES' },
  { label: 'Sooretama - ES' },
  { label: 'Vargem Alta - ES' },
  { label: 'Venda Nova Do Imigrante - ES' },
  { label: 'Viana - ES' },
  { label: 'Vila Pavao - ES' },
  { label: 'Vila Valerio - ES' },
  { label: 'Vila Velha - ES' },
  { label: 'Vitoria - ES' },
  { label: 'Angra Dos Reis - RJ' },
  { label: 'Aperibe - RJ' },
  { label: 'Araruama - RJ' },
  { label: 'Areal - RJ' },
  { label: 'Armacao Dos Buzios - RJ' },
  { label: 'Arraial Do Cabo - RJ' },
  { label: 'Barra Do Pirai - RJ' },
  { label: 'Barra Mansa - RJ' },
  { label: 'Belford Roxo - RJ' },
  { label: 'Bom Jardim - RJ' },
  { label: 'Bom Jesus Do Itabapoana - RJ' },
  { label: 'Cabo Frio - RJ' },
  { label: 'Cachoeiras De Macacu - RJ' },
  { label: 'Cambuci - RJ' },
  { label: 'Carapebus - RJ' },
  { label: 'Comendador Levy Gasparian - RJ' },
  { label: 'Campos Dos Goytacazes - RJ' },
  { label: 'Cantagalo - RJ' },
  { label: 'Cardoso Moreira - RJ' },
  { label: 'Carmo - RJ' },
  { label: 'Casimiro De Abreu - RJ' },
  { label: 'Conceicao De Macabu - RJ' },
  { label: 'Cordeiro - RJ' },
  { label: 'Duas Barras - RJ' },
  { label: 'Duque De Caxias - RJ' },
  { label: 'Engenheiro Paulo De Frontin - RJ' },
  { label: 'Guapimirim - RJ' },
  { label: 'Iguaba Grande - RJ' },
  { label: 'Itaborai - RJ' },
  { label: 'Itaguai - RJ' },
  { label: 'Italva - RJ' },
  { label: 'Itaocara - RJ' },
  { label: 'Itaperuna - RJ' },
  { label: 'Itatiaia - RJ' },
  { label: 'Japeri - RJ' },
  { label: 'Laje Do Muriae - RJ' },
  { label: 'Macae - RJ' },
  { label: 'Macuco - RJ' },
  { label: 'Mage - RJ' },
  { label: 'Mangaratiba - RJ' },
  { label: 'Marica - RJ' },
  { label: 'Mendes - RJ' },
  { label: 'Mesquita - RJ' },
  { label: 'Miguel Pereira - RJ' },
  { label: 'Miracema - RJ' },
  { label: 'Natividade - RJ' },
  { label: 'Nilopolis - RJ' },
  { label: 'Niteroi - RJ' },
  { label: 'Nova Friburgo - RJ' },
  { label: 'Nova Iguacu - RJ' },
  { label: 'Paracambi - RJ' },
  { label: 'Paraiba Do Sul - RJ' },
  { label: 'Paraty - RJ' },
  { label: 'Paty Do Alferes - RJ' },
  { label: 'Petropolis - RJ' },
  { label: 'Pinheiral - RJ' },
  { label: 'Pirai - RJ' },
  { label: 'Porciuncula - RJ' },
  { label: 'Porto Real - RJ' },
  { label: 'Quatis - RJ' },
  { label: 'Queimados - RJ' },
  { label: 'Quissama - RJ' },
  { label: 'Resende - RJ' },
  { label: 'Rio Bonito - RJ' },
  { label: 'Rio Claro - RJ' },
  { label: 'Rio Das Flores - RJ' },
  { label: 'Rio Das Ostras - RJ' },
  { label: 'Rio De Janeiro - RJ' },
  { label: 'Santa Maria Madalena - RJ' },
  { label: 'Santo Antonio De Padua - RJ' },
  { label: 'Sao Francisco De Itabapoana - RJ' },
  { label: 'Sao Fidelis - RJ' },
  { label: 'Sao Goncalo - RJ' },
  { label: 'Sao Joao Da Barra - RJ' },
  { label: 'Sao Joao De Meriti - RJ' },
  { label: 'Sao Jose De Uba - RJ' },
  { label: 'Sao Jose Do Vale Do Rio Preto - RJ' },
  { label: 'Sao Pedro Da Aldeia - RJ' },
  { label: 'Sao Sebastiao Do Alto - RJ' },
  { label: 'Sapucaia - RJ' },
  { label: 'Saquarema - RJ' },
  { label: 'Seropedica - RJ' },
  { label: 'Silva Jardim - RJ' },
  { label: 'Sumidouro - RJ' },
  { label: 'Tangua - RJ' },
  { label: 'Teresopolis - RJ' },
  { label: 'Trajano De Moraes - RJ' },
  { label: 'Tres Rios - RJ' },
  { label: 'Valenca - RJ' },
  { label: 'Varre-sai - RJ' },
  { label: 'Vassouras - RJ' },
  { label: 'Volta Redonda - RJ' },
  { label: 'Adamantina - SP' },
  { label: 'Adolfo - SP' },
  { label: 'Aguai - SP' },
  { label: 'Aguas Da Prata - SP' },
  { label: 'Aguas De Lindoia - SP' },
  { label: 'Aguas De Santa Barbara - SP' },
  { label: 'Aguas De Sao Pedro - SP' },
  { label: 'Agudos - SP' },
  { label: 'Alambari - SP' },
  { label: 'Alfredo Marcondes - SP' },
  { label: 'Altair - SP' },
  { label: 'Altinopolis - SP' },
  { label: 'Alto Alegre - SP' },
  { label: 'Aluminio - SP' },
  { label: 'Alvares Florence - SP' },
  { label: 'Alvares Machado - SP' },
  { label: 'Alvaro De Carvalho - SP' },
  { label: 'Alvinlandia - SP' },
  { label: 'Americana - SP' },
  { label: 'Americo Brasiliense - SP' },
  { label: 'Americo De Campos - SP' },
  { label: 'Amparo - SP' },
  { label: 'Analandia - SP' },
  { label: 'Andradina - SP' },
  { label: 'Angatuba - SP' },
  { label: 'Anhembi - SP' },
  { label: 'Anhumas - SP' },
  { label: 'Aparecida - SP' },
  { label: `${''}Aparecida D'oeste - SP` },
  { label: 'Apiai - SP' },
  { label: 'Aracariguama - SP' },
  { label: 'Aracatuba - SP' },
  { label: 'Aracoiaba Da Serra - SP' },
  { label: 'Aramina - SP' },
  { label: 'Arandu - SP' },
  { label: 'Arapei - SP' },
  { label: 'Araraquara - SP' },
  { label: 'Araras - SP' },
  { label: 'Arco-iris - SP' },
  { label: 'Arealva - SP' },
  { label: 'Areias - SP' },
  { label: 'Areiopolis - SP' },
  { label: 'Ariranha - SP' },
  { label: 'Artur Nogueira - SP' },
  { label: 'Aruja - SP' },
  { label: 'Aspasia - SP' },
  { label: 'Assis - SP' },
  { label: 'Atibaia - SP' },
  { label: 'Auriflama - SP' },
  { label: 'Avai - SP' },
  { label: 'Avanhandava - SP' },
  { label: 'Avare - SP' },
  { label: 'Bady Bassitt - SP' },
  { label: 'Balbinos - SP' },
  { label: 'Balsamo - SP' },
  { label: 'Bananal - SP' },
  { label: 'Barao De Antonina - SP' },
  { label: 'Barbosa - SP' },
  { label: 'Bariri - SP' },
  { label: 'Barra Bonita - SP' },
  { label: 'Barra Do Chapeu - SP' },
  { label: 'Barra Do Turvo - SP' },
  { label: 'Barretos - SP' },
  { label: 'Barrinha - SP' },
  { label: 'Barueri - SP' },
  { label: 'Bastos - SP' },
  { label: 'Batatais - SP' },
  { label: 'Bauru - SP' },
  { label: 'Bebedouro - SP' },
  { label: 'Bento De Abreu - SP' },
  { label: 'Bernardino De Campos - SP' },
  { label: 'Bertioga - SP' },
  { label: 'Bilac - SP' },
  { label: 'Birigui - SP' },
  { label: 'Biritiba-mirim - SP' },
  { label: 'Boa Esperanca Do Sul - SP' },
  { label: 'Bocaina - SP' },
  { label: 'Bofete - SP' },
  { label: 'Boituva - SP' },
  { label: 'Bom Jesus Dos Perdoes - SP' },
  { label: 'Bom Sucesso De Itarare - SP' },
  { label: 'Bora - SP' },
  { label: 'Boraceia - SP' },
  { label: 'Borborema - SP' },
  { label: 'Borebi - SP' },
  { label: 'Botucatu - SP' },
  { label: 'Braganca Paulista - SP' },
  { label: 'Brauna - SP' },
  { label: 'Brejo Alegre - SP' },
  { label: 'Brodowski - SP' },
  { label: 'Brotas - SP' },
  { label: 'Buri - SP' },
  { label: 'Buritama - SP' },
  { label: 'Buritizal - SP' },
  { label: 'Cabralia Paulista - SP' },
  { label: 'Cabreuva - SP' },
  { label: 'Cacapava - SP' },
  { label: 'Cachoeira Paulista - SP' },
  { label: 'Caconde - SP' },
  { label: 'Cafelandia - SP' },
  { label: 'Caiabu - SP' },
  { label: 'Caieiras - SP' },
  { label: 'Caiua - SP' },
  { label: 'Cajamar - SP' },
  { label: 'Cajati - SP' },
  { label: 'Cajobi - SP' },
  { label: 'Cajuru - SP' },
  { label: 'Campina Do Monte Alegre - SP' },
  { label: 'Campinas - SP' },
  { label: 'Campo Limpo Paulista - SP' },
  { label: 'Campos Do Jordao - SP' },
  { label: 'Campos Novos Paulista - SP' },
  { label: 'Cananeia - SP' },
  { label: 'Canas - SP' },
  { label: 'Candido Mota - SP' },
  { label: 'Candido Rodrigues - SP' },
  { label: 'Canitar - SP' },
  { label: 'Capao Bonito - SP' },
  { label: 'Capela Do Alto - SP' },
  { label: 'Capivari - SP' },
  { label: 'Caraguatatuba - SP' },
  { label: 'Carapicuiba - SP' },
  { label: 'Cardoso - SP' },
  { label: 'Casa Branca - SP' },
  { label: 'Cassia Dos Coqueiros - SP' },
  { label: 'Castilho - SP' },
  { label: 'Catanduva - SP' },
  { label: 'Catigua - SP' },
  { label: 'Cedral - SP' },
  { label: 'Cerqueira Cesar - SP' },
  { label: 'Cerquilho - SP' },
  { label: 'Cesario Lange - SP' },
  { label: 'Charqueada - SP' },
  { label: 'Clementina - SP' },
  { label: 'Colina - SP' },
  { label: 'Colombia - SP' },
  { label: 'Conchal - SP' },
  { label: 'Conchas - SP' },
  { label: 'Cordeiropolis - SP' },
  { label: 'Coroados - SP' },
  { label: 'Coronel Macedo - SP' },
  { label: 'Corumbatai - SP' },
  { label: 'Cosmopolis - SP' },
  { label: 'Cosmorama - SP' },
  { label: 'Cotia - SP' },
  { label: 'Cravinhos - SP' },
  { label: 'Cristais Paulista - SP' },
  { label: 'Cruzalia - SP' },
  { label: 'Cruzeiro - SP' },
  { label: 'Cubatao - SP' },
  { label: 'Cunha - SP' },
  { label: 'Descalvado - SP' },
  { label: 'Diadema - SP' },
  { label: 'Dirce Reis - SP' },
  { label: 'Divinolandia - SP' },
  { label: 'Dobrada - SP' },
  { label: 'Dois Corregos - SP' },
  { label: 'Dolcinopolis - SP' },
  { label: 'Dourado - SP' },
  { label: 'Dracena - SP' },
  { label: 'Duartina - SP' },
  { label: 'Dumont - SP' },
  { label: 'Echapora - SP' },
  { label: 'Eldorado - SP' },
  { label: 'Elias Fausto - SP' },
  { label: 'Elisiario - SP' },
  { label: 'Embauba - SP' },
  { label: 'Embu - SP' },
  { label: 'Embu-guacu - SP' },
  { label: 'Emilianopolis - SP' },
  { label: 'Engenheiro Coelho - SP' },
  { label: 'Espirito Santo Do Pinhal - SP' },
  { label: 'Espirito Santo Do Turvo - SP' },
  { label: `${''}Estrela D'oeste - SP` },
  { label: 'Estrela Do Norte - SP' },
  { label: 'Euclides Da Cunha Paulista - SP' },
  { label: 'Fartura - SP' },
  { label: 'Fernandopolis - SP' },
  { label: 'Fernando Prestes - SP' },
  { label: 'Fernao - SP' },
  { label: 'Ferraz De Vasconcelos - SP' },
  { label: 'Flora Rica - SP' },
  { label: 'Floreal - SP' },
  { label: 'Florida Paulista - SP' },
  { label: 'Florinia - SP' },
  { label: 'Franca - SP' },
  { label: 'Francisco Morato - SP' },
  { label: 'Franco Da Rocha - SP' },
  { label: 'Gabriel Monteiro - SP' },
  { label: 'Galia - SP' },
  { label: 'Garca - SP' },
  { label: 'Gastao Vidigal - SP' },
  { label: 'Gaviao Peixoto - SP' },
  { label: 'General Salgado - SP' },
  { label: 'Getulina - SP' },
  { label: 'Glicerio - SP' },
  { label: 'Guaicara - SP' },
  { label: 'Guaimbe - SP' },
  { label: 'Guaira - SP' },
  { label: 'Guapiacu - SP' },
  { label: 'Guapiara - SP' },
  { label: 'Guara - SP' },
  { label: 'Guaracai - SP' },
  { label: 'Guaraci - SP' },
  { label: `${''}Guarani D'oeste - SP` },
  { label: 'Guaranta - SP' },
  { label: 'Guararapes - SP' },
  { label: 'Guararema - SP' },
  { label: 'Guaratingueta - SP' },
  { label: 'Guarei - SP' },
  { label: 'Guariba - SP' },
  { label: 'Guaruja - SP' },
  { label: 'Guarulhos - SP' },
  { label: 'Guatapara - SP' },
  { label: 'Guzolandia - SP' },
  { label: 'Herculandia - SP' },
  { label: 'Holambra - SP' },
  { label: 'Hortolandia - SP' },
  { label: 'Iacanga - SP' },
  { label: 'Iacri - SP' },
  { label: 'Iaras - SP' },
  { label: 'Ibate - SP' },
  { label: 'Ibira - SP' },
  { label: 'Ibirarema - SP' },
  { label: 'Ibitinga - SP' },
  { label: 'Ibiuna - SP' },
  { label: 'Icem - SP' },
  { label: 'Iepe - SP' },
  { label: 'Igaracu Do Tiete - SP' },
  { label: 'Igarapava - SP' },
  { label: 'Igarata - SP' },
  { label: 'Iguape - SP' },
  { label: 'Ilhabela - SP' },
  { label: 'Ilha Comprida - SP' },
  { label: 'Ilha Solteira - SP' },
  { label: 'Indaiatuba - SP' },
  { label: 'Indiana - SP' },
  { label: 'Indiapora - SP' },
  { label: 'Inubia Paulista - SP' },
  { label: 'Ipaussu - SP' },
  { label: 'Ipero - SP' },
  { label: 'Ipeuna - SP' },
  { label: 'Ipigua - SP' },
  { label: 'Iporanga - SP' },
  { label: 'Ipua - SP' },
  { label: 'Iracemapolis - SP' },
  { label: 'Irapua - SP' },
  { label: 'Irapuru - SP' },
  { label: 'Itabera - SP' },
  { label: 'Itai - SP' },
  { label: 'Itajobi - SP' },
  { label: 'Itaju - SP' },
  { label: 'Itanhaem - SP' },
  { label: 'Itaoca - SP' },
  { label: 'Itapecerica Da Serra - SP' },
  { label: 'Itapetininga - SP' },
  { label: 'Itapeva - SP' },
  { label: 'Itapevi - SP' },
  { label: 'Itapira - SP' },
  { label: 'Itapirapua Paulista - SP' },
  { label: 'Itapolis - SP' },
  { label: 'Itaporanga - SP' },
  { label: 'Itapui - SP' },
  { label: 'Itapura - SP' },
  { label: 'Itaquaquecetuba - SP' },
  { label: 'Itarare - SP' },
  { label: 'Itariri - SP' },
  { label: 'Itatiba - SP' },
  { label: 'Itatinga - SP' },
  { label: 'Itirapina - SP' },
  { label: 'Itirapua - SP' },
  { label: 'Itobi - SP' },
  { label: 'Itu - SP' },
  { label: 'Itupeva - SP' },
  { label: 'Ituverava - SP' },
  { label: 'Jaborandi - SP' },
  { label: 'Jaboticabal - SP' },
  { label: 'Jacarei - SP' },
  { label: 'Jaci - SP' },
  { label: 'Jacupiranga - SP' },
  { label: 'Jaguariuna - SP' },
  { label: 'Jales - SP' },
  { label: 'Jambeiro - SP' },
  { label: 'Jandira - SP' },
  { label: 'Jardinopolis - SP' },
  { label: 'Jarinu - SP' },
  { label: 'Jau - SP' },
  { label: 'Jeriquara - SP' },
  { label: 'Joanopolis - SP' },
  { label: 'Joao Ramalho - SP' },
  { label: 'Jose Bonifacio - SP' },
  { label: 'Julio Mesquita - SP' },
  { label: 'Jumirim - SP' },
  { label: 'Jundiai - SP' },
  { label: 'Junqueiropolis - SP' },
  { label: 'Juquia - SP' },
  { label: 'Juquitiba - SP' },
  { label: 'Lagoinha - SP' },
  { label: 'Laranjal Paulista - SP' },
  { label: 'Lavinia - SP' },
  { label: 'Lavrinhas - SP' },
  { label: 'Leme - SP' },
  { label: 'Lencois Paulista - SP' },
  { label: 'Limeira - SP' },
  { label: 'Lindoia - SP' },
  { label: 'Lins - SP' },
  { label: 'Lorena - SP' },
  { label: 'Lourdes - SP' },
  { label: 'Louveira - SP' },
  { label: 'Lucelia - SP' },
  { label: 'Lucianopolis - SP' },
  { label: 'Luis Antonio - SP' },
  { label: 'Luiziania - SP' },
  { label: 'Lupercio - SP' },
  { label: 'Lutecia - SP' },
  { label: 'Macatuba - SP' },
  { label: 'Macaubal - SP' },
  { label: 'Macedonia - SP' },
  { label: 'Magda - SP' },
  { label: 'Mairinque - SP' },
  { label: 'Mairipora - SP' },
  { label: 'Manduri - SP' },
  { label: 'Maraba Paulista - SP' },
  { label: 'Maracai - SP' },
  { label: 'Marapoama - SP' },
  { label: 'Mariapolis - SP' },
  { label: 'Marilia - SP' },
  { label: 'Marinopolis - SP' },
  { label: 'Martinopolis - SP' },
  { label: 'Matao - SP' },
  { label: 'Maua - SP' },
  { label: 'Mendonca - SP' },
  { label: 'Meridiano - SP' },
  { label: 'Mesopolis - SP' },
  { label: 'Miguelopolis - SP' },
  { label: 'Mineiros Do Tiete - SP' },
  { label: 'Miracatu - SP' },
  { label: 'Mira Estrela - SP' },
  { label: 'Mirandopolis - SP' },
  { label: 'Mirante Do Paranapanema - SP' },
  { label: 'Mirassol - SP' },
  { label: 'Mirassolandia - SP' },
  { label: 'Mococa - SP' },
  { label: 'Mogi Das Cruzes - SP' },
  { label: 'Mogi Guacu - SP' },
  { label: 'Moji Mirim - SP' },
  { label: 'Mombuca - SP' },
  { label: 'Moncoes - SP' },
  { label: 'Mongagua - SP' },
  { label: 'Monte Alegre Do Sul - SP' },
  { label: 'Monte Alto - SP' },
  { label: 'Monte Aprazivel - SP' },
  { label: 'Monte Azul Paulista - SP' },
  { label: 'Monte Castelo - SP' },
  { label: 'Monteiro Lobato - SP' },
  { label: 'Monte Mor - SP' },
  { label: 'Morro Agudo - SP' },
  { label: 'Morungaba - SP' },
  { label: 'Motuca - SP' },
  { label: 'Murutinga Do Sul - SP' },
  { label: 'Nantes - SP' },
  { label: 'Narandiba - SP' },
  { label: 'Natividade Da Serra - SP' },
  { label: 'Nazare Paulista - SP' },
  { label: 'Neves Paulista - SP' },
  { label: 'Nhandeara - SP' },
  { label: 'Nipoa - SP' },
  { label: 'Nova Alianca - SP' },
  { label: 'Nova Campina - SP' },
  { label: 'Nova Canaa Paulista - SP' },
  { label: 'Nova Castilho - SP' },
  { label: 'Nova Europa - SP' },
  { label: 'Nova Granada - SP' },
  { label: 'Nova Guataporanga - SP' },
  { label: 'Nova Independencia - SP' },
  { label: 'Novais - SP' },
  { label: 'Nova Luzitania - SP' },
  { label: 'Nova Odessa - SP' },
  { label: 'Novo Horizonte - SP' },
  { label: 'Nuporanga - SP' },
  { label: 'Ocaucu - SP' },
  { label: 'Oleo - SP' },
  { label: 'Olimpia - SP' },
  { label: 'Onda Verde - SP' },
  { label: 'Oriente - SP' },
  { label: 'Orindiuva - SP' },
  { label: 'Orlandia - SP' },
  { label: 'Osasco - SP' },
  { label: 'Oscar Bressane - SP' },
  { label: 'Osvaldo Cruz - SP' },
  { label: 'Ourinhos - SP' },
  { label: 'Ouroeste - SP' },
  { label: 'Ouro Verde - SP' },
  { label: 'Pacaembu - SP' },
  { label: 'Palestina - SP' },
  { label: 'Palmares Paulista - SP' },
  { label: `${''}Palmeira D'oeste - SP` },
  { label: 'Palmital - SP' },
  { label: 'Panorama - SP' },
  { label: 'Paraguacu Paulista - SP' },
  { label: 'Paraibuna - SP' },
  { label: 'Paraiso - SP' },
  { label: 'Paranapanema - SP' },
  { label: 'Paranapua - SP' },
  { label: 'Parapua - SP' },
  { label: 'Pardinho - SP' },
  { label: 'Pariquera-acu - SP' },
  { label: 'Parisi - SP' },
  { label: 'Patrocinio Paulista - SP' },
  { label: 'Pauliceia - SP' },
  { label: 'Paulinia - SP' },
  { label: 'Paulistania - SP' },
  { label: 'Paulo De Faria - SP' },
  { label: 'Pederneiras - SP' },
  { label: 'Pedra Bela - SP' },
  { label: 'Pedranopolis - SP' },
  { label: 'Pedregulho - SP' },
  { label: 'Pedreira - SP' },
  { label: 'Pedrinhas Paulista - SP' },
  { label: 'Pedro De Toledo - SP' },
  { label: 'Penapolis - SP' },
  { label: 'Pereira Barreto - SP' },
  { label: 'Pereiras - SP' },
  { label: 'Peruibe - SP' },
  { label: 'Piacatu - SP' },
  { label: 'Piedade - SP' },
  { label: 'Pilar Do Sul - SP' },
  { label: 'Pindamonhangaba - SP' },
  { label: 'Pindorama - SP' },
  { label: 'Pinhalzinho - SP' },
  { label: 'Piquerobi - SP' },
  { label: 'Piquete - SP' },
  { label: 'Piracaia - SP' },
  { label: 'Piracicaba - SP' },
  { label: 'Piraju - SP' },
  { label: 'Pirajui - SP' },
  { label: 'Pirangi - SP' },
  { label: 'Pirapora Do Bom Jesus - SP' },
  { label: 'Pirapozinho - SP' },
  { label: 'Pirassununga - SP' },
  { label: 'Piratininga - SP' },
  { label: 'Pitangueiras - SP' },
  { label: 'Planalto - SP' },
  { label: 'Platina - SP' },
  { label: 'Poa - SP' },
  { label: 'Poloni - SP' },
  { label: 'Pompeia - SP' },
  { label: 'Pongai - SP' },
  { label: 'Pontal - SP' },
  { label: 'Pontalinda - SP' },
  { label: 'Pontes Gestal - SP' },
  { label: 'Populina - SP' },
  { label: 'Porangaba - SP' },
  { label: 'Porto Feliz - SP' },
  { label: 'Porto Ferreira - SP' },
  { label: 'Potim - SP' },
  { label: 'Potirendaba - SP' },
  { label: 'Pracinha - SP' },
  { label: 'Pradopolis - SP' },
  { label: 'Praia Grande - SP' },
  { label: 'Pratania - SP' },
  { label: 'Presidente Alves - SP' },
  { label: 'Presidente Bernardes - SP' },
  { label: 'Presidente Epitacio - SP' },
  { label: 'Presidente Prudente - SP' },
  { label: 'Presidente Venceslau - SP' },
  { label: 'Promissao - SP' },
  { label: 'Quadra - SP' },
  { label: 'Quata - SP' },
  { label: 'Queiroz - SP' },
  { label: 'Queluz - SP' },
  { label: 'Quintana - SP' },
  { label: 'Rafard - SP' },
  { label: 'Rancharia - SP' },
  { label: 'Redencao Da Serra - SP' },
  { label: 'Regente Feijo - SP' },
  { label: 'Reginopolis - SP' },
  { label: 'Registro - SP' },
  { label: 'Restinga - SP' },
  { label: 'Ribeira - SP' },
  { label: 'Ribeirao Bonito - SP' },
  { label: 'Ribeirao Branco - SP' },
  { label: 'Ribeirao Corrente - SP' },
  { label: 'Ribeirao Do Sul - SP' },
  { label: 'Ribeirao Dos Indios - SP' },
  { label: 'Ribeirao Grande - SP' },
  { label: 'Ribeirao Pires - SP' },
  { label: 'Ribeirao Preto - SP' },
  { label: 'Riversul - SP' },
  { label: 'Rifaina - SP' },
  { label: 'Rincao - SP' },
  { label: 'Rinopolis - SP' },
  { label: 'Rio Claro - SP' },
  { label: 'Rio Das Pedras - SP' },
  { label: 'Rio Grande Da Serra - SP' },
  { label: 'Riolandia - SP' },
  { label: 'Rosana - SP' },
  { label: 'Roseira - SP' },
  { label: 'Rubiacea - SP' },
  { label: 'Rubineia - SP' },
  { label: 'Sabino - SP' },
  { label: 'Sagres - SP' },
  { label: 'Sales - SP' },
  { label: 'Sales Oliveira - SP' },
  { label: 'Salesopolis - SP' },
  { label: 'Salmourao - SP' },
  { label: 'Saltinho - SP' },
  { label: 'Salto - SP' },
  { label: 'Salto De Pirapora - SP' },
  { label: 'Salto Grande - SP' },
  { label: 'Sandovalina - SP' },
  { label: 'Santa Adelia - SP' },
  { label: 'Santa Albertina - SP' },
  { label: `${''}Santa Barbara D'oeste - SP` },
  { label: 'Santa Branca - SP' },
  { label: `${''}Santa Clara D'oeste - SP` },
  { label: 'Santa Cruz Da Conceicao - SP' },
  { label: 'Santa Cruz Da Esperanca - SP' },
  { label: 'Santa Cruz Das Palmeiras - SP' },
  { label: 'Santa Cruz Do Rio Pardo - SP' },
  { label: 'Santa Ernestina - SP' },
  { label: 'Santa Fe Do Sul - SP' },
  { label: 'Santa Gertrudes - SP' },
  { label: 'Santa Isabel - SP' },
  { label: 'Santa Lucia - SP' },
  { label: 'Santa Maria Da Serra - SP' },
  { label: 'Santa Mercedes - SP' },
  { label: 'Santana Da Ponte Pensa - SP' },
  { label: 'Santana De Parnaiba - SP' },
  { label: `${''}Santa Rita D'oeste - SP` },
  { label: 'Santa Rita Do Passa Quatro - SP' },
  { label: 'Santa Rosa De Viterbo - SP' },
  { label: 'Santa Salete - SP' },
  { label: 'Santo Anastacio - SP' },
  { label: 'Santo Andre - SP' },
  { label: 'Santo Antonio Da Alegria - SP' },
  { label: 'Santo Antonio De Posse - SP' },
  { label: 'Santo Antonio Do Aracangua - SP' },
  { label: 'Santo Antonio Do Jardim - SP' },
  { label: 'Santo Antonio Do Pinhal - SP' },
  { label: 'Santo Expedito - SP' },
  { label: 'Santopolis Do Aguapei - SP' },
  { label: 'Santos - SP' },
  { label: 'Sao Bento Do Sapucai - SP' },
  { label: 'Sao Bernardo Do Campo - SP' },
  { label: 'Sao Caetano Do Sul - SP' },
  { label: 'Sao Carlos - SP' },
  { label: 'Sao Francisco - SP' },
  { label: 'Sao Joao Da Boa Vista - SP' },
  { label: 'Sao Joao Das Duas Pontes - SP' },
  { label: 'Sao Joao De Iracema - SP' },
  { label: `${''}Sao Joao Do Pau D'alho - SP` },
  { label: 'Sao Joaquim Da Barra - SP' },
  { label: 'Sao Jose Da Bela Vista - SP' },
  { label: 'Sao Jose Do Barreiro - SP' },
  { label: 'Sao Jose Do Rio Pardo - SP' },
  { label: 'Sao Jose Do Rio Preto - SP' },
  { label: 'Sao Jose Dos Campos - SP' },
  { label: 'Sao Lourenco Da Serra - SP' },
  { label: 'Sao Luis Do Paraitinga - SP' },
  { label: 'Sao Manuel - SP' },
  { label: 'Sao Miguel Arcanjo - SP' },
  { label: 'Sao Paulo - SP' },
  { label: 'Sao Pedro - SP' },
  { label: 'Sao Pedro Do Turvo - SP' },
  { label: 'Sao Roque - SP' },
  { label: 'Sao Sebastiao - SP' },
  { label: 'Sao Sebastiao Da Grama - SP' },
  { label: 'Sao Simao - SP' },
  { label: 'Sao Vicente - SP' },
  { label: 'Sarapui - SP' },
  { label: 'Sarutaia - SP' },
  { label: 'Sebastianopolis Do Sul - SP' },
  { label: 'Serra Azul - SP' },
  { label: 'Serrana - SP' },
  { label: 'Serra Negra - SP' },
  { label: 'Sertaozinho - SP' },
  { label: 'Sete Barras - SP' },
  { label: 'Severinia - SP' },
  { label: 'Silveiras - SP' },
  { label: 'Socorro - SP' },
  { label: 'Sorocaba - SP' },
  { label: 'Sud Mennucci - SP' },
  { label: 'Sumare - SP' },
  { label: 'Suzano - SP' },
  { label: 'Suzanapolis - SP' },
  { label: 'Tabapua - SP' },
  { label: 'Tabatinga - SP' },
  { label: 'Taboao Da Serra - SP' },
  { label: 'Taciba - SP' },
  { label: 'Taguai - SP' },
  { label: 'Taiacu - SP' },
  { label: 'Taiuva - SP' },
  { label: 'Tambau - SP' },
  { label: 'Tanabi - SP' },
  { label: 'Tapirai - SP' },
  { label: 'Tapiratiba - SP' },
  { label: 'Taquaral - SP' },
  { label: 'Taquaritinga - SP' },
  { label: 'Taquarituba - SP' },
  { label: 'Taquarivai - SP' },
  { label: 'Tarabai - SP' },
  { label: 'Taruma - SP' },
  { label: 'Tatui - SP' },
  { label: 'Taubate - SP' },
  { label: 'Tejupa - SP' },
  { label: 'Teodoro Sampaio - SP' },
  { label: 'Terra Roxa - SP' },
  { label: 'Tiete - SP' },
  { label: 'Timburi - SP' },
  { label: 'Torre De Pedra - SP' },
  { label: 'Torrinha - SP' },
  { label: 'Trabiju - SP' },
  { label: 'Tremembe - SP' },
  { label: 'Tres Fronteiras - SP' },
  { label: 'Tuiuti - SP' },
  { label: 'Tupa - SP' },
  { label: 'Tupi Paulista - SP' },
  { label: 'Turiuba - SP' },
  { label: 'Turmalina - SP' },
  { label: 'Ubarana - SP' },
  { label: 'Ubatuba - SP' },
  { label: 'Ubirajara - SP' },
  { label: 'Uchoa - SP' },
  { label: 'Uniao Paulista - SP' },
  { label: 'Urania - SP' },
  { label: 'Uru - SP' },
  { label: 'Urupes - SP' },
  { label: 'Valentim Gentil - SP' },
  { label: 'Valinhos - SP' },
  { label: 'Valparaiso - SP' },
  { label: 'Vargem - SP' },
  { label: 'Vargem Grande Do Sul - SP' },
  { label: 'Vargem Grande Paulista - SP' },
  { label: 'Varzea Paulista - SP' },
  { label: 'Vera Cruz - SP' },
  { label: 'Vinhedo - SP' },
  { label: 'Viradouro - SP' },
  { label: 'Vista Alegre Do Alto - SP' },
  { label: 'Vitoria Brasil - SP' },
  { label: 'Votorantim - SP' },
  { label: 'Votuporanga - SP' },
  { label: 'Zacarias - SP' },
  { label: 'Chavantes - SP' },
  { label: 'Estiva Gerbi - SP' },
  { label: 'Abatia - PR' },
  { label: 'Adrianopolis - PR' },
  { label: 'Agudos Do Sul - PR' },
  { label: 'Almirante Tamandare - PR' },
  { label: 'Altamira Do Parana - PR' },
  { label: 'Altonia - PR' },
  { label: 'Alto Parana - PR' },
  { label: 'Alto Piquiri - PR' },
  { label: 'Alvorada Do Sul - PR' },
  { label: 'Amapora - PR' },
  { label: 'Ampere - PR' },
  { label: 'Anahy - PR' },
  { label: 'Andira - PR' },
  { label: 'Angulo - PR' },
  { label: 'Antonina - PR' },
  { label: 'Antonio Olinto - PR' },
  { label: 'Apucarana - PR' },
  { label: 'Arapongas - PR' },
  { label: 'Arapoti - PR' },
  { label: 'Arapua - PR' },
  { label: 'Araruna - PR' },
  { label: 'Araucaria - PR' },
  { label: 'Ariranha Do Ivai - PR' },
  { label: 'Assai - PR' },
  { label: 'Assis Chateaubriand - PR' },
  { label: 'Astorga - PR' },
  { label: 'Atalaia - PR' },
  { label: 'Balsa Nova - PR' },
  { label: 'Bandeirantes - PR' },
  { label: 'Barbosa Ferraz - PR' },
  { label: 'Barracao - PR' },
  { label: 'Barra Do Jacare - PR' },
  { label: 'Bela Vista Da Caroba - PR' },
  { label: 'Bela Vista Do Paraiso - PR' },
  { label: 'Bituruna - PR' },
  { label: 'Boa Esperanca - PR' },
  { label: 'Boa Esperanca Do Iguacu - PR' },
  { label: 'Boa Ventura De Sao Roque - PR' },
  { label: 'Boa Vista Da Aparecida - PR' },
  { label: 'Bocaiuva Do Sul - PR' },
  { label: 'Bom Jesus Do Sul - PR' },
  { label: 'Bom Sucesso - PR' },
  { label: 'Bom Sucesso Do Sul - PR' },
  { label: 'Borrazopolis - PR' },
  { label: 'Braganey - PR' },
  { label: 'Brasilandia Do Sul - PR' },
  { label: 'Cafeara - PR' },
  { label: 'Cafelandia - PR' },
  { label: 'Cafezal Do Sul - PR' },
  { label: 'California - PR' },
  { label: 'Cambara - PR' },
  { label: 'Cambe - PR' },
  { label: 'Cambira - PR' },
  { label: 'Campina Da Lagoa - PR' },
  { label: 'Campina Do Simao - PR' },
  { label: 'Campina Grande Do Sul - PR' },
  { label: 'Campo Bonito - PR' },
  { label: 'Campo Do Tenente - PR' },
  { label: 'Campo Largo - PR' },
  { label: 'Campo Magro - PR' },
  { label: 'Campo Mourao - PR' },
  { label: 'Candido De Abreu - PR' },
  { label: 'Candoi - PR' },
  { label: 'Cantagalo - PR' },
  { label: 'Capanema - PR' },
  { label: 'Capitao Leonidas Marques - PR' },
  { label: 'Carambei - PR' },
  { label: 'Carlopolis - PR' },
  { label: 'Cascavel - PR' },
  { label: 'Castro - PR' },
  { label: 'Catanduvas - PR' },
  { label: 'Centenario Do Sul - PR' },
  { label: 'Cerro Azul - PR' },
  { label: 'Ceu Azul - PR' },
  { label: 'Chopinzinho - PR' },
  { label: 'Cianorte - PR' },
  { label: 'Cidade Gaucha - PR' },
  { label: 'Clevelandia - PR' },
  { label: 'Colombo - PR' },
  { label: 'Colorado - PR' },
  { label: 'Congonhinhas - PR' },
  { label: 'Conselheiro Mairinck - PR' },
  { label: 'Contenda - PR' },
  { label: 'Corbelia - PR' },
  { label: 'Cornelio Procopio - PR' },
  { label: 'Coronel Domingos Soares - PR' },
  { label: 'Coronel Vivida - PR' },
  { label: 'Corumbatai Do Sul - PR' },
  { label: 'Cruzeiro Do Iguacu - PR' },
  { label: 'Cruzeiro Do Oeste - PR' },
  { label: 'Cruzeiro Do Sul - PR' },
  { label: 'Cruz Machado - PR' },
  { label: 'Cruzmaltina - PR' },
  { label: 'Curitiba - PR' },
  { label: 'Curiuva - PR' },
  { label: 'Diamante Do Norte - PR' },
  { label: 'Diamante Do Sul - PR' },
  { label: `${''}Diamante D'oeste - PR` },
  { label: 'Dois Vizinhos - PR' },
  { label: 'Douradina - PR' },
  { label: 'Doutor Camargo - PR' },
  { label: 'Eneas Marques - PR' },
  { label: 'Engenheiro Beltrao - PR' },
  { label: 'Esperanca Nova - PR' },
  { label: 'Entre Rios Do Oeste - PR' },
  { label: 'Espigao Alto Do Iguacu - PR' },
  { label: 'Farol - PR' },
  { label: 'Faxinal - PR' },
  { label: 'Fazenda Rio Grande - PR' },
  { label: 'Fenix - PR' },
  { label: 'Fernandes Pinheiro - PR' },
  { label: 'Figueira - PR' },
  { label: 'Florai - PR' },
  { label: 'Flor Da Serra Do Sul - PR' },
  { label: 'Floresta - PR' },
  { label: 'Florestopolis - PR' },
  { label: 'Florida - PR' },
  { label: 'Formosa Do Oeste - PR' },
  { label: 'Foz Do Iguacu - PR' },
  { label: 'Francisco Alves - PR' },
  { label: 'Francisco Beltrao - PR' },
  { label: 'Foz Do Jordao - PR' },
  { label: 'General Carneiro - PR' },
  { label: 'Godoy Moreira - PR' },
  { label: 'Goioere - PR' },
  { label: 'Goioxim - PR' },
  { label: 'Grandes Rios - PR' },
  { label: 'Guaira - PR' },
  { label: 'Guairaca - PR' },
  { label: 'Guamiranga - PR' },
  { label: 'Guapirama - PR' },
  { label: 'Guaporema - PR' },
  { label: 'Guaraci - PR' },
  { label: 'Guaraniacu - PR' },
  { label: 'Guarapuava - PR' },
  { label: 'Guaraquecaba - PR' },
  { label: 'Guaratuba - PR' },
  { label: 'Honorio Serpa - PR' },
  { label: 'Ibaiti - PR' },
  { label: 'Ibema - PR' },
  { label: 'Ibipora - PR' },
  { label: 'Icaraima - PR' },
  { label: 'Iguaracu - PR' },
  { label: 'Iguatu - PR' },
  { label: 'Imbau - PR' },
  { label: 'Imbituva - PR' },
  { label: 'Inacio Martins - PR' },
  { label: 'Inaja - PR' },
  { label: 'Indianopolis - PR' },
  { label: 'Ipiranga - PR' },
  { label: 'Ipora - PR' },
  { label: 'Iracema Do Oeste - PR' },
  { label: 'Irati - PR' },
  { label: 'Iretama - PR' },
  { label: 'Itaguaje - PR' },
  { label: 'Itaipulandia - PR' },
  { label: 'Itambaraca - PR' },
  { label: 'Itambe - PR' },
  { label: `${''}Itapejara D'oeste - PR` },
  { label: 'Itaperucu - PR' },
  { label: 'Itauna Do Sul - PR' },
  { label: 'Ivai - PR' },
  { label: 'Ivaipora - PR' },
  { label: 'Ivate - PR' },
  { label: 'Ivatuba - PR' },
  { label: 'Jaboti - PR' },
  { label: 'Jacarezinho - PR' },
  { label: 'Jaguapita - PR' },
  { label: 'Jaguariaiva - PR' },
  { label: 'Jandaia Do Sul - PR' },
  { label: 'Janiopolis - PR' },
  { label: 'Japira - PR' },
  { label: 'Japura - PR' },
  { label: 'Jardim Alegre - PR' },
  { label: 'Jardim Olinda - PR' },
  { label: 'Jataizinho - PR' },
  { label: 'Jesuitas - PR' },
  { label: 'Joaquim Tavora - PR' },
  { label: 'Jundiai Do Sul - PR' },
  { label: 'Juranda - PR' },
  { label: 'Jussara - PR' },
  { label: 'Kalore - PR' },
  { label: 'Lapa - PR' },
  { label: 'Laranjal - PR' },
  { label: 'Laranjeiras Do Sul - PR' },
  { label: 'Leopolis - PR' },
  { label: 'Lidianopolis - PR' },
  { label: 'Lindoeste - PR' },
  { label: 'Loanda - PR' },
  { label: 'Lobato - PR' },
  { label: 'Londrina - PR' },
  { label: 'Luiziana - PR' },
  { label: 'Lunardelli - PR' },
  { label: 'Lupionopolis - PR' },
  { label: 'Mallet - PR' },
  { label: 'Mambore - PR' },
  { label: 'Mandaguacu - PR' },
  { label: 'Mandaguari - PR' },
  { label: 'Mandirituba - PR' },
  { label: 'Manfrinopolis - PR' },
  { label: 'Mangueirinha - PR' },
  { label: 'Manoel Ribas - PR' },
  { label: 'Marechal Candido Rondon - PR' },
  { label: 'Maria Helena - PR' },
  { label: 'Marialva - PR' },
  { label: 'Marilandia Do Sul - PR' },
  { label: 'Marilena - PR' },
  { label: 'Mariluz - PR' },
  { label: 'Maringa - PR' },
  { label: 'Mariopolis - PR' },
  { label: 'Maripa - PR' },
  { label: 'Marmeleiro - PR' },
  { label: 'Marquinho - PR' },
  { label: 'Marumbi - PR' },
  { label: 'Matelandia - PR' },
  { label: 'Matinhos - PR' },
  { label: 'Mato Rico - PR' },
  { label: 'Maua Da Serra - PR' },
  { label: 'Medianeira - PR' },
  { label: 'Mercedes - PR' },
  { label: 'Mirador - PR' },
  { label: 'Miraselva - PR' },
  { label: 'Missal - PR' },
  { label: 'Moreira Sales - PR' },
  { label: 'Morretes - PR' },
  { label: 'Munhoz De Melo - PR' },
  { label: 'Nossa Senhora Das Gracas - PR' },
  { label: 'Nova Alianca Do Ivai - PR' },
  { label: 'Nova America Da Colina - PR' },
  { label: 'Nova Aurora - PR' },
  { label: 'Nova Cantu - PR' },
  { label: 'Nova Esperanca - PR' },
  { label: 'Nova Esperanca Do Sudoeste - PR' },
  { label: 'Nova Fatima - PR' },
  { label: 'Nova Laranjeiras - PR' },
  { label: 'Nova Londrina - PR' },
  { label: 'Nova Olimpia - PR' },
  { label: 'Nova Santa Barbara - PR' },
  { label: 'Nova Santa Rosa - PR' },
  { label: 'Nova Prata Do Iguacu - PR' },
  { label: 'Nova Tebas - PR' },
  { label: 'Novo Itacolomi - PR' },
  { label: 'Ortigueira - PR' },
  { label: 'Ourizona - PR' },
  { label: 'Ouro Verde Do Oeste - PR' },
  { label: 'Paicandu - PR' },
  { label: 'Palmas - PR' },
  { label: 'Palmeira - PR' },
  { label: 'Palmital - PR' },
  { label: 'Palotina - PR' },
  { label: 'Paraiso Do Norte - PR' },
  { label: 'Paranacity - PR' },
  { label: 'Paranagua - PR' },
  { label: 'Paranapoema - PR' },
  { label: 'Paranavai - PR' },
  { label: 'Pato Bragado - PR' },
  { label: 'Pato Branco - PR' },
  { label: 'Paula Freitas - PR' },
  { label: 'Paulo Frontin - PR' },
  { label: 'Peabiru - PR' },
  { label: 'Perobal - PR' },
  { label: 'Perola - PR' },
  { label: `${''}Perola D'oeste - PR` },
  { label: 'Pien - PR' },
  { label: 'Pinhais - PR' },
  { label: 'Pinhalao - PR' },
  { label: 'Pinhal De Sao Bento - PR' },
  { label: 'Pinhao - PR' },
  { label: 'Pirai Do Sul - PR' },
  { label: 'Piraquara - PR' },
  { label: 'Pitanga - PR' },
  { label: 'Pitangueiras - PR' },
  { label: 'Planaltina Do Parana - PR' },
  { label: 'Planalto - PR' },
  { label: 'Ponta Grossa - PR' },
  { label: 'Pontal Do Parana - PR' },
  { label: 'Porecatu - PR' },
  { label: 'Porto Amazonas - PR' },
  { label: 'Porto Barreiro - PR' },
  { label: 'Porto Rico - PR' },
  { label: 'Porto Vitoria - PR' },
  { label: 'Prado Ferreira - PR' },
  { label: 'Pranchita - PR' },
  { label: 'Presidente Castelo Branco - PR' },
  { label: 'Primeiro De Maio - PR' },
  { label: 'Prudentopolis - PR' },
  { label: 'Quarto Centenario - PR' },
  { label: 'Quatigua - PR' },
  { label: 'Quatro Barras - PR' },
  { label: 'Quatro Pontes - PR' },
  { label: 'Quedas Do Iguacu - PR' },
  { label: 'Querencia Do Norte - PR' },
  { label: 'Quinta Do Sol - PR' },
  { label: 'Quitandinha - PR' },
  { label: 'Ramilandia - PR' },
  { label: 'Rancho Alegre - PR' },
  { label: `${''}Rancho Alegre D'oeste - PR` },
  { label: 'Realeza - PR' },
  { label: 'Reboucas - PR' },
  { label: 'Renascenca - PR' },
  { label: 'Reserva - PR' },
  { label: 'Reserva Do Iguacu - PR' },
  { label: 'Ribeirao Claro - PR' },
  { label: 'Ribeirao Do Pinhal - PR' },
  { label: 'Rio Azul - PR' },
  { label: 'Rio Bom - PR' },
  { label: 'Rio Bonito Do Iguacu - PR' },
  { label: 'Rio Branco Do Ivai - PR' },
  { label: 'Rio Branco Do Sul - PR' },
  { label: 'Rio Negro - PR' },
  { label: 'Rolandia - PR' },
  { label: 'Roncador - PR' },
  { label: 'Rondon - PR' },
  { label: 'Rosario Do Ivai - PR' },
  { label: 'Sabaudia - PR' },
  { label: 'Salgado Filho - PR' },
  { label: 'Salto Do Itarare - PR' },
  { label: 'Salto Do Lontra - PR' },
  { label: 'Santa Amelia - PR' },
  { label: 'Santa Cecilia Do Pavao - PR' },
  { label: 'Santa Cruz De Monte Castelo - PR' },
  { label: 'Santa Fe - PR' },
  { label: 'Santa Helena - PR' },
  { label: 'Santa Ines - PR' },
  { label: 'Santa Isabel Do Ivai - PR' },
  { label: 'Santa Izabel Do Oeste - PR' },
  { label: 'Santa Lucia - PR' },
  { label: 'Santa Maria Do Oeste - PR' },
  { label: 'Santa Mariana - PR' },
  { label: 'Santa Monica - PR' },
  { label: 'Santana Do Itarare - PR' },
  { label: 'Santa Tereza Do Oeste - PR' },
  { label: 'Santa Terezinha De Itaipu - PR' },
  { label: 'Santo Antonio Da Platina - PR' },
  { label: 'Santo Antonio Do Caiua - PR' },
  { label: 'Santo Antonio Do Paraiso - PR' },
  { label: 'Santo Antonio Do Sudoeste - PR' },
  { label: 'Santo Inacio - PR' },
  { label: 'Sao Carlos Do Ivai - PR' },
  { label: 'Sao Jeronimo Da Serra - PR' },
  { label: 'Sao Joao - PR' },
  { label: 'Sao Joao Do Caiua - PR' },
  { label: 'Sao Joao Do Ivai - PR' },
  { label: 'Sao Joao Do Triunfo - PR' },
  { label: `${''}Sao Jorge D'oeste - PR` },
  { label: 'Sao Jorge Do Ivai - PR' },
  { label: 'Sao Jorge Do Patrocinio - PR' },
  { label: 'Sao Jose Da Boa Vista - PR' },
  { label: 'Sao Jose Das Palmeiras - PR' },
  { label: 'Sao Jose Dos Pinhais - PR' },
  { label: 'Sao Manoel Do Parana - PR' },
  { label: 'Sao Mateus Do Sul - PR' },
  { label: 'Sao Miguel Do Iguacu - PR' },
  { label: 'Sao Pedro Do Iguacu - PR' },
  { label: 'Sao Pedro Do Ivai - PR' },
  { label: 'Sao Pedro Do Parana - PR' },
  { label: 'Sao Sebastiao Da Amoreira - PR' },
  { label: 'Sao Tome - PR' },
  { label: 'Sapopema - PR' },
  { label: 'Sarandi - PR' },
  { label: 'Saudade Do Iguacu - PR' },
  { label: 'Senges - PR' },
  { label: 'Serranopolis Do Iguacu - PR' },
  { label: 'Sertaneja - PR' },
  { label: 'Sertanopolis - PR' },
  { label: 'Siqueira Campos - PR' },
  { label: 'Sulina - PR' },
  { label: 'Tamarana - PR' },
  { label: 'Tamboara - PR' },
  { label: 'Tapejara - PR' },
  { label: 'Tapira - PR' },
  { label: 'Teixeira Soares - PR' },
  { label: 'Telemaco Borba - PR' },
  { label: 'Terra Boa - PR' },
  { label: 'Terra Rica - PR' },
  { label: 'Terra Roxa - PR' },
  { label: 'Tibagi - PR' },
  { label: 'Tijucas Do Sul - PR' },
  { label: 'Toledo - PR' },
  { label: 'Tomazina - PR' },
  { label: 'Tres Barras Do Parana - PR' },
  { label: 'Tunas Do Parana - PR' },
  { label: 'Tuneiras Do Oeste - PR' },
  { label: 'Tupassi - PR' },
  { label: 'Turvo - PR' },
  { label: 'Ubirata - PR' },
  { label: 'Umuarama - PR' },
  { label: 'Uniao Da Vitoria - PR' },
  { label: 'Uniflor - PR' },
  { label: 'Urai - PR' },
  { label: 'Wenceslau Braz - PR' },
  { label: 'Ventania - PR' },
  { label: 'Vera Cruz Do Oeste - PR' },
  { label: 'Vere - PR' },
  { label: 'Alto Paraiso - PR' },
  { label: 'Doutor Ulysses - PR' },
  { label: 'Virmond - PR' },
  { label: 'Vitorino - PR' },
  { label: 'Xambre - PR' },
  { label: 'Abdon Batista - SC' },
  { label: 'Abelardo Luz - SC' },
  { label: 'Agrolandia - SC' },
  { label: 'Agronomica - SC' },
  { label: 'Agua Doce - SC' },
  { label: 'Aguas De Chapeco - SC' },
  { label: 'Aguas Frias - SC' },
  { label: 'Aguas Mornas - SC' },
  { label: 'Alfredo Wagner - SC' },
  { label: 'Alto Bela Vista - SC' },
  { label: 'Anchieta - SC' },
  { label: 'Angelina - SC' },
  { label: 'Anita Garibaldi - SC' },
  { label: 'Anitapolis - SC' },
  { label: 'Antonio Carlos - SC' },
  { label: 'Apiuna - SC' },
  { label: 'Arabuta - SC' },
  { label: 'Araquari - SC' },
  { label: 'Ararangua - SC' },
  { label: 'Armazem - SC' },
  { label: 'Arroio Trinta - SC' },
  { label: 'Arvoredo - SC' },
  { label: 'Ascurra - SC' },
  { label: 'Atalanta - SC' },
  { label: 'Aurora - SC' },
  { label: 'Balneario Arroio Do Silva - SC' },
  { label: 'Balneario Camboriu - SC' },
  { label: 'Balneario Barra Do Sul - SC' },
  { label: 'Balneario Gaivota - SC' },
  { label: 'Bandeirante - SC' },
  { label: 'Barra Bonita - SC' },
  { label: 'Barra Velha - SC' },
  { label: 'Bela Vista Do Toldo - SC' },
  { label: 'Belmonte - SC' },
  { label: 'Benedito Novo - SC' },
  { label: 'Biguacu - SC' },
  { label: 'Blumenau - SC' },
  { label: 'Bocaina Do Sul - SC' },
  { label: 'Bombinhas - SC' },
  { label: 'Bom Jardim Da Serra - SC' },
  { label: 'Bom Jesus - SC' },
  { label: 'Bom Jesus Do Oeste - SC' },
  { label: 'Bom Retiro - SC' },
  { label: 'Botuvera - SC' },
  { label: 'Braco Do Norte - SC' },
  { label: 'Braco Do Trombudo - SC' },
  { label: 'Brunopolis - SC' },
  { label: 'Brusque - SC' },
  { label: 'Cacador - SC' },
  { label: 'Caibi - SC' },
  { label: 'Calmon - SC' },
  { label: 'Camboriu - SC' },
  { label: 'Capao Alto - SC' },
  { label: 'Campo Alegre - SC' },
  { label: 'Campo Belo Do Sul - SC' },
  { label: 'Campo Ere - SC' },
  { label: 'Campos Novos - SC' },
  { label: 'Canelinha - SC' },
  { label: 'Canoinhas - SC' },
  { label: 'Capinzal - SC' },
  { label: 'Capivari De Baixo - SC' },
  { label: 'Catanduvas - SC' },
  { label: 'Caxambu Do Sul - SC' },
  { label: 'Celso Ramos - SC' },
  { label: 'Cerro Negro - SC' },
  { label: 'Chapadao Do Lageado - SC' },
  { label: 'Chapeco - SC' },
  { label: 'Cocal Do Sul - SC' },
  { label: 'Concordia - SC' },
  { label: 'Cordilheira Alta - SC' },
  { label: 'Coronel Freitas - SC' },
  { label: 'Coronel Martins - SC' },
  { label: 'Corupa - SC' },
  { label: 'Correia Pinto - SC' },
  { label: 'Criciuma - SC' },
  { label: 'Cunha Pora - SC' },
  { label: 'Cunhatai - SC' },
  { label: 'Curitibanos - SC' },
  { label: 'Descanso - SC' },
  { label: 'Dionisio Cerqueira - SC' },
  { label: 'Dona Emma - SC' },
  { label: 'Doutor Pedrinho - SC' },
  { label: 'Entre Rios - SC' },
  { label: 'Ermo - SC' },
  { label: 'Erval Velho - SC' },
  { label: 'Faxinal Dos Guedes - SC' },
  { label: 'Flor Do Sertao - SC' },
  { label: 'Florianopolis - SC' },
  { label: 'Formosa Do Sul - SC' },
  { label: 'Forquilhinha - SC' },
  { label: 'Fraiburgo - SC' },
  { label: 'Frei Rogerio - SC' },
  { label: 'Galvao - SC' },
  { label: 'Garopaba - SC' },
  { label: 'Garuva - SC' },
  { label: 'Gaspar - SC' },
  { label: 'Governador Celso Ramos - SC' },
  { label: 'Grao Para - SC' },
  { label: 'Gravatal - SC' },
  { label: 'Guabiruba - SC' },
  { label: 'Guaraciaba - SC' },
  { label: 'Guaramirim - SC' },
  { label: 'Guaruja Do Sul - SC' },
  { label: 'Guatambu - SC' },
  { label: `${''}Herval D'oeste - SC` },
  { label: 'Ibiam - SC' },
  { label: 'Ibicare - SC' },
  { label: 'Ibirama - SC' },
  { label: 'Icara - SC' },
  { label: 'Ilhota - SC' },
  { label: 'Imarui - SC' },
  { label: 'Imbituba - SC' },
  { label: 'Imbuia - SC' },
  { label: 'Indaial - SC' },
  { label: 'Iomere - SC' },
  { label: 'Ipira - SC' },
  { label: 'Ipora Do Oeste - SC' },
  { label: 'Ipuacu - SC' },
  { label: 'Ipumirim - SC' },
  { label: 'Iraceminha - SC' },
  { label: 'Irani - SC' },
  { label: 'Irati - SC' },
  { label: 'Irineopolis - SC' },
  { label: 'Ita - SC' },
  { label: 'Itaiopolis - SC' },
  { label: 'Itajai - SC' },
  { label: 'Itapema - SC' },
  { label: 'Itapiranga - SC' },
  { label: 'Itapoa - SC' },
  { label: 'Ituporanga - SC' },
  { label: 'Jabora - SC' },
  { label: 'Jacinto Machado - SC' },
  { label: 'Jaguaruna - SC' },
  { label: 'Jaragua Do Sul - SC' },
  { label: 'Jardinopolis - SC' },
  { label: 'Joacaba - SC' },
  { label: 'Joinville - SC' },
  { label: 'Jose Boiteux - SC' },
  { label: 'Jupia - SC' },
  { label: 'Lacerdopolis - SC' },
  { label: 'Lages - SC' },
  { label: 'Laguna - SC' },
  { label: 'Lajeado Grande - SC' },
  { label: 'Laurentino - SC' },
  { label: 'Lauro Muller - SC' },
  { label: 'Lebon Regis - SC' },
  { label: 'Leoberto Leal - SC' },
  { label: 'Lindoia Do Sul - SC' },
  { label: 'Lontras - SC' },
  { label: 'Luiz Alves - SC' },
  { label: 'Luzerna - SC' },
  { label: 'Macieira - SC' },
  { label: 'Mafra - SC' },
  { label: 'Major Gercino - SC' },
  { label: 'Major Vieira - SC' },
  { label: 'Maracaja - SC' },
  { label: 'Maravilha - SC' },
  { label: 'Marema - SC' },
  { label: 'Massaranduba - SC' },
  { label: 'Matos Costa - SC' },
  { label: 'Meleiro - SC' },
  { label: 'Mirim Doce - SC' },
  { label: 'Modelo - SC' },
  { label: 'Mondai - SC' },
  { label: 'Monte Carlo - SC' },
  { label: 'Monte Castelo - SC' },
  { label: 'Morro Da Fumaca - SC' },
  { label: 'Morro Grande - SC' },
  { label: 'Navegantes - SC' },
  { label: 'Nova Erechim - SC' },
  { label: 'Nova Itaberaba - SC' },
  { label: 'Nova Trento - SC' },
  { label: 'Nova Veneza - SC' },
  { label: 'Novo Horizonte - SC' },
  { label: 'Orleans - SC' },
  { label: 'Otacilio Costa - SC' },
  { label: 'Ouro - SC' },
  { label: 'Ouro Verde - SC' },
  { label: 'Paial - SC' },
  { label: 'Painel - SC' },
  { label: 'Palhoca - SC' },
  { label: 'Palma Sola - SC' },
  { label: 'Palmeira - SC' },
  { label: 'Palmitos - SC' },
  { label: 'Papanduva - SC' },
  { label: 'Paraiso - SC' },
  { label: 'Passo De Torres - SC' },
  { label: 'Passos Maia - SC' },
  { label: 'Paulo Lopes - SC' },
  { label: 'Pedras Grandes - SC' },
  { label: 'Penha - SC' },
  { label: 'Peritiba - SC' },
  { label: 'Petrolandia - SC' },
  { label: 'Balneario Picarras - SC' },
  { label: 'Pinhalzinho - SC' },
  { label: 'Pinheiro Preto - SC' },
  { label: 'Piratuba - SC' },
  { label: 'Planalto Alegre - SC' },
  { label: 'Pomerode - SC' },
  { label: 'Ponte Alta - SC' },
  { label: 'Ponte Alta Do Norte - SC' },
  { label: 'Ponte Serrada - SC' },
  { label: 'Porto Belo - SC' },
  { label: 'Porto Uniao - SC' },
  { label: 'Pouso Redondo - SC' },
  { label: 'Praia Grande - SC' },
  { label: 'Presidente Castello Branco - SC' },
  { label: 'Presidente Getulio - SC' },
  { label: 'Presidente Nereu - SC' },
  { label: 'Princesa - SC' },
  { label: 'Quilombo - SC' },
  { label: 'Rancho Queimado - SC' },
  { label: 'Rio Das Antas - SC' },
  { label: 'Rio Do Campo - SC' },
  { label: 'Rio Do Oeste - SC' },
  { label: 'Rio Dos Cedros - SC' },
  { label: 'Rio Do Sul - SC' },
  { label: 'Rio Fortuna - SC' },
  { label: 'Rio Negrinho - SC' },
  { label: 'Rio Rufino - SC' },
  { label: 'Riqueza - SC' },
  { label: 'Rodeio - SC' },
  { label: 'Romelandia - SC' },
  { label: 'Salete - SC' },
  { label: 'Saltinho - SC' },
  { label: 'Salto Veloso - SC' },
  { label: 'Sangao - SC' },
  { label: 'Santa Cecilia - SC' },
  { label: 'Santa Helena - SC' },
  { label: 'Santa Rosa De Lima - SC' },
  { label: 'Santa Rosa Do Sul - SC' },
  { label: 'Santa Terezinha - SC' },
  { label: 'Santa Terezinha Do Progresso - SC' },
  { label: 'Santiago Do Sul - SC' },
  { label: 'Santo Amaro Da Imperatriz - SC' },
  { label: 'Sao Bernardino - SC' },
  { label: 'Sao Bento Do Sul - SC' },
  { label: 'Sao Bonifacio - SC' },
  { label: 'Sao Carlos - SC' },
  { label: 'Sao Cristovao Do Sul - SC' },
  { label: 'Sao Domingos - SC' },
  { label: 'Sao Francisco Do Sul - SC' },
  { label: 'Sao Joao Do Oeste - SC' },
  { label: 'Sao Joao Batista - SC' },
  { label: 'Sao Joao Do Itaperiu - SC' },
  { label: 'Sao Joao Do Sul - SC' },
  { label: 'Sao Joaquim - SC' },
  { label: 'Sao Jose - SC' },
  { label: 'Sao Jose Do Cedro - SC' },
  { label: 'Sao Jose Do Cerrito - SC' },
  { label: 'Sao Lourenco Do Oeste - SC' },
  { label: 'Sao Ludgero - SC' },
  { label: 'Sao Martinho - SC' },
  { label: 'Sao Miguel Da Boa Vista - SC' },
  { label: 'Sao Miguel Do Oeste - SC' },
  { label: 'Sao Pedro De Alcantara - SC' },
  { label: 'Saudades - SC' },
  { label: 'Schroeder - SC' },
  { label: 'Seara - SC' },
  { label: 'Serra Alta - SC' },
  { label: 'Sideropolis - SC' },
  { label: 'Sombrio - SC' },
  { label: 'Sul Brasil - SC' },
  { label: 'Taio - SC' },
  { label: 'Tangara - SC' },
  { label: 'Tigrinhos - SC' },
  { label: 'Tijucas - SC' },
  { label: 'Timbe Do Sul - SC' },
  { label: 'Timbo - SC' },
  { label: 'Timbo Grande - SC' },
  { label: 'Tres Barras - SC' },
  { label: 'Treviso - SC' },
  { label: 'Treze De Maio - SC' },
  { label: 'Treze Tilias - SC' },
  { label: 'Trombudo Central - SC' },
  { label: 'Tubarao - SC' },
  { label: 'Tunapolis - SC' },
  { label: 'Turvo - SC' },
  { label: 'Uniao Do Oeste - SC' },
  { label: 'Urubici - SC' },
  { label: 'Urupema - SC' },
  { label: 'Urussanga - SC' },
  { label: 'Vargeao - SC' },
  { label: 'Vargem - SC' },
  { label: 'Vargem Bonita - SC' },
  { label: 'Vidal Ramos - SC' },
  { label: 'Videira - SC' },
  { label: 'Vitor Meireles - SC' },
  { label: 'Witmarsum - SC' },
  { label: 'Xanxere - SC' },
  { label: 'Xavantina - SC' },
  { label: 'Xaxim - SC' },
  { label: 'Zortea - SC' },
  { label: 'Acegua - RS' },
  { label: 'Agua Santa - RS' },
  { label: 'Agudo - RS' },
  { label: 'Ajuricaba - RS' },
  { label: 'Alecrim - RS' },
  { label: 'Alegrete - RS' },
  { label: 'Alegria - RS' },
  { label: 'Almirante Tamandare Do Sul - RS' },
  { label: 'Alpestre - RS' },
  { label: 'Alto Alegre - RS' },
  { label: 'Alto Feliz - RS' },
  { label: 'Alvorada - RS' },
  { label: 'Amaral Ferrador - RS' },
  { label: 'Ametista Do Sul - RS' },
  { label: 'Andre Da Rocha - RS' },
  { label: 'Anta Gorda - RS' },
  { label: 'Antonio Prado - RS' },
  { label: 'Arambare - RS' },
  { label: 'Ararica - RS' },
  { label: 'Aratiba - RS' },
  { label: 'Arroio Do Meio - RS' },
  { label: 'Arroio Do Sal - RS' },
  { label: 'Arroio Do Padre - RS' },
  { label: 'Arroio Dos Ratos - RS' },
  { label: 'Arroio Do Tigre - RS' },
  { label: 'Arroio Grande - RS' },
  { label: 'Arvorezinha - RS' },
  { label: 'Augusto Pestana - RS' },
  { label: 'Aurea - RS' },
  { label: 'Bage - RS' },
  { label: 'Balneario Pinhal - RS' },
  { label: 'Barao - RS' },
  { label: 'Barao De Cotegipe - RS' },
  { label: 'Barao Do Triunfo - RS' },
  { label: 'Barracao - RS' },
  { label: 'Barra Do Guarita - RS' },
  { label: 'Barra Do Quarai - RS' },
  { label: 'Barra Do Ribeiro - RS' },
  { label: 'Barra Do Rio Azul - RS' },
  { label: 'Barra Funda - RS' },
  { label: 'Barros Cassal - RS' },
  { label: 'Benjamin Constant Do Sul - RS' },
  { label: 'Bento Goncalves - RS' },
  { label: 'Boa Vista Das Missoes - RS' },
  { label: 'Boa Vista Do Burica - RS' },
  { label: 'Boa Vista Do Cadeado - RS' },
  { label: 'Boa Vista Do Incra - RS' },
  { label: 'Boa Vista Do Sul - RS' },
  { label: 'Bom Jesus - RS' },
  { label: 'Bom Principio - RS' },
  { label: 'Bom Progresso - RS' },
  { label: 'Bom Retiro Do Sul - RS' },
  { label: 'Boqueirao Do Leao - RS' },
  { label: 'Bossoroca - RS' },
  { label: 'Bozano - RS' },
  { label: 'Braga - RS' },
  { label: 'Brochier - RS' },
  { label: 'Butia - RS' },
  { label: 'Cacapava Do Sul - RS' },
  { label: 'Cacequi - RS' },
  { label: 'Cachoeira Do Sul - RS' },
  { label: 'Cachoeirinha - RS' },
  { label: 'Cacique Doble - RS' },
  { label: 'Caibate - RS' },
  { label: 'Caicara - RS' },
  { label: 'Camaqua - RS' },
  { label: 'Camargo - RS' },
  { label: 'Cambara Do Sul - RS' },
  { label: 'Campestre Da Serra - RS' },
  { label: 'Campina Das Missoes - RS' },
  { label: 'Campinas Do Sul - RS' },
  { label: 'Campo Bom - RS' },
  { label: 'Campo Novo - RS' },
  { label: 'Campos Borges - RS' },
  { label: 'Candelaria - RS' },
  { label: 'Candido Godoi - RS' },
  { label: 'Candiota - RS' },
  { label: 'Canela - RS' },
  { label: 'Cangucu - RS' },
  { label: 'Canoas - RS' },
  { label: 'Canudos Do Vale - RS' },
  { label: 'Capao Bonito Do Sul - RS' },
  { label: 'Capao Da Canoa - RS' },
  { label: 'Capao Do Cipo - RS' },
  { label: 'Capao Do Leao - RS' },
  { label: 'Capivari Do Sul - RS' },
  { label: 'Capela De Santana - RS' },
  { label: 'Capitao - RS' },
  { label: 'Carazinho - RS' },
  { label: 'Caraa - RS' },
  { label: 'Carlos Barbosa - RS' },
  { label: 'Carlos Gomes - RS' },
  { label: 'Casca - RS' },
  { label: 'Caseiros - RS' },
  { label: 'Catuipe - RS' },
  { label: 'Caxias Do Sul - RS' },
  { label: 'Centenario - RS' },
  { label: 'Cerrito - RS' },
  { label: 'Cerro Branco - RS' },
  { label: 'Cerro Grande - RS' },
  { label: 'Cerro Grande Do Sul - RS' },
  { label: 'Cerro Largo - RS' },
  { label: 'Chapada - RS' },
  { label: 'Charqueadas - RS' },
  { label: 'Charrua - RS' },
  { label: 'Chiapetta - RS' },
  { label: 'Chui - RS' },
  { label: 'Chuvisca - RS' },
  { label: 'Cidreira - RS' },
  { label: 'Ciriaco - RS' },
  { label: 'Colinas - RS' },
  { label: 'Colorado - RS' },
  { label: 'Condor - RS' },
  { label: 'Constantina - RS' },
  { label: 'Coqueiro Baixo - RS' },
  { label: 'Coqueiros Do Sul - RS' },
  { label: 'Coronel Barros - RS' },
  { label: 'Coronel Bicaco - RS' },
  { label: 'Coronel Pilar - RS' },
  { label: 'Cotipora - RS' },
  { label: 'Coxilha - RS' },
  { label: 'Crissiumal - RS' },
  { label: 'Cristal - RS' },
  { label: 'Cristal Do Sul - RS' },
  { label: 'Cruz Alta - RS' },
  { label: 'Cruzaltense - RS' },
  { label: 'Cruzeiro Do Sul - RS' },
  { label: 'David Canabarro - RS' },
  { label: 'Derrubadas - RS' },
  { label: 'Dezesseis De Novembro - RS' },
  { label: 'Dilermando De Aguiar - RS' },
  { label: 'Dois Irmaos - RS' },
  { label: 'Dois Irmaos Das Missoes - RS' },
  { label: 'Dois Lajeados - RS' },
  { label: 'Dom Feliciano - RS' },
  { label: 'Dom Pedro De Alcantara - RS' },
  { label: 'Dom Pedrito - RS' },
  { label: 'Dona Francisca - RS' },
  { label: 'Doutor Mauricio Cardoso - RS' },
  { label: 'Doutor Ricardo - RS' },
  { label: 'Eldorado Do Sul - RS' },
  { label: 'Encantado - RS' },
  { label: 'Encruzilhada Do Sul - RS' },
  { label: 'Engenho Velho - RS' },
  { label: 'Entre-ijuis - RS' },
  { label: 'Entre Rios Do Sul - RS' },
  { label: 'Erebango - RS' },
  { label: 'Erechim - RS' },
  { label: 'Ernestina - RS' },
  { label: 'Herval - RS' },
  { label: 'Erval Grande - RS' },
  { label: 'Erval Seco - RS' },
  { label: 'Esmeralda - RS' },
  { label: 'Esperanca Do Sul - RS' },
  { label: 'Espumoso - RS' },
  { label: 'Estacao - RS' },
  { label: 'Estancia Velha - RS' },
  { label: 'Esteio - RS' },
  { label: 'Estrela - RS' },
  { label: 'Estrela Velha - RS' },
  { label: 'Eugenio De Castro - RS' },
  { label: 'Fagundes Varela - RS' },
  { label: 'Farroupilha - RS' },
  { label: 'Faxinal Do Soturno - RS' },
  { label: 'Faxinalzinho - RS' },
  { label: 'Fazenda Vilanova - RS' },
  { label: 'Feliz - RS' },
  { label: 'Flores Da Cunha - RS' },
  { label: 'Floriano Peixoto - RS' },
  { label: 'Fontoura Xavier - RS' },
  { label: 'Formigueiro - RS' },
  { label: 'Forquetinha - RS' },
  { label: 'Fortaleza Dos Valos - RS' },
  { label: 'Frederico Westphalen - RS' },
  { label: 'Garibaldi - RS' },
  { label: 'Garruchos - RS' },
  { label: 'Gaurama - RS' },
  { label: 'General Camara - RS' },
  { label: 'Gentil - RS' },
  { label: 'Getulio Vargas - RS' },
  { label: 'Girua - RS' },
  { label: 'Glorinha - RS' },
  { label: 'Gramado - RS' },
  { label: 'Gramado Dos Loureiros - RS' },
  { label: 'Gramado Xavier - RS' },
  { label: 'Gravatai - RS' },
  { label: 'Guabiju - RS' },
  { label: 'Guaiba - RS' },
  { label: 'Guapore - RS' },
  { label: 'Guarani Das Missoes - RS' },
  { label: 'Harmonia - RS' },
  { label: 'Herveiras - RS' },
  { label: 'Horizontina - RS' },
  { label: 'Hulha Negra - RS' },
  { label: 'Humaita - RS' },
  { label: 'Ibarama - RS' },
  { label: 'Ibiaca - RS' },
  { label: 'Ibiraiaras - RS' },
  { label: 'Ibirapuita - RS' },
  { label: 'Ibiruba - RS' },
  { label: 'Igrejinha - RS' },
  { label: 'Ijui - RS' },
  { label: 'Ilopolis - RS' },
  { label: 'Imbe - RS' },
  { label: 'Imigrante - RS' },
  { label: 'Independencia - RS' },
  { label: 'Inhacora - RS' },
  { label: 'Ipe - RS' },
  { label: 'Ipiranga Do Sul - RS' },
  { label: 'Irai - RS' },
  { label: 'Itaara - RS' },
  { label: 'Itacurubi - RS' },
  { label: 'Itapuca - RS' },
  { label: 'Itaqui - RS' },
  { label: 'Itati - RS' },
  { label: 'Itatiba Do Sul - RS' },
  { label: 'Ivora - RS' },
  { label: 'Ivoti - RS' },
  { label: 'Jaboticaba - RS' },
  { label: 'Jacuizinho - RS' },
  { label: 'Jacutinga - RS' },
  { label: 'Jaguarao - RS' },
  { label: 'Jaguari - RS' },
  { label: 'Jaquirana - RS' },
  { label: 'Jari - RS' },
  { label: 'Joia - RS' },
  { label: 'Julio De Castilhos - RS' },
  { label: 'Lagoa Bonita Do Sul - RS' },
  { label: 'Lagoao - RS' },
  { label: 'Lagoa Dos Tres Cantos - RS' },
  { label: 'Lagoa Vermelha - RS' },
  { label: 'Lajeado - RS' },
  { label: 'Lajeado Do Bugre - RS' },
  { label: 'Lavras Do Sul - RS' },
  { label: 'Liberato Salzano - RS' },
  { label: 'Lindolfo Collor - RS' },
  { label: 'Linha Nova - RS' },
  { label: 'Machadinho - RS' },
  { label: 'Macambara - RS' },
  { label: 'Mampituba - RS' },
  { label: 'Manoel Viana - RS' },
  { label: 'Maquine - RS' },
  { label: 'Marata - RS' },
  { label: 'Marau - RS' },
  { label: 'Marcelino Ramos - RS' },
  { label: 'Mariana Pimentel - RS' },
  { label: 'Mariano Moro - RS' },
  { label: 'Marques De Souza - RS' },
  { label: 'Mata - RS' },
  { label: 'Mato Castelhano - RS' },
  { label: 'Mato Leitao - RS' },
  { label: 'Mato Queimado - RS' },
  { label: 'Maximiliano De Almeida - RS' },
  { label: 'Minas Do Leao - RS' },
  { label: 'Miraguai - RS' },
  { label: 'Montauri - RS' },
  { label: 'Monte Alegre Dos Campos - RS' },
  { label: 'Monte Belo Do Sul - RS' },
  { label: 'Montenegro - RS' },
  { label: 'Mormaco - RS' },
  { label: 'Morrinhos Do Sul - RS' },
  { label: 'Morro Redondo - RS' },
  { label: 'Morro Reuter - RS' },
  { label: 'Mostardas - RS' },
  { label: 'Mucum - RS' },
  { label: 'Muitos Capoes - RS' },
  { label: 'Muliterno - RS' },
  { label: 'Nao-me-toque - RS' },
  { label: 'Nicolau Vergueiro - RS' },
  { label: 'Nonoai - RS' },
  { label: 'Nova Alvorada - RS' },
  { label: 'Nova Araca - RS' },
  { label: 'Nova Bassano - RS' },
  { label: 'Nova Boa Vista - RS' },
  { label: 'Nova Brescia - RS' },
  { label: 'Nova Candelaria - RS' },
  { label: 'Nova Esperanca Do Sul - RS' },
  { label: 'Nova Hartz - RS' },
  { label: 'Nova Padua - RS' },
  { label: 'Nova Palma - RS' },
  { label: 'Nova Petropolis - RS' },
  { label: 'Nova Prata - RS' },
  { label: 'Nova Ramada - RS' },
  { label: 'Nova Roma Do Sul - RS' },
  { label: 'Nova Santa Rita - RS' },
  { label: 'Novo Cabrais - RS' },
  { label: 'Novo Hamburgo - RS' },
  { label: 'Novo Machado - RS' },
  { label: 'Novo Tiradentes - RS' },
  { label: 'Novo Xingu - RS' },
  { label: 'Novo Barreiro - RS' },
  { label: 'Osorio - RS' },
  { label: 'Paim Filho - RS' },
  { label: 'Palmares Do Sul - RS' },
  { label: 'Palmeira Das Missoes - RS' },
  { label: 'Palmitinho - RS' },
  { label: 'Panambi - RS' },
  { label: 'Pantano Grande - RS' },
  { label: 'Parai - RS' },
  { label: 'Paraiso Do Sul - RS' },
  { label: 'Pareci Novo - RS' },
  { label: 'Parobe - RS' },
  { label: 'Passa Sete - RS' },
  { label: 'Passo Do Sobrado - RS' },
  { label: 'Passo Fundo - RS' },
  { label: 'Paulo Bento - RS' },
  { label: 'Paverama - RS' },
  { label: 'Pedras Altas - RS' },
  { label: 'Pedro Osorio - RS' },
  { label: 'Pejucara - RS' },
  { label: 'Pelotas - RS' },
  { label: 'Picada Cafe - RS' },
  { label: 'Pinhal - RS' },
  { label: 'Pinhal Da Serra - RS' },
  { label: 'Pinhal Grande - RS' },
  { label: 'Pinheirinho Do Vale - RS' },
  { label: 'Pinheiro Machado - RS' },
  { label: 'Pirapo - RS' },
  { label: 'Piratini - RS' },
  { label: 'Planalto - RS' },
  { label: 'Poco Das Antas - RS' },
  { label: 'Pontao - RS' },
  { label: 'Ponte Preta - RS' },
  { label: 'Portao - RS' },
  { label: 'Porto Alegre - RS' },
  { label: 'Porto Lucena - RS' },
  { label: 'Porto Maua - RS' },
  { label: 'Porto Vera Cruz - RS' },
  { label: 'Porto Xavier - RS' },
  { label: 'Pouso Novo - RS' },
  { label: 'Presidente Lucena - RS' },
  { label: 'Progresso - RS' },
  { label: 'Protasio Alves - RS' },
  { label: 'Putinga - RS' },
  { label: 'Quarai - RS' },
  { label: 'Quatro Irmaos - RS' },
  { label: 'Quevedos - RS' },
  { label: 'Quinze De Novembro - RS' },
  { label: 'Redentora - RS' },
  { label: 'Relvado - RS' },
  { label: 'Restinga Seca - RS' },
  { label: 'Rio Dos Indios - RS' },
  { label: 'Rio Grande - RS' },
  { label: 'Rio Pardo - RS' },
  { label: 'Riozinho - RS' },
  { label: 'Roca Sales - RS' },
  { label: 'Rodeio Bonito - RS' },
  { label: 'Rolador - RS' },
  { label: 'Rolante - RS' },
  { label: 'Ronda Alta - RS' },
  { label: 'Rondinha - RS' },
  { label: 'Roque Gonzales - RS' },
  { label: 'Rosario Do Sul - RS' },
  { label: 'Sagrada Familia - RS' },
  { label: 'Saldanha Marinho - RS' },
  { label: 'Salto Do Jacui - RS' },
  { label: 'Salvador Das Missoes - RS' },
  { label: 'Salvador Do Sul - RS' },
  { label: 'Sananduva - RS' },
  { label: 'Santa Barbara Do Sul - RS' },
  { label: 'Santa Cecilia Do Sul - RS' },
  { label: 'Santa Clara Do Sul - RS' },
  { label: 'Santa Cruz Do Sul - RS' },
  { label: 'Santa Maria - RS' },
  { label: 'Santa Maria Do Herval - RS' },
  { label: 'Santa Margarida Do Sul - RS' },
  { label: 'Santana Da Boa Vista - RS' },
  { label: `${''}Sant'ana Do Livramento - RS` },
  { label: 'Santa Rosa - RS' },
  { label: 'Santa Tereza - RS' },
  { label: 'Santa Vitoria Do Palmar - RS' },
  { label: 'Santiago - RS' },
  { label: 'Santo Angelo - RS' },
  { label: 'Santo Antonio Do Palma - RS' },
  { label: 'Santo Antonio Da Patrulha - RS' },
  { label: 'Santo Antonio Das Missoes - RS' },
  { label: 'Santo Antonio Do Planalto - RS' },
  { label: 'Santo Augusto - RS' },
  { label: 'Santo Cristo - RS' },
  { label: 'Santo Expedito Do Sul - RS' },
  { label: 'Sao Borja - RS' },
  { label: 'Sao Domingos Do Sul - RS' },
  { label: 'Sao Francisco De Assis - RS' },
  { label: 'Sao Francisco De Paula - RS' },
  { label: 'Sao Gabriel - RS' },
  { label: 'Sao Jeronimo - RS' },
  { label: 'Sao Joao Da Urtiga - RS' },
  { label: 'Sao Joao Do Polesine - RS' },
  { label: 'Sao Jorge - RS' },
  { label: 'Sao Jose Das Missoes - RS' },
  { label: 'Sao Jose Do Herval - RS' },
  { label: 'Sao Jose Do Hortencio - RS' },
  { label: 'Sao Jose Do Inhacora - RS' },
  { label: 'Sao Jose Do Norte - RS' },
  { label: 'Sao Jose Do Ouro - RS' },
  { label: 'Sao Jose Do Sul - RS' },
  { label: 'Sao Jose Dos Ausentes - RS' },
  { label: 'Sao Leopoldo - RS' },
  { label: 'Sao Lourenco Do Sul - RS' },
  { label: 'Sao Luiz Gonzaga - RS' },
  { label: 'Sao Marcos - RS' },
  { label: 'Sao Martinho - RS' },
  { label: 'Sao Martinho Da Serra - RS' },
  { label: 'Sao Miguel Das Missoes - RS' },
  { label: 'Sao Nicolau - RS' },
  { label: 'Sao Paulo Das Missoes - RS' },
  { label: 'Sao Pedro Da Serra - RS' },
  { label: 'Sao Pedro Das Missoes - RS' },
  { label: 'Sao Pedro Do Butia - RS' },
  { label: 'Sao Pedro Do Sul - RS' },
  { label: 'Sao Sebastiao Do Cai - RS' },
  { label: 'Sao Sepe - RS' },
  { label: 'Sao Valentim - RS' },
  { label: 'Sao Valentim Do Sul - RS' },
  { label: 'Sao Valerio Do Sul - RS' },
  { label: 'Sao Vendelino - RS' },
  { label: 'Sao Vicente Do Sul - RS' },
  { label: 'Sapiranga - RS' },
  { label: 'Sapucaia Do Sul - RS' },
  { label: 'Sarandi - RS' },
  { label: 'Seberi - RS' },
  { label: 'Sede Nova - RS' },
  { label: 'Segredo - RS' },
  { label: 'Selbach - RS' },
  { label: 'Senador Salgado Filho - RS' },
  { label: 'Sentinela Do Sul - RS' },
  { label: 'Serafina Correa - RS' },
  { label: 'Serio - RS' },
  { label: 'Sertao - RS' },
  { label: 'Sertao Santana - RS' },
  { label: 'Sete De Setembro - RS' },
  { label: 'Severiano De Almeida - RS' },
  { label: 'Silveira Martins - RS' },
  { label: 'Sinimbu - RS' },
  { label: 'Sobradinho - RS' },
  { label: 'Soledade - RS' },
  { label: 'Tabai - RS' },
  { label: 'Tapejara - RS' },
  { label: 'Tapera - RS' },
  { label: 'Tapes - RS' },
  { label: 'Taquara - RS' },
  { label: 'Taquari - RS' },
  { label: 'Taquarucu Do Sul - RS' },
  { label: 'Tavares - RS' },
  { label: 'Tenente Portela - RS' },
  { label: 'Terra De Areia - RS' },
  { label: 'Teutonia - RS' },
  { label: 'Tio Hugo - RS' },
  { label: 'Tiradentes Do Sul - RS' },
  { label: 'Toropi - RS' },
  { label: 'Torres - RS' },
  { label: 'Tramandai - RS' },
  { label: 'Travesseiro - RS' },
  { label: 'Tres Arroios - RS' },
  { label: 'Tres Cachoeiras - RS' },
  { label: 'Tres Coroas - RS' },
  { label: 'Tres De Maio - RS' },
  { label: 'Tres Forquilhas - RS' },
  { label: 'Tres Palmeiras - RS' },
  { label: 'Tres Passos - RS' },
  { label: 'Trindade Do Sul - RS' },
  { label: 'Triunfo - RS' },
  { label: 'Tucunduva - RS' },
  { label: 'Tunas - RS' },
  { label: 'Tupanci Do Sul - RS' },
  { label: 'Tupancireta - RS' },
  { label: 'Tupandi - RS' },
  { label: 'Tuparendi - RS' },
  { label: 'Turucu - RS' },
  { label: 'Ubiretama - RS' },
  { label: 'Uniao Da Serra - RS' },
  { label: 'Unistalda - RS' },
  { label: 'Uruguaiana - RS' },
  { label: 'Vacaria - RS' },
  { label: 'Vale Verde - RS' },
  { label: 'Vale Do Sol - RS' },
  { label: 'Vale Real - RS' },
  { label: 'Vanini - RS' },
  { label: 'Venancio Aires - RS' },
  { label: 'Vera Cruz - RS' },
  { label: 'Veranopolis - RS' },
  { label: 'Vespasiano Correa - RS' },
  { label: 'Viadutos - RS' },
  { label: 'Viamao - RS' },
  { label: 'Vicente Dutra - RS' },
  { label: 'Victor Graeff - RS' },
  { label: 'Vila Flores - RS' },
  { label: 'Vila Langaro - RS' },
  { label: 'Vila Maria - RS' },
  { label: 'Vila Nova Do Sul - RS' },
  { label: 'Vista Alegre - RS' },
  { label: 'Vista Alegre Do Prata - RS' },
  { label: 'Vista Gaucha - RS' },
  { label: 'Vitoria Das Missoes - RS' },
  { label: 'Westfalia - RS' },
  { label: 'Xangri-la - RS' },
  { label: 'Agua Clara - MS' },
  { label: 'Alcinopolis - MS' },
  { label: 'Amambai - MS' },
  { label: 'Anastacio - MS' },
  { label: 'Anaurilandia - MS' },
  { label: 'Angelica - MS' },
  { label: 'Antonio Joao - MS' },
  { label: 'Aparecida Do Taboado - MS' },
  { label: 'Aquidauana - MS' },
  { label: 'Aral Moreira - MS' },
  { label: 'Bandeirantes - MS' },
  { label: 'Bataguassu - MS' },
  { label: 'Bataypora - MS' },
  { label: 'Bela Vista - MS' },
  { label: 'Bodoquena - MS' },
  { label: 'Bonito - MS' },
  { label: 'Brasilandia - MS' },
  { label: 'Caarapo - MS' },
  { label: 'Camapua - MS' },
  { label: 'Campo Grande - MS' },
  { label: 'Caracol - MS' },
  { label: 'Cassilandia - MS' },
  { label: 'Chapadao Do Sul - MS' },
  { label: 'Corguinho - MS' },
  { label: 'Coronel Sapucaia - MS' },
  { label: 'Corumba - MS' },
  { label: 'Costa Rica - MS' },
  { label: 'Coxim - MS' },
  { label: 'Deodapolis - MS' },
  { label: 'Dois Irmaos Do Buriti - MS' },
  { label: 'Douradina - MS' },
  { label: 'Dourados - MS' },
  { label: 'Eldorado - MS' },
  { label: 'Fatima Do Sul - MS' },
  { label: 'Figueirao - MS' },
  { label: 'Gloria De Dourados - MS' },
  { label: 'Guia Lopes Da Laguna - MS' },
  { label: 'Iguatemi - MS' },
  { label: 'Inocencia - MS' },
  { label: 'Itapora - MS' },
  { label: 'Itaquirai - MS' },
  { label: 'Ivinhema - MS' },
  { label: 'Japora - MS' },
  { label: 'Jaraguari - MS' },
  { label: 'Jardim - MS' },
  { label: 'Jatei - MS' },
  { label: 'Juti - MS' },
  { label: 'Ladario - MS' },
  { label: 'Laguna Carapa - MS' },
  { label: 'Maracaju - MS' },
  { label: 'Miranda - MS' },
  { label: 'Mundo Novo - MS' },
  { label: 'Navirai - MS' },
  { label: 'Nioaque - MS' },
  { label: 'Nova Alvorada Do Sul - MS' },
  { label: 'Nova Andradina - MS' },
  { label: 'Novo Horizonte Do Sul - MS' },
  { label: 'Paranaiba - MS' },
  { label: 'Paranhos - MS' },
  { label: 'Pedro Gomes - MS' },
  { label: 'Ponta Pora - MS' },
  { label: 'Porto Murtinho - MS' },
  { label: 'Ribas Do Rio Pardo - MS' },
  { label: 'Rio Brilhante - MS' },
  { label: 'Rio Negro - MS' },
  { label: 'Rio Verde De Mato Grosso - MS' },
  { label: 'Rochedo - MS' },
  { label: 'Santa Rita Do Pardo - MS' },
  { label: 'Sao Gabriel Do Oeste - MS' },
  { label: 'Sete Quedas - MS' },
  { label: 'Selviria - MS' },
  { label: 'Sidrolandia - MS' },
  { label: 'Sonora - MS' },
  { label: 'Tacuru - MS' },
  { label: 'Taquarussu - MS' },
  { label: 'Terenos - MS' },
  { label: 'Tres Lagoas - MS' },
  { label: 'Vicentina - MS' },
  { label: 'Acorizal - MT' },
  { label: 'Agua Boa - MT' },
  { label: 'Alta Floresta - MT' },
  { label: 'Alto Araguaia - MT' },
  { label: 'Alto Boa Vista - MT' },
  { label: 'Alto Garcas - MT' },
  { label: 'Alto Paraguai - MT' },
  { label: 'Alto Taquari - MT' },
  { label: 'Apiacas - MT' },
  { label: 'Araguaiana - MT' },
  { label: 'Araguainha - MT' },
  { label: 'Araputanga - MT' },
  { label: 'Arenapolis - MT' },
  { label: 'Aripuana - MT' },
  { label: 'Barao De Melgaco - MT' },
  { label: 'Barra Do Bugres - MT' },
  { label: 'Barra Do Garcas - MT' },
  { label: 'Bom Jesus Do Araguaia - MT' },
  { label: 'Brasnorte - MT' },
  { label: 'Caceres - MT' },
  { label: 'Campinapolis - MT' },
  { label: 'Campo Novo Do Parecis - MT' },
  { label: 'Campo Verde - MT' },
  { label: 'Campos De Julio - MT' },
  { label: 'Canabrava Do Norte - MT' },
  { label: 'Canarana - MT' },
  { label: 'Carlinda - MT' },
  { label: 'Castanheira - MT' },
  { label: 'Chapada Dos Guimaraes - MT' },
  { label: 'Claudia - MT' },
  { label: 'Cocalinho - MT' },
  { label: 'Colider - MT' },
  { label: 'Colniza - MT' },
  { label: 'Comodoro - MT' },
  { label: 'Confresa - MT' },
  { label: `${''}Conquista D'Oeste - MT` },
  { label: 'Cotriguacu - MT' },
  { label: 'Cuiaba - MT' },
  { label: 'Curvelandia - MT' },
  { label: 'Denise - MT' },
  { label: 'Diamantino - MT' },
  { label: 'Dom Aquino - MT' },
  { label: 'Feliz Natal - MT' },
  { label: `${''}Figueiropolis D'Oeste - MT` },
  { label: 'Gaucha Do Norte - MT' },
  { label: 'General Carneiro - MT' },
  { label: `${''}Gloria D'Oeste - MT` },
  { label: 'Guaranta Do Norte - MT' },
  { label: 'Guiratinga - MT' },
  { label: 'Indiavai - MT' },
  { label: 'Ipiranga Do Norte - MT' },
  { label: 'Itanhanga - MT' },
  { label: 'Itauba - MT' },
  { label: 'Itiquira - MT' },
  { label: 'Jaciara - MT' },
  { label: 'Jangada - MT' },
  { label: 'Jauru - MT' },
  { label: 'Juara - MT' },
  { label: 'Juina - MT' },
  { label: 'Juruena - MT' },
  { label: 'Juscimeira - MT' },
  { label: `${''}Lambari D'Oeste - MT` },
  { label: 'Lucas Do Rio Verde - MT' },
  { label: 'Luciara - MT' },
  { label: 'Vila Bela Da Santissima Trindade - MT' },
  { label: 'Marcelandia - MT' },
  { label: 'Matupa - MT' },
  { label: `${''}Mirassol D'Oeste - MT` },
  { label: 'Nobres - MT' },
  { label: 'Nortelandia - MT' },
  { label: 'Nossa Senhora Do Livramento - MT' },
  { label: 'Nova Bandeirantes - MT' },
  { label: 'Nova Nazare - MT' },
  { label: 'Nova Lacerda - MT' },
  { label: 'Nova Santa Helena - MT' },
  { label: 'Nova Brasilandia - MT' },
  { label: 'Nova Canaa Do Norte - MT' },
  { label: 'Nova Mutum - MT' },
  { label: 'Nova Olimpia - MT' },
  { label: 'Nova Ubirata - MT' },
  { label: 'Nova Xavantina - MT' },
  { label: 'Novo Mundo - MT' },
  { label: 'Novo Horizonte Do Norte - MT' },
  { label: 'Novo Sao Joaquim - MT' },
  { label: 'Paranaita - MT' },
  { label: 'Paranatinga - MT' },
  { label: 'Novo Santo Antonio - MT' },
  { label: 'Pedra Preta - MT' },
  { label: 'Peixoto De Azevedo - MT' },
  { label: 'Planalto Da Serra - MT' },
  { label: 'Pocone - MT' },
  { label: 'Pontal Do Araguaia - MT' },
  { label: 'Ponte Branca - MT' },
  { label: 'Pontes E Lacerda - MT' },
  { label: 'Porto Alegre Do Norte - MT' },
  { label: 'Porto Dos Gauchos - MT' },
  { label: 'Porto Esperidiao - MT' },
  { label: 'Porto Estrela - MT' },
  { label: 'Poxoreo - MT' },
  { label: 'Primavera Do Leste - MT' },
  { label: 'Querencia - MT' },
  { label: 'Sao Jose Dos Quatro Marcos - MT' },
  { label: 'Reserva Do Cabacal - MT' },
  { label: 'Ribeirao Cascalheira - MT' },
  { label: 'Ribeiraozinho - MT' },
  { label: 'Rio Branco - MT' },
  { label: 'Santa Carmem - MT' },
  { label: 'Santo Afonso - MT' },
  { label: 'Sao Jose Do Povo - MT' },
  { label: 'Sao Jose Do Rio Claro - MT' },
  { label: 'Sao Jose Do Xingu - MT' },
  { label: 'Sao Pedro Da Cipa - MT' },
  { label: 'Rondolandia - MT' },
  { label: 'Rondonopolis - MT' },
  { label: 'Rosario Oeste - MT' },
  { label: 'Santa Cruz Do Xingu - MT' },
  { label: 'Salto Do Ceu - MT' },
  { label: 'Santa Rita Do Trivelato - MT' },
  { label: 'Santa Terezinha - MT' },
  { label: 'Santo Antonio Do Leste - MT' },
  { label: 'Santo Antonio Do Leverger - MT' },
  { label: 'Sao Felix Do Araguaia - MT' },
  { label: 'Sapezal - MT' },
  { label: 'Serra Nova Dourada - MT' },
  { label: 'Sinop - MT' },
  { label: 'Sorriso - MT' },
  { label: 'Tabapora - MT' },
  { label: 'Tangara Da Serra - MT' },
  { label: 'Tapurah - MT' },
  { label: 'Terra Nova Do Norte - MT' },
  { label: 'Tesouro - MT' },
  { label: 'Torixoreu - MT' },
  { label: 'Uniao Do Sul - MT' },
  { label: 'Vale De Sao Domingos - MT' },
  { label: 'Varzea Grande - MT' },
  { label: 'Vera - MT' },
  { label: 'Vila Rica - MT' },
  { label: 'Nova Guarita - MT' },
  { label: 'Nova Marilandia - MT' },
  { label: 'Nova Maringa - MT' },
  { label: 'Nova Monte Verde - MT' },
  { label: 'Abadia De Goias - GO' },
  { label: 'Abadiania - GO' },
  { label: 'Acreuna - GO' },
  { label: 'Adelandia - GO' },
  { label: 'Agua Fria De Goias - GO' },
  { label: 'Agua Limpa - GO' },
  { label: 'Aguas Lindas De Goias - GO' },
  { label: 'Alexania - GO' },
  { label: 'Aloandia - GO' },
  { label: 'Alto Horizonte - GO' },
  { label: 'Alto Paraiso De Goias - GO' },
  { label: 'Alvorada Do Norte - GO' },
  { label: 'Amaralina - GO' },
  { label: 'Americano Do Brasil - GO' },
  { label: 'Amorinopolis - GO' },
  { label: 'Anapolis - GO' },
  { label: 'Anhanguera - GO' },
  { label: 'Anicuns - GO' },
  { label: 'Aparecida De Goiania - GO' },
  { label: 'Aparecida Do Rio Doce - GO' },
  { label: 'Apore - GO' },
  { label: 'Aracu - GO' },
  { label: 'Aragarcas - GO' },
  { label: 'Aragoiania - GO' },
  { label: 'Araguapaz - GO' },
  { label: 'Arenopolis - GO' },
  { label: 'Aruana - GO' },
  { label: 'Aurilandia - GO' },
  { label: 'Avelinopolis - GO' },
  { label: 'Baliza - GO' },
  { label: 'Barro Alto - GO' },
  { label: 'Bela Vista De Goias - GO' },
  { label: 'Bom Jardim De Goias - GO' },
  { label: 'Bom Jesus De Goias - GO' },
  { label: 'Bonfinopolis - GO' },
  { label: 'Bonopolis - GO' },
  { label: 'Brazabrantes - GO' },
  { label: 'Britania - GO' },
  { label: 'Buriti Alegre - GO' },
  { label: 'Buriti De Goias - GO' },
  { label: 'Buritinopolis - GO' },
  { label: 'Cabeceiras - GO' },
  { label: 'Cachoeira Alta - GO' },
  { label: 'Cachoeira De Goias - GO' },
  { label: 'Cachoeira Dourada - GO' },
  { label: 'Cacu - GO' },
  { label: 'Caiaponia - GO' },
  { label: 'Caldas Novas - GO' },
  { label: 'Caldazinha - GO' },
  { label: 'Campestre De Goias - GO' },
  { label: 'Campinacu - GO' },
  { label: 'Campinorte - GO' },
  { label: 'Campo Alegre De Goias - GO' },
  { label: 'Campo Limpo De Goias - GO' },
  { label: 'Campos Belos - GO' },
  { label: 'Campos Verdes - GO' },
  { label: 'Carmo Do Rio Verde - GO' },
  { label: 'Castelandia - GO' },
  { label: 'Catalao - GO' },
  { label: 'Caturai - GO' },
  { label: 'Cavalcante - GO' },
  { label: 'Ceres - GO' },
  { label: 'Cezarina - GO' },
  { label: 'Chapadao Do Ceu - GO' },
  { label: 'Cidade Ocidental - GO' },
  { label: 'Cocalzinho De Goias - GO' },
  { label: 'Colinas Do Sul - GO' },
  { label: 'Corrego Do Ouro - GO' },
  { label: 'Corumba De Goias - GO' },
  { label: 'Corumbaiba - GO' },
  { label: 'Cristalina - GO' },
  { label: 'Cristianopolis - GO' },
  { label: 'Crixas - GO' },
  { label: 'Crominia - GO' },
  { label: 'Cumari - GO' },
  { label: 'Damianopolis - GO' },
  { label: 'Damolandia - GO' },
  { label: 'Davinopolis - GO' },
  { label: 'Diorama - GO' },
  { label: 'Doverlandia - GO' },
  { label: 'Edealina - GO' },
  { label: 'Edeia - GO' },
  { label: 'Estrela Do Norte - GO' },
  { label: 'Faina - GO' },
  { label: 'Fazenda Nova - GO' },
  { label: 'Firminopolis - GO' },
  { label: 'Flores De Goias - GO' },
  { label: 'Formosa - GO' },
  { label: 'Formoso - GO' },
  { label: 'Gameleira De Goias - GO' },
  { label: 'Divinopolis De Goias - GO' },
  { label: 'Goianapolis - GO' },
  { label: 'Goiandira - GO' },
  { label: 'Goianesia - GO' },
  { label: 'Goiania - GO' },
  { label: 'Goianira - GO' },
  { label: 'Goias - GO' },
  { label: 'Goiatuba - GO' },
  { label: 'Gouvelandia - GO' },
  { label: 'Guapo - GO' },
  { label: 'Guaraita - GO' },
  { label: 'Guarani De Goias - GO' },
  { label: 'Guarinos - GO' },
  { label: 'Heitorai - GO' },
  { label: 'Hidrolandia - GO' },
  { label: 'Hidrolina - GO' },
  { label: 'Iaciara - GO' },
  { label: 'Inaciolandia - GO' },
  { label: 'Indiara - GO' },
  { label: 'Inhumas - GO' },
  { label: 'Ipameri - GO' },
  { label: 'Ipiranga De Goias - GO' },
  { label: 'Ipora - GO' },
  { label: 'Israelandia - GO' },
  { label: 'Itaberai - GO' },
  { label: 'Itaguari - GO' },
  { label: 'Itaguaru - GO' },
  { label: 'Itaja - GO' },
  { label: 'Itapaci - GO' },
  { label: 'Itapirapua - GO' },
  { label: 'Itapuranga - GO' },
  { label: 'Itaruma - GO' },
  { label: 'Itaucu - GO' },
  { label: 'Itumbiara - GO' },
  { label: 'Ivolandia - GO' },
  { label: 'Jandaia - GO' },
  { label: 'Jaragua - GO' },
  { label: 'Jatai - GO' },
  { label: 'Jaupaci - GO' },
  { label: 'Jesupolis - GO' },
  { label: 'Joviania - GO' },
  { label: 'Jussara - GO' },
  { label: 'Lagoa Santa - GO' },
  { label: 'Leopoldo De Bulhoes - GO' },
  { label: 'Luziania - GO' },
  { label: 'Mairipotaba - GO' },
  { label: 'Mambai - GO' },
  { label: 'Mara Rosa - GO' },
  { label: 'Marzagao - GO' },
  { label: 'Matrincha - GO' },
  { label: 'Maurilandia - GO' },
  { label: 'Mimoso De Goias - GO' },
  { label: 'Minacu - GO' },
  { label: 'Mineiros - GO' },
  { label: 'Moipora - GO' },
  { label: 'Monte Alegre De Goias - GO' },
  { label: 'Montes Claros De Goias - GO' },
  { label: 'Montividiu - GO' },
  { label: 'Montividiu Do Norte - GO' },
  { label: 'Morrinhos - GO' },
  { label: 'Morro Agudo De Goias - GO' },
  { label: 'Mossamedes - GO' },
  { label: 'Mozarlandia - GO' },
  { label: 'Mundo Novo - GO' },
  { label: 'Mutunopolis - GO' },
  { label: 'Nazario - GO' },
  { label: 'Neropolis - GO' },
  { label: 'Niquelandia - GO' },
  { label: 'Nova America - GO' },
  { label: 'Nova Aurora - GO' },
  { label: 'Nova Crixas - GO' },
  { label: 'Nova Gloria - GO' },
  { label: 'Nova Iguacu De Goias - GO' },
  { label: 'Nova Roma - GO' },
  { label: 'Nova Veneza - GO' },
  { label: 'Novo Brasil - GO' },
  { label: 'Novo Gama - GO' },
  { label: 'Novo Planalto - GO' },
  { label: 'Orizona - GO' },
  { label: 'Ouro Verde De Goias - GO' },
  { label: 'Ouvidor - GO' },
  { label: 'Padre Bernardo - GO' },
  { label: 'Palestina De Goias - GO' },
  { label: 'Palmeiras De Goias - GO' },
  { label: 'Palmelo - GO' },
  { label: 'Palminopolis - GO' },
  { label: 'Panama - GO' },
  { label: 'Paranaiguara - GO' },
  { label: 'Parauna - GO' },
  { label: 'Perolandia - GO' },
  { label: 'Petrolina De Goias - GO' },
  { label: 'Pilar De Goias - GO' },
  { label: 'Piracanjuba - GO' },
  { label: 'Piranhas - GO' },
  { label: 'Pirenopolis - GO' },
  { label: 'Pires Do Rio - GO' },
  { label: 'Planaltina - GO' },
  { label: 'Pontalina - GO' },
  { label: 'Porangatu - GO' },
  { label: 'Porteirao - GO' },
  { label: 'Portelandia - GO' },
  { label: 'Posse - GO' },
  { label: 'Professor Jamil - GO' },
  { label: 'Quirinopolis - GO' },
  { label: 'Rialma - GO' },
  { label: 'Rianapolis - GO' },
  { label: 'Rio Quente - GO' },
  { label: 'Rio Verde - GO' },
  { label: 'Rubiataba - GO' },
  { label: 'Sanclerlandia - GO' },
  { label: 'Santa Barbara De Goias - GO' },
  { label: 'Santa Cruz De Goias - GO' },
  { label: 'Santa Fe De Goias - GO' },
  { label: 'Santa Helena De Goias - GO' },
  { label: 'Santa Isabel - GO' },
  { label: 'Santa Rita Do Araguaia - GO' },
  { label: 'Santa Rita Do Novo Destino - GO' },
  { label: 'Santa Rosa De Goias - GO' },
  { label: 'Santa Tereza De Goias - GO' },
  { label: 'Santa Terezinha De Goias - GO' },
  { label: 'Santo Antonio Da Barra - GO' },
  { label: 'Santo Antonio De Goias - GO' },
  { label: 'Santo Antonio Do Descoberto - GO' },
  { label: 'Sao Domingos - GO' },
  { label: 'Sao Francisco De Goias - GO' },
  { label: `${''}Sao Joao D'Alianca - GO` },
  { label: 'Sao Joao Da Parauna - GO' },
  { label: 'Sao Luis De Montes Belos - GO' },
  { label: 'Sao Luiz Do Norte - GO' },
  { label: 'Sao Miguel Do Araguaia - GO' },
  { label: 'Sao Miguel Do Passa Quatro - GO' },
  { label: 'Sao Patricio - GO' },
  { label: 'Sao Simao - GO' },
  { label: 'Senador Canedo - GO' },
  { label: 'Serranopolis - GO' },
  { label: 'Silvania - GO' },
  { label: 'Simolandia - GO' },
  { label: `${''}Sitio D'Abadia - GO` },
  { label: 'Taquaral De Goias - GO' },
  { label: 'Teresina De Goias - GO' },
  { label: 'Terezopolis De Goias - GO' },
  { label: 'Tres Ranchos - GO' },
  { label: 'Trindade - GO' },
  { label: 'Trombas - GO' },
  { label: 'Turvania - GO' },
  { label: 'Turvelandia - GO' },
  { label: 'Uirapuru - GO' },
  { label: 'Uruacu - GO' },
  { label: 'Uruana - GO' },
  { label: 'Urutai - GO' },
  { label: 'Valparaiso De Goias - GO' },
  { label: 'Varjao - GO' },
  { label: 'Vianopolis - GO' },
  { label: 'Vicentinopolis - GO' },
  { label: 'Vila Boa - GO' },
  { label: 'Vila Propicio - GO' },
  { label: 'Brasilia - DF' }
]
