import styled from 'styled-components'

export const Form = styled.form`
  height: 100%;
  padding: 20px;
  width: 100%;
  max-width: 500px;

  h4 {
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 500px) {
    max-width: 360px;
  }
`
