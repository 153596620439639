import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import LaunchIcon from '@material-ui/icons/Launch'
import { toast } from 'react-toastify'

import { TextInput, NumberInput, Select } from '../../components/FormComponents'
import { CardEditIcon } from './'

import { statesOptions, CEP_LENGTH, leadModalCards } from '../../constants'
import { formatValueToBrl } from '../../helpers'
import { getAddressByCep } from '../../services/v3'

import { Card, Title, InfoContainer, Info, Bold, EditInputsContainer } from './styledComponents'

const CompanyInfosCard = ({ lead, isEditing, handleEditCard, control, errors, watch, setValue }) => {
  const [lastValue, setLastValue] = useState(lead.zip_code)
  const zipCode = watch('zip_code')

  const address = `${lead.street}, ${lead.number} - ${lead.district}, ${lead.city} - ${lead.uf}`

  useEffect(() => {
    if (!isEditing) return
    setValue('company_site', lead.company_site)
    setValue('zip_code', lead.zip_code)
    setValue('street', lead.street)
    setValue('number', lead.number)
    setValue('district', lead.district)
    setValue('complement', lead.complement)
    setValue('city', lead.city)
    setValue('uf', lead.uf)
    setValue('average_monthly_revenue', formatValueToBrl(lead.average_monthly_revenue))
    setLastValue(lead.zip_code)
  }, [isEditing])

  const setCompanyAddress = useCallback(
    async cep => {
      try {
        const companyAddress = await getAddressByCep(cep)
        setValue('city', companyAddress.localidade)
        setValue('uf', companyAddress.uf)
        setValue('street', companyAddress.logradouro)
        setValue('district', companyAddress.bairro)
        setValue('number', '')
        setValue('complement', '')
      } catch (error) {
        if (error.message) {
          toast.error(error.message)
        } else {
          toast.error('Erro a buscar CEP')
        }
      }
    },
    [setValue]
  )

  useEffect(() => {
    if (!(zipCode && lastValue)) return

    const sanitizedZipCode = zipCode.replace(/\D/g, '')
    const sanitizedLastValue = lastValue.replace(/\D/g, '')

    if (sanitizedZipCode.length === CEP_LENGTH && sanitizedLastValue !== sanitizedZipCode) {
      setCompanyAddress(sanitizedZipCode)
      setLastValue(sanitizedZipCode)
    }
  }, [zipCode, setCompanyAddress])

  const openMaps = () => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`)
  }

  const openSite = () => {
    const { company_site } = lead
    if (company_site) window.open(company_site.match(/^http[s]?:\/\//) ? company_site : 'http://' + company_site)
  }
  return (
    <Card>
      <CardEditIcon onClick={() => handleEditCard(leadModalCards.COMPANY_INFOS)} />
      <Title>Dados da empresa</Title>
      {isEditing ? (
        <EditInputsContainer>
          <TextInput
            name='company_site'
            control={control}
            error={errors['company_site']}
            label='Site da empresa'
            defaultValue=''
            width='300px'
            dense
          />
          <NumberInput
            name='zip_code'
            control={control}
            error={errors['zip_code']}
            label='CEP *'
            format='#####-###'
            defaultValue=''
            width='120px'
            dense
          />
          <TextInput name='street' control={control} error={errors['street']} label='Rua *' defaultValue='' dense />
          <TextInput
            name='number'
            control={control}
            error={errors['number']}
            label='Nº *'
            defaultValue=''
            width='100px'
            dense
          />
          <TextInput
            name='district'
            control={control}
            error={errors['district']}
            label='Bairro *'
            defaultValue=''
            dense
          />
          <TextInput
            name='complement'
            control={control}
            error={errors['complement']}
            label='Complemento'
            defaultValue=''
            dense
          />
          <TextInput name='city' control={control} error={errors['city']} label='Cidade *' defaultValue='' dense />
          <Select
            name='uf'
            error={errors['uf']}
            options={statesOptions}
            control={control}
            label='Estado *'
            defaultValue=''
            width='100px'
            dense
          />
          <NumberInput
            name='average_monthly_revenue'
            control={control}
            error={errors['average_monthly_revenue']}
            label='FMM *'
            defaultValue=''
            type='currency'
            dense
          />
        </EditInputsContainer>
      ) : (
        <InfoContainer>
          <Info>
            <Bold>Site da empresa:</Bold> {lead.company_site || 'não informado'}
            <StyledLaunchIcon style={{ fontSize: '16px' }} onClick={openSite} />
          </Info>
          <Info>
            <Bold>Endereço da empresa:</Bold> {address}{' '}
            <StyledLaunchIcon style={{ fontSize: '16px' }} onClick={openMaps} />
          </Info>
          <Info>
            <Bold>Faturamento médio mensal estimado:</Bold> {formatValueToBrl(lead.average_monthly_revenue)}
          </Info>
          <Info>
            <Bold>Valor solicitado:</Bold> {formatValueToBrl(lead.desired_amount)}
          </Info>
        </InfoContainer>
      )}
    </Card>
  )
}

const StyledLaunchIcon = styled(LaunchIcon)`
  margin-bottom: 3px;
  color: #757575;
  cursor: pointer;
`

export default CompanyInfosCard
