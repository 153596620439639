import React from 'react'
import PropTypes from 'prop-types'

import { ButtonStyled } from './styles'

const IconButton = ({ children, variant, color, icon, ...props }) => {
  return (
    <ButtonStyled variant={variant} color={color} {...props}>
      {icon}
    </ButtonStyled>
  )
}

IconButton.defaultProps = {
  color: 'primary',
  variant: 'contained'
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['primary', 'secondary'])
}

export default IconButton
