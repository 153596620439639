import React from 'react'
import { Document, Font } from '@react-pdf/renderer'
import styled from '@react-pdf/styled-components'

import { formatCPF, formatCNPJ } from '../../helpers'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: '/assets/fonts/Roboto-Regular.ttf'
    },
    {
      src: '/assets/fonts/Roboto-Medium.ttf',
      fontWeight: 'medium'
    },
    {
      src: '/assets/fonts/Roboto-Bold.ttf',
      fontWeight: 'bold'
    }
  ]
})

const PDFIncomeReport = ({ data }) => {
  const {
    year,
    userInfos: { first_name, last_name, cpf },
    campaignsInfos,
    campaignsSum: { sumRaw, sumInitialBalance, sumFinalBalance, sumTotalRevenue, sumTotalIrTax }
  } = data

  const CurrenyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  const formatNumToCur = value => CurrenyFormatter.format(value.toFixed(2)).slice(2)

  return (
    <Document>
      <PageContainer size='A4' orientation='landscape'>
        <Header>
          <Logo src='/assets/images/logo-branco.png' />
          <HeaderTitle>INFORME DE RENDIMENTOS FINANCEIROS / ANO CALENDÁRIO {year}</HeaderTitle>
        </Header>
        <Section>
          <Title bold>IMPOSTO DE RENDA - PESSOA FÍSICA</Title>
        </Section>

        <Section>
          <Title bold>1) PESSOA FÍSICA BENEFICIÁRIA DOS RENDIMENTOS</Title>
          <EmptyLine />
          <SubSectionLineDivided>
            <UserInfoLabel width='70%' initial>
              NOME
            </UserInfoLabel>
            <UserInfoLabel width='30%'>CPF</UserInfoLabel>
          </SubSectionLineDivided>
          <SubSectionLineDivided>
            <UserInfoText width='70%' initial>
              {first_name} {last_name}
            </UserInfoText>
            <UserInfoText width='30%'>{formatCPF(cpf)}</UserInfoText>
          </SubSectionLineDivided>
          <Line />
        </Section>

        <Section>
          <Title bold>
            2) DADOS DA FONTE PAGADORA E RENDIMENTOS SUJEITOS A TRIBUTAÇÃO EXCLUSIVA (valores em reais)
          </Title>
          <Title>
            Declarar o saldo em {year} como "51 - Crédito decorrente de empréstimo" na aba "Bens e Direitos"
          </Title>
          <Title>
            Declarar os rendimentos {year} como "Rendimentos Sujeitos à Tributação Exclusiva/Definitiva" opção "12 -
            Outros"
          </Title>
          <EmptyLine />
          <SubSectionLineDivided>
            <ColumnHeader width='10%'>CNPJ</ColumnHeader>
            <ColumnHeader width='30%'>RAZÃO SOCIAL</ColumnHeader>
            <ColumnHeader width='7%'>VALOR</ColumnHeader>
            <ColumnHeader width='8%'>EMISSÃO</ColumnHeader>
            <ColumnHeader width='9%'>VENCIMENTO</ColumnHeader>
            <ColumnHeader width='7%'>SALDO EM {year - 1}</ColumnHeader>
            <ColumnHeader width='7%'>SALDO EM {year}</ColumnHeader>
            <ColumnHeader width='10%'>RENDIMENTOS LÍQUIDOS {year}</ColumnHeader>
            <ColumnHeader width='12%'>IMPOSTO DE RENDA (recolhido via DARF)</ColumnHeader>
          </SubSectionLineDivided>

          {campaignsInfos.map(
            ({
              campaignId,
              campaignCNPJ,
              campaignName,
              totalRaw,
              campaignInitialDate,
              campaignLastDueDate,
              initialBalance,
              finalBalance,
              totalRevenue,
              totalIrTax
            }) => {
              return (
                <SubSectionLineDivided key={campaignId}>
                  <ColumnText initial align='left' width='10%'>
                    {formatCNPJ(campaignCNPJ)}
                  </ColumnText>
                  <ColumnText align='left' width='30%'>
                    {campaignName}
                  </ColumnText>
                  <ColumnText align='right' width='7%'>
                    {formatNumToCur(totalRaw)}
                  </ColumnText>
                  <ColumnText align='right' width='8%'>
                    {campaignInitialDate}
                  </ColumnText>
                  <ColumnText align='right' width='9%'>
                    {campaignLastDueDate}
                  </ColumnText>
                  <ColumnText align='right' width='7%'>
                    {formatNumToCur(initialBalance)}
                  </ColumnText>
                  <ColumnText align='right' width='7%'>
                    {formatNumToCur(finalBalance)}
                  </ColumnText>
                  <ColumnText align='right' width='10%'>
                    {formatNumToCur(totalRevenue)}
                  </ColumnText>
                  <ColumnText align='right' width='12%'>
                    {formatNumToCur(totalIrTax)}
                  </ColumnText>
                </SubSectionLineDivided>
              )
            }
          )}
          <SubSectionLineDivided>
            <ColumnTextSum align='left' width='10%'>
              TOTAIS
            </ColumnTextSum>
            <ColumnTextSum align='left' width='30%' />
            <ColumnTextSum align='right' width='7%'>
              {formatNumToCur(sumRaw)}
            </ColumnTextSum>
            <ColumnTextSum align='right' width='8%' />
            <ColumnTextSum align='right' width='9%' />
            <ColumnTextSum align='right' width='7%'>
              {formatNumToCur(sumInitialBalance)}
            </ColumnTextSum>
            <ColumnTextSum align='right' width='7%'>
              {formatNumToCur(sumFinalBalance)}
            </ColumnTextSum>
            <ColumnTextSum align='right' width='10%'>
              {formatNumToCur(sumTotalRevenue)}
            </ColumnTextSum>
            <ColumnTextSum align='right' width='12%'>
              {formatNumToCur(sumTotalIrTax)}
            </ColumnTextSum>
          </SubSectionLineDivided>
        </Section>
        <Title bold>
          Dúvidas com relação à declaração podem ser sanadas no site da Receita Federal, conforme o link:
        </Title>
        <Link>
          http://idg.receita.fazenda.gov.br/orientacao/tributaria/declaracoes-e-demonstrativos/dirpf/entrega-da-declaracao-do-irpf/como-preencher-corretamente-a-sua-declaracao-do-irpf-evitando-a-ocorrencia-de-pendencias
        </Link>
      </PageContainer>
    </Document>
  )
}

const PageContainer = styled.Page`
  flex-direction: column;
  background-color: #fff;
  justify-content: flex-start;
  padding: 50px 30px;
`

const Header = styled.View`
  padding: 10px 30px;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #076f9f;
`

const HeaderTitle = styled.Text`
  font-family: Roboto;
  font-weight: medium;
  color: #fff;
  font-size: 16px;
`

const Logo = styled.Image`
  width: 100px;
`

const Section = styled.View`
  border: 2px solid #076f9f;
  margin: 5px 0px;
  padding: 10px 0px;
`

const Title = styled.Text`
  padding: 2px 0px 3px 3px;
  font-family: Roboto;
  font-size: 10px;
  color: #076f9f;
  ${({ bold }) => bold && 'font-weight: bold'};
`

const ColumnHeader = styled.Text`
  flex-wrap: wrap;
  padding: 2px 0px 3px 3px;
  font-family: Roboto;
  font-size: 8px;
  text-align: center;
  color: #076f9f;
  font-weight: bold;
  width: ${({ width }) => (width ? width : '100%')};
`

const ColumnText = styled.Text`
  flex-wrap: wrap;
  padding: 3px;
  font-family: Roboto;
  font-size: 8px;
  color: #076f9f;
  text-align: ${({ align }) => align};
  width: ${({ width }) => (width ? width : '100%')};
  border: 1px solid #076f9f;
  border-bottom: none;
  border-right: none;
  ${({ initial }) => initial && 'border-left: none;'}
`

const ColumnTextSum = styled.Text`
  flex-wrap: wrap;
  padding: 3px;
  font-family: Roboto;
  font-size: 8px;
  background-color: #076f9f;
  color: #fff;
  text-align: ${({ align }) => align};
  width: ${({ width }) => (width ? width : '100%')};
`

const SubSectionLineDivided = styled.View`
  display: flex;
  flex-direction: row;
`

const UserInfoText = styled.Text`
  padding: 2px 0px 0px 3px;
  font-family: Roboto;
  font-size: 10px;
  color: #076f9f;
  border: 1px solid #076f9f;
  border-bottom: none;
  border-right: none;
  ${({ initial }) => initial && 'border-left: none;'}
  width: ${({ width }) => (width ? width : '100%')};
`

const UserInfoLabel = styled(UserInfoText)`
  font-weight: bold;
`

const EmptyLine = styled.View`
  width: 100%;
  height: 10px;
  background-color: #fff;
`

const Line = styled.View`
  width: 100%;
  height: 1px;
  background-color: #076f9f;
`

const Link = styled.Link`
  padding: 2px 0px 3px 3px;
  font-family: Roboto;
  font-size: 8px;
  text-decoration: underline;
  color: #076f9f;
`

export default PDFIncomeReport
