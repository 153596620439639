import React, { useState, useEffect } from 'react'
import Popover from '@material-ui/core/Popover'
import styled from 'styled-components'

import { formatPhoneWithoutPrefix } from '../../helpers'
import { getPartner } from '../../services/v3'

const PopoverIndicatedBy = ({ anchor, setAnchor, lead }) => {
  const [partner, setPartner] = useState(null)

  useEffect(() => {
    const fetchPartner = async () => {
      try {
        const { data: partner } = await getPartner(lead.partner_id)
        setPartner(partner)
      } catch (error) {}
    }
    fetchPartner()
  }, [])

  return (
    <StyledPopover
      id='responsible-popover'
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {partner && (
        <InfoContainer>
          <Info>
            <Bold>Nome:</Bold> {partner.first_name || 'não informado'}
          </Info>
          <Info>
            <Bold>Sobrenome:</Bold> {partner.last_name || 'não informado'}
          </Info>
          <Info>
            <Bold>Celular:</Bold> {`(${partner.celphone_prefix || '##'}) ${formatPhoneWithoutPrefix(partner.celphone)}`}
          </Info>
          <Info>
            <Bold>Telefone Fixo:</Bold>{' '}
            {`(${partner.telephone_prefix || '##'}) ${formatPhoneWithoutPrefix(partner.telephone)}`}
          </Info>
          <Info>
            <Bold>E-mail:</Bold> {partner.user.email}
          </Info>
        </InfoContainer>
      )}
    </StyledPopover>
  )
}

const StyledPopover = styled(Popover)`
  pointer-events: none;
`

export const Info = styled.p`
  font-size: 13px;
  margin-bottom: 10px;
  color: #424242;
  :last-child {
    margin-bottom: 0px;
  }
`

export const InfoContainer = styled.div`
  padding: 8px;
`

export const Bold = styled.span`
  font-weight: 500;
  font-size: 13px;
`

export default PopoverIndicatedBy
