export * from './banks'
export * from './cities'
export * from './forms'
export * from './yupSchemas'
export * from './inputs'
export * from './processCampaign'
export * from './status'
export * from './pledges'
export * from './users'
export * from './campaigns'
export * from './anticipation'
export * from './installments'
export * from './renegotiation'
export * from './addresses'
export * from './states'
export * from './leads'
export * from './roles'

export const wizardElements = [
  { index: 1, title: 'Informações da campanha', to: 'informacoes' },
  { index: 2, title: 'Resumo', to: 'resumo' },
  { index: 3, title: 'Sobre a empresa', to: 'sobre-a-empresa' },
  // { index: 4, title: 'Visualizar Draft', to: 'visualizar-draft' },
  { index: 4, title: 'Foto', to: 'foto' },
  { index: 5, title: 'Galeria', to: 'galeria' },
  { index: 6, title: 'Atualizações', to: 'atualizacoes' },
  { index: 7, title: 'Excluir campanha', to: 'excluir' }
]
