import React, { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import {
  getLeadStatuses,
  GetOneLead,
  getLeadUpdate,
  fetchAllEmployees,
  getPartners,
  fetchCurrentUser,
  getLeadDocuments,
  getLeadDocumentTypes
} from '../../services/v3'

import LoadingComponent from '../../components/LoadingComponent'
import {
  Accordion,
  HeaderCreditRequest,
  Info,
  Timeline,
  ModalAddRegister,
  ModalProposal
} from '../../components/v2Components'
import { CampaignsContainer } from './styles.js'

const Campaigns = () => {
  const [leadStatuses, setLeadStatuses] = useState(null)
  const [loading, setLoading] = useState(true)
  const [responsibles, setResponsibles] = useState([])
  const [partner, setPartner] = useState([])
  const [leadUpdate, setLeadUpdate] = useState([])
  const [documentType, setDocumentType] = useState([])
  const [modalRegisterIsOpen, setModalRegisterIsOpen] = useState(false)
  const [isAdministrator, setIsAdministrator] = useState(false)

  const [modalProposalIsOpen, setModalProposalIsOpen] = useState(false)
  const [data, setData] = useState([])
  const { id } = useParams()
  const [user, setUser] = useState([])

  const fetchInfos = useCallback(async () => {
    try {
      const { data } = await getLeadStatuses()
      setLeadStatuses(data)

      const { data: userData } = await fetchCurrentUser()

      setUser(userData)
      if (!!userData.roles) {
        setIsAdministrator(
          userData.roles.every(role => {
            if (role.id === 10) {
              return false
            } else {
              return true
            }
          })
        )
      }

      const { data: response } = await GetOneLead(id)
      const { data: respDoc } = await getLeadDocuments(id)
      const { data: allDocType } = await getLeadDocumentTypes()
      const { data: leadUpdateData } = await getLeadUpdate(id)

      var temp = []
      var observations = []
      respDoc.forEach(item => {
        if (
          item.lead_document_observation_id !== null &&
          item.approved !== 0 &&
          !temp.includes(item.lead_document_observation_id)
        ) {
          temp.push(item.lead_document_observation_id)
          observations.push(item.lead_document_observations.description)
        }
      })

      setLeadUpdate(leadUpdateData)
      setDocumentType(allDocType)
      setData({ ...response, lead_Documents: respDoc, observations: observations })
    } catch (err) {
      console.log(err)
      toast.error('Erro ao carregar a página')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const fetchResponsibles = async () => {
      const { data: employees } = await fetchAllEmployees({ pageSize: 1000 })
      setResponsibles(
        employees.employees.reduce((acc, cur) => {
          return (acc = [
            ...acc,
            {
              name: `${cur.first_name} ${cur.last_name}`,
              avatar: cur.s3_name,
              user_id: cur.user_id
            }
          ])
        }, [])
      )
    }
    const fetchPartner = async () => {
      try {
        const { data: partners } = await getPartners()
        setPartner(
          partners.reduce((acc, cur) => {
            return (acc = [
              ...acc,
              {
                label: `${cur.first_name} ${cur.last_name}`,
                value: cur.id
              }
            ])
          }, [])
        )
      } catch {
        toast.error('erro ao procurar por parceiros')
      }
    }
    fetchPartner()
    fetchResponsibles()
  }, [])

  useEffect(() => {
    fetchInfos()
  }, [loading])

  const addItem = item => {
    setLeadUpdate([{ ...item, user: user }, ...leadUpdate])
  }

  const editItem = item => {
    const editLeadTimeline = element => {
      if (element.id === item.id) {
        element.observation = item.observation
        element.is_visible_to_partner = item.is_visible_to_partner
      }
    }
    leadUpdate.forEach(editLeadTimeline)
  }

  const deleteItem = item => {
    const deleteLeadTimeline = element => {
      return element.id !== item.id
    }
    setLeadUpdate(leadUpdate.filter(deleteLeadTimeline))
  }

  if (loading) return <LoadingComponent />
  return (
    <>
      <CampaignsContainer>
        <div style={{ gridArea: 'Header' }}>
          <HeaderCreditRequest
            state={data}
            leadStatuses={leadStatuses}
            handleOpenProposal={() => setModalProposalIsOpen(data)}
            addInTimeline={item => addItem(item)}
            id={data.id}
            oldStatus={data.lead_status.id}
          />
        </div>
        <div style={{ gridArea: 'Details' }}>
          <Info state={data} responsibles={responsibles} reload={() => setLoading(true)} partners={partner} />
        </div>
        <div style={{ gridArea: 'Accordion' }}>
          <Accordion state={data} fetchInfos={fetchInfos} documentType={documentType} />
        </div>
        <div style={{ gridArea: 'Timeline' }}>
          <Timeline
            state={data}
            leadStatuses={leadStatuses}
            leadUpdate={leadUpdate}
            onClick={() => setModalRegisterIsOpen(true)}
            isFranchisor={data.lead_origin.name === 'Parceiro' ? true : false}
            editInTimeline={editItem}
            deleteInTimeLine={deleteItem}
            isAdministrator={isAdministrator}
            responsibles={responsibles}
          />
        </div>

        <ModalProposal
          open={modalProposalIsOpen}
          handleClose={() => setModalProposalIsOpen(false)}
          buttonClick={() => setModalProposalIsOpen(false)}
          addInTimeline={item => addItem(item)}
        />
      </CampaignsContainer>

      <ModalAddRegister
        id={data.id}
        open={modalRegisterIsOpen}
        handleClose={() => setModalRegisterIsOpen(false)}
        buttonClick={() => setModalRegisterIsOpen(false)}
        addInTimeline={item => addItem(item)}
        user={user}
      />
    </>
  )
}

export default Campaigns
