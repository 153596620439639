import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Field, ErrorMessage } from 'formik'
import { TimePicker } from 'material-ui-pickers'
import { Input } from '../'
import moment from 'moment-timezone'

const DatePickerFormik = ({ name, label, values, setFieldValue }) => {
  const pickerRef = useRef(null)
  const [pickerOpen, setPickerOpen] = useState(false)

  const handleFocus = () => {
    if (pickerOpen) {
      pickerRef.current.close()
    } else {
      pickerRef.current.open()
    }
    setPickerOpen(!pickerOpen)
  }

  return (
    <InputWrapper>
      <Field
        name={name}
        render={() => (
          <label htmlFor={name}>
            <Input
              label={label}
              placeholder='hh:mm'
              value={values[name] ? moment(values[name]).format('HH:mm') : ''}
              onFocus={handleFocus}
              onChange={() => {}}
              style={{ zIndex: -1 }}
            />
            <TimePicker
              id={name}
              ref={pickerRef}
              value={values[name]}
              onChange={val => setFieldValue(name, val)}
              style={{ display: 'none' }}
              ampm={false}
              minutesStep={1}
            />
          </label>
        )}
      />
      <ErrorMessage name={name} component={Error} />
    </InputWrapper>
  )
}

export default DatePickerFormik

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  width: 50%;
`
const Error = styled.span`
  color: red;
  font-size: 0.8em;
  padding: 0 0.3em;
`
