import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { editLeadUpdate, updateLeads, updateProposal } from '../../../../../services/v3'

import { ModalBase, Column, Button, Row, Text } from '../../../../v2Components'

const ModalFormalizeProposal = ({ open, handleClose }) => {
  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    try {
      await editLeadUpdate(open.id, { is_visible_to_partner: true })
      await updateLeads(open.lead_id, { is_visible_to_partner: true, lead_status_id: 6 })
      await updateProposal(open.proposal_id, { is_simulation: false })
      toast.success('Proposta Formalizada.')
      document.location.reload(true)
    } catch {
      toast.error('Ocorreu um erro ao formalizar a proposta.')
    } finally {
      handleClose()
    }
  }

  return (
    <ModalBase
      open={open ? true : false}
      onClose={handleClose}
      height='100%'
      width='100%'
      title={'Formalizar Proposta'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column height='100%'>
          <Text variant='h4'>Você deseja formalizar essa proposta?</Text>
          <Row mt={3}>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Não
            </Button>
            <Button height={40} width={227} type='submit'>
              Sim
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalFormalizeProposal
