import styled from 'styled-components'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'
import { Drawer } from '@material-ui/core/'

import { IconClear } from '../../../../components/v2Components/icons'

export const MaterialDrawer = styled(Drawer)`
  div {
    &.MuiDrawer-paper {
      width: 100%;
      padding: 24px;
      max-width: 448px;
      ${palette}
      ${spacing}
      ${typography}
      ${borders}
      ${flexbox}
      ${shadows}
      ${sizing}
    }
  }
`
export const Close = styled(IconClear)`
  cursor: pointer;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
