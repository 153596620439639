import React, { useEffect, useState, Fragment } from 'react'
import { toast } from 'react-toastify'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import moment from 'moment-timezone'

import { LoadingComponent, MaterialButton } from '../../components'
import { TextInput, Select, NumberInput, DatePicker } from '../../components/FormComponents'
import SimulationTable from './SimulationTable'

import { renegotiationSchema } from '../../schemas'
import { getCampaignById, renegotiatePrice, renegotiateIrregular } from '../../services/v3'
import { renegotiationTypes, renegotiationOptions } from '../../constants'
import { formatPercentageToBack, formatCurrencyToBack } from '../../helpers'

import { Title, ButtonContainer, RemoveContainer, Row, Container, StyledAddIcon, StyledRemoveIcon } from './style'

const Renegotiation = ({ match }) => {
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [simulatedBody, setSimulatedBody] = useState({})

  const { handleSubmit, errors, control, reset, watch } = useForm({
    resolver: yupResolver(renegotiationSchema)
  })

  const { fields: irregularParcels, append, remove } = useFieldArray({
    control,
    name: 'irregular_installments'
  })
  const renegotiationType = watch('type')

  useEffect(() => {
    if (renegotiationType === renegotiationTypes.IRREGULAR) {
      append({ installment_number: 0, amount: 0 }, true)
    } else {
      remove()
    }
  }, [renegotiationType])

  const fetchCampaign = async () => {
    try {
      setLoading(true)
      const { data: campaign } = await getCampaignById(match.params.id)
      reset({
        operation_tax: campaign.rate_month,
        new_rate_month: campaign.rate_month,
        tax_default_interest: '',
        tax_fine: '',
        renegotiate_date: null,
        number_installments: '',
        new_grace_period: '',
        type: '',
        new_first_due: null
      })
      remove()
      setSimulatedBody({})
    } catch (error) {
      console.error(error)
      toast.error('Erro ao buscar os dados da campanha')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCampaign()
  }, [match.params.id])

  const simulatePrice = async body => {
    try {
      const { data: newInstallments } = await renegotiatePrice(body)
      setSimulatedBody({
        type: renegotiationTypes.PRICE,
        body: body,
        new_installments: newInstallments.taker_installments,
        new_balance: newInstallments.new_balance
      })
    } catch (error) {
      throw new Error('Ocorreu um erro ao simular a renegociação Price da campanha')
    }
  }

  const simulateIrregular = async (body, irregular_installments, new_first_due) => {
    try {
      let installmentNumber = 0
      const irregularInstallments = irregular_installments.reduce((acc, { installment_number, amount }) => {
        const spreadInstallments = [...Array(Number(installment_number)).keys()].map(_ => {
          installmentNumber = installmentNumber + 1
          return {
            installment_number: Number(installmentNumber),
            amount: formatCurrencyToBack(amount)
          }
        })
        return [...acc, ...spreadInstallments]
      }, [])
      const bodyIrregular = {
        ...body,
        new_first_due: moment(new Date(new_first_due)).format('YYYY-MM-DD'),
        irregular_installments: irregularInstallments
      }
      const { data: newInstallments } = await renegotiateIrregular(bodyIrregular)
      setSimulatedBody({
        type: renegotiationTypes.IRREGULAR,
        body: bodyIrregular,
        new_installments: newInstallments.taker_installments,
        new_balance: newInstallments.new_balance
      })
    } catch (error) {
      throw new Error('Ocorreu um erro ao simular a renegociação Irregular da campanha')
    }
  }

  const onSubmit = async values => {
    try {
      setSending(true)
      const body = {
        campaign_id: Number(match.params.id),
        renegotiate_date: moment(new Date(values.renegotiate_date)).format('YYYY-MM-DD'),
        tax_default_interest: formatPercentageToBack(values.tax_default_interest),
        tax_fine: formatPercentageToBack(values.tax_fine),
        number_installments: Number(values.number_installments),
        new_grace_period: Number(values.new_grace_period),
        new_rate_month: formatPercentageToBack(values.new_rate_month),
        is_simulation: true
      }
      switch (values.type) {
        case renegotiationTypes.IRREGULAR:
          await simulateIrregular(body, values.irregular_installments, values.new_first_due)
          break
        case renegotiationTypes.PRICE:
        default:
          await simulatePrice(body)
          break
      }
      toast.success('Renegociação simulada com sucesso')
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao simular a renegociação da campanha')
    } finally {
      setSending(false)
    }
  }

  const handleAddIrregularInstallment = () => {
    append({ installment_number: 0, amount: 0 }, true)
  }

  if (loading) return <LoadingComponent />

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Dados da Renegociação</Title>
        <Row>
          <NumberInput
            name='tax_default_interest'
            control={control}
            label='Mora *'
            error={errors['tax_default_interest']}
            suffix=' %'
          />
          <NumberInput
            name='operation_tax'
            control={control}
            label='Taxa operação *'
            error={errors['operation_tax']}
            disabled
            suffix=' %'
          />
          <NumberInput name='tax_fine' control={control} label='Multa *' error={errors['tax_fine']} suffix=' %' />
        </Row>
        <Row>
          <Select name='type' control={control} label='Tipo *' error={errors['type']} options={renegotiationOptions} />
          <DatePicker
            name='renegotiate_date'
            control={control}
            label='Data renegociação *'
            error={errors['renegotiate_date']}
          />
          <TextInput
            name='number_installments'
            control={control}
            label='Prazo (meses) *'
            error={errors['number_installments']}
          />
          <NumberInput
            name='new_rate_month'
            control={control}
            label='Nova taxa a.m. *'
            error={errors['new_rate_month']}
            suffix=' %'
          />
          <TextInput
            name='new_grace_period'
            control={control}
            label='Carência (meses) *'
            error={errors['new_grace_period']}
          />
        </Row>
        {renegotiationType === renegotiationTypes.IRREGULAR && (
          <Fragment>
            <Row>
              <Title>Parcelas irregulares</Title>
              <StyledAddIcon fontSize='large' onClick={handleAddIrregularInstallment} />
            </Row>
            <Row>
              <DatePicker
                name='new_first_due'
                control={control}
                label='Primeiro venc. *'
                error={errors['new_first_due']}
              />
            </Row>
          </Fragment>
        )}
        {renegotiationType === renegotiationTypes.IRREGULAR &&
          irregularParcels.map((field, index) => (
            <Row key={field.id}>
              <NumberInput
                name={`irregular_installments[${index}].installment_number`}
                control={control}
                label='Nº parcelas *'
                error={
                  errors['irregular_installments'] &&
                  errors['irregular_installments'][index] &&
                  errors['irregular_installments'][index]['installment_number']
                }
              />
              <RemoveContainer>
                <NumberInput
                  name={`irregular_installments[${index}].amount`}
                  control={control}
                  label='R$ parcela *'
                  error={
                    errors['irregular_installments'] &&
                    errors['irregular_installments'][index] &&
                    errors['irregular_installments'][index]['amount']
                  }
                  thousandSeparator='.'
                  type='currency'
                />
                <StyledRemoveIcon
                  fontSize='large'
                  onClick={() => {
                    if (irregularParcels.length > 1) remove(index)
                  }}
                />
              </RemoveContainer>
            </Row>
          ))}
        <ButtonContainer>
          <MaterialButton loading={sending} type='submit' label='Calcular' margin='10px' width='220px' />
        </ButtonContainer>
      </form>
      {Object.values(simulatedBody).length > 0 && (
        <SimulationTable simulation={simulatedBody} cleanForm={fetchCampaign} campaignId={Number(match.params.id)} />
      )}
    </Container>
  )
}

export default Renegotiation
