import React from 'react'

import { Column, Row, Text, IconButton } from '../index'
import { IconEdit, IconDelete } from '../icons'
import { EmailText, Card, StyledAvatar, Admin } from './styles'

import { formatCpf, formatPhoneWithoutPrefix, sanitizeDate } from '../../../helpers'

const ContributorCard = ({ contributor, role, onClick, editClick }) => {
  const isAdmin = response => {
    try {
      if (!!response.user.roles) {
        const fil = response.user.roles.filter(value => value.id !== 2)
        return fil.length > 0
          ? fil.every(role => {
              if (role.id === 10) {
                return false
              } else {
                return true
              }
            })
          : false
      }
    } catch (error) {}
  }

  return (
    <Card>
      <Column alignItems='center' mb={2}>
        <StyledAvatar src={contributor.s3_name} />
        <Text variant='subtitle2' mt={2}>
          {contributor.first_name} {contributor.last_name}
        </Text>
      </Column>
      <Column mb={2}>
        <Text variant='body2' styled={{ color: 'gray.700' }}>
          E-mail
        </Text>
        <EmailText> {contributor.business_email} </EmailText>
      </Column>
      <Column mb={2}>
        <Text variant='body2' styled={{ color: 'gray.700' }}>
          Telefone
        </Text>
        <Text variant='subtitle2'>
          ({contributor.prefix}) {formatPhoneWithoutPrefix(contributor.phone)}
        </Text>
      </Column>
      <Column mb={2}>
        <Text variant='body2' styled={{ color: 'gray.700' }}>
          Departamento
        </Text>
        <Text variant='subtitle2'>{role.name}</Text>
      </Column>
      <Column mb={2}>
        <Text variant='body2' styled={{ color: 'gray.700' }}>
          CPF
        </Text>
        <Text variant='subtitle2'>{formatCpf(contributor.user.document_number)}</Text>
      </Column>
      <Column>
        <Text variant='body2' styled={{ color: 'gray.700' }}>
          Data de nascimento
        </Text>
        <Row justifyContent='space-between'>
          <div>
            <Text variant='subtitle2'>{sanitizeDate(contributor.birth_date)}</Text>
          </div>
          <div>
            <IconButton width={16} height={16} variant='text' mr={2} icon={<IconEdit />} onClick={editClick} />
            <IconButton width={16} height={16} variant='text' icon={<IconDelete />} onClick={onClick} />
          </div>
        </Row>
      </Column>
      {isAdmin(contributor) && (
        <Admin>
          <p>ADMIN</p>
        </Admin>
      )}
    </Card>
  )
}

export default ContributorCard
