import styled from 'styled-components'
import { colors } from '../../../Themes/colors'

export const Container = styled.div`
  position: relative;
  width: ${({ wid }) => (wid ? '18%' : '221px')};
  height: auto;
  min-height: 100px;
  padding: 8px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background: ${({ disabled }) =>
    disabled
      ? 'linear-gradient(to right, #9E9E9E 4px, #ffffff 0%);'
      : `linear-gradient(to right, ${colors.blue} 4px, #ffffff 0%);`};

  box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
  border-radius: 4px;
  z-index: 2;

  button {
    border: none;
    background: transparent;

    img {
      margin-left: 9.33px;
      z-index: 5;

      :hover {
        transform: scale(1.2);
      }
    }
  }

  p {
    position: relative;
    margin: 0px 24px 0px 24px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #757575;
    z-index: 4;
  }

  label {
    position: relative;
    margin: 0px 24px 0px 24px;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;

    /* Neutral / Gray 800 */

    color: #424242;
  }

  @media (max-width: 1200px) {
    width: ${({ main }) => (main ? 100 : 49)}%;
    margin-bottom: 8px;
  }

  @media (max-width: 730px) {
    min-height: 70px;

    p {
      font-size: 14px;
      margin: 0px 16px 0px 16px;
    }

    label {
      font-size: 18px;
      line-height: 26px;
      margin: 0px 16px 0px 16px;
    }
  }
`
