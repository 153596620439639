import { numbroUnformat } from '../helpers/general'

export const formatPersonToUpdate = values => {
  const { agent, number, id, personal_document_type_id, kvd_id, email, phone, ...rest } = values
  return rest
}

export const formatAddressToUpdate = values => {
  const { reference, ...rest } = values
  return rest
}

export const formatBankToUpdate = values => {
  const { monthly_earn, property_value, ...rest } = values
  return rest
}

export const formatInvestorToUpdate = values => {
  const { kvd_id, code, agency, agency_digit, account, account_digit, std_id, type, ...rest } = values
  const unformatMonthlyEarn = numbroUnformat(rest.monthly_earn)
  const unformatPropertyValue = numbroUnformat(rest.property_value)
  rest.monthly_earn = unformatMonthlyEarn
  rest.property_value = unformatPropertyValue
  return rest
}

export const formatDocumentRGToUpdate = values => {
  const {
    birth_date,
    cpf,
    email,
    father_name,
    first_name,
    gender,
    last_name,
    marital_status_id,
    mother_name,
    nationality,
    naturality,
    phone,
    ...rest
  } = values

  return rest
}
