import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'

import { IconCheck, IconExpandMore } from '../../icons'
import { Container, SelectInput, Item, HelperText, Label, useSelectStyles } from './styles'

const Select = forwardRef(
  ({ name, label, options, error, helperText, defaultValue, control, children, ...props }, ref) => {
    const selectClasses = useSelectStyles()

    const menuProps = {
      classes: {
        paper: selectClasses.paper,
        list: selectClasses.list
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      getContentAnchorEl: null
    }

    const labelId = `${name}-label`

    return (
      <Container {...props}>
        <FormControl fullWidth {...props} error={error}>
          <Label id={labelId}>{label}</Label>
          <Controller
            as={
              <SelectInput
                ref={ref}
                classes={{ root: selectClasses.select }}
                IconComponent={IconExpandMore}
                MenuProps={menuProps}
                {...props}
              >
                {options.map(({ value, label }) => (
                  <Item key={value} value={value}>
                    {label}
                    <IconCheck />
                  </Item>
                ))}
              </SelectInput>
            }
            name={name}
            defaultValue={defaultValue}
            control={control}
          />
          {helperText && <HelperText>{helperText}</HelperText>}
        </FormControl>
      </Container>
    )
  }
)

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  defaultValue: PropTypes.string
}

Select.defaultProps = {
  variant: 'outlined',
  size: 'small',
  defaultValue: ''
}

export default Select
