import React from 'react'
import styled from '@emotion/styled'

const Filter = ({ value, onChange }) => {
  return (
    <FilterContainer>
      <FilterInput placeholder='Nome da campanha ...' value={value} onChange={onChange} />
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

const FilterInput = styled.input`
  height: 30px;
  padding: 0.5em;
  width: 170px;
  border-radius: 4px;
  font-size: 13px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGray};
  transition: all 0.3s;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.blueKavod};
    outline: 0;
  }
`

export default Filter
