import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import { personalDocumentsInputs } from '../../constants/forms'
import { getLinkToUpload, fetchPersonalDocuments } from '../../services/personalDocuments'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import { uploadDoc, updatePersonalDocument } from '../../services'
import { formatDocumentName } from '../../helpers/index'
import { renderField } from '../../constants/inputs'
import LoadingScreen from '../LoadingScreen'

const DocumentsForm = ({ classes, documents }) => {
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [withFirstDoc, setWithFirstDoc] = useState(null)
  const [withSecondDoc, setWithSecondDoc] = useState(null)

  useEffect(() => {
    handlePreviousDocuments()
  }, [])

  const handlePreviousDocuments = () => {
    const docsWithoutCR = documents.filter(documents => documents && documents.personal_document_type_id !== 'CR')
    const docCR = documents.filter(documents => documents && documents.personal_document_type_id === 'CR')
    if (documents) {
      if (docsWithoutCR.length > 0) {
        const latestUpload = docsWithoutCR.reduce((prev, prox) => {
          const prevDate = moment(prev.date_created)
          const proxDate = moment(prox.date_created)
          const diff = prevDate.diff(proxDate)

          if (diff > 0) {
            return prev
          }
          return prox
        })
        if (latestUpload) {
          latestUpload.with_document_type_id = true
          setWithFirstDoc(latestUpload)
        }
      }
      if (docCR.length > 0) {
        docCR[0].with_document_type_cr = true
        docCR[0].personal_document_type_cr = docCR[0].personal_document_type_id
        setWithSecondDoc(docCR[0])
      }
    }
    setLoading(false)
  }

  const handleSubmit = async data => {
    const promises = []
    const documentId = data.upload_personal_document_type_id
    const documentCr = data.upload_personal_document_type_cr
    setSubmitting(true)

    if (documentId) {
      try {
        const firstDocName = formatDocumentName(documentId.name)
        const linkFirstDoc = await getLinkToUpload(data.kvd_id, data.personal_document_type_id, firstDocName)
        const firstDocument = {
          kvd_id: data.kvd_id,
          personal_document_type_id: data.personal_document_type_id,
          original_name: linkFirstDoc.data.bucket_path
        }
        promises.push(uploadDoc(linkFirstDoc.data.url, documentId), updatePersonalDocument(firstDocument))
      } catch (error) {
        console.log(error)
        toast.error('Não foi possível atualizar os documentos')
      }
    }

    if (documentCr) {
      try {
        const secondDocName = formatDocumentName(documentCr.name)
        const linkSecondDoc = await getLinkToUpload(
          documentCr.kvd_id,
          documentCr.documents.personal_document_type_cr,
          secondDocName
        )
        const secondDocument = {
          id: documentCr.id,
          kvd_id: documentCr.kvd_id,
          personal_document_type_id: documentCr.personal_document_type_id,
          original_name: documentCr.original_name
        }

        promises.push(uploadDoc(linkSecondDoc.data.url, documentCr), updatePersonalDocument(secondDocument))
      } catch (error) {
        console.log(error)
        toast.error('Não foi possível atualizar os documentos')
      }
    }

    try {
      await Promise.all(promises)
      fetchPersonalDocuments(data.kvd_id)
      handlePreviousDocuments()
      setSubmitting(false)
      toast.success('Documentos atualizados!')
    } catch (error) {
      console.log(error)
      setSubmitting(false)
    }
  }

  const renderContent = (inputs, setFieldValue, onChange) => {
    return inputs.map(input => {
      if (input.name === 'personal_document_type_id') {
        input.document = withFirstDoc
      }
      if (input.name === 'personal_document_type_cr') {
        return (
          <p className={classes.pInputDropzone} key={input.id}>
            Comprovante de residência *
          </p>
        )
      }
      if (input.name === 'upload_personal_document_type_id' && withFirstDoc) {
        input.document = withFirstDoc
      }
      if (input.name === 'upload_personal_document_type_cr' && withSecondDoc) {
        input.document = withSecondDoc
      }
      return (
        <Field name={input.name} render={({ field }) => renderField(input, field, classes, setFieldValue, onChange)} />
      )
    })
  }

  return (
    <Grid container>
      <Grid item spacing={6}>
        {loading || submitting ? (
          <LoadingScreen small />
        ) : (
          <Formik
            initialValues={{
              kvd_id: documents.kvd_id,
              personal_document_type_id: withFirstDoc.personal_document_type_id || '',
              personal_document_type_cr: withSecondDoc.personal_document_type_id || '',
              upload_personal_document_type_id: '',
              upload_personal_document_type_cr: ''
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, onChange }) => (
              <Form className={classes.container}>
                {renderContent(personalDocumentsInputs, setFieldValue, onChange)}
                <Grid container spacing={1} className={classes.containerButton}>
                  <Fab variant='extended' type='submit' aria-label='Add' className={classes.button}>
                    CONCLUIR
                  </Fab>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Grid>
    </Grid>
  )
}

export default DocumentsForm
