import React from 'react'
import { withRouter } from 'react-router'
import { Wizard } from '../../components'
import styled from '@emotion/styled'

const CampaignContainer = ({ history, match, children, step }) => {
  return (
    <Container>
      <Wizard step={step} campaignId={match.params.id} history={history} />
      {children}
    </Container>
  )
}

const Container = styled.div`
  margin-top: 120px;
  display: flex;
`

export default withRouter(CampaignContainer)
