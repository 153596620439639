import React, { useEffect } from 'react'
import Popover from '@material-ui/core/Popover'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import { NumberInput } from '../../components/FormComponents'
import { MaterialButton } from '../../components'

import { formatCurrencyToBack, formatValueToBrl } from '../../helpers'
import { popoverFmmSchema } from '../../schemas'
import { toast } from 'react-toastify'

const PopoverFmm = ({ anchor, setAnchor, search, startValues }) => {
  const { control, errors, handleSubmit, reset } = useForm({ resolver: popoverFmmSchema })

  useEffect(() => {
    if (anchor) {
      reset({
        initial_value: formatValueToBrl(startValues.lending_amount_min),
        final_value:
          formatValueToBrl(startValues.lending_amount_max) !== 'R$ 0,00'
            ? formatValueToBrl(startValues.lending_amount_max)
            : null
      })
    }
  }, [anchor])

  const onSubmit = values => {
    if (formatCurrencyToBack(values.initial_value) < formatCurrencyToBack(values.final_value)) {
      search({
        lending_amount_min: formatCurrencyToBack(values.initial_value),
        lending_amount_max: formatCurrencyToBack(values.final_value)
      })
      setAnchor(null)
    } else {
      toast.error('Valor máximo menor que o valor mínimo.')
    }
  }

  const onClean = () => {
    reset()
    const body = {
      lending_amount_min: 0.01,
      lending_amount_max: null
    }
    search(body)
    setAnchor(null)
  }

  return (
    <Popover
      id='fmm-popover'
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>Busca por valor do empréstimo</Title>
        <NumberInput
          name='initial_value'
          control={control}
          label='De'
          error={errors['initial_value']}
          thousandSeparator='.'
          type='currency'
        />
        <NumberInput
          name='final_value'
          control={control}
          label='Até'
          error={errors['final_value']}
          thousandSeparator='.'
          type='currency'
        />
        <ButtonsContainer>
          <MaterialButton
            label='Limpar'
            margin='10px'
            padding='0.5em 1em'
            width='50%'
            color='#076f9f'
            backgroundColor='#ffffff'
            variant='outlined'
            onClick={onClean}
          />
          <MaterialButton
            type='submit'
            label='Buscar'
            margin='10px'
            padding='0.5em 1em'
            width='50%'
            color='#ffffff'
            backgroundColor='#076f9f'
            variant='outlined'
          />
        </ButtonsContainer>
      </Form>
    </Popover>
  )
}

const Form = styled.form`
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.p`
  font-weight: bold;
  align-self: center;
  color: #076f9f;
`

const ButtonsContainer = styled.div`
  display: flex;
`

export default PopoverFmm
