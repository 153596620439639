import client from '../../providers/fetchClient'

export const getInstallments = params => client.get('/v3/installments', { params })

export const getInstallmentStatus = () => client.get('/v3/installment-statuses')

export const getInstallmentsConsultPayments = () => client.get('/v3/campaign-installments/consult-payments')

export const getCampaignInstallments = params => client.get('/v3/campaign-installments', { params })

export const consultPayments = data => client.post('/v3/installments/consult-payments', data)

export const createInstallments = data => client.post('/v3/campaign-installments', data)

export const updateInstallment = (id, data) => client.put(`/v3/campaign-installments/${id}`, data)

export const installmentReceived = (id, data) =>
  client.put(`/v3/campaign-installments/${id}/installment-received`, data)

export const simulateInstallmentsDelay = data => client.post('/v3/campaign-installments/simulate', data)

export const changeStatusLatePayments = (id, data) => client.put(`/v3/campaign-installments/${id}/late-payments`, data)

export const updateValueDelayInstallments = (id, data) =>
  client.put(`/v3/campaign-installments/${id}/updated-gross-amount`, data)

export const removeDelayFromInstallments = id => client.put(`v3/campaign-installments/${id}/original-value`)

export const renegotiatePrice = (data, configs) =>
  client.post('/v3/campaign-installments/renegotiate-price', data, configs)

export const renegotiateIrregular = (data, configs) =>
  client.post('/v3/campaign-installments/renegotiate-irregular', data, configs)

export const anticipationOneInstallment = (installment_id, data) =>
  client.put(`/v3/campaign-installments/${installment_id}/antecipation`, data)

export const anticipationAllInstallments = data => client.post('/v3/campaign-installments/antecipation', data)

export const generatorPayments = data =>
  client.post('/v3/installments/generate-payments', data, {
    responseType: 'arraybuffer'
  })

export const generatorPaymentsCNAB = (group_by, file_date, payment_date, installments_ids) =>
  client.get(
    `/v3/installments/cnab?group_by=${
      group_by ? 'true' : 'false'
    }&file_date=${file_date}&payment_date=${payment_date}&installments_ids=${installments_ids}`,
    {
      responseType: 'arraybuffer'
    }
  )

export const getDownloadInstallments = id =>
  client.get(`/v3/campaign-installments?campaign_id=${id}&export_as_csv=true`, { responseType: 'arraybuffer' })

export const getDownloadInstallmentsExecution = (id, file_date) =>
  client.get(`/v3/campaign-installments/execution?campaign_id=${id}&file_date=${file_date}`, {
    responseType: 'arraybuffer'
  })
