import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import EditIcon from '@material-ui/icons/Edit'
import { toast } from 'react-toastify'

import { PopoverResponsible } from '../../components'

import { updateLeads } from '../../services/v3'
import { LeadContext } from '../../contexts'
import { abbreviateFullNameResp } from '../../helpers'

const ResponsibleCell = ({ lead }) => {
  const [anchor, setAnchor] = useState(null)
  const { refresh, search } = useContext(LeadContext)

  const hasResponsible = lead && lead.responsible

  const handleEdit = e => {
    setAnchor(e.currentTarget)
  }

  const handleSelectOption = async selectedOption => {
    try {
      const body = {
        responsible: selectedOption
      }
      await updateLeads(lead.id, body)
      setAnchor(null)
      refresh()
    } catch (error) {
      toast.error('Ocorreu um erro ao mudar o responsável')
    }
  }

  const handleFilterResponsible = () => {
    search({
      responsible: [lead.responsible]
    })
  }

  return (
    <ResponsibleContainer hasResponsible={hasResponsible}>
      {hasResponsible ? (
        <>
          <ResponsibleName onClick={handleFilterResponsible}>
            {abbreviateFullNameResp(lead.responsible, 15)}
          </ResponsibleName>
          <StyledEditIcon style={{ fontSize: 16 }} onClick={handleEdit} />
        </>
      ) : (
        <StyledAddCircleOutlineOutlinedIcon onClick={handleEdit} />
      )}
      <PopoverResponsible anchor={anchor} setAnchor={setAnchor} handleSelectOption={handleSelectOption} />
    </ResponsibleContainer>
  )
}

const ResponsibleName = styled.span`
  max-width: 100px;
  cursor: pointer;
`

const StyledAddCircleOutlineOutlinedIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: #076f9f;
  cursor: pointer;
`

const StyledEditIcon = styled(EditIcon)`
  color: #076f9f;
  cursor: pointer;
`

const ResponsibleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ hasResponsible }) => (hasResponsible ? 'space-between' : 'center')};
  align-items: center;
`

export default ResponsibleCell
