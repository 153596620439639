import styled from '@emotion/styled'
import { Edit } from '@material-ui/icons'
import { Form as FormikForm } from 'formik'

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1em;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`

export const ContainerButton = styled.div`
  display: flex;
  max-height: 54px;
  justify-content: flex-end;
`

export const EditIcon = styled(Edit)`
  cursor: pointer;
  color: #076f9f;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const InputContainer = styled.div`
  display: flex;
  width: 160px;
`

export const CardTitle = styled.p`
  font-size: 1em;
  width: 100%;
  margin-bottom: 1em;
  text-align: center;
`

export const BankInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const Form = styled(FormikForm)`
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 1em;
  max-width: 360px;
  margin-bottom: 1em;
`

export const Amount = styled.div`
  display: flex;
  min-width: 230px;
  justify-content: flex-start;
  align-items: center;
`

export const AmountContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-bottom: 1em;
  flex-direction: row;
`
