import React, { useState, useEffect } from 'react'
import { Home } from '@material-ui/icons'
import { withRouter, Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { MaterialButton } from '../../components'

import { getCampaignById } from '../../services/v3'
import { campaignStatuses } from '../../constants'

const runnedCampaigns = [campaignStatuses.CCBS, campaignStatuses.OLD_CCBS, campaignStatuses.DISBURSED]

const NavigationBar = ({ match, history }) => {
  const [isAvailableCcb, setIsAvailableCcb] = useState(false)
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const { data: campaignInfos } = await getCampaignById(match.params.id)
        setIsAvailableCcb(runnedCampaigns.includes(campaignInfos.campaign_status.abbreviation))
      } catch (error) {
        console.error(error)
      }
    }
    fetchStatus()
  }, [])
  return (
    <Container>
      <Link to='/'>
        <MaterialButton width={230} label={<Home />} />
      </Link>
      <Link to={`/campanha/${match.params.id}/pedidos`}>
        <MaterialButton width={230} label='Pedidos' />
      </Link>
      <Link to={`/campanha/${match.params.id}/parcelas`}>
        <MaterialButton width={230} label='Parcelas' />
      </Link>
      {isAvailableCcb && (
        <Link to={`/campanha/${match.params.id}/renegociacao`}>
          <MaterialButton width={230} label='Renegociação' />
        </Link>
      )}
      {isAvailableCcb && (
        <Link to={`/campanha/${match.params.id}/ccbs`}>
          <MaterialButton width={230} label='CCB' />
        </Link>
      )}
    </Container>
  )
}
const Container = styled.div`
  border-bottom: 1px solid lightgray;
  flex-wrap: wrap;
  justify-content: center;
  margin: 110px 2em 2em 2em;
  padding-bottom: 1em;
  display: flex;
  flex: 1;
`

export default withRouter(NavigationBar)
