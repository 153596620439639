import styled from 'styled-components'

export const ContainerTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 300px;
  height: 80px;

  background: #076f9f;
  border-radius: 12px;

  h4 {
    color: white;
    font-size: 1.6em;
  }

  p {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  @media (max-width: 540px) {
    min-width: 300px;
    width: 100%;
  }
`

export const ContainerFiltered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 300px;
  height: 80px;

  border-radius: 12px;
  border: solid 3px #076f9f;

  h4 {
    color: #076f9f;
    font-size: 1.6em;
  }

  p {
    color: #076f9f;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  @media (max-width: 540px) {
    min-width: 300px;
    width: 100%;
  }
`
