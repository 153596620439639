import React from 'react'

import { Row, Column, Text, Input, NumberInput, Select } from '../index'

const CompanyDataCompleted = ({ isEditing, states, errors, isMobile, isTablet, control }) => {
  if (isMobile || isTablet) {
    return (
      <Column>
        <Text variant='h4' mt={5}>
          Dados da empresa
        </Text>
        <NumberInput inputType='cnpj' control={control} name='cnpj' width='100%' mt={5} error={errors.cnpj} />
        <Input
          label='Empresa (Razão social) *'
          control={control}
          name='company_name'
          width='100%'
          mt={5}
          error={errors.company_name}
        />
        <Input
          label='Site da empresa (opcional)'
          control={control}
          name='company_site'
          width='100%'
          mt={5}
          error={errors.company_site}
        />
        <NumberInput inputType='cep' control={control} name='zip_code' width='100%' mt={5} error={errors.zip_code} />

        <Input name='street' width='100%' mt={5} control={control} label='Rua *' error={errors.street} />
        <Input label='Número *' control={control} name='number' width='100%' mt={5} error={errors.number} />
        <Input label='Complemento' control={control} name='complement' width='100%' mt={5} />
        <Input label='Cidade *' control={control} name='city' width='100%' mt={5} error={errors.city} />
        <Input label='Bairro *' control={control} name='district' width='100%' mt={5} error={errors.district} />
        <Select
          label='Estado *'
          control={control}
          name='uf'
          width='100%'
          options={states.map(({ id, text }) => ({ value: id, label: text }))}
          mt={5}
          error={errors.uf}
        />
        <NumberInput
          error={errors.average_monthly_revenue}
          inputType='currency'
          label='Faturamento médio mensal estimado *'
          name='average_monthly_revenue'
          control={control}
          width='100%'
          mt={5}
        />
        {!isEditing && (
          <NumberInput
            error={errors.desired_amount}
            inputType='currency'
            label='Valor do empréstimo desejado *'
            name='desired_amount'
            control={control}
            width='100%'
            mt={5}
          />
        )}
        <Input
          error={errors.company_history}
          label='Histórico da empresa *'
          control={control}
          name='company_history'
          width='100%'
          multiline
          rows={5}
          mt={5}
        />
        <Input
          error={errors.lending_reason}
          label='Motivo do empréstimo *'
          control={control}
          name='lending_reason'
          width='100%'
          multiline
          rows={5}
          mt={5}
        />
      </Column>
    )
  }
  return (
    <Column>
      <Row mt={5}>
        <Text variant='h4'> Dados da empresa </Text>
      </Row>
      <Row mt={5}>
        <NumberInput inputType='cnpj' control={control} name='cnpj' width='25%' error={errors.cnpj} />
        <Input
          label='Empresa (Razão social) *'
          error={errors.company_name}
          control={control}
          name='company_name'
          width='65%'
          ml={3}
        />
      </Row>
      <Row mt={5}>
        <Input
          label='Site da empresa (opcional)'
          control={control}
          name='company_site'
          error={errors.company_site}
          width='45%'
        />
      </Row>
      <Row mt={5}>
        <NumberInput inputType='cep' control={control} name='zip_code' width='25%' error={errors.zip_code} />
        <Input label='Rua *' control={control} name='street' width='37%' ml={3} error={errors.street} />
        <Input label='Número *' control={control} name='number' width='14%' ml={3} error={errors.number} />
        <Input label='Complemento' control={control} name='complement' width='10%' ml={3} />
      </Row>
      <Row mt={5}>
        <Input label='Cidade *' control={control} name='city' width='25%' error={errors.city} />
        <Input label='Bairro *' control={control} name='district' width='37%' ml={3} error={errors.district} />
        <Select
          label='Estado *'
          control={control}
          name='uf'
          width='26%'
          ml={3}
          error={errors.uf}
          options={states.map(({ id, text }) => ({ value: id, label: text }))}
        />
      </Row>
      <Row mt={5}>
        <NumberInput
          error={errors.average_monthly_revenue}
          inputType='currency'
          label='Faturamento médio mensal estimado *'
          name='average_monthly_revenue'
          control={control}
          width='45%'
        />
        {!isEditing && (
          <NumberInput
            error={errors.desired_amount}
            inputType='currency'
            label='Valor do empréstimo desejado *'
            name='desired_amount'
            control={control}
            width='45%'
            ml={3}
          />
        )}
      </Row>
      <Row mt={5}>
        <Input
          error={errors.company_history}
          label='Histórico da empresa *'
          control={control}
          name='company_history'
          width='92%'
          multiline
          rows={5}
        />
      </Row>
      <Row mt={5}>
        <Input
          error={errors.lending_reason}
          label='Motivo do empréstimo *'
          control={control}
          name='lending_reason'
          width='92%'
          multiline
          rows={5}
        />
      </Row>
    </Column>
  )
}

export default CompanyDataCompleted
