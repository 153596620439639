import client from '../../providers/fetchClient'

export const simulateInstallmentBmp = data => client.post('/v3/bmp/simulation', data)

export const updateCampaignInstallmentValues = data => client.post('/v3/bmp/installment-value', data)

export const getCampaignInstallmentValues = campaign_id => client.get(`/v3/bmp/installment-value/${campaign_id}`)

export const runCampaign = data => client.post('/v3/bmp/run-campaign', data)

export const getCampaignResponse = id => client.get(`/v3/bmp/campaign-response/${id}`)

export const processCampaignExtraFields = campaign_id => client.get(`/v3/bmp/extra-fields/${campaign_id}`)

export const changeCampaignProposalsStatus = campaign_id => client.get(`/v3/bmp/change-status/${campaign_id}`)

export const getLinkToDownloadCcbs = campaign_id =>
  client.get(`/v3/bmp/download-ccbs/${campaign_id}`, { responseType: 'blob' })
