import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { Row, Button, Text } from '../index'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StyledRow = styled(Row)`
  @media (max-width: 768px) {
    align-items: center;
    margin-bottom: 15px;
    flex-direction: column;
  }
`

export const StyledButton = styled(Button)`
  &&.MuiButton-root {
    margin-left: 24px;
    @media (max-width: 768px) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
`

export const StyledText = styled(Text)`
  &&.MuiTypography-root {
    margin-right: 24px;
    @media (max-width: 768px) {
      margin-right: 0px;
      margin-bottom: 10px;
      text-align: center;
    }
  }
`

export const Link = styled(RouterLink)`
  align-self: center;
  color: #076f9f;
  margin-left: 5px;
`
export const LinkPartner = styled.p`
  align-self: center;
  color: #076f9f;
  margin-left: 5px;
  cursor: pointer;
`
