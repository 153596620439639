export const styles = {
  space: {
    mui: false
  },
  sizes: [],
  palette: {
    white: '#ffffff',

    gray: {
      50: '#fdfdfd',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#E0E0E0',
      400: '#BDBDBD',
      main: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    },

    black: '#000000',

    primary: {
      light: '#0AA2E8',
      400: '#0987C1',
      main: '#076F9F',
      dark: '#07567B',
      700: '#064460'
    },

    secondary: {
      light: '#74D6F4',
      400: '#55CDF1',
      main: '#3DC6EF',
      dark: '#15AEDC',
      700: '#1395BC'
    },

    green: {
      400: '#43A047'
    },

    status: {
      inCaptation: '#076F9F',
      inFormalization: '#663CCC',
      concluded: '#4AB34E',
      waitingList: '#F6B344',
      defaulting: '#FD4646',
      late: '#F6B344',
      compliant: '#076F9F',
      completed: '#4AB34E'
    },

    action: {
      active: '#616161',
      disabled: '#BDBDBD'
    },

    error: {
      main: '#D93025'
    },

    text: {
      primary: '#424242',
      secondary: '#212121'
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontSize: '3rem',
      lineHeight: '64px'
    },
    h2: {
      fontSize: '2rem',
      lineHeight: '48px'
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '40px',
      fontWeight: 600
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: '26px',
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '24px'
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: '24px',
      fontWeight: 600,
      color: '#424242'
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '24px',
      fontWeight: 400
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '24px'
    },
    caption: {
      fontSize: '0,75rem',
      lineHeight: '16px'
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: '24px',
      fontWeight: 600
    }
  }
}
