import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'

import { ModalBase, Input, Column, Row, ImageUpload, NumberInput, Select, Button, Text, DatePicker } from '../index'

import useDevice from '../../../hooks/useDevice'
import { createEmployee } from '../../../services/v3'
import { EmployeesSchema } from '../../../schemas/Employees'

const ModalAddColabutor = ({open, onClose, areaTypes, loading}) => {
  const { control, handleSubmit, setValue, errors } = useForm({
    mode: 'onBlur',
    resolver: EmployeesSchema
  })
  const { isMobile } = useDevice()

  const handleDrop = files => {
    setValue('file', files)
  }

  const handleDelete = () => {
    setValue('file', '')
  }

  const onSubmit = async data => {
    try{
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append( 'email', data.email)
      formData.append('area_type_id', data.departament)
      formData.append('phone', data.celphone.replace(/([^\d])+/gim, '').slice(2))
      formData.append('prefix', data.celphone.replace(/([^\d])+/gim, '').slice(0, 2))
      formData.append('birth_date', format(data.date, 'yyyy-MM-dd'))
      formData.append('file', data.file[0])
      formData.append('document_number', data.document_number.replace(/[^\d]+/g, ''))

      await createEmployee(formData)
      toast.success('Funcionário adicionado')
      onClose()
      loading()
    }catch{
      toast.error('Erro ao criar funcionário')
    }
  }

  if(isMobile){
    return(
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <ModalBase title='Adicionar novo funcionário' iconClose width={300} height={720} open={open} onClose={onClose}>
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            <Column>
              <Row justifyContent='center'>
                <Column>
                  <ImageUpload control={control} name='file' onDrop={handleDrop} onDelete={handleDelete} />
                  {errors.file &&  (
                    <Text variant='subtitle1' color='error'>
               Campo obrigatório
                    </Text>
                  )}
                </Column>
              </Row>
              <Input control={control} name='first_name'mt={3} mb={3} placeholder='Nome' width='95%' error={errors.first_name}/>
              <Input control={control} name='last_name' placeholder='Sobrenome' width='95%'  mb={3} error={errors.last_name}/>
              <NumberInput control={control} name='document_number' inputType='cpf'  width='95%'  mb={3} error={errors.document_number}/>
              <DatePicker control={control} name='date' width='95%' label='Data de nascimento'  mb={3} error={errors.date}/>
              <Input control={control} name='email' placeholder='E-mail' width='95%'  mb={3} error={errors.email}/>
              <NumberInput control={control} name='celphone' label='Telefone' inputType='celphone' width='95%' mb={3} error={errors.celphone}/>
              <Select control={control} name='departament' options={areaTypes.map(({name, id}) => ({value: id, label: name}))}  error={errors.departament} width='95%' label='Departamento' mb={4}/>
              <Row mb={3}>
                <Button width='45%' variant='outlined' ml={1} mr={4} onClick={onClose}>Cancelar</Button>
                <Button width='45%' type='submit'>Enviar</Button>
              </Row>
            </Column>
          </form>
        </ModalBase>
      </MuiPickersUtilsProvider>

    )
  }

  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <ModalBase title='Adicionar novo funcionário' iconClose width={526} height={720} open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
          <Column>
            <Row justifyContent='center' mb={5}>
              <Column>
                <ImageUpload control={control} name='file' onDrop={handleDrop} onDelete={handleDelete} />
                {errors.file &&  (
                  <Text variant='subtitle1' color='error'>
               Campo obrigatório
                  </Text>
                )}
              </Column>
            </Row>
            <Row justifyContent='space-around' mb={5}>
              <Input control={control} name='first_name' placeholder='Nome' width='45%'  error={errors.first_name}/>
              <Input control={control} name='last_name' placeholder='Sobrenome' width='45%'  error={errors.last_name}/>
            </Row>
            <Row justifyContent='space-around' mb={5}>
              <NumberInput control={control} name='document_number' inputType='cpf'  width='45%'  error={errors.document_number}/>
              <DatePicker control={control} name='date' width='45%' label='Data de nascimento' error={errors.date}/>
            </Row>
            <Row justifyContent='space-around' mb={5}>
              <Input control={control} name='email' placeholder='E-mail' width='95%' error={errors.email}/>
            </Row>
            <Row justifyContent='space-around' mb={5}>
              <NumberInput control={control} name='celphone' label='Telefone' inputType='celphone' width='45%'  error={errors.celphone}/>
              <Select control={control} name='departament' options={areaTypes.map(({name, id}) => ({value: id, label: name}))} width='45%' label='Departamento' error={errors.departament}/>
            </Row>
            <Row>
              <Button width='45%' variant='outlined' ml={1} mr={4} onClick={onClose}>Cancelar</Button>
              <Button width='45%' type='submit'>Enviar</Button>
            </Row>
          </Column>
        </form>
      </ModalBase>
    </MuiPickersUtilsProvider>
  )
}

export default ModalAddColabutor
