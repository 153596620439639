import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useStyles } from './styles'

const SpinnerLoader = props => {
  const classes = useStyles()

  return (
    <CircularProgress
      variant='indeterminate'
      disableShrink
      size={24}
      thickness={4}
      value={100}
      className={classes.top}
      classes={{
        circle: classes.circle
      }}
      {...props}
    />
  )
}

export default SpinnerLoader
