import React, { useEffect, useState, Fragment } from 'react'
import { Add as ExpandIcon, Remove as CloseIcon, CheckCircle, Close } from '@material-ui/icons'
import styled from 'styled-components'
import { getUser, getUserBankInfo, getUserAdress, getUserPatrimony } from '../../services'
import { CircularProgress } from '@material-ui/core'

const InvestorsPanels = ({ person }) => {
  console.log(person)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [openPanel, setOpenPanel] = useState(null)
  const {
    completeProfile: { missingData }
  } = person
  const fetchUserInfo = async () => {
    const [
      {
        data: [personalInfo]
      },
      {
        data: [bankInfo]
      },
      {
        data: [addressInfo]
      },
      {
        data: [patrimonyInfo]
      }
    ] = await Promise.all([
      getUser(person.kvd_id),
      getUserBankInfo(person.kvd_id),
      getUserAdress(person.kvd_id),
      getUserPatrimony(person.kvd_id)
    ])
    setUser({
      personalInfo: personalInfo,
      bankInfo: bankInfo,
      addressInfo: addressInfo,
      patrimonyInfo: patrimonyInfo
    })
    setLoading(false)
  }

  useEffect(() => {
    fetchUserInfo()
  }, [])

  const renderMissingDataIcon = hasDataMissing =>
    hasDataMissing ? <Close style={{ color: 'red' }} /> : <CheckCircle style={{ color: 'green' }} />

  const renderExpandIcon = panel =>
    panel === openPanel ? (
      <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setOpenPanel(null)} />
    ) : (
      <ExpandIcon style={{ cursor: 'pointer' }} onClick={() => setOpenPanel(panel)} />
    )

  if (loading)
    return (
      <LoaderContainer>
        <CircularProgress style={{ color: '#0275d8', width: 25, height: 25 }} />
      </LoaderContainer>
    )
  return (
    <Fragment>
      <PanelContainer>
        <Title>{renderMissingDataIcon(missingData.address.length > 0)} Endereço</Title>
        {renderExpandIcon('address')}
      </PanelContainer>
      {openPanel === 'address' && (
        <PanelContainer>
          {console.log(missingData.address)}
          {console.log(user.addressInfo)}
          <Info error={!!user.addressInfo.zip_code}>
            <span>CEP: </span>
            {!!user.addressInfo.zip_code ? user.addressInfo.zip_code : 'não preenchido.'}
          </Info>
          <Info error={!!user.addressInfo.type}>
            <span>Tipo: </span>
            {!!user.addressInfo.type ? user.addressInfo.type : 'não preenchido.'}
          </Info>
          <Info error={!!user.addressInfo.street}>
            <span>Rua: </span>
            {!!user.addressInfo.street ? user.addressInfo.street : 'não preenchido.'}
          </Info>
          <Info error={!!user.addressInfo.number}>
            <span>Número: </span>
            {!!user.addressInfo.number ? user.addressInfo.number : 'não preenchido.'}
          </Info>
        </PanelContainer>
      )}
    </Fragment>
  )
}

const Info = styled.p`
  width: 33%;
  padding: 0.3em 0;
  color: ${props => (props.error ? 'black' : 'red')};
  span {
    font-weight: bold;
  }
`

const Title = styled.p``

const PanelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  border: 1px solid lightgray;
  justify-content: space-between;
  padding: 1em;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
`

export default InvestorsPanels
