import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'

import { LoadingComponent } from '../../components'
import { TabMenu } from '../../components/v2Components'

import { InstallmentsMaturities, ConsultPayments } from '../../routes'

import { Body, Title } from './style'

const OperationsControl = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [tabMenu, setTabMenu] = useState(0)

  const menu = useMemo(
    () => [
      {
        name: 'Consultar pagamentos',
        handleClick() {
          setTabMenu(0)
        }
      },
      {
        name: 'Vencimentos',
        handleClick() {
          setTabMenu(1)
        }
      }
    ],
    []
  )

  const fetchInfos = useCallback(async () => {
    try {
    } catch {
      toast.error('Não foi possível encontrar as parcelas da campanha.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchInfos()
  }, [])

  if (isLoading) return <LoadingComponent />

  return (
    <Body>
      <div className='main'>
        <Title>Controle de Operações</Title>
        <TabMenu options={menu} />

        {tabMenu === 0 && <ConsultPayments />}

        {tabMenu === 1 && <InstallmentsMaturities />}
      </div>
    </Body>
  )
}

export default OperationsControl
