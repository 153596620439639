import styled from '@emotion/styled'
import ReactTable from 'react-table-6'
import { Link } from 'react-router-dom'

export const Button = styled.button`
  height: 30px;
  cursor: pointer;
  padding: 0 1.3em;
  color: #fff;
  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.blueKavod};
  margin: 0 1em;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
`

export const ResetFilter = styled.p`
  font-size: 1em;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blueKavod};
  &:hover {
    text-decoration: underline;
  }
`

export const SearchContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`

export const SearchFieldContainer = styled.div`
  margin: 10px 0px 10px 10px;
  @media (max-width: 1024px) {
    margin: 0px;
    margin-bottom: 15px;
  }
`

export const KavodInfo = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  margin-bottom: 15px;
  margin-left: 15px;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const Row = styled.div`
  display: flex;
  font-size: 1.1em;
  margin-top: 15px;
`

export const Title = styled.p`
  font-weight: 600;
  white-space: nowrap;
`

export const Text = styled.p`
  padding: 0 0.5em;
  white-space: nowrap;
`

export const CampaignsContainer = styled.div`
  flex: 1;
  padding-top: 100px;
  padding-bottom: 30px;
  min-height: 100vh;
`

export const StyledReactTable = styled(ReactTable)`
  width: 80vw;

  & .rt-tr {
    height: 49px !important;
  }

  & .rt-th {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px !important;
  }
`

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ReactTableContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TextTable = styled.p`
  text-align: center;
`

export const StyledLink = styled(Link)`
  color: #000;
  display: block;
  width: 100%;
  padding: 16px 8px;
`
export const SearchCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SearchBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;

  @media (max-width: 1030px) {
    flex-direction: column;
  }
`
export const DivExport = styled.div`
  padding-right: 0px;
  background: transparent;
  button {
    background-color: transparent;
  }

  @media (max-width: 1030px) {
    padding-right: 20px;
  }
`
