import React, { useState } from 'react'
import ReactTable from 'react-table-6'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'

import { MaterialButton } from '../../components'

import { anticipationAllInstallments, anticipationOneInstallment } from '../../services/v3'
import { anticipationTypes } from '../../constants'
import { IDLE, SUBMITTING } from '../../constants/status'
import { simulationColumns } from './simulationColumns'

import { Title, Row } from './style'

const Resume = ({
  history,
  match,
  infos: {
    type,
    params,
    simulation: { taker_installment, taker_installments },
    installment_number
  }
}) => {
  const [status, setStatus] = useState(IDLE)

  const handleSave = async e => {
    try {
      e.preventDefault()
      setStatus(SUBMITTING)

      switch (type) {
        case anticipationTypes.ONE_INSTALLMENT:
          await anticipationOneInstallment(installment_number, {
            ...params,
            is_simulation: false
          })
          toast.success('Parcela antecipada com sucesso')
          break
        case anticipationTypes.ALL_INSTALLMENTS:
          await anticipationAllInstallments({ ...params, is_simulation: false })
          toast.success('Campanha antecipada com sucesso')
          break
        default:
          throw new Error('Erro de seleção de tipo')
      }
      history.push(`/campanha/${match.params.id}/parcelas`)
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao salvar a antecipação da campanha: ' + error)
    } finally {
      setStatus(IDLE)
    }
  }

  const prepareNewInstallments = () => {
    switch (type) {
      case anticipationTypes.ONE_INSTALLMENT:
        return taker_installment
      case anticipationTypes.ALL_INSTALLMENTS:
        const newInstallmentsSum = taker_installments.reduce((acc, { gross_amount }) => acc + gross_amount, 0)
        return taker_installments.concat({ installment_number: 'Total', gross_amount: newInstallmentsSum })
      default:
        return []
    }
  }

  const newInstallments = prepareNewInstallments()

  return (
    <div>
      <Title>Resumo Tomador</Title>
      <StyledReactTable
        className='ReactTableDefault'
        data={newInstallments}
        columns={simulationColumns}
        showPagination={false}
        pageSize={newInstallments.length}
        minRows={0}
      />
      <Row>
        <MaterialButton loading={status === SUBMITTING} label='Salvar' onClick={handleSave} width='220px' />
      </Row>
    </div>
  )
}

const StyledReactTable = styled(ReactTable)`
  margin: 10px;
  max-width: 280px;
  margin-bottom: 30px;
`

export default withRouter(Resume)
