import React from 'react'
import styled from 'styled-components'

const InfoField = ({ labelText, valueText, width }) => {
  return (
    <InfoFieldContainer width={width}>
      <InfoFieldLabel>{labelText}:</InfoFieldLabel>
      <InfoFieldValue>{valueText}</InfoFieldValue>
    </InfoFieldContainer>
  )
}

const InfoFieldContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
  border-radius: 4px;
  border-left: 4px solid #076f9f;
  padding: 8px 24px;
  margin: 12px 24px 12px 0px;
  width: ${({ width }) => width || '370px'};
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 12px 0px;
  }
`

const InfoFieldLabel = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-right: 4px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const InfoFieldValue = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export default InfoField
