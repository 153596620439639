import styled from 'styled-components'

export const TextEditor = styled.div`
  .ql-toolbar.ql-snow {
    border: 1px solid #424242;
    border-radius: 4px 4px 0px 0px;
  }

  .ql-container.ql-snow {
    border: 1px solid #424242;
    border-radius: 0px 0px 4px 4px;
    font-size: 15px;
  }

  .quill {
    padding: 0px;
  }

  .ql-editor {
    background: rgb(219 219 219 / 13%);
  }

  p,
  ul,
  ol {
    font-weight: 600;
    font-size: 18px;
    color: #757575;

    span {
      &.ql-size-small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 47px;
        color: #757575;
      }

      &.ql-size-large {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 47px;
        color: #757575;
      }

      &.ql-size-huge {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 47px;
        color: #757575;
      }
    }

    strong {
      color: black;
      &.ql-size-small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 47px;
        color: black;
      }

      &.ql-size-large {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 47px;
        color: black;
      }

      &.ql-size-huge {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 47px;
        color: black;
      }
    }
  }

  h5 {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #9e9e9e;
    text-transform: uppercase;
    position: relative;
    top: -8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: white;
    left: 11px;
    z-index: 10;
  }

  @media (max-width: 720px) {
    margin-bottom: 40px;
  }
`
