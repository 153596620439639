import React from 'react'

import { formatValueToBrl } from '../../helpers'

export const simulationColumns = [
  {
    Header: 'Nº parcela',
    accessor: 'installment_number',
    minWidth: 80,
    Cell: ({ original: { installment_number } }) => <p>{installment_number}</p>
  },
  {
    Header: 'Venc. Tomador',
    accessor: 'expire_date',
    minWidth: 140,
    Cell: ({ original: { expire_date } }) => <p>{expire_date}</p>
  },
  {
    Header: 'Venc. Investidores',
    accessor: 'transfer_date',
    minWidth: 160,
    Cell: ({ original: { transfer_date } }) => <p>{transfer_date}</p>
  },
  {
    Header: 'Juros',
    accessor: 'interest',
    minWidth: 140,
    Cell: ({ original }) => <p>{formatValueToBrl(original.interest)}</p>
  },
  {
    Header: 'Principal',
    accessor: 'principal',
    minWidth: 140,
    Cell: ({ original }) => <p>{formatValueToBrl(original.principal)}</p>
  },
  {
    Header: 'Valor',
    accessor: 'gross_amount',
    minWidth: 140,
    Cell: ({ original }) => <p>{formatValueToBrl(original.amount)}</p>
  }
]
