import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import './style.css'

const LoadingScreen = ({ small }) => (
  <div className={small ? 'loading-small' : 'loading-wrapper'}>
    <div className='flex-center-column'>
      <Loader src='/assets/icons/spinner.svg' />
    </div>
  </div>
)

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.img`
  width: 40px;
  animation: ${rotate} 1s linear infinite;
`

export default LoadingScreen
