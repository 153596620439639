/* eslint-disable indent */
import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment-timezone'
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { FormControlLabel } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import Pledges from './Pledges'
import Pledge from './Pledge'
import FilterByName from './FilterByName'
import {
  LoadingComponent,
  PledgeModal,
  Checkbox,
  MaterialButton,
  CampaignStatus,
  ModalReopenCampaign
} from '../../components'

import ModalRunIndo from './ModalRunInfo'

import { IconButton, Row, Radio as RadioV2 } from '../../components/v2Components'
import { IconEdit, IconCopy } from '../../components/v2Components/icons'

import { formatCNPJ, formatMoney, getAnnualRate, getSiteLink, copyCnpj } from '../../helpers'
import {
  acceptedAverageM,
  filterAcceptedPledgesArray,
  filterOnHoldingPledgesArray,
  filterCancelledPledgesArray,
  filterDeletedPledgesArray,
  sortPledgesBySttAndKvdid,
  matrization,
  sortMatrizationById
} from '../../helpers/pledges'
import { useDebounce } from '../../hooks'
import { campaignTypesLink } from '../../constants'
import { getCampaignById, getPledges, getPledgeStatuses, updatePledge, getDownloadPledges } from '../../services/v3'

const radioValues = [
  {
    value: 'all',
    label: 'Todos'
  },
  {
    value: 'yes',
    label: 'Sim'
  },
  {
    value: 'no',
    label: 'Não'
  }
]

const CampaignPledges = ({ match, history }) => {
  const [campaign, setCampaign] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState(['AC', 'A', 'OH', 'C', 'D'])
  const [isOpen, setIsOpen] = useState(false)
  const [downloadFilters, setDownloadFilters] = useState({ AC: true, OH: false, C: false, A: true, D: false })
  const [filterName, setFilterName] = useState('')
  const [matrizationPledges, setMatrizationPledges] = useState([])
  const [pledgeStatuses, setPledgeStatuses] = useState([])
  const [isOpenModalReopenCampaign, setIsOpenModalReopenCampaign] = useState(false)
  const [isOpenModalRun, setIsOpenModalRun] = useState(false)

  const { errors, control, watch } = useForm({ defaultValues: { ted: 'all', whats: 'all' } })
  const valuesRadio = watch()

  const fetchCampaign = async () => {
    try {
      setIsLoading(true)
      const [{ data: campaign }, { data: pledges }] = await Promise.all([
        getCampaignById(match.params.id),
        await getPledges({ campaign_id: match.params.id })
      ])
      const copyPledges = pledges.slice().sort(sortPledgesBySttAndKvdid)
      setMatrizationPledges(matrization(copyPledges))
      setCampaign(campaign)
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os dados de pedidos: ' + error)
      setMatrizationPledges([])
    } finally {
      setIsLoading(false)
    }
  }

  const refresh = async () => {
    try {
      const [{ data: campaign }, { data: pledges }] = await Promise.all([
        getCampaignById(match.params.id),
        await getPledges({ campaign_id: match.params.id })
      ])
      const copyPledges = pledges.slice().sort(sortPledgesBySttAndKvdid)
      setMatrizationPledges(matrization(copyPledges))
      setCampaign(campaign)
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os dados de pedidos: ' + error)
      setMatrizationPledges([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setIsLoading(true)
        const { data: pledgeStatuses } = await getPledgeStatuses()
        setPledgeStatuses(pledgeStatuses)
        await fetchCampaign()
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar os status de pedidos')
      } finally {
        setIsLoading(false)
      }
    }
    fetchInfos()
  }, [match.params.id])

  const handleClick = _ => setIsOpen(!isOpen)

  const handleRunCampaign = useCallback(async () => {
    try {
      let valueAcceptedPledges = filterAcceptedPledgesArray(matrizationPledges).reduce(
        (acc, obj) => acc + obj.reduce((sum, pledge) => sum + pledge.amount, 0),
        0
      )
      let numberAcceptedPledges = filterAcceptedPledgesArray(matrizationPledges).length
      let tedValueAcceptedPledges = filterAcceptedPledgesArray(matrizationPledges)
        .filter(pledgeArray => !!pledgeArray[0].ted_is_confirmed === true)
        .reduce((acc, obj) => acc + obj.reduce((sum, pledge) => sum + pledge.amount, 0), 0)
      let numberTedAcceptedPledges = filterAcceptedPledgesArray(matrizationPledges).filter(
        pledgeArray => !!pledgeArray[0].ted_is_confirmed === true
      ).length

      if (numberAcceptedPledges === numberTedAcceptedPledges && valueAcceptedPledges === tedValueAcceptedPledges) {
        history.push(`/campanha/${match.params.id}/rodar-campanha`)
      } else {
        setIsOpenModalRun({
          numberAcceptedPledges: numberAcceptedPledges,
          valueAcceptedPledges: formatMoney(valueAcceptedPledges),
          numberTedAcceptedPledges: numberTedAcceptedPledges,
          tedValueAcceptedPledges: formatMoney(tedValueAcceptedPledges)
        })
      }
    } catch (e) {}
  }, [match.params.id, matrizationPledges])

  const handleDownloadPledges = useCallback(async () => {
    try {
      const status = Object.keys(downloadFilters)
        .filter(i => downloadFilters[i] === true)
        .join()
      const { data: response } = await getDownloadPledges(match.params.id, status)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = `Pedidos-${!!campaign ? campaign.name : ''}.xlsx`
      element.click()
    } catch (error) {
      if (error.response.status === 404) {
        toast.error('Pedidos com o status selecionado não foram encontrados.')
      } else {
        toast.error('Ocorreu um erro ao exportar.')
      }
    }
  }, [match.params.id, campaign, downloadFilters])

  const debounceFilterName = useDebounce(filterName, 500)

  const handleCheckbox = e => {
    const isChecked = e.target.checked
    switch (e.target.id) {
      case 'onHolding':
        setFilters(filters =>
          isChecked ? [].concat(['OH'], filters) : filters.filter(filter => !['OH'].includes(filter))
        )
        break

      case 'cancelled':
        setFilters(filters =>
          isChecked ? [].concat(['C'], filters) : filters.filter(filter => !['C'].includes(filter))
        )
        break

      case 'deleted':
        setFilters(filters =>
          isChecked ? [].concat(['D'], filters) : filters.filter(filter => !['D'].includes(filter))
        )
        break
      case 'confirmed':
        setFilters(filters =>
          isChecked ? [].concat(['AC', 'A'], filters) : filters.filter(filter => !['AC', 'A'].includes(filter))
        )
        break
      default:
        break
    }
  }

  const formatFractionTotal = () => {
    const totalAmount = matrizationPledges.reduce(
      (acc, obj) => acc + obj.reduce((sum, pledge) => sum + pledge.amount, 0),
      0
    )
    return (
      <div style={{ display: 'flex', paddingRight: '16px' }}>
        <p>Total de Pedidos:</p>
        <p style={{ marginLeft: '5px' }}>
          <span>
            {matrizationPledges.length} | R$ {formatMoney(totalAmount)}
          </span>
        </p>
      </div>
    )
  }

  const formatFraction = data => {
    if (!data) return null
    const totalAmount = data.reduce((acc, obj) => acc + obj.reduce((sum, pledge) => sum + pledge.amount, 0), 0)
    return (
      <div style={{ display: 'flex' }}>
        <p>{data.length} |</p>
        <p style={{ marginLeft: '5px' }}>
          <span>R$ {formatMoney(totalAmount)}</span>
        </p>
      </div>
    )
  }

  const handleUpdate = async (pledge, newStatus) => {
    try {
      const findNewStatus = pledgeStatuses.find(({ abbreviation }) => abbreviation === newStatus)
      if (!findNewStatus) throw new Error('Ocorreu um erro ao buscar o novo status do pedido')
      const body = {
        campaign_id: pledge.campaign_id,
        pledge_status_id: findNewStatus.id
      }
      const {
        data: { should_campaign_be_active }
      } = await updatePledge(pledge.id, body)
      if (should_campaign_be_active) showReopenCampaignModal()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar o status do pedido')
    } finally {
      setIsLoading(false)
      refresh()
    }
  }

  const handleUpdatePledges = async (pledges, newStatus) => {
    try {
      const findNewStatus = pledgeStatuses.find(({ abbreviation }) => abbreviation === newStatus)
      if (!findNewStatus) throw new Error('Ocorreu um erro ao buscar o novo status dos pedidos')
      const updatesResonse = await Promise.all(
        pledges.map(pledge =>
          updatePledge(pledge.id, {
            campaign_id: pledge.campaign_id,
            pledge_status_id: findNewStatus.id
          })
        )
      )
      const shouldReopen = updatesResonse.some(({ data: { should_campaign_be_active } }) => should_campaign_be_active)
      if (shouldReopen) showReopenCampaignModal()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar o status dos pedidos')
    } finally {
      setIsLoading(false)
      refresh()
    }
  }

  const showReopenCampaignModal = () => setIsOpenModalReopenCampaign(true)

  const checkboxChange = name => event => {
    if (name === 'AC') {
      setDownloadFilters({ ...downloadFilters, A: event.target.checked, AC: event.target.checked })
    } else {
      setDownloadFilters({ ...downloadFilters, [name]: event.target.checked })
    }
  }

  if (isLoading) return <LoadingComponent />

  return (
    <Container>
      <Content>
        {campaign && (
          <FlowContainer>
            <CampaignStatus match={match} campaign={campaign} reloadCampaign={refresh} />
          </FlowContainer>
        )}
        <CampaignTitleContainer>
          <Row style={{ alignItems: 'center' }}>
            <CampaignName>{campaign.name}</CampaignName>
            <CampaignLink
              onClick={() =>
                window.open(
                  `${getSiteLink()}/campanhas/${campaignTypesLink[campaign.campaign_type.id]}/${campaign.url}`
                )
              }
            >
              (ver campanha)
            </CampaignLink>
            <IconButton
              icon={<IconEdit fontSize='small' />}
              variant='text'
              onClick={() => window.open(`${getSiteLink()}/admin/campanhas/${campaign.id}/`)}
              ml={1}
            />
          </Row>
          <CampaignCnpj>
            {formatCNPJ(campaign.company.cnpj)}
            <IconButton
              icon={<IconCopy style={{ fontSize: 16 }} />}
              variant='text'
              onClick={() => {
                copyCnpj(campaign.company.cnpj)
              }}
            />
          </CampaignCnpj>
        </CampaignTitleContainer>
        <Header>
          <div>
            <CampaignInfo>
              Data do lançamento:
              {moment.utc(campaign.start_date).format('DD/MM/YYYY [às] HH:mm')}
            </CampaignInfo>
            <CampaignInfo>Meta de captação: R$ {formatMoney(campaign.amount)}</CampaignInfo>
          </div>
          <div>
            <CampaignInfo>
              Taxa mensal / anual: {String(campaign.rate_month).replace('.', ',')}% /
              {getAnnualRate(campaign.rate_month)}%
            </CampaignInfo>
            <CampaignInfo>
              Tipo de Campanha: {campaign.campaign_type_id === 1 ? 'Aval' : 'Aval e Garantia'}
            </CampaignInfo>
          </div>
          <div>
            <CampaignInfo>Ticket médio: R$ {formatMoney(acceptedAverageM(matrizationPledges))}</CampaignInfo>
            {campaign.campaign_type_id === 2 && (
              <CampaignInfo>Percentual em garantia: {campaign.collateral}%</CampaignInfo>
            )}
          </div>
        </Header>
        <ActionsContainer>
          <a target='_blank' rel='noopener noreferrer' href={`${getSiteLink()}/admin/campanhas/${campaign.id}/?tab=4`}>
            <MaterialButton width={210} label='Criar Atualização' />
          </a>

          <MaterialButton width={210} label='Rodar Campanha' onClick={handleRunCampaign} />
        </ActionsContainer>
        <DownloadCSVContainer>
          <DownloadCSVButton
            disabled={!downloadFilters.AC && !downloadFilters.OH && !downloadFilters.C && !downloadFilters.D}
            onClick={handleDownloadPledges}
          >
            Exportar Pedidos
          </DownloadCSVButton>
          <FormControlLabel
            control={
              <Checkbox checked={downloadFilters.AC} onChange={checkboxChange('AC')} value='AC' color='primary' />
            }
            label='Confirmados'
          />
          <FormControlLabel
            control={
              <Checkbox checked={downloadFilters.OH} onChange={checkboxChange('OH')} value='OH' color='primary' />
            }
            label='Em espera'
          />
          <FormControlLabel
            control={<Checkbox checked={downloadFilters.C} onChange={checkboxChange('C')} value='C' color='primary' />}
            label='Cancelado'
          />
          <FormControlLabel
            control={<Checkbox checked={downloadFilters.D} onChange={checkboxChange('D')} value='D' color='primary' />}
            label='Deletado'
          />
        </DownloadCSVContainer>

        <ContainerFilters>
          <Filters
            type='byName'
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
          >
            <FilterByName onChangeName={setFilterName} defaultValue={filterName} />
            <FilterByNameValue>{formatFractionTotal()}</FilterByNameValue>
          </Filters>
        </ContainerFilters>
        <ContainerFilters>
          <FilterStatus>
            <Filters>
              <Filter labelBg='#5cb85c' hidden={false}>
                <div>
                  <input onChange={handleCheckbox} id='confirmed' type='checkbox' defaultChecked />
                  <label htmlFor='confirmed'>Lista de confirmados</label>
                </div>
                {formatFraction(filterAcceptedPledgesArray(matrizationPledges))}
              </Filter>
              <Filter labelBg='#076F9F' hidden={false}>
                <div>
                  <input onChange={handleCheckbox} id='onHolding' type='checkbox' defaultChecked />
                  <label htmlFor='onHolding'>Lista de espera</label>
                </div>
                {formatFraction(filterOnHoldingPledgesArray(matrizationPledges))}
              </Filter>
            </Filters>

            <Filters>
              <Filter labelBg='#d9534f' hidden={false}>
                <div>
                  <input onChange={handleCheckbox} id='cancelled' type='checkbox' defaultChecked />
                  <label htmlFor='cancelled'>Lista de cancelados</label>
                </div>
                {formatFraction(filterCancelledPledgesArray(matrizationPledges))}
              </Filter>
              <Filter labelBg='#d9534f' hidden={false}>
                <div>
                  <input onChange={handleCheckbox} id='deleted' type='checkbox' defaultChecked />
                  <label htmlFor='deleted'>Lista de deletados</label>
                </div>
                {formatFraction(filterDeletedPledgesArray(matrizationPledges))}
              </Filter>
            </Filters>
          </FilterStatus>

          <Filters type='ted'>
            <Filter c='#000' fw='300' p='0px' hidden={false}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor='ted'>TED / Pix</label>
                <Radio mb='0px !important' name='ted' control={control} error={errors.ted} options={radioValues} />
              </div>
              {formatFraction(
                filterAcceptedPledgesArray(matrizationPledges).filter(pledgeArray =>
                  valuesRadio.ted === 'all'
                    ? true
                    : valuesRadio.ted === 'yes'
                    ? !!pledgeArray[0].ted_is_confirmed === true
                    : !!pledgeArray[0].ted_is_confirmed === false
                )
              )}
            </Filter>

            <Filter c='#000' fw='300' p='2px' hidden={false}>
              <div>
                <img
                  htmlFor='whats'
                  src='/assets/images/icon-whatsapp.png'
                  alt='Whatsapp'
                  style={{ marginRight: '30.34px' }}
                />
                <Radio mb='0px !important' name='whats' control={control} error={errors.whats} options={radioValues} />
              </div>
              {formatFraction(
                filterAcceptedPledgesArray(matrizationPledges).filter(pledgeArray =>
                  valuesRadio.whats === 'all'
                    ? true
                    : valuesRadio.whats === 'yes'
                    ? !!pledgeArray[0].whatsapp_is_confirmed === true
                    : !!pledgeArray[0].whatsapp_is_confirmed === false
                )
              )}
            </Filter>
          </Filters>
        </ContainerFilters>
        {matrizationPledges
          .filter(pledgeArray => filters.includes(pledgeArray[0].pledge_status.abbreviation))
          .filter(pledgeArray => {
            const { first_name, last_name } = pledgeArray[0].investor
            return (
              `${first_name} ${last_name}`.toUpperCase().includes(debounceFilterName.toUpperCase()) ||
              `${first_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')} ${last_name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')}`
                .toUpperCase()
                .includes(debounceFilterName.toUpperCase())
            )
          })
          .filter(pledgeArray =>
            valuesRadio.ted === 'all'
              ? true
              : valuesRadio.ted === 'yes'
              ? !!pledgeArray[0].ted_is_confirmed === true
              : !!pledgeArray[0].ted_is_confirmed === false
          )
          .filter(pledgeArray =>
            valuesRadio.whats === 'all'
              ? true
              : valuesRadio.whats === 'yes'
              ? !!pledgeArray[0].whatsapp_is_confirmed === true
              : !!pledgeArray[0].whatsapp_is_confirmed === false
          )
          .sort(sortMatrizationById)
          .map(pledgeArray =>
            pledgeArray.length === 1 ? (
              <Pledge
                key={pledgeArray[0].id}
                pledge={pledgeArray[0]}
                reload={refresh}
                handleUpdate={handleUpdate}
                campaign={campaign}
                pledgeStatuses={pledgeStatuses}
                reopenCampaingModal={showReopenCampaignModal}
              />
            ) : (
              <Pledges
                key={pledgeArray[0].id}
                pledges={pledgeArray}
                reload={refresh}
                handleUpdate={handleUpdate}
                handleUpdatePledges={handleUpdatePledges}
                campaign={campaign}
                pledgeStatuses={pledgeStatuses}
                reopenCampaingModal={showReopenCampaignModal}
              />
            )
          )}
      </Content>
      <CreatePledgeContainer data-tip='Adicionar Pledge' onClick={handleClick}>
        <PlusIcon>+</PlusIcon>
      </CreatePledgeContainer>
      {isOpen && (
        <PledgeModal
          campaign={campaign}
          reload={refresh}
          handleClick={handleClick}
          pledgeStatuses={pledgeStatuses}
          title='Criar Pedido'
          reopenCampaingModal={showReopenCampaignModal}
        />
      )}
      {isOpenModalReopenCampaign && (
        <ModalReopenCampaign
          isOpen={isOpenModalReopenCampaign}
          setIsOpen={setIsOpenModalReopenCampaign}
          campaignId={campaign.id}
          reload={refresh}
        />
      )}
      <ModalRunIndo open={isOpenModalRun} onClose={() => setIsOpenModalRun(false)} />
      <ReactTooltip />
    </Container>
  )
}

const ActionsContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    justify-content: flex-start;
  }
`

const FlowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 769px) {
    justify-content: flex-start;
  }
`

const CampaignInfo = styled.p`
  padding: 0.3em;
  font-size: 1em;
  font-weight: 500;
  @media (max-width: 769px) {
    padding: 0.3em 0;
  }
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1em;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
    justify-content: center;
  }
`

const DownloadCSVContainer = styled.div`
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    label {
      width: 150px;
    }
  }
`

const DownloadCSVButton = styled.button`
  border-radius: 4px;
  padding: 0.6em 35px;
  margin: 0 1em 0 0;
  background: white;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  outline: none;
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : 'white')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  color: ${({ disabled }) => (disabled ? '#252525' : '#076F9F')};
  border: 1px solid ${({ disabled }) => (disabled ? '#252525' : '#076F9F')};
  cursor: ${({ disabled }) => (disabled ? 'not-allwed' : 'pointer')};

  &:hover {
    background: #076f9f;
    color: white;
    transition: all 0.3s;
  }
`

const CampaignTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 18px;
`

const CampaignName = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 5px;
`

const CampaignLink = styled.button`
  margin-bottom: 3px;
  margin-top: 5px;
  background: transparent;
  border: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blueKavod};
  cursor: pointer;
  outline: none;
  text-decoration: underline;
`

const CampaignCnpj = styled.div`
  width: 100%;
  font-size: 1.125rem;
  font-weight: bold;
`

const PlusIcon = styled.p`
  font-size: 30px;
`

const CreatePledgeContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5em;
  border-radius: 50%;
  background-color: #5cb85c;
  box-shadow: 0 0 1px 0 #5cb85c inset, 0 0 1px 0 #5cb85c;
  position: fixed;
  bottom: 20px;
  right: 45px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #3d7c3d;
    box-shadow: 0 0 1px 0 #3d7c3d inset, 0 0 1px 0 #3d7c3d;
    transition: all 0.3s;
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  p {
    span {
      font-weight: 500;
    }
  }
`

const Content = styled.div`
  width: 80%;
  @media (max-width: 769px) {
    margin-bottom: 60px;
  }
`

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (!!props.p ? `${props.p} 16px` : '16px')};
  padding-left: ${props => (!!props.p ? '16px' : '')};
  ${props => props.hidden && 'display: none;'}
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  input {
    margin-right: 10px;
  }
  label {
    background-color: ${props => props.labelBg};
    color: ${props => (!!props.c ? props.c : 'white')};
    padding: 0.25em 0.4em;
    border-radius: 0.25rem;
    font-weight: ${props => (!!props.fw ? props.fw : '900')};
    line-height: 1;
  }
  input {
    width: 15px;
    height: 15px;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    padding: 16px;
    height: inherit;

    display: flex;
    gap: 10px;
  }
`

const Filters = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  margin-bottom: 30px;
  width: ${({ type }) => (type === 'ted' ? '70%' : '100%')};

  @media (max-width: 1655px) {
    margin-bottom: ${({ type }) => (type === 'ted' ? '30px' : type === 'byName' ? '30px' : '0px')};
    width: ${({ type }) => (type === 'ted' ? '100%' : '')};
    height: min-content;
  }

  @media (max-width: 1200px) {
    margin-bottom: 15px;
    width: 100%;
  }
`

const FilterStatus = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;

  @media (max-width: 1655px) {
    gap: 0px;
    flex-direction: column;
    max-width: 40%;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
  }
`

const ContainerFilters = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 1655px) {
    margin-bottom: 30px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0px;
  }
`

const FilterByNameValue = styled.div`
  display: flex;
  padding: 22px 16px 16px 0px;

  @media (max-width: 1040px) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

const Radio = styled(RadioV2)`
  label {
    margin-right: 10px !important;
  }
`

export default withRouter(CampaignPledges)
