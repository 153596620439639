import React from 'react'
import { Container } from './styles'

const PaymentConfirmed = () => {
  return (
    <Container>
      <img src='/assets/images/check.svg' alt='Parcela paga' />
    </Container>
  )
}

export default PaymentConfirmed
