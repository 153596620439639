import PropTypes from 'prop-types'
import styled from 'styled-components'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

const Row = styled.div`
  display: flex;
  ${spacing};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
  ${palette};
  ${typography};
`

Row.propTypes = {
  flex: PropTypes.number,
  flexWrap: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string
}

export const RowDesktop = styled(Row)`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
  }
`

export const RowMobile = styled(Row)`
  display: flex;
  @media (min-width: 1200px) {
    display: none;
  }
`

export default Row
