import styled from 'styled-components'

export const Content = styled.main`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    background: transparent;
    border: none;
  }
`
