import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .main {
    width: 100%;
    max-width: 1800px;
    padding-top: 78px;
    padding-bottom: 40px;
    margin: 0px 24px;
  }
`
export const Title = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-top: 24px;

  color: #424242;
`
export const Subtitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #424242;
`
