import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Grid = styled.div`
  display: ${({ show }) => (!!show ? 'block' : 'none')};
  width: 600px;
  border-style: solid;
  border: 2px solid;
  background-color: white;

  h4 {
    display: flex;
    align-items: center;
    min-height: 28px;
    height: inherit;
    border: 1px dotted gray;
    padding-left: 5px;
  }

  label {
    display: flex;
    align-items: center;
    min-height: 28px;
    height: inherit;
    border: 1px dotted gray;
    padding-left: 5px;

    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
  }
`
export const ImageProposal = styled.div`
  width: 100%;
  max-width: 600px;

  img {
    /* width: -webkit-fill-available;
    width: -moz-available; */
    width: 100%;
    height: 100%;
  }
`
export const Link = styled.a`
  color: #757575;
  font-weight: 500;
  text-decoration: none;

  :hover {
    color: #076f9f;
    text-decoration: underline;
  }
`
