import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CloseIcon from '@material-ui/icons/Close'
import { toast } from 'react-toastify'

import { MaterialButton, LoadingComponent, SignatureResume } from '../../components'
import ModalChangeStatus from './ModalChangeStatus'

import { IDLE, ERROR, FETCHING } from '../../constants/status'
import { getCampaignResponse, getEletronicSignatures, changeCampaignProposalsStatus, getUsers } from '../../services/v3'
import { campaignStatuses } from '../../constants'

const ProposalStatus = ({ match, setIsLoading, openModalCcb, updateCampaignStatus }) => {
  const [pendingSubscriptions, setPendingSubscriptions] = useState(true)
  const [subscribers, setSubscribers] = useState([])
  const [isOpenModalChangeStatus, setIsOpenModalChangeStatus] = useState(false)
  const [status, setStatus] = useState(FETCHING)

  useEffect(_ => {
    const fetchSubscribers = async () => {
      try {
        setStatus(FETCHING)
        const [{ data: campaignResponse }, { data: eletronicSignatures }] = await Promise.all([
          getCampaignResponse(match.params.id),
          getEletronicSignatures({ campaign_id: match.params.id })
        ])
        if (!campaignResponse) throw new Error('Campanha não possui simulação na BMP')
        const subscribersInfo = await Promise.all(
          campaignResponse.signers.map(async subscriber => {
            const {
              data: [user]
            } = await getUsers({ document_number: subscriber.Documento })

            return {
              ...subscriber,
              has_signed:
                user && user.signer && eletronicSignatures.some(({ signer_id }) => signer_id === user.signer.id)
            }
          })
        )

        setPendingSubscriptions(!subscribersInfo.every(({ has_signed }) => has_signed))
        setSubscribers(subscribersInfo)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setStatus(ERROR)
        }
        setPendingSubscriptions(true)
        console.error(error)
      } finally {
        setStatus(IDLE)
      }
    }
    fetchSubscribers()
  }, [])

  const confirmStatusChange = async () => {
    try {
      setIsLoading(true)
      setIsOpenModalChangeStatus(false)
      await changeCampaignProposalsStatus(match.params.id)
      toast.success('Status das propostas alteradas.')
      openModalCcb()
      updateCampaignStatus(campaignStatuses.CCBS)
    } catch (error) {
      console.error(error)
      toast.error('Não foi possível alterar o status das propostas no momento.')
    } finally {
      setIsLoading(false)
    }
  }

  if (status === FETCHING) {
    return (
      <Container>
        <LoadingComponent />
      </Container>
    )
  }

  return (
    <Container>
      <SignatureResume campaignId={match.params.id} />
      {(() => {
        if (status === ERROR) {
          return (
            <>
              <Title>Ainda não foi enviada a proposta, por favor realize o passo 3</Title>
              <StyledCloseIcon fontSize='large' />
            </>
          )
        }
        if (pendingSubscriptions) {
          return (
            <>
              <Title>Ainda existem assinaturas pendentes para esta campanha</Title>
              <StyledCloseIcon fontSize='large' />
            </>
          )
        }
        return (
          <>
            <Title>Todas as propostas foram assinadas com sucesso</Title>
            <StyledCheckBox fontSize='large' />
          </>
        )
      })()}
      {status !== ERROR && (
        <ContentSubscribers>
          <Subtitle>Assinantes:</Subtitle>
          {subscribers.length > 0 &&
            subscribers.map(subscriber => (
              <ContentItem key={subscriber.Email}>
                <Text>{subscriber.Nome} - </Text>
                {subscriber.has_signed ? <StyledCheckBox fontSize='small' /> : <StyledCloseIcon fontSize='small' />}
              </ContentItem>
            ))}
        </ContentSubscribers>
      )}
      <MaterialButton
        onClick={_ => setIsOpenModalChangeStatus(true)}
        label='Mudar status'
        disabled={pendingSubscriptions}
      />
      {isOpenModalChangeStatus && (
        <ModalChangeStatus
          isOpen={isOpenModalChangeStatus}
          setIsOpen={setIsOpenModalChangeStatus}
          onConfirm={confirmStatusChange}
          onCancel={() => setIsOpenModalChangeStatus(false)}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StyledCheckBox = styled(CheckBoxIcon)`
  color: #32cd32;
`
const StyledCloseIcon = styled(CloseIcon)`
  color: #ff0000;
`
const Title = styled.p`
  font-size: 22px;
`
const Subtitle = styled.p`
  font-size: 18px;
  font-weight: 700;
`
const Text = styled.p``

const ContentSubscribers = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
`
const ContentItem = styled.div`
  display: flex;
  align-items: center;
`

export default ProposalStatus
