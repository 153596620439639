import * as yup from 'yup'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório'
  }
})

export const anticipationSchema = yup.object().shape({
  type: yup.string().required(),
  operation_tax: yup.string().required(),
  anticipation_date: yup
    .string()
    .nullable()
    .required()
    .test('valid_date', 'Data inválida', date => date !== 'Invalid date'),
  installment_id: yup.string().required()
})
