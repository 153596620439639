import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import EditIcon from '@material-ui/icons/Edit'

import { PopoverEditIndicatedBy, PopoverLeadIndicatedBy } from '../../components'

import { LeadContext } from '../../contexts'
import { abbreviateFullName } from '../../helpers'

const IndicatedByCell = ({ lead }) => {
  const [anchorEdit, setAnchorEdit] = useState(null)
  const [anchorInfo, setAnchorInfo] = useState(null)
  const { search } = useContext(LeadContext)
  const hasIndicatedBy = lead && lead.indicated_by

  const handleEdit = e => {
    setAnchorEdit(e.currentTarget)
  }

  const handleFilterIndicatedBy = () => {
    search({
      indicated_by: [lead.indicated_by]
    })
  }

  return (
    <IndicatedByContainer hasIndicatedBy={hasIndicatedBy}>
      {hasIndicatedBy ? (
        <>
          <IndicatedByName
            onClick={handleFilterIndicatedBy}
            onMouseEnter={event => {
              setAnchorInfo(event.currentTarget)
            }}
            onMouseLeave={() => {
              setAnchorInfo(null)
            }}
          >
            {abbreviateFullName(lead.indicated_by, 15)}
          </IndicatedByName>
          <StyledEditIcon style={{ fontSize: 16 }} onClick={handleEdit} />
        </>
      ) : (
        <StyledAddCircleOutlineOutlinedIcon onClick={handleEdit} />
      )}
      {anchorEdit && <PopoverEditIndicatedBy lead={lead} anchor={anchorEdit} setAnchor={setAnchorEdit} />}
      {lead.partner_id && anchorInfo && (
        <PopoverLeadIndicatedBy anchor={anchorInfo} setAnchor={setAnchorInfo} lead={lead} />
      )}
    </IndicatedByContainer>
  )
}

const IndicatedByName = styled.span`
  max-width: 100px;
  cursor: pointer;
`

const StyledAddCircleOutlineOutlinedIcon = styled(AddCircleOutlineOutlinedIcon)`
  color: #076f9f;
  cursor: pointer;
`

const StyledEditIcon = styled(EditIcon)`
  color: #076f9f;
  cursor: pointer;
`

const IndicatedByContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ hasIndicatedBy }) => (hasIndicatedBy ? 'space-between' : 'center')};
  align-items: center;
`

export default IndicatedByCell
