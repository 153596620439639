import React from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import { styles } from './styles'

const Theme = ({ children }) => {
  const customTheme = createMuiTheme({
    ...styles
  })

  return (
    <MuiThemeProvider theme={customTheme}>
      <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}

Theme.propTypes = {
  theme: PropTypes.object
}
export default Theme
