import styled from '@emotion/styled'

export const Title = styled.p`
  font-size: 1.3em;
  margin-top: 2em;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

export const DeleteButton = styled.button`
  background: #ff3f3f;
  border: 0;
  color: #fff;
  padding: 0.8em 0.6em;
  font-size: 1.3em;
  margin: 1em;
  border-radius: 0.5em;
  cursor: pointer;
  &:hover {
    background: #b73333;
    transition: all 0.3s;
  }

  &:focus {
    background: #b73333 !important;
    outline: 0;
  }
`
