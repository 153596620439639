import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    max-width: 500px;
  }

  form {
    margin-bottom: 20px;
  }
`

export const Controls = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 93px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 15px;
`
export const CropContainer = styled.div``
