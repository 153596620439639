import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import { DateRange } from 'react-date-range'
import { pt } from 'react-date-range/dist/locale'
import { format, parse } from 'date-fns'

import { MaterialButton } from '../../components'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const PopoverCalendar = ({ anchor, setAnchor, search, startValues }) => {
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    if (anchor) {
      setSelectedDates([
        {
          startDate: startValues.start_date ? parse(startValues.start_date, 'yyyy-MM-dd', new Date()) : new Date(),
          endDate: startValues.end_date ? parse(startValues.end_date, 'yyyy-MM-dd', new Date()) : new Date(),
          key: 'selection'
        }
      ])
    }
  }, [anchor])

  const onSubmit = () => {
    const [{ startDate, endDate }] = selectedDates
    search({
      start_date: format(startDate, 'yyyy-MM-dd'),
      end_date: format(endDate, 'yyyy-MM-dd')
    })
    setAnchor(null)
  }

  const onClean = () => {
    setSelectedDates([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
    search({
      start_date: null,
      end_date: null
    })
    setAnchor(null)
  }

  return (
    <Popover
      id='calendar-popover'
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Container>
        <DateRange
          editableDateInputs={false}
          onChange={item => setSelectedDates([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={selectedDates}
          locale={pt}
          weekStartsOn={0}
        />
        <ButtonsContainer>
          <MaterialButton
            label='Limpar'
            margin='10px'
            padding='0.5em 1em'
            width='50%'
            color='#076f9f'
            backgroundColor='#ffffff'
            variant='outlined'
            onClick={onClean}
          />
          <MaterialButton
            label='Buscar'
            margin='10px'
            padding='0.5em 1em'
            width='50%'
            color='#ffffff'
            backgroundColor='#076f9f'
            variant='outlined'
            onClick={onSubmit}
          />
        </ButtonsContainer>
      </Container>
    </Popover>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 300px;
`

export default PopoverCalendar
