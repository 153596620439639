import React from 'react'
import { format, parseISO } from 'date-fns'

import { Grid, Row } from './style'

import { formatCurrency } from '../../../helpers'

const ProposalTimeline = React.forwardRef((props, ref) => {
  const data = props.data

  return (
    <Grid show={props.show} ref={ref}>
      <Row>
        <h4 style={{ justifyContent: 'center', backgroundColor: '#076F9F', color: 'white', width: '450px' }}>
          PROPOSTA DE CRÉDITO
        </h4>
        <h4 style={{ justifyContent: 'center', backgroundColor: '#3dc6ef', color: 'white', width: '190px' }}>
          {format(parseISO(data.created_at), 'dd/MM/yyyy')}
        </h4>
      </Row>

      <Row>
        <label style={{ width: '154px' }}>Razão social</label>
        <label style={{ width: '442px', display: 'flex', justifyContent: 'center' }}>{data.cnpj_name}</label>
      </Row>

      <Row>
        <label style={{ width: '315px' }}>Valor financiamento</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
          {formatCurrency(data.financing_value)}
        </label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>1º vencimento</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{data.first_due}</label>
      </Row>

      <Row>
        <label style={{ width: '314px' }}>Parcelas mensais</label>
        <h4 style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{data.installments_number}</h4>
        <h4 style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
          {formatCurrency(data.installment_value)}
        </h4>
        <div style={{ width: '306px' }} />
      </Row>

      <Row>
        <label style={{ width: '315px' }}>Taxa (a.m.)</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{`${data.rate_month}%`}</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Taxa (a.a)</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{`${data.rate_annual}%`}</label>
      </Row>

      <Row>
        <label style={{ width: '315px' }}>Garantia</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}> {data.warranty}</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Tipo Garantia</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
          {data.warranty_type.abbreviation}
        </label>
      </Row>

      <Row>
        <label style={{ width: '315px' }}>Percentual</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{`${
          data.warranty_percentage
        }%`}</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Valor</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
          {formatCurrency(data.warranty_value)}
        </label>
      </Row>

      <Row>
        <label style={{ width: '315px' }}>Comissão Kavod</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{`${
          data.kavod_percentage
        }%`}</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
          {formatCurrency(data.commission_amount)}
        </label>
        <div style={{ width: '307px' }} />
      </Row>

      <Row>
        <label style={{ width: '310px' }}>IOF</label>
        <label style={{ width: '296px', display: 'flex', justifyContent: 'center' }}>{formatCurrency(data.iof)}</label>
        <div style={{ width: '603px' }} />
      </Row>

      <Row>
        <label style={{ width: '310px' }}>Valor líquido</label>
        <h4 style={{ width: '296px', display: 'flex', justifyContent: 'center' }}>
          {formatCurrency(data.liquid_amount)}
        </h4>
        <div style={{ width: '603px' }} />
      </Row>

      <Row>
        <label style={{ width: '315px' }}>CET (a.m.)</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{`${data.cet_month}%`}</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>CET (a.a)</label>
        <label style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>{`${data.cet_annual}%`}</label>
      </Row>
    </Grid>
  )
})

export default ProposalTimeline
