import React from 'react'

import { ContainerTotal, ContainerFiltered } from './style'

import { formatValueToBrl } from '../../../../helpers'

const CardValue = ({ value, type = 'total' }) => {
  if (type === 'total') {
    return (
      <ContainerTotal>
        <p>Total Solicitado</p>
        <h4>{formatValueToBrl(value)}</h4>
      </ContainerTotal>
    )
  } else {
    return (
      <ContainerFiltered>
        <p>Total filtrado</p>
        <h4>{formatValueToBrl(value)}</h4>
      </ContainerFiltered>
    )
  }
}

export default CardValue
