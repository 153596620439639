import React from 'react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import {
  Main,
  DateContainer,
  Content,
  UpdatedMessage,
  TimelineConnectorContainer,
  StyledTimelineConnector
} from './styles'

const Timeline = ({ investmentsUpdatesData }) => {
  return (
    <>
      {investmentsUpdatesData.map((message, index) => {
        return (
          <Main key={index}>
            <DateContainer>
              <label>
                {format(
                  new Date(
                    message.date
                      .split('/')
                      .reverse()
                      .join('-')
                  ),
                  "dd 'de' LLLL 'de' yyyy",
                  {
                    locale: ptBR
                  }
                )}
              </label>
            </DateContainer>
            <Content>
              <TimelineConnectorContainer>
                <StyledTimelineConnector>
                  <img src='/assets/images/update.svg' alt='update' />
                </StyledTimelineConnector>
              </TimelineConnectorContainer>
              <div style={{ padding: '40px 0' }}>
                <UpdatedMessage>
                  <label>
                    <strong>{message.title}</strong>
                    {message.hour}
                  </label>
                  <p>{message.description}</p>
                </UpdatedMessage>
              </div>
            </Content>
          </Main>
        )
      })}
    </>
  )
}

export default Timeline
