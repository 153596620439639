import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Controller } from 'react-hook-form'

import { Upload } from '../icons/custom'
import { Button, Row } from '../index'
import {
  StyledRow,
  StyledButton,
  AcceptFile,
  AttachFileIcon,
  ErrorFile,
  ErrorMessage,
  FileWithError,
  Container
} from './styles'

const UploadButton = ({ onDelete, onDrop, defaultValue, error, name, control, ...props }) => {
  const [archive, setArchive] = useState(false)
  const [haveAnError, setHaveAnError] = useState(false)

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    noDrag: true,
    maxFiles: 1,
    maxSize: 5242880,
    onDrop: file => {
      setArchive(true)
      onDrop(file)
    },
    onDropRejected: () => {
      setHaveAnError(true)
    }
  })

  const handleButtonClick = () => {
    setArchive(false)
    setHaveAnError(false)
    onDelete()
  }

  const acceptedFileItems = acceptedFiles.map(file => {
    return (
      <StyledRow key={file.path}>
        <Row>
          <AttachFileIcon fontSize='small' />
          <AcceptFile key={file.path}> {file.path}</AcceptFile>
        </Row>
        <div>
          <StyledButton onClick={() => window.open(URL.createObjectURL(file))} variant='text'>
            Visualizar
          </StyledButton>
          <StyledButton onClick={handleButtonClick} variant='text'>
            Excluir
          </StyledButton>
        </div>
      </StyledRow>
    )
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <ErrorFile key={file.path}>
        <div>
          <Row>
            <AttachFileIcon fontSize='small' />
            <FileWithError>{file.path}</FileWithError>
          </Row>
          <div>
            {errors.map(e => {
              if (e.code === 'file-too-large') {
                return <ErrorMessage key={e.code}>O tamanho máximo do arquivo é de 5MB.</ErrorMessage>
              } else {
                return <ErrorMessage key={e.code}>Erro na tentativa de upload. Tente novamente</ErrorMessage>
              }
            })}
          </div>
        </div>
        <StyledButton onClick={handleButtonClick} variant='text' buttoncolor='#D93025'>
          Excluir
        </StyledButton>
      </ErrorFile>
    )
  })

  return (
    <div>
      <Controller
        as={
          <Container {...getRootProps()}>
            <input {...getInputProps()} />
            <Button icon={<Upload />} variant='text' style={{ display: archive ? 'none' : 'inherit' }}>
              Anexar arquivo
            </Button>
          </Container>
        }
        defaultValue={defaultValue}
        error={error}
        name={name}
        control={control}
        {...props}
      />
      {!haveAnError && archive && <div>{acceptedFileItems}</div>}
      {haveAnError && archive && <div>{fileRejectionItems}</div>}
    </div>
  )
}

UploadButton.defaultProps = {
  defaultValue: ''
}

UploadButton.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onDrop: PropTypes.func
}

export default UploadButton
