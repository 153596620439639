import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Column, Row, Text, Button, DropzoneHome, IconButton } from '../../components/v2Components'
import { CardFQS, ModalEditOrder, PhotoBody, ModalFQS, ModalDeleteFQS } from './components'
import LoadingComponent from '../../components/LoadingComponent'
import { IconEdit, IconDelete } from '../../components/v2Components/icons'
import { TabMenu } from '../NewUserInvestments/components'

import { ContainerGallery, DivImg, Img, DivImgBody } from './style'

import { getFQS, createImagesHome, deleteImagesHome, getImagesHome } from '../../services/v3'
import { HomeSchema } from '../../schemas/Employees'

const HomeEdit = () => {
  const { control, setValue } = useForm({
    mode: 'onBlur',
    resolver: HomeSchema
  })

  const [isLoading, setIsLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [headerImages, setHeaderImages] = useState([])
  const [bodyImages, setBodyImages] = useState([])
  const [modalEdit, setModalEdit] = useState(undefined)
  const [tabMenu, setTabMenu] = useState(0)
  const [modalAddFQS, setModalAddFQS] = useState(false)
  const [modalDeleteFQS, setModalDeleteFQS] = useState(false)

  const menu = useMemo(
    () => [
      {
        name: 'Perguntas frequentes',
        handleClick() {
          setTabMenu(0)
        }
      },
      {
        name: 'Imagens do topo',
        handleClick() {
          setTabMenu(1)
        }
      },
      {
        name: 'Imagens do corpo',
        handleClick() {
          setTabMenu(2)
        }
      }
    ],
    []
  )

  const fetchInfos = useCallback(async () => {
    try {
      const [{ data: response }, { data: responseImages }] = await Promise.all([getFQS(), getImagesHome()])

      if (!!response && response.length > 0) {
        setQuestions(response)
      }

      if (!!responseImages && responseImages.length > 0) {
        setHeaderImages(
          responseImages
            .filter(item => item.order !== 0)
            .sort((a, b) => {
              return a.order - b.order
            })
        )
        setBodyImages(responseImages.filter(item => item.order === 0))
      }

      setIsLoading(false)
    } catch (error) {}
  }, [])

  const handleDropDocuments = useCallback(
    async files => {
      if (!files.length) return

      try {
        await Promise.all(
          files.map(async (file, index) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('order', headerImages.length + index + 1)
            return await createImagesHome(formData)
          })
        )

        toast.success('Foto(s) adicionada(s) com sucesso')
        fetchInfos()
      } catch (error) {
        toast.error('Ocorreu um erro ao salvar as fotos')
      }
      setValue('file_document', files)
    },
    [headerImages]
  )

  const handleDeleteDocuments = useCallback(() => {
    setValue('file_document', '')
  }, [])

  const deletePhoto = useCallback(
    async photo => {
      try {
        await deleteImagesHome(photo.id)
        toast.success('Foto deletada com sucesso')
        fetchInfos()
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao tentar deletar a foto')
      }
    },
    [fetchInfos]
  )

  useEffect(() => {
    fetchInfos()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLoading])

  if (isLoading) return <LoadingComponent />

  return (
    <>
      <Column pt={10} mx={3}>
        <Row justifyContent='space-between'>
          <Column>
            <Text variant='h3' color='primary' mt={3}>
              Home
            </Text>
          </Column>
        </Row>

        <ModalFQS open={modalAddFQS} onClose={() => setModalAddFQS(false)} />
        <ModalDeleteFQS open={modalDeleteFQS} handleClose={() => setModalDeleteFQS(false)} />

        <TabMenu options={menu} />

        {tabMenu === 0 && (
          <>
            <Row justifyContent='space-between' alignItems='flex-end' mb='10px' flexWrap='wrap'>
              <Text variant='h3' color='primary' mt={3}>
                Perguntas frequentes
              </Text>
              <Button
                height={40}
                onClick={() => {
                  setModalAddFQS(true)
                }}
                style={{ width: '100%', maxWidth: '200px' }}
              >
                Adicionar pergunta
              </Button>
            </Row>

            <Column>
              <CardFQS
                questions={questions}
                setModalAddFQS={setModalAddFQS}
                setModalDeleteFQS={setModalDeleteFQS}
                fetchInfos={fetchInfos}
              />
            </Column>
          </>
        )}
      </Column>

      {tabMenu === 1 && (
        <Column mx={3}>
          <Row justifyContent='space-between'>
            <Column>
              <Text variant='h3' color='primary' mt={3}>
                Imagens do topo
              </Text>
              <Text variant='h4'>Imagem ideal (1280 x 662)px</Text>
            </Column>
          </Row>
          <DropzoneHome
            name='photo'
            control={control}
            onDrop={handleDropDocuments}
            onDelete={handleDeleteDocuments}
            multiple={true}
          />
          <ContainerGallery>
            {headerImages.map((value, index) => (
              <DivImg key={index}>
                <p>{value.order}</p>
                <Img src={value.photo_url} />
                <Row style={{ justifyContent: 'flex-end' }}>
                  <IconButton icon={<IconEdit />} variant='text' onClick={() => setModalEdit(value)} />
                  <IconButton icon={<IconDelete />} variant='text' onClick={() => deletePhoto(value)} />
                </Row>
              </DivImg>
            ))}
            <ModalEditOrder open={modalEdit} onClose={() => setModalEdit(undefined)} img={headerImages} />
          </ContainerGallery>
        </Column>
      )}

      {tabMenu === 2 && (
        <Column mx={3}>
          <Text variant='h3' color='primary' mt={3}>
            Imagens do corpo
          </Text>
          <DivImgBody>
            <Column width='100%'>
              <Text variant='h4' mt={2}>
                Imagem empréstimo para empresas
              </Text>
              <PhotoBody image={bodyImages[0]} fetchInfos={fetchInfos} />
            </Column>

            <Column width='100%'>
              <Text variant='h4' mt={2}>
                Imagem investimentos rentáveis para o seu patrimônio
              </Text>
              <PhotoBody image={bodyImages[1]} fetchInfos={fetchInfos} />
            </Column>
          </DivImgBody>
        </Column>
      )}
    </>
  )
}

export default HomeEdit
