import React, { useEffect, useState } from 'react'

import { ToolTipAlert } from './style'
import { IconErrorOutline } from '../icons'

const InactivityAlert = ({ lead }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (lead.lead_updates.length > 0) {
      var date1 = new Date(lead.lead_updates[0].updated_at)
      var date2 = new Date()
      var Difference_In_Time = date2.getTime() - date1.getTime()
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

      if (Difference_In_Days > 20) {
        setShow(true)
      } else {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }, [lead])

  return (
    lead.lead_status_id <= 8 &&
    show && (
      <ToolTipAlert
        arrow={true}
        title='Não há atualizações há mais de 20 dias.'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div>
          <IconErrorOutline style={{ fill: '#ff8300', width: '25px', height: '25px' }} />
        </div>
      </ToolTipAlert>
    )
  )
}

export default InactivityAlert
