import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`

export const CampaignName = styled.h2`
  margin-bottom: 30px;
  text-align: center;
`
export const List = styled.ul`
  padding-left: 18px;

  li {
    color: red;
  }
`
