import { getComplianceAgreementTypes, getInvestorComplianceAgreements } from '../services/v3'

export const checkIsCompletedPersonalData = investor => {
  return Boolean(
    investor &&
      investor.first_name &&
      investor.last_name &&
      investor.document_number &&
      investor.email &&
      investor.phone &&
      investor.birth_date &&
      investor.nationality &&
      investor.naturality &&
      investor.gender_id &&
      investor.marital_type_id &&
      investor.mother_name &&
      investor.father_name &&
      investor.rg &&
      investor.rg_agent
  )
}

export const checkIsCompletedAddress = investor => {
  return Boolean(
    investor &&
      investor.address_id &&
      investor.address.zip_code &&
      investor.address.type &&
      investor.address.street &&
      investor.address.number &&
      investor.address.district &&
      investor.address.city
  )
}

export const checkIsCompletedBank = investor => {
  return Boolean(
    investor &&
      investor.bank_id &&
      investor.bank.type &&
      investor.bank.code &&
      investor.bank.agency &&
      investor.bank.account &&
      investor.bank.account_digit &&
      investor.monthly_earn &&
      investor.property_value
  )
}

export const checkIsCompletedDocuments = async investor => {
  if (!investor || investor.documents.length === 0) return false

  const { data: allComplianceAgreementTypes } = await getComplianceAgreementTypes()
  const { data: investorComplianceAgreements } = await getInvestorComplianceAgreements(investor.id)

  const complianceTypes = allComplianceAgreementTypes.filter(({ abbreviation }) => ['I', 'P'].includes(abbreviation))

  const hasSignedTerms = complianceTypes.every(complianceType =>
    investorComplianceAgreements.some(
      investorCompliance => investorCompliance.compliance_agreement_type_id === complianceType.id
    )
  )

  const hasIdentificationDoc = investor.documents.some(
    ({ document_type, s3_name }) => ['C', 'R', 'O', 'RN'].includes(document_type.abbreviation) && s3_name
  )

  const hasResidenceDoc = investor.documents.some(
    ({ document_type, s3_name }) => document_type.abbreviation === 'CR' && s3_name
  )

  return Boolean(hasSignedTerms && hasIdentificationDoc && hasResidenceDoc)
}

export const checkIsCompletedInvestor = async investor => {
  return (
    checkIsCompletedPersonalData(investor) &&
    checkIsCompletedBank(investor) &&
    checkIsCompletedAddress(investor) &&
    (await checkIsCompletedDocuments(investor))
  )
}
