import React from 'react'
import styled from '@emotion/styled'

const FilterByName = ({ onChangeName, defaultValue }) => {
  return (
    <Wrapper>
      <Label>Filtrar pelo Nome</Label>
      <FilterInput
        type='text'
        name='name'
        placeholder='Digite no mínimo 3 caracteres'
        onChange={e => {
          if (e.target.value.length > 2) onChangeName(e.target.value)
          else onChangeName('')
        }}
        defaultValue={defaultValue || ''}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  @media (max-width: 1040px) {
    width: 100%;
  }
`

const Label = styled.p`
  margin-right: 15px;
  color: #606060;
`

const FilterInput = styled.input`
  height: 30px;
  padding: 0.5em;
  width: 300px;
  border-radius: 4px;
  font-size: 13px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGray};
  transition: all 0.3s;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.blueKavod};
    outline: 0;
  }
`

export default FilterByName
