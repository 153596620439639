import styled from 'styled-components'
import { Dialog as MaterialModal } from '@material-ui/core'

export const Modal = styled(MaterialModal)`
  div {
    &.MuiPaper-elevation24 {
      box-shadow: none;
      border-radius: 8px;
      width: 100%;
      max-width: 1200px;
      padding: 24px;
      overflow: initial;
    }
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
