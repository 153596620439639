/* eslint-disable indent */
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { CheckCircle, List } from '@material-ui/icons'

import { deletePledge, getMyInvestments, getMyInvestmentsCSV, getInvestors, getExtract } from '../../services/v3'

import { formatCurrency, formatTax, testFilter } from '../../helpers'

import { Radio, Checkbox, Button } from '../../components/v2Components'
import { DelayedFlag, ExecutionFlag } from './Show/components'

import {
  Body,
  Title,
  TabMenu,
  TableBase,
  Drawer,
  Option,
  Badge,
  ProgressBar,
  Loader,
  CardBase,
  CardProcessing,
  DeleteCampaignIcon,
  StatusModal,
  CardList,
  Modal,
  DeleteModal,
  ModalProcessing,
  ModalShowProcessing,
  Extract
} from './components'

import { Form, DrawerTitle, Detail, OptionClose, CCBDownload, ContainerTextProcessing, InfoIconTab } from './styles'

const NewUserInvestments = props => {
  const [filterValue, setFilterValue] = useState({
    all: true,
    defaulter: false,
    late: false,
    defaulting: false,
    concluded: false
  })

  const { handleSubmit, control } = useForm({ defaultValues: filterValue })

  const [investmentsData, setInvestmentsData] = useState([])
  const [investmentsConsolidated, setInvestmentsConsolidated] = useState([])
  const [investmentsProcessing, setInvestmentsProcessing] = useState([])
  const [investor, setInvestor] = useState()

  const [extract, setExtract] = useState([])

  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [modalDelete, setModalDelete] = useState(false)
  const [modalStatusTitle, setStatusModalTitle] = useState('')
  const [modalInfoProcessing, setModalInfoProcessing] = useState(false)
  const [modalShowProcessing, setModalShowProcessing] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)

  const [tabMenu, setTabMenu] = useState(0)
  const [statusMenu, setStatusMenu] = useState(0)

  const [isLoading, setIsLoading] = useState(true)
  const [pageSelected, setPageSelected] = useState(0)

  const [rowLength, setRowLength] = useState(8)
  const [orderBy, setOrderBy] = useState('current')

  const menu = useMemo(
    () => [
      {
        name: investmentsConsolidated.all ? `Em andamento (${investmentsConsolidated.all.length})` : '',
        handleClick() {
          setTabMenu(0)
        }
      },
      {
        name: investmentsProcessing ? `Processando (${investmentsProcessing.length})` : '',
        handleClick() {
          setTabMenu(1)
        }
      },
      {
        name: extract.entries ? `Lançamentos (${extract.entries.length})` : '',
        handleClick() {
          setTabMenu(2)
        }
      }
    ],
    [investmentsConsolidated, investmentsProcessing, extract]
  )

  const subMenu = useMemo(
    () =>
      testFilter(filterValue)
        ? [
            {
              name: investmentsData ? `Todos (${investmentsConsolidated.all.length})` : '',
              handleClick() {
                setStatusMenu(0)
              }
            },
            {
              name: investmentsConsolidated.defaulted ? `Adimplente (${investmentsConsolidated.defaulted.length})` : '',
              handleClick() {
                setStatusMenu(1)
              }
            },
            {
              name: investmentsConsolidated.delayed ? `Atrasado (${investmentsConsolidated.delayed.length})` : '',
              handleClick() {
                setStatusMenu(2)
              }
            },
            {
              name: investmentsConsolidated.notDefaulted
                ? `Inadimplente (${investmentsConsolidated.notDefaulted.length})`
                : '',
              handleClick() {
                setStatusMenu(3)
              }
            },
            {
              name: investmentsConsolidated.completed ? `Concluído (${investmentsConsolidated.completed.length})` : '',
              handleClick() {
                setStatusMenu(4)
              }
            },
            {
              name: investmentsConsolidated.completed ? `Filtrado (${investmentsData.length})` : '',
              handleClick() {
                setStatusMenu(5)
              }
            }
          ]
        : [
            {
              name: investmentsData ? `Todos (${investmentsData.length})` : '',
              handleClick() {
                setStatusMenu(0)
              }
            },
            {
              name: investmentsConsolidated.defaulted ? `Adimplente (${investmentsConsolidated.defaulted.length})` : '',
              handleClick() {
                setStatusMenu(1)
              }
            },
            {
              name: investmentsConsolidated.delayed ? `Atrasado (${investmentsConsolidated.delayed.length})` : '',
              handleClick() {
                setStatusMenu(2)
              }
            },
            {
              name: investmentsConsolidated.notDefaulted
                ? `Inadimplente (${investmentsConsolidated.notDefaulted.length})`
                : '',
              handleClick() {
                setStatusMenu(3)
              }
            },
            {
              name: investmentsConsolidated.completed ? `Concluído (${investmentsConsolidated.completed.length})` : '',
              handleClick() {
                setStatusMenu(4)
              }
            }
          ],
    [investmentsConsolidated, investmentsData, filterValue]
  )

  const investmentsColumns = useMemo(
    () => [
      {
        Header: 'Campanha',
        accessor: 'Campanha'
      },
      {
        Header: 'Status',
        accessor: 'Status'
      },
      {
        Header: 'Recebido / A receber',
        accessor: 'Recebido / A receber'
      },
      {
        Header: 'Taxa mensal / anual',
        accessor: 'Taxa mensal / anual'
      },
      {
        Header: 'TIR',
        accessor: 'TIR'
      },
      {
        Header: 'Parcela',
        accessor: 'Parcela'
      },
      {
        Header: 'Próxima parcela',
        accessor: 'Próxima parcela'
      },
      {
        Header: '',
        accessor: 'detalhes'
      },
      {
        Header: '',
        accessor: 'ccb'
      }
    ],
    []
  )

  const processingColumns = useMemo(
    () => [
      {
        Header: 'Campanha',
        accessor: 'Campanha'
      },
      {
        Header: (
          <div title='Status'>
            Status{' '}
            <InfoIconTab
              title='Info'
              onClick={() => {
                setModalInfoProcessing(true)
              }}
            />
          </div>
        ),
        accessor: 'Status'
      },
      {
        Header: 'Investimento',
        accessor: 'Investimento'
      },
      {
        Header: '',
        accessor: 'ted'
      },
      {
        Header: '',
        accessor: 'delete'
      }
    ],
    []
  )

  const processingData = useMemo(() => {
    try {
      const response = investmentsProcessing.map(investment => {
        if (investment.length > 1) {
          let amountSum = 0
          investment.forEach(pledge => (amountSum = pledge.amount + amountSum))

          return {
            Campanha: investment[0].company_name,
            Status: (
              <Badge
                title={investment[0].pledge_status}
                status={investment[0].pledge_status}
                color={
                  investment[0].pledge_status === 'Lista principal'
                    ? 'green'
                    : investment[0].pledge_status === 'Em espera'
                    ? 'yellow'
                    : investment[0].pledge_status === 'Cancelada'
                    ? 'red'
                    : null
                }
              />
            ),
            Investimento: formatCurrency(amountSum),
            ted:
              investment[0].pledge_status === 'Lista principal' ? (
                !!investment[0].ted_is_confirmed === true ? (
                  <div style={{ display: 'flex' }} title='TED/ Pix confirmada'>
                    <CheckCircle style={{ color: '#4ab34e', marginRight: '5px' }} />
                    TED/ Pix confirmada
                  </div>
                ) : (
                  'TED/ Pix pendente de confirmação'
                )
              ) : investment[0].pledge_status === 'Em espera' ? (
                investment[0].disbursement_done ? (
                  <ContainerTextProcessing title='Campanha finalizada. Clique na lata de lixo para excluír'>
                    <p>Campanha finalizada.</p>
                    <p>Clique na lata de lixo para excluír</p>
                  </ContainerTextProcessing>
                ) : (
                  'Você ainda não foi acionado'
                )
              ) : (
                <ContainerTextProcessing title='Pedido cancelado. Clique na lata de lixo para excluír'>
                  <p>Pedido cancelado.</p>
                  <p>Clique na lata de lixo para excluír</p>
                </ContainerTextProcessing>
              ),
            delete: (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }} title=''>
                <DeleteCampaignIcon
                  onClick={() => {
                    setModalDelete(investment[0])
                  }}
                  isDelete={
                    investment[0].pledge_status === 'Cancelada' ||
                    (investment[0].pledge_status === 'Em espera' && investment[0].disbursement_done === true)
                  }
                />
                <List
                  data-tip='Mostrar histórico'
                  fontSize='large'
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    setModalShowProcessing(
                      investment.map(item => {
                        return {
                          Campanha: item.company_name,
                          Status: (
                            <Badge
                              title={item.pledge_status}
                              status={item.pledge_status}
                              color={
                                item.pledge_status === 'Lista principal'
                                  ? 'green'
                                  : item.pledge_status === 'Em espera'
                                  ? 'yellow'
                                  : item.pledge_status === 'Cancelada'
                                  ? 'red'
                                  : null
                              }
                            />
                          ),
                          Investimento: formatCurrency(item.amount),
                          ted:
                            item.pledge_status === 'Lista principal' ? (
                              !!item.ted_is_confirmed === true ? (
                                <div style={{ display: 'flex' }} title='TED/ Pix confirmada'>
                                  <CheckCircle style={{ color: '#4ab34e', marginRight: '5px' }} />
                                  TED/ Pix confirmada
                                </div>
                              ) : (
                                'TED/ Pix pendente de confirmação'
                              )
                            ) : item.pledge_status === 'Em espera' ? (
                              item.disbursement_done ? (
                                <ContainerTextProcessing title='Campanha finalizada. Clique na lata de lixo para excluír'>
                                  <p>Campanha finalizada.</p>
                                  <p>Clique na lata de lixo para excluír</p>
                                </ContainerTextProcessing>
                              ) : (
                                'Você ainda não foi acionado'
                              )
                            ) : (
                              <ContainerTextProcessing title='Pedido cancelado. Clique na lata de lixo para excluír'>
                                <p>Pedido cancelado.</p>
                                <p>Clique na lata de lixo para excluír</p>
                              </ContainerTextProcessing>
                            ),
                          delete: (
                            <DeleteCampaignIcon
                              onClick={() => {
                                setModalDelete(item)
                              }}
                              isDelete={
                                item.pledge_status === 'Cancelada' ||
                                (item.pledge_status === 'Em espera' && item.disbursement_done === true)
                              }
                            />
                          )
                        }
                      })
                    )
                  }
                />
              </div>
            )
          }
        } else {
          return {
            Campanha: investment[0].company_name,
            Status: (
              <Badge
                title={investment[0].pledge_status}
                status={investment[0].pledge_status}
                color={
                  investment[0].pledge_status === 'Lista principal'
                    ? 'green'
                    : investment[0].pledge_status === 'Em espera'
                    ? 'yellow'
                    : investment[0].pledge_status === 'Cancelada'
                    ? 'red'
                    : null
                }
              />
            ),
            Investimento: formatCurrency(investment[0].amount),
            ted:
              investment[0].pledge_status === 'Lista principal' ? (
                !!investment[0].ted_is_confirmed === true ? (
                  <div style={{ display: 'flex' }} title='TED/ Pix confirmada'>
                    <CheckCircle style={{ color: '#4ab34e', marginRight: '5px' }} />
                    TED/ Pix confirmada
                  </div>
                ) : (
                  'TED/ Pix pendente de confirmação'
                )
              ) : investment[0].pledge_status === 'Em espera' ? (
                investment[0].disbursement_done ? (
                  <ContainerTextProcessing title='Campanha finalizada. Clique na lata de lixo para excluír'>
                    <p>Campanha finalizada.</p>
                    <p>Clique na lata de lixo para excluír</p>
                  </ContainerTextProcessing>
                ) : (
                  'Você ainda não foi acionado'
                )
              ) : (
                <ContainerTextProcessing title='Pedido cancelado. Clique na lata de lixo para excluír'>
                  <p>Pedido cancelado.</p>
                  <p>Clique na lata de lixo para excluír</p>
                </ContainerTextProcessing>
              ),
            delete: (
              <DeleteCampaignIcon
                onClick={() => {
                  setModalDelete(investment[0])
                }}
                isDelete={
                  investment[0].pledge_status === 'Cancelada' ||
                  (investment[0].pledge_status === 'Em espera' && investment[0].disbursement_done === true)
                }
              />
            )
          }
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsProcessing])

  const investmentsAll = useMemo(() => {
    try {
      const response = investmentsConsolidated.all.map(item => {
        return {
          Campanha: item.campaign_name,
          Status: (
            <div style={{ display: 'flex' }} title={item.campaign_status}>
              <Badge
                title={item.campaign_status}
                status={item.campaign_status}
                color={
                  item.campaign_status === 'Adimplente'
                    ? 'blue'
                    : item.campaign_status === 'Concluído'
                    ? 'green'
                    : item.campaign_status === 'Atrasado'
                    ? 'yellow'
                    : item.campaign_status === 'Inadimplente'
                    ? 'red'
                    : null
                }
              />
              <span style={{ display: 'flex' }}>
                {!!item.is_renegotiated === true && <DelayedFlag />}
                {(item.campaign_state.id === 2 || item.campaign_state.id === 3) && (
                  <ExecutionFlag title={item.campaign_state.name} />
                )}
              </span>
            </div>
          ),
          'Recebido / A receber': (
            <ProgressBar
              title={`${formatCurrency(item.total_received_per_campaign)} - ${formatCurrency(
                item.remaining_to_receive_per_campaign
              )}`}
              initialValue={item.total_received_per_campaign}
              finalValue={item.remaining_to_receive_per_campaign}
              thinbar={true}
              value={
                (item.total_received_per_campaign * 100) /
                (item.remaining_to_receive_per_campaign + item.total_received_per_campaign)
              }
            />
          ),
          'Taxa mensal / anual': `${formatTax(item.rate_month)} / ${formatTax(item.rate_anual)}`,
          TIR: formatTax(item.internal_rate_return),
          Parcela: `${item.next_installment_number} / ${item.total_installments}`,
          'Próxima parcela':
            formatCurrency(item.next_installment_value) +
            (!!item.next_installment_date ? ` - ${item.next_installment_date}` : ''),
          detalhes: (
            <Link to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}>
              <Detail title='Detalhes'> Detalhes </Detail>
            </Link>
          ),
          ccb: (
            <CCBDownload show={!!item.ccb_url} href={!!item.ccb_url ? item.ccb_url : ''}>
              <a
                download
                href={!!item.ccb_url ? item.ccb_url : ''}
                target='_blank'
                rel='noopener noreferrer'
                title='Download CCB'
              >
                CCB
                <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
              </a>
            </CCBDownload>
          )
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsConsolidated])

  const investmentsOverview = useMemo(() => {
    try {
      const overview = investmentsConsolidated.overview

      return [
        { name: 'Total investido', value: formatCurrency(overview.totalInvested), main: true },
        { name: 'Total recebido', value: formatCurrency(overview.totalReceived) },
        { name: 'Total a receber', value: formatCurrency(overview.remainingToReceive) },
        {
          name: 'Taxa de Inadimplência',
          value: overview.default_rate !== '' ? formatTax(overview.default_rate) : formatTax(0),
          information: true,
          onClick: () => {
            setModalTitle('Taxa de Inadimplência')
            setModalMessage(
              'A Taxa de Inadimplência é o percentual do valor total emprestado que está com atraso maior que 60 dias ou operação em execução judicial da dívida.'
            )
          }
        },
        {
          name: 'TIR',
          value: `${!!overview.internal_rate_return ? formatTax(overview.internal_rate_return) : '-'}`,
          information: true,
          onClick: () => {
            setModalTitle('TIR')
            setModalMessage(
              'A Taxa Interna de Retorno (TIR) é o retorno sobre o valor investido considerando o pagamento mensal de uma parcela composta por principal e juros.'
            )
          }
        }
      ]
    } catch (err) {
      return undefined
    }
  }, [investmentsConsolidated])

  const investmentsDefaulted = useMemo(() => {
    try {
      const response = investmentsConsolidated.defaulted.map(item => {
        return {
          Campanha: item.campaign_name,

          Status: (
            <div style={{ display: 'flex' }} title={item.campaign_status}>
              <Badge
                title={item.campaign_status}
                status={item.campaign_status}
                color={
                  item.campaign_status === 'Adimplente'
                    ? 'blue'
                    : item.campaign_status === 'Concluído'
                    ? 'green'
                    : item.campaign_status === 'Atrasado'
                    ? 'yellow'
                    : item.campaign_status === 'Inadimplente'
                    ? 'red'
                    : null
                }
              />
              <span style={{ display: 'flex' }}>
                {!!item.is_renegotiated === true && <DelayedFlag />}
                {(item.campaign_state.id === 2 || item.campaign_state.id === 3) && (
                  <ExecutionFlag title={item.campaign_state.name} />
                )}
              </span>
            </div>
          ),
          'Recebido / A receber': (
            <ProgressBar
              title={`${formatCurrency(item.total_received_per_campaign)} - ${formatCurrency(
                item.remaining_to_receive_per_campaign
              )}`}
              initialValue={item.total_received_per_campaign}
              finalValue={item.remaining_to_receive_per_campaign}
              thinbar={true}
              value={
                (item.total_received_per_campaign * 100) /
                (item.remaining_to_receive_per_campaign + item.total_received_per_campaign)
              }
            />
          ),
          'Taxa mensal / anual': `${formatTax(item.rate_month)} / ${formatTax(item.rate_anual)}`,
          TIR: formatTax(item.internal_rate_return),
          Parcela: `${item.next_installment_number} / ${item.total_installments}`,
          'Próxima parcela':
            formatCurrency(item.next_installment_value) +
            (!!item.next_installment_date ? ` - ${item.next_installment_date}` : ''),
          detalhes: (
            <Link to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}>
              <Detail title='Detalhes'> Detalhes </Detail>
            </Link>
          ),
          ccb: (
            <CCBDownload show={!!item.ccb_url} href={!!item.ccb_url ? item.ccb_url : ''}>
              <a
                download
                href={!!item.ccb_url ? item.ccb_url : ''}
                target='_blank'
                rel='noopener noreferrer'
                title='Download CCB'
              >
                CCB
                <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
              </a>
            </CCBDownload>
          )
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsConsolidated])

  const investmentsDelayed = useMemo(() => {
    try {
      const response = investmentsConsolidated.delayed.map(item => {
        return {
          Campanha: item.campaign_name,
          Status: (
            <div style={{ display: 'flex' }} title={item.campaign_status}>
              <Badge
                title={item.campaign_status}
                status={item.campaign_status}
                color={
                  item.campaign_status === 'Adimplente'
                    ? 'blue'
                    : item.campaign_status === 'Concluído'
                    ? 'green'
                    : item.campaign_status === 'Atrasado'
                    ? 'yellow'
                    : item.campaign_status === 'Inadimplente'
                    ? 'red'
                    : null
                }
              />
              <span style={{ display: 'flex' }}>
                {!!item.is_renegotiated === true && <DelayedFlag />}
                {(item.campaign_state.id === 2 || item.campaign_state.id === 3) && (
                  <ExecutionFlag title={item.campaign_state.name} />
                )}
              </span>
            </div>
          ),
          'Recebido / A receber': (
            <ProgressBar
              title={`${formatCurrency(item.total_received_per_campaign)} - ${formatCurrency(
                item.remaining_to_receive_per_campaign
              )}`}
              initialValue={item.total_received_per_campaign}
              finalValue={item.remaining_to_receive_per_campaign}
              thinbar={true}
              value={
                (item.total_received_per_campaign * 100) /
                (item.remaining_to_receive_per_campaign + item.total_received_per_campaign)
              }
            />
          ),
          'Taxa mensal / anual': `${formatTax(item.rate_month)} / ${formatTax(item.rate_anual)}`,
          TIR: formatTax(item.internal_rate_return),
          Parcela: `${item.next_installment_number} / ${item.total_installments}`,
          'Próxima parcela':
            formatCurrency(item.next_installment_value) +
            (!!item.next_installment_date ? ` - ${item.next_installment_date}` : ''),
          detalhes: (
            <Link to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}>
              <Detail title='Detalhes'> Detalhes </Detail>
            </Link>
          ),
          ccb: (
            <CCBDownload show={!!item.ccb_url} href={!!item.ccb_url ? item.ccb_url : ''}>
              <a
                download
                href={!!item.ccb_url ? item.ccb_url : ''}
                target='_blank'
                rel='noopener noreferrer'
                title='Download CCB'
              >
                CCB
                <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
              </a>
            </CCBDownload>
          )
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsConsolidated])

  const investmentsNotDefaulted = useMemo(() => {
    try {
      const response = investmentsConsolidated.notDefaulted.map(item => {
        return {
          Campanha: item.campaign_name,
          Status: (
            <div style={{ display: 'flex' }} title={item.campaign_status}>
              <Badge
                title={item.campaign_status}
                status={item.campaign_status}
                color={
                  item.campaign_status === 'Adimplente'
                    ? 'blue'
                    : item.campaign_status === 'Concluído'
                    ? 'green'
                    : item.campaign_status === 'Atrasado'
                    ? 'yellow'
                    : item.campaign_status === 'Inadimplente'
                    ? 'red'
                    : null
                }
              />
              <span style={{ display: 'flex' }}>
                {!!item.is_renegotiated === true && <DelayedFlag />}
                {(item.campaign_state.id === 2 || item.campaign_state.id === 3) && (
                  <ExecutionFlag title={item.campaign_state.name} />
                )}
              </span>
            </div>
          ),
          'Recebido / A receber': (
            <ProgressBar
              title={`${formatCurrency(item.total_received_per_campaign)} - ${formatCurrency(
                item.remaining_to_receive_per_campaign
              )}`}
              initialValue={item.total_received_per_campaign}
              finalValue={item.remaining_to_receive_per_campaign}
              thinbar={true}
              value={
                (item.total_received_per_campaign * 100) /
                (item.remaining_to_receive_per_campaign + item.total_received_per_campaign)
              }
            />
          ),
          'Taxa mensal / anual': `${formatTax(item.rate_month)} / ${formatTax(item.rate_anual)}`,
          TIR: formatTax(item.internal_rate_return),
          Parcela: `${item.next_installment_number} / ${item.total_installments}`,
          'Próxima parcela':
            formatCurrency(item.next_installment_value) +
            (!!item.next_installment_date ? ` - ${item.next_installment_date}` : ''),
          detalhes: (
            <Link to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}>
              <Detail title='Detalhes'> Detalhes </Detail>
            </Link>
          ),
          ccb: (
            <CCBDownload show={!!item.ccb_url} href={!!item.ccb_url ? item.ccb_url : ''}>
              <a
                download
                href={!!item.ccb_url ? item.ccb_url : ''}
                target='_blank'
                rel='noopener noreferrer'
                title='Download CCB'
              >
                CCB
                <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
              </a>
            </CCBDownload>
          )
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsConsolidated])

  const investmentsCompleted = useMemo(() => {
    try {
      const response = investmentsConsolidated.completed.map(item => {
        return {
          Campanha: item.campaign_name,
          Status: (
            <div style={{ display: 'flex' }} title={item.campaign_status}>
              <Badge
                title={item.campaign_status}
                status={item.campaign_status}
                color={
                  item.campaign_status === 'Adimplente'
                    ? 'blue'
                    : item.campaign_status === 'Concluído'
                    ? 'green'
                    : item.campaign_status === 'Atrasado'
                    ? 'yellow'
                    : item.campaign_status === 'Inadimplente'
                    ? 'red'
                    : null
                }
              />
              <span style={{ display: 'flex' }}>
                {!!item.is_renegotiated === true && <DelayedFlag />}
                {(item.campaign_state.id === 2 || item.campaign_state.id === 3) && (
                  <ExecutionFlag title={item.campaign_state.name} />
                )}
              </span>
            </div>
          ),
          'Recebido / A receber': (
            <ProgressBar
              title={`${formatCurrency(item.total_received_per_campaign)} - ${formatCurrency(
                item.remaining_to_receive_per_campaign
              )}`}
              initialValue={item.total_received_per_campaign}
              finalValue={item.remaining_to_receive_per_campaign}
              thinbar={true}
              value={
                (item.total_received_per_campaign * 100) /
                (item.remaining_to_receive_per_campaign + item.total_received_per_campaign)
              }
            />
          ),
          'Taxa mensal / anual': `${formatTax(item.rate_month)} / ${formatTax(item.rate_anual)}`,
          TIR: formatTax(item.internal_rate_return),
          Parcela: `${item.next_installment_number} / ${item.total_installments}`,
          'Próxima parcela':
            formatCurrency(item.next_installment_value) +
            (!!item.next_installment_date ? ` - ${item.next_installment_date}` : ''),
          detalhes: (
            <Link to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}>
              <Detail title='Detalhes'> Detalhes </Detail>
            </Link>
          ),
          ccb: (
            <CCBDownload show={!!item.ccb_url} href={!!item.ccb_url ? item.ccb_url : ''}>
              <a
                download
                href={!!item.ccb_url ? item.ccb_url : ''}
                target='_blank'
                rel='noopener noreferrer'
                title='Download CCB'
              >
                CCB
                <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
              </a>
            </CCBDownload>
          )
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsConsolidated])

  const fetchInfos = useCallback(() => {
    async function fetchInfo() {
      try {
        const {
          data: [investor]
        } = await getInvestors({ user_id: props.match.params.id })

        setInvestor(investor)

        const {
          data: { consolidated, processing }
        } = await getMyInvestments(investor.id)

        const { data: extract } = await getExtract(investor.id)

        setInvestmentsConsolidated(consolidated)
        setExtract(extract)

        const matrization = pledges => {
          let finalArray = []
          let currentName = null
          let currentStatus = null
          let currentArray = []
          pledges.forEach(pledge => {
            if (!currentName) {
              currentName = pledge.company_name
              currentStatus = pledge.pledge_status
            } else if (currentName !== pledge.company_name || currentStatus !== pledge.pledge_status) {
              finalArray.push(currentArray)
              currentArray = []
              currentName = pledge.company_name
              currentStatus = pledge.pledge_status
            }
            currentArray.push(pledge)
          })
          if (currentArray.length) finalArray.push(currentArray)
          return finalArray
        }

        setInvestmentsProcessing(
          matrization(
            processing
              .sort((a, b) => (a.company_name === b.company_name ? 0 : -1))
              .sort((a, b) => (a.pledge_status === b.pledge_status ? 0 : -1))
          )
        )
      } catch (err) {
        toast.error('Ocorreu um erro ao buscar seus investimentos')
      } finally {
        setIsLoading(false)
      }
    }
    fetchInfo()
  }, [props.match.params])

  const handleOrderInvestments = useCallback(
    (orderBy = 'current', filterVal) => {
      try {
        let order = []

        if (filterVal.all) {
          const newOrder = investmentsConsolidated.all
          order = newOrder
        } else {
          if (filterVal.defaulter) {
            const newOrder = investmentsConsolidated.all.filter(item => {
              return item.campaign_status === 'Adimplente'
            })
            order = order.concat(newOrder)
          }
          if (filterVal.late) {
            const newOrder = investmentsConsolidated.all.filter(item => {
              return item.campaign_status === 'Atrasado'
            })
            order = order.concat(newOrder)
          }
          if (filterVal.defaulting) {
            const newOrder = investmentsConsolidated.all.filter(item => {
              return item.campaign_status === 'Inadimplente'
            })
            order = order.concat(newOrder)
          }
          if (filterVal.concluded) {
            const newOrder = investmentsConsolidated.all.filter(item => {
              return item.campaign_status === 'Concluído'
            })
            order = order.concat(newOrder)
          }
        }

        if (!order) {
          throw Error()
        }

        switch (orderBy) {
          case 'current':
            order.sort(orderByBiggestDate)

            break
          case 'old':
            order.sort(orderByLowestDate)

            break
          case 'growing':
            order.sort(orderByGrowing)

            break
          case 'decreasing':
            order.sort(orderByDecreasing)

            break
          default:
            break
        }

        const response = order.map(item => {
          return {
            Campanha: item.campaign_name,
            Status: (
              <div style={{ display: 'flex' }} title={item.campaign_status}>
                <Badge
                  title={item.campaign_status}
                  status={item.campaign_status}
                  color={
                    item.campaign_status === 'Adimplente'
                      ? 'blue'
                      : item.campaign_status === 'Concluído'
                      ? 'green'
                      : item.campaign_status === 'Atrasado'
                      ? 'yellow'
                      : item.campaign_status === 'Inadimplente'
                      ? 'red'
                      : null
                  }
                />
                <span style={{ display: 'flex' }}>
                  {!!item.is_renegotiated === true && <DelayedFlag />}
                  {(item.campaign_state.id === 2 || item.campaign_state.id === 3) && (
                    <ExecutionFlag title={item.campaign_state.name} />
                  )}
                </span>
              </div>
            ),
            'Recebido / A receber': (
              <ProgressBar
                title={`${formatCurrency(item.total_received_per_campaign)} - ${formatCurrency(
                  item.remaining_to_receive_per_campaign
                )}`}
                initialValue={item.total_received_per_campaign}
                finalValue={item.remaining_to_receive_per_campaign}
                thinbar={true}
                value={
                  (item.total_received_per_campaign * 100) /
                  (item.remaining_to_receive_per_campaign + item.total_received_per_campaign)
                }
              />
            ),
            'Taxa mensal / anual': `${formatTax(item.rate_month)} / ${formatTax(item.rate_anual)}`,
            TIR: formatTax(item.internal_rate_return),
            Parcela: `${item.next_installment_number} / ${item.total_installments}`,
            'Próxima parcela':
              formatCurrency(item.next_installment_value) +
              (!!item.next_installment_date ? ` - ${item.next_installment_date}` : ''),
            detalhes: (
              <Link to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}>
                <Detail title='Detalhes'> Detalhes </Detail>
              </Link>
            ),
            ccb: (
              <CCBDownload show={!!item.ccb_url} href={!!item.ccb_url ? item.ccb_url : ''}>
                <a
                  download
                  href={!!item.ccb_url ? item.ccb_url : ''}
                  target='_blank'
                  rel='noopener noreferrer'
                  title='Download CCB'
                >
                  CCB
                  <img src='/assets/images/export.svg' title='Download CCB' alt='Download CCB' />
                </a>
              </CCBDownload>
            )
          }
        })
        setInvestmentsData(response)
      } catch (err) {}
    },
    [investmentsConsolidated]
  )

  const handleFilter = useCallback(
    ({ pagination, order }) => {
      try {
        setPageSelected(0)
        setRowLength(Number(pagination))
        setOrderBy(order)

        if (filterValue.all) {
          setStatusMenu(0)
          setIsFiltering(false)
        } else {
          setStatusMenu(5)
          setIsFiltering(true)
          if (!filterValue.concluded && filterValue.defaulter && !filterValue.defaulting && !filterValue.late) {
            setStatusMenu(1)
          }
          if (!filterValue.concluded && !filterValue.defaulter && !filterValue.defaulting && filterValue.late) {
            setStatusMenu(2)
          }
          if (!filterValue.concluded && !filterValue.defaulter && filterValue.defaulting && !filterValue.late) {
            setStatusMenu(3)
          }
          if (filterValue.concluded && !filterValue.defaulter && !filterValue.defaulting && !filterValue.late) {
            setStatusMenu(4)
          }
        }

        handleOrderInvestments(order, filterValue)
      } catch (err) {
        toast.error('Não foi possível realizar esse filtro.')
      } finally {
        setIsOpenFilter(false)
      }
    },
    [filterValue, isFiltering, handleOrderInvestments]
  )

  const handleDeleteInvestment = useCallback(
    item => {
      const deleteInvest = async invest => {
        try {
          await deletePledge(invest.pledge_id)
          fetchInfos()
          toast.success('Investimento excluído com sucesso.')
          setModalDelete(false)
        } catch (err) {
          toast.error('Não foi possível realizar esse filtro.')
          setModalDelete(false)
        }
      }
      deleteInvest(item)
    },
    [fetchInfos]
  )

  const handleFilterCleaning = useCallback(() => {
    setFilterValue({ all: true, defaulter: false, late: false, defaulting: false, concluded: false })
    setStatusMenu(0)
  }, [handleOrderInvestments])

  const orderByBiggestDate = useCallback((a, b) => {
    const date1 = new Date(a.created_at).valueOf()
    const date2 = new Date(b.created_at).valueOf()

    if (date1 < date2) {
      return -1
    }

    if (date1 > date2) {
      return 1
    }

    return 0
  }, [])

  const orderByLowestDate = useCallback((a, b) => {
    const date1 = new Date(a.created_at).valueOf()
    const date2 = new Date(b.created_at).valueOf()

    if (date1 < date2) {
      return 1
    }

    if (date1 > date2) {
      return -1
    }

    return 0
  }, [])

  const orderByGrowing = useCallback(
    (a, b) => {
      if (a.campaign_name.toUpperCase() < b.campaign_name.toUpperCase()) {
        return -1
      }

      if (a.campaign_name.toUpperCase() > b.campaign_name.toUpperCase()) {
        return 1
      }

      return 0
    },
    [investmentsConsolidated.all]
  )

  const orderByDecreasing = useCallback(
    (a, b) => {
      if (a.campaign_name.toUpperCase() < b.campaign_name.toUpperCase()) {
        return 1
      }

      if (a.campaign_name.toUpperCase() > b.campaign_name.toUpperCase()) {
        return -1
      }

      return 0
    },
    [investmentsConsolidated.all]
  )

  useEffect(() => {
    if (
      filterValue.all === false &&
      filterValue.defaulter === false &&
      filterValue.late === false &&
      filterValue.defaulting === false &&
      filterValue.concluded === false
    ) {
      setFilterValue({
        all: true,
        defaulter: false,
        late: false,
        defaulting: false,
        concluded: false
      })
    }
  }, [filterValue])

  useEffect(() => {
    fetchInfos()
  }, [props.match.params])

  useEffect(() => {
    handleOrderInvestments('current', { all: true })
  }, [investmentsConsolidated.all])

  const handleExportCSV = useCallback(() => {
    const fetchCSV = async () => {
      try {
        const { data: response } = await getMyInvestmentsCSV(investor.id, {
          responseType: 'arraybuffer'
        })
        const element = document.createElement('a')
        element.href = URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        )
        element.download = 'meus_investimentos.xlsx'
        element.click()
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um erro ao exportar a listagem em excel')
      }
    }

    fetchCSV()
  }, [investor])

  if (isLoading) {
    return (
      <Body>
        <Loader />
      </Body>
    )
  }

  return (
    <Body>
      <Title title={`Investimentos | ${investor.first_name} ${investor.last_name} (user_id: ${investor.user_id})`} />
      <ModalProcessing
        open={modalInfoProcessing}
        onClose={() => {
          setModalInfoProcessing(false)
        }}
      />

      <ModalShowProcessing
        open={modalShowProcessing}
        onClose={() => {
          setModalShowProcessing(false)
        }}
      />
      {!isLoading && (!!investmentsConsolidated.all || !!investmentsProcessing.all) ? (
        <>
          <TabMenu options={menu} />

          {tabMenu === 0 && (
            <>
              {investmentsOverview && <CardList cards={investmentsOverview} />}
              <TabMenu options={subMenu} sel={statusMenu} name='invest'>
                <div>
                  <Option name='Exportar' imgUrl='/assets/images/export.svg' onPress={handleExportCSV} />
                  <Option name='Filtrar' imgUrl='/assets/images/filter.svg' onPress={() => setIsOpenFilter(true)} />
                  {testFilter(filterValue) && (
                    <OptionClose
                      name='Limpar Filtro'
                      imgUrl='/assets/images/closeFilter.svg'
                      onPress={handleFilterCleaning}
                    />
                  )}
                </div>
              </TabMenu>
              {statusMenu === 0 && (
                <>
                  <TableBase
                    hasPaginationTop
                    hasPaginationBottom
                    hasFilter
                    widthHidden={1100}
                    columns={investmentsColumns}
                    data={investmentsAll}
                    length={rowLength}
                  />
                  <CardBase
                    investments={investmentsData}
                    hasPaginationTop
                    hasPaginationBottom
                    offset={pageSelected}
                    rowCount={rowLength}
                    navigationTo={setPageSelected}
                  />
                </>
              )}
              {statusMenu === 1 && (
                <>
                  <TableBase
                    hasPaginationTop
                    hasPaginationBottom
                    hasFilter
                    widthHidden={1100}
                    columns={investmentsColumns}
                    data={investmentsDefaulted}
                    length={rowLength}
                  />
                  <CardBase
                    investments={investmentsDefaulted}
                    hasPaginationTop
                    hasPaginationBottom
                    offset={pageSelected}
                    rowCount={rowLength}
                    navigationTo={setPageSelected}
                  />
                </>
              )}
              {statusMenu === 2 && (
                <>
                  <TableBase
                    hasPaginationTop
                    hasPaginationBottom
                    hasFilter
                    widthHidden={1100}
                    columns={investmentsColumns}
                    data={investmentsDelayed}
                    length={rowLength}
                  />
                  <CardBase
                    investments={investmentsDelayed}
                    hasPaginationTop
                    hasPaginationBottom
                    offset={pageSelected}
                    rowCount={rowLength}
                    navigationTo={setPageSelected}
                  />
                </>
              )}
              {statusMenu === 3 && (
                <>
                  <TableBase
                    hasPaginationTop
                    hasPaginationBottom
                    hasFilter
                    widthHidden={1100}
                    columns={investmentsColumns}
                    data={investmentsNotDefaulted}
                    length={rowLength}
                  />
                  <CardBase
                    investments={investmentsNotDefaulted}
                    hasPaginationTop
                    hasPaginationBottom
                    offset={pageSelected}
                    rowCount={rowLength}
                    navigationTo={setPageSelected}
                  />
                </>
              )}
              {statusMenu === 4 && (
                <>
                  <TableBase
                    hasPaginationTop
                    hasPaginationBottom
                    hasFilter
                    widthHidden={1100}
                    columns={investmentsColumns}
                    data={investmentsCompleted}
                    length={rowLength}
                  />
                  <CardBase
                    investments={investmentsCompleted}
                    hasPaginationTop
                    hasPaginationBottom
                    offset={pageSelected}
                    rowCount={rowLength}
                    navigationTo={setPageSelected}
                  />
                </>
              )}
              {statusMenu === 5 && (
                <>
                  <TableBase
                    hasPaginationTop
                    hasPaginationBottom
                    hasFilter
                    widthHidden={1100}
                    columns={investmentsColumns}
                    data={investmentsData}
                    length={rowLength}
                  />
                  <CardBase
                    investments={investmentsData}
                    hasPaginationTop
                    hasPaginationBottom
                    offset={pageSelected}
                    rowCount={rowLength}
                    navigationTo={setPageSelected}
                  />
                </>
              )}
            </>
          )}

          {tabMenu === 1 && (
            <>
              <CardProcessing
                investmentIntentions={processingData}
                info={
                  <InfoIconTab
                    type='card'
                    onClick={() => {
                      setModalInfoProcessing(true)
                    }}
                  />
                }
              />
              {processingData && (
                <TableBase
                  widthHidden={800}
                  columns={processingColumns}
                  data={processingData}
                  length={investmentsProcessing.length}
                  hasFilter
                />
              )}
            </>
          )}
        </>
      ) : (
        <p>{!isLoading && 'Você não possui investimentos.'}</p>
      )}

      {tabMenu === 2 && <Extract investor={investor} allExtract={extract} props={props} />}

      <Modal
        title={modalTitle}
        description={modalMessage}
        close={() => {
          setModalTitle('')
          setModalMessage('')
        }}
      />

      <DeleteModal
        item={modalDelete}
        close={() => {
          setModalDelete(false)
        }}
        deleteInvestment={handleDeleteInvestment}
      />

      <StatusModal title={modalStatusTitle} close={() => setStatusModalTitle('')} />

      <Drawer
        anchor='right'
        open={isOpenFilter}
        onClose={() => setIsOpenFilter(false)}
        iconClose
        titleContainer={<DrawerTitle>Filtrar</DrawerTitle>}
      >
        <Form onSubmit={handleSubmit(data => handleFilter(data))}>
          <main>
            <section>
              <h2>Status do investimento:</h2>

              <Checkbox
                control={control}
                name='Adimplente'
                label='Adimplente'
                checked={filterValue.defaulter}
                onChange={() =>
                  setFilterValue({
                    all: false,
                    defaulter: !filterValue.defaulter,
                    late: filterValue.late,
                    defaulting: filterValue.defaulting,
                    concluded: filterValue.concluded
                  })
                }
              />
              <Checkbox
                control={control}
                name='Atrasado'
                label='Atrasado'
                checked={filterValue.late}
                onChange={() =>
                  setFilterValue({
                    all: false,
                    defaulter: filterValue.defaulter,
                    late: !filterValue.late,
                    defaulting: filterValue.defaulting,
                    concluded: filterValue.concluded
                  })
                }
              />
              <Checkbox
                control={control}
                name='Inadimplente'
                label='Inadimplente'
                checked={filterValue.defaulting}
                onChange={() =>
                  setFilterValue({
                    all: false,
                    defaulter: filterValue.defaulter,
                    late: filterValue.late,
                    defaulting: !filterValue.defaulting,
                    concluded: filterValue.concluded
                  })
                }
              />
              <Checkbox
                control={control}
                name='Concluído'
                label='Concluído'
                checked={filterValue.concluded}
                onChange={() =>
                  setFilterValue({
                    all: false,
                    defaulter: filterValue.defaulter,
                    late: filterValue.late,
                    defaulting: filterValue.defaulting,
                    concluded: !filterValue.concluded
                  })
                }
              />
              <Checkbox
                control={control}
                name='Todos'
                label='Todos'
                checked={filterValue.all}
                onChange={() =>
                  setFilterValue({
                    all: !filterValue.all,
                    defaulter: false,
                    late: false,
                    defaulting: false,
                    concluded: false
                  })
                }
              />
            </section>

            <section>
              <h2>Ordenar por:</h2>

              <Radio
                control={control}
                name='order'
                defaultValue={String(orderBy)}
                options={[
                  {
                    value: 'current',
                    label: 'Mais recentes'
                  },
                  {
                    value: 'old',
                    label: 'Mais antigas'
                  },
                  {
                    value: 'growing',
                    label: 'De A a Z'
                  },
                  {
                    value: 'decreasing',
                    label: 'De Z a A'
                  }
                ]}
              />
            </section>

            <section>
              <h2>Itens por página:</h2>

              <Radio
                control={control}
                name='pagination'
                defaultValue={String(rowLength)}
                options={[
                  {
                    value: '8',
                    label: '8 itens'
                  },
                  {
                    value: '24',
                    label: '24 itens'
                  },
                  {
                    value: '40',
                    label: '40 itens'
                  },
                  {
                    value: '100000000',
                    label: 'Todos'
                  }
                ]}
              />
            </section>
          </main>

          <footer>
            <Button type='submit'>Filtrar</Button>
          </footer>
        </Form>
      </Drawer>
    </Body>
  )
}

export default NewUserInvestments
