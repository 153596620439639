import styled from 'styled-components'
import { TimelineConnector } from '@material-ui/lab'

export const Main = styled.div`
  label {
    background-color: #f5f5f5;
    padding: 6px;
    border-radius: 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    white-space: nowrap;
  }
`
export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  :before,
  :after {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
  }
`
export const Content = styled.div`
  display: flex;

  img {
    background-color: #bdbdbd;
    padding: 12px;
    border-radius: 50%;
  }
`

export const UpdatedMessage = styled.section`
  border-radius: 10px;
  padding: 14px;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  label {
    white-space: normal;
    display: flex;
    flex-wrap: wrap;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    margin-bottom: 8px;
    @media (max-width: 320px) {
      font-size: 12px;
    }

    strong {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #424242;
      margin-right: 8px;
    }
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
`
export const TimelineConnectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  :after {
    content: ' ';
    width: 40px;
    height: 1px;
    background-color: #eeeeee;
  }
`

export const StyledTimelineConnector = styled(TimelineConnector)`
  height: 118%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #e0e0e0 !important;
`
