import axios from 'axios'

import client from '../../providers/fetchClient'

export const getAddressByCep = async zipCode => {
  try {
    const { data: addressInfos, statusText } = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
    if (statusText !== 'OK') throw new Error('Ocorreu um erro ao buscar os dados do CEP')
    return addressInfos
  } catch (error) {
    console.error(error)
    throw new Error(error.message)
  }
}

export const createAddress = data => client.post('/v3/addresses', data)

export const updateAddress = (id, data) => client.put(`/v3/addresses/${id}`, data)

export const getAddressById = id => client.get(`/v3/addresses/${id}`)
