import styled from 'styled-components'
import { Timeline, TimelineItem, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab'
import { Avatar } from '@material-ui/core'

import { Column, Text } from '../index'
import { IconCheck, IconClear } from '../icons'
import { leadStatusBgColors, leadSatusFontColor, leadStatusBorderColors } from '../../../styles'

export const TimelineMaterial = styled(Timeline)`
  &&.MuiTimeline-root {
    align-items: center;
    @media (max-width: 768px) {
      align-items: inherit;
    }
  }
`

export const StyledTimelineItem = styled(TimelineItem)`
  &&.MuiTimelineItem-missingOppositeContent:before {
    content: none;
  }
`

export const StyledTimelineConnector = styled(TimelineConnector)`
  &&.MuiTimelineConnector-root {
    background-color: ${({ theme }) => theme.palette.gray[300]};
    position: absolute;
    height: 100%;
    z-index: -1;
  }
`

export const StyledTimelineDot = styled(TimelineDot)`
  &&.MuiTimelineDot-root {
    margin-bottom: 0;
    position: relative;
    top: 50%;
    margin-top: 0;
  }
  &&.MuiTimelineDot-defaultGrey {
    background-color: ${({ status, bgcolor }) => (bgcolor ? bgcolor : leadStatusBgColors[status] || '#076F9F')};
    border-style: solid;
    border-color: ${({ status }) => leadStatusBorderColors[status] || ''};
    svg {
      fill: ${({ status }) => leadSatusFontColor[status] || '#ffffff'};
    }
  }
`

export const StyledAvatar = styled(Avatar)`
  &&.MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
`

export const Status = styled.div`
  width: 220px;
  height: 27px;
  border-radius: 15px;
  color: ${({ fontColor }) => fontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  white-space: normal;
  text-align: center;
  line-height: initial;
  background-color: ${({ bgColor }) => bgColor};
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor};

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
`

export const AcceptColumn = styled(Column)`
  background: rgba(74, 179, 78, 0.1);
`

export const RejectedColumn = styled(Column)`
  background: rgba(253, 70, 70, 0.1);
`

export const TextMensageVisible = styled(Text)`
  &&.MuiTypography-root {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.gray[600]};
  }
`

export const TitleText = styled(Text)`
  &&.MuiTypography-root {
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.gray[700]};
  }
`

export const StyledTimelineContent = styled(TimelineContent)`
  &&.MuiTimelineContent-root {
    width: 35vw;
  }
`

export const TypeTitle = styled(Text)`
  @media (max-width: 768px) {
    max-width: 100px;
  }
`

export const Download = styled.p`
  color: #757575;
  margin-top: 4px;
  margin-left: 5px;

  a {
    color: #757575;
    font-weight: 500;
    text-decoration: none;

    :hover {
      color: #076f9f;
      text-decoration: underline;
    }
  }
`
export const DivDonwload = styled.div`
  display: flex;

  @media (max-width: 450px) {
    flex-wrap: wrap;
  }
`
export const Observation = styled.div`
  font-family: Open Sans;

  ul,
  ol {
    padding-left: 20px;
  }

  p {
    img {
      width: 100%;
      max-width: fit-content;
    }
  }
`
export const StyledIconCheck = styled(IconCheck)`
  @media (max-width: 550px) {
    display: none !important;
  }
`

export const StyledIconClear = styled(IconClear)`
  @media (max-width: 550px) {
    display: none !important;
  }
`
