import React, { useState, useEffect } from 'react'
import { getCampaign, updateCampaign } from '../../services'

import { CampaignContainer, Quill, LoadingScreen, Button } from '../../components'
import { ButtonContainer, QuillContainer } from './style'

import { toast } from 'react-toastify'

const AboutCompany = ({ match, history }) => {
  const [campaign, setCampaign] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await getCampaign(match.params.id)
        setCampaign(response.data.pop())
        setIsLoading(false)
      } catch (error) {
        toast.error('Não foi possível buscar informações.')
        setIsLoading(false)
      }
    }
    fetchCampaign()
  }, [])

  if (isLoading) {
    return <LoadingScreen />
  }

  const handleChange = value => setCampaign({ ...campaign, description: value })

  const handleClick = async _ => {
    try {
      setIsLoading(true)
      await updateCampaign(campaign)
      toast.success('Campanha atualizada com sucesso.')
      setIsLoading(false)
    } catch (error) {
      toast.error('Não foi possível atualizar campanha.')
      setIsLoading(false)
    }
  }

  return (
    <CampaignContainer step={3}>
      <QuillContainer>
        <Quill onChange={handleChange} value={campaign.description} />
        <ButtonContainer>
          <Button label='Enviar' secondary onClick={handleClick} />
        </ButtonContainer>
      </QuillContainer>
    </CampaignContainer>
  )
}

export default AboutCompany
