import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { Modal } from '../'

const ModalLoading = ({ isOpen, setIsOpen }) => (
  <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <ContainerModal>
      <ContentLoader>
        <Loader src='/assets/icons/spinner-secondary.svg' />
      </ContentLoader>
      <Text>Processando...</Text>
    </ContainerModal>
  </StyledModal>
)

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`
const Loader = styled.img`
  width: 80px;
  animation: ${rotate} 2.5s linear infinite;
`
const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 300px;
  height: 140px;
`
const ContentLoader = styled.div`
  width: 100%;
`
const StyledModal = styled(Modal)`
  padding: 2em;
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
`
const Text = styled.p`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.blueKavod};
`
export default ModalLoading
