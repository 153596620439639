import React, { useState, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'
import { formatISO } from 'date-fns'

import { StyledSelect as Select, Status, StatusContainer, MenuItem } from './styles'
import { updateLeads } from '../../../services/v3'
import { leadStatusBgColors, leadSatusFontColor, leadStatusBorderColors } from '../../../styles'
import { IconExpandMore } from '../icons'
import { Row } from '../index'
import { Selected } from '../icons/custom'
import { UpdateStatusModal } from './UpdateStatusModal'
import ModalRecused from '../../v2Components/ModalRecused'

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      padding: '0px',
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
})

const SelectStatusLead = ({ lead, leadStatuses, handleOpenModal, id, addInTimeline }) => {
  const [status, setStatus] = useState(lead.lead_status.id)
  const [open, setOpen] = useState(false)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
  const [updatedStatus, setUpdatedStatus] = useState(undefined)
  const [modalRecusedIsOpen, setModalRecusedIsOpen] = useState(false)

  const classes = useStyles()

  const nameStatus = useMemo(() => {
    if (!updatedStatus) {
      return undefined
    }

    const status = leadStatuses.find(status => status.id === updatedStatus.new_lead_status_id)

    return status
  }, [leadStatuses, updatedStatus])

  const handleChangeStatus = async e => {
    try {
      if (e.target.value === 11) {
        setModalRecusedIsOpen(!modalRecusedIsOpen)

        return
      }

      setUpdatedStatus({
        lead_update_status_id: 4,
        old_lead_status_id: status,
        new_lead_status_id: e.target.value,
        is_visible_to_partner: true,
        lead_id: id,
        created_at: formatISO(new Date())
      })

      setIsOpenConfirmationModal(true)
    } catch (error) {
      toast.error('Ocorreu um erro ao mudar o status')
    }
  }

  const updateStatus = useCallback(
    async item => {
      try {
        if (!updatedStatus) {
          throw new Error()
        }

        const { is_visible_to_partner } = item

        const response = await updateLeads(lead.id, {
          lead_status_id: updatedStatus.new_lead_status_id,
          is_visible_to_partner: !!is_visible_to_partner
        })
        addInTimeline({
          ...updatedStatus,
          is_visible_to_partner: !!is_visible_to_partner,
          id: response.data.new_lead_update_id
        })
        setStatus(updatedStatus.new_lead_status_id)
        setIsOpenConfirmationModal(false)
        document.location.reload(false)
        toast.success('Status atualizado com sucesso.')
      } catch (err) {
        toast.error('Ocorreu um erro ao mudar o status')
      }
    },
    [updatedStatus, updateLeads, addInTimeline, setStatus]
  )

  return (
    <>
      <StatusContainer>
        <Select
          className={classes.root}
          value={status}
          onChange={handleChangeStatus}
          variant='outlined'
          IconComponent={IconExpandMore}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          {leadStatuses.map(({ id, name, selectable }) => (
            <MenuItem key={id} value={id} disabled={!selectable}>
              {status === id ? (
                open ? (
                  <Row justifyContent='center'>
                    <Status
                      bgColor={leadStatusBgColors[name] || '#2F80ED'}
                      fontColor={leadSatusFontColor[name] || '#ffffff'}
                      borderColor={leadStatusBorderColors[name] || ''}
                    >
                      {name}
                    </Status>
                    <Selected />
                  </Row>
                ) : (
                  <Status
                    bgColor={leadStatusBgColors[name] || '#2F80ED'}
                    fontColor={leadSatusFontColor[name] || '#ffffff'}
                    borderColor={leadStatusBorderColors[name] || ''}
                  >
                    {name}
                  </Status>
                )
              ) : (
                <Status
                  bgColor={leadStatusBgColors[name] || '#2F80ED'}
                  fontColor={leadSatusFontColor[name] || '#ffffff'}
                  borderColor={leadStatusBorderColors[name] || ''}
                >
                  {name}
                </Status>
              )}
            </MenuItem>
          ))}
        </Select>
      </StatusContainer>

      <UpdateStatusModal
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        onConfirm={updateStatus}
        status={nameStatus}
      />
      <ModalRecused
        id={lead.id}
        oldStatus={status}
        open={modalRecusedIsOpen}
        handleClose={() => setModalRecusedIsOpen(false)}
        buttonClick={() => setModalRecusedIsOpen(false)}
        addInTimeline={addInTimeline}
        setStatus={setStatus}
      />
    </>
  )
}

export default SelectStatusLead
