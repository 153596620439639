import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill'
import useDevice from '../../../../hooks/useDevice'

import { Column, Row, Text, Button, Input, ModalBase, Checkbox } from '../../../../components/v2Components'

import { TextEditor } from './style'

import { createFQS, updateFQS } from '../../../../services/v3'
import { HomeSchema } from '../../../../schemas/Employees'

import 'react-quill/dist/quill.snow.css'

const defaultValue = {
  question: '',
  is_featured: false
}

const myToolbar = [
  [({ font: [] }, { size: [] })],
  [{ align: [] }, 'direction'],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ script: 'super' }, { script: 'sub' }],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image', 'video'],
  ['clean']
]

const ModalFQS = ({ open, onClose }) => {
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: HomeSchema,
    mode: 'all',
    defaultValues: defaultValue
  })

  const [checkbox, setCheckbox] = useState(false)
  const [value, setValue] = useState('')

  const quillRef = useRef()

  const { isMobile } = useDevice()

  const handleImage = useCallback(() => {
    var range = quillRef.current.editor.getSelection()
    var value = prompt('Por favor colar a URL da imagem aqui.')
    if (value) {
      quillRef.current.editor.insertEmbed(range.index, 'image', value)
    }
  }, [quillRef])

  const handleRedo = useCallback(() => {
    let myEditor = quillRef.current.getEditor()
    return myEditor.history.redo()
  }, [quillRef])

  const onSubmit = useCallback(
    async data => {
      try {
        if (typeof open === 'object') {
          await updateFQS(open.id, {
            question: data.question,
            answer: value || '',
            is_featured: data.is_featured,
            url: data.question
              .normalize('NFD')
              .replace(/[^a-zA-Zs]/g, '')
              .replace(' ', '-')
              .replace(' ', '')
          })
        } else {
          await createFQS({
            question: data.question,
            answer: value || '',
            is_featured: data.is_featured,
            url: data.question
              .normalize('NFD')
              .replace(/[^a-zA-Zs]/g, '')
              .replace(' ', '-')
              .replace(' ', '')
          })
        }

        window.location.reload()
        onClose()
        toast.success('Pergunta adicionada')
      } catch (error) {
        if (error.message === 'Request failed with status code 400') {
          return toast.error('Já existem 4 perguntas para tela inicial.')
        }
        toast.error('Erro ao criar a pergunta.')
      }
    },
    [open, value]
  )

  useEffect(() => {
    if (typeof open === 'object') {
      reset({ question: open.question, is_featured: !!open.is_featured })
      setValue(open.answer)
      setCheckbox(!!open.is_featured)
    } else {
      reset({ question: '', is_featured: false })
      setCheckbox(!!open.is_featured)
    }
  }, [open])

  return (
    <ModalBase
      open={!!open}
      onClose={onClose}
      title={typeof open === 'object' ? 'Editar pergunta' : 'Adicionar pergunta'}
      width={isMobile ? 300 : 600}
      height='100%'
      minWidth='auto'
      iconClose
    >
      <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
        <Column>
          <Text variant='body1' mt={1}>
            Título
          </Text>
          <Input
            id={'question'}
            name='question'
            placeholder='Pergunta'
            width='100%'
            mb='10px'
            control={control}
            error={errors.question}
          />

          <Text variant='body1' mt={1}>
            Resposta
          </Text>
          <TextEditor>
            <ReactQuill
              theme='snow'
              ref={quillRef}
              value={value}
              onChange={setValue}
              modules={{
                history: {
                  delay: 50,
                  maxStack: 150,
                  userOnly: false
                },
                toolbar: {
                  container: myToolbar,
                  handlers: {
                    image: handleImage
                  }
                },
                keyboard: {
                  bindings: {
                    custom: {
                      key: 'Y',
                      ctrlKey: true,
                      handler: handleRedo
                    }
                  }
                }
              }}
              spellcheck='true'
            />
          </TextEditor>

          <Checkbox
            id='is_featured'
            name='is_featured'
            label='Tela inicial'
            control={control}
            error={errors.is_featured}
            checked={checkbox}
            onChange={() => {
              setCheckbox(!checkbox)
            }}
          />
          <Row mb={3}>
            <Button width='45%' variant='outlined' ml={1} mr={4} onClick={onClose}>
              Cancelar
            </Button>
            <Button width='45%' type='submit'>
              Enviar
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalFQS
