import styled from '@emotion/styled'

export const CampaignStatusContainer = styled.div`
  padding: 1em 0em;
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Info = styled.div`
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const CampaignInfo = styled.div`
  display: flex;
  border-bottom: 1px solid lightgray;
  padding-bottom: 1em;
  margin-bottom: 1em;
  div:first-of-type {
    padding-left: 0px;
  }
`

export const Subtitle = styled.p`
  color: #5d5d5d;
`

export const Title = styled.p`
  font-weight: bold;
  color: ${({ color }) => color || '#5d5d5d'};
  margin-bottom: 1em;
`

export const CampaignActions = styled.div`
  padding: 2em 0;
  width: 100%;
`

export const Header = styled.div`
  padding: 1em 0em;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
`

export const CampaignName = styled.p`
  font-size: 2em;
  color: #5d5d5d;
`

export const Container = styled.div`
  margin-top: 110px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  padding: 0 3em;
`
