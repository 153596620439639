import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import AutocompleteInput from '../components/AutocompleteInput'
import UploadPersonalDocument from '../components/UploadPersonalDocument'
import { RadioInput } from '../components'
import InputMask from 'react-input-mask'

export const renderField = (input, field, classes, setFieldValue, onChange, onFocus, onBlur, handleChange) => {
  switch (input.type) {
    case 'text':
      return !!input.mask ? (
        <Grid item className={classes.containerInput} sm={input.col} xs={12}>
          <InputMask
            mask={input.mask ? input.mask : null}
            maskChar={input.maskChar ? input.maskChar : null}
            placeholder={input.placeholder}
            name={input.name}
            disabled={input.disabled}
            onFocus={field.onFocus}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            type={input.type}
            label={input.label}
            margin={input.margin}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                className={classes.textField}
                onFocus={inputProps.onFocus}
                onBlur={inputProps.onBlur}
                type={inputProps.type}
              />
            )}
          </InputMask>
        </Grid>
      ) : (
        <Grid item className={classes.containerInput} sm={input.col} xs={12}>
          <TextField
            {...field}
            label={input.label}
            className={classes.textField}
            margin={input.margin}
            id={input.id}
            type={input.type}
            placeholder={input.placeholder}
            disabled={input.disabled}
            mask={input.mask}
          />
        </Grid>
      )
    case 'select':
      return (
        <Grid item className={classes.containerInput} sm={input.col} xs={12}>
          <TextField
            {...field}
            id={input.id}
            select
            label={input.label}
            className={classes.textField}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            margin='normal'
          >
            {input.options.map(options => (
              <MenuItem className={classes.select} key={options.value} value={options.value}>
                {options.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )
    case 'autocomplete':
      return <AutocompleteInput field={field} input={input} setFieldValue={setFieldValue} />

    case 'personal-document':
      return <UploadPersonalDocument field={field} input={input} classes={classes} setFieldValue={setFieldValue} />

    case 'radio':
      return <RadioInput field={field} input={input} classes={classes} setFieldValue={setFieldValue} />

    default:
      return (
        <Grid item className={classes.containerInput} sm={input.col} xs={12}>
          <TextField
            {...field}
            label={input.label}
            className={classes.textField}
            margin={input.margin}
            id={input.id}
            type={input.type}
            placeholder={input.placeholder}
            disabled={input.disabled}
          />
        </Grid>
      )
  }
}
