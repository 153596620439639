import React from 'react'
import styled from '@emotion/styled'
import { Button } from '../index'
import numbro from 'numbro'
import { withTheme } from 'emotion-theming'

const BankCreditCertificatesModal = ({ info, handleModal }) => {
  return (
    <Modal>
      <ModalContent>
        <ModalTitle>Dados da CCB</ModalTitle>
        <ModalBody>
          <ModalInfoContainer>
            <ModalLabel>Valor do investimento</ModalLabel>
            <ModalInfo>R$ {numbro(info.amount).format({ mantissa: 2, thousandSeparated: true })}</ModalInfo>
          </ModalInfoContainer>
          <ModalInfoContainer>
            <ModalLabel>Valor da parcela</ModalLabel>
            <ModalInfo>R$ {numbro(info.instalment_value).format({ mantissa: 2, thousandSeparated: true })}</ModalInfo>
          </ModalInfoContainer>
          <ModalInfoContainer>
            <ModalLabel>Usuário</ModalLabel>
            <ModalInfo>{info.first_name + ' ' + info.last_name}</ModalInfo>
          </ModalInfoContainer>
          <ModalInfoContainer>
            <ModalLabel>ID da proposta</ModalLabel>
            <ModalInfo>{info.proposal_id}</ModalInfo>
          </ModalInfoContainer>
          <ModalInfoContainer>
            <ModalLabel>Taxa mensal</ModalLabel>
            <ModalInfo>{info.rate_month}</ModalInfo>
          </ModalInfoContainer>
          <ModalInfoContainer>
            <ModalLabel>Número de parcelas</ModalLabel>
            <ModalInfo>{info.number_instalments}</ModalInfo>
          </ModalInfoContainer>
        </ModalBody>
        <Button label='fechar' secondary onClick={handleModal} />
      </ModalContent>
    </Modal>
  )
}

const ModalLabel = styled.label`
  margin-bottom: 0.5em;
`

const ModalInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 1em;
  margin-bottom: 1em;
`

const ModalBody = styled.div`
  display: flex;
  padding: 1em 0;
  flex-wrap: wrap;
  justify-content: center;
`

const ModalInfo = styled.p`
  padding: 0.5em 0 0.5em 0.5em;
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1em;
`

const ModalTitle = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 2em;
  color: black;
`

export const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  flex-wrap: wrap;
  width: 50%;
  padding: 1em;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray};
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);

  @media (max-width: 769px) {
    width: 70%;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 321px) {
    width: auto;
    padding: 2em;
    font-size: 0.8em;
  }
`

export default withTheme(BankCreditCertificatesModal)
