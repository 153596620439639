import React, { useState } from 'react'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { withRouter, Link as RouterLink } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CloseIcon from '@material-ui/icons/Close'
import { Sidebar } from '../'

const Header = ({ theme, history }) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem('@kavod:token')
    history.push('/login')
  }

  return (
    <div>
      <MenuContainer>
        <Container background={theme.colors.gray}>
          <ContainerInitial>
            <ButtonMenu onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
              {isOpenSidebar ? <StyledCloseIcon fontSize='large' /> : <StyledMenuIcon fontSize='large' />}
            </ButtonMenu>
            <LogoContainer>
              <Link to='/'>
                <ImageLogo src='/assets/images/logo-eshkavodbranco.png' alt='Kavod Lending' />
              </Link>
            </LogoContainer>
          </ContainerInitial>
          <ButtonMenu onClick={handleLogout}>
            <StyledExitIcon fontSize='large' />
          </ButtonMenu>
        </Container>
      </MenuContainer>
      <Sidebar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />
    </div>
  )
}

const navItemStyle = `
  cursor: pointer;
  padding: 1em;
  background: none;
  border: none;
  text-decoration: none;
  font-size: 1em;
  color:#fff;
  font-weight: bold;
  &:hover {
    color: #000;
    transition: all 0.3s;
  }
  &:focus {
    outline: 0;
  }
`

const ContainerInitial = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonMenu = styled.button`
  cursor: pointer;
  padding: 1em;
  background: none;
  border: none;
  text-decoration: none;
  font-size: 1em;
  color: #fff;
  font-weight: bold;
  &:hover {
    color: #000;
    transition: all 0.3s;
  }
  &:focus {
    outline: 0;
  }
`

const StyledMenuIcon = styled(MenuIcon)`
  color: #fff;
  cursor: pointer;
  margin-left: 6px;
`

const StyledCloseIcon = styled(CloseIcon)`
  color: #fff;
  cursor: pointer;
  margin-left: 6px;
`

const StyledExitIcon = styled(ExitToAppIcon)`
  color: #fff;
  cursor: pointer;
`

const MenuContainer = styled.div`
  width: 100%;
  display: block;
`

const Container = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  height: auto;
  background: ${({ theme }) => theme.colors.blueKavod};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const ImageLogo = styled.img`
  width: 180px;
  height: 45px;
`

const Link = styled(RouterLink)`
  ${navItemStyle}
`

export default withRouter(withTheme(Header))
