import React from 'react'

import { Row, Column, Input } from '../index'

const FranchiseData = ({ errors, isMobile, isTablet, control }) => {
  if (isMobile || isTablet) {
    return (
      <Column>
        <Input
          mt={5}
          label='Franqueador *'
          control={control}
          error={errors.franchisor_name}
          name='franchisor_name'
          width='100%'
        />
        <Input
          error={errors.franchise_description}
          label='Descrição da franquia *'
          control={control}
          name='franchise_description'
          width='100%'
          multiline
          rows={5}
          mt={5}
        />
      </Column>
    )
  }
  return (
    <Column>
      <Row mt={5}>
        <Input
          label='Franqueador *'
          control={control}
          error={errors.franchisor_name}
          name='franchisor_name'
          width='45%'
        />
      </Row>
      <Row mt={5}>
        <Input
          error={errors.franchise_description}
          label='Descrição da franquia *'
          control={control}
          name='franchise_description'
          width='92%'
          multiline
          rows={5}
        />
      </Row>
    </Column>
  )
}

export default FranchiseData
