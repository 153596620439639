import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import { Input, Button } from '../../components'

import { getToken } from '../../helpers/auth'
import { login, fetchCurrentUser } from '../../services/v3'

import { withTheme } from 'emotion-theming'

const LoginSchema = yup.object().shape({
  cpf: yup.string().required('Campo obrigatório.'),
  password: yup.string().required('Campo obrigatório.')
})

const Login = ({ theme, history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [wrongUser, setWrongUser] = useState(false)

  const isLogged = async () => {
    const token = getToken()
    if (token) {
      const {
        data: { roles }
      } = await fetchCurrentUser()

      if (roles && roles.find(role => role.name === 'Administrator')) {
        history.push('/')
      } else {
        localStorage.removeItem('@kavod:token')
        toast.error('Acesso restrito a usuários administradores')
        history.push('/login')
      }
    }
  }
  useEffect(() => {
    isLogged()
  })

  const handleSubmit = async values => {
    try {
      setIsLoading(true)

      const response = await login({ document_number: values.cpf.replace(/\D/g, ''), password: values.password })
      localStorage.setItem('@kavod:token', `Bearer ${response.data.token}`)
      setIsLoading(false)
      history.push('/campanhas')
    } catch (e) {
      setWrongUser(true)
      setIsLoading(false)
    }
  }

  const handleChange = () => {
    setWrongUser(false)
  }

  return (
    <Container backgroundColor={theme.colors.gray}>
      <Logo src='/assets/images/kavod_logo.png' />
      <Formik
        validationSchema={LoginSchema}
        initialValues={{ cpf: '', password: '' }}
        onSubmit={handleSubmit}
        render={() => (
          <LoginForm>
            <InputsContainer onClick={handleChange}>
              <InputContainer>
                <Field
                  render={({ field }) => (
                    <Input
                      {...field}
                      type='text'
                      mask='999.999.999-99'
                      error={wrongUser}
                      label='CPF'
                      placeholder='Digite seu CPF..'
                    />
                  )}
                  name='cpf'
                />
                <ErrorMessage name='cpf' component={ErrorSpan} />
              </InputContainer>
              <InputContainer>
                <Field
                  render={({ field }) => (
                    <Input {...field} type='password' error={wrongUser} label='Senha' placeholder='Digite sua senha' />
                  )}
                  name='password'
                />
                <ErrorMessage name='password' component={ErrorSpan} />
              </InputContainer>
            </InputsContainer>
            <Button label='Login' secondary type='submit' isLoading={isLoading} />
          </LoginForm>
        )}
      />
    </Container>
  )
}

const ErrorSpan = styled.span`
  color: #f00;
  font-size: 0.8em;
`

const InputContainer = styled.div`
  min-height: 100px;
`

const Logo = styled.img`
  margin-bottom: 1em;
  width: 10em;
`

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const LoginForm = styled(Form)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2em;
  height: 50%;
  width: 30%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  @media (max-width: 600px) {
    padding: 1em;
    width: 90%;
  }
`

export default withTheme(Login)
