/* eslint-disable indent */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { Body, TableBase, TabMenu, Option, Loader } from '../components'
import {
  Card,
  ResponsiveCard,
  Timeline,
  DetailsModal,
  DetailsButton,
  DelayedFlag,
  PaymentConfirmed,
  InternalNavigation
} from './components'

import { Container, ContainerOption, ResponsiveTabMenu, TableContainer, PredictedValue, Parcel } from './styles'

import { formatCurrency, formatDateInvest, formatTax } from '../../../helpers'

import { getMyInvestmentsShow, getMyInvestmentsShowCSV, getInvestors } from '../../../services/v3'

const DetailsInvestments = props => {
  const [tabMenu, setTabMenu] = useState(0)
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [investmentsInstallments, setInvestmentsInstallments] = useState([])
  const [investmentsOverview, setInvestmentsOverview] = useState([])
  const [investmentsUpdates, setInvestmentsUpdates] = useState([])
  const [indexValue, setIndexValue] = useState(0)
  const [investor, setInvestor] = useState()

  const history = useHistory()

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        if (props.match.params.id) {
          const {
            data: [investor]
          } = await getInvestors({ user_id: props.match.params.id })

          const { data: response } = await getMyInvestmentsShow(props.match.params.campaignId, investor.id)
          const { installments, overview, updates } = response

          setInvestor(investor)
          setInvestmentsInstallments(installments)
          setInvestmentsOverview(overview)
          setInvestmentsUpdates(updates)
          setIsLoading(false)
        }
      } catch (error) {
        history.push(`/usuarios/${props.match.params.id}/investimentos`)
        toast.error('Investimento não encontrado.')
      }
    }
    fetchInfos()
  }, [props.match.params])

  const investmentsUpdatesData = useMemo(() => {
    try {
      const response = investmentsUpdates.map(investments => {
        return {
          date: investments.date,
          description: investments.description,
          hour: investments.hour,
          title: investments.title
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsUpdates])

  const installmentsData = useMemo(() => {
    try {
      const response = investmentsInstallments.map((installments, index) => {
        function handleClick(i) {
          setIsOpenDetail(true)
          setIndexValue(i)
        }

        return {
          Parcela: (
            <Parcel title={index + 1 + '/' + investmentsInstallments.length}>
              {index + 1 + '/' + investmentsInstallments.length}
              {installments.abbreviation === 'P' ? <PaymentConfirmed title='pagamento' /> : ''}
            </Parcel>
          ),

          'Valor previsto da TED': (
            <PredictedValue
              title={formatCurrency(installments.transfer_amount_delayed || installments.transfer_amount)}
            >
              {formatCurrency(installments.transfer_amount_delayed || installments.transfer_amount)}{' '}
              {installments.renegotiated !== 0 ? <DelayedFlag /> : ''}
            </PredictedValue>
          ),
          'Previsão de pagamento': !!installments.transfer_date_delayed
            ? formatDateInvest(installments.transfer_date_delayed)
            : installments.transfer_date != null
            ? formatDateInvest(installments.transfer_date)
            : formatDateInvest(installments.expire_date),
          'Pago em': installments.pay_date != null ? formatDateInvest(installments.pay_date) : '-',
          detalhes: <DetailsButton onClick={() => handleClick(index)} />
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsInstallments])

  const overviewData = useMemo(() => {
    try {
      const overview = investmentsOverview[0]

      return {
        campaignCCBUrl: overview.campaignCCBUrl,
        campaignName: overview.campaignName,
        campaignRateAnual: overview.campaignRateAnual,
        campaignRateMonth: overview.campaignRateMonth,
        campaignStatus: overview.campaignStatus,
        grossAmount: overview.grossAmount,
        internalRateReturn: overview.internalRateReturn,
        investmentAmount: overview.investmentAmount,
        isRenegotiated: overview.negotiationStatus,
        netAmount: overview.netAmount,
        percentageReceived: overview.percentageReceived,
        percentualReturn: overview.percentualReturn,
        remainingToReceivePerCampaign: overview.remainingToReceivePerCampaign,
        totalReceivedPerCampaign: overview.totalReceivedPerCampaign,
        valueReturn: overview.valueReturn,
        campaignState: overview.campaignState
      }
    } catch (err) {
      return undefined
    }
  }, [investmentsOverview])

  const parcelDetailsData = useMemo(() => {
    try {
      const response = investmentsInstallments.map(installments => {
        return {
          status: installments.abbreviation === 'O' ? 'A receber' : installments.abbreviation === 'P' ? 'Pago' : '',
          'Valor bruto da parcela': formatCurrency(installments.installment_default_value),
          Amortização: formatCurrency(installments.amortization),
          'Juros da parcela': formatCurrency(installments.tax_per_installment),
          'Juros do atraso': !!installments.installment_interest
            ? formatCurrency(installments.installment_interest)
            : null,
          'Faixa de imposto de renda': formatTax(installments.IR_tax_based_on_past_days),
          'Imposto de renda da parcela': formatCurrency(installments.IR_based_on_past_days),
          'Imposto de renda do atraso da parcela': !!installments.IR_on_delay
            ? formatCurrency(installments.IR_on_delay)
            : null,
          'Imposto de renda acumulado': formatCurrency(installments.accumulated_ir),
          'Valor líquido original': !!installments.liquidation_transfer_amount
            ? formatCurrency(installments.liquidation_transfer_amount)
            : formatCurrency(installments.transfer_amount),
          'Valor líquido previsto': formatCurrency(
            installments.transfer_amount_delayed || installments.transfer_amount
          ),
          'Dias corridos desde o início da campanha': installments.past_days,
          'Previsão da TED': formatDateInvest(installments.transfer_date_delayed || installments.transfer_date),
          'Previsão de pagamento':
            installments.pay_date != null
              ? formatDateInvest(installments.pay_date)
              : formatDateInvest(installments.transfer_date),
          transfer_amount_delayed: installments.transfer_amount_delayed
        }
      })
      return response
    } catch (err) {
      return undefined
    }
  }, [investmentsInstallments])

  const menu = useMemo(
    () => [
      {
        name: 'Parcelas',
        handleClick() {
          setTabMenu(0)
        }
      },
      {
        name: 'Atualizações',
        handleClick() {
          setTabMenu(1)
        }
      }
    ],
    []
  )

  const installmentsColumns = useMemo(
    () => [
      {
        Header: 'Parcela',
        accessor: 'Parcela'
      },
      {
        Header: 'Valor previsto da TED',
        accessor: 'Valor previsto da TED'
      },
      {
        Header: 'Previsão de pagamento',
        accessor: 'Previsão de pagamento'
      },
      {
        Header: 'Pago em',
        accessor: 'Pago em'
      },
      {
        Header: '',
        accessor: 'detalhes'
      }
    ],
    []
  )

  const handleExportCSV = useCallback(() => {
    const fetchCSV = async () => {
      try {
        const { data: response } = await getMyInvestmentsShowCSV(props.match.params.campaignId, investor.id, {
          responseType: 'arraybuffer'
        })
        const element = document.createElement('a')
        element.href = URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        )
        element.download = `${overviewData.campaignName}.xlsx`
        element.click()
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um erro ao exportar a listagem em excel')
      }
    }

    fetchCSV()
  }, [props.match.params, investor])

  if (isLoading) {
    return (
      <Body header='Detalhes'>
        <Loader />
      </Body>
    )
  }

  return (
    <Body header='Detalhes'>
      {!isLoading && (
        <>
          <InternalNavigation
            link={`/usuarios/${props.match.params.id}/investimentos`}
            routeName={`Investimentos  | ${investor.first_name} ${investor.last_name} (user_id: ${investor.user_id})`}
            name={overviewData.campaignName}
          />

          <Card id={props.match.params.campaignId} overview={overviewData} investorId={investor.id} />

          <ResponsiveTabMenu>
            <TabMenu options={menu}>
              <ContainerOption>
                <Option name='Exportar' imgUrl='/assets/images/export.svg' onPress={handleExportCSV} />
              </ContainerOption>
            </TabMenu>
          </ResponsiveTabMenu>

          {tabMenu === 0 && (
            <>
              <TableContainer>
                <TableBase
                  columns={installmentsColumns}
                  data={installmentsData}
                  length={investmentsInstallments.length}
                  widthHidden={780}
                />
              </TableContainer>

              <Container>
                <ResponsiveCard infoParcels={installmentsData} />
              </Container>
            </>
          )}

          {tabMenu === 1 && investmentsUpdates.length > 0 && (
            <Timeline investmentsUpdatesData={investmentsUpdatesData} />
          )}

          {tabMenu === 1 && investmentsUpdates.length <= 0 && <p>Sem atualizações para este investimento</p>}

          <DetailsModal
            indexValue={indexValue}
            dataInfo={parcelDetailsData}
            isOpen={isOpenDetail}
            isClose={() => setIsOpenDetail(false)}
            handleChangePortion={setIndexValue}
          />
        </>
      )}
    </Body>
  )
}

export default DetailsInvestments
