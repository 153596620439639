import styled from 'styled-components'

export const BlankField = styled.div`
  background: transparent;
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '200px')};
  @media (max-width: 768px) {
    display: none;
  }
`
