import React from 'react'
import MomentUtils from '@date-io/moment'
import { Formik, Form } from 'formik'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { TimePickerFormik, DatePickerFormik, Button, Quill, FieldForm } from '../../components'
import { createCampaignUpdate, updateCampaignUpdate } from '../../services'
import styled from '@emotion/styled'
import moment from 'moment-timezone'
import 'moment/locale/pt'
import { toast } from 'react-toastify'

moment.locale('pt')

const initialValues = {
  title: '',
  description: '',
  date: null,
  time: ''
}

const CampaignUpdatesForm = ({ update, campaignId, setLoading, reload }) => {
  const handleSubmit = async values => {
    setLoading(true)
    try {
      if (update) {
        await updateCampaignUpdate(values)
        toast.success('Atualização editada com sucesso.')
      } else {
        await createCampaignUpdate(values)
        toast.success('Atualização criada com sucesso.')
      }
    } catch (error) {
      toast.error(`Não foi possível ${update ? 'editar' : 'criar'} atualização.`)
    } finally {
      reload()
    }
  }

  return (
    <Container>
      <Formik
        initialValues={
          update
            ? { ...update, time: moment('2000-01-01 ' + update.time) }
            : { ...initialValues, campaign_id: campaignId }
        }
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <StyledForm>
            <FieldForm name='title' label='Título' />
            <MuiPickersUtilsProvider utils={MomentUtils} locale='pt'>
              <DatePickerFormik name='date' label='Data' values={values} setFieldValue={setFieldValue} />
              <TimePickerFormik name='time' label='Hora' values={values} setFieldValue={setFieldValue} />
            </MuiPickersUtilsProvider>
            <QuillContainer>
              <Quill value={values.description} onChange={value => setFieldValue('description', value)} />
            </QuillContainer>
            <ButtonContainer>
              <Button label='Enviar' secondary />
            </ButtonContainer>
          </StyledForm>
        )}
      </Formik>
    </Container>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1em;
  margin-top: 20px;
`

const QuillContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const Container = styled.div`
  width: 100%;
`

export default CampaignUpdatesForm
