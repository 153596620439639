import moment from 'moment-timezone'
import numbro from 'numbro'

export const formatDate = date => {
  const newDate = new Date(
    date
      .split('/')
      .reverse()
      .join('/')
  )
  return moment(newDate).isValid() ? moment(newDate).format('YYYY-MM-DD') : null
}

export const formatDateInvest = date => (moment.utc(date).isValid() ? moment.utc(date).format('DD/MM/YYYY') : null)

export const formatDateInterval = value => (moment.utc(value).isValid() ? moment.utc(value).format('YYYY-MM-DD') : '-')

export const filterIntervalDates = (date, startDate, endDate) =>
  moment(date).isValid() ? moment(date).isBetween(startDate, endDate) : '-'

export const sanitizeDate = value => (moment(value).isValid() ? moment.utc(value).format('DD/MM/YYYY') : '-')

export const sanitizeDateWithHours = value =>
  moment.utc(value).isValid() ? moment.tz(value, 'America/Sao_Paulo').format('DD/MM/YYYY [às] HH:mm') : '-'

export const formatMoney = value => {
  if (value === null) return '-'
  const newValue = isNaN(value) ? 0 : value
  return numbro(newValue).format({
    thousandSeparated: true,
    mantissa: 2
  })
}

export const numbroUnformat = value => numbro.unformat(value)

export const sanitizeMoney = value => (value ? String(numbro.unformat(value)).replace('.', ',') : '0')

export const sanitizeMoneyNumber = value => {
  if (!!value) {
    return Number(
      value
        .replace(/\./g, '')
        .replace(',', '.')
        .replace(/^\D*/, '')
    )
  } else {
    return 0
  }
}

export const getAnnualRate = value =>
  parseFloat(value) ? numbro(((value / 100 + 1) ** 12 - 1) * 100).format({ mantissa: 2 }) : 0

export const formatTime = date =>
  date
    .hours()
    .toString()
    .padStart(2, '0') +
  ':' +
  date
    .minutes()
    .toString()
    .padStart(2, '0')

export const checkIsEdit = url => url.split('/').indexOf('informacoes') !== -1

export const formatCampaignToServer = values => ({
  ...values,
  start_time: formatTime(values.start_time),
  end_time: formatTime(values.end_time),
  start_date: values.start_date.format('YYYY-MM-DD'),
  end_date: values.end_date.format('YYYY-MM-DD'),
  amount: sanitizeMoney(values.amount),
  min_amount: sanitizeMoney(values.min_amount),
  max_amount: sanitizeMoney(values.max_amount)
})

export const formatCampaign = (campaign, isEdit) => {
  if (isEdit) {
    return {
      ...campaign,
      anual_rate: getAnnualRate(campaign.rate_month),
      amount: formatMoney(campaign.amount),
      max_amount: formatMoney(campaign.max_amount),
      min_amount: formatMoney(campaign.min_amount),
      end_time: moment('2000-01-01 ' + campaign.end_time),
      end_date: moment.utc(campaign.end_date),
      start_time: moment('2000-01-01 ' + campaign.start_time),
      start_date: moment.utc(campaign.start_date)
    }
  } else {
    return {
      company_name: '',
      name: '',
      short_description: '<p></p>',
      url: '',
      amount: '',
      description: '<p></p>',
      rate_month: '',
      CDI: '',
      anual_rate: 0,
      installments: '',
      tags: null,
      recommended_amount: null,
      max_amount: '',
      min_amount: '',
      grace_period: '',
      end_time: '',
      end_date: null,
      start_time: '',
      start_date: null,
      stt_id: 'A',
      published: 1
    }
  }
}

export const remainingDays = value => {
  if (value) {
    const newValue = value.split('T')[0].split('-')
    const day = newValue[2]
    const month = newValue[1] - 1
    const year = newValue[0]
    const newDate = new Date(year, month, day)
    const oneDay = 1000 * 60 * 60 * 24
    const date = new Date()
    const date1 = date.getTime()
    const date2 = newDate.getTime()
    const difference = date2 - date1
    const days = Math.round(difference / oneDay)
    if (isNaN(days)) return 0
    if (days < 0) return 0

    return days
  }
}

export const calculatePercent = (maxValue, currentAmount) => {
  let percent = (currentAmount * 100) / maxValue
  if (percent > 100) return 100
  return percent.toFixed(2)
}

export const formatDocumentName = string => string.replace(/[^A-Z0-9.]+/gi, '_')

export const getAccountType = accountType => {
  switch (accountType) {
    case 'CC':
      return 'Conta Corrente'
    case 'CP':
      return 'Conta Poupança'
    default:
      return ''
  }
}

export const formatCPF = string => string.replace(/^(\d{3})\D*(\d{3})\D*(\d{3})\D*(\d{2})$/g, '$1.$2.$3-$4')

export const formatPhoneWithPrefix = (prefix, phone) =>
  `(${prefix}) ${phone.substr(0, 1)} ${phone.substr(1, 4)}-${phone.substr(5, 8)}`

export const formatPhone = string => string.replace(/^(\d{5})\D*(\d{4})$/g, '$1-$2')

export const validateEmail = email =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email
  )

export const fetchRG = documents => documents.find(doc => doc && doc.personal_document_type_id === 'R') || ''

export const formatCNPJ = string => {
  if (string) return string.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export const sanitizeSearchTermFromInput = searchTerm =>
  Number(searchTerm.replace(/[.,/#!?$%^&*;:{}=\-_`~()]/g, ''))
    ? searchTerm.replace(/[.,/#!?$%^&*;:{}=\-_`~()]/g, '')
    : searchTerm.replace(/[,/#!?$%^&*;:{}=`~()]/g, '')

export const formatDay = date => (date ? moment.utc(date).format('DD') : '-')

export const formatFloat = number => {
  const integer = number.toString().slice(0, -2)
  const decimal = (number % 100).toString().padStart(2, '0')
  const stringNumber = `${integer}.${decimal}`
  return parseFloat(stringNumber)
}

export const bmpFormatNumber = value => (isNaN(value) ? Number(value.replace(',', '.')) : value)

export const getLowerValue = (a, b) => (a > b ? b : a)

export const isEmptyObject = object => !Object.keys(object).filter(key => object[key]).length

const limit = (value, max) => {
  if (value.length === 1 && value[0] > max[0]) value = '0' + value
  if (value.length === 2 && value > max) value = max

  return value
}

export const timeValidation = value => {
  const hour = limit(value.substring(0, 2), '23')
  const minutes = limit(value.substring(2, 4), '59')

  return hour + (minutes.length ? ':' + minutes : '')
}
