import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

export const ToolTipAlert = withStyles(theme => ({
  arrow: {
    color: '#ff8300'
  },
  tooltip: {
    backgroundColor: '#ff8300',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    borderRadius: '4px'
  }
}))(Tooltip)
