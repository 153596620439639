import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  Column,
  ContributorCard,
  Row,
  Input,
  Text,
  Button,
  ModalAddColabutor,
  ModalDeleteEmployee,
  Pagination,
  ModalEditEmployee
} from '../../components/v2Components'
import { IconSearch } from '../../components/v2Components/icons'
import LoadingComponent from '../../components/LoadingComponent'

import { fetchAllEmployees, fetchAreaTypeIds } from '../../services/v3'
import { useDevice, useDebounce } from '../../hooks'

const Contributors = () => {
  const [contributors, setContributors] = useState([])
  const [areaTypeIds, setAreaTypeIds] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState(false)
  const [employee, setEmployee] = useState([])
  const [params, setParams] = useState({
    general_filter: '',
    page: 1,
    pageSize: 12
  })

  const { isMobile } = useDevice()

  const { control, watch } = useForm()

  const searchText = watch('search')

  const handleSearch = filters => {
    setParams({ ...params, page: filters.page || 1, general_filter: searchText })
  }

  const handleDelete = colabutor => {
    setModalDeleteIsOpen(true)
    setEmployee(colabutor)
  }

  const handleEdit = colabutor => {
    setModalEditIsOpen(true)
    setEmployee(colabutor)
  }

  const debounceSearchText = useDebounce(searchText, 500)

  useEffect(() => {
    const fetchContributor = async () => {
      try {
        const { data: employees } = await fetchAllEmployees(params)
        setContributors(employees)
        setPagination(employees.pagination)
        const { data: areaType } = await fetchAreaTypeIds()
        setAreaTypeIds(areaType)
        setLoading(false)
      } catch {
        toast.error('Erro ao carregar funcionários')
      } finally {
        setIsLoading(false)
      }
    }

    fetchContributor()
  }, [loading || params])

  useEffect(() => {
    const handleTextSearch = () => {
      setParams({ ...params, general_filter: searchText })
    }
    handleTextSearch()
  }, [debounceSearchText])

  if (isLoading) return <LoadingComponent />

  if (isMobile) {
    return (
      <Column pt={10} mx={3} alignItems='center'>
        <Text variant='h3' color='primary' mt={3}>
          Funcionários
        </Text>
        <Input label='Pesquisar' name='search' control={control} icon={<IconSearch />} my={5} />
        <Button height={40} onClick={() => setModalIsOpen(true)} mb={3}>
          Adicionar novo funcionário
        </Button>
        <Row flexWrap='wrap' justifyContent='center'>
          {contributors.employees.map(item => {
            const role = areaTypeIds.find(role => role.id === item.area_type_id)
            return (
              <div key={item.id}>
                <ContributorCard
                  contributor={item}
                  role={role}
                  loading={() => setLoading(true)}
                  onClick={() => handleDelete(item)}
                  editClick={() => handleEdit(item)}
                />
              </div>
            )
          })}
        </Row>
        <Pagination pagination={pagination} onChangePage={handleSearch} isLoading={false} />
        <ModalAddColabutor
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          areaTypes={areaTypeIds}
          loading={() => setLoading(true)}
        />
        <ModalDeleteEmployee
          open={modalDeleteIsOpen}
          onClose={() => setModalDeleteIsOpen(false)}
          loading={() => setLoading(true)}
          id={employee.id}
        />
        {modalEditIsOpen && (
          <ModalEditEmployee
            open={modalEditIsOpen}
            onClose={() => setModalEditIsOpen(false)}
            employee={employee}
            areaTypes={areaTypeIds}
            loading={() => setLoading(true)}
          />
        )}
      </Column>
    )
  }

  return (
    <Column pt={10} mx={3}>
      <Row justifyContent='space-between'>
        <Column>
          <Text variant='h3' color='primary' mt={3}>
            Funcionários
          </Text>
          <Input label='Pesquisar' name='search' control={control} icon={<IconSearch />} my={5} />
        </Column>
        <Row alignItems='flex-end' mb={5}>
          <Button height={40} onClick={() => setModalIsOpen(true)}>
            Adicionar novo funcionário
          </Button>
        </Row>
      </Row>
      <Row flexWrap='wrap' justifyContent='center'>
        {contributors.employees.map(item => {
          const role = areaTypeIds.find(role => role.id === item.area_type_id)
          return (
            <div key={item.id}>
              <ContributorCard
                contributor={item}
                role={role}
                loading={() => setLoading(true)}
                onClick={() => handleDelete(item)}
                editClick={() => handleEdit(item)}
              />
            </div>
          )
        })}
      </Row>
      <Pagination pagination={pagination} onChangePage={handleSearch} isLoading={false} />
      <ModalAddColabutor
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        areaTypes={areaTypeIds}
        loading={() => setLoading(true)}
      />
      <ModalDeleteEmployee
        open={modalDeleteIsOpen}
        onClose={() => setModalDeleteIsOpen(false)}
        loading={() => setLoading(true)}
        id={employee.id}
      />
      {modalEditIsOpen && (
        <ModalEditEmployee
          open={modalEditIsOpen}
          onClose={() => setModalEditIsOpen(false)}
          employee={employee}
          areaTypes={areaTypeIds}
          loading={() => setLoading(true)}
        />
      )}
    </Column>
  )
}

export default Contributors
