import React from 'react'

import { ModalBase } from '../../../../components/v2Components'
import { Badge } from '../'

import { P } from './style'

const ModalProcessing = ({ open, onClose }) => {
  return (
    <ModalBase
      open={open}
      onClose={onClose}
      title='Status'
      width='100%'
      height='100%'
      minWidth='auto'
      maxWidth='432px'
      iconClose
    >
      <Badge title='Lista principal' status='Lista principal' color='green' />
      <P>
        Você está na lista principal. Ao término da captação, você receberá um e-mail com os dados para realizar a TED /
        Pix. Acompanhe ao lado o status do seu investimento.
      </P>
      <Badge title='Em espera' status='Em espera' color='yellow' />
      <P>
        Você está na lista de espera. Caso necessário, você será comunicado para prosseguir com o investimento.
        Normalmente este contato é feito pela equipe de suporte da Kavod via whatsapp (11 97530-52863).
      </P>
      <Badge title='Cancelada' status='Cancelada' color='red' />
      <P>Pedido cancelado.</P>
    </ModalBase>
  )
}

export default ModalProcessing
