import styled from 'styled-components'
import { Avatar } from '@material-ui/core'
import { IconButton } from '../index'

export const ImageContainer = styled.section`
  border-radius: 56px;
`

export const StyledAvatar = styled(Avatar)`
  &&.MuiAvatar-root {
    width: 112px;
    height: 112px;
  }
`

export const StyledButtonIcon = styled(IconButton)`
  &&.MuiButton-root {
    position: relative;
    top: -25px;
    right: -80px;
  }
`
