import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { updateLeads, createLeadUpdate } from '../../../services/v3'
import { recusedLead } from '../../../schemas'
import useDevice from '../../../hooks/useDevice'

import { ModalBase, Input, Column, Button, Row, Select } from '../index'

const options = [
  {
    value: 'Empresa fora do perfil da Kavod',
    label: 'Empresa fora do perfil da Kavod'
  },
  {
    value: 'Setor de atuação não operado pela Kavod',
    label: 'Setor de atuação não operado pela Kavod'
  },
  {
    value: 'Sócios com patrimônio declarado (IR) de valor inexpressivo',
    label: 'Sócios com patrimônio declarado (IR) de valor inexpressivo'
  },
  {
    value: 'Empresa com situação econômica-financeira desfavorável',
    label: 'Empresa com situação econômica-financeira desfavorável'
  },
  {
    value: 'Restrições relevantes/default na Serasa',
    label: 'Restrições relevantes/default na Serasa'
  },
  {
    value: 'Não forneceu dados cadastrais e financeiros suficientes para avaliação do crédito',
    label: 'Não forneceu dados cadastrais e financeiros suficientes para avaliação do crédito'
  },
  {
    value: 'Outros',
    label: 'Outros'
  }
]

const ModalRecused = ({ open, buttonClick, handleClose, id, oldStatus, addInTimeline, setStatus }) => {
  const {
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: recusedLead
  })

  const { isMobile } = useDevice()

  const onSubmit = async values => {
    try {
      const body = {
        reason: values.reason,
        observation: values.observation,
        lead_id: id,
        lead_update_status_id: 4,
        is_visible_to_partner: true,
        new_lead_status_id: 11,
        old_lead_status_id: oldStatus
      }
      const { data } = await createLeadUpdate(body)
      await updateLeads(id, { lead_status_id: 11 })
      addInTimeline(data)
      setStatus(11)
      document.location.reload(false)
      toast.success('Registro adicionado.')
    } catch {
      toast.error('Ocorreu um erro ao criar o resgistro.')
    } finally {
      handleClose()
    }
  }

  return (
    <ModalBase open={open} onClose={handleClose} height={430} width={isMobile ? 300 : 526} title='Motivos para negação'>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Column justifyContent='space-around' height='100%'>
          <Select control={control} name='reason' options={options} error={errors.reason} />
          <Input name='observation' control={control} multiline rows={6} error={errors.observation} />
          <Row>
            <Button height={40} width={227} onClick={buttonClick} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting}>
              Enviar
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalRecused
