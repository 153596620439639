import React, { useEffect, useContext, useState, useMemo } from 'react'
import Drawer from '@material-ui/core/Drawer'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { LeadContext } from '../../contexts'
import { DateRange } from 'react-date-range'
import { pt } from 'react-date-range/dist/locale'
import { format, parse } from 'date-fns'

import { NumberInput } from '../../components/FormComponents'
import { Column, Button, Select, AutocompleteMulti } from '../v2Components'

import { HR } from './style'

import { formatValueToBrl, formatCurrencyToBack } from '../../helpers'

const options = [
  { value: '10', label: '10 itens' },
  { value: '25', label: '25 itens' },
  { value: '50', label: '50 itens' },
  { value: 'all', label: 'Todos' }
]

const DrawerFiltersLeads = ({
  isOpen,
  setIsOpen,
  pageSize,
  maxPageSize,
  params,
  leadStatuses,
  clearFilters,
  origins
}) => {
  const { handleSubmit, errors, control, reset, setValue, watch } = useForm({})

  const page_size = watch('page_size')

  const { search, indicatorsOptions, partnersOptions, responsibles } = useContext(LeadContext)

  const allIndicateOptions = useMemo(() => {
    var all = [...indicatorsOptions, ...partnersOptions]
      .filter(item => item.label !== '')
      .sort((a, b) => a.label.localeCompare(b.label))

    const objectMap = all.reduce((map, object) => {
      map.set(object.label, object)
      return map
    }, new Map())

    return Array.from(objectMap, ([_, value]) => value)
  }, [indicatorsOptions, partnersOptions])

  const allOrigins = useMemo(() => {
    return origins.map(item => {
      return { value: item.name, label: item.name }
    })
  }, [origins])

  const [load, setLoad] = useState(false)
  const [filterDate, setFilterDate] = useState(false)
  const [statusFilter, setStatusFilter] = useState([])
  const [originFilter, setOriginFilter] = useState([])
  const [indicateFilter, setIndicateFilter] = useState([])
  const [responsibleFilter, setResponsibleFilter] = useState([])
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const handleClear = () => {
    clearFilters()
    setSelectedDates([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
    setFilterDate(false)
    setValue('final_value', null)
  }

  useEffect(() => {
    let filteredStatus = leadStatuses.filter(item => params.statuses.includes(item.name))
    const statusNow = filteredStatus.map(item => {
      return { value: item.name, label: item.name }
    })
    setStatusFilter(statusNow)

    let filteredOrigins = origins.filter(item => params.origin_name.includes(item.name))
    const originsNow = filteredOrigins.map(item => {
      return { value: item.name, label: item.name }
    })
    setOriginFilter(originsNow)

    var all = [...indicatorsOptions, ...partnersOptions]
      .filter(item => item.label !== '')
      .sort((a, b) => a.label.localeCompare(b.label))

    const objectMap = all.reduce((map, object) => {
      map.set(object.label, object)
      return map
    }, new Map())

    const allIndicate = Array.from(objectMap, ([_, value]) => value)

    let filteredIndicate = allIndicate.filter(item => params.indicated_by.includes(item.label))
    const indicateNow = filteredIndicate.map(item => {
      return { value: item.label, label: item.label }
    })

    setIndicateFilter(indicateNow)

    let filteredResponsibles = responsibles.filter(item => params.responsible.includes(item))
    const responsiblesNow = filteredResponsibles.map(item => {
      return { value: item, label: item }
    })

    setResponsibleFilter(responsiblesNow)

    const hasOption = options.some(option => option.value === String(pageSize))

    setSelectedDates([
      {
        startDate: params.start_date ? parse(params.start_date, 'yyyy-MM-dd', new Date()) : new Date(),
        endDate: params.end_date ? parse(params.end_date, 'yyyy-MM-dd', new Date()) : new Date(),
        key: 'selection'
      }
    ])

    reset({
      page_size: hasOption ? String(pageSize) : 'all',
      initial_value: formatValueToBrl(params.lending_amount_min),
      final_value:
        formatValueToBrl(params.lending_amount_max) !== 'R$ 0,00' ? formatValueToBrl(params.lending_amount_max) : null,
      status: statusNow,
      origin: originsNow,
      indicate_by: filteredIndicate,
      responsible: responsiblesNow
    })
    setLoad(true)
  }, [isOpen, pageSize, params, origins, indicatorsOptions, partnersOptions, responsibles])

  const onSubmit = async values => {
    const newStatus =
      values.status !== undefined && values.status.length > 0 ? values.status.map(item => item.value) : []

    const newOrigins =
      values.origin !== undefined && values.origin.length > 0 ? values.origin.map(item => item.value) : []

    const newIndicatedBy =
      values.indicate_by !== undefined && values.indicate_by.length > 0
        ? values.indicate_by.map(item => item.label)
        : []

    const newResponsibles =
      values.responsible !== undefined && values.responsible.length > 0
        ? values.responsible.map(item => item.value)
        : []

    const data = {
      pageSize: values.page_size === 'all' ? Number(maxPageSize) : Number(values.page_size),
      statuses: newStatus,
      origin_name: newOrigins,
      indicated_by: newIndicatedBy,
      responsible: newResponsibles
    }

    if (values.final_value !== null) {
      if (formatCurrencyToBack(values.initial_value) < formatCurrencyToBack(values.final_value)) {
        data.lending_amount_min = formatCurrencyToBack(values.initial_value)
        data.lending_amount_max = formatCurrencyToBack(values.final_value)
      } else {
        toast.error('Valor máximo menor que o valor mínimo.')
      }
    }

    if (filterDate) {
      const [{ startDate, endDate }] = selectedDates
      data.start_date = format(startDate, 'yyyy-MM-dd')
      data.end_date = format(endDate, 'yyyy-MM-dd')
    }

    search(data)
    setIsOpen(false)
  }

  if (!load) {
    return <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)} />
  }

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => setIsOpen(false)}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Column>
          <DivButtons>
            <Button
              width='100%'
              type='reset'
              onClick={() => {
                handleClear()
              }}
              variant='outlined'
            >
              Limpar
            </Button>
            <Button type='submit' width='100%'>
              Filtrar
            </Button>
          </DivButtons>
          <div>
            <Select
              name='page_size'
              label='Itens por página'
              control={control}
              options={options}
              error={errors['page_size']}
              defaultValue={page_size}
              mb='10px'
              mt='50px'
            />
          </div>
          <HR />
          <Title>Status: </Title>
          <AutocompleteMulti
            error={errors.status}
            name='status'
            disableCloseOnSelect={true}
            options={leadStatuses.map(item => {
              return { value: item.name, label: item.name }
            })}
            defaultValue={statusFilter}
            control={control}
            label='Status:'
            multiple
            creatable={false}
            width='100%'
            maxWidth='333px'
            mt='10px'
            mb='10px'
          />
          <HR />

          <Title>Origem: </Title>
          <AutocompleteMulti
            error={errors.origin}
            name='origin'
            disableCloseOnSelect={true}
            options={allOrigins}
            defaultValue={originFilter}
            control={control}
            label='Origem'
            multiple
            creatable={false}
            width='100%'
            maxWidth='333px'
            mt='10px'
            mb='10px'
          />
          <HR />

          <Title>Indicado Por: </Title>
          <AutocompleteMulti
            error={errors.indicate_by}
            name='indicate_by'
            disableCloseOnSelect={true}
            options={allIndicateOptions}
            defaultValue={indicateFilter}
            control={control}
            label='Indicado Por'
            multiple
            creatable={false}
            width='100%'
            maxWidth='333px'
            mt='10px'
            mb='10px'
          />
          <HR />

          <Title>Responsável: </Title>
          <AutocompleteMulti
            error={errors.responsible}
            name='responsible'
            disableCloseOnSelect={true}
            options={responsibles.map(item => {
              return { value: item, label: item }
            })}
            defaultValue={responsibleFilter}
            control={control}
            label='Responsável'
            multiple
            creatable={false}
            width='100%'
            maxWidth='333px'
            mt='10px'
            mb='10px'
          />
          <HR />

          <Title style={{ marginTop: '10px' }}>Busca por valor do empréstimo: </Title>
          <NumberInput
            name='initial_value'
            control={control}
            label='De'
            error={errors.initial_value}
            thousandSeparator='.'
            type='currency'
          />
          <NumberInput
            name='final_value'
            control={control}
            label='Até'
            error={errors.final_value}
            thousandSeparator='.'
            type='currency'
          />
          <HR style={{ marginTop: '10px' }} />
          <Title style={{ marginTop: '10px' }}>Busca por data: </Title>
          <DateRange
            editableDateInputs={false}
            onChange={item => {
              setSelectedDates([item.selection])
              setFilterDate(true)
            }}
            moveRangeOnFirstSelection={false}
            ranges={selectedDates}
            locale={pt}
            weekStartsOn={0}
          />
        </Column>
      </Form>
    </Drawer>
  )
}

const Form = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424242;
`
const DivButtons = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  max-width: 333px;
  padding-bottom: 10px;
  padding-top: 10px;
  gap: 10px;
  top: 0px;

  background: white;
  z-index: 100000;
`

export default DrawerFiltersLeads
