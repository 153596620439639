import styled from 'styled-components'

export const DrawerTitle = styled.h1`
  margin-left: 25px;
  font-size: 20px;
  font-weight: 600;
  color: #424242;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`

export const Content = styled.div`
  position: relative;
  margin-top: 50px;
  width: 100%;

  div {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding: 12px;
    gap: 10px;

    &:nth-of-type(odd) {
      background-color: #fafafa;
    }

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #424242;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #424242;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`

export const CarouselController = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-wrap: nowrap;
  }

  @media (max-width: 500px) {
    div {
      display: none;
    }
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #424242;
  }

  button {
    margin: 0 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
export const ResponsiveController = styled.div`
  display: none;

  div {
    display: flex;
    flex-wrap: nowrap;
  }

  @media (max-width: 500px) {
    margin-top: 25px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #424242;
  }

  button {
    margin: 0 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
