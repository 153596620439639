import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { TimelineSeparator } from '@material-ui/lab'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useForm } from 'react-hook-form'
import moment from 'moment-timezone'

import { leadStatusBgColors, leadSatusFontColor, leadStatusBorderColors } from '../../../styles'
import { Column, Text, Row, Button, Select, ProposalTimeline } from '../index'
import {
  TimelineMaterial,
  StyledTimelineItem,
  StyledTimelineDot,
  StyledTimelineConnector,
  StyledTimelineContent,
  TypeTitle,
  StyledAvatar,
  Status,
  Image,
  AcceptColumn,
  RejectedColumn,
  TitleText,
  TextMensageVisible,
  Download,
  DivDonwload,
  Observation,
  StyledIconCheck,
  StyledIconClear
} from './styles'
import {
  IconSpeakerNotes,
  IconPhone,
  IconAssignment,
  IconArrowRightAlt,
  IconUpdate,
  IconMonetization,
  IconEdit,
  IconDelete,
  IconSendDocumentOutlined,
  IconDeleteTimeline
} from '../icons'

import { ModalAcceptProposal, ModalRefuseProposal, ModalFormalizeProposal } from './components'
import { ModalEditRegister, ModalDeleteRegister, IconButton } from '../../../components/v2Components'

import { getLeadDocumentUrl } from '../../../services/v3'

const Timeline = ({
  leadStatuses,
  leadUpdate,
  onClick,
  isFranchisor,
  editInTimeline,
  deleteInTimeLine,
  isAdministrator,
  responsibles,
  state
}) => {
  const [leadsUpdate, setLeadsUpdate] = useState(leadUpdate)

  const [modalEditIsOpen, setModalEditIsOpen] = useState(undefined)
  const [modalDeleteOpen, setModalDeleteIsOpen] = useState(undefined)
  const [modalAcceptIsOpen, setModalAcceptIsOpen] = useState(undefined)
  const [modalRefuseOpen, setModalRefuseIsOpen] = useState(undefined)
  const [modalFormalizeOpen, setModalFormalizeOpen] = useState(undefined)

  let varData = ''

  const data = useMemo(() => {
    const array = [
      {
        label: 'Todos',
        value: 'all'
      },
      {
        label: 'Coméntario',
        value: '1'
      },
      {
        label: 'Ligação',
        value: '2'
      },
      {
        label: 'Notas do comitê',
        value: '3'
      },
      {
        label: 'Alteração de status',
        value: '4'
      },
      {
        label: 'Proposta',
        value: '5'
      },
      {
        label: 'Documento enviado',
        value: '8'
      }
    ]

    function arrayUnique(array) {
      var a = array.concat()
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i].value === a[j].value) a.splice(j--, 1)
        }
      }
      return a
    }

    if (responsibles.length > 0) {
      var arrayResp = leadUpdate.map(lead => {
        var findResp = responsibles.find(resp => resp.user_id === lead.user_id)

        return !!findResp
          ? { label: findResp.name, value: findResp.user_id }
          : { label: 'Usuario não funcionário', value: lead.user_id }
      })

      var resposible = arrayUnique(arrayResp.flat())
      return arrayUnique(array.concat(resposible))
    }

    return []
  }, [leadUpdate, responsibles])

  const { control, watch } = useForm()

  const filter = watch('filter', 'all')

  const handleSetLeadsFilter = () => {
    filter === 'all'
      ? setLeadsUpdate(leadUpdate)
      : Number(filter) > 6
      ? setLeadsUpdate(leadUpdate.filter(item => item.user_id === Number(filter)))
      : setLeadsUpdate(leadUpdate.filter(item => item.lead_update_status_id === Number(filter)))
  }

  const handleShowDocument = useCallback(async doc => {
    try {
      const { data: document } = await getLeadDocumentUrl(doc.lead_id, doc.id)
      window.open(document.url, '_blank')
    } catch (error) {}
  }, [])

  useEffect(() => {
    handleSetLeadsFilter()
  }, [filter])

  useEffect(() => {
    setLeadsUpdate(leadUpdate)
  }, [leadUpdate])

  return (
    <Column mt={4}>
      <Text variant='h4' color='grey.900' ml='8%'>
        Histórico
      </Text>
      <Row justifyContent='space-between' mt={4} mb={3}>
        <Select
          id='filter'
          name='filter'
          label='Mostrar'
          control={control}
          options={data}
          width={180}
          defaultValue='all'
          ml='8%'
        />

        <Button variant='text' onClick={onClick}>
          Adicionar registro
        </Button>
      </Row>
      <ModalEditRegister
        item={modalEditIsOpen}
        handleClose={() => setModalEditIsOpen(undefined)}
        buttonClick={() => setModalEditIsOpen(undefined)}
        editInTimeline={editInTimeline}
      />
      <ModalDeleteRegister
        item={modalDeleteOpen}
        handleClose={() => setModalDeleteIsOpen(undefined)}
        deleteInTimeLine={deleteInTimeLine}
      />
      <ModalAcceptProposal open={modalAcceptIsOpen} handleClose={() => setModalAcceptIsOpen(undefined)} />
      <ModalRefuseProposal useProposal open={modalRefuseOpen} handleClose={() => setModalRefuseIsOpen(undefined)} />
      <ModalFormalizeProposal open={modalFormalizeOpen} handleClose={() => setModalFormalizeOpen(undefined)} />
      <TimelineMaterial>
        {leadsUpdate.map((item, index) => {
          const avatar = (item.user && item.user.employee && item.user.employee.s3_name) || ''

          let changeData = false

          if (varData === format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })) {
            changeData = false
          } else {
            changeData = true
            varData = format(parseISO(item.created_at), "dd 'de' LLLL ", { locale: ptBR })
          }

          if (item.lead_update_status_id === 1) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot>
                    <IconSpeakerNotes />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <Text variant='subtitle2'>Comentário</Text>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Observation dangerouslySetInnerHTML={{ __html: item.observation }} />
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconEdit fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalEditIsOpen(item)
                            }}
                            mr={1}
                          />
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 2) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot>
                    <IconPhone />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <TypeTitle variant='subtitle2'>Ligação</TypeTitle>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Observation dangerouslySetInnerHTML={{ __html: item.observation }} />
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconEdit fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalEditIsOpen(item)
                            }}
                            mr={1}
                          />
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 3) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot>
                    <IconAssignment />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <TypeTitle variant='subtitle2'>Notas do comitê</TypeTitle>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Observation dangerouslySetInnerHTML={{ __html: item.observation }} />
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconEdit fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalEditIsOpen(item)
                            }}
                            mr={1}
                          />
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 4 && item.new_lead_status_id !== 11) {
            const leadOldStatusName = !!item.old_lead_status_id
              ? leadStatuses[item.old_lead_status_id - 1].name
              : 'Novo'
            const leadNewStatusName = leadStatuses[item.new_lead_status_id - 1].name
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot status={leadNewStatusName}>
                    <IconUpdate />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <TypeTitle variant='subtitle2'>Alteração de status</TypeTitle>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Row flexDirection='column' flexWrap='wrap' alignItems='flex-start'>
                        <Status
                          bgColor={leadStatusBgColors[leadOldStatusName] || '#2F80ED'}
                          fontColor={leadSatusFontColor[leadOldStatusName] || '#ffffff'}
                          borderColor={leadStatusBorderColors[leadOldStatusName] || ''}
                        >
                          {leadOldStatusName}
                        </Status>
                        <IconArrowRightAlt style={{ marginLeft: '105px', transform: 'rotate(90deg)' }} />
                        <Status
                          bgColor={leadStatusBgColors[leadNewStatusName] || '#2F80ED'}
                          fontColor={leadSatusFontColor[leadNewStatusName] || '#ffffff'}
                          borderColor={leadStatusBorderColors[leadNewStatusName] || ''}
                        >
                          {leadNewStatusName}
                        </Status>
                      </Row>
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 5) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot bgcolor='#4AB34E'>
                    <IconMonetization />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <DivDonwload>
                          <TypeTitle variant='subtitle2'>
                            {!!item.proposal && !item.proposal.is_simulation ? 'Proposta' : 'Simulação'}
                          </TypeTitle>
                          {!item.proposal ? (
                            <Download>
                              (
                              <a href={item.s3_name} target='_blank' rel='noopener noreferrer'>
                                Vizualizar
                              </a>
                              )
                            </Download>
                          ) : (
                            ''
                          )}
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </DivDonwload>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Column>
                        {!!item.proposal ? (
                          <ProposalTimeline data={{ ...item.proposal, cnpj_name: state.company_name }} />
                        ) : (
                          <Image src={item.s3_name} />
                        )}
                      </Column>
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {!!item.proposal && !item.proposal.is_simulation ? (
                        <>
                          {isAdministrator && (
                            <Row justifyContent='center' mt='16px'>
                              <Button
                                variant='outlined'
                                style={{ color: '#FD4646', borderColor: '#FD4646' }}
                                width='50%'
                                maxwidth={245}
                                mr='24px'
                                onClick={() => setModalRefuseIsOpen(item)}
                              >
                                <StyledIconClear />
                                Recusar
                              </Button>
                              <Button
                                variant='outlined'
                                style={{ color: '#4AB34E', borderColor: '#4AB34E' }}
                                width='50%'
                                maxwidth={245}
                                onClick={() => setModalAcceptIsOpen(item)}
                              >
                                <StyledIconCheck />
                                Aceitar
                              </Button>
                            </Row>
                          )}
                        </>
                      ) : (
                        <Row justifyContent='center' mt='16px'>
                          <Button
                            variant='outlined'
                            color='primary'
                            width='50%'
                            maxwidth={245}
                            onClick={() => setModalFormalizeOpen(item)}
                          >
                            <StyledIconCheck />
                            Formalizar
                          </Button>
                        </Row>
                      )}

                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 6) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot bgcolor='#4AB34E'>
                    <IconMonetization />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <AcceptColumn bgcolor='#4AB34E' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <DivDonwload>
                          <TypeTitle variant='subtitle2'>Proposta aceita</TypeTitle>
                          {!item.proposal ? (
                            <Download>
                              (
                              <a href={item.s3_name} target='_blank' rel='noopener noreferrer'>
                                Vizualizar
                              </a>
                              )
                            </Download>
                          ) : (
                            ''
                          )}
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </DivDonwload>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Row flexDirection='column' mb='10px'>
                        <TitleText>Descrição</TitleText>
                        <Observation dangerouslySetInnerHTML={{ __html: item.observation }} />
                      </Row>
                      <Column mb='8px'>
                        {!!item.proposal ? (
                          <ProposalTimeline data={{ ...item.proposal, cnpj_name: state.company_name }} />
                        ) : (
                          <Image src={item.s3_name} />
                        )}
                      </Column>
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                    </AcceptColumn>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 4 && item.new_lead_status_id === 11) {
            const leadOldStatusName = !!item.old_lead_status_id
              ? leadStatuses[item.old_lead_status_id - 1].name
              : 'Novo'
            const leadNewStatusName = leadStatuses[item.new_lead_status_id - 1].name
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot status={leadNewStatusName}>
                    <IconUpdate />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <TypeTitle variant='subtitle2'>Alteração de status</TypeTitle>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Row flexDirection='column' flexWrap='wrap' alignItems='flex-start'>
                        <Status
                          bgColor={leadStatusBgColors[leadOldStatusName] || '#2F80ED'}
                          fontColor={leadSatusFontColor[leadOldStatusName] || '#ffffff'}
                          borderColor={leadStatusBorderColors[leadOldStatusName] || ''}
                        >
                          {leadOldStatusName}
                        </Status>
                        <IconArrowRightAlt style={{ marginLeft: '105px', transform: 'rotate(90deg)' }} />
                        <Status
                          bgColor={leadStatusBgColors[leadNewStatusName] || '#2F80ED'}
                          fontColor={leadSatusFontColor[leadNewStatusName] || '#ffffff'}
                          borderColor={leadStatusBorderColors[leadNewStatusName] || ''}
                        >
                          {leadNewStatusName}
                        </Status>
                      </Row>
                      <TitleText>Motivo</TitleText>
                      <Text variant='subtitle2'>{item.reason}</Text>
                      {item.observation && (
                        <>
                          <TitleText>Observação</TitleText>
                          <Text variant='subtitle2'>{item.observation}</Text>
                        </>
                      )}
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 7) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot bgcolor='#FD4646'>
                    <IconMonetization />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <RejectedColumn borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <DivDonwload>
                          <TypeTitle variant='subtitle2'>Proposta recusada</TypeTitle>
                          {!item.proposal ? (
                            <Download>
                              (
                              <a href={item.s3_name} target='_blank' rel='noopener noreferrer'>
                                Vizualizar
                              </a>
                              )
                            </Download>
                          ) : (
                            ''
                          )}
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </DivDonwload>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Row flexDirection='column' mb='10px'>
                        <TitleText>Descrição</TitleText>
                        <Observation dangerouslySetInnerHTML={{ __html: item.observation }} />
                      </Row>
                      <Column mb='8px'>
                        {!!item.proposal ? (
                          <ProposalTimeline data={{ ...item.proposal, cnpj_name: state.company_name }} />
                        ) : (
                          <Image src={item.s3_name} />
                        )}
                      </Column>
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                    </RejectedColumn>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 8) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot>
                    <IconSendDocumentOutlined />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <Text variant='subtitle2'>
                            {item.lead_documents.length > 1 ? 'Documentos enviados' : 'Documento enviado'}
                          </Text>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Text variant='subtitle2'>
                        {`Total enviado: ${item.lead_documents.length} ${
                          item.lead_documents.length > 1 ? 'arquivos' : 'arquivo'
                        }`}
                      </Text>
                      <ul style={{ paddingLeft: '18px', paddingBottom: '8px' }}>
                        {item.lead_documents
                          .sort((a, b) => a.filename.localeCompare(b.filename))
                          .filter(doc => doc.approved !== 0)
                          .map(doc => (
                            <li>
                              {doc.deleted_at ? (
                                <Text variant='body1' mr='10px' style={{ color: '#757575' }}>
                                  {doc.filename || 'Documento sem nome'}
                                </Text>
                              ) : (
                                <Text
                                  variant='body1'
                                  mr='10px'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleShowDocument(doc)}
                                  color='primary'
                                >
                                  {doc.filename || 'Documento sem nome'}
                                </Text>
                              )}
                            </li>
                          ))}
                      </ul>
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 9) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot bgcolor='#FD4646'>
                    <IconSendDocumentOutlined />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <Text variant='subtitle2'>Documento deletado</Text>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <ul style={{ paddingLeft: '18px', paddingBottom: '8px' }}>
                        <li>
                          <Text variant='body1' mr='10px' style={{ color: '#757575' }}>
                            {(item.lead_document_deleted && item.lead_document_deleted.filename) ||
                              'Documento sem nome'}
                          </Text>
                        </li>
                      </ul>
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 10) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot bgcolor='#FD4646'>
                    <IconSendDocumentOutlined />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <Column bgcolor='gray.100' borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <Row>
                          <Text variant='subtitle2'>Documento recusado</Text>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </Row>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <ul style={{ paddingLeft: '18px', paddingBottom: '8px' }}>
                        {item.lead_documents
                          .sort((a, b) => a.filename.localeCompare(b.filename))
                          .map(doc => (
                            <li>
                              {doc.deleted_at ? (
                                <Text variant='body1' mr='10px' style={{ color: '#757575' }}>
                                  {doc.filename || 'Documento sem nome'}
                                </Text>
                              ) : (
                                <Text
                                  variant='body1'
                                  mr='10px'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleShowDocument(doc)}
                                  color='primary'
                                >
                                  {doc.filename || 'Documento sem nome'}
                                </Text>
                              )}
                            </li>
                          ))}
                      </ul>
                      {item.reason && (
                        <>
                          <TitleText>Motivo</TitleText>
                          <Text variant='subtitle2'>{item.reason}</Text>
                        </>
                      )}
                      {!!item.is_visible_to_partner && (
                        <TextMensageVisible mt={1}>Mensagem vísivel para parceiro</TextMensageVisible>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </Column>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          if (item.lead_update_status_id === 11) {
            return (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <StyledTimelineDot bgcolor='#FD4646'>
                    <IconDeleteTimeline />
                  </StyledTimelineDot>
                  <StyledTimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Column alignItems='center'>
                    {changeData && (
                      <Row
                        height={24}
                        bgcolor='#3DC6EF'
                        borderRadius={16}
                        mb='24px'
                        mt='12px'
                        width='max-content'
                        px={1}
                      >
                        <Text>{format(parseISO(item.created_at), "dd 'de' LLLL 'de' yyyy", { locale: ptBR })}</Text>
                      </Row>
                    )}

                    <RejectedColumn borderRadius={10} padding={1} width='100%'>
                      <Row justifyContent='space-between' mb={1}>
                        <DivDonwload>
                          <TypeTitle variant='subtitle2'>Campanha deletada</TypeTitle>
                          <Text marginLeft={1}>
                            {moment(item.created_at)
                              .add(3, 'hours')
                              .format('HH:mm')
                              .replace(':', 'h')}
                          </Text>
                        </DivDonwload>
                        <Row>
                          <StyledAvatar src={avatar} />
                        </Row>
                      </Row>
                      <Row flexDirection='column' mb='10px'>
                        <TitleText>Campanha</TitleText>
                        <Text variant='subtitle2'>{item.observation}</Text>
                      </Row>
                      {item.reason && (
                        <Row flexDirection='column' mb='10px'>
                          <TitleText>Motivo</TitleText>
                          <Text variant='subtitle2'>{item.reason}</Text>
                        </Row>
                      )}
                      {isAdministrator && (
                        <Row justifyContent='flex-end' mt='10px' width='101%'>
                          <IconButton
                            icon={<IconDelete fontSize='small' />}
                            variant='text'
                            onClick={() => {
                              setModalDeleteIsOpen(item)
                            }}
                          />
                        </Row>
                      )}
                    </RejectedColumn>
                  </Column>
                </StyledTimelineContent>
              </StyledTimelineItem>
            )
          }

          return <></>
        })}
      </TimelineMaterial>
    </Column>
  )
}

export default Timeline
