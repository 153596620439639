import styled from '@emotion/styled'

export const Accordion = styled.div`
  width: 90%;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
`
