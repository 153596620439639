import React, { useCallback } from 'react'
import { toast } from 'react-toastify'

import { Badge, Option, ProgressBar } from '../../../components'
import { DelayedFlag, ExecutionFlag } from '../index'

import { Header, InfoCard, Content, Details, Container, HeaderResponsive, HeaderTablet } from './styles'

import { formatCurrency, formatTax } from '../../../../../helpers'

import { getMyInvestmentsShowCSV } from '../../../../../services/v3'

const Card = ({ overview, id, investorId }) => {
  const handleExportCSV = useCallback(() => {
    const fetchCSV = async () => {
      try {
        const { data: response } = await getMyInvestmentsShowCSV(Number(id), investorId, {
          responseType: 'arraybuffer'
        })
        const element = document.createElement('a')
        element.href = URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        )
        element.download = `${overview.campaignName}.xlsx`
        element.click()
      } catch (error) {
        console.log(error)
        toast.error('Ocorreu um erro ao exportar a listagem em excel')
      }
    }

    fetchCSV()
  }, [investorId])

  return (
    <Container>
      <Header>
        <div>
          <h1>{overview.campaignName}</h1>
          <Badge
            status={overview.campaignStatus}
            color={
              overview.campaignStatus === 'Adimplente'
                ? 'blue'
                : overview.campaignStatus === 'Concluído'
                ? 'green'
                : overview.campaignStatus === 'Atrasado'
                ? 'yellow'
                : overview.campaignStatus === 'Inadimplente'
                ? 'red'
                : null
            }
          />
          <span style={{ display: 'flex' }}>
            {!!overview.isRenegotiated === true && <DelayedFlag />}
            {(overview.campaignState.id === 2 || overview.campaignState.id === 3) && (
              <ExecutionFlag title={overview.campaignState.name} />
            )}
          </span>
        </div>
        <Option name='Exportar' imgUrl='/assets/images/export.svg' onPress={handleExportCSV} />
      </Header>

      <HeaderResponsive>
        <h1>{overview.campaignName}</h1>
        <div style={{ justifyContent: 'space-between', marginTop: 32, marginBottom: 24 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              status={overview.campaignStatus}
              color={
                overview.campaignStatus === 'Adimplente'
                  ? 'blue'
                  : overview.campaignStatus === 'Concluído'
                  ? 'green'
                  : overview.campaignStatus === 'Atrasado'
                  ? 'yellow'
                  : overview.campaignStatus === 'Inadimplente'
                  ? 'red'
                  : null
              }
            />
            <span style={{ display: 'flex' }}>
              {!!overview.isRenegotiated === true && <DelayedFlag />}
              {(overview.campaignState.id === 2 || overview.campaignState.id === 3) && (
                <ExecutionFlag title={overview.campaignState.name} />
              )}
            </span>
          </div>
          <Option name='Exportar' imgUrl='/assets/images/export.svg' onPress={handleExportCSV} />
        </div>
      </HeaderResponsive>

      <HeaderTablet>
        <h1>{overview.campaignName}</h1>
        <div>
          <Badge
            status={overview.campaignStatus}
            color={
              overview.campaignStatus === 'Adimplente'
                ? 'blue'
                : overview.campaignStatus === 'Concluído'
                ? 'green'
                : overview.campaignStatus === 'Atrasado'
                ? 'yellow'
                : overview.campaignStatus === 'Inadimplente'
                ? 'red'
                : null
            }
          />
          <span style={{ display: 'flex' }}>
            {!!overview.isRenegotiated === true && <DelayedFlag />}
            {(overview.campaignState.id === 2 || overview.campaignState.id === 3) && (
              <ExecutionFlag title={overview.campaignState.name} />
            )}
          </span>
        </div>
      </HeaderTablet>

      <Content>
        <InfoCard>
          <p>Valor do investimento</p>
          <label>{formatCurrency(overview.investmentAmount)}</label>

          <section>
            <div>
              <p>Total recebido</p>
              <p>Total a receber</p>
            </div>
          </section>

          <div>
            <ProgressBar
              initialValue={overview.totalReceivedPerCampaign}
              finalValue={overview.remainingToReceivePerCampaign}
              thinbar={true}
              value={
                (overview.totalReceivedPerCampaign * 100) /
                (overview.remainingToReceivePerCampaign + overview.totalReceivedPerCampaign)
              }
            />
          </div>
        </InfoCard>

        <Details>
          <div>
            <p>Retorno bruto</p>
            <label>{formatCurrency(overview.grossAmount)}</label>
          </div>
          <div>
            <p>Retorno líquido</p>
            <label>{formatCurrency(overview.netAmount)}</label>
          </div>
          <div>
            <p>Ganho real líquido</p>
            <label>{formatCurrency(overview.valueReturn)}</label>
          </div>
          <div>
            <p>TIR</p>
            <label>{formatTax(overview.internalRateReturn)}</label>
          </div>
          <div>
            <p>Taxa mensal / anual</p>
            <label>
              {formatTax(overview.campaignRateMonth)} / {formatTax(overview.campaignRateAnual)}
            </label>
          </div>
          <div>
            <p>Aumento de patrimônio</p>
            <label>{formatTax(overview.percentualReturn)}</label>
          </div>
        </Details>
      </Content>
    </Container>
  )
}

export default Card
