import React, { useState } from 'react'
import styled from '@emotion/styled'
import { CheckCircle, CheckBox, RemoveCircle } from '@material-ui/icons'

import { formatMoney } from '../../helpers'

const ParcelSimulatorCard = ({ parcel, confirmAmount, deleteAmount }) => {
  const [isChecked, setIsChecked] = useState(false)

  const approveOrder = () => {
    if (!isChecked) {
      setIsChecked(true)
      confirmAmount(parcel)
    }
  }

  const removeOrder = () => {
    deleteAmount(parcel)
  }

  return (
    <Container>
      {isChecked && <Checked />}
      <h1>
        <b> PARCELA {formatMoney(parcel.value)} </b>
      </h1>
      <p>CET Anual: {parcel.PercCETAnual} %</p>
      <p>CET Mensal: {parcel.PercCETMensal} %</p>
      <p>Juros Anual: {parcel.PercJurosAnual} %</p>
      <p>Juros Mensal: {parcel.PercJurosMensal} %</p>
      <p>Valor Boleto: R$ {formatMoney(parcel.VlrBoleto)}</p>
      <p>IOF: R$ {formatMoney(parcel.VlrIOF)}</p>
      <p>Parcela: R$ {formatMoney(parcel.VlrParcela)}</p>
      <p>Seguro: R$ {formatMoney(parcel.VlrSeguro)}</p>
      <p>TAC: R$ {formatMoney(parcel.VlrTAC)}</p>
      <p>Valor total do crédito: R$ {formatMoney(parcel.VlrTotalCredito)}</p>
      <p>Valor total da dívida: R$ {formatMoney(parcel.VlrTotalDivida)}</p>
      <ButtonsContainer>
        <CheckBox
          style={{
            color: isChecked ? 'gray' : '#56ad3c',
            fontSize: '2em',
            cursor: isChecked ? 'not-allowed' : 'pointer'
          }}
          onClick={approveOrder}
        />
        <RemoveCircle onClick={removeOrder} style={{ color: '#ce1616', fontSize: '2em', cursor: 'pointer' }} />
      </ButtonsContainer>
    </Container>
  )
}

const Checked = styled(CheckCircle)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #56ad3c;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 1em 0;
`

const Container = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 306px;
  margin: 0 0.3em;
  border: 1px solid lightgray;
  margin-bottom: 1em;
  h1 {
    font-size: 1em;
  }
  p {
    font-size: 14px;
    line-height: 17px;
  }
`

export default ParcelSimulatorCard
