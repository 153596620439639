export const anticipationTypes = {
  ALL_INSTALLMENTS: 'all_installments',
  ONE_INSTALLMENT: 'one_installment'
}

export const anticipationOptions = [
  { value: anticipationTypes.ALL_INSTALLMENTS, label: 'Total' },
  { value: anticipationTypes.ONE_INSTALLMENT, label: 'Única parcela' }
]

export const anticipationFormDefaultValues = {
  operation_tax: '',
  anticipation_date: null,
  type: '',
  installment_number: ''
}
