import { toast } from 'react-toastify'

export const copyCnpj = cnpj => {
  try {
    if (!!cnpj && cnpj > 1) {
      navigator.clipboard.writeText(cnpj)
      toast.success('CNPJ copiado.')
    } else {
      throw Error('1')
    }
  } catch (error) {
    if (error.message === '1') {
      toast.error('Não possui CNPJ para copiar')
      return
    }
    toast.error('Erro ao copiar CNPJ.')
  }
}
