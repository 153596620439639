import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { FormControlLabel } from '@material-ui/core'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import NumberFormat from 'react-number-format'

import { Dropdown, AutosuggestInput, Button, Checkbox } from '../../components'

import { sanitizeMoney, formatMoney, numbroUnformat, checkIsCompletedInvestor } from '../../helpers'
import { createPledge, updatePledge, getInvestor } from '../../services/v3'

const InputDiv = ({ edit, investorId, setInvestorId, amount, handleAmountChange, pledge }) => {
  return (
    <InputContainer>
      <AutosuggestInput
        value={edit ? `${pledge.investor.first_name} ${pledge.investor.last_name}` : ''}
        investorId={investorId}
        setInvestorId={setInvestorId}
        placeholder='Busque um usuário'
        label='Usuário'
        suggestion='name'
      />
      <ValueInputContainer>
        <Label>Valor </Label>
        <ResponsiveNumberInput
          prefix={'R$ '}
          thousandSeparator={'.'}
          decimalSeparator={','}
          value={amount}
          onChange={handleAmountChange}
          fixedDecimalScale={true}
          decimalScale={2}
        />
      </ValueInputContainer>
    </InputContainer>
  )
}

const InputContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }
`

const ResponsiveNumberInput = styled(NumberFormat)`
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  outline: none;
  padding: 0px 10px;
  background: '#ddd';
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.blueKavod};
    color: #000;
    text-shadow: none;
  }
  @media only screen and (max-width: 500px) {
    height: 32px;
  }
`

const ValueInputContainer = styled.div`
  padding: 4.8px;
  display: flex;
  flex-direction: column;
  width: 90%;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`

const ObservationTextArea = ({ observation, setObservation }) => {
  return (
    <TextareaContainer>
      <Label>Observação </Label>
      <Textarea value={observation} onChange={e => setObservation(e.target.value)} label='Observação' />
    </TextareaContainer>
  )
}

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media only screen and (max-width: 500px) {
    width: 97%;
  }
`

const Label = styled.label`
  color: #7f7f7f;
  padding: 0px 8px;
`

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.blueKavod};
    outline: 0;
  }
`

const StatusDiv = ({ status, setStatus, isEditable }) => {
  const findStatusColor = status => {
    switch (status) {
      case 'A':
        return '#5cb85c'
      case 'AC':
        return '#5cb85c'
      case 'C':
        return '#d9534f'
      case 'OH':
        return '#076F9F'
      case 'D':
        return '#d9534f'
      default:
        break
    }
  }

  const formatStatus = status => {
    switch (status) {
      case 'A':
        return 'Confirmado'
      case 'AC':
        return 'Processado'
      case 'C':
        return 'Cancelado'
      case 'OH':
        return 'Em espera'
      case 'D':
        return 'Deletado'
      default:
        break
    }
  }
  return (
    <div>
      <DropdownTitle>Status do pedido: </DropdownTitle>
      <Dropdown
        disabled={!isEditable}
        titleBg={findStatusColor(status)}
        title={formatStatus(status)}
        list={[
          {
            title: 'Confirmado',
            onClick: () => setStatus('A')
          },
          {
            title: 'Em espera',
            onClick: () => setStatus('OH')
          },
          {
            title: 'Cancelado',
            onClick: () => setStatus('C')
          }
        ]}
      />
    </div>
  )
}

const DropdownTitle = styled.p`
  margin-bottom: 0.3em;
  color: #7f7f7f;
`

const PledgeModal = ({
  title,
  pledge,
  handleClick,
  match,
  pledgeStatuses,
  reload,
  edit,
  campaign,
  onSubmit,
  isEditable = true,
  reopenCampaingModal
}) => {
  const [status, setStatus] = useState(edit ? pledge.pledge_status.abbreviation : 'A')
  const [amount, setAmount] = useState(edit ? pledge.amount : null)
  const [investorId, setInvestorId] = useState(edit ? pledge.investor_id : null)
  const [checked, setChecked] = useState(false)
  const [observation, setObservation] = useState((edit && pledge.observation) || '')
  const [hasProfileComplete, setHasProfileComplete] = useState(true)

  useEffect(() => {
    if (investorId) checkUserProfile()
  }, [investorId])

  const checkUserProfile = async () => {
    try {
      const { data: investor } = await getInvestor(investorId)
      const isCompleted = checkIsCompletedInvestor(investor)
      setHasProfileComplete(isCompleted)
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao buscar o investidor')
    }
  }

  const handleAmountChange = e => setAmount(e.target.value)

  const submitPledge = async () => {
    if (sanitizeMoney(amount) < campaign.min_amount) {
      return toast.error(`O valor da pledge precisa ser maior que ${formatMoney(campaign.min_amount)}`)
    }

    const selectedStatuses = pledgeStatuses.find(({ abbreviation }) => abbreviation === status)

    const body = {
      investor_id: investorId,
      amount: numbroUnformat(amount),
      campaign_id: match.params.id,
      pledge_status_id: selectedStatuses ? selectedStatuses.id : null,
      observation: observation,
      send_email: checked
    }

    try {
      if (edit) {
        const {
          data: { should_campaign_be_active }
        } = await updatePledge(pledge.id, body)
        if (should_campaign_be_active) reopenCampaingModal()
      } else {
        await createPledge(body)
      }
      toast.success(`Pedido ${edit ? 'editado' : 'criado'} com sucesso.`)
    } catch (error) {
      console.error(error)
      toast.error(`Não conseguimos ${edit ? 'editar' : 'criar'} seu pedido.`)
    }

    reload()
    handleClick()
    if (onSubmit) onSubmit()
  }

  return (
    <Container>
      <Modal>
        {!hasProfileComplete && investorId && <HasProfileCompleted>Cadastro incompleto.</HasProfileCompleted>}
        <ModalTitle>{title}</ModalTitle>
        <InputDiv
          edit={edit}
          investorId={investorId}
          setInvestorId={setInvestorId}
          amount={amount}
          handleAmountChange={handleAmountChange}
          pledge={pledge}
        />
        <ObservationTextArea observation={observation} setObservation={setObservation} />
        <StatusContainer>
          <StatusDiv status={status} setStatus={setStatus} isEditable={isEditable} />
          <StyledButton label={title} secondary onClick={submitPledge} disabled={!isEditable} />
          <CancelActionButton onClick={handleClick}>CANCELAR</CancelActionButton>
        </StatusContainer>
        <FormControlLabel
          onClick={() => setChecked(!checked)}
          control={<Checkbox checked={checked} />}
          label='Enviar e-mail com atualização.'
        />
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`

const Modal = styled.div`
  overflow: auto;
  padding: 1em 2em;
  background: #eee;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 500px) {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }
`

const HasProfileCompleted = styled.p`
  position: absolute;
  color: red;
  padding: 1em;
  top: 0;
  left: 0;
`

const ModalTitle = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
  color: #7f7f7f;
  @media only screen and (max-width: 500px) {
    margin-bottom: 10px;
  }
`

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 1em 0.3em;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`

const StyledButton = styled(Button)`
  margin-left: 10px;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 12px;
  }
`

const CancelActionButton = styled.button`
  height: 38px;
  padding: 0 1em;
  margin-left: 10px;
  cursor: pointer;
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 4px;
  &:hover {
    background: red;
    color: white;
    transition: all 0.3s;
  }

  @media (max-width: 768px) {
    margin: 0;
    margin-top: 1em;
  }
`

export default withRouter(PledgeModal)
