import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { LoadingScreen, AddressForm, PersonForm, BankForm, DocumentsForm } from '../../components'
import { Accordion, Container } from './style'

import { toast } from 'react-toastify'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { AccountCircle, LocationOn, AccountBalance, Folder, CheckCircle } from '@material-ui/icons'
import { fetchPersonalDocuments } from '../../services/personalDocuments'
import { fetchPerson, fetchAddress, fetchBank, fetchInvestor, checkCompleteProfile } from '../../services/persons'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '140px 0',
    padding: '50px 150px',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
    color: '#266f9f',
    marginRight: 14
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    display: 'flex',
    width: '100%'
  },
  containerInput: {
    padding: '10px'
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '14px',
    flexWrap: 'nowrap'
  },
  button: {
    display: 'flex',
    backgroundColor: '#3dc6ef',
    color: '#fff',
    fontSize: '14px',
    boxShadow: 'none',
    padding: '0 50px',
    '&:hover': {
      backgroundColor: '#266f9f'
    }
  },
  menu: {
    width: 200
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    borderRadius: '3px',
    background: '#fff',
    border: '1px dashed #9b9b9b',
    cursor: 'pointer',
    margin: '14px 0 14px 0',
    alignItems: 'center',
    padding: '14px 0',
    fontSize: '14px',
    fontWeight: '600'
  },
  dropzoneStyleActive: {
    border: '1px solid green',
    opacity: '0.9'
  },
  pInputDropzone: {
    fontSize: '14px',
    color: '#9b9b9b'
  },
  spanDropzone: {
    fontFamily: 'HelveticaNeue',
    fontSize: '18px',
    color: '#9b9b9b',
    letterSpacing: 0,
    textAlign: 'center'
  },
  iconUpload: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: 'none'
  },
  containerDropzone: {
    marginBottom: '16px'
  },
  buttonsDropzone: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'
  },
  textDropzone: {
    padding: '14px'
  },
  containerDocuments: {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px'
  }
}))

const GlobalCss = withStyles({
  '@global': {
    '.MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)'
    },
    '.MuiExpansionPanel-root.Mui-expanded': {
      margin: 0
    },
    '.MuiExpansionPanel-root.Mui-expanded:before': {
      opacity: 1
    }
  }
})(() => null)

const AboutPerson = ({ match }) => {
  const [expanded, setExpanded] = useState(false)
  const [person, setPerson] = useState([])
  const [address, setAddress] = useState([])
  const [bank, setBank] = useState([])
  const [investor, setInvestor] = useState([])
  const [documents, setDocuments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [checkComplete, setCheckComplete] = useState(null)
  const [completedPersonal, setCompletedPersonal] = useState(false)
  const [completedAddress, setCompletedAddress] = useState(false)
  const [completedFinancial, setCompletedFinancial] = useState(false)
  const [completedDocuments, setCompletedDocuments] = useState(false)
  const [completedAgreement, setCompletedAgreement] = useState(false)
  const [completedPhone, setCompletedPhone] = useState(false)

  const classes = useStyles()
  const kdvId = match.params.id

  const handleChangeExpansionPanel = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const fetchDataPerson = async () => {
    try {
      const promises = [
        fetchPerson(kdvId),
        fetchAddress(kdvId),
        fetchPersonalDocuments(kdvId),
        fetchBank(kdvId),
        fetchInvestor(kdvId),
        checkCompleteProfile(kdvId)
      ]

      const response = await Promise.all(promises)
      const responsePersonalDocuments = response[2].map(({ data }) => data.pop())
      setPerson(response[0].data[0])
      setAddress(response[1].data[0])
      setDocuments(responsePersonalDocuments)
      setBank(response[3].data[0])
      setInvestor(response[4].data[0])
      setCheckComplete(response[5].data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      toast.error('Não foi possível buscar informações')
      setPerson([])
      setAddress([])
      setDocuments([])
      setBank([])
      setInvestor([])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDataPerson()
  }, [])

  useEffect(() => {
    if (checkComplete) updateCompleted()
  }, [checkComplete])

  const updateCompleted = () => {
    const completeProfile = checkComplete
    if (completeProfile.missingData.person.length === 0) setCompletedPersonal(true)
    if (completeProfile.missingData.address.length === 0) setCompletedAddress(true)
    if (completeProfile.missingData.bank.length === 0) setCompletedFinancial(true)
    if (completeProfile.missingData.uploadedDocuments.length === 0) setCompletedDocuments(true)
    if (completeProfile.missingData.agreementDocuments.length === 0) setCompletedAgreement(true)
    if (completeProfile.missingData.phone.length === 0) setCompletedPhone(true)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Container>
      <Accordion>
        <GlobalCss />
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChangeExpansionPanel('panel1')}>
          <ExpansionPanelSummary
            expandIcon={<CheckCircle htmlColor={completedPersonal && completedPhone ? 'green' : 'grey'} />}
            id='panel1c-header'
          >
            <div className={classes.column}>
              <AccountCircle className={classes.icon} />
              <p>Dados Pessoais</p>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <PersonForm classes={classes} person={person} documents={documents} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChangeExpansionPanel('panel2')}>
          <ExpansionPanelSummary
            expandIcon={<CheckCircle htmlColor={completedAddress ? 'green' : 'grey'} />}
            aria-controls='panel1c-content'
            id='panel1c-header'
          >
            <div className={classes.column}>
              <LocationOn className={classes.icon} />
              <p>Endereço Principal</p>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <AddressForm classes={classes} address={address} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChangeExpansionPanel('panel3')}>
          <ExpansionPanelSummary
            expandIcon={<CheckCircle htmlColor={completedFinancial ? 'green' : 'grey'} />}
            aria-controls='panel1c-content'
            id='panel1c-header'
          >
            <div className={classes.column}>
              <AccountBalance className={classes.icon} />
              <p>Dados Financeiros</p>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <BankForm classes={classes} bank={bank} investor={investor} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChangeExpansionPanel('panel4')}>
          <ExpansionPanelSummary
            expandIcon={<CheckCircle htmlColor={completedDocuments && completedAgreement ? 'green' : 'grey'} />}
            aria-controls='panel1c-content'
            id='panel1c-header'
          >
            <div className={classes.column}>
              <Folder className={classes.icon} />
              <p>Documentos</p>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <DocumentsForm documents={documents} classes={classes} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Accordion>
    </Container>
  )
}

export default withRouter(AboutPerson)
