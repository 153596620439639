import { Option } from './components'
import styled from 'styled-components'
import { colors } from '../../Themes/colors'
import InfoIcon from '@material-ui/icons/Info'

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 16px;
    color: ${colors.gray};

    margin-bottom: 8px;
  }

  label {
    width: 100%;
  }

  section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 12px;
    padding-top: 12px;
  }

  section + section {
    margin-top: 0px;
    border-top: #e0e0e0 solid 1px;
  }

  footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    width: 100%;
  }
`

export const DrawerTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.gray};
`

export const Detail = styled.div`
  color: ${colors.blue};
  font-weight: 600;
  font-size: 14px;
`
export const OptionClose = styled(Option)`
  img {
    width: 25px;
    height: 25px;
  }
`
export const CCBDownload = styled.div`
  display: ${({ show }) => (!!show ? 'flex' : 'none')};
  a {
    color: ${colors.blue};
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    position: relative;
  }

  img {
    transform: rotate(180deg);
    position: relative;
    top: 2px;
  }
`
export const ContainerTextProcessing = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    justify-content: center;
  }
`

export const InfoIconTab = styled(InfoIcon)`
  &.MuiSvgIcon-root {
    font-size: ${({ type }) => (type === 'card' ? '30px' : '16px')};
    color: ${({ type }) => (type === 'card' ? '#076F9F' : '')};

    :hover {
      transform: scale(1.2);
    }
  }
`
