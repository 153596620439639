import React, { useRef, useEffect, useState, useCallback } from 'react'
import { toPng } from 'html-to-image'
import { useId } from 'react-id-generator'

import { Column, Row } from '../'
import Proposal from './Proposal'
import { ImageProposal, Link } from './style'

const ProposalTimeline = ({ data }) => {
  const [load, setLoad] = useState(false)
  const [url, setUrl] = useState('')

  const ref = useRef(null)
  const ref2 = useRef(null)

  const [htmlId] = useId()

  useEffect(() => {
    toPng(ref.current, { cacheBust: true })
      .then(dataUrl => {
        var img = new Image()
        img.src = dataUrl
        setUrl(dataUrl.replace(/^data:image\/(png|jpg);base64,/, ''))
        document.getElementById(htmlId).appendChild(img)
        setLoad(true)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const OpenImageProposal = useCallback(() => {
    function base64ToBlob(base64, mime) {
      mime = mime || ''
      var sliceSize = 1024
      var byteChars = window.atob(base64)
      var byteArrays = []

      for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize)

        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        var byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, { type: mime })
    }

    var dataImage = url

    var image = new Image()
    image.src = 'data:image/jpg;base64,' + dataImage
    var jpegFile64 = dataImage
    var jpegBlob = base64ToBlob(jpegFile64, 'image/jpeg')

    var fileURL = URL.createObjectURL(jpegBlob)
    window.open(fileURL)
  }, [htmlId, url])

  return (
    <Column>
      <Row justifyContent='flex-end' mb='5px'>
        <Link onClick={OpenImageProposal}>(Vizualizar)</Link>
      </Row>
      <Proposal data={data} ref={ref} show={!load} />
      <ImageProposal id={htmlId} ref={ref2} />
    </Column>
  )
}

export default ProposalTimeline
