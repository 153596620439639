import client from '../../providers/fetchClient'

export const getFQS = () => client.get('/v3/faq')

export const getFQsById = id => client.get(`/v3/faq/${id}`)

export const createFQS = body => client.post('/v3/create-faq', body)

export const updateFQS = (id, body) => client.put(`/v3/faq/${id}`, body)

export const deleteFQS = id => client.delete(`/v3/faq/${id}`)
