export const campaignTypes = {
  AVAL: 1,
  AVAL_E_GARANTIA: 2
}

export const campaignStatuses = {
  ACTIVE: 'A',
  CLOSED: 'CL',
  CLOSED_WITHOUT_CAPTATION: 'CS',
  WAITING_LIST: 'WL',
  PAYMENT_RECEIVED: 'PR',
  DISBURSED: 'DD',
  OLD_CCBS: 'CCBs',
  PROCESS_PLEDGES: 'STEP1',
  BMP_SIMULATOR: 'STEP2',
  SEND_PROPOSAL: 'STEP3',
  PROPOSAL_STATUS: 'STEP4',
  CCBS: 'STEP5',
  DELETED: 'D'
}

export const runCampaignSteps = [
  { title: '1. Processar Pedidos', abbreviation: campaignStatuses.PROCESS_PLEDGES, order: 1 },
  { title: '2. Simulador BMP', abbreviation: campaignStatuses.BMP_SIMULATOR, order: 2 },
  { title: '3. Enviar Proposta', abbreviation: campaignStatuses.SEND_PROPOSAL, order: 3 },
  { title: '4. Status da Proposta', abbreviation: campaignStatuses.PROPOSAL_STATUS, order: 4 },
  { title: '5. Download/Upload das CCBs', abbreviation: campaignStatuses.CCBS, order: 5 }
]

export const campaignTypesLink = {
  [campaignTypes.AVAL]: 'com-aval',
  [campaignTypes.AVAL_E_GARANTIA]: 'com-aval-e-garantia'
}
