import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'

import { Row, Text } from '../index'
import {
  DragAndDrop,
  StyledUpload,
  Title,
  Underlined,
  Description,
  AcceptFile,
  AttachFileIcon,
  StyledRow,
  StyledButton,
  ErrorFile,
  ErrorMessage,
  FileWithError,
  Container,
  Content
} from './styles'

const DropzoneHome = ({
  onDrop,
  defaultValue,
  error,
  name,
  control,
  onDelete,
  isRequiredMessage,
  multiple = false,
  ...props
}) => {
  const [archive, setArchive] = useState()

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    maxFiles: multiple ? 10 : 1,
    maxSize: 5242880,
    onDrop: files => {
      setArchive(multiple ? false : true)
      onDrop(files)
    },
    multiple: multiple
  })

  const file = useMemo(() => acceptedFiles[acceptedFiles.length - 1], [acceptedFiles])

  const handleButtonClick = () => {
    setArchive()
    onDelete()
  }

  const acceptedFileItems = acceptedFiles.map(file => {
    return (
      <StyledRow key={file.path}>
        <Row>
          <AttachFileIcon fontSize='small' />
          <AcceptFile key={file.path}> {file.path}</AcceptFile>
        </Row>
        <div>
          <StyledButton onClick={() => window.open(URL.createObjectURL(file))} variant='text'>
            Ampliar
          </StyledButton>
          <StyledButton onClick={handleButtonClick} variant='text'>
            Excluir
          </StyledButton>
        </div>
      </StyledRow>
    )
  })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <ErrorFile key={file.path}>
      <div>
        <Row>
          <AttachFileIcon fontSize='small' />
          <FileWithError>{file.path}</FileWithError>
        </Row>
        <div>
          {errors.map(e => {
            if (e.code === 'file-too-large') {
              return <ErrorMessage key={e.code}>O tamanho máximo do arquivo é de 5MB.</ErrorMessage>
            } else {
              return <ErrorMessage key={e.code}>Erro na tentativa de upload. Tente novamente</ErrorMessage>
            }
          })}
        </div>
      </div>
      <StyledButton onClick={handleButtonClick} variant='text' buttoncolor='#D93025'>
        Excluir
      </StyledButton>
    </ErrorFile>
  ))

  return (
    <Controller
      as={
        <Container>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Content>
              {archive && file ? (
                <img src={URL.createObjectURL(file)} alt='Arquivo' />
              ) : (
                <DragAndDrop>
                  <StyledUpload />
                  <Title> Anexar arquivos </Title>
                  <div>
                    <Underlined>Selecione </Underlined>
                    <Description>ou arraste os arquivos até aqui</Description>
                  </div>
                </DragAndDrop>
              )}
            </Content>
          </div>
          {archive && acceptedFileItems}
          {archive && fileRejectionItems}
          {isRequiredMessage && (
            <Text variant='subtitle1' color='error'>
              Você precisa anexar uma imagem.
            </Text>
          )}
        </Container>
      }
      defaultValue={defaultValue}
      error={error}
      name={name}
      control={control}
      {...props}
    />
  )
}

DropzoneHome.defaultProps = {
  defaultValue: ''
}

DropzoneHome.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onDrop: PropTypes.func
}

export default DropzoneHome
