import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { LoadingComponent } from '../'

import { formatMoney, bmpFormatNumber } from '../../helpers'
import { getCampaignById, getCampaignResponse } from '../../services/v3'

const SignatureResume = ({ campaignId }) => {
  const [campaign, setCampaign] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setIsLoading(true)

        const [
          { data: campaign },
          {
            data: { usersProposal }
          }
        ] = await Promise.all([getCampaignById(campaignId), getCampaignResponse(campaignId)])

        if (!usersProposal || !campaign) throw new Error('Erro ao buscar os dados da campanha')

        const campaignValues = usersProposal.reduce(
          (acc, cur) => {
            return {
              operationValue: Number(acc.operationValue) + Number(bmpFormatNumber(cur.user.proposal.OriginalOrder)),
              netValue: Number(acc.netValue) + Number(bmpFormatNumber(cur.user.proposal.VlrSolicitado)),
              parcel: Number(acc.parcel) + Number(bmpFormatNumber(cur.user.proposal.VlrParcela)),
              debtAmount: Number(acc.debtAmount) + Number(bmpFormatNumber(cur.user.proposal.VlrParcela)),
              tacValue: Number(acc.tacValue) + Number(cur.user.proposal.VlrTAC),
              iofValue: Number(acc.iofValue) + Number(bmpFormatNumber(cur.user.proposal.VlrIOF))
            }
          },
          {
            operationValue: 0,
            netValue: 0,
            parcel: 0,
            debtAmount: 0,
            tacValue: 0,
            iofValue: 0
          }
        )

        const populatedCampaign = { ...campaign, campaignValues }
        setCampaign(populatedCampaign)
      } catch (error) {
        toast.error('Ocorreu um erro ao buscar as informações de resumo da assinatura da campanha')
      } finally {
        setIsLoading(false)
      }
    }
    fetchInfos()
  }, [])

  if (isLoading) return <LoadingComponent />

  if (!campaign) return null

  return (
    <Container>
      <Summary>Resumo dos dados da operação: </Summary>
      <CampaignInfo>
        <RowItem>
          <Text>Nome da empresa</Text>
          <Small>{campaign.name}</Small>
        </RowItem>
        <RowItem>
          <Text>Tipo de operação</Text>
          <Small>Financiamento</Small>
        </RowItem>
        <RowItem>
          <Text>Valor da operação</Text>
          <Small>R$ {formatMoney(campaign.campaignValues.operationValue)}</Small>
        </RowItem>
        <RowItem>
          <Text>Valor líquido</Text>
          <Small>R$ {formatMoney(campaign.campaignValues.netValue)}</Small>
        </RowItem>
        <RowItem>
          <Text>Parcela</Text>
          <Small>R$ {formatMoney(campaign.campaignValues.parcel)}</Small>
        </RowItem>
        <RowItem>
          <Text>Valor da dívida</Text>
          <Small>R$ {formatMoney(campaign.campaignValues.parcel * campaign.installments)}</Small>
        </RowItem>
        <RowItem>
          <Text>Número de parcelas</Text>
          <Small>{campaign.installments}</Small>
        </RowItem>
        <RowItem>
          <Text>TAC</Text>
          <Small>R$ {formatMoney(campaign.campaignValues.tacValue)}</Small>
        </RowItem>
        <RowItem>
          <Text>IOF</Text>
          <Small>R$ {formatMoney(campaign.campaignValues.iofValue)}</Small>
        </RowItem>
      </CampaignInfo>
    </Container>
  )
}

const CampaignInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 769px) {
    justify-content: center;
  }
`

const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  width: 175px;
  justify-content: space-between;
`

const Small = styled.small`
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? 'gray' : 'black')};
`

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${({ disabled }) => (disabled ? 'gray' : 'black')};
`

const Summary = styled.p`
  margin: 15px;
  font-size: 24px;
  font-weight: bold;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-bottom: 20px;
`

export default SignatureResume
