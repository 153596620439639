import styled from 'styled-components'
import { Button } from '../../components/v2Components/Button'
import { TableBase } from '../NewUserInvestments/components'

export const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'parcel gener judicial'
    'bank bank bank'
    'info info info';

  @media (max-width: 1370px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'parcel gener'
      'judicial judicial'
      'bank bank'
      'info info';
  }

  @media (max-width: 1130px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'parcel'
      'gener'
      'judicial'
      'bank'
      'info';
  }
`

export const Title = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin: 24px 0px;

  color: #076f9f;
`
export const Subtitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #424242;
`

export const Infotitle = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #076f9f;
`

export const ContainerFilter = styled.div`
  display: grid;
  grid-template-columns: 541px 1fr 1fr;
  grid-template-areas: 'filter judicial search';

  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;

  .filter {
    padding: 24px 32px 24px 0px;
    border-right: 1px solid #e0e0e0;
    grid-area: filter;
  }

  .judicial {
    padding: 24px 32px;
    border-right: 1px solid #e0e0e0;
    grid-area: judicial;
  }

  .search {
    padding: 24px 0px 24px 32px;
    grid-area: search;
  }

  @media (max-width: 1370px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'filter filter'
      'judicial search';

    .filter {
      padding: 24px 32px 24px 0px;
      border-bottom: 1px solid #e0e0e0;
      border-right: 0px solid #e0e0e0;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'filter'
      'judicial'
      'search';

    .filter {
      padding: 24px 32px 24px 25px;
      border-bottom: 1px solid #e0e0e0;
      border-right: 0px solid #e0e0e0;
    }

    .judicial {
      border-bottom: 1px solid #e0e0e0;
      border-right: 0px solid #e0e0e0;
    }

    .search {
      padding: 24px 32px 24px 32px;
    }
  }
`

export const ButtonFilter = styled(Button)``

export const Table = styled(TableBase)`
  .MuiTableCell-body {
    font-size: 16px;
    padding: 12px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    box-sizing: border-box;
  }

  .MuiTableCell-root:last-child {
    max-width: 200px;
    width: 68px;
  }
`

export const ContainerBank = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 10px;
  align-items: center;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`
export const ContainerCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  border-top: 1px solid #e0e0e0;
`

export const Card = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  width: 100%;
  max-width: 260px;
  min-width: 244px;
  height: 40px;

  label {
    font-weight: 600;
  }
`
export const Bank = styled.div`
  grid-area: bank;
  border-top: 1px solid #e0e0e0;
`
export const Parcel = styled.div`
  grid-area: parcel;
  border-right: 1px solid #e0e0e0;
  padding-right: 24px;

  @media (max-width: 1130px) {
    padding: 0 15px;
  }
`
export const Judicial = styled.div`
  grid-area: judicial;
  border-left: 1px solid #e0e0e0;
  padding-left: 24px;

  @media (max-width: 1130px) {
    padding: 0 15px;
  }
`
export const Generator = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: gener;
  padding: 0 24px;

  @media (max-width: 1130px) {
    padding: 0 15px;
  }
`
