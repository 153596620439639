import client from '../../providers/fetchClient'

export const getImagesHome = () => client.get('/v3/images-home')

export const getImagesHomeByID = id => client.get(`/v3/images-home/${id}`)

export const createImagesHome = data => client.post('/v3/create-images-home', data)

export const updateImagesHome = (id, data) => client.put(`/v3/images-home/${id}`, data)

export const deleteImagesHome = id => client.delete(`/v3/images-home/${id}`)
