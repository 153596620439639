import styled from 'styled-components'

export const Content = styled.main`
  background-color: #fafafa;
`

export const Container = styled.section`
  display: none;

  ${props => {
    return !!props.showPocessing
      ? `
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
    padding: 16px;

    section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    flex-wrap: wrap;
    gap: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }

  h1 {
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }

  label {
    font-size: 12px;
    line-height: 16px;
    color: #757575;
    :last-child {
      color: white;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }
  `
      : ` section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
  }

  div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }

  h1 {
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }

  label {
    font-size: 12px;
    line-height: 16px;
    color: #757575;
    :last-child {
      color: white;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
    padding: 16px;
  }`
  }};
`

export const InfoContainer = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px 10px 0px;
    background: white;
  }
`
