import React from 'react'
import PropTypes from 'prop-types'

import { MaterialLinearProgress, ValueBox, BarBox, CurrentValue, Container } from './styles'
import { formatCurrency } from '../../../../helpers'

const ProgressBar = ({
  variant,
  initialValue,
  finalValue,
  desativeValue,
  percentage,
  thinbar = false,
  value,
  ...props
}) => {
  const currentValue = value || (initialValue * 100) / finalValue
  return (
    <Container {...props}>
      {!desativeValue && (
        <ValueBox {...props}>
          <p> {formatCurrency(initialValue)} </p>
          <p> {formatCurrency(finalValue)} </p>
        </ValueBox>
      )}
      <BarBox>
        {!thinbar && <MaterialLinearProgress variant={variant} value={currentValue} />}
        {thinbar && (
          <MaterialLinearProgress variant={variant} value={currentValue} thinbar={!!thinbar ? 'true' : 'false'} />
        )}
        {percentage && !thinbar && <CurrentValue>{currentValue}%</CurrentValue>}
      </BarBox>
    </Container>
  )
}

ProgressBar.defaultProps = {
  variant: 'determinate',
  desativeValue: false,
  thinbar: false
}

ProgressBar.propTypes = {
  variant: PropTypes.oneOf(['buffer', 'determinate', 'indeterminate', 'query']),
  initialValue: PropTypes.number,
  finalValue: PropTypes.number,
  value: PropTypes.number,
  percentage: PropTypes.bool,
  desativeValue: PropTypes.bool,
  thinbar: PropTypes.bool
}

export default ProgressBar
