import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const modalAddLeadUpdate = yupResolver(
  yup.object().shape({
    lead_update_status_id: yup.string().required(),
    is_visible_to_partner: yup.bool()
  })
)

export const modalEditLeadUpdate = yupResolver(
  yup.object().shape({
    is_visible_to_partner: yup.bool()
  })
)

export const recusedLead = yupResolver(
  yup.object().shape({
    reason: yup.string().required(),
    observation: yup.string().when('reason', {
      is: 'Outros',
      then: yup.string().required()
    })
  })
)

export const proposalLead = yupResolver(
  yup.object().shape({
    s3_name: yup
      .array()
      .required()
      .nullable()
  })
)
