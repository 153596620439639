import React from 'react'
import format from 'date-fns/format'
import { ptBR } from 'date-fns/locale'
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'

const CustomToolbar = function ({ date, isLandscape, openView, setOpenView, title, ...props }) {
  const handleChangeViewClick = view => e => {
    setOpenView(view)
  }

  return (
    <PickerToolbar title={title} isLandscape={isLandscape} {...props}>
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant='h6'
        selected={openView === 'date'}
        label={format(date, 'dd LLLL', { locale: ptBR })}
      />
      <ToolbarButton
        style={{marginLeft: 5}}
        onClick={handleChangeViewClick('year')}
        variant='h6'
        selected={openView === 'year'}
        label={format(date, 'yyyy')}
      />
    </PickerToolbar>
  )
}

export default CustomToolbar
