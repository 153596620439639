import React from 'react'
import NumberFormat from 'react-number-format'
import { Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'

import { formatValueToBrl } from '../../helpers'

const NumberInput = ({
  register,
  defaultValue,
  name,
  error,
  label,
  type,
  placeholder,
  disabled,
  control,
  required,
  width,
  thousandSeparator,
  mask,
  format,
  prefix,
  suffix,
  margin,
  decimalScale,
  fixedDecimalScale,
  dense = false,
  ...props
}) => {
  return (
    <Container width={width} margin={margin}>
      <Controller
        as={
          <NumberFormat
            {...props}
            mask={mask}
            customInput={TextField}
            prefix={prefix}
            suffix={suffix}
            format={type === 'currency' ? value => formatValueToBrl(value / 100) : format || null}
            decimalSeparator={','}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            thousandSeparator={thousandSeparator || null}
            inputProps={{
              className: 'browser-default'
            }}
          />
        }
        label={label}
        defaultValue={defaultValue}
        variant='outlined'
        error={Boolean(error)}
        helperText={error && error.message}
        name={name}
        control={control}
        ref={register}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
        {...dense && { margin: 'dense' }}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: ${({ margin }) => (margin ? margin : '10px')};
  width: ${({ width }) => (width ? width : '220px')};
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`

export { NumberInput }
