import React, { useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import { MaterialButton, Modal } from '../'

import { getLinkToDownloadCcbs } from '../../services/v3'

const ModalDownloadCcb = ({ isOpen, setIsOpen, campaignId }) => {
  const [isDownloadingCcbs, setIsDownloadingCcbs] = useState(false)

  const handleDownloadCcbs = async () => {
    try {
      setIsDownloadingCcbs(true)
      const { data: zipFile } = await getLinkToDownloadCcbs(campaignId)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(new Blob([zipFile], { type: 'text/plain' }))
      element.download = `ccbs_campaign_${campaignId}.zip`
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      console.error(error)
      toast.error('Não encontramos essas CCBs.')
    } finally {
      setIsDownloadingCcbs(false)
      setIsOpen(false)
    }
  }

  return (
    <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalContainer>
        <Title>Processamento concluído com sucesso!</Title>
        <Text> Clique abaixo para fazer o download de todas as CCBs da campanha</Text>
        <MaterialButton width='180px' label='Baixar CCBs' onClick={handleDownloadCcbs} loading={isDownloadingCcbs} />
      </ModalContainer>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 450px;
  padding: 10px;
`

const Title = styled.p`
  font-size: 28px;
  color: #076f9f;
  font-weight: bold;
  margin-bottom: 35px;
  width: 100%;
  text-align: center;
`

const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`

export default ModalDownloadCcb
