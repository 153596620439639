import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 32px;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;

  div {
    display: flex;
    align-items: center;

    h1 {
      margin-right: 10px;
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: #424242;
    }
  }

  button {
    background-color: transparent;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`
export const HeaderTablet = styled.header`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    div {
      display: flex;
      align-items: center;
    }

    h1 {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`

export const HeaderResponsive = styled.header`
  display: none;

  @media (max-width: 768px) {
    display: block;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 24px;

    span {
      display: flex;
      align-items: center;
    }

    div {
      display: flex;
      align-items: center;

      h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        display: flex;
        align-items: center;
        color: #424242;
      }
    }

    button {
      background-color: transparent;
    }
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

export const InfoCard = styled.section`
  display: flex;
  flex-direction: column;
  padding-right: 64px;
  border-right: 1px solid #e0e0e0;

  @media (max-width: 1200px) {
    padding-right: 0;
    padding-bottom: 24px;
    border-bottom: none;
    border-right: none;
  }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;
    border-right: none;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }

  label {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    margin-top: 8px;
  }

  div {
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #424242;
      margin-bottom: 8px;
    }
  }

  section {
    margin-top: 24px;
    margin-bottom: 8px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #424242;
      }
    }
  }
`

export const Details = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  white-space: nowrap;
  padding-left: 64px;
  gap: 15px;

  @media (max-width: 1200px) {
    padding-left: 0;
  }

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
  }

  @media (max-width: 400px) {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 80px;
  }

  @media (max-width: 380px) {
    gap: 30px;
    grid-template-columns: 1fr;
  }

  div {
    display: flex;
    flex-direction: column;

    @media (max-width: 425px) {
      flex: 1;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #424242;
    }

    label {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #424242;
    }
  }
`
