import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import { CNPJ_LENGTH, CEP_LENGTH } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const newCreditRequestSchemaNoCnpj = yupResolver(
  yup.object().shape({
    lead_origin_id: yup.string().required(),
    indicated_by: yup.string().when('lead_origin_id', {
      is: '3',
      then: yup.string().required()
    }),
    responsible: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    celphone: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    company_type: yup.string().required(),
    franchisor_name: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    franchise_description: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    average_monthly_revenue: yup.string().required(),
    desired_amount: yup.string().required(),
    lending_reason: yup.string().required(),
    have_cnpj: yup.boolean()
  })
)

export const newCreditRequestSchemaCnpj = yupResolver(
  yup.object().shape({
    lead_origin_id: yup.string().required(),
    indicated_by: yup.string().when('lead_origin_id', {
      is: '3',
      then: yup.string().required()
    }),
    responsible: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    celphone: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    company_type: yup.string().required(),
    franchisor_name: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    franchise_description: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    average_monthly_revenue: yup.string().required(),
    desired_amount: yup.string().required(),
    lending_reason: yup.string().required(),
    have_cnpj: yup.boolean(),
    cnpj: yup.string().test({
      name: 'cnpj',
      message: 'CNPJ incompleto',
      test: value => !value || value.replace(/\D/g, '').length === CNPJ_LENGTH
    }),
    company_name: yup.string().required(),
    company_history: yup.string().required(),
    street: yup.string().required(),
    zip_code: yup
      .string()
      .required()
      .test('cep', 'CEP incompleto', value => value.replace(/\D/g, '').length === CEP_LENGTH),
    number: yup.string().required(),
    district: yup.string().required(),
    city: yup.string().required(),
    uf: yup.string().required(),
    company_site: yup
      .string()
      .test(
        'site',
        'Digite uma URL válida',
        value =>
          !value ||
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(value)
      )
  })
)

export const newCreditRequestSchema = yupResolver(
  yup.object().shape({
    lead_origin_id: yup.string().required(),
    indicated_by: yup
      .mixed()
      .when('lead_origin_id', {
        is: '3',
        then: yup.mixed().required()
      })
      .nullable(true),
    responsible: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    celphone: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    company_type: yup.string().required(),
    franchise_type: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    franchisor_name: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    franchise_description: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    company_history: yup.string().required(),
    average_monthly_revenue: yup.string().required(),
    desired_amount: yup.string().required(),
    lending_reason: yup.string().required(),
    have_cnpj: yup.boolean(),
    cnpj: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup
        .string()
        .required()
        .test({
          name: 'cnpj',
          message: 'CNPJ incompleto',
          test: value => !value || value.replace(/\D/g, '').length === CNPJ_LENGTH
        })
    }),
    company_name: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente' || franchise_type === 'Nova') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    street: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    zip_code: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup
        .string()
        .required()
        .test('cep', 'CEP incompleto', value => !value || value.replace(/\D/g, '').length === CEP_LENGTH)
    }),
    number: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    district: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    city: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    uf: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    company_site: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup
        .string()
        .test(
          'site',
          'Digite uma URL válida',
          value =>
            !value ||
            /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(value)
        )
        .nullable()
    })
  })
)

export const newCreditRequestSchemaEdit = yupResolver(
  yup.object().shape({
    lead_origin_id: yup.string().required(),
    indicated_by: yup
      .mixed()
      .when('lead_origin_id', {
        is: '3',
        then: yup.mixed().required()
      })
      .nullable(true),
    responsible: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    celphone: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    company_type: yup.string().required(),
    franchise_type: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    franchisor_name: yup
      .string()
      .when('company_type', {
        is: 'Franquia',
        then: yup.string().required()
      })
      .nullable(true),
    franchise_description: yup
      .string()
      .when('company_type', {
        is: 'Franquia',
        then: yup.string().required()
      })
      .nullable(true),
    company_history: yup.string().required(),
    average_monthly_revenue: yup.string().required(),
    lending_reason: yup.string().required(),
    have_cnpj: yup.boolean(),
    cnpj: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup
        .string()
        .required()
        .test({
          name: 'cnpj',
          message: 'CNPJ incompleto',
          test: value => !value || value.replace(/\D/g, '').length === CNPJ_LENGTH
        })
    }),
    company_name: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente' || franchise_type === 'Nova') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    street: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    zip_code: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup
        .string()
        .required()
        .test('cep', 'CEP incompleto', value => !value || value.replace(/\D/g, '').length === CEP_LENGTH)
    }),
    number: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    district: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    city: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    uf: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup.string().required()
    }),
    company_site: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
      is: (have_cnpj, company_type, franchise_type) => {
        if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
          return true
        }
        return false
      },
      then: yup
        .string()
        .test(
          'site',
          'Digite uma URL válida',
          value =>
            !value ||
            /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(value)
        )
        .nullable(true)
    })
  })
)
