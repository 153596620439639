import styled from 'styled-components'
import { Dialog as MaterialModal } from '@material-ui/core'

import Row from '../Row'
import { IconClear } from '../icons'

export const Modal = styled(MaterialModal)`
  div {
    &.MuiPaper-elevation24 {
      box-shadow: none;
      border-radius: 8px;
      width: 100%;
      max-width: 1200px;
      padding: 24px;
      overflow: initial;

      @media (max-width: 1100px) {
        overflow: scroll;
      }
    }
  }
`
export const GridProposal = styled.div`
  display: grid;
  grid-template-columns: 230px repeat(1, 1fr);
  gap: 0px 110px;
  grid-template-areas: 'input proposal';

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'input'
      'proposal';
  }
`

export const Close = styled(IconClear)`
  cursor: pointer;
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: ${props => props.flexDirection};
  width: 100%;
`
