import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 170px;
  height: 80px;

  border-radius: 12px;
  border: solid 1px;
  border-color: ${({ type }) => (type === 'filter' ? 'black' : '#969696')};

  grid-area: ${({ type }) => (type === 'filter' ? 'cardFilter' : type === 'all' ? 'cardAll' : 'cardDate')};

  h4 {
    color: ${({ type }) => (type === 'filter' ? 'black' : '#969696')};
    font-size: 2em;
  }

  p {
    width: 100%;
    height: 22px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 7px;
    line-height: 25px;
    color: ${({ type }) => (type === 'filter' ? 'black' : '#969696')};
  }

  @media (max-width: 540px) {
    max-width: 175px;
    width: 100%;

    p {
      line-height: 17px;
      margin-top: 4px;
    }
  }
`
