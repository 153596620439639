import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import EmailIcon from '@material-ui/icons/Email'
import { Link } from 'react-router-dom'

import { SelectStatusLead, ResponsibleCell, IndicatedByCell, PopoverLeadContact } from '../../components'

import { Row, IconButton, InactivityAlert, WithoutDocument } from '../../components/v2Components'

import { formatCNPJ, formatValueToBrl, sanitizeDate, copyCnpj } from '../../helpers'
import { LeadContext } from '../../contexts'
import { IconCopy } from '../../components/v2Components/icons'

export const openDetailsColumns = ['company_name', 'average_monthly_revenue', 'lending_amount', 'created_at']

export const creditRequestColumns = [
  {
    Header: 'Razão Social/CNPJ',
    accessor: 'company_name',
    className: 'pointer',
    minWidth: 330,
    maxWidth: 500,
    alignItems: 'center',
    Cell: ({ original }) => (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '17px 0px' }}>
        <Link
          style={{ padding: '0px 8px', width: 'inherit', color: '#076F9F' }}
          to={`/detalhes-solicitação-de-credito/${original.id}`}
        >
          <span>{original.company_name}</span>
        </Link>
        <Row justifyContent='flex-start' style={{ alignItems: 'center', padding: '0px 8px', gap: '10px' }}>
          <span>{original.cnpj ? formatCNPJ(original.cnpj) : '-'}</span>
          {original.cnpj && (
            <IconButton
              style={{ width: '10px', height: '10px' }}
              icon={<IconCopy style={{ fontSize: 16 }} />}
              variant='text'
              onClick={() => {
                copyCnpj(original.cnpj)
              }}
            />
          )}
        </Row>
      </div>
    )
  },
  {
    Header: 'FMM',
    accessor: 'average_monthly_revenue',
    className: 'align-right pointer',
    width: 130,
    Cell: ({ original }) => (
      <Link
        style={{ padding: '16px 8px', width: 'inherit', color: '#076F9F', display: 'flex', justifyContent: 'flex-end' }}
        to={`/detalhes-solicitação-de-credito/${original.id}`}
      >
        <span>{formatValueToBrl(original.average_monthly_revenue)}</span>
      </Link>
    )
  },
  {
    Header: 'Valor Empréstimo',
    accessor: 'lending_amount',
    className: 'align-right pointer',
    width: 150,
    Cell: ({ original }) => (
      <Link
        style={{ padding: '16px 8px', width: 'inherit', color: '#076F9F', display: 'flex', justifyContent: 'flex-end' }}
        to={`/detalhes-solicitação-de-credito/${original.id}`}
      >
        <span>{formatValueToBrl(original.lending_amount)}</span>
      </Link>
    )
  },
  {
    Header: 'Solicitado em',
    accessor: 'created_at',
    className: 'align-center pointer',
    width: 110,
    Cell: ({ original }) => (
      <Link
        style={{ padding: '16px 8px', width: 'inherit', color: '#076F9F', display: 'flex', justifyContent: 'center' }}
        to={`/detalhes-solicitação-de-credito/${original.id}`}
      >
        <span>{sanitizeDate(original.created_at)}</span>
      </Link>
    )
  },
  {
    Header: 'Contato',
    accessor: 'contact',
    width: 160,
    Cell: ({ original: lead }) => {
      const [anchor, setAnchor] = useState(null)
      const { first_name, last_name, email } = lead
      const { search, setSearchText } = useContext(LeadContext)
      const completeName = `${first_name || ''} ${last_name || ''}`.trim()
      return (
        <ContactContainer>
          <FilterableText
            onClick={() => {
              setSearchText(completeName)
              search({
                general_filter: completeName,
                contact: completeName
              })
            }}
            onMouseEnter={event => {
              setAnchor(event.currentTarget)
            }}
            onMouseLeave={() => {
              setAnchor(null)
            }}
          >
            {first_name}
          </FilterableText>
          <StyledEmailIcon onClick={() => window.open(`mailto:${email}`)} />
          <PopoverLeadContact anchor={anchor} setAnchor={setAnchor} lead={lead} />
        </ContactContainer>
      )
    }
  },
  {
    Header: 'Origem',
    accessor: 'lead_origin',
    width: 115,
    Cell: ({ original: { lead_origin } }) => {
      const { search } = useContext(LeadContext)
      return (
        <span
          style={{ cursor: 'pointer', padding: '16px 8px' }}
          onClick={() => {
            search({
              origin_name: [lead_origin.name]
            })
          }}
        >
          {lead_origin && lead_origin.name}
        </span>
      )
    }
  },
  {
    Header: 'Indicado por',
    accessor: 'indicated_by',
    width: 150,
    Cell: ({ original: lead }) => (
      <div style={{ padding: '16px 8px', width: 'inherit' }}>
        <IndicatedByCell lead={lead} />
      </div>
    )
  },
  {
    Header: 'Responsável',
    accessor: 'responsible',
    width: 100,
    Cell: ({ original }) => (
      <div style={{ padding: '16px 8px', width: 'inherit' }}>
        <ResponsibleCell lead={original} />
      </div>
    )
  },
  {
    Header: 'Status',
    accessor: 'details',
    minWidth: 295,
    Cell: ({ original }) => (
      <DetailsContainer>
        <DetailsContent>
          <SelectStatusLead lead={original} />

          <AlertsContainer>
            <InactivityAlert lead={original} />
            <WithoutDocument lead={original} />

            {!!original.lead_update_partners && original.lead_update_partners.length > 0 && (
              <Notification style={{ textAlign: 'center' }} title='Notificação'>
                {original.lead_update_partners.length}
              </Notification>
            )}
          </AlertsContainer>
        </DetailsContent>
      </DetailsContainer>
    )
  }
]

const AlertsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 60px;
`

const Notification = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: -webkit-center;
  vertical-align: middle;
  line-height: 25px;
  font-weight: 700;
  transform: scale(0.88);

  color: white;
  background: #fd4646;
`

const ContactContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
`

const StyledEmailIcon = styled(EmailIcon)`
  color: #757575;
  cursor: pointer;
`

const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
const DetailsContent = styled.div`
  width: 295px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-left: 35px;

  @media (max-width: 1900px) {
    padding-left: 5%;
  }

  @media (max-width: 1780px) {
    padding-left: 1%;
  }
`

const FilterableText = styled.span`
  cursor: pointer;
`
