import React from 'react'
import deburr from 'lodash/deburr'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: 'relative',
    padding: '10px'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing(2)
  }
}))

const AutocompleteInput = ({ input, field, setFieldValue }) => {
  const suggestions = input.options
  const renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps
    return (
      <TextField
        fullWidth
        margin={input.margin}
        name={input.name}
        InputProps={{
          inputRef: node => {
            ref(node)
            inputRef(node)
          },
          classes: {
            input: classes.input
          }
        }}
        {...other}
      />
    )
  }

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)

    return (
      <MenuItem selected={isHighlighted} component='div'>
        <div>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }

  const getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    if (inputLength === 0) return []
    else {
      return suggestions.filter(suggestion => {
        const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue
        if (keep) {
          count += 1
        }
        return keep
      })
    }
  }

  const getSuggestionValue = suggestion => {
    return suggestion.label
  }

  const classes = useStyles()
  const [state, setState] = React.useState({
    single: field.value
  })

  const [stateSuggestions, setSuggestions] = React.useState([])

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestions([])
  }
  const handleChange = name => (event, { newValue }) => {
    setState({
      ...state,
      [name]: newValue
    })
  }
  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion
  }
  return (
    <Grid item className={classes.containerInput} sm={input.col} xs={12}>
      <Autosuggest
        {...autosuggestProps}
        {...field}
        inputProps={{
          classes,
          id: input.id,
          label: input.label,
          placeholder: input.placeholder,
          value: state.single,
          onChange: handleChange('single'),
          name: input.name,
          type: input.type
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
      <div className={classes.divider} />
    </Grid>
  )
}
export default AutocompleteInput
