import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const BlueRadio = withStyles({
  root: {
    '&$checked': {
      color: '#076f9f'
    },
    '& input': {
      display: 'none'
    }
  },
  checked: {}
})(props => <Radio color='default' {...props} />)
const useStyles = makeStyles(theme => ({
  label: {
    color: '#9b9b9b',
    fontSize: '12px'
  }
}))

const theme = createMuiTheme({
  typography: {
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.46429em'
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#9b9b9b',
        fontSize: '14px'
      }
    }
  }
})

const RadioInput = ({ field, input, setFieldValue }) => {
  const [value, setValue] = React.useState(input.document.personal_document_type_id)
  field.value = value
  input.document.personal_document_type_id = field.value
  const classes = useStyles()

  const handleChange = (event, value) => {
    field.onChange(value)
    setValue(value)
  }

  return (
    <Grid item sm={input.col}>
      <FormControl component='fieldset'>
        <ThemeProvider theme={theme}>
          <FormLabel className={classes.label} component='legend'>
            {input.label}
          </FormLabel>
          <RadioGroup {...field} valueSelected={field.value} onChange={handleChange} row>
            {input.options.map(option => (
              <FormControlLabel value={option.id} control={<BlueRadio />} label={option.label} name={option.name} />
            ))}
          </RadioGroup>
        </ThemeProvider>
      </FormControl>
    </Grid>
  )
}

export default RadioInput
