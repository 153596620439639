import React from 'react'
import styled from 'styled-components'
import EmailIcon from '@material-ui/icons/Email'
import { useHistory } from 'react-router-dom'

import { SelectStatusPartner } from '../../components'

import { formatCNPJ, sanitizeDate, formatPhoneWithoutPrefix } from '../../helpers'

export const partnersColumns = [
  {
    Header: 'Nome do parceiro',
    accessor: 'partner_name',
    minWidth: 220,
    Cell: ({ original: { first_name, last_name, user } }) => (
      <ContactContainer>
        <span>{`${first_name || ''} ${last_name || ''}`}</span>
        <StyledEmailIcon onClick={() => user && user.email && window.open(`mailto:${user.email}`)} />
      </ContactContainer>
    )
  },
  {
    Header: 'Celular',
    accessor: 'celphone',
    width: 125,
    Cell: ({ original: { celphone_prefix, celphone } }) => (
      <span>{`(${celphone_prefix || '##'}) ${formatPhoneWithoutPrefix(celphone)}`}</span>
    )
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    minWidth: 220,
    Cell: ({ original: { user } }) => <span>{user && user.email}</span>
  },
  {
    Header: 'Razão Social',
    accessor: 'company_name',
    minWidth: 280
  },
  {
    Header: 'CNPJ',
    accessor: 'cnpj',
    width: 150,
    Cell: ({ original: { cnpj } }) => <span>{formatCNPJ(cnpj)}</span>
  },
  {
    Header: 'Data de cadastro',
    accessor: 'created_at',
    className: 'align-center',
    width: 130,
    Cell: ({ original: { created_at } }) => <span>{sanitizeDate(created_at)}</span>
  },
  {
    Header: 'Indicações',
    accessor: 'leads',
    width: 160,
    Cell: ({ original: { leads, first_name, last_name } }) => {
      const history = useHistory()

      const handleClickLeads = () => {
        history.push(`/solicitacoes-de-credito?partner=${first_name || ''} ${last_name || ''}`, { leads })
      }
      return (
        <DetailsContainer>
          <span>{leads.length}</span>
          <Details onClick={handleClickLeads}>Ver indicações</Details>
        </DetailsContainer>
      )
    }
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'align-center',
    width: 120,
    Cell: ({ original: partner }) => <SelectStatusPartner partner={partner} />
  }
]

const ContactContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const StyledEmailIcon = styled(EmailIcon)`
  color: #757575;
  cursor: pointer;
`

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const Details = styled.p`
  cursor: pointer;
  text-decoration-line: underline;
`
