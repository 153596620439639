import React, { Fragment, useState, useEffect, useRef } from 'react'
import { CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core'
import { toast } from 'react-toastify'
import { Add as ExpandIcon, Remove as CloseIcon } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'

import { InvestorPanel } from '../../components'
import {
  FiltersContainer,
  HeaderContainer,
  CheckboxContainer,
  Title,
  IncomeReportLink,
  ButtonRed,
  PersonContainer,
  Investments,
  InvestmentsLink,
  PersonName,
  Person,
  Button,
  Select,
  Filters,
  Filter,
  Investors,
  Container,
  LoaderContainer
} from './style'
import ModalUserDelete from './ModalUserDelete'

import { checkIsCompletedInvestor } from '../../helpers'
import { downloadIncomeReportPDF, generateIncomeReportData } from '../../utils'
import { getUsersWithParams, getRoles, deleteUser } from '../../services/v3'

const searchOptions = [
  { value: 'name', name: 'Nome' },
  { value: 'email', name: 'E-mail' },
  { value: 'document_number', name: 'CPF' },
  { value: 'phone', name: 'Telefone' },
  { value: 'user_id', name: 'User ID' }
]

const InvestorsComponent = ({ history }) => {
  const [loading, setLoading] = useState(true)
  const [option, setOption] = useState('name')
  const [userID, setUserID] = useState(null)
  const [filter, setFilter] = useState('')
  const [isOpenModalUserDelete, setIsOpenModalUserDelete] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [filterRoles, setFilterRoles] = useState(['Administrator', 'Investor', 'Signer'])
  const [isComplete, setIsComplete] = useState(false)

  const inputSearchText = useRef(null)

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setLoading(true)
        const { data: roles } = await getRoles()
        setRoles(roles)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    const querry = history.location.search
    if (querry) {
      const role = querry.slice(history.location.search.length - 1)
      switch (role) {
        case 'S':
          setFilterRoles(['Signer'])
          break
        case 'A':
          setFilterRoles(['Administrator'])
          break
        case 'I':
          setFilterRoles(['Investor'])
          break
        default:
          setFilterRoles(['Administrator', 'Investor', 'Signer'])
          break
      }
    }

    fetchInfos()
    inputSearchText.current.focus()
  }, [history.location.search])

  const handleFilterChange = async e => {
    setFilter(e.target.value)
  }

  const handleKeyDown = ({ key }) => key === 'Enter' && searchUser()

  const searchUser = async () => {
    if (filter.length > 2 || option === 'user_id') {
      try {
        setLoading(true)

        const queryParams = filterRoles.reduce((acc, filterRole) => {
          const roleid = roles.find(({ name }) => name === filterRole)
          if (filterRole === 'deleted') return `${acc}&deleted=true`
          return roleid ? `${acc}&role_id=${roleid.id}` : acc
        }, `${option}=${filter}`)

        const { data: users } = await getUsersWithParams(queryParams)

        if (!users || users.length === 0) {
          toast.info('Não há usuários correspondentes à sua busca')
          return setUsers([])
        }

        const fetchedUsers = await Promise.all(
          users.map(async user => {
            const userInfo = {
              ...user,
              ...(user.signer ? user.signer : null),
              ...(user.investor ? user.investor : null),
              created_at_user: user.created_at
            }
            return {
              ...userInfo,
              profileComplete: await checkIsCompletedInvestor(userInfo)
            }
          })
        )

        setUsers(isComplete ? fetchedUsers.filter(user => user.profileComplete === true) : fetchedUsers)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar os usuários')
        setUsers([])
      } finally {
        setLoading(false)
      }
    } else {
      toast.error('Digite pelo menos três caracteres')
    }
  }

  const handleIconClick = kvdID => (userID === kvdID ? setUserID(null) : setUserID(kvdID))

  const handleDeleteUser = async () => {
    try {
      if (!userToDelete) throw new Error('Usuário não selecionado')
      await deleteUser(userToDelete)
      toast.success('Usuário excluido com sucesso.')
      searchUser()
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao tentar excluir o usuário')
    } finally {
      setIsOpenModalUserDelete(false)
      setUserToDelete(null)
    }
  }

  const openModalUserDelete = kvd_id => {
    setIsOpenModalUserDelete(true)
    setUserToDelete(kvd_id)
  }

  const changeFilterRoles = (role, checked) => {
    setFilterRoles(filterRoles =>
      checked ? [...filterRoles, role] : filterRoles.filter(filterRole => filterRole !== role)
    )
  }

  const handleDownloadIncomeReport = async (e, user) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      const userIncomeReportData = await generateIncomeReportData(user)
      if (!userIncomeReportData) {
        toast.info('Usuário não possui informe de rendimento')
        return
      }
      await downloadIncomeReportPDF(userIncomeReportData)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <Container>
      <Investors>
        <FiltersContainer>
          <HeaderContainer>
            <Title>Busca por: </Title>
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#076f9f' }}
                    onChange={e => changeFilterRoles('Investor', e.target.checked)}
                    checked={filterRoles.some(role => role === 'Investor')}
                  />
                }
                label='Investidores'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#076f9f' }}
                    onChange={e => changeFilterRoles('Administrator', e.target.checked)}
                    checked={filterRoles.some(role => role === 'Administrator')}
                  />
                }
                label='Administradores'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#076f9f' }}
                    onChange={e => changeFilterRoles('Signer', e.target.checked)}
                    checked={filterRoles.some(role => role === 'Signer')}
                  />
                }
                label='Assinantes'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#076f9f' }}
                    onChange={e => changeFilterRoles('deleted', e.target.checked)}
                    checked={filterRoles.some(role => role === 'deleted')}
                  />
                }
                label='Deletados'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#076f9f' }}
                    onChange={() => setIsComplete(!isComplete)}
                    checked={isComplete}
                  />
                }
                label='Cadastro completo'
              />
            </CheckboxContainer>
          </HeaderContainer>
          <Filters>
            <Select value={option} onChange={e => setOption(e.target.value)}>
              {searchOptions.map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </Select>
            <Filter
              value={filter}
              onKeyDown={handleKeyDown}
              onChange={handleFilterChange}
              type='text'
              placeholder='Busque por nome, cpf, email, ...'
              ref={inputSearchText}
            />
            <Button disabled={loading} onClick={searchUser}>
              {loading ? <CircularProgress style={{ color: '#fff', width: 18, height: 18 }} /> : 'Buscar'}
            </Button>
          </Filters>
        </FiltersContainer>
        {loading ? (
          <LoaderContainer>
            <CircularProgress style={{ color: '#0275d8', width: 25, height: 25 }} />
          </LoaderContainer>
        ) : (
          users.map(user => {
            const completeNameInfo = `${user.first_name} ${user.last_name} (user_id: ${user.user_id})`
            return (
              <Fragment key={user.user_id}>
                <Person onClick={e => handleIconClick(user.user_id)} profileComplete={user.profileComplete}>
                  <PersonContainer>
                    {user.deleted_at ? (
                      <PersonName>Usuário deletado - {completeNameInfo}</PersonName>
                    ) : (
                      <PersonName>
                        {user.investor && user.investor.id ? '' : 'Cadastro inicial - '} {completeNameInfo}
                      </PersonName>
                    )}
                    <Investments>
                      <IncomeReportLink onClick={e => handleDownloadIncomeReport(e, user)}>Informe IR</IncomeReportLink>
                      {user.email && <ButtonRed onClick={() => openModalUserDelete(user.user_id)}>Excluir</ButtonRed>}
                      <InvestmentsLink href={`/usuarios/${user.user_id}/investimentos`} target='_blank'>
                        Ver investimentos
                      </InvestmentsLink>
                    </Investments>
                  </PersonContainer>
                  {userID === user.user_id ? (
                    <CloseIcon onClick={() => handleIconClick(user.user_id)} style={{ cursor: 'pointer' }} />
                  ) : (
                    <ExpandIcon onClick={() => handleIconClick(user.user_id)} style={{ cursor: 'pointer' }} />
                  )}
                </Person>
                {userID === user.user_id && <InvestorPanel user={user} />}
              </Fragment>
            )
          })
        )}
      </Investors>
      {isOpenModalUserDelete && (
        <ModalUserDelete
          open={isOpenModalUserDelete}
          onClose={_ => setIsOpenModalUserDelete(false)}
          handleDelete={handleDeleteUser}
        />
      )}
    </Container>
  )
}

export default withRouter(InvestorsComponent)
