import styled from 'styled-components'
import { colors } from '../../../../Themes/colors'

export const Container = styled.div`
  display: flex;

  input {
    min-width: 384px;
    width: 20%;
    margin-bottom: 15px;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #757575;
    border-radius: 4px;
    font-size: 16px;
    ::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #9e9e9e;
    }
    :focus {
      border-color: ${colors.blue};
    }

    @media (max-width: 600px) {
      width: 100%;
      min-width: 260px;
    }
  }

  img {
    z-index: 2;
    margin-left: -30px;
    margin-bottom: 15px;
  }
`
