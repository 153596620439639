import React from 'react'
import Popover from '@material-ui/core/Popover'
import styled from 'styled-components'

import { formatPhoneWithoutPrefix } from '../../helpers'

const PopoverLeadContact = ({ anchor, setAnchor, lead }) => {
  return (
    <StyledPopover
      id='responsible-popover'
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => {
        setAnchor(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <InfoContainer>
        <Info>
          <Bold>Nome:</Bold> {lead.first_name}
        </Info>
        <Info>
          <Bold>Sobrenome:</Bold> {lead.last_name || 'não informado'}
        </Info>
        <Info>
          <Bold>Celular:</Bold> {`(${lead.celphone_prefix || '##'}) ${formatPhoneWithoutPrefix(lead.celphone)}`}
        </Info>
        <Info>
          <Bold>Telefone Fixo:</Bold> {`(${lead.telephone_prefix || '##'}) ${formatPhoneWithoutPrefix(lead.telephone)}`}
        </Info>
        <Info>
          <Bold>E-mail:</Bold> {lead.email}
        </Info>
      </InfoContainer>
    </StyledPopover>
  )
}

const StyledPopover = styled(Popover)`
  pointer-events: none;
`

export const Info = styled.p`
  font-size: 13px;
  margin-bottom: 10px;
  color: #424242;
  :last-child {
    margin-bottom: 0px;
  }
`

export const InfoContainer = styled.div`
  padding: 8px;
`

export const Bold = styled.span`
  font-weight: 500;
  font-size: 13px;
`

export default PopoverLeadContact
