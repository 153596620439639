import React from 'react'
import { Content } from './styles'
import PropTypes from 'prop-types'

const DetailsButton = ({ onClick }) => {
  return (
    <Content title='Ver detalhes'>
      <button onClick={onClick}>Ver detalhes</button>
    </Content>
  )
}

DetailsButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default DetailsButton
