import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import { MenuItem as Menu } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { toast } from 'react-toastify'

import { updateLeads } from '../../services/v3'
import { leadStatusBgColors, leadSatusFontColor, leadStatusBorderColors } from '../../styles'
import { LeadContext } from '../../contexts'

import ModalRecusedTable from './components/ModalRecusedTable'

const useStyles = makeStyles({
  root: {
    '& .MuiSelect-root': {
      padding: '0px',
      backgroundColor: 'transparent',
      border: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiSelect-icon': {
      display: 'none'
    }
  }
})

const SelectStatusLead = ({ lead }) => {
  const [status, setStatus] = useState(lead.lead_status.id)

  const [modalRecusedIsOpen, setModalRecusedIsOpen] = useState(false)

  const classes = useStyles()

  const { refresh, leadStatuses } = useContext(LeadContext)

  useEffect(() => {
    setStatus(lead.lead_status.id)
  }, [lead])

  const handleChangeStatus = async e => {
    if (e.target.value === 11) {
      setModalRecusedIsOpen(!modalRecusedIsOpen)
    } else {
      try {
        const body = {
          lead_status_id: e.target.value
        }
        await updateLeads(lead.id, body)
        setStatus(e.target.value)
        refresh()
      } catch (error) {
        toast.error('Ocorreu um erro ao mudar o status')
      }
    }
  }
  return (
    <>
      <StatusContainer>
        <Select className={classes.root} value={status} onChange={handleChangeStatus} variant='outlined'>
          {leadStatuses.map(({ id, name, selectable }) => (
            <MenuItem key={id} value={id} disabled={!selectable}>
              <Status
                bgColor={leadStatusBgColors[name] || '#2F80ED'}
                fontColor={leadSatusFontColor[name] || '#ffffff'}
                borderColor={leadStatusBorderColors[name] || ''}
              >
                {name}
              </Status>
            </MenuItem>
          ))}
        </Select>
      </StatusContainer>
      <ModalRecusedTable
        id={lead.id}
        oldStatus={lead.lead_status.id}
        open={modalRecusedIsOpen}
        handleClose={() => setModalRecusedIsOpen(false)}
        buttonClick={() => setModalRecusedIsOpen(false)}
        setStatus={setStatus}
      />
    </>
  )
}

const Status = styled.div`
  width: 220px;
  height: 25px;
  border-radius: 15px;
  color: ${({ fontColor }) => fontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  text-align: center;
  line-height: initial;
  background-color: ${({ bgColor }) => bgColor};
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor};
`

const StatusContainer = styled.div`
  display: flex;
  position: relative;
`
const MenuItem = styled(Menu)`
  &&.MuiListItem-root.Mui-disabled {
    display: none;
  }
`

export default SelectStatusLead
