import axios from 'axios'

// export const __API__ = 'https://api-hmg.kavodlending.com'

// export const __API__ = 'ec2-18-231-172-73.sa-east-1.compute.amazonaws.com'

// export const __API__ = 'https://api-prod.kavodlending.com'

// export const __API__ = 'http://192.168.18.13:8080'

// export const __API__ = 'http://localhost:8080'

export const getEnviroment = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://api-prod.kavodlending.com'
    case 'homolog':
      return 'https://api-hmg.kavodlending.com'
    case 'dev':
    default:
      return 'https://api-dev.kavodlending.com'
  }
}

const defaultOptions = {
  baseURL: getEnviroment()
}

let instance = axios.create(defaultOptions)

instance.interceptors.request.use(config => {
  /* global localStorage */
  config.headers.Authorization = localStorage.getItem('@kavod:token')
  return config
})

instance.interceptors.response.use(
  function(response) {
    return response
  },
  error => {
    if (error && error.response && error.response.status === 401 && window.location.pathname !== '/login') {
      window.location.href = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)
export default instance
