import * as yup from 'yup'

import { renegotiationTypes } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório'
  }
})

export const renegotiationSchema = yup.object().shape({
  tax_default_interest: yup.string().required(),
  operation_tax: yup.string().required(),
  tax_fine: yup.string().required(),
  number_installments: yup.string().required(),
  renegotiate_date: yup
    .string()
    .nullable()
    .required()
    .test('valid_date', 'Data inválida', date => date !== 'Invalid date'),
  new_grace_period: yup.string().required(),
  new_rate_month: yup.string().required(),
  type: yup.string().required(),
  new_first_due: yup.string().when('type', {
    is: renegotiationTypes.IRREGULAR,
    then: yup
      .string()
      .nullable()
      .required()
      .test('valid_date', 'Data inválida', date => date !== 'Invalid date')
  }),
  irregular_installments: yup.array().of(
    yup.object().shape({
      installment_number: yup.string().required(),
      amount: yup.string().required()
    })
  )
})

export const modalSendDocumentsResolver = yup.object().shape({
  observation: yup.string()
})

export const modalAproveResolver = yup.object().shape({
  document_type: yup.string().required('Campo obrigatório.'),
  name: yup.string().required('Campo obrigatório.')
})

export * from './anticipationSchema'
export * from './creditRequest'
export * from './editLeadIndicatorSchema'
export * from './leadUpdate'
export * from './newCreditRequest'
export * from './newCreditRequestList'
export * from './ticketSchema'
