import React, { useState } from 'react'
import moment from 'moment-timezone'
import ReactTooltip from 'react-tooltip'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { Loader, InvestorPanel, Dropdown } from '../../components'
import ModalPledgeTable from './ModalPledgeTable'
import { Add as ExpandIcon, Remove as CloseIcon, List, Comment as CommentIcon } from '@material-ui/icons'

import { formatMoney } from '../../helpers'
import { getInvestors, updatePledge } from '../../services/v3'

import { withTheme } from 'emotion-theming'

const Pledges = ({
  pledges,
  handleUpdate,
  handleUpdatePledges,
  reload,
  campaign,
  pledgeStatuses,
  reopenCampaingModal
}) => {
  const [isOpenModalPledgeTable, setIsOpenModalPledgeTable] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const handleModalPledgeTable = () => setIsOpenModalPledgeTable(!isOpenModalPledgeTable)

  const findStatusColor = status => {
    switch (status) {
      case 'A':
        return '#5cb85c'
      case 'AC':
        return '#5cb85c'
      case 'C':
        return '#d9534f'
      case 'OH':
        return '#076F9F'
      case 'D':
        return '#d9534f'
      default:
        break
    }
  }

  const formatStatus = status => {
    switch (status) {
      case 'A':
        return 'Confirmado'
      case 'AC':
        return 'Processado'
      case 'C':
        return 'Cancelado'
      case 'OH':
        return 'Em espera'
      case 'D':
        return 'Deletado'
      default:
        break
    }
  }

  const handlePanel = async userId => {
    try {
      setIsLoading(true)
      setIsExpanded(!isExpanded)
      if (!isExpanded) {
        const {
          data: [investor]
        } = await getInvestors({ user_id: userId })
        setUserInfo({
          investor,
          ...investor.user,
          ...investor,
          created_at_user: investor.user && investor.user.created_at
        })
        setHasError(false)
      }
    } catch {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const renderUserInfo = () => (
    <UserInfoCardContainer error={hasError}>
      {hasError ? (
        <ErrorContainer>
          <Error>Tivemos alguns erros ao buscar as informações do usuário. </Error>
        </ErrorContainer>
      ) : (
        <React.Fragment>
          <InvestorPanel user={userInfo} />
        </React.Fragment>
      )}
    </UserInfoCardContainer>
  )

  const changePledgesStatus = (pledges, newStatus) => {
    handleUpdatePledges(pledges, newStatus)
    setIsOpenModalPledgeTable(false)
  }

  const handleCheckbox = async e => {
    const isChecked = e.target.checked
    const type = e.target.id
    try {
      Promise.all(
        pledges.map(async pledge => {
          if (isChecked) {
            switch (type) {
              case 'whats':
                await updatePledge(pledge.id, { whatsapp_is_confirmed: true })
                break
              case 'ted':
                await updatePledge(pledge.id, { ted_is_confirmed: true })
                break
              default:
                break
            }
          } else {
            switch (type) {
              case 'whats':
                await updatePledge(pledge.id, { whatsapp_is_confirmed: false })
                break
              case 'ted':
                await updatePledge(pledge.id, { ted_is_confirmed: false })
                break
              default:
                break
            }
          }
        })
      ).then(() => reload())

      toast.success('Pedido atualizado')
    } catch (error) {}
  }

  return (
    <Container>
      <Header>
        <ModalPledgeTable
          open={isOpenModalPledgeTable}
          onClose={handleModalPledgeTable}
          pledges={pledges}
          campaign={campaign}
          reload={reload}
          handleUpdate={handleUpdate}
          pledgeStatuses={pledgeStatuses}
          reopenCampaingModal={reopenCampaingModal}
        />
        <UserInfoContainer>
          <ExpandInfoIconContainer>
            {isExpanded ? (
              <CloseIcon
                style={{ margin: '0 0.4em', fontZize: '2em !important', cursor: 'pointer' }}
                data-tip='Expandir informações do usuário '
                isExpanded={isExpanded}
                onClick={() => handlePanel(pledges[0].investor.user_id)}
              />
            ) : (
              <ExpandIcon
                style={{ margin: '0 0.4em', fontZize: '2em !important', cursor: 'pointer' }}
                data-tip='Expandir informações do usuário '
                isExpanded={isExpanded}
                onClick={() => handlePanel(pledges[0].investor.user_id)}
              />
            )}
          </ExpandInfoIconContainer>
          <UserInfo>
            <UserName>
              {`${pledges[0].investor.first_name} ${pledges[0].investor.last_name}`} (user_id:{' '}
              {pledges[0].investor.user_id})
            </UserName>
            <p>
              <span>Valor Total: </span>R$ {formatMoney(pledges.reduce((total, pledge) => total + pledge.amount, 0))}
            </p>
            <p>
              <span>1º Pedido: </span>
              {pledges[0].id} | <span>Hora: </span>
              {moment(pledges[0].created_at)
                .add(3, 'hours')
                .format('DD/MM/YYYY - HH:mm')}
            </p>
            <InvestmentsLink onClick={_ => window.open(`/usuarios/${pledges[0].investor.user_id}/investimentos`)}>
              Ver investimentos
            </InvestmentsLink>
          </UserInfo>
        </UserInfoContainer>
        <OptionsContainer>
          <StatusContainer>
            <Filter c='#000' fw='300' p='2px' hidden={'true'}>
              <div>
                <input
                  onChange={handleCheckbox}
                  id='whats'
                  type='checkbox'
                  defaultChecked={pledges.every(pledge => !!pledge.whatsapp_is_confirmed === true)}
                />
                <img htmlFor='whats' src='/assets/images/icon-whatsapp.png' alt='Whatsapp' />
              </div>
            </Filter>
            <Filter c='#000' fw='300' hidden={'true'}>
              <div>
                <input
                  onChange={handleCheckbox}
                  id='ted'
                  type='checkbox'
                  defaultChecked={pledges.every(pledge => !!pledge.ted_is_confirmed === true)}
                />
                <label htmlFor='ted'>TED / Pix</label>
              </div>
            </Filter>
            <Dropdown
              disabled={['AC', 'D'].includes(pledges[0].pledge_status.abbreviation)}
              titleBg={findStatusColor(pledges[0].pledge_status.abbreviation)}
              title={formatStatus(pledges[0].pledge_status.abbreviation)}
              list={[
                {
                  title: 'Confirmado',
                  onClick: () => changePledgesStatus(pledges, 'A')
                },
                {
                  title: 'Em espera',
                  onClick: () => changePledgesStatus(pledges, 'OH')
                },
                {
                  title: 'Cancelado',
                  onClick: () => changePledgesStatus(pledges, 'C')
                }
              ]}
            />
          </StatusContainer>
          <List
            data-tip='Mostrar histórico'
            fontSize='large'
            style={{
              margin: '0 10px',
              cursor: 'pointer'
            }}
            onClick={handleModalPledgeTable}
          />
          {pledges.find(pledge => pledge.observation) && (
            <CommentIcon
              data-tip='Possui comentários'
              fontSize='default'
              onClick={handleModalPledgeTable}
              style={{ cursor: 'pointer' }}
            />
          )}
        </OptionsContainer>
        <ReactTooltip />
      </Header>
      <ExpansionPanel isExpanded={isExpanded}>
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          userInfo && renderUserInfo()
        )}
      </ExpansionPanel>
    </Container>
  )
}

const ExpandInfoIconContainer = styled.div`
  @media (max-width: 769px) {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 769px) {
    padding: 0.5em 0;
  }
`

const InvestmentsLink = styled.button`
  margin: 1em 0;
  background: transparent;
  border: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blueKavod};
  cursor: pointer;
  outline: none;
  text-decoration: underline;
`

const ErrorContainer = styled.div`
  display: flex;
  padding: 1em;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Error = styled.p``

const UserInfoCardContainer = styled.div`
  border-top: ${({ error }) => (error ? 'none' : '1px solid rgba(0, 0, 0, 0.125)')};
  margin-top: 1em;
  justify-content: space-between;
  display: flex;
  flex: 1;
  align-items: flex-start;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1em;
`

const ExpansionPanel = styled.div`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  padding: 1em;
`

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  @media (max-width: 769px) {
    flex-direction: column-reverse;
    width: initial;
  }
`

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
`

const UserName = styled.p`
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 20px;
`

const Container = styled.div`
  padding: 16px;
  transition: all 1s;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 769px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
`
const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => (!!props.p ? `${props.p} 16px` : '16px')};
  padding-left: ${props => (!!props.p ? '16px' : '')};

  div {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
  }
  label {
    background-color: ${props => props.labelBg};
    color: ${props => (!!props.c ? props.c : 'white')};
    padding: 0.25em 0.4em;
    border-radius: 0.25rem;
    font-weight: ${props => (!!props.fw ? props.fw : '900')};
    line-height: 1;
  }
  input {
    width: 15px;
    height: 15px;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    padding: 16px;
    height: 100px;
    justify-content: center;
  }
`

export default withTheme(Pledges)
