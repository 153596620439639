import React, { useState } from 'react'
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import { Dropdown, PledgeModal, Loader, InvestorPanel } from '../../components'
import { Add as ExpandIcon, Remove as CloseIcon, Edit, Comment as CommentIcon } from '@material-ui/icons'

import { formatMoney } from '../../helpers'
import { getInvestors, updatePledge } from '../../services/v3'

import { withTheme } from 'emotion-theming'

const Pledge = ({ pledge, handleUpdate, reload, campaign, pledgeStatuses, reopenCampaingModal, index = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const handleModal = () => setIsOpen(!isOpen)

  const findStatusColor = status => {
    switch (status) {
      case 'A':
        return '#5cb85c'
      case 'AC':
        return '#5cb85c'
      case 'C':
        return '#d9534f'
      case 'OH':
        return '#076F9F'
      case 'D':
        return '#d9534f'
      default:
        break
    }
  }

  const formatStatus = status => {
    switch (status) {
      case 'A':
        return 'Confirmado'
      case 'AC':
        return 'Processado'
      case 'C':
        return 'Cancelado'
      case 'OH':
        return 'Em espera'
      case 'D':
        return 'Deletado'
      default:
        break
    }
  }

  const handleCheckbox = async e => {
    const isChecked = e.target.checked
    const type = e.target.id
    try {
      if (isChecked) {
        switch (type) {
          case 'whats':
            await updatePledge(pledge.id, { whatsapp_is_confirmed: true })
            break
          case 'ted':
            await updatePledge(pledge.id, { ted_is_confirmed: true })
            break
          default:
            break
        }
      } else {
        switch (type) {
          case 'whats':
            await updatePledge(pledge.id, { whatsapp_is_confirmed: false })
            break
          case 'ted':
            await updatePledge(pledge.id, { ted_is_confirmed: false })
            break
          default:
            break
        }
      }
      reload()
      return toast.success('Pedido atualizado')
    } catch (error) {}
  }

  const handlePanel = async userId => {
    try {
      setIsLoading(true)
      setIsExpanded(!isExpanded)
      if (!isExpanded) {
        const {
          data: [investor]
        } = await getInvestors({ user_id: userId })

        setUserInfo({
          investor,
          ...investor.user,
          ...investor,
          created_at_user: investor.user && investor.user.created_at
        })
        setHasError(false)
      }
    } catch {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const renderUserInfo = () => (
    <UserInfoCardContainer error={hasError}>
      {hasError ? (
        <ErrorContainer>
          <Error>Tivemos alguns erros ao buscar as informações do usuário. </Error>
        </ErrorContainer>
      ) : (
        <React.Fragment>
          <InvestorPanel user={userInfo} />
        </React.Fragment>
      )}
    </UserInfoCardContainer>
  )

  return (
    <Container>
      <Header>
        <UserInfoContainer>
          <ExpandInfoIconContainer>
            {isExpanded ? (
              <CloseIcon
                style={{ margin: '0 0.4em', fontZize: '2em !important', cursor: 'pointer' }}
                data-tip='Expandir informações do usuário '
                isExpanded={isExpanded}
                onClick={() => handlePanel(pledge.investor.user_id)}
              />
            ) : (
              <ExpandIcon
                style={{ margin: '0 0.4em', fontZize: '2em !important', cursor: 'pointer' }}
                data-tip='Expandir informações do usuário '
                isExpanded={isExpanded}
                onClick={() => handlePanel(pledge.investor.user_id)}
              />
            )}
          </ExpandInfoIconContainer>
          <UserInfo>
            <UserName>
              {`${pledge.investor.first_name} ${pledge.investor.last_name}`} (user_id: {pledge.investor.user_id})
            </UserName>
            <p>
              <span>Valor: </span>R$ {formatMoney(pledge.amount)}
            </p>
            <p>
              <span>Pedido: </span>
              {pledge.id} | <span>Hora: </span>
              {moment(pledge.created_at)
                .add(3, 'hours')
                .format('DD/MM/YYYY - HH:mm')}
            </p>
            <InvestmentsLink onClick={_ => window.open(`/usuarios/${pledge.investor.user_id}/investimentos`)}>
              Ver investimentos
            </InvestmentsLink>
          </UserInfo>
        </UserInfoContainer>
        <StatusContainer>
          {index && (
            <Filter c='#000' fw='300' p='2px' hidden={'true'}>
              <div>
                <input
                  onChange={handleCheckbox}
                  id='whats'
                  type='checkbox'
                  defaultChecked={pledge.whatsapp_is_confirmed}
                />
                <img htmlFor='whats' src='/assets/images/icon-whatsapp.png' alt='Whatsapp' />
              </div>
            </Filter>
          )}
          {index && (
            <Filter c='#000' fw='300' hidden={'true'}>
              <div>
                <input onChange={handleCheckbox} id='ted' type='checkbox' defaultChecked={pledge.ted_is_confirmed} />
                <label htmlFor='ted'>TED / Pix</label>
              </div>
            </Filter>
          )}

          <Dropdown
            disabled={['AC', 'D'].includes(pledge.pledge_status.abbreviation)}
            titleBg={findStatusColor(pledge.pledge_status.abbreviation)}
            title={formatStatus(pledge.pledge_status.abbreviation)}
            list={[
              {
                title: 'Confirmado',
                onClick: () => handleUpdate(pledge, 'A')
              },
              {
                title: 'Em espera',
                onClick: () => handleUpdate(pledge, 'OH')
              },
              {
                title: 'Cancelado',
                onClick: () => handleUpdate(pledge, 'C')
              }
            ]}
          />
          <Edit
            data-tip='Editar pedido'
            fontSize='large'
            style={{
              margin: '0 10px',
              cursor: 'pointer',
              visibility:
                ['AC', 'D'].includes(pledge.pledge_status.abbreviation) && !pledge.observation ? 'hidden' : 'visible'
            }}
            onClick={handleModal}
          />
          {pledge.observation && (
            <CommentIcon
              data-tip='Possui comentários'
              fontSize='default'
              onClick={handleModal}
              style={{ cursor: 'pointer' }}
            />
          )}
          <ReactTooltip />
        </StatusContainer>
      </Header>
      {isOpen && (
        <PledgeModal
          edit
          isEditable={!['AC', 'D'].includes(pledge.pledge_status.abbreviation)}
          campaign={campaign}
          reload={reload}
          handleClick={handleModal}
          pledge={pledge}
          title='Editar Pedido'
          pledgeStatuses={pledgeStatuses}
          reopenCampaingModal={reopenCampaingModal}
        />
      )}
      <ExpansionPanel isExpanded={isExpanded}>
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          userInfo && renderUserInfo()
        )}
      </ExpansionPanel>
    </Container>
  )
}

const ExpandInfoIconContainer = styled.div`
  @media (max-width: 769px) {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 769px) {
    padding: 0.5em 0;
  }
`

const InvestmentsLink = styled.button`
  margin: 1em 0;
  background: transparent;
  border: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blueKavod};
  cursor: pointer;
  outline: none;
  text-decoration: underline;
`

const ErrorContainer = styled.div`
  display: flex;
  padding: 1em;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Error = styled.p``

const UserInfoCardContainer = styled.div`
  border-top: ${({ error }) => (error ? 'none' : '1px solid rgba(0, 0, 0, 0.125)')};
  margin-top: 1em;
  justify-content: space-between;
  display: flex;
  flex: 1;
  align-items: flex-start;
`

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1em;
`

const ExpansionPanel = styled.div`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  padding: 1em;
`

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 500px;
  @media (max-width: 769px) {
    flex-direction: column-reverse;
    width: initial;
  }
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const UserName = styled.p`
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 20px;
`

const Container = styled.div`
  padding: 16px;
  transition: all 1s;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 769px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => (!!props.p ? `${props.p} 16px` : '16px')};
  padding-left: ${props => (!!props.p ? '16px' : '')};

  div {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
  }
  label {
    background-color: ${props => props.labelBg};
    color: ${props => (!!props.c ? props.c : 'white')};
    padding: 0.25em 0.4em;
    border-radius: 0.25rem;
    font-weight: ${props => (!!props.fw ? props.fw : '900')};
    line-height: 1;
  }
  input {
    width: 15px;
    height: 15px;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    padding: 16px;
    height: 100px;
    justify-content: center;
  }
`

export default withTheme(Pledge)
