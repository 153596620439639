import styled from 'styled-components'
import { colors } from '../../../../../Themes/colors'

export const Container = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: block;
  }
  padding: 16px;
  &:nth-of-type(odd) {
    background-color: #fafafa;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
  }

  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-of-type(2) {
      flex-direction: row-reverse;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    display: flex;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    margin-top: 8px;
  }
`
export const OpenDetails = styled.button`
  color: ${colors.blue};
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
