import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../components/v2Components'

import { Container } from './styled'

const CardList = ({ cards }) => {
  return (
    <Container>
      {cards.map((card, index) => (
        <Card key={`${index}`} card={card} wid={true} />
      ))}
    </Container>
  )
}

CardList.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      information: PropTypes.bool,
      main: PropTypes.bool
    }).isRequired
  ).isRequired
}

export default CardList
