export const getSiteLink = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'homolog':
      return 'https://hmg.kavodlending.com'
    case 'production':
      return 'https://kavodlending.com'
    case 'development':
    default:
      return 'https://dev.kavodlending.com'
  }
}
