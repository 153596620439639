import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

export const popoverFmmSchema = yupResolver(
  yup.object().shape({
    initial_value: yup.string().required('Valor mínimo obrigatório.'),
    final_value: yup
      .string()
      .required('Valor final obrigatório.')
      .nullable()
  })
)
