/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMoreSharp } from '@material-ui/icons'
import { Formik, Form, FieldArray, ErrorMessage } from 'formik'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import {
  MaterialButton,
  ProcessCampaignGuarantorsForm,
  ProcessCampaignCompanyForm,
  ProcessCampaignCampaignForm,
  ProcessCampaignSubscribersForm,
  LoadingComponent,
  ProcessCampaignPartnerForm
} from '../../components'
import {
  HeaderContainer,
  ClientPartner,
  PartnersContainer,
  Partners,
  Guarantors,
  RemoveGuarantor,
  InputWrapper,
  Title,
  AccordionContainer,
  Container,
  Error,
  SendContainer
} from './style'

import ModalSummary from './ModalSummary'

import { processCampaignForm } from '../../constants/yupSchemas'
import { useDebounce } from '../../hooks'
import { formatGuarantors, formatPartners, formatSubscribers, formatProposals } from '../../helpers'
import { IDLE, FETCHING, SUBMITTING, LOADING } from '../../constants/status'
import {
  getCampaignById,
  runCampaign,
  getCampaignInstallmentValues,
  getMaritalTypes,
  getUnionTypes
} from '../../services/v3'

const ProcessCampaign = ({ match, history, updateCampaignStatus }) => {
  const [expanded, setExpanded] = useState(false)
  const [campaign, setCampaign] = useState(null)
  const [temporaryFields, setTemporaryFields] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [status, setStatus] = useState(IDLE)
  const [fetchingSigners, setFetchingSigners] = useState(false)
  const [maritalTypes, setMaritalTypes] = useState([])
  const [unionTypes, setUnionTypes] = useState([])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        setStatus(FETCHING)
        const [{ data: maritalTypes }, { data: unionTypes }, { data: campaign }] = await Promise.all([
          getMaritalTypes(),
          getUnionTypes(),
          getCampaignById(match.params.id)
        ])

        const guarantors =
          campaign.guarantors.length > 0
            ? await campaign.guarantors.map(item => {
                const { address } = item
                return {
                  guarantor_type: item.type,
                  guarantor_fantasy_name: !!item.fantasy_name ? item.fantasy_name : '',
                  guarantor_name: !!item.first_name ? `${item.first_name} ${item.last_name}` : '',
                  guarantor_document: !!item.identifier ? item.identifier : '',
                  guarantor_id_card: '',
                  guarantor_marital_status: !!item.marital_status_id ? Number(item.marital_status_id) : '',
                  guarantor_zip_code: !!address ? address.zip_code : '',
                  guarantor_street: !!address ? address.street : '',
                  guarantor_number: !!address ? address.number : '',
                  guarantor_neighborhood: !!address ? address.district : '',
                  guarantor_complement: !!address ? address.number : '',
                  guarantor_city: !!address ? address.city : '',
                  guarantor_uf: !!address ? address.uf : '',
                  guarantor_phone_prefix: '',
                  guarantor_phone: '',
                  guarantor_email: '',
                  guarantor_marital_type: !!item.union_type_id ? Number(item.union_type_id) : ''
                }
              })
            : [
                {
                  guarantor_type: 'PF',
                  guarantor_fantasy_name: '',
                  guarantor_name: '',
                  guarantor_document: '',
                  guarantor_id_card: '',
                  guarantor_marital_status: '',
                  guarantor_zip_code: '',
                  guarantor_street: '',
                  guarantor_number: '',
                  guarantor_neighborhood: '',
                  guarantor_complement: '',
                  guarantor_city: '',
                  guarantor_uf: '',
                  guarantor_phone_prefix: '',
                  guarantor_phone: '',
                  guarantor_email: '',
                  guarantor_marital_type: ''
                }
              ]

        setMaritalTypes(maritalTypes)
        setUnionTypes(unionTypes)
        setCampaign(campaign)

        setTemporaryFields({
          company_name: '',
          company_fantasy_name: '',
          company_cnpj: '',
          company_zip_code: '',
          company_street: '',
          company_number: '',
          company_city: '',
          company_complement: '',
          company_neighborhood: '',
          company_uf: '',
          company_country: '',
          company_email: '',
          company_phone: '',
          company_phone_prefix: '',
          company_bank_code: '',
          company_bank_agency: '',
          company_bank_account: '',
          company_bank_account_digit: '',
          company_bank_type: '',
          guarantors: guarantors,
          subscribers: [],
          partner_cnpj: '',
          partners:
            campaign.shareholders.length > 0
              ? campaign.shareholders.map(item => {
                  return {
                    partner_name: `${item.first_name} ${item.last_name}`,
                    partner_document: item.identifier.replace('.', '').replace('-', '')
                  }
                })
              : [{ partner_name: '', partner_document: '' }],
          campaign_document: null,
          campaign_requested_amount: null,
          campaign_time: null,
          campaign_first_due_date: moment()
            .add((campaign.grace_period + 30) / 30, 'M')
            .format('DD/MM/YYYY'),
          campaign_iof: null,
          campaign_tac: null,
          campaign_installment_value: null,
          campaign_operation_code: null,
          campaign_interest_negotiated: null,
          campaign_iof_percentual: campaign.is_without_iof ? '0' : 1.5,
          campaign_iof_aditional: campaign.is_without_iof ? '0' : 0.38,
          campaign_guarantee: String(!!campaign.collateral ? campaign.collateral : '-')
        })
      } catch (error) {
        console.error(error)
        toast.error('Não foi possível encontrar a campanha')
      } finally {
        setStatus(IDLE)
      }
    }

    fetchInfos()

    /*     setTemporaryFields(JSON.parse(localStorage.getItem(`@kavod:temporary_fields_${match.params.id}`))) */
  }, [])

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const debouncedTemporaryFields = useDebounce(temporaryFields, 2000)

  useEffect(
    _ => {
      if (debouncedTemporaryFields) {
        localStorage.setItem(`@kavod:temporary_fields_${match.params.id}`, JSON.stringify(debouncedTemporaryFields))
      }
    },
    [debouncedTemporaryFields]
  )

  const createNewSubscribers = async values => {
    const subsGuarantors = values.guarantors
      .filter(
        ({ guarantor_document, guarantor_type }) =>
          guarantor_document &&
          guarantor_type === 'PF' &&
          !values.subscribers.find(({ subscriber_document }) => subscriber_document === guarantor_document)
      )
      .map(guarantor => {
        return {
          subscriber_document: guarantor.guarantor_document,
          subscriber_name: guarantor.guarantor_name
        }
      })

    const subPartners = values.partners
      .filter(
        ({ partner_document }) =>
          partner_document &&
          !values.subscribers.find(({ subscriber_document }) => subscriber_document === partner_document) &&
          !subsGuarantors.find(({ subscriber_document }) => subscriber_document === partner_document)
      )
      .map(partner => {
        return {
          subscriber_name: partner.partner_name,
          subscriber_document: partner.partner_document
        }
      })

    const oldSubscribers = values.subscribers.filter(subscriber => subscriber.subscriber_document)

    const setSubs = new Set()
    return [...oldSubscribers, ...subsGuarantors, ...subPartners]
      .map(item => {
        return {
          subscriber_document: String(item.subscriber_document)
            .replaceAll('.', '')
            .replaceAll('-', ''),
          subscriber_name: item.subscriber_name
        }
      })
      .filter(item => {
        const duplicatedSubs = setSubs.has(item.subscriber_document)
        setSubs.add(item.subscriber_document)
        return !duplicatedSubs
      })
  }

  const handleSubmit = async values => {
    setStatus(SUBMITTING)
    try {
      const { data: proposals } = await getCampaignInstallmentValues(match.params.id)

      const formattedValues = {
        campaign_id: match.params.id,
        companyData: {
          company: {
            name: values.company_name,
            fantasy_name: values.company_fantasy_name,
            cnpj: values.company_cnpj
          },
          address: {
            street: values.company_street,
            number: values.company_number,
            city: values.company_city,
            complement: values.company_complement,
            zip_code: values.company_zip_code,
            district: values.company_neighborhood,
            uf: values.company_uf,
            country: values.company_country
          },
          email: values.company_email,
          phone: { prefix: values.company_phone_prefix, phone: values.company_phone },
          bank: {
            code: values.company_bank_code.split(' ')[0],
            agency: values.company_bank_agency,
            account: values.company_bank_account,
            account_digit: values.company_bank_account_digit,
            type: Number(values.company_bank_type) === 2 ? 'CC' : 'CP'
          }
        },
        avalistas: formatGuarantors(values.guarantors),
        assinantes: formatSubscribers(values.subscribers),
        garantia:
          campaign.campaign_type.id === 2
            ? `Aplicação financeira de ${campaign.collateral}% do valor retido sob gestão da Kavod Lending. ${
                campaign.guarantors.length > 1 ? 'Avalistas:' : 'Avalista:'
              }  ${campaign.guarantors.map(item => ` ${item.first_name} ${item.last_name}`).join(',')}`
            : `Aval de ${campaign.guarantors.length > 1 ? 'Avalistas:' : 'Avalista:'} ${campaign.guarantors
                .map(item => ` ${item.first_name} ${item.last_name}`)
                .join(',')}`,
        proposalDetails: {
          rate_month: campaign.rate_month,
          number_installments: campaign.installments,
          VlrTAC: values.campaign_tac
        },
        proposalData: {
          DocumentoCliente: values.campaign_document,
          VlrSolicitado: values.campaign_requested_amount,
          Prazo: values.campaign_time,
          DtPrimeiroVencto: values.campaign_first_due_date,
          VlrIOF: values.campaign_iof,
          VlrTAC: values.campaign_tac,
          VlrParcela: values.campaign_installment_value,
          CodigoOperacao: values.campaign_operation_code,
          PercJurosNegociado: values.campaign_interest_negotiated,
          PercIOF: Number(values.campaign_iof_percentual),
          PercIOFAdicional: Number(values.campaign_iof_aditional),
          VlrBoleto: 0
        },
        partners: formatPartners(values.partners, values.company_cnpj),
        propostas: formatProposals(proposals.installments)
      }

      await runCampaign(formattedValues)

      setIsOpen(true)
      localStorage.removeItem(`@kavod:temporary_fields_${match.params.id}`)
      toast.success('Campanha rodada com sucesso.')
    } catch (e) {
      console.error(e)
      toast.error('Houve problemas no envio dos dados, não foi possível processar a campanha.')
    } finally {
      setStatus(IDLE)
    }
  }

  const handleEmptyInitialValues = _ => ({
    company_name: '',
    company_fantasy_name: '',
    company_cnpj: '',
    company_zip_code: '',
    company_street: '',
    company_number: '',
    company_city: '',
    company_complement: '',
    company_neighborhood: '',
    company_uf: '',
    company_country: '',
    company_email: '',
    company_phone: '',
    company_phone_prefix: '',
    company_bank_code: '',
    company_bank_agency: '',
    company_bank_account: '',
    company_bank_account_digit: '',
    company_bank_type: '',
    guarantors: [
      {
        guarantor_type: 'PF',
        guarantor_fantasy_name: '',
        guarantor_name: '',
        guarantor_document: '',
        guarantor_id_card: '',
        guarantor_marital_status: '',
        guarantor_zip_code: '',
        guarantor_street: '',
        guarantor_number: '',
        guarantor_neighborhood: '',
        guarantor_complement: '',
        guarantor_city: '',
        guarantor_uf: '',
        guarantor_phone_prefix: '',
        guarantor_phone: '',
        guarantor_email: '',
        guarantor_marital_type: ''
      }
    ],
    subscribers: [],
    partner_cnpj: '',
    partners: [{ partner_name: '', partner_document: '' }],
    campaign_document: null,
    campaign_requested_amount: null,
    campaign_time: null,
    campaign_first_due_date: '',
    campaign_iof: null,
    campaign_tac: null,
    campaign_installment_value: null,
    campaign_operation_code: null,
    campaign_interest_negotiated: null,
    campaign_iof_percentual: 1.5,
    campaign_iof_aditional: 0.38,
    campaign_guarantee: ''
  })

  const handleInitialValues = _ => {
    const guarantors = temporaryFields.guarantors.map(guarantor => ({
      guarantor_type: guarantor.guarantor_type ? guarantor.guarantor_type : 'PF',
      guarantor_fantasy_name: guarantor.guarantor_fantasy_name,
      guarantor_name: guarantor.guarantor_name,
      guarantor_document: guarantor.guarantor_document,
      guarantor_id_card: guarantor.guarantor_id_card,
      guarantor_marital_status: guarantor.guarantor_marital_status,
      guarantor_zip_code: guarantor.guarantor_zip_code,
      guarantor_street: guarantor.guarantor_street,
      guarantor_number: guarantor.guarantor_number,
      guarantor_neighborhood: guarantor.guarantor_neighborhood,
      guarantor_complement: guarantor.guarantor_complement,
      guarantor_city: guarantor.guarantor_city,
      guarantor_uf: guarantor.guarantor_uf,
      guarantor_phone_prefix: guarantor.guarantor_phone_prefix,
      guarantor_phone: guarantor.guarantor_phone,
      guarantor_email: guarantor.guarantor_email,
      guarantor_marital_type: guarantor.guarantor_marital_type
    }))

    const subscribers = temporaryFields.subscribers.map(subscriber => ({
      subscriber_name: subscriber.subscriber_name,
      subscriber_description: subscriber.subscriber_description,
      subscriber_document: subscriber.subscriber_document,
      subscriber_phone_prefix: subscriber.subscriber_phone_prefix,
      subscriber_phone: subscriber.subscriber_phone,
      subscriber_email: subscriber.subscriber_email,
      subscriber_kvd_id: subscriber.subscriber_kvd_id ? subscriber.subscriber_kvd_id : null
    }))

    const partners = temporaryFields.partners.map(partner => ({
      partner_name: partner.partner_name,
      partner_document: partner.partner_document
    }))
    return {
      company_name: temporaryFields.company_name,
      company_fantasy_name: temporaryFields.company_fantasy_name,
      company_cnpj: temporaryFields.company_cnpj,
      company_zip_code: temporaryFields.company_zip_code,
      company_street: temporaryFields.company_street,
      company_number: temporaryFields.company_number,
      company_city: temporaryFields.company_city,
      company_complement: temporaryFields.company_complement,
      company_neighborhood: temporaryFields.company_neighborhood,
      company_uf: temporaryFields.company_uf,
      company_country: temporaryFields.company_country,
      company_email: temporaryFields.company_email,
      company_phone: temporaryFields.company_phone,
      company_phone_prefix: temporaryFields.company_phone_prefix,
      company_bank_code: temporaryFields.company_bank_code,
      company_bank_agency: temporaryFields.company_bank_agency,
      company_bank_account: temporaryFields.company_bank_account,
      company_bank_account_digit: temporaryFields.company_bank_account_digit,
      company_bank_type: temporaryFields.company_bank_type,
      guarantors: guarantors,
      subscribers: subscribers,
      partner_cnpj: '',
      partners: partners,
      campaign_document: null,
      campaign_requested_amount: null,
      campaign_time: null,
      campaign_first_due_date: temporaryFields.campaign_first_due_date ? temporaryFields.campaign_first_due_date : '',
      campaign_iof: null,
      campaign_tac: null,
      campaign_installment_value: null,
      campaign_operation_code: null,
      campaign_interest_negotiated: null,
      campaign_iof_percentual: temporaryFields.campaign_iof_percentual ? temporaryFields.campaign_iof_percentual : 1.5,
      campaign_iof_aditional: temporaryFields.campaign_iof_aditional ? temporaryFields.campaign_iof_aditional : 0.38,
      campaign_guarantee: temporaryFields.campaign_guarantee ? temporaryFields.campaign_guarantee : ''
    }
  }

  if (status === FETCHING || status === LOADING) {
    return (
      <Container>
        <LoadingComponent />
      </Container>
    )
  }

  return (
    <Container>
      {campaign && (
        <AccordionContainer>
          <HeaderContainer>
            <Title>Processar campanha {campaign.name}</Title>
          </HeaderContainer>
          <div>
            <Formik
              validationSchema={processCampaignForm}
              initialValues={temporaryFields ? handleInitialValues() : handleEmptyInitialValues()}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, setFieldError, errors, setValues }) => (
                <Form onKeyUp={_ => setTemporaryFields(values)} onClick={_ => setTemporaryFields(values)}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls='panel1bh-content'
                      id='panel1bh-header'
                    >
                      <Typography>Empresa requerente</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InputWrapper>
                        <ProcessCampaignCompanyForm
                          campaign={campaign}
                          setValues={setValues}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography>Avalistas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InputWrapper>
                        <FieldArray
                          name='guarantors'
                          render={arrayHelpers => (
                            <div>
                              {values.guarantors.map((guarantor, index) => (
                                <Guarantors key={index}>
                                  <h1>Avalista {index + 1}</h1>
                                  <InputWrapper>
                                    <ProcessCampaignGuarantorsForm
                                      index={index}
                                      guarantor={guarantor}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      maritalTypes={maritalTypes}
                                      maritalTypesNotMarried={maritalTypes
                                        .filter(
                                          ({ name }) =>
                                            name === 'Solteiro' || name === 'Desquitado' || name === 'Viúvo(a)'
                                        )
                                        .map(({ id }) => id)}
                                      unionTypes={unionTypes}
                                      unionTypeSingle={unionTypes.find(({ abbreviation }) => abbreviation === 'NC')}
                                    />
                                    <RemoveGuarantor>
                                      <MaterialButton
                                        type='button'
                                        onClick={() => {
                                          if (temporaryFields) {
                                            setTemporaryFields({
                                              ...values,
                                              guarantors: temporaryFields.guarantors.filter((g, i) => !(i === index))
                                            })
                                          }

                                          arrayHelpers.remove(index)
                                        }}
                                        label='Remover avalista'
                                      />
                                    </RemoveGuarantor>
                                  </InputWrapper>
                                </Guarantors>
                              ))}
                              <MaterialButton
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({
                                    guarantor_name: '',
                                    guarantor_type: 'PF',
                                    guarantor_fantasy_name: '',
                                    guarantor_document: '',
                                    guarantor_id_card: '',
                                    guarantor_marital_status: '',
                                    guarantir_marital_type: '4',
                                    guarantor_zip_code: '',
                                    guarantor_street: '',
                                    guarantor_number: '',
                                    guarantor_neighborhood: '',
                                    guarantor_complement: '',
                                    guarantor_city: '',
                                    guarantor_uf: ''
                                  })
                                }
                                label='Adicionar avalista'
                              />
                            </div>
                          )}
                        />
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls='panel3bh-content'
                      id='panel3bh-header'
                    >
                      <Typography>Campanha</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InputWrapper>
                        <ProcessCampaignCampaignForm
                          campaign={campaign}
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          values={values}
                        />
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls='panel5bh-content'
                      id='panel5bh-header'
                    >
                      <Partners>
                        <Typography>Sócios</Typography>
                      </Partners>
                    </AccordionSummary>
                    <AccordionDetails>
                      <PartnersContainer>
                        <ClientPartner />
                        <InputWrapper>
                          <FieldArray
                            name='partners'
                            render={arrayHelpers => (
                              <div style={{ width: '100%' }}>
                                {values.partners.map((partner, index) => (
                                  <Guarantors key={index}>
                                    <h1>Sócio {index + 1}</h1>
                                    <InputWrapper style={{ width: '100%' }}>
                                      <ProcessCampaignPartnerForm
                                        partner={partner}
                                        index={index}
                                        setFieldValue={setFieldValue}
                                      />
                                      <RemoveGuarantor>
                                        <MaterialButton
                                          type='button'
                                          onClick={() => {
                                            if (temporaryFields) {
                                              setTemporaryFields({
                                                ...values,
                                                partners: temporaryFields.partners.filter((p, i) => !(i === index))
                                              })
                                            }
                                            arrayHelpers.remove(index)
                                          }}
                                          label='Remover sócio'
                                        />
                                      </RemoveGuarantor>
                                    </InputWrapper>
                                  </Guarantors>
                                ))}
                                <MaterialButton
                                  type='button'
                                  onClick={() => arrayHelpers.push({ partner_name: '', partner_document: '' })}
                                  label='Adicionar sócio'
                                />
                              </div>
                            )}
                          />
                        </InputWrapper>
                      </PartnersContainer>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreSharp />}
                      aria-controls='panel4bh-content'
                      id='panel4bh-header'
                    >
                      <Partners>
                        <Typography>Assinantes</Typography>
                      </Partners>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InputWrapper>
                        <FieldArray
                          name='subscribers'
                          render={arrayHelpers => (
                            <div>
                              <MaterialButton
                                type='button'
                                onClick={async () => {
                                  setFetchingSigners(true)
                                  setFieldValue('subscribers', await createNewSubscribers(values))
                                  setFetchingSigners(false)
                                }}
                                loading={fetchingSigners}
                                label='Incluir Sócios e Avalistas'
                              />
                              {values.subscribers &&
                                values.subscribers.map((subscriber, index) => (
                                  <Guarantors key={index}>
                                    <h1>Assinante {index + 1}</h1>
                                    <InputWrapper>
                                      <ProcessCampaignSubscribersForm
                                        index={index}
                                        subscriber={subscriber}
                                        setFieldValue={setFieldValue}
                                      />
                                      <RemoveGuarantor>
                                        <MaterialButton
                                          type='button'
                                          onClick={() => {
                                            if (temporaryFields) {
                                              setTemporaryFields({
                                                ...values,
                                                subscribers: temporaryFields.subscribers.filter(
                                                  (s, i) => !(i === index)
                                                )
                                              })
                                            }
                                            arrayHelpers.remove(index)
                                          }}
                                          label='Remover assinante'
                                        />
                                      </RemoveGuarantor>
                                    </InputWrapper>
                                  </Guarantors>
                                ))}
                              <MaterialButton
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({
                                    subscriber_name: '',
                                    subscriber_document: '',
                                    subscriber_phone_prefix: '',
                                    subscriber_phone: '',
                                    subscriber_email: ''
                                  })
                                }
                                loading={fetchingSigners}
                                label='Adicionar assinante'
                              />
                            </div>
                          )}
                        />
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>
                  <SendContainer>
                    {typeof errors.guarantors === 'string' && <ErrorMessage component={Error} name='guarantors' />}
                    {typeof errors.partners === 'string' && <ErrorMessage component={Error} name='partners' />}
                    {typeof errors.subscribers === 'string' && <ErrorMessage component={Error} name='subscribers' />}
                    <MaterialButton
                      type='submit'
                      width='180px'
                      loading={status === SUBMITTING}
                      label='Enviar dados'
                      disabled={Boolean(campaign.operation_date)}
                    />
                  </SendContainer>
                </Form>
              )}
            </Formik>
          </div>
        </AccordionContainer>
      )}
      {isOpen && (
        <ModalSummary
          match={match}
          history={history}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          updateCampaignStatus={updateCampaignStatus}
        />
      )}
    </Container>
  )
}

export default ProcessCampaign
