import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'

import { CampaignContainer, LoadingScreen } from '../../components'
import { Title, ButtonContainer, DeleteButton } from './style'

import { getCampaign, deleteCampaign } from '../../services'

const CampaignDelete = ({ history, match }) => {
  const [campaign, setCampaign] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchCampaign = async () => {
      const response = await getCampaign(match.params.id)
      setCampaign(response.data.pop())

      setIsLoading(false)
    }
    fetchCampaign()
  }, [])

  if (isLoading) {
    return <LoadingScreen />
  }

  const handleClick = async () => {
    try {
      await deleteCampaign(campaign.id)
      toast.success(`Campanha ${campaign.name} deletada com sucesso.`)
      setTimeout(() => {
        history.push('/admin')
      }, 1000)
    } catch {
      toast.error('Tivemos problemas em deletar a campanha. Tente novamente.')
    }
  }

  return (
    <CampaignContainer step={8}>
      <ButtonContainer>
        <Title>Clique no botão pra confirmar a exclusão da campanha {campaign.name}</Title>
        <DeleteButton onClick={handleClick}>Confirmar exclusão</DeleteButton>
      </ButtonContainer>
    </CampaignContainer>
  )
}

export default withRouter(CampaignDelete)
