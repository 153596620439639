import React, { useEffect, useState } from 'react'
import ManageCampaignStatus from './ManageCampaignStatus'

import { getCampaignFlow, updateCampaignsFlow } from '../../services'

import { LoadingScreen } from '../../components'
import { CampaignsContainer, CampaignsContent } from './style'

const CampaignStatus = () => {
  const [campaigns, setCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchCampaigns = async () => {
      const response = await getCampaignFlow()
      setCampaigns(response.data)
      setIsLoading(false)
    }

    fetchCampaigns()
  }, [])

  const updateStatus = async campaign => {
    try {
      setIsLoading(true)
      const campaignWithoutId = { ...campaign }
      delete campaignWithoutId.id
      await updateCampaignsFlow(campaign.id, campaignWithoutId)
      const response = await getCampaignFlow()
      setCampaigns(response.data)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <CampaignsContainer>
      <CampaignsContent>
        {campaigns.map(campaign => (
          <ManageCampaignStatus handleUpdate={updateStatus} key={campaign.id} campaign={campaign} />
        ))}
      </CampaignsContent>
    </CampaignsContainer>
  )
}

export default CampaignStatus
