import styled from 'styled-components'
import { display, positions, sizing, spacing, flexbox } from '@material-ui/system'

import { TextField } from '@material-ui/core'

export const InputText = styled(TextField)`
  &&.MuiFormControl-root {
    ${display};
    ${positions};
    ${sizing};
    ${spacing};
    ${flexbox};
  }

  svg {
    color: ${({ error, theme }) => (error ? theme.palette.error.main : theme.palette.gray[600])};
  }

  label {
    color: ${({ theme }) => theme.palette.gray.main};
    &.MuiInputLabel-shrink {
      text-transform: uppercase;
      font-weight: 600;
      padding-right: 4px;
      background: ${({ theme }) => theme.palette.white};
      width: max-content;
    }
    &.Mui-focused {
      color: ${({ theme }) => theme.palette.primary[400]};
    }

    @media (max-width: 378px) {
      &.MuiInputLabel-shrink {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        padding-right: 4px;
      }
    }
  }

  div {
    &.MuiOutlinedInput-root {
      fieldset {
        border-color: ${({ theme }) => theme.palette.gray[700]};
      }
      &.Mui-focused {
        fieldset {
          border-color: ${({ theme }) => theme.palette.primary[400]};
        }
      }
    }
  }

  .MuiFormHelperText-root {
    margin-left: 2px;
    color: ${({ theme }) => theme.palette.gray[700]};
  }
`
