import styled from 'styled-components'

export const Img = styled.img`
  width: -webkit-fill-available;
`
export const DivImg = styled.div`
  width: 33.3333333333%;
  height: fit-content;
  padding: 10px;
  max-width: 100%;

  p {
    color: #076f9f;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const ContainerGallery = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const DivImgBody = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 35px;

  @media (max-width: 769px) {
    flex-direction: column;
  }
`
