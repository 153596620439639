import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import MomentUtils from '@date-io/moment'
import styled from 'styled-components'
import Modal from 'react-modal'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { toast } from 'react-toastify'

import { MaterialButton, Input, LoadingComponent } from '../../../components'

import { getDarfs, createDarfs, getCnab, simulateDarfs } from '../../../services/v3'
import { formatValueToBrl } from '../../../helpers'

Yup.setLocale({
  string: {
    required: 'Campo obrigatório.'
  }
})

const ModalDarfCnab = ({ isOpen, setIsOpen, selectedCampaingInfos, reload }) => {
  const [loading, setLoading] = useState(true)
  const [simulatedInfos, setSimulatedInfos] = useState({})

  useEffect(() => {
    const getSimulation = async () => {
      try {
        setLoading(true)
        const body = {
          campaign_id: selectedCampaingInfos.campaign_id,
          installment_number: selectedCampaingInfos.installment_number
        }
        const { data: simulatedDarfs } = await simulateDarfs(body)
        setSimulatedInfos(simulatedDarfs)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao simular os valores do CNAB')
      } finally {
        setLoading(false)
      }
    }

    getSimulation()
  }, [])

  const handleSubmit = async values => {
    const { file_date, pay_day, due_date, counting_period } = values
    try {
      await createDarfs(selectedCampaingInfos.campaign_id, selectedCampaingInfos.installment_number)

      const { data: requestDarfs } = await getDarfs(
        selectedCampaingInfos.campaign_id,
        selectedCampaingInfos.installment_number
      )
      const darfsIdList = requestDarfs.map(darf => darf.id).join(',')

      const { data: cnabBlob } = await getCnab(
        darfsIdList,
        moment(file_date, 'DD/MM/YYYYY').format('DDMMYYYY'),
        moment(pay_day, 'DD/MM/YYYYY').format('DDMMYYYY'),
        moment(due_date, 'DD/MM/YYYYY').format('DDMMYYYY'),
        moment(counting_period, 'DD/MM/YYYYY').format('DDMMYYYY')
      )

      const element = document.createElement('a')
      element.href = URL.createObjectURL(new Blob([cnabBlob], { type: 'text/plain' }))
      element.download = `D${('0000' + selectedCampaingInfos.campaign_id).slice(-4)}${(
        '000' + selectedCampaingInfos.installment_number
      ).slice(-3)}.REM`
      document.body.appendChild(element)
      element.click()
      reload()
    } catch (error) {
      toast.error(error.message)
      console.error(error.message)
    } finally {
      setIsOpen(false)
    }
  }

  const validationSchema = Yup.object().shape({
    file_date: Yup.string().test('is-date', 'Data inválida.', value => moment(value, 'DD/MM/YYYY', true).isValid()),
    due_date: Yup.string().test('is-date', 'Data inválida.', value => moment(value, 'DD/MM/YYYY', true).isValid()),
    counting_period: Yup.string().test('is-date', 'Data inválida.', value =>
      moment(value, 'DD/MM/YYYY', true).isValid()
    ),
    pay_day: Yup.string().test('is-date', 'Data inválida.', value => moment(value, 'DD/MM/YYYY', true).isValid())
  })

  if (loading) {
    return (
      <ModalComponent
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        closeModal={() => setIsOpen(false)}
      >
        <ModalDarfContent>
          <LoadingComponent />
        </ModalDarfContent>
      </ModalComponent>
    )
  }

  return (
    <ModalComponent
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      closeModal={() => setIsOpen(false)}
    >
      <ModalDarfContent>
        {selectedCampaingInfos.warning && (
          <Warning>ATENÇÃO: Existem parcelas anteriores que não tiveram seus DARFs gerados.</Warning>
        )}
        <ModalDescripion>Preencha os dados abaixo para gerar o DARF:</ModalDescripion>
        <PayDateContainer>
          <Formik
            initialValues={{
              total_investors: simulatedInfos.totalInvestors,
              total_amount: formatValueToBrl(simulatedInfos.totalAmount),
              file_date: moment().format('DD/MM/YYYY'),
              due_date: '',
              counting_period: '',
              pay_day: moment().format('DD/MM/YYYY')
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <MuiPickersUtilsProvider utils={MomentUtils} locale='pt'>
                  <FormContainer>
                    <InputRowContainer>
                      <InputContainer>
                        <Input
                          disabled
                          name='total_investors'
                          label='Total de Investidores'
                          value={values.total_investors}
                        />
                        <Error>{errors.total_investors}</Error>
                      </InputContainer>
                      <InputContainer>
                        <Input disabled name='total_amount' label='Valor Total' value={values.total_amount} />
                        <Error>{errors.total_amount}</Error>
                      </InputContainer>
                    </InputRowContainer>
                    <InputRowContainer>
                      <InputContainer>
                        <Input
                          name='file_date'
                          mask='99/99/9999'
                          label='Data do Arquivo'
                          value={values.file_date}
                          onChange={e => {
                            setFieldValue('file_date', e.target.value)
                          }}
                        />
                        <Error>{errors.file_date}</Error>
                      </InputContainer>
                      <InputContainer>
                        <Input
                          name='counting_period'
                          mask='99/99/9999'
                          label='Período de Apuração'
                          value={values.counting_period}
                          onChange={e => {
                            setFieldValue('counting_period', e.target.value)
                          }}
                        />
                        <Error>{errors.counting_period}</Error>
                      </InputContainer>
                    </InputRowContainer>
                    <InputRowContainer>
                      <InputContainer>
                        <Input
                          name='pay_day'
                          mask='99/99/9999'
                          label='Data do Pagamento'
                          value={values.pay_day}
                          onChange={e => {
                            setFieldValue('pay_day', e.target.value)
                          }}
                        />
                        <Error>{errors.pay_day}</Error>
                      </InputContainer>
                      <InputContainer>
                        <Input
                          name='due_date'
                          mask='99/99/9999'
                          label='Vencimento'
                          value={values.due_date}
                          onChange={e => {
                            setFieldValue('due_date', e.target.value)
                          }}
                        />
                        <Error>{errors.due_date}</Error>
                      </InputContainer>
                    </InputRowContainer>
                  </FormContainer>
                  <ButtonsContent>
                    <MaterialButton width='100%' label='Confimar' type='submit' />
                    <MaterialButton
                      width='100%'
                      label='Cancelar'
                      backgroundColor='red'
                      onClick={() => setIsOpen(false)}
                    />
                  </ButtonsContent>
                </MuiPickersUtilsProvider>
              </Form>
            )}
          </Formik>
        </PayDateContainer>
      </ModalDarfContent>
    </ModalComponent>
  )
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ModalDescripion = styled.p`
  padding: 1em 0;
  font-weight: 500;
`

const ModalContent = styled.div`
  display: flex;
  width: 700px;
  border-radius: 4px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`

const ModalDarfContent = styled(ModalContent)`
  height: auto;
  min-height: 400px;
`

const ModalComponent = styled(Modal)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PayDateContainer = styled.div`
  display: flex;
  align-items: center;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`

const ButtonsContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Error = styled.span`
  color: red;
`
const Warning = styled.div`
  margin: 15px 50px;
  font-weight: bold;
  color: red;
`

export default ModalDarfCnab
