import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { createLeadUpdate, aprovePoposal } from '../../../../../services/v3'

import { ModalBase, Column, Button, Row } from '../../../../v2Components'

const defaultValue = { observation: '' }

const ModalRefuseProposal = ({ open, handleClose }) => {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: defaultValue
  })

  const [value, setValue] = useState('')

  const onSubmit = async data => {
    try {
      if (!!open.proposal_id) {
        const body = {
          lead_update_status_id: 7,
          lead_id: open.lead_id,
          observation: value.length > 0 ? value : undefined,
          is_visible_to_partner: true,
          proposal_id: open.proposal_id
        }
        await aprovePoposal(open.proposal_id, { is_accepted: false })
        await createLeadUpdate(body)
      } else {
        const body = {
          lead_update_status_id: 7,
          lead_id: open.lead_id,
          observation: value.length > 0 ? value : undefined,
          is_visible_to_partner: true
        }
        await createLeadUpdate(body)
      }

      document.location.reload(true)
      toast.success('Proposta Recusada.')
    } catch {
      toast.error('Ocorreu um erro ao Recusar a proposta.')
    } finally {
      handleClose()
    }
  }

  return (
    <ModalBase
      open={open ? true : false}
      onClose={handleClose}
      height='100%'
      width='100%'
      title={'Motivos para recusar'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column height='100%'>
          <ReactQuill
            theme='snow'
            value={value}
            onChange={setValue}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image'],
                ['clean']
              ]
            }}
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image'
            ]}
          />
          <Row mt={3}>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting} bgcolor='#eb6e75'>
              Recusar
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalRefuseProposal
