/* eslint-disable indent */
import React, { Fragment, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { Input } from '../../components'

const ProcessCampaignCampaignForm = ({ campaign, setFieldValue }) => {
  useEffect(() => {
    const { grace_period } = campaign
    setFieldValue(
      'campaign_first_due_date',
      moment()
        .add((grace_period + 30) / 30, 'M')
        .format('DD/MM/YYYY')
    )
  }, [])

  return (
    <Fragment>
      <h4 style={{ width: '100%' }}>
        {campaign.campaign_type.id === 2
          ? `Aplicação financeira de ${campaign.collateral}% do valor retido sob gestão da Kavod Lending. ${
              campaign.guarantors.length > 1 ? 'Avalistas:' : 'Avalista:'
            }  ${campaign.guarantors.map(item => ` ${item.first_name} ${item.last_name}`).join(',')}`
          : `Aval de ${campaign.guarantors.length > 1 ? 'Avalistas:' : 'Avalista:'} ${campaign.guarantors
              .map(item => ` ${item.first_name} ${item.last_name}`)
              .join(',')}`}
      </h4>
      <InputContainer>
        <Field
          name='campaign_first_due_date'
          render={({ field }) => (
            <Input label='Data do primeiro vencimento' placeholder='DD/MM/YYYY' mask='99/99/9999' {...field} />
          )}
        />
        <ErrorMessage name='campaign_first_due_date' component={Error} />
      </InputContainer>
      <InputContainer>
        <Field name='campaign_guarantee' render={({ field }) => <Input disabled label='Garantia' {...field} />} />
        <ErrorMessage name='campaign_guarantee' component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name='campaign_iof_percentual'
          render={({ field }) => (
            <Input label='Percentual de IOF' formatChars={{ n: '[0-9/.]' }} mask='nnnn' {...field} />
          )}
        />
        <ErrorMessage name='campaign_iof_percentual' component={Error} />
      </InputContainer>
      <InputContainer>
        <Field
          name='campaign_iof_aditional'
          render={({ field }) => (
            <Input label='Adicional de IOF' formatChars={{ n: '[0-9/.]' }} mask='nnnn' {...field} />
          )}
        />
      </InputContainer>
    </Fragment>
  )
}

const Error = styled.span`
  color: red;
  font-size: 0.8em;
`

const InputContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`

export default ProcessCampaignCampaignForm
