import React, { useEffect } from 'react'
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'

import { TextInput } from '../../components/FormComponents'
import { CardEditIcon } from './'

import { leadModalCards } from '../../constants'

import { Card, Title, InfoContainer, Info, EditInputsContainer } from './styledComponents'

const LendingReasonCard = ({ lead, isEditing, handleEditCard, control, errors, setValue }) => {
  useEffect(() => {
    if (!isEditing) return
    setValue('lending_reason', lead.lending_reason)
  }, [isEditing])

  return (
    <Card>
      <CardEditIcon onClick={() => handleEditCard(leadModalCards.LENDING_REASON)} />
      <Title>Motivo do empréstimo</Title>
      {isEditing ? (
        <EditInputsContainer>
          <ReactIsCapsLockActive>
            {active => (
              <TextInput
                name='lending_reason'
                control={control}
                error={active ? { message: 'Caps Lock ligado' } : errors['lending_reason']}
                label='Motivo do empréstimo *'
                defaultValue=''
                width='100%'
                margin='0px'
                multiline
                dense
              />
            )}
          </ReactIsCapsLockActive>
        </EditInputsContainer>
      ) : (
        <InfoContainer>
          <Info>{lead.lending_reason}</Info>
        </InfoContainer>
      )}
    </Card>
  )
}

export default LendingReasonCard
