import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import InputBase from '../InputBase'
import IconButton from '../../IconButton'
import Text  from '../../Text'
import { IconCheck, IconCircleFilled,  IconVisibility, IconVisibilityOff } from '../../icons'
import { ValidationBox, Container } from './styles'

import useDevice from '../../../../hooks/useDevice'

const PasswordInput = forwardRef(
  ({ name, label, error, defaultValue, helperText, validationBox, control, ...props }, ref) => {
    const { isMobile, isTablet } = useDevice()

    const [values, setValues] = useState({
      password: '',
      showPassword: false
    })

    const [passwordValidation, setPasswordValidation] = useState({
      oneUppercase: false,
      oneLowcase: false,
      oneNumber: false,
      min8Character: false
    })

    const validationTypes = [
      {
        title: 'Mínimo de 8 caracteres',
        type: 'min8Character'
      },
      {
        title: 'Um número',
        type: 'oneNumber'
      },
      {
        title: 'Uma letra maiúscula',
        type: 'oneUppercase'
      },
      {
        title: 'Uma letra minúscula',
        type: 'oneLowcase'
      }
    ]

    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleMouseDownPassword = event => {
      event.preventDefault()
    }

    const handleValidation = password => {
      const oneUppercaseRegExp = RegExp(/^(?=.*?[A-Z])/)
      const oneLowcaseRegExp = RegExp(/^(?=.*?[a-z])/)
      const oneNumberRegExp = RegExp(/^(?=.*?[0-9])/)

      const oneUppercaseUpdate = oneUppercaseRegExp.test(password)
      const oneLowcaseUpdate = oneLowcaseRegExp.test(password)
      const oneNumberUpdate = oneNumberRegExp.test(password)
      const min8CharacterUpdate = password.length >= 8

      setPasswordValidation({
        oneUppercase: oneUppercaseUpdate,
        oneLowcase: oneLowcaseUpdate,
        oneNumber: oneNumberUpdate,
        min8Character: min8CharacterUpdate
      })
    }

    return (
      <Controller
        as={
          <Container>
            <InputBase
              ref={ref}
              label={label || 'Senha'}
              type={values.showPassword ? 'text' : 'password'}
              onChange={event => handleValidation(event.target.value)}
              suffix={
                <IconButton
                  variant='text'
                  aria-label='Toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  icon={values.showPassword ? <IconVisibility /> : <IconVisibilityOff />}
                />
              }
              helperText={
                validationBox ? (
                  <ValidationBox error={error}>
                    {validationTypes.map(item => (
                      <li key={item.title} className={passwordValidation[item.type] && 'validated'}>
                        {passwordValidation[item.type] ? (
                          <IconCheck style={{ fontSize: 14 }} />
                        ) : (
                          <IconCircleFilled style={{ fontSize: 6, marginRight: 9 }} />
                        )}
                        <Text variant='caption'>{item.title}</Text>
                      </li>
                    ))}
                  </ValidationBox>
                ) : (
                  (!!error && error.message) || helperText
                )
              }
              error={validationBox ? '' : error}
              width={(isMobile && 328) || (isTablet && 340) || 384}
              {...props}
            />
          </Container>
        }
        name={name}
        defaultValue={defaultValue}
        control={control}
      />
    )
  }
)

PasswordInput.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string
    }),
    PropTypes.bool
  ]),
  type: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  inputProps: PropTypes.object,
  validationBox: PropTypes.bool
}

PasswordInput.defaultProps = {
  variant: 'outlined',
  size: 'small',
  multiline: false,
  validationBox: false
}

export default PasswordInput
