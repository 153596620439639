import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './style.css'

const Quill = props => {
  return <ReactQuill {...props} />
}

export default Quill
