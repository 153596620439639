import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import useDevice from '../../../hooks/useDevice'
import { deleteLeadUpdate } from '../../../services/v3'

import { ModalBase, Column, Button, Row } from '../index'

const ModalDeleteRegister = ({ item, handleClose, deleteInTimeLine }) => {
  const { isMobile } = useDevice()
  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    try {
      await deleteLeadUpdate(item.id)
      deleteInTimeLine(item)
      toast.success('Registro excluido.')
    } catch {
      toast.error('Ocorreu um erro ao deletar o resgistro.')
    } finally {
      handleClose()
    }
  }

  return (
    <ModalBase
      open={item ? true : false}
      onClose={handleClose}
      width={isMobile ? 300 : 526}
      height='100%'
      title={'Tem certeza que deseja excluir esse registro?'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column height='100%'>
          <Row mt={3}>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Não
            </Button>
            <Button height={40} width={227} type='submit'>
              Sim
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalDeleteRegister
