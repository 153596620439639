import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import styled from '@emotion/styled'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CampaignContainer, LoadingScreen, CampaignReturnSimulator, Button } from '../../components'
import { getCampaign, getCampaignPhoto } from '../../services'
import { formatMoney, remainingDays, getAnnualRate, calculatePercent } from '../../helpers'

const ViewDraft = ({ match, history }) => {
  const [campaign, setCampaign] = useState(null)
  const [campaignPhoto, setCampaignPhoto] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSimulated, setIsSimulated] = useState(false)
  const [simulatedAmount, setSimulatedAmount] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    const fetchCampaign = async () => {
      const response = await getCampaign(match.params.id)
      const photo = await getCampaignPhoto(match.params.id)
      setCampaign(response.data.pop())
      setCampaignPhoto(photo.data[0].photoURL)

      setIsLoading(false)
    }
    fetchCampaign()
  }, [])

  useEffect(() => {
    const initialValues = () => {
      const response = remainingDays(campaign.end_date)
      if (response === 0) {
        setIsSimulated(true)
        setSimulatedAmount(10000)
      }
    }

    campaign && initialValues()
  })

  const renderCampaignValuesInfo = campaign => {
    const capturedValue = campaign.amount
    if (campaign !== null) {
      return (
        <div className='flex justify-between'>
          <div className='campaign-page-values-align'>
            <CampaignValuesInfoContainer>
              <span className='campaign-page-values '>R$ {formatMoney(campaign.amount)}</span>
              <span className='campaign-page-values-info'>Meta de Captação</span>
            </CampaignValuesInfoContainer>
          </div>

          <div className=' campaign-page-values-align'>
            <CampaignValuesInfoContainer>
              <span className='campaign-page-values col s12'>R$ {formatMoney(capturedValue)}</span>
              <span className='campaign-page-values-info'>Valor Captado</span>
            </CampaignValuesInfoContainer>
          </div>

          <div className='campaign-page-values-align'>
            <CampaignValuesInfoContainer>
              <span className='campaign-page-values '>{remainingDays(campaign.end_date)}</span>
              <span className=' campaign-page-values-info'>Dias Restantes</span>
            </CampaignValuesInfoContainer>
          </div>
        </div>
      )
    }
  }

  const renderAmountCampaign = (campaign, sumOrders) => {
    let totalAmount = sumOrders >= campaign.amount
    if ((totalAmount && campaign.stt_id === 'CL') || remainingDays(campaign.end_date) <= 0) {
      return (
        <div style={{ margin: '50px 0 30px' }}>
          <span className='campaign-page-title'>Esta campanha foi finalizada.</span>
        </div>
      )
    } else {
      return (
        <div>
          <form onSubmit={e => this.goToCheckoutPage(e)} style={{ padding: '0px', marginTop: '30px' }}>
            <div className='row'>
              <div className='col s12'>{/* {this.handleMessagesWhenUserCanNotInvest()} */}</div>
              <div className='col s12 xl5'>
                <div style={{ position: 'relative' }}>
                  <MaskedInput
                    style={{
                      textAlign: 'right',
                      width: 'calc(100% - 55px)',
                      paddingRight: '55px'
                    }}
                    id='campaign-input'
                    onChange={handleChange}
                    onKeyUp={() => disableButton()}
                    mask={rawValue => {
                      let numberMask = createNumberMask({
                        prefix: '',
                        includeThousandsSeparator: true,
                        allowDecimal: false,
                        allowLeadingZeroes: false,
                        thousandsSeparatorSymbol: '.'
                      })
                      return numberMask(rawValue)
                    }}
                  />
                  <span
                    onClick={() => {
                      setFocusInputAmount()
                    }}
                    className='labels-campaign'
                    style={{
                      position: 'absolute',
                      bottom: '18px',
                      left: 0,
                      fontSize: '16px',
                      color: 'rgba(0,0,0,0.87)'
                    }}
                  >
                    R$
                  </span>
                  <span
                    onClick={() => {
                      setFocusInputAmount()
                    }}
                    className='labels-campaign'
                    style={{
                      position: 'absolute',
                      bottom: '18px',
                      right: 0,
                      fontSize: '16px',
                      color: 'rgba(0,0,0,0.87)'
                    }}
                  >
                    .000,00
                  </span>
                </div>
              </div>
              <div className='col s12 xl7'>{renderInputValueButtons(campaign, 4000)}</div>
            </div>
          </form>
        </div>
      )
    }
  }

  const renderCampaignImageContainer = campaign => {
    return (
      <>
        <div>
          <CampaignImage src={campaignPhoto} />
        </div>
        <div className='flex justify-evenly campaign-image-container'>
          <div className='campaign-page-values-align'>
            <CampaignValuesInfoContainer>
              <span className='campaign-page-values '>{campaign.rate_month} %</span>
              <span className='campaign-page-values-info'>Taxa mensal</span>
            </CampaignValuesInfoContainer>
          </div>

          <div className=' campaign-page-values-align'>
            <CampaignValuesInfoContainer>
              <span className='campaign-page-values col s12'>{getAnnualRate(campaign.rate_month)} %</span>
              <span className='campaign-page-values-info'>Taxa anual</span>
            </CampaignValuesInfoContainer>
          </div>

          <div className='campaign-page-values-align'>
            <CampaignValuesInfoContainer>
              <span className='campaign-page-values '>{campaign.installments} meses</span>
              <span className=' campaign-page-values-info'>Prazo</span>
            </CampaignValuesInfoContainer>
          </div>
        </div>
      </>
    )
  }

  const handleChange = e => {
    const valueTreated = e.target.value.replace('.', '')
    const value = parseInt(valueTreated) * 1000
    if (value !== simulatedAmount) {
      setSimulatedAmount(isNaN(value) ? simulatedAmount : value)
      setIsSimulated(false)
    }
    if (value < campaign.min_amount) {
    } else if (value > campaign.max_amount) {
    } else {
    }
  }

  const disableButton = () => {
    if (simulatedAmount > campaign.max_amount) {
      setDisabledButton(true)
    }

    if (simulatedAmount < campaign.min_amount && simulatedAmount) {
      setDisabledButton(true)
      return
    }

    if (simulatedAmount > campaign.max_amount) {
      setDisabledButton(true)
      return
    }

    if (simulatedAmount > campaign.amount) {
      setDisabledButton(true)
      return
    }

    if (!simulatedAmount) {
      setDisabledButton(true)
    }
  }

  const setFocusInputAmount = () => {
    let inp = document.querySelector('#campaign-input')
    inp.focus()
  }

  const simulateInvestiment = (campaign, e = null) => {
    setIsSimulated(true)
    if (campaign.stt_id === 'CL' || remainingDays(campaign.end_date) <= 0) {
      setSimulatedAmount(10000)
    }
  }

  const renderInputValueButtons = campaign => {
    if (campaign.stt_id === 'WL') {
      return (
        <div className='row'>
          <div className='col s12 m6 xl5 input-field inline'>
            <button
              className='btn-block btn waves-effect waves-light btn-simulate'
              type='button'
              onClick={e => simulateInvestiment(campaign, e)}
              disabled={disabledButton}
            >
              Simular
            </button>
          </div>
          <div className='col s12 m6 xl7 input-field inline'>
            <Button disabled={disabledButton} label='Lista de espera' type='submit' name='action' />
          </div>
        </div>
      )
    } else {
      // invest
      return (
        <div className='row'>
          <div className='col s12 m6 input-field inline'>
            <button
              className='btn-block btn waves-effect waves-light btn-simulate'
              type='button'
              onClick={e => simulateInvestiment(campaign, e)}
              disabled={disabledButton}
            >
              Simular
            </button>
          </div>
          <div className='col s12 m6  input-field inline'>
            <Button disabled={disabledButton} text='Investir' className='btn-block' type='submit' name='action' />
          </div>
        </div>
      )
    }
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <CampaignContainer step={4}>
      <CampaignImageContainer>{renderCampaignImageContainer(campaign)}</CampaignImageContainer>
      <SimulatorContainer>
        <div className='campaign-page-right-section-margin'>
          <h2 className='campaign-page-title hide-on-med-and-down'>{campaign.name}</h2>
          {renderCampaignValuesInfo(campaign)}
          <div className='campaign-page-values' style={{ marginTop: '30px', marginBottom: '30px' }}>
            <span>Captado: {calculatePercent(campaign.amount, 4000)}%</span>
            <div className='progress campaign-progress'>
              <div
                className='determinate'
                style={{
                  width: calculatePercent(campaign.amount, 4000) + '%'
                }}
              />
            </div>
          </div>
          {renderAmountCampaign(campaign, 4000)}
          <p className='iframe__title'>
            Quanto a campanha deve render em relação a poupança? <br /> Veja a simulação abaixo com um investimento de
            R$ {simulatedAmount}{' '}
          </p>{' '}
          {/* <iframe
            id='simulatorFrame'
            title='ladk'
            width='110%'
            height='330px'
            frameBorder='0'
            allowFullScreen=''
            src='https://app.kavodlending.com/simulador/0/0/0'
          /> */}
          <CampaignReturnSimulator campaign={campaign} isSimulated={isSimulated} investmentValue={simulatedAmount} />
        </div>
      </SimulatorContainer>
    </CampaignContainer>
  )
}

const CampaignImage = styled.img`
  width: 400px;
`

const CampaignValuesInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SimulatorContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 1em;
`

const CampaignImageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`

export default withRouter(ViewDraft)
