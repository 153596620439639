import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import { Controller } from 'react-hook-form'

import { ImageContainer, StyledAvatar, StyledButtonIcon } from './styles'

const ImageUpload = ({ name, control, error, onDrop, onDelete, avatar }) => {
  const [files, setFiles] = useState()
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      onDrop(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
    }
  })

  const handleButtonClick = () => {
    setFiles()
    onDelete()
  }

  const file = files ? files.map(file => file.preview) : false

  useEffect(
    () => () => {
      if (files) {
        files.forEach(file => URL.revokeObjectURL(file.preview))
      }
    },
    [files]
  )

  return (
    <Controller
      as={
        <ImageContainer>
          <StyledAvatar {...getRootProps({ className: 'dropzone' })} sizes='112px' src={file || avatar}>
            <AddIcon fontSize='large' />
          </StyledAvatar>
          <input {...getInputProps()} style={{ display: 'none' }} />
          {files ? (
            <StyledButtonIcon
              icon={<ClearIcon fontSize='small' />}
              width={32}
              height={32}
              onClick={handleButtonClick}
            />
          ) : null}
        </ImageContainer>
      }
      defaultValue=''
      name={name}
      control={control}
      error={error}
    />
  )
}

export default ImageUpload
