import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import ReactCrop from 'react-image-crop'
import { toast } from 'react-toastify'

import { CampaignContainer, Button, LoadingScreen } from '../../components'
import { InputFile, Img, Label, Form } from './style'

import 'react-image-crop/dist/ReactCrop.css'
import { getCampaignPhoto, updateCampaignPhoto } from '../../services/campaigns'

const initialCrop = {
  aspect: 1,
  width: 200,
  x: 0,
  y: 0
}

const CampaignPhoto = ({ location, match, history }) => {
  const [loading, setLoading] = useState(true)
  const [crop, setCrop] = useState(initialCrop)
  const [src, setSrc] = useState(null)
  const [fileName, setFileName] = useState('')
  const [currentImg, setCurrentImg] = useState(null)
  const [campaignImage, setCampaignImg] = useState('')
  const [imageRef, setImageRef] = useState(null)

  useEffect(() => {
    const getPhoto = async () => {
      try {
        const response = await getCampaignPhoto(match.params.id)
        if (response.data.length > 0) {
          setCampaignImg(response.data[0].photoURL)
        }
        setLoading(false)
      } catch (e) {
        toast.error('Não foi possível buscar informações')
        setLoading(false)
      }
    }
    getPhoto()
  }, [])

  const onSelectFile = e => {
    setCurrentImg(e.target.files)
    setFileName(e.target.files[0].name)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader() // eslint-disable-line
      reader.addEventListener('load', () => setSrc(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onCropComplete = async () => {
    if (imageRef && crop.width && crop.height) {
      await getCroppedImg(imageRef)
    }
  }

  const onImageLoaded = image => {
    setImageRef(image)
  }

  const OnChange = crop => {
    setCrop(crop)
  }

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  const getCroppedImg = image => {
    const canvas = document.createElement('canvas')
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height)
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName
        setCurrentImg([blobToFile(blob, fileName)])
        resolve(blob)
      }, 'image/jpeg')
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!currentImg) return
    setLoading(true)

    try {
      const formData = new FormData() //eslint-disable-line
      formData.append('file', currentImg[0])
      formData.append('campaign_id', match.params.id)
      formData.append('rol_id', 'B')
      const response = await updateCampaignPhoto(formData)
      setCampaignImg(response.data.imageInfo.Location)
      setCurrentImg(null)
      setCrop(initialCrop)
      setSrc(null)
      setFileName('')
      setImageRef(null)
      setLoading(false)
      toast.success('Imagem da campanha atualizada com sucesso.')
    } catch (error) {
      setLoading(false)
      toast.error('Não foi possível atualizar imagem da campanha.')
    }
  }

  if (loading) return <LoadingScreen />

  return (
    <CampaignContainer step={5}>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor='ProfileFileInput'>
          Selecionar imagem
          <InputFile type='file' id='ProfileFileInput' onChange={onSelectFile} accept='image/*' />
        </Label>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onComplete={onCropComplete}
            onChange={OnChange}
            onImageLoaded={onImageLoaded}
            keepSelection
            style={{ marginBottom: 30 }}
          />
        )}
        <Button type='submit' secondary label='UPLOAD' disabled={loading} />
        {campaignImage && <Img alt='campaign profile' src={campaignImage} />}
      </Form>
    </CampaignContainer>
  )
}

export default withRouter(CampaignPhoto)
