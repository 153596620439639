import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers'

import { CNPJ_LENGTH, CEP_LENGTH } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Campo Obrigatório'
  }
})

export const creditRequestSchema = yupResolver(
  yup.object().shape({
    indicated_by: yup.string(),
    responsible: yup.string().required(),
    lead_origin_id: yup.string().required(),
    first_name: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    celphone: yup
      .string()
      .required()
      .length(15, 'Celular incompleto'),
    cnpj: yup.string().test({
      name: 'cnpj',
      message: 'CNPJ incompleto',
      test: value => !value || value.replace(/\D/g, '').length === CNPJ_LENGTH
    }),
    company_name: yup.string().required(),
    average_monthly_revenue: yup.string().required(),
    desired_amount: yup.string().required(),
    company_history: yup.string().required(),
    lending_reason: yup.string().required(),
    street: yup.string().required(),
    zip_code: yup
      .string()
      .required()
      .test('cep', 'CEP incompleto', value => value.replace(/\D/g, '').length === CEP_LENGTH),
    number: yup.string().required(),
    district: yup.string().required(),
    city: yup.string().required(),
    uf: yup.string().required(),
    company_site: yup
      .string()
      .test(
        'site',
        'Digite uma URL válida',
        value =>
          !value ||
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(value)
      )
  })
)

export const editLeadSchema = yupResolver(
  yup.object().shape({
    first_name: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    email: yup.lazy(value => {
      if (value !== undefined) {
        return yup
          .string()
          .email('Email inválido')
          .required()
      }
      return yup.mixed()
    }),
    celphone: yup.lazy(value => {
      if (value !== undefined) {
        return yup
          .string()
          .required()
          .test('incomplete_celphone', 'Celular incompleto', value => value.replace(/\D/g, '').length === 11)
      }
      return yup.mixed()
    }),
    company_name: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    average_monthly_revenue: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    desired_amount: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    company_history: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    lending_reason: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    street: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    zip_code: yup.lazy(value => {
      if (value !== undefined) {
        return yup
          .string()
          .required()
          .test('cep', 'CEP incompleto', value => value.replace(/\D/g, '').length === CEP_LENGTH)
      }
      return yup.mixed()
    }),
    number: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    district: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    city: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    uf: yup.lazy(value => (value !== undefined ? yup.string().required() : yup.mixed())),
    company_site: yup
      .string()
      .test(
        'site',
        'Digite uma URL válida',
        value =>
          !value ||
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(value)
      )
  })
)

export const newCreditRequestSchemaNoCnpj = yupResolver(
  yup.object().shape({
    lead_origin_id: yup.string().required(),
    indicated_by: yup.string().when('lead_origin_id', {
      is: '3',
      then: yup.string().required()
    }),
    responsible: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    celphone: yup.string().required(),
    email: yup
      .string()
      .email('Email inválido')
      .required(),
    company_type: yup.string().required(),
    franchisor_name: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    franchise_description: yup.string().when('company_type', {
      is: 'Franquia',
      then: yup.string().required()
    }),
    average_monthly_revenue: yup.string().required(),
    lending_reason: yup.string().required(),
    have_cnpj: yup.boolean()
  })
)
