import React, { useState, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { Row, IconButton } from '../../../../components/v2Components'
import { GlobalFilter } from '../../../NewUserInvestments/components'
import { IconEdit, IconDelete, IconCopy } from '../../../../components/v2Components/icons'
import { AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HomeIcon from '@material-ui/icons/Home'

import { updateFQS } from '../../../../services/v3'

import { getSiteLink } from '../../../../helpers'

import { FilterContainer, Accordion, AnswerFQS } from './style'

const CardFQS = ({ questions, setModalAddFQS, setModalDeleteFQS, fetchInfos }) => {
  const [filter, setFilter] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [items, setItems] = useState(questions)

  const data = useMemo(
    () =>
      questions.filter(
        item =>
          `${item.question.toUpperCase()}${item.answer.toUpperCase()}`.indexOf(filter.toUpperCase()) !== -1 && item
      ),
    [(questions, filter)]
  )

  const handleChange = useCallback(
    panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    },
    []
  )

  const handleCopy = useCallback(item => {
    navigator.clipboard.writeText(`${getSiteLink()}/perguntas-frequentes/${item.url}`)
    toast.success('Link da pergunta copiado.')
  }, [])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const newItems = [...items]
    const [removed] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, removed)
    setItems(newItems)
    const update = async () => {
      newItems.map(async (item, index) => {
        await updateFQS(item.id, { order: index })
      })
    }

    update().then(() => {
      fetchInfos()
      toast.success('Ordem atualizada')
    })
  }

  return (
    <div style={{ marginBottom: '50px' }}>
      <FilterContainer>
        <GlobalFilter
          filter={filter}
          setFilter={value => {
            setFilter(value)
          }}
        />
      </FilterContainer>
      {filter !== '' ? (
        <div>
          {data.map((item, index) => (
            <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)} key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: '40px', color: '#9E9E9E' }} />}>
                <h4>{item.question}</h4>
                <Row justifyContent='flex-end' mt='5px' width='100%' alignItems='center'>
                  <IconButton
                    icon={<IconCopy fontSize='small' />}
                    variant='text'
                    onClick={() => {
                      handleCopy(item)
                    }}
                  />
                  {!!item.is_featured && <HomeIcon style={{ color: '#076F9F' }} />}
                  <IconButton
                    icon={<IconEdit fontSize='small' />}
                    variant='text'
                    onClick={() => {
                      setModalAddFQS(item)
                    }}
                  />
                  <IconButton
                    icon={<IconDelete fontSize='small' />}
                    variant='text'
                    onClick={() => {
                      setModalDeleteFQS(item)
                    }}
                  />
                </Row>
              </AccordionSummary>

              <AnswerFQS dangerouslySetInnerHTML={{ __html: item.answer }} />
            </Accordion>
          ))}
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)} key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ fontSize: '40px', color: '#9E9E9E' }} />}
                          >
                            <h4>{item.question}</h4>
                            <Row justifyContent='flex-end' mt='5px' width='100%' alignItems='center'>
                              <IconButton
                                icon={<IconCopy fontSize='small' />}
                                variant='text'
                                onClick={() => {
                                  handleCopy(item)
                                }}
                              />
                              {!!item.is_featured && <HomeIcon style={{ color: '#076F9F' }} />}
                              <IconButton
                                icon={<IconEdit fontSize='small' />}
                                variant='text'
                                onClick={() => {
                                  setModalAddFQS(item)
                                }}
                              />
                              <IconButton
                                icon={<IconDelete fontSize='small' />}
                                variant='text'
                                onClick={() => {
                                  setModalDeleteFQS(item)
                                }}
                              />
                            </Row>
                          </AccordionSummary>

                          <AnswerFQS dangerouslySetInnerHTML={{ __html: item.answer }} />
                        </Accordion>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  )
}

export default CardFQS
