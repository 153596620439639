import styled from '@emotion/styled'

export const Updates = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
export const Table = styled.div`
  width: 500px;
  margin-bottom: 20px;
`
export const Update = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.5em 0.5em;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
`
export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  padding: 0 0.5em;
`
