import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalBase, Text, Column, Row, Button, Select, Input } from '../../../index'

import { modalAproveResolver } from '../../../../../schemas'

import { updateLeadDocument } from '../../../../../services/v3'

const defaultValue = { name: '' }

const ModalEditDoc = ({ open, handleClose, documentType, fetchInfos }) => {
  const {
    control,
    errors,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(modalAproveResolver),
    defaultValues: defaultValue
  })

  const onSubmit = async data => {
    try {
      await updateLeadDocument(open.id, { filename: data.name, lead_document_type_id: data.document_type })
      fetchInfos()
      toast.success('Documento editado com sucesso.')
    } catch {
      toast.error('Erro ao editar documento.')
    } finally {
      handleClose()
    }
  }

  useEffect(() => {
    reset({ name: open.filename && open.filename.split('.').shift(), document_type: open.lead_document_type_id })
  }, [open])

  return (
    <ModalBase open={!!open ? true : false} onClose={handleClose} height='100%' width='100%' title='Documento'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Column justifyContent='space-around' style={{ gap: '20px' }}>
          <Text variant='h4'>Você tem certeza que deseja editar este documento?</Text>
          <Select
            rigth='true'
            name='document_type'
            label='Tipo de documento'
            control={control}
            error={errors.document_type}
            options={documentType.map(item => {
              return { value: item.id, label: item.name }
            })}
            mb='10px'
          />
          <Input name='name' label='Nome' control={control} error={errors.name} />
          <Row>
            <Button height={40} width={227} onClick={handleClose} mr={3} variant='outlined'>
              Cancelar
            </Button>
            <Button height={40} width={227} type='submit' loader={isSubmitting}>
              Sim
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export default ModalEditDoc
