import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

import { origins } from '../constants'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório'
  }
})

export const editLeadIndicatorSchema = yupResolver(
  yup.object().shape({
    origin: yup.string().required(),
    indicated_by: yup.object().when('origin', {
      is: origin => [origins.PARTNER, origins.FRANCHISOR].includes(origin),
      then: yup
        .object()
        .typeError('Campo Obrigatório')
        .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value)),
      otherwise: yup.object().nullable()
    })
  })
)
