import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment-timezone'
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'
import { FormControlLabel } from '@material-ui/core'

import Pledges from './Pledges'
import Pledge from './Pledge'
import FilterByName from './FilterByName'
import { PledgeModal, Checkbox, MaterialButton, LoadingComponent, ModalReopenCampaign } from '../../components'

import { formatMoney, getAnnualRate } from '../../helpers'
import {
  acceptedAverageM,
  filterAcceptedPledgesArray,
  sortPledgesBySttAndKvdid,
  matrization,
  sortMatrizationById
} from '../../helpers/pledges'
import { campaignStatuses } from '../../constants'
import { useDebounce } from '../../hooks'
import { IDLE, FETCHING, LOADING, SUBMITTING } from '../../constants/status'
import {
  getCampaignById,
  getPledges,
  getPledgeStatuses,
  updatePledge,
  processCampaignPledges,
  getDownloadPledges
} from '../../services/v3'

const ProcessOrders = ({ match, updateCampaignStatus }) => {
  const [campaign, setCampaign] = useState('')
  const [filters, setFilters] = useState(['AC', 'A'])
  const [isOpen, setIsOpen] = useState(false)
  const [downloadFilters, setDownloadFilters] = useState({ AC: true, A: true })
  const [filterName, setFilterName] = useState('')
  const [matrizationPledges, setMatrizationPledges] = useState([])
  const [hasConfirmedPledges, setHasConfirmedPledges] = useState(true)
  const [status, setStatus] = useState(IDLE)
  const [pledgeStatuses, setPledgeStatuses] = useState([])
  const [isOpenModalReopenCampaign, setIsOpenModalReopenCampaign] = useState(false)

  const fetchPledgesData = async () => {
    setStatus(FETCHING)
    try {
      const { data: pledges } = await getPledges({ campaign_id: match.params.id })
      setHasConfirmedPledges(
        pledges.some(({ pledge_status_id, pledge_status }) => pledge_status_id && pledge_status.abbreviation === 'A')
      )
      const copyPledges = pledges.slice().sort(sortPledgesBySttAndKvdid)

      setMatrizationPledges(matrization(copyPledges))
    } catch (error) {
      console.error(error)

      setMatrizationPledges([])
    } finally {
      setStatus(IDLE)
    }
  }

  const fetchCampaign = async () => {
    try {
      setStatus(FETCHING)
      const { data: campaign } = await getCampaignById(match.params.id)
      setCampaign(campaign)
      fetchPledgesData()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao carregar a campanha')
    } finally {
      setStatus(IDLE)
    }
  }

  useEffect(() => {
    const fetchPledgeStatuses = async () => {
      try {
        setStatus(FETCHING)
        const { data: pledgeStatuses } = await getPledgeStatuses()
        setPledgeStatuses(pledgeStatuses)
      } catch (error) {
        console.error(error)
        toast.error('Ocorreu um erro ao buscar os status de pedidos')
      } finally {
        setStatus(IDLE)
      }
    }

    fetchPledgeStatuses()
    fetchCampaign()
  }, [])

  const handleClick = _ => setIsOpen(!isOpen)

  const handleDownloadPledges = useCallback(async () => {
    try {
      const { data: response } = await getDownloadPledges(match.params.id)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = `Pedidos-${!!campaign ? campaign.name : ''}.xlsx`
      element.click()
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao exportar a listagem em CSV')
    }
  }, [match.params.id, campaign])

  const debounceFilterName = useDebounce(filterName, 500)

  const handleCheckbox = e => {
    const isChecked = e.target.checked
    if (e.target.id === 'confirmed') {
      setFilters(filters =>
        isChecked ? [].concat(['AC', 'A'], filters) : filters.filter(filter => !['AC', 'A'].includes(filter))
      )
    }
  }

  const formatFraction = data => {
    if (!data) return null
    const totalAmount = data.reduce((acc, obj) => acc + obj.reduce((sum, pledge) => sum + pledge.amount, 0), 0)
    return (
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: '20px' }}>
          <span>R$ {formatMoney(totalAmount)}</span>
        </p>
        <p>
          {data.length}/<span>{matrizationPledges.length}</span>
        </p>
      </div>
    )
  }

  const handleUpdate = async (pledge, newStatus) => {
    try {
      setStatus(LOADING)
      const findNewStatus = pledgeStatuses.find(({ abbreviation }) => abbreviation === newStatus)
      if (!findNewStatus) throw new Error('Ocorreu um erro ao buscar o novo status do pedido')
      const body = {
        campaign_id: pledge.campaign_id,
        pledge_status_id: findNewStatus.id
      }
      const {
        data: { should_campaign_be_active }
      } = await updatePledge(pledge.id, body)

      if (should_campaign_be_active) showReopenCampaignModal()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar o status do pedido')
    } finally {
      setStatus(IDLE)
      fetchCampaign()
    }
  }

  const handleUpdatePledges = async (pledges, newStatus) => {
    try {
      setStatus(LOADING)
      const findNewStatus = pledgeStatuses.find(({ abbreviation }) => abbreviation === newStatus)
      if (!findNewStatus) throw new Error('Ocorreu um erro ao buscar o novo status dos pedidos')
      const updatesResonse = await Promise.all(
        pledges.map(pledge =>
          updatePledge(pledge.id, {
            campaign_id: pledge.campaign_id,
            pledge_status_id: findNewStatus.id
          })
        )
      )
      const shouldReopen = updatesResonse.some(({ data: { should_campaign_be_active } }) => should_campaign_be_active)
      if (shouldReopen) showReopenCampaignModal()
    } catch (error) {
      console.error(error)
      toast.error('Ocorreu um erro ao atualizar o status dos pedidos')
    } finally {
      setStatus(IDLE)
      fetchCampaign()
    }
  }

  const showReopenCampaignModal = () => setIsOpenModalReopenCampaign(true)

  const checkboxChange = name => event => {
    if (name === 'AC') {
      setDownloadFilters({ ...downloadFilters, A: event.target.checked, AC: event.target.checked })
    } else {
      setDownloadFilters({ ...downloadFilters, [name]: event.target.checked })
    }
  }

  const processPledges = async () => {
    try {
      setStatus(SUBMITTING)
      await processCampaignPledges(campaign.id)
      updateCampaignStatus(campaignStatuses.BMP_SIMULATOR)
    } catch (error) {
      console.error(error)
      toast.error('Erro ao processar os pedidos')
    } finally {
      setStatus(IDLE)
    }
  }

  if (status === FETCHING || status === LOADING) {
    return (
      <Container>
        <LoadingComponent />
      </Container>
    )
  }

  return (
    <Container>
      <Content>
        <CampaignName>{campaign.name}</CampaignName>
        <Header>
          <div>
            <CampaignInfo>
              Data do lançamento:
              {moment.utc(campaign.start_date).format('DD/MM/YYYY [às] HH:mm')}
            </CampaignInfo>
            <CampaignInfo>Meta de captação: R$ {formatMoney(campaign.amount)}</CampaignInfo>
          </div>
          <div>
            <CampaignInfo>Taxa mensal: {campaign.rate_month}%</CampaignInfo>
            <CampaignInfo>Taxa anual: {getAnnualRate(campaign.rate_month)}% </CampaignInfo>
          </div>
          <div>
            <CampaignInfo>Ticket médio: R$ {formatMoney(acceptedAverageM(matrizationPledges))}</CampaignInfo>
            <CampaignInfo>
              <MaterialButton
                disabled={!hasConfirmedPledges}
                width={230}
                onClick={processPledges}
                loading={status === SUBMITTING}
                label='Processar pedidos'
              />
            </CampaignInfo>
          </div>
        </Header>
        <DownloadCSVContainer>
          <DownloadCSVButton
            disabled={!downloadFilters.A && !downloadFilters.OH && !downloadFilters.C && !downloadFilters.D}
            onClick={handleDownloadPledges}
          >
            Exportar Pedidos
          </DownloadCSVButton>

          <FormControlLabel
            control={
              <Checkbox checked={downloadFilters.AC} onChange={checkboxChange('AC')} value='AC' color='primary' />
            }
            label='Confirmados'
          />
        </DownloadCSVContainer>
        <Filters>
          <FilterByName onChangeName={setFilterName} />
          <Filter labelBg='#5cb85c' hidden={!filterAcceptedPledgesArray(matrizationPledges).length}>
            <div>
              <input onChange={handleCheckbox} id='confirmed' type='checkbox' defaultChecked />
              <label htmlFor='confirmed'>Lista de confirmados</label>
            </div>
            {formatFraction(filterAcceptedPledgesArray(matrizationPledges))}
          </Filter>
        </Filters>
        {matrizationPledges
          .filter(pledgeArray => filters.includes(pledgeArray[0].pledge_status.abbreviation))
          .filter(pledgeArray => {
            const { first_name, last_name } = pledgeArray[0].investor
            return (
              `${first_name} ${last_name}`.toUpperCase().includes(debounceFilterName.toUpperCase()) ||
              `${first_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')} ${last_name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')}`
                .toUpperCase()
                .includes(debounceFilterName.toUpperCase())
            )
          })
          .sort(sortMatrizationById)
          .map(pledgeArray =>
            pledgeArray.length === 1 ? (
              <Pledge
                key={pledgeArray[0].id}
                pledge={pledgeArray[0]}
                reload={fetchCampaign}
                handleUpdate={handleUpdate}
                campaign={campaign}
                pledgeStatuses={pledgeStatuses}
                reopenCampaingModal={showReopenCampaignModal}
              />
            ) : (
              <Pledges
                key={pledgeArray[0].id}
                pledges={pledgeArray}
                reload={fetchCampaign}
                handleUpdate={handleUpdate}
                handleUpdatePledges={handleUpdatePledges}
                campaign={campaign}
                pledgeStatuses={pledgeStatuses}
                reopenCampaingModal={showReopenCampaignModal}
              />
            )
          )}
      </Content>
      <CreatePledgeContainer data-tip='Adicionar Pledge' onClick={handleClick}>
        <PlusIcon>+</PlusIcon>
      </CreatePledgeContainer>
      {isOpen && (
        <PledgeModal
          campaign={campaign}
          handleClick={handleClick}
          reload={fetchCampaign}
          title='Criar Pedido'
          pledgeStatuses={pledgeStatuses}
          reopenCampaingModal={showReopenCampaignModal}
        />
      )}
      {isOpenModalReopenCampaign && (
        <ModalReopenCampaign
          isOpen={isOpenModalReopenCampaign}
          setIsOpen={setIsOpenModalReopenCampaign}
          campaignId={campaign.id}
          reload={fetchCampaign}
        />
      )}
      <ReactTooltip />
    </Container>
  )
}

const CampaignInfo = styled.p`
  padding: 0.3em;
  font-size: 1em;
  font-weight: 500;
  @media (max-width: 769px) {
    padding: 0.3em 0;
  }
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1em;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
    justify-content: center;
  }
`

const DownloadCSVContainer = styled.div`
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    label {
      width: 150px;
    }
  }
`

const DownloadCSVButton = styled.button(
  ({ disabled }) => `
  border-radius: 4px;
  padding: 0.6em 35px;
  margin: 0 1em 0 0;
  background: white;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  outline: none;
  background: ${disabled ? '#f5f5f5' : 'white'};
  pointer-events: ${disabled ? 'none' : 'initial'};
  color: ${disabled ? '#252525' : '#076F9F'};
  border: 1px solid ${disabled ? '#252525' : '#076F9F'};
  cursor: ${disabled ? 'not-allwed' : 'pointer'};

  &:hover {
    background: #076f9f;
    color: white;
    transition: all 0.3s;
  }
`
)

const CampaignName = styled.h2``

const PlusIcon = styled.p`
  font-size: 30px;
`

const CreatePledgeContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5em;
  border-radius: 50%;
  background-color: #5cb85c;
  box-shadow: 0 0 1px 0 #5cb85c inset, 0 0 1px 0 #5cb85c;
  position: fixed;
  bottom: 20px;
  right: 45px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #3d7c3d;
    box-shadow: 0 0 1px 0 #3d7c3d inset, 0 0 1px 0 #3d7c3d;
    transition: all 0.3s;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  p {
    span {
      font-weight: 500;
    }
  }
`

const Content = styled.div`
  width: 80%;
  @media (max-width: 769px) {
    margin-bottom: 60px;
  }
`

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  ${props => props.hidden && 'display: none;'}
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  input {
    margin-right: 10px;
  }
  label {
    background-color: ${props => props.labelBg};
    color: white;
    padding: 0.25em 0.4em;
    border-radius: 0.25rem;
    font-weight: 900;
    line-height: 1;
  }
  input {
    width: 15px;
    height: 15px;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    padding: 16px;
    height: 100px;
  }
`

const Filters = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  margin-bottom: 30px;
`

export default ProcessOrders
