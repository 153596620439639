import React, { useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import { ThemeProvider } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import { createBrowserHistory } from 'history'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer } from 'react-toastify'
import languages from 'numbro/dist/languages.min'
import numbro from 'numbro'
import styled from 'styled-components'
import moment from 'moment-timezone'

import {
  Login,
  BankCreditCertificates,
  Campaigns,
  CampaignForm,
  CampaignSummary,
  AboutCompany,
  CampaignStatus,
  CampaignPledges,
  CampaignPhoto,
  CampaignGallery,
  CampaignUpdates,
  CampaignDelete,
  Parcels,
  Persons,
  AboutPerson,
  CampaignPage,
  ProcessCampaign,
  Simulator,
  BankCreditCertificatesUpload,
  Investors,
  Partners,
  RunCampaign,
  Renegotiation,
  Anticipation,
  NewCreditRequesList,
  DetailsCreditRequest,
  Contributors,
  CreditRequestFormEdit,
  CreditRequestForm,
  NewUserInvestments,
  DetailsInvestments,
  HomeEdit,
  JudicialExecution,
  InstallmentsMaturities,
  OperationsControl
} from './routes'

import { Header, NavigationBar } from './components'

import { getToken } from './helpers/auth'
import { colors } from './Themes'
import { GlobalContext } from './contexts'

import 'sanitize.css/sanitize.css'

Object.values(languages).forEach(l => numbro.registerLanguage(l))
numbro.setLanguage('pt-BR')

library.add(faStroopwafel, faChevronDown, faChevronUp)
const history = createBrowserHistory()
const theme = {
  colors
}

const App = () => {
  useEffect(() => {
    const token = getToken()
    if (!token) {
      return history.push('/login')
    }
  }, [])

  useEffect(() => {
    moment.updateLocale('pt-br', { week: { dow: 0 } })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          * {
            font-family: 'Roboto', sans-serif;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
          }
          a {
            text-decoration: none;
          }
        `}
      />
      <ToastContainer hideProgressBar pauseOnFocusLoss={false} autoClose={3000} />
      <GlobalContext.Provider value={{}}>
        <Router history={history}>
          <Switch>
            <Route path='/login' component={props => <Login {...props} />} />
            <PageContainer>
              <Header history={history} />
              <InsideContainer>
                <Route path='/campanha/:id/' component={props => <NavigationBar {...props} />} />
                <Route exact path='/campanha/:id/pedidos' component={props => <CampaignPledges {...props} />} />
                <Route exact path='/campanha/:id/ccbs' component={props => <BankCreditCertificates {...props} />} />
                <Route exact path='/campanha/:id/parcelas' component={props => <Parcels {...props} />} />
                <Route exact path='/campanha/:id/renegociacao' component={props => <Renegotiation {...props} />} />
                <Route exact path='/campanha/:id/antecipacao' component={props => <Anticipation {...props} />} />
                <Route exact path='/campanha/:id/judicial' component={props => <JudicialExecution {...props} />} />
                <Route exact path='/campanha/:id/simulador-bmp' component={props => <Simulator {...props} />} />
                <Route exact path='/campanha/:id/processar' component={props => <ProcessCampaign {...props} />} />
                <Route exact path='/campanha/:id/rodar-campanha' component={props => <RunCampaign {...props} />} />
                <Route exact path='/' component={props => <Campaigns {...props} />} />
                <Route exact path='/campanha/:id/' component={props => <CampaignPage {...props} />} />
                <Route exact path='/campanhas/criar' component={props => <CampaignForm {...props} />} />
                <Route exact path='/campanhas/:id/informacoes' component={props => <CampaignForm {...props} />} />
                <Route exact path='/campanhas/:id/resumo' component={props => <CampaignSummary {...props} />} />
                <Route exact path='/campanhas/:id/sobre-a-empresa' component={props => <AboutCompany {...props} />} />
                <Route exact path='/campanhas/status' component={props => <CampaignStatus {...props} />} />
                <Route
                  exact
                  path='/campanha/:id/ccbs/upload-em-lote'
                  component={props => <BankCreditCertificatesUpload {...props} />}
                />
                <Route exact path='/campanhas/:id/foto' component={props => <CampaignPhoto {...props} />} />
                <Route exact path='/campanhas/:id/galeria' component={props => <CampaignGallery {...props} />} />
                <Route exact path='/campanhas/:id/atualizacoes' component={props => <CampaignUpdates {...props} />} />
                <Route exact path='/campanhas/:id/excluir' component={props => <CampaignDelete {...props} />} />
                <Route exact path='/pessoas' component={props => <Persons {...props} />} />
                <Route exact path='/pessoas/:id/informacoes' component={props => <AboutPerson {...props} />} />
                <Route
                  exact
                  path='/usuarios/:id/investimentos'
                  component={props => <NewUserInvestments {...props} />}
                />
                <Route
                  exact
                  path='/usuarios/:id/detalhe-investimento/:campaignId'
                  component={props => <DetailsInvestments {...props} />}
                />

                <Route exact path='/usuarios' component={props => <Investors {...props} />} />
                <Route exact path='/parceiros' component={props => <Partners {...props} />} />
                <Route
                  exact
                  path='/formulario-solicitacao-credito/:id'
                  component={props => <CreditRequestFormEdit {...props} />}
                />
                <Route
                  exact
                  path='/formulario-solicitacao-credito'
                  component={props => <CreditRequestForm {...props} />}
                />
                <Route exact path='/solicitacoes-de-credito' component={props => <NewCreditRequesList {...props} />} />
                <Route exact path='/funcionarios' component={props => <Contributors {...props} />} />
                <Route
                  exact
                  path='/detalhes-solicitação-de-credito/:id'
                  component={props => <DetailsCreditRequest {...props} />}
                />
                <Route exact path='/home' component={props => <HomeEdit {...props} />} />
                <Route exact path='/vencimentos' component={props => <InstallmentsMaturities {...props} />} />
                <Route exact path='/controle-operacao' component={props => <OperationsControl {...props} />} />
              </InsideContainer>
            </PageContainer>
          </Switch>
        </Router>
      </GlobalContext.Provider>
    </ThemeProvider>
  )
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
`

const InsideContainer = styled.div`
  min-height: 100vh;
  width: 100%;
`

export default App
