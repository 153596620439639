import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import * as yup from 'yup'

import { MaterialButton, Modal, Loader } from '../../../components'
import { Input as InputV2, NumberInput, Row } from '../../../components/v2Components'

import { NumberTax } from '../../../helpers'
import { simulateInstallmentsDelay, updateValueDelayInstallments } from '../../../services/v3'

const formatToBrl = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

const ModalInstallmentDelay = ({ isOpen, setIsOpen, reload }) => {
  const [returnValues, setReturnValues] = useState(null)
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false)

  const { handleSubmit, errors, control, reset } = useForm({})
  const { handleSubmit: handleSubmitSend, errors: errorsSend, control: controlSend, reset: resetSend } = useForm({})

  yup.setLocale({
    mixed: {
      required: 'Campo obrigatório'
    }
  })

  const diffDays = (dateStart, dateEnd) => {
    const momentStart = moment.tz(dateStart, 'DD/MM/YYYY', 'America/Sao_Paulo')
    const momentEnd = moment.tz(dateEnd.slice(0, 10), 'America/Sao_Paulo')
    const diffDays = momentStart.diff(momentEnd, 'days')
    return diffDays >= 0 ? diffDays : 0
  }

  const handleSubmitCalculate = async values => {
    try {
      const dataInstallment = {
        days_late: values.days_late,
        campaign_id: isOpen.campaign_id,
        installment_number: Number(values.installment_number.split(' ', 1)[0]),
        tax_default_interest: NumberTax(values.tax_default_interest),
        tax_fine: NumberTax(values.tax_fine)
      }
      setIsLoadingCalculate(true)
      const { data: returnSimulate } = await simulateInstallmentsDelay(dataInstallment)
      setReturnValues({
        ...returnSimulate,
        pay_day: moment(values.pay_day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        days_late: values.days_late,
        campaign_id: isOpen.campaign_id,
        installment_number: Number(values.installment_number.split(' ', 1)[0]),
        tax_default_interest: NumberTax(values.tax_default_interest),
        tax_fine: NumberTax(values.tax_fine)
      })
      resetSend({
        interest: formatValue(returnSimulate.interest),
        default_interest: formatValue(returnSimulate.default_interest),
        fine: formatValue(returnSimulate.fine),
        amountLate: formatValue(returnSimulate.totalAmountLate),
        installmentLate: formatValue(returnSimulate.installmentLate)
      })
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao calcular o atraso da parcela.')
    } finally {
      setIsLoadingCalculate(false)
    }
  }

  const formatValue = value => formatToBrl.format(value)

  const handleSubmitSave = async values => {
    try {
      const dataInstallment = {
        days_late: returnValues.days_late,
        tax_default_interest: returnValues.tax_default_interest,
        tax_fine: returnValues.tax_fine,
        expected_date: returnValues.pay_day
      }

      await updateValueDelayInstallments(isOpen.id, dataInstallment)
      toast.success('Dados atualizados com sucesso.')
      setReturnValues(null)
      reload()
      setIsOpen(false)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar atualizar os dados')
      console.log(error)
    }
  }

  useEffect(() => {
    if (!!isOpen) {
      reset({
        pay_day: moment.utc(isOpen.newDate).format('DD/MM/YYYY'),
        days_late: !!isOpen.newDate && !!isOpen.expire_date ? diffDays(isOpen.newDate, isOpen.expire_date) : '',
        tax_default_interest: 1,
        tax_fine: 2,
        campaign_fee: isOpen.campaign.rate_month,
        installment_number: `${isOpen.installment_number} (${moment.utc(isOpen.expire_date).format('DD/MM/YYYY')})`
      })
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={!!isOpen}
      setIsOpen={setIsOpen}
      onRequestClose={() => {
        setReturnValues(null)
      }}
    >
      <ModalContent>
        <Title>Cálculo de atraso da parcela</Title>
        <SimulatorForm onSubmit={handleSubmit(handleSubmitCalculate)}>
          <GridInpu style={{ gridArea: 'input' }}>
            <InputV2
              control={control}
              error={errors.installment_number}
              name='installment_number'
              label='Nº Parcela'
              disabled
            />
            <InputV2 control={control} error={errors.pay_day} name='pay_day' label='Data de pagamento' disabled />
            <NumberInput
              control={control}
              error={errors.days_late}
              width='230px'
              name='days_late'
              label='Dias de atraso'
              disabled
            />
            <NumberInput
              control={control}
              error={errors.tax_default_interest}
              width='230px'
              name='tax_default_interest'
              label='Juros mora'
              suffix='%'
            />
            <NumberInput
              control={control}
              error={errors.tax_fine}
              width='230px'
              name='tax_fine'
              label='Multa'
              suffix='%'
            />
            <NumberInput
              control={control}
              error={errors.campaign_fee}
              width='230px'
              name='campaign_fee'
              label='Taxa da campanha'
              suffix='%'
              disabled
            />
          </GridInpu>
          <Row style={{ gridArea: 'button' }} justifyContent='flex-end'>
            {isLoadingCalculate ? <Loader /> : <MaterialButton type='submit' label='Calcular' />}
          </Row>
        </SimulatorForm>

        {returnValues && !isLoadingCalculate && (
          <SimulatorForm onSubmit={handleSubmitSend(handleSubmitSave)}>
            <GridInpu style={{ gridArea: 'input' }}>
              <InputV2 control={controlSend} error={errorsSend.interest} name='interest' label='Juros' disabled />
              <InputV2
                control={controlSend}
                error={errorsSend.default_interest}
                name='default_interest'
                label='Mora'
                disabled
              />
              <InputV2 control={controlSend} error={errorsSend.fine} name='fine' label='Multa' disabled />
              <InputV2
                control={controlSend}
                error={errorsSend.amountLate}
                name='amountLate'
                label='Total atraso'
                disabled
              />
              <InputV2
                control={controlSend}
                error={errorsSend.installmentLate}
                name='installmentLate'
                label='Valor parcela'
                disabled
              />
            </GridInpu>
            <Row style={{ gridArea: 'button' }} justifyContent='flex-end'>
              <MaterialButton type='submit' label='Salvar' />
            </Row>
          </SimulatorForm>
        )}
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
`
const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`

const SimulatorForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'input input'
    'button button';
  gap: 11px;
`

const GridInpu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
`

export default ModalInstallmentDelay
