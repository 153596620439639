import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import * as yup from 'yup'

import { MaterialButton, Modal, Input, Loader, Select } from '../../../components'

import { simulateInstallmentsDelay, changeStatusLatePayments } from '../../../services/v3'

import { colors } from '../../../Themes'

const formatToBrl = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

const DATE_LENGTH = 10

const ModalInstallmentDelay = ({ isOpen, setIsOpen, campaign, installments, reload }) => {
  const [returnValues, setReturnValues] = useState(null)
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false)
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  yup.setLocale({
    mixed: {
      required: 'Campo obrigatório'
    }
  })

  const validationSchema = yup.object().shape({
    pay_day: yup.string().required(),
    days_late: yup
      .number()
      .required()
      .moreThan(0, 'Data inválida.'),
    tax_default_interest: yup.number().required(),
    tax_fine: yup.number().required(),
    campaign_fee: yup.string().required(),
    installment_number: yup.string().required()
  })

  const diffDays = (dateStart, dateEnd) => {
    const momentStart = moment.tz(dateStart, 'DD/MM/YYYY', 'America/Sao_Paulo')
    const momentEnd = moment.tz(dateEnd.slice(0, 10), 'America/Sao_Paulo')
    const diffDays = momentStart.diff(momentEnd, 'days')
    return diffDays >= 0 ? diffDays : 0
  }

  const handleDays = (e, setFieldValue, installment_number) => {
    if (e.target.value && e.target.value.length === DATE_LENGTH) {
      const parcel = installments.find(installment => installment.installment_number === Number(installment_number))

      if (parcel) setFieldValue('days_late', diffDays(e.target.value, parcel.expire_date))
    } else {
      setFieldValue('days_late', 0)
    }
    setFieldValue('pay_day', e.target.value)
  }

  const handleChangeInstallment = (e, setFieldValue, pay_day) => {
    if (pay_day && pay_day.length === DATE_LENGTH) {
      const parcel = installments.find(installment => installment.installment_number === Number(e.target.value))
      if (parcel) setFieldValue('days_late', diffDays(pay_day, parcel.expire_date))
    } else {
      setFieldValue('days_late', 0)
    }
    setFieldValue('installment_number', e.target.value)
  }

  const handleSubmitCalculate = async values => {
    try {
      const dataInstallment = {
        days_late: values.days_late,
        campaign_id: campaign.id,
        installment_number: Number(values.installment_number),
        tax_default_interest: values.tax_default_interest,
        tax_fine: values.tax_fine
      }
      setIsLoadingCalculate(true)
      const { data: returnSimulate } = await simulateInstallmentsDelay(dataInstallment)
      setReturnValues({
        ...returnSimulate,
        pay_day: moment(values.pay_day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        days_late: values.days_late,
        campaign_id: campaign.id,
        installment_number: Number(values.installment_number),
        tax_default_interest: values.tax_default_interest,
        tax_fine: values.tax_fine
      })
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao calcular o atraso da parcela.')
    } finally {
      setIsLoadingCalculate(false)
    }
  }

  const formatValue = value => formatToBrl.format(value)

  const handleSubmitSave = async values => {
    try {
      const dataInstallment = {
        fine: values.fine,
        default_interest: values.default_interest,
        interest: values.interest,
        days_late: values.days_late,
        tax_fine: values.tax_fine,
        tax_default_interest: values.tax_default_interest,
        pay_day: values.pay_day
      }
      const parcel = installments.find(
        installment => installment.installment_number === Number(values.installment_number)
      )

      setIsLoadingSave(true)
      await changeStatusLatePayments(parcel.id, dataInstallment)
      toast.success('Dados atualizados com sucesso.')
      setReturnValues(null)
      reload()
      setIsOpen(false)
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar atualizar os dados')
      console.log(error)
      setIsLoadingSave(false)
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onRequestClose={() => {
        setReturnValues(null)
      }}
    >
      <ModalContent>
        <Title>Cálculo de atraso da parcela</Title>
        <Formik
          initialValues={{
            pay_day: '',
            days_late: '',
            tax_default_interest: 1,
            tax_fine: 2,
            campaign_fee: campaign.rate_month,
            installment_number: ''
          }}
          onSubmit={handleSubmitCalculate}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, errors, values }) => (
            <Form>
              <InputContainer>
                <InputRowContainer>
                  <InputContent>
                    <Field
                      name='installment_number'
                      render={({ field }) => (
                        <Select
                          label='Nº Parcela'
                          field={field}
                          onChange={e => handleChangeInstallment(e, setFieldValue, values.pay_day)}
                        >
                          <option value='' disabled />
                          {installments
                            .filter(({ installment_status }) => installment_status.abbreviation === 'O')
                            .map(({ installment_number, expire_date }) => (
                              <option key={installment_number} value={installment_number}>
                                {installment_number} ({moment.utc(expire_date).format('DD/MM/YYYY')})
                              </option>
                            ))}
                        </Select>
                      )}
                    />
                    <Error>{errors.installment_number}</Error>
                  </InputContent>

                  <InputContent>
                    <Label>Data de pagamento</Label>
                    <Input
                      name='pay_day'
                      type='date'
                      mask='99/99/9999'
                      onChange={e => {
                        handleDays(e, setFieldValue, values.installment_number)
                      }}
                    />
                    <Error>{errors.pay_day}</Error>
                  </InputContent>

                  <SmallInputContent>
                    <Label>Dias de atraso</Label>
                    <Input name='days_late' value={values.days_late} disabled />
                    <Error>{errors.days_late}</Error>
                  </SmallInputContent>

                  <SmallInputContent>
                    <Label>% juros mora</Label>
                    <Input
                      name='tax_default_interest'
                      type='number'
                      mask='nnnn'
                      value={values.tax_default_interest}
                      formatChars={{ n: '[0-9/.]' }}
                      onChange={e => setFieldValue('tax_default_interest', e.target.value)}
                    />
                    <Error>{errors.tax_default_interest}</Error>
                  </SmallInputContent>

                  <SmallInputContent>
                    <Label>% multa</Label>
                    <Input
                      name='tax_fine'
                      type='number'
                      mask='nnnn'
                      value={values.tax_fine}
                      formatChars={{ n: '[0-9/.]' }}
                      onChange={e => {
                        setFieldValue('tax_fine', e.target.value)
                      }}
                    />
                    <Error>{errors.tax_fine}</Error>
                  </SmallInputContent>

                  <SmallInputContent>
                    <Label>Taxa da campanha</Label>
                    <Input
                      name='campaign_fee'
                      value={`${campaign.rate_month}%`}
                      onChange={e => {
                        setFieldValue('campaign_fee', e.target.value)
                      }}
                      disabled
                    />
                    <Error>{errors.campaign_fee}</Error>
                  </SmallInputContent>
                </InputRowContainer>
              </InputContainer>
              {isLoadingCalculate ? <Loader /> : <MaterialButton type='submit' label='Calcular' />}
            </Form>
          )}
        </Formik>
        {returnValues && !isLoadingCalculate && (
          <Formik
            initialValues={{
              fine: returnValues.fine,
              default_interest: returnValues.default_interest,
              interest: returnValues.interest,
              totalAmountLate: returnValues.totalAmountLate,
              installmentLate: returnValues.installmentLate,
              pay_day: returnValues.pay_day,
              days_late: returnValues.days_late,
              campaign_id: campaign.id,
              installment_number: returnValues.installment_number,
              tax_default_interest: returnValues.tax_default_interest,
              tax_fine: returnValues.tax_fine
            }}
            onSubmit={handleSubmitSave}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <StyledInputContainer>
                  <InputColumnContainer>
                    <InputContent>
                      <Label>Juros</Label>
                      <Input
                        name='interest'
                        value={formatValue(values.interest)}
                        onChange={e => {
                          setFieldValue('interest', e.target.value)
                        }}
                        disabled
                      />
                    </InputContent>

                    <InputContent>
                      <Label>Mora</Label>
                      <Input
                        name='default_interest'
                        value={formatValue(values.default_interest)}
                        disabled
                        onChange={e => {
                          setFieldValue('default_interest', e.target.value)
                        }}
                      />
                    </InputContent>

                    <InputContent>
                      <Label>Multa</Label>
                      <Input
                        name='fine'
                        value={formatValue(values.fine)}
                        disabled
                        onChange={e => {
                          setFieldValue('fine', e.target.value)
                        }}
                      />
                    </InputContent>

                    <InputContent>
                      <Label>Total atraso</Label>
                      <Input
                        name='amountLate'
                        value={formatValue(values.totalAmountLate)}
                        disabled
                        onChange={e => {
                          setFieldValue('amountLate', e.target.value)
                        }}
                      />
                    </InputContent>
                  </InputColumnContainer>
                  <InputColumnContainer>
                    <InputContent>
                      <Label>Valor parcela</Label>
                      <Input
                        name='installmentLate'
                        value={formatValue(values.installmentLate)}
                        disabled
                        onChange={e => {
                          setFieldValue('installmentLate', e.target.value)
                        }}
                      />
                    </InputContent>
                    {isLoadingSave ? <Loader /> : <MaterialButton type='submit' label='Salvar' />}
                  </InputColumnContainer>
                </StyledInputContainer>
              </Form>
            )}
          </Formik>
        )}
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
`
const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const Label = styled.p`
  color: ${colors.mediumGray};
`
const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 10px;
`
const InputColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledInputContainer = styled(InputContainer)`
  flex-direction: row;
`
const InputContent = styled.div`
  width: 200px;
  padding: 0 10px 0 0;
`
const SmallInputContent = styled.div`
  width: 100px;
  padding: 0 10px 0 0;
`
const Error = styled.span`
  color: red;
  font-size: 0.8em;
`
export default ModalInstallmentDelay
