import client from '../../providers/fetchClient'

export const getPledges = params => client.get('/v3/pledges', { params })

export const createPledge = data => client.post('/v3/pledges/admins', data)

export const getPledgeStatuses = () => client.get('/v3/pledge-statuses')

export const updatePledge = (id, data) => client.put(`/v3/pledges/${id}`, data)

export const deletePledge = id => client.delete(`/v3/pledges/${id}`)

export const getDownloadPledges = (id, status) =>
  client.get(`/v3/pledges-list?pledge_status=${status}&campaign_id=${id}`, {
    responseType: 'arraybuffer'
  })
