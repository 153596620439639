import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { AddBox } from '@material-ui/icons'

function SimpleDialog(props) {
  return (
    <Dialog {...props} haria-labelledby='simple-dialog-title'>
      <DialogTitle id='simple-dialog-title'>Dados do usuário </DialogTitle>
      <List>
        <ListItem>
          <ListItemText primary='Nome' secondary={`${props.user.user_first_name} ${props.user.user_last_name}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary='E-mail' secondary={props.user.email} />
        </ListItem>
        <ListItem>
          <ListItemText primary='Telefone' secondary={props.user.prefix + props.user.phone} />
        </ListItem>
      </List>
    </Dialog>
  )
}
function SimpleDialogDemo(props) {
  return (
    <div>
      <AddBox
        data-tip='Informações sobre o usuário'
        size='large'
        style={{ margin: '0 10px', color: '#076F9F', cursor: 'pointer' }}
        fontSize='large'
        onClick={props.handleClickOpen}
      />
      <SimpleDialog user={props.user} open={props.open} onClose={props.handleClickOpen} />
    </div>
  )
}

export default SimpleDialogDemo
