import styled from 'styled-components'

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 769px) {
    align-items: center;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
    margin-bottom: 15px;
  }
`

export const Title = styled.h1`
  margin-right: 20px;
`

export const IncomeReportLink = styled.button`
  margin: 3px 15px;
  color: #014c8c;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 769px) {
    margin-left: 0px;
  }
`

export const ButtonRed = styled.button`
  margin: 3px 15px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #eee;
  font-weight: 600;
  color: #a80303;
  :hover {
    text-decoration: underline;
  }
  @media (max-width: 769px) {
    margin-left: 0px;
  }
`

export const PersonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

export const Investments = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const InvestmentsLink = styled.a`
  color: #014c8c;
  margin: 3px 15px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 769px) {
    margin-left: 0px;
  }
`

export const PersonName = styled.p`
  font-weight: bold;
  @media (max-width: 769px) {
    margin-bottom: 0.5em;
  }
`

export const Person = styled.div`
  display: flex;
  cursor: pointer;
  border-left: 3px solid ${({ profileComplete }) => (profileComplete ? 'green' : 'red')};
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  background: #eee;
  margin-top: 1em;
`

export const Button = styled.button`
  background: #0275d8;
  padding: 0.5rem 1rem;
  color: white;
  height: 36px;
  border-radius: 4px;
  border: none;
  width: 150px;
  cursor: pointer;
`

export const Select = styled.select`
  outline: 0;
  height: 36px;
  width: 300px;
  background: #fff;
  border: 1px solid lightgray;
  transition: all 0.3s;
  &:focus {
    border: 1px solid #0275d8;
  }
  border-radius: 4px;
`

export const Filters = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: 1em;
  justify-content: space-between;
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Filter = styled.input`
  padding: 0.5rem 0.75rem;
  margin: 0 2em;
  font-size: 1rem;
  width: 100%;
  height: 36px;
  outline: 0;
  border: 1px solid lightgray;
  transition: all 0.3s;
  &:focus {
    border: 1px solid #0275d8;
  }
  border-radius: 4px;
  color: #464a4c;
  background-color: #fff;
  @media (max-width: 769px) {
    width: 300px;
    margin: 1em 0;
  }
`

export const Investors = styled.div`
  display: flex;
  width: 70%;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 769px) {
    width: 100%;
    padding: 0 1em;
  }
`

export const Container = styled.div`
  padding-top: 120px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1em;
`
