import React from 'react'

import { Row, Column, Text, NumberInput, Input, Checkbox } from '../index'

const ContactData = ({ errors, isMobile, isTablet, control, changeCheckbox, valueOrigin, indicateBy }) => {
  if (isMobile || isTablet) {
    return (
      <Column>
        <Text mt={5} variant='h4'>
          Dados de contato
        </Text>
        {valueOrigin === 3 && !!indicateBy && !!indicateBy.value && (
          <Checkbox
            onChange={e => changeCheckbox(e)}
            name='mock_checkbox'
            control={control}
            label='O parceiro será o contato responsável pelo envio dos documentos e informações financeiras'
            error={errors.mock_checkbox}
            ml='10px'
          />
        )}
        <Input error={errors.first_name} label='Nome *' control={control} name='first_name' width='100%' mt={5} />
        <Input error={errors.last_name} label='Sobrenome *' control={control} name='last_name' width='100%' mt={5} />
        <NumberInput mt={5} error={errors.celphone} inputType='celphone' control={control} name='celphone' />
        <NumberInput mt={5} inputType='phone' control={control} name='telephone' />
        <Input error={errors.email} label='E-mail *' control={control} name='email' width='100%' mt={5} />
      </Column>
    )
  }
  return (
    <Column>
      <Row mt={5}>
        <Text variant='h4'>Dados de contato</Text>
      </Row>
      {valueOrigin === 3 && !!indicateBy && !!indicateBy.value && (
        <Checkbox
          onChange={e => changeCheckbox(e)}
          name='mock_checkbox'
          control={control}
          label='O parceiro será o contato responsável pelo envio dos documentos e informações financeiras'
          error={errors.mock_checkbox}
          ml='10px'
        />
      )}
      <Row mt={5}>
        <Input error={errors.first_name} label='Nome *' control={control} name='first_name' width='45%' />
        <Input error={errors.last_name} label='Sobrenome *' control={control} name='last_name' width='45%' ml={3} />
      </Row>
      <Row mt={5}>
        <NumberInput error={errors.celphone} inputType='celphone' control={control} name='celphone' width='45%' />
        <NumberInput inputType='phone' control={control} name='telephone' width='45%' ml={3} />
      </Row>
      <Row mt={5}>
        <Input error={errors.email} label='E-mail *' control={control} name='email' width='45%' />
      </Row>
    </Column>
  )
}

export default ContactData
