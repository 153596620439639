import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  margin: 8px;
  padding: 16px;
  width: 47%;
  height: 250px;
  background: #f2f2f2;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`

export const Title = styled.p`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #076f9f;
`

export const Info = styled.p`
  margin-bottom: 16px;
  color: #424242;
`

export const InfoContainer = styled.div`
  width: 100%;
`

export const Bold = styled.span`
  font-weight: 500;
`

export const EditInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    align-items: center;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
`
