import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import InputBase from './InputBase'

const Input = forwardRef(({ name, defaultValue, control, ...props }, ref) => {
  return (
    <Controller as={<InputBase ref={ref} {...props} />} name={name} defaultValue={defaultValue} control={control} />
  )
})

Input.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string
    }),
    PropTypes.bool
  ]),
  type: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  inputProps: PropTypes.object
}

Input.defaultProps = {
  variant: 'outlined',
  size: 'small',
  multiline: false
}

export default Input
