import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Drawer, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { createLeadUpdate } from '../../../services/v3'
import { modalAddLeadUpdate } from '../../../schemas'

import { Radio, Checkbox, Row, Button } from '../index'
import { Form } from './styles'

const drawerWidth = 240

const options = [
  {
    value: '1',
    label: 'Comentário'
  },
  {
    value: '2',
    label: 'Ligação'
  },
  {
    value: '3',
    label: 'Notas do comitê'
  }
]

const ModalAddRegister = ({ open, buttonClick, handleClose, id, addInTimeline }) => {
  const {
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm({
    resolver: modalAddLeadUpdate
  })

  const [value, setValue] = useState('')

  const onSubmit = async values => {
    try {
      const body = {
        observation: value.toString('html'),
        lead_id: id,
        lead_update_status_id: values.lead_update_status_id,
        is_visible_to_partner: values.is_visible_to_partner
      }
      const { data } = await createLeadUpdate(body)
      toast.success('Registro adicionado.')
      addInTimeline(data)
    } catch {
      toast.error('Ocorreu um erro ao criar o resgistro.')
    } finally {
      setValue('')
      handleClose()
    }
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      anchor='right'
      open={open}
    >
      <div style={{ background: '#dfdfdf' }}>
        <IconButton onClick={handleClose}>{open === false ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <h4>Adicionar registro</h4>
        <Row style={{ marginBottom: '15px' }}>
          <Radio
            options={options}
            control={control}
            name='lead_update_status_id'
            error={errors.lead_update_status_id}
          />
        </Row>

        <ReactQuill
          theme='snow'
          value={value}
          onChange={setValue}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['link', 'image'],
              ['clean']
            ]
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image'
          ]}
        />
        <Checkbox
          label='Mostrar mensagem para parceiro'
          control={control}
          name='is_visible_to_partner'
          error={errors.is_visible_to_partner}
          style={{ marginTop: '15px' }}
        />
        <Row>
          <Button height={40} width={227} onClick={buttonClick} mr={3} variant='outlined'>
            Cancelar
          </Button>
          <Button height={40} width={227} type='submit' loader={isSubmitting}>
            Enviar
          </Button>
        </Row>
      </Form>
    </Drawer>
  )
}

export default ModalAddRegister
