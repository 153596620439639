import React from 'react'

import { Row, Column, Input, Text, NumberInput } from '../index'

const CompanyDataIncompleted = ({ isEditing, errors, isMobile, isTablet, control }) => {
  if (isMobile || isTablet) {
    return (
      <Column>
        <Text mt={5} variant='h4'>
          Dados da empresa
        </Text>
        <Input
          mt={5}
          label='Nome da empresa *'
          control={control}
          name='company_name'
          width='100%'
          error={errors.company_name}
        />
        <Input
          label='Histórico da Franquia *'
          control={control}
          name='company_history'
          width='100%'
          multiline
          mt={5}
          error={errors.company_history}
        />
        <NumberInput
          mt={5}
          error={errors.average_monthly_revenue}
          inputType='currency'
          label='Faturamento médio mensal estimado *'
          name='average_monthly_revenue'
          control={control}
          width='100%'
        />
        {!isEditing && (
          <NumberInput
            error={errors.desired_amount}
            inputType='currency'
            label='Valor do empréstimo desejado *'
            name='desired_amount'
            control={control}
            width='100%'
            mt={5}
          />
        )}
        <Input
          error={errors.lending_reason}
          label='Motivo do empréstimo *'
          control={control}
          name='lending_reason'
          width='100%'
          multiline
          rows={5}
          mt={5}
        />
      </Column>
    )
  }
  return (
    <Column>
      <Row mt={5}>
        <Text variant='h4'> Dados da empresa </Text>
      </Row>
      <Row mt={5}>
        <Input
          label='Nome da empresa *'
          control={control}
          name='company_name'
          width='45%'
          error={errors.company_name}
        />
      </Row>
      <Row mt={5}>
        <NumberInput
          error={errors.average_monthly_revenue}
          inputType='currency'
          label='Faturamento médio mensal estimado *'
          name='average_monthly_revenue'
          control={control}
          width='45%'
        />
        {!isEditing && (
          <NumberInput
            error={errors.desired_amount}
            inputType='currency'
            label='Valor do empréstimo desejado *'
            name='desired_amount'
            control={control}
            width='45%'
            ml={3}
          />
        )}
      </Row>
      <Row mt={5}>
        <Input
          label='Histórico da empresa *'
          control={control}
          name='company_history'
          width='92%'
          multiline
          rows={5}
          error={errors.company_history}
        />
      </Row>
      <Row mt={5}>
        <Input
          error={errors.lending_reason}
          label='Motivo do empréstimo *'
          control={control}
          name='lending_reason'
          width='92%'
          multiline
          rows={5}
        />
      </Row>
    </Column>
  )
}

export default CompanyDataIncompleted
