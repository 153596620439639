import styled from 'styled-components'
import { Option } from '../../components'

export const DrawerTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #424242;
`
export const Value = styled.p`
  color: ${({ title }) => (/[-]/.test(title) ? '#fd4646' : '#43A047')};
`
export const ViewInvestment = styled.p`
  margin-left: 11px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #076f9f;
  cursor: pointer;
`

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 16px;
    color: #424242;

    margin-bottom: 8px;
  }

  label {
    width: 100%;
  }

  section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 12px;
    padding-top: 12px;
  }

  section + section {
    margin-top: 0px;
    border-top: #e0e0e0 solid 1px;
  }

  footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    width: 100%;
  }
`
export const OptionClose = styled(Option)`
  img {
    width: 25px;
    height: 25px;
  }
`

export const DivOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    background: transparent;
  }
`
