import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'

import numbro from 'numbro'
import { Formik } from 'formik'
import ReactTooltip from 'react-tooltip'
import { FormControlLabel, Modal, Typography, Fab, Button as DefaultButton } from '@material-ui/core'
import { Edit, Add as AddIcon, GetApp, CloudUpload, CloudDone } from '@material-ui/icons'
import { withTheme } from 'emotion-theming'

import { Checkbox, Filter, LoadingScreen, MaterialButton } from '../../components'

import {
  Button,
  ResetFilter,
  SearchContainer,
  SelectContainer,
  Select,
  TypographyContainer,
  Form,
  InputContainer,
  InputWrapper,
  ButtonsContainer,
  ModalContent,
  ModalContainer,
  CheckboxContainer,
  StyledReactTable,
  FileInput,
  IconsContainer,
  Container,
  Loader,
  LoaderContainer
} from './style'

import { getCampaignById, getLinkToDownloadCcbs, getOrders, createCcb } from '../../services/v3'

import 'react-toastify/dist/ReactToastify.css'
import './toastify.css'
import 'react-table-6/react-table.css'

const BankCreditCertificates = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [campaignOrders, setCampaignOrders] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [filters, setFilters] = useState({ withBCC: true, withoutBCC: true })
  const [isOpen, setIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [filter, setFilter] = useState('')
  const [campaignOriginalOrders, setCampaignOriginalOrders] = useState(null)
  const [isDownloadingCcbs, setIsDownloadingCcbs] = useState(false)

  const selectedFilter = useRef(null)

  const fetchCampaign = async () => {
    try {
      setIsLoading(true)

      const [{ data: campaign }, { data: campaignOrders }] = await Promise.all([
        getCampaignById(match.params.id),
        getOrders({ campaign_id: match.params.id })
      ])

      setCampaign(campaign)

      const filteredCampaignOrders = campaignOrders.filter(order => {
        switch (true) {
          case filters.withBCC && filters.withoutBCC:
            return order
          case filters.withoutBCC:
            return !order.ccb || !order.ccb.id
          case filters.withBCC:
            return order.ccb && order.ccb.id
          default:
            return null
        }
      })

      const populatedOrders = filteredCampaignOrders.map(order => ({
        ...order,
        name: `${order.pledges[0].investor.first_name} ${order.pledges[0].investor.last_name}`,
        user_id: order.pledges[0].investor.user_id
      }))

      setCampaignOrders(populatedOrders)
      setCampaignOriginalOrders(populatedOrders)
    } catch (error) {
      console.error(error.message)
      toast.error('Ocorreu um erro ao buscar os dados')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCampaign()
  }, [filters])

  const handleDownload = async url => {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    return link.click()
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      formData.append('order_id', selectedUser.id)
      formData.append('file', fileToUpload)

      if (!selectedUser.ccb || !selectedUser.ccb.id) {
        await createCcb(formData)
      } else {
        console.log('edit')
      }
      setIsOpen(false)
      toast.success('Upload de CCB realizado.')
    } catch {
      toast.error('Não foi possível realizar o upload.')
    } finally {
      setFileToUpload(null)
      setIsOpen(false)
      fetchCampaign()
    }
  }

  const handleUploadFileModal = file => {
    setFileToUpload(file)
  }

  const handleModal = user => {
    setSelectedUser(user)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setFileToUpload(false)
  }

  const handleDownloadCcbs = async () => {
    try {
      setIsDownloadingCcbs(true)
      const { data: zipFile } = await getLinkToDownloadCcbs(match.params.id)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(new Blob([zipFile], { type: 'text/plain' }))
      element.download = `ccbs_campaign_${match.params.id}.zip`
      document.body.appendChild(element)
      element.click()
    } catch (error) {
      console.error(error.message)
      toast.error('Não encontramos essas CCBs.')
    } finally {
      setIsDownloadingCcbs(false)
    }
  }

  const columns = [
    {
      accessor: 'name',
      Header: 'Nome',
      Cell: props => (
        <p>
          {props.original.name} (user_id: {props.original.user_id})
        </p>
      )
    },
    {
      accessor: 'amount',
      Header: 'Valor',
      Cell: props => <p>R$ {numbro(props.original.amount).format({ thousandSeparated: true, mantissa: 2 })}</p>
    },
    {
      Header: 'Ações',
      Cell: props => (
        <IconsContainer>
          {props.original.ccb && props.original.ccb.id ? (
            <React.Fragment>
              <Fab
                onClick={() => handleModal(props.original)}
                data-tip='Edite a CCB.'
                size='small'
                color='secondary'
                aria-label='Edit'
                disabled={true}
              >
                <Edit fontSize='small' />
              </Fab>
              <Fab
                onClick={() => handleDownload(props.original.ccb.ccb_url)}
                data-tip='Download da CCB'
                size='small'
                color='primary'
                style={{ background: '#076F9F' }}
                aria-label='Edit'
              >
                <GetApp fontSize='small' />
              </Fab>
            </React.Fragment>
          ) : (
            <Fab
              data-tip='Adicione uma CCB.'
              size='small'
              onClick={() => handleModal(props.original)}
              color='primary'
              style={{ background: '#076F9F' }}
              aria-label='Add'
            >
              <AddIcon />
            </Fab>
          )}
        </IconsContainer>
      )
    }
  ]

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader src='/assets/icons/big-spinner.svg' />
      </LoaderContainer>
    )
  }

  const filterOrders = e => {
    e.preventDefault()

    switch (selectedFilter.current.value) {
      case 'name':
        return setCampaignOrders(
          campaignOriginalOrders.filter(order => order.name.toUpperCase().includes(filter.toUpperCase()))
        )
      case 'user_id':
        return setCampaignOrders(campaignOriginalOrders.filter(order => String(order.user_id).includes(filter)))
      case 'amount':
        return setCampaignOrders(campaignOriginalOrders.filter(order => order.amount === Number(filter)))
      default:
        return setCampaignOrders(campaignOriginalOrders)
    }
  }

  const resetFilters = () => {
    setCampaignOrders(campaignOriginalOrders)
    setFilter('')
  }

  if (!campaign)
    return (
      <Container>
        <LoadingScreen />
      </Container>
    )

  return (
    <Container>
      <React.Fragment>
        <h3>{campaign.name}</h3>
        <SearchContainer>
          <SelectContainer>
            <label htmlFor={selectedFilter}>Filtrar por:</label>
            <Select ref={selectedFilter}>
              <option value='name'>Nome do cliente</option>
              <option value='user_id'>user_id</option>
              <option value='amount'>Valor do pedido</option>
            </Select>
          </SelectContainer>
          <Filter value={filter} onChange={e => setFilter(e.target.value)} />
          <Button type='submit' onClick={e => filterOrders(e)}>
            Buscar
          </Button>
          <ResetFilter onClick={resetFilters}>Resetar filtros</ResetFilter>
        </SearchContainer>
        <CheckboxContainer>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.withBCC}
                onChange={e => {
                  setFilters({ ...filters, withBCC: !filters.withBCC })
                }}
                color='primary'
                name='withBCC'
              />
            }
            label='Propostas com CCB'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.withoutBCC}
                onChange={e => {
                  setFilters({ ...filters, withoutBCC: !filters.withoutBCC })
                }}
                value='checkedB'
                color='primary'
                name='withouBCC'
              />
            }
            label='Propostas sem CCB'
          />
          <MaterialButton
            onClick={() => history.push(`/campanha/${match.params.id}/ccbs/upload-em-lote`)}
            variant='contained'
            label='UPLOAD EM LOTE'
          />
          <MaterialButton
            onClick={handleDownloadCcbs}
            backgroundColor='#ffffff'
            color='#076F9F'
            width='250px'
            variant='contained'
            label='DOWNLOAD EM LOTE'
            loading={isDownloadingCcbs}
          />
        </CheckboxContainer>
        <StyledReactTable
          className='ReactTableDefault'
          data={campaignOrders}
          columns={columns}
          defaultPageSize={20}
          loading={isLoading}
          showPaginationBottom={true}
          showPaginationTop={false}
        />
      </React.Fragment>
      <Modal aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description' open={isOpen}>
        <ModalContainer>
          <ModalContent>
            <Typography variant='h6' id='modal-title'>
              Upload de ccb
            </Typography>
            <TypographyContainer>
              <Typography variant='subtitle1' id='simple-modal-description'>
                Upload do arquivo
              </Typography>
            </TypographyContainer>

            <Formik
              onSubmit={handleSubmit}
              render={({ handleSubmit, setFieldValue }) => {
                return (
                  <Form id='form1' onSubmit={handleSubmit}>
                    <InputContainer>
                      <InputWrapper>
                        <FileInput
                          type='file'
                          name={'file'}
                          id={'file'}
                          onChange={e => {
                            setFieldValue('file', e.currentTarget.files[0])
                            handleUploadFileModal(e.currentTarget.files[0])
                          }}
                        />
                        <label htmlFor={'file'}>
                          {fileToUpload ? (
                            <CloudDone style={{ fontSize: 80, color: '#076F9F' }} />
                          ) : (
                            <CloudUpload style={{ fontSize: 80, color: '#076F9F' }} />
                          )}
                        </label>
                      </InputWrapper>
                    </InputContainer>
                    <ButtonsContainer>
                      <DefaultButton
                        type='submit'
                        variant='contained'
                        style={{
                          background: fileToUpload ? '#3dc6ef' : 'gray',
                          color: fileToUpload ? '#fff' : 'darkgray'
                        }}
                        disabled={!fileToUpload}
                      >
                        Criar CCB
                      </DefaultButton>
                      <DefaultButton
                        style={{ background: '#076F9F' }}
                        variant='contained'
                        color='primary'
                        onClick={closeModal}
                      >
                        Fechar
                      </DefaultButton>
                    </ButtonsContainer>
                  </Form>
                )
              }}
            />
          </ModalContent>
        </ModalContainer>
      </Modal>
      <ReactTooltip />
    </Container>
  )
}

export default withTheme(BankCreditCertificates)
