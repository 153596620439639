import React from 'react'

import { ToolTipAlert } from './style'

const WithoutDocument = ({ lead }) => {
  return (
    lead.lead_status_id === 3 &&
    !lead.lead_document_sent && (
      <ToolTipAlert
        arrow={true}
        title='Não possui nenhum documento enviado.'
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div>
          <img
            style={{ width: '30px' }}
            src='/assets/icons/NotDocument.svg'
            alt='Não possui nenhum documento enviado.'
          />
        </div>
      </ToolTipAlert>
    )
  )
}

export default WithoutDocument
