import client from '../../providers/fetchClient'

export const getCampaigns = () => client.get('/v3/campaigns')

export const getCampaignStatuses = () => client.get('/v3/campaign-statuses')

export const getCampaignById = id => client.get(`/v3/campaigns/${id}`)

export const updateCampaign = (id, data) => client.put(`/v3/campaigns/${id}`, data)

export const getAllInfoCompaigns = params => client.get('/v3/campaigns/admin', { params })

export const exportCampaings = () =>
  client.get('/v3/campaigns/admin?export_as_csv=true', {
    responseType: 'arraybuffer'
  })
