import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Dropdown = ({ list, title, titleBg, disabled }) => {
  const [listOpen, setListOpen] = React.useState(false)
  const toggleList = () => {
    setListOpen(!listOpen)
  }

  const closeList = () => {
    setListOpen(false)
    window.removeEventListener('click', closeList)
  }

  const handleClick = cb => {
    setListOpen(false)
    cb()
  }

  React.useEffect(() => {
    if (listOpen) {
      window.addEventListener('click', closeList)
    }
    return () => window.removeEventListener('click', closeList)
  }, [listOpen])
  return (
    <Wrapper disabled={disabled} onClick={toggleList} on>
      <Header>
        <HeaderTitle bgColor={titleBg}>{title}</HeaderTitle>
        {listOpen ? <FontAwesomeIcon icon='chevron-up' /> : <FontAwesomeIcon icon='chevron-down' />}
      </Header>
      {listOpen && (
        <List onClick={e => e.stopPropagation()}>
          {list.map((item, i) => (
            <ListItem key={`${i}-dropdown-item`} onClick={() => handleClick(item.onClick)}>
              {item.title}
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  )
}

const HeaderTitle = styled.p`
  margin: 0px;
  text-transform: uppercase;
  line-height: 17px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-right: 20px;
  background-color: ${props => props.bgColor};
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  position: relative;
  height: 17px;
  cursor: pointer;
`

const List = styled.ul`
  z-index: 10;
  position: absolute;
  left: 0px;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  font-weight: 700;
  padding: 14px 0px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 18px 0px;
`

const ListItem = styled.li`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  padding: 10px 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.08);
  }
`

const Wrapper = styled.div`
  padding: 16px 10px 16px;
  background-color: transparent;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  min-width: 150px;
`

export default Dropdown
