import client, { getEnviroment } from '../providers/fetchClient'
import axios from 'axios'

export const getLinkToDownload = (id, docInitials) => client.get(`/esh-download/${id}/${docInitials}/`)

export const getLinkToDownloadCcbs = campaignId => `${getEnviroment()}/bmp/download-ccbs/${campaignId}`

export const getLinkToUpload = (id, docName) => client.get(`/esh-upload/${id}/CCB/${docName}`)

export const getLinkToUploadv2 = (name, id, fileName) => client.get(`/v2/upload/${name}/${id}/${fileName}`)

export const uploadDoc = async (url, file) =>
  new Promise(async (resolve, reject) => {
    const config = {
      headers: {
        'content-type': file.type
      }
    }
    try {
      const res = await axios.put(url, file, config)
      resolve(res.data.url)
      return res
    } catch (error) {
      reject(error)
    }
  })
