import styled from 'styled-components'
import Modal from 'react-modal'

export const Cell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  margin-left: 10px;
`

export const ModalDescripion = styled.p`
  padding: 1em 0;
  font-weight: 500;
`

export const ModalContent = styled.div`
  display: flex;
  width: 700px;
  border-radius: 4px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`

export const ModalComponent = styled(Modal)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PayDateInput = styled.input`
  padding: 0.5em;
  margin: 0 0.3em;
  border-radius: 4px;
  border: 1px solid darkgray;
  transition: all 0.3s;
  &:focus {
    border: 1px solid #076f9f;
    outline: 0;
  }
`

export const PayDateContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CampaignName = styled.h2`
  margin-bottom: 30px;
  text-align: center;
`

export const ParcelsContainer = styled.div`
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const ParcelsContent = styled.div`
  padding: 0 40px;
`

export const I = styled.i`
  color: ${({ color }) => color};
  font-size: 16px;
  margin-right: 5px;
`

export const DateCell = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const DateText = styled.p`
  margin-bottom: 5px;
`
// const IconsContainer = styled.div`
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   padding: 0 0.3em;
// `

export const ActionContainer = styled.div`
  margin: 0px 2em 15px 2em;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgray;
  flex-wrap: wrap;
`
export const DownloadCSVContainer = styled.div`
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 40px;
  @media (max-width: 769px) {
    label {
      width: 150px;
    }
  }
`
export const DownloadCSVButton = styled.button`
  border-radius: 4px;
  padding: 0.6em 35px;
  margin: 0 1em 0 0;
  background: white;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px;
  outline: none;
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : 'white')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  color: ${({ disabled }) => (disabled ? '#252525' : '#076F9F')};
  border: 1px solid ${({ disabled }) => (disabled ? '#252525' : '#076F9F')};
  cursor: ${({ disabled }) => (disabled ? 'not-allwed' : 'pointer')};

  &:hover {
    background: #076f9f;
    color: white;
    transition: all 0.3s;
  }
`
