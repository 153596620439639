import React from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment-timezone'
import 'moment/locale/pt-br'
import MomentUtils from '@date-io/moment'
import { withStyles } from '@material-ui/core'
import { colors } from '../../Themes'

const locale = moment.locale('pt-BR')

const GlobalCss = withStyles({
  '@global': {
    '.MuiFormControl-root': {
      width: '173px'
    },
    '.MuiOutlinedInput-input': {
      padding: '6px',
      color: colors.mediumGray,
      borderRadius: '4px',
      border: `1px solid ${colors.mediumGray}`,
      fontSize: '14px',
      textAlign: 'center'
    },
    '.MuiInputBase-root.Mui-focused': {
      border: `0 solid ${colors.blueKavod}`,
      outline: 0
    },
    '.MuiInputBase-input ': {
      borderRadius: '4px',
      transition: 'all 0.3s'
    }
  }
})(() => null)

const DatePickerMaterial = ({ value, onChange, placeholder, inputVariant }) => {
  return (
    <>
      <GlobalCss />
      <MuiPickersUtilsProvider format='string' utils={MomentUtils} locale={locale}>
        <DatePicker
          value={value}
          onChange={onChange}
          format={'DD/MM/YYYY'}
          inputVariant={inputVariant}
          placeholder={placeholder}
        />
      </MuiPickersUtilsProvider>
    </>
  )
}

export default DatePickerMaterial
