import styled from 'styled-components'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

export const StyledBreadcrumb = styled(Breadcrumbs)`
  &&.MuiBreadcrumbs-root {
    ${spacing};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  ${palette};
  ${typography};
`

export const Container = styled.div``
