import styled from 'styled-components'
import { colors } from '../../../../Themes/colors'

export const Main = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: block;
  }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`

export const Container = styled.div`
  display: flex;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;

    p {
      margin-right: 8px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #424242;
    }
  }

  main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 50px;
    flex-wrap: wrap;
    @media (max-width: 750px) {
      gap: 0 150px;
    }
    @media (max-width: 590px) {
      gap: 0 70px;
    }
    @media (max-width: 475px) {
      gap: 0 25px;
    }

    div {
      padding: 16px 0;
      display: flex;
      flex-direction: column;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
      }

      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #424242;
      }
    }
  }

  section {
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 12px;
        line-height: 16px;
        color: #757575;
        padding-top: 16px;
      }
    }
  }

  div {
    p {
      font-size: 16px;
      line-height: 24px;
      color: #424242;
      padding: 4px 0;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      color: ${colors.blue};
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      padding: 24px;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
    border-radius: 4px;
    padding: 16px;
    z-index: 2;
  }
`

export const FilterContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 1100px) {
    display: block;
  }
`
