import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import useDevice from '../../../../hooks/useDevice'

import { ModalBase, Column, Button, Row, Checkbox } from '../../index'

const UpdateStatusModal = ({ isOpen, onClose, onConfirm, status }) => {
  const { isMobile } = useDevice()
  const { control, handleSubmit } = useForm()
  const [check, setCheck] = useState(true)

  return (
    <ModalBase
      open={isOpen}
      onClose={onClose}
      width={isMobile ? 300 : 526}
      height='100%'
      title={`Tem certeza que deseja alterar o status${!!status ? ` para "${status.name}"` : ''}?`}
    >
      <form
        onSubmit={handleSubmit(item =>
          onConfirm({ is_visible_to_partner: !!status && status.id === 5 ? false : check })
        )}
      >
        {!!status && status.id !== 5 && (
          <Checkbox
            label='Mostrar mensagem para parceiro'
            control={control}
            checked={check}
            onClick={() => {
              setCheck(!check)
            }}
            name='is_visible_to_partner'
          />
        )}

        <Column height='100%'>
          <Row mt={3}>
            <Button height={40} width={227} onClick={onClose} mr={3} variant='outlined'>
              Não
            </Button>
            <Button height={40} width={227} type='submit'>
              Sim
            </Button>
          </Row>
        </Column>
      </form>
    </ModalBase>
  )
}

export { UpdateStatusModal }
