import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import PortionsModal from '../PortionsModal'
import InstallmentRow from '../InstallmentRow'
import AnticipationTableModal from '../AnticipationTableModal'

const InstallmentsTable = ({ installments }) => {
  const [portionIndex, setPortionIndex] = useState(0)
  const [normalInstallments, setNormalInstallments] = useState([])
  const [anticipateInstallments, setAnticipateInstallments] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [anticipationModalIsOpen, setAnticipationModalIsOpen] = useState(false)

  useEffect(() => {
    setNormalInstallments(installments.filter(({ was_total_liquidation }) => !was_total_liquidation))
    setAnticipateInstallments(installments.filter(({ was_total_liquidation }) => was_total_liquidation))
  }, [installments])

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const handleInstallmentClick = index => () => {
    setPortionIndex(index)
    setModalIsOpen(true)
  }

  const handleAnticipationClick = () => {
    setAnticipationModalIsOpen(true)
  }

  return (
    <Container>
      <Table>
        <thead>
          <TableRow>
            <TableHeader mw='50px'>#</TableHeader>
            <TableHeader mw='100px'>Valor previsto da TED</TableHeader>
            <TableHeader mw='80px'>Previsão de pagamento</TableHeader>
            <TableHeader mw='80px'>Pago em</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {normalInstallments.map((portion, index) => (
            <InstallmentRow key={portion.id} portion={portion} handleRowClick={handleInstallmentClick(index)} />
          ))}
          {anticipateInstallments.length > 0 && (
            <TableRow onClick={handleAnticipationClick}>
              <AnticipationMessageRow className='accordion-table-cell'>
                Esta campanha possuía um prazo de {installments.length} parcelas porém foi quitada antecipadamente,
                clique para ver o detalhamento.
              </AnticipationMessageRow>
            </TableRow>
          )}
        </tbody>
      </Table>
      {modalIsOpen && (
        <PortionsModal
          className='Modal'
          overlayClassName='Overlay'
          isOpen={modalIsOpen}
          closeModal={closeModal}
          portions={installments}
          index={portionIndex}
          handleChangePortion={setPortionIndex}
        />
      )}
      {anticipationModalIsOpen && (
        <AnticipationTableModal
          installments={anticipateInstallments}
          isOpen={anticipationModalIsOpen}
          onClose={() => setAnticipationModalIsOpen(false)}
          handleInstallmentClick={handleInstallmentClick}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  overflow: auto;
`

const AnticipationMessageRow = styled.th`
  padding: 30px 10px;
  height: 108px;
  flex: 1;
  text-align: left;
  display: flex;
  justify-content: center;
  white-space: normal;
  font-weight: normal;
`

const Table = styled.table`
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const TableRow = styled.tr`
  cursor: pointer;
  background-color: white;
  transition: all 0.3s;
  display: flex;
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
    transition: all 0.3s;
  }

  @media (max-width: 769px) {
    font-size: 14px;
  }
`

const TableHeader = styled.th`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: normal;
  font-weight: 600;
  padding: 1em 0;
  ${({ mw }) => `min-width: ${mw}`};
`

export default InstallmentsTable
