import styled from 'styled-components'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { colors } from '../../Themes'

export const Title = styled.h1`
  color: ${colors.blueKavod};
  font-size: 24px;
  margin: 20px 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  @media (max-width: 620px) {
    flex-direction: column;
    align-items: center;
  }
`

export const RemoveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  @media (max-width: 620px) {
    width: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

export const Container = styled.div`
  margin-bottom: 2em;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const StyledAddIcon = styled(AddCircleIcon)`
  color: ${colors.blueKavod};
  cursor: pointer;
`

export const StyledRemoveIcon = styled(RemoveCircleIcon)`
  color: ${colors.darkRed};
  cursor: pointer;
`
