import moment from 'moment-timezone'

import { banks } from '../constants'
import { formatMoney, formatCpf, getAccountType } from './'
import { getBankById, getUserById } from '../services/v3'

const banksArray = Object.keys(banks)

export const filterAcceptedPledgesArray = pledgesMatrix =>
  pledgesMatrix.filter(
    pledgeArray =>
      pledgeArray[0].pledge_status.abbreviation === 'AC' || pledgeArray[0].pledge_status.abbreviation === 'A'
  )

export const filterOnHoldingPledgesArray = pledgesMatrix =>
  pledgesMatrix.filter(pledgeArray => pledgeArray[0].pledge_status.abbreviation === 'OH')

export const filterCancelledPledgesArray = pledgesMatrix =>
  pledgesMatrix.filter(pledgeArray => pledgeArray[0].pledge_status.abbreviation === 'C')

export const filterDeletedPledgesArray = pledgesMatrix =>
  pledgesMatrix.filter(pledgeArray => pledgeArray[0].pledge_status.abbreviation === 'D')

export const sortPledgesBySttAndKvdid = (a, b) => {
  const sttOrder = { AC: 1, A: 2, OH: 3, C: 4, D: 5 }
  return (
    sttOrder[a.pledge_status.abbreviation] - sttOrder[b.pledge_status.abbreviation] ||
    a.investor_id - b.investor_id ||
    a.id - b.id
  )
}

export const sortMatrizationById = (a, b) => a[0].id - b[0].id

export const acceptedAverageM = pledges => {
  const acceptedPledgesArray = filterAcceptedPledgesArray(pledges)
  const total = acceptedPledgesArray.reduce((acc, cur) => acc + cur.reduce((sum, pledge) => sum + pledge.amount, 0), 0)
  return acceptedPledgesArray.length > 0 ? total / acceptedPledgesArray.length : 0
}

export const matrization = pledges => {
  let finalArray = []
  let currentId = null
  let currentStatus = null
  let currentArray = []

  pledges.forEach(pledge => {
    if (!currentId) {
      currentId = pledge.investor_id
      currentStatus = pledge.pledge_status_id
    } else if (currentId !== pledge.investor_id || currentStatus !== pledge.pledge_status_id) {
      finalArray.push(currentArray)
      currentArray = []
      currentId = pledge.investor_id
      currentStatus = pledge.pledge_status_id
    }

    currentArray.push(pledge)
  })
  if (currentArray.length) finalArray.push(currentArray)
  return finalArray
}

export const generatePledgesCsv = async pledgesMatrix => {
  return await Promise.all(pledgesMatrix.map(generateLineCsv))
}

const generateLineCsv = async (pledgeArray, index) => {
  try {
    const { bank_id, user_id } = pledgeArray[0].investor

    const { data: user } = await getUserById(user_id)

    let investorBankInfos = {}
    if (bank_id) {
      const { data: fetchInvestorBank } = await getBankById(bank_id)
      const bankName = banksArray.find(bank => bank.split(' ')[0] === fetchInvestorBank.code)
      investorBankInfos = {
        bank_account_type: getAccountType(fetchInvestorBank.type),
        bank_name: bankName,
        bank_agency: fetchInvestorBank.agency,
        bank_account_number: fetchInvestorBank.account,
        bank_account_digit: fetchInvestorBank.account_digit
      }
    }

    const { prefix, phone } = pledgeArray[0].investor
    return {
      index: index + 1,
      campaign_id: pledgeArray[0].campaign_id,
      pledge_id: pledgeArray[0].id,
      user_id: pledgeArray[0].investor.user_id,
      amount: formatMoney(pledgeArray.reduce((total, { amount }) => total + amount, 0)),
      date_created: moment(pledgeArray[0].created_at).format('DD/MM/YYYY [às] HH:mm'),
      status: formatStatus(pledgeArray[0].pledge_status.abbreviation),
      first_name: pledgeArray[0].investor.first_name,
      last_name: pledgeArray[0].investor.last_name,
      email: user.email,
      document_number: user.document_number ? formatCpf(user.document_number) : '',
      phone: `${prefix || ''} ${phone || ''}`,
      ...investorBankInfos
      // ccb_file_name: , // TODO integração de ccb
    }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const formatStatus = abbreviation => {
  switch (abbreviation) {
    case 'A':
      return 'Confirmado'
    case 'AC':
      return 'Processado'
    case 'C':
      return 'Cancelado'
    case 'OH':
      return 'Em espera'
    case 'D':
      return 'Deletado'
    default:
      break
  }
}

export const verifyPledgeSteps = (abbreviation, campaignStatus) => {
  if (abbreviation === 'PR') {
    return {
      payments_received: true,
      disbursement_done: false
    }
  }

  if (abbreviation === 'DD' || ['CL', 'CS'].includes(campaignStatus)) {
    return {
      payments_received: true,
      disbursement_done: true
    }
  }

  return {
    payments_received: false,
    disbursement_done: false
  }
}
